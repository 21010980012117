import { Box, Card, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { GaugeAnalysis } from "./GaugeAnalysis";
import { GaugeInfo } from "./GaugeInfo";
import { ProductInfo } from "./ProductInfo";
import CardContent from "@material-ui/core/CardContent";
import { blue } from "@material-ui/core/colors";

export function GaugeRep({
  study_info = [{ report_number: "", study: "" }],
  product_info = [{ Part: "", Character: "", Specification: "" }],
  gauge_info = [
    { "Gauge Family": "", "Gauge Number": null, "Least Count": "" },
  ],
  test_info = [
    {
      "Study Date": "",
      Comments: "",
    },
  ],
}) {
  return (
    <>
      <div className="row mt-1 align-items-center">
        <div className="col-6">
          <Typography variant="h5" style={{ backgroundColor: blue[100] }}>
            {study_info[0]?.study}
          </Typography>
        </div>
        <div className="col-6">
          <Typography variant="h5" style={{ backgroundColor: blue[100] }}>
            {study_info[0]?.report_number}
          </Typography>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-4">
          {" "}
          <ProductInfo product_info={product_info} />
        </div>
        <div className="col-4">
          {" "}
          <GaugeInfo gauge_info={gauge_info} />
        </div>
        <div className="col-4">
          <GaugeAnalysis test_info={test_info} />
        </div>
      </div>
    </>
  );
}
