import { Button, Grid, Paper } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import formtoollist from "../../../services/formTool"
import { DGDrawer } from "../../../components/DGDrawer";
import ActiveInActive from "../../../components/ActiveInActive";
import CommonGrid from "../../../components/CommonGrid";
import AddIcon from "@material-ui/icons/Add";
import LinkIcon from "@material-ui/icons/Link";
import StatusFilter from "../../../components/shared/StatusFilter";
import FormToolProductLinkAdd from "./FormToolProductLinkAdd"




const FormToolProductLink = () => {
    const [rows, setRows] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [plants, setPlants] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const { globalPlant } = useContext(PlantContext);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            callback: changeStatus,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return formtoollist.updateToolProduct(rowId, { enabled: newStatus });
    }

    function reloadGridData() {
        formtoollist.getToolProductList(
            currentUser?.client_id,
            globalPlant?.globalPlant?.id || currentUser?.client_id)
            .then((res) => {
                setRows(res?.data ?? []);
            });
    }


    function ButtonComponent() {
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<LinkIcon />}
                    onClick={() => {
                        LoadAddForm();
                    }}
                    style={{ marginLeft: 3 }}
                >
                    Link
                </Button>
            </>
        );
    }

    function LoadAddForm(data) {
        //setSubtitle(data?.request_name ?? "");
        setFormOpen(true);
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },
        {
            headerName: "Part",
            field: "part_number",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 300,

            valueGetter: (p) => {
                return p?.data?.part_number + " - " + p?.data?.part_name;
            },
        },

        {
            headerName: "Tool Name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 200,
            valueGetter: (p) => {
                return p?.data?.tool_name + " - " + p?.data?.tool_number;
            },
        },

        {
            headerName: "Machine",
            field: "machine_number",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 300,

            valueGetter: (p) => {
                return p?.data?.machine_number + " - " + p?.data?.machine_name;
            },
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.tool_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entity: params?.data?.part_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    return (
        <Paper>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <CommonGrid
                        rows={rows}
                        columns={columns}
                        gridheight={115}
                        ButtonComponent={ButtonComponent}
                    //onRowSelect={onRowSelection}
                    //getRowStyle={getRowStyle}

                    />
                </Grid>
            </Grid>
            <DGDrawer
                needSave
                drawerWidth={600}
                isOpen={formOpen}
                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData();
                }}
                Component={FormToolProductLinkAdd}
                title="Link Tool and Product "
                subtitle={new Date().toLocaleDateString()}
            />

        </Paper>
    );


}

export default FormToolProductLink;