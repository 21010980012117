/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CommonGrid from "../../components/CommonGrid";
import { StatusContext } from "../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import productService from "../../services/productService";
import { AuthContext } from "../../context/AuthContextProvider";
import plantService from "../../services/plantService";
import { PlantContext } from "../../context/PlantContextProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider"
import StatusFilter from "../../components/shared/StatusFilter";
import ActiveInActive from "../../components/ActiveInActive";

const CharProductListing = () => {
  const { currentUser } = useContext(AuthContext);
  const globalplant = useContext(PlantContext);
  const [rows, setRows] = useState([]);
  const [customerDeliveryChecked, setCustomerDeliveryChecked] = useState(false);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const [plants, setPlants] = useState([]);
  const [plant, setPlant] = useState(null);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);



  //const { updateRoutes } = useContext(SubNavigationContext);


  useEffect(() => {
    setPlant(currentUser.default_plant);
  }, []);


  useEffect(() => {
    if (globalplant?.globalPlant?.globalPlant) {
      setPlant(globalplant?.globalPlant?.globalPlant.id);
    }
  }, [globalplant]);


  useEffect(() => {
    reloadGridData(customerDeliveryChecked)
  }, [plant]);


  const handleCustomerDelivery = (flag) => {
    setCustomerDeliveryChecked(flag);
    reloadGridData(flag);
  };


  useEffect(() => {

    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(customerDeliveryChecked),
      callback: updateProductStatus,
    }));

    reloadGridData(customerDeliveryChecked);


    plantService.getPlants(currentUser.client_id).then((res) => {
      setPlants(res?.data?.data ?? []);
    });

    setHeaderPageTitle(`Critical Characteristics Management`);
  }, []);

  function updateProductStatus(dummy1, dummy2, newStatus, rowId) {
    return productService.updateProduct(rowId, { enabled: newStatus });
  }


  function reloadGridData(customerDeliveryChecked) {
    if (plant === null) return;
    productService
      .getProductTableByClient(
        plant,
        currentUser.client_id
      )
      .then((res) => {
        if (customerDeliveryChecked === true) {
          setRows(res?.data?.filter((exp) => exp?.customer_delivery === 1));
        } else {
          setRows(res?.data ?? []);
        }
      });
  }


  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      maxWidth: 100,
    },
    {
      field: "part_number",
      flex: 1,
      headerName: "Part Number",
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
      cellRenderer: (params) => (
        <Link
          style={{
            cursor: "pointer",
            color: "#2196f3",
          }}
          variant="body2"
          to={`/characteristics-details/${params.data.id}`}
        >

          {params.value}
        </Link>


      )
    },
    {
      field: "part_name",
      headerName: "Part Name",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 300,
    },

    {
      field: "name",
      headerName: "Plant Name",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      resizable: true,
      minWidth: 200,
      hide: currentUser.user_role > 3
    },

    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
        /* onClick={() =>
          setStatus((prev) => ({
            ...prev,
            open: true,
            entityName: params?.data?.part_name ?? "",
            currentStatus: params?.data?.enabled,
            rowId: params?.data?.id,
          }))
        } */
        />
      ),
    },

  ];

  return (
    <Paper>
      <Grid container>
        <Grid item md={12} sm={12}>
          <CommonGrid
            coldefs={{
              filter: true,
              filterParams: {
                apply: true,
                newRowsAction: "keep",
                enabled: { filter: 1 },
                defaultSortModel: [
                  { colId: 'created_at', sort: 'desc' }
                ]
              },
            }}

            columns={columns}
            rows={rows}
            gridheight={100}
            HeaderComponent={() => (
              <FormControlLabel
                control={
                  <Switch
                    checked={customerDeliveryChecked}
                    onChange={(e) => handleCustomerDelivery(e.target.checked)}
                    color="primary"
                    name="Products for Customer Delivery"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Products for Customer Delivery"
              />
            )}
          />
        </Grid>
      </Grid>

    </Paper>
  );
};

export default CharProductListing;
