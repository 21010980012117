import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { number, object, string } from "yup";
import { ACTION_TYPE } from "../../components/shared/commonenum";
import { DGInput } from "../../components/shared/DGInput";
import { AlertContext } from "../../context/AlertContextProvider";
import { AuthContext } from "../../context/AuthContextProvider";
import addressService from "../../services/addressService";
import dateService from "../../services/dateService";
import plantService from "../../services/plantService";
const { v4: uuidv4 } = require("uuid");

export function PlantForm({
  Footer = () => null,
  closeForm = () => {},
  componentProps = null,
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  useEffect(() => {
    let editingPlant = componentProps?.editingPlant;
    let addres = componentProps?.address;
    if (ACTION_TYPE.edit) {
      form.setValues({
        address1: addres?.address_line_1,
        address2: addres?.address_line_2,
        plant: editingPlant?.name,
        city: addres?.city,
        country: addres?.country,
        state: addres?.state,
        pincode: addres?.pincode,
        addressId: editingPlant?.addressId,
        id: editingPlant?.id,
      });
    }

    return () => {
      ACTION_TYPE.edit = false;
    };
  }, []);
  let form = useFormik({
    initialValues: {
      addressId: "",
      plant: "",
      address1: "",
      address2: "",
      state: "",
      city: "",
      pincode: "",
      country: "",
      id: "",
    },
    validationSchema: object({
      plant: string().required(),
      address1: string().required(),
      address2: string().required(),
      state: string().required(),
      city: string().required(),
      pincode: number().required(),
      country: string().required(),
    }),
    onSubmit: onSave,
  });

  async function onSave(values) {
    try {
      if (ACTION_TYPE.edit) {
        const obj1 = {
          id: form.values.addressId,
          address_line_1: values.address1,
          address_line_2: values.address2,
          city: values.city,
          pincode: values.pincode,
          state: values.state,
          country: values.country,
        };
        const response1 = await addressService.updateAddress(
          form.values.addressId,
          obj1
        );
        // console.log("Response from Updating Address", response1);
        if (response1 && response1.data) {
          const obj2 = {
            id: form.values.id,
            name: values.plant,
            address_id: form.values.addressId,
            client_id: currentUser.client_id,
          };
          const response2 = await plantService.updatePlant(
            form.values.id,
            obj2
          );
          // console.log("Response from Updating Plants", response2);
          if (response2 && response2.data) {
            form.resetForm();
            closeForm();
            updateAlert({
              open: true,
              message: "Plant Updated Sucessfully",
              type: "success",
            });
          }
        }
      } else if (!ACTION_TYPE.edit) {
        const obj1 = {
          id: uuidv4(),
          address_line_1: values.address2,
          address_line_2: values.address2,
          city: values.city,
          pincode: values.pincode,
          state: values.state,
          country: values.country,
        };
        const response1 = await addressService.createAddress(obj1);
        if (response1 && response1.status === 201) {
          const obj2 = {
            id: uuidv4(),
            name: values.plant,
            address_id: response1.data.id,
            client_id: currentUser.client_id,
            created_at: dateService.getDateInSqlFormat(new Date()),
          };
          const response2 = await plantService.createPlant(obj2);
          // console.log("Response from Creating Customers", response2);
          if (response2 && response2.status === 201) {
            updateAlert({
              open: true,
              message: "Plant Created Sucessfully",
              type: "success",
            });
            form.resetForm();
            closeForm();
          }
        }
      }
    } catch (error) {
      updateAlert({
        open: true,
        message: "Failed to update/create plant.",
        type: "error",
      });
    }
  }
  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Plant"
          id="plant"
          value={form.values.plant}
          onChange={form.handleChange}
          error={form.touched.plant && form.errors.plant}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Street Address Line 1"
          id="address1"
          value={form.values.address1}
          onChange={form.handleChange}
          error={form.touched.address1 && form.errors.address1}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Street Address Line 2"
          id="address2"
          value={form.values.address2}
          onChange={form.handleChange}
          error={form.touched.address2 && form.errors.address2}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="State"
          id="state"
          value={form.values.state}
          onChange={form.handleChange}
          error={form.touched.state && form.errors.state}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="City"
          id="city"
          value={form.values.city}
          onChange={form.handleChange}
          error={form.touched.city && form.errors.city}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Pincode"
          id="pincode"
          value={form.values.pincode}
          onChange={form.handleChange}
          error={form.touched.pincode && form.errors.pincode}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Country"
          id="country"
          value={form.values.country}
          onChange={form.handleChange}
          isFormik
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
