/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import ActiveInActive from "../../../../components/ActiveInActive";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import gaugeService from "../../../../services/gaugeService";
import AddIcon from "@material-ui/icons/Add";
import CommonGrid from "../../../../components/CommonGrid";
import { GaugeCharacterForm } from "./GaugeForm";
import StatusFilter from "../../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../../components/DGDrawer";

export function GaugeListing(partId) {
  const [formOpen, setFormOpen] = useState(false);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: getList,
      callback: updategaugeStatus,
    }));
  }, []);



  function updategaugeStatus(dummy1, dummy2, newStatus, rowId) {
    return gaugeService.updateGaugeCharstatus(rowId, { enabled: newStatus });
  }

  async function getList() {
    const res = await gaugeService.getGaugeProductTable(partId.partId);
    setRows(res?.data ?? []);
  }

  let columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",

      width: 100,
    },
    {
      field: "specification",
      headerName: "Specification",
      filter: "agTextColumnFilter",
      floatingFilter: true,
      //rowGroup: true,
      resizable: true,
      minWidth: 300,
      valueGetter: (params) =>

        `${params?.data?.name}  ${params?.data?.specification !== null ? params?.data?.specification : ""
        }   ${params?.data?.upper_specification !== null
          ? params?.data?.upper_specification
          : ""
        }   ${params?.data?.lower_specification !== null
          ? params?.data?.lower_specification
          : ""
        }`
    },

    {
      field: "number",
      headerName: "Gauge",
      filter: "agTextColumnFilter",
      resizable: true,
      floatingFilter: true,
      valueGetter: (params) => `${params?.data?.number} - ${params?.data?.gauge_name}`
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: `${params?.data?.number} - ${params?.data?.gauge_name}`,
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
              callback: updategaugeStatus
            }))

          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.gauge_number ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];
  return (
    <Grid container justifyContent="flex-end" p={4}>
      <Grid item md={12} sm={12}>
        <CommonGrid
          columns={columns}
          gridheight={213}
          rows={rows}
          ButtonComponent={() => (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setFormOpen(!formOpen);
              }}
            >
              GAUGE
            </Button>
          )}
        />

      </Grid>
      <>
        <DGDrawer
          Component={GaugeCharacterForm}
          title="Gauge Adding"
          isOpen={formOpen}
          setOpen={() => {
            setFormOpen(false);
            getList()
          }}
          drawerWidth={800}
          componentProps={{ partId: partId }}
          needSave
        />
      </>
    </Grid>
  );
}
