import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import React from "react";

export function Evaluationfo({
  evaluation_info = [{
    "Equipment Variation (%)": "",
    "Part Variation (%)": "",
    "Appraiser Variation (%)": "",
    "Interaction (%)": ""
  }]
}) {
  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h5" gutterBottom align="center" style={{ backgroundColor: indigo[100] }} >
        EVALUATION
      </Typography>
      <List>
        <Divider />
        <ListItem>
          <ListItemText primary={"Equipment Variation (%)"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={parseFloat(evaluation_info[0]["Equipment Variation (%)"] ?? 0).toFixed(3)}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Part Variation (%)"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={parseFloat(evaluation_info[0]["Part Variation (%)"] ?? 0).toFixed(3)}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Appraiser Variation (%)"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={parseFloat(evaluation_info[0]["Appraiser Variation (%)"] ?? 0).toFixed(3) ?? ""}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText primary={"Interaction (%)"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={parseFloat(evaluation_info[0]["Interaction (%)"] ?? 0).toFixed(3)}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}
