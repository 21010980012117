import {
  Box,
  Button as MuiButton,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  TextareaAutosize as MuiTextareaAutosize,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { AuthContext } from "../../../../context/AuthContextProvider";
import piService from "../../../../services/piService";
import dateService from "../../../../services/dateService";
import uploadService from "../../../../services/uploadService";
import gaugeService from "../../../../services/gaugeService";
import { KeyboardDatePicker } from "@material-ui/pickers";
const { v4: uuidv4 } = require("uuid");
const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Alert = styled(MuiAlert)(spacing);
const Spacer = styled.div(spacing);
const TextField = styled(MuiTextField)(spacing);
const TextareaAutosize = styled(MuiTextareaAutosize)(spacing);

const PICertificateDialog = ({
  addPICertificateDialog,
  setAddPICertificateDialog,
  onSuccess,
  piRequest,
}) => {
  let { currentUser } = useContext(AuthContext);
  const [uploadedCertificate, setUploadedCertificate] = useState();
  const [comments, setComments] = useState("");
  const timeOut = (time) => new Promise((res) => setTimeout(res, time));

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    let uploadResponse = null;
    try {
      await timeOut(1500);
      setStatus({ sent: true });
      setSubmitting(false);
      // console.log('gaugeItem', piRequest, piRequest.gauge_item);
      // console.log("🚀  -> file: PICertificateDialog.js -> line 90 -> PICertificateDialog -> values", uploadedCertificate);
      if (uploadedCertificate) {
        uploadResponse = await uploadService.uploadFile(uploadedCertificate);
        // console.log("Uploading File Response", uploadResponse);
      }

      if (piRequest.gauge_item) {
        let tempDate = values.calibrated_date
          ? new Date(values.calibrated_date).setDate(
              new Date(values.calibrated_date).getDate() +
                parseInt(piRequest.gauge_item.pi_frequency.interval_in_days)
            )
          : new Date().setDate(
              new Date().getDate() +
                parseInt(piRequest?.gauge_item?.pi_frequency.interval_in_days)
            );
        const updateData = {
          pi_last_date: values.calibrated_date
            ? dateService.getDateInSqlFormat(values.calibrated_date)
            : dateService.getDateInSqlFormat(new Date()),
          pi_due_date: dateService.getDateInSqlFormat(tempDate),
          guage_status_id: 3,
        };
        // console.log('updating gauge item data', updateData);
        const response2 = await gaugeService.updateGaugeItem(
          piRequest?.gauge_item?.id,
          updateData
        );
        // console.log('updating gauge item response', response2);
      }
      const obj = {
        id: uuidv4(),
        gauge_id: piRequest.guage_id,
        created_date: dateService.getDateInSqlFormat(new Date()),
        status: "pending",
        certificate_link:
          uploadResponse &&
          uploadResponse.data &&
          uploadResponse.data.files &&
          uploadResponse.data.files.length
            ? uploadResponse.data.files[0].location
            : null,
        pi_request_id: piRequest.id,
        calibration_comments: comments,
        calibrated_date: values.calibrated_date
          ? dateService.getDateInSqlFormat(values.calibrated_date)
          : dateService.getDateInSqlFormat(new Date()),
        created_by: currentUser.id,
      };
      const response = await piService.createPIResult(obj);
      // console.log("Creating PI Result response", response);
      if (response && response.data) {
        onSuccess(true);
        resetForm();
        setAddPICertificateDialog(false);
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      // console.log('error in upload certificate/create pi result');
    }
  };

  return (
    <>
      <Dialog
        open={addPICertificateDialog}
        onClose={() => setAddPICertificateDialog(false)}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        fullWidth
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#0d51ca",
            color: "#fff",
          }}
        >
          <DialogTitle id="form-dialog-title">
            Upload the Calibration Result Certificate below
          </DialogTitle>
          <div>
            <Button onClick={() => setAddPICertificateDialog(false)}>
              <CloseRoundedIcon style={{ fill: "#fff" }} />
            </Button>
          </div>
        </div>
        <DialogContent pb={4}>
          <Spacer my={6} />
          <Formik
            initialValues={{ id: "", calibrated_date: null }}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <Card mb={6}>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <DropzoneArea
                          showFileNamesInPreview={true}
                          showFileNames={true}
                          showAlerts={true}
                          onChange={(file) => setUploadedCertificate(file)}
                          acceptedFiles={["image/*", "application/*"]}
                          maxSize={10971520} 
                          
                        />
                      </Grid>
                      <Grid item md={12}>
                        <div
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.3)",
                            borderRadius: "5px",
                          }}
                        >
                          <KeyboardDatePicker
                            style={{ width: "100%" }}
                            name="calibrated_date"
                            margin="normal"
                            id="date-picker-dialog"
                            label="Calibrated Date"
                            format="MM/dd/yyyy"
                            value={
                              values.calibrated_date
                                ? new Date(values.calibrated_date)
                                : null
                            }
                            onChange={(value) => {
                              // console.log("Date:", value);
                              setFieldValue("calibrated_date", value);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <TextareaAutosize
                          required
                          aria-label="comments"
                          placeholder="Add your comments here"
                          minRows={6}
                          style={{
                            width: "100%",
                            borderColor: "#bbb",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Divider my={6} />
                    <div className="d-flex justify-content-end">
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        mr={3}
                        mb={3}
                        position="right"
                        onClick={() => setAddPICertificateDialog(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mb={3}
                        position="right"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </Card>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PICertificateDialog;
