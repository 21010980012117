import React, { useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";

import GembaMachineMaintenanceList from "./machineMainteannceList";

export function GembaMachineMaintenance({ setSelectedCard = () => { } }) {
    //const [subtitle, setSubtitle] = useState("");
    const [listOpen, setListOpen] = useState(true);

    return (
        <>
            {" "}

            <>
                {" "}
                <DGDrawer
                    Component={GembaMachineMaintenanceList}
                    isOpen={listOpen}
                    setOpen={(state) => {
                        setListOpen(state);
                        setSelectedCard(-1);
                    }}
                    drawerWidth={1300}
                    title="Machine Maintenance"
                    needSave
                />
            </>
            {/*             <DGDrawer
                Component={MacineMaintenanceRequestCreate}
                isOpen={requestFormOpen}
                setOpen={(state, reqID) => {

                    if (reqID) {
                        setRequestFormOpen(state);
                        setSelectedRow_(reqID);
                        setRecordFormOpen(true);
                    } else {
                        setRequestFormOpen(state);
                        setSelectedCard(-1);
                    }
                }

                }
                title="Maintenance Request"
                drawerWidth={800}


            />

            <DGDrawer
                isOpen={recordFormOpen}
                setOpen={(state) => {
                    setRecordFormOpen(false);
                    if (!state) {
                        setSelectedCard(-1);
                    }
                }}
                drawerWidth={1200}
                rowHeight={150}
                Component={MachineMaintenanaceRequestView}
                componentProps={selectedRow_?.id}

            /> */}
        </>
    );
}
