import {
  Button,
  Grid,
  Paper,
  TextField as MuiTextField,
  Typography,
  Card,
} from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContextProvider";
import { saveUserLocal, setAccessToken } from "../../services/authService";
import otpService from "../../services/otpService";
import digitalGembaLogo from "../../assets/images/digitalGembaLogo.png";
import userService from "../../services/userService";
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

const SignIn = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [wrongOTP, setWrongOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [shortPhoneNumber, setShortPhoneNumber] = useState();
  const Spacer = styled.div(spacing);
  const history = useHistory();
  const { loginUser } = useContext(AuthContext);

  const onVerifyOTP = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const obj = {
        strategy: "local",
        phone_number: phoneNumber,
        otp: values.otp,
      };
      const response = await otpService.verifyOTP(obj);

      if (response && response.status === 201) {
        checkValiduser(response);
      } else {
        setWrongOTP(true);
      }
    } catch (error) {
      console.log(error);
      const message = "Invalid OTP";
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  function checkValiduser(response) {
    userService.UsersInfoCheck(response.data.user?.id).then(res => {
      if (res?.data?.user_info_required) {
        history.push("/create-account",
          {
            user: response.data.user,
            token: response.data.accessToken
          });
      }
      else {
        setAccessToken(response.data.accessToken);
        saveUserLocal(JSON.stringify(response.data.user));
        loginUser(response.data.user);
        if (response.data?.user?.user_role === 5) {
          history.push("/gemba-dashboard");
        } else {
          history.push("/overview");
        }
      }

    })
  }

  const onGenerateOTP = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const obj = {
        phone_number: values.phone_number,
      };
      const response = await otpService.createOTP(obj);
      setPhoneNumber(values.phone_number);
      setShortPhoneNumber(values.phone_number.slice(6));
      if (response && response.status === 201) {
        if (response.data.success === true) {
          setVerifyOTP(true);
        } else if (response.data.success === false) {
          setShowMessage(true);
        }
      } else if (response && response.status !== 201) {
        setShowMessage(true);
      }
    } catch (error) {
      const message = error.message || "Something went wrong";
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  };

  return (
    <Paper elevation={10} style={{ width: "100%" }}>
      <Wrapper>
        <Helmet title="Login" />

        {showMessage && !verifyOTP && (
          <>
            <img
              src={digitalGembaLogo ? digitalGembaLogo : ""}
              width="110rem"
              height="55rem"
              alt=""
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: "2rem",
              }}
            />
            <div style={{ textAlign: "center" }}>
              <p>
                Sorry you do not have permission to login, either you or your
                company/organization should have a valid subscription to proceed
                with login.
              </p>
            </div>
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              To Buy <b>Digital Gemba</b> Subscriptions <br />
              Contact: +91 9739934669 <br />
              Email: solutions@digitalgemba.in
            </div>
          </>
        )}

        {wrongOTP && (
          <>
            <Typography component="h2" variant="body1" align="center">
              <CancelOutlinedIcon
                style={{ fontSize: 60, color: "#FF0000", margin: "auto" }}
              />
            </Typography>
            <Typography component="h3" variant="body1" align="center">
              Sorry....You have entered the incorrect OTP
            </Typography>
          </>
        )}

        {!showMessage && !verifyOTP && (
          <Card>
            <img
              src={digitalGembaLogo ? digitalGembaLogo : ""}
              width="110rem"
              height="55rem"
              alt=""
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: "2rem",
              }}
            />
            <Typography component="h1" align="center">
              <b>Login to your account to continue</b>
            </Typography>
            <Spacer mb={6} />
            <Formik
              initialValues={{
                phone_number: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                phone_number: Yup.string()
                  .min(10)
                  .max(10)
                  .required("Phone Number is required"),
              })}
              onSubmit={onGenerateOTP}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    pt={3}
                    pb={3}
                  >
                    <TextField
                      type="text"
                      name="phone_number"
                      label="Enter your mobile number"
                      value={values?.phone_number}
                      error={Boolean(
                        touched.phone_number && errors.phone_number
                      )}
                      fullWidth
                      helperText={touched.phone_number && errors.phone_number}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      variant="outlined"
                    />
                    {/*  <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    /> */}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ margin: "1rem" }}
                      disabled={isSubmitting}
                    >
                      Get OTP
                    </Button>
                  </Grid>

                  {errors.submit && (
                    <Alert mt={5} severity="error">
                      {errors.submit}
                    </Alert>
                  )}
                </form>
              )}
            </Formik>
          </Card>
        )}

        {verifyOTP && (
          <Card>
            <img
              src={digitalGembaLogo ? digitalGembaLogo : ""}
              width="110rem"
              height="55rem"
              alt=""
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "100%",
                marginBottom: "2rem",
              }}
            />
            <Typography component="h2" variant="body1" align="center">
              An OTP has been sent to <br />
              XXX XXX {shortPhoneNumber}
            </Typography>
            <Spacer mb={6} />
            <Formik
              initialValues={{
                otp: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                otp: Yup.string().min(6).max(6).required("OTP is required"),
              })}
              onSubmit={onVerifyOTP}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    pt={3}
                    pb={3}
                  >
                    <TextField
                      type="text"
                      name="otp"
                      label="Enter OTP"
                      value={values.otp}
                      error={Boolean(touched.otp && errors.otp)}
                      fullWidth
                      helperText={touched.otp && errors.otp}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      style={{ margin: "1rem" }}
                    >
                      Verify & Proceed
                    </Button>
                  </Grid>

                  {errors.submit && (
                    <Alert mt={5} severity="error">
                      {errors.submit}
                    </Alert>
                  )}
                </form>
              )}
            </Formik>
          </Card>
        )}
      </Wrapper>
    </Paper>
  );
};

export default SignIn;
