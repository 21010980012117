import {
  customerProtectedRoutes,
  customersMenuRoutes,
  protectedRoutes,
  sidebarRoutes,
} from "../routes";

export function hasErrors(payload) {
  return !Object.values(payload).every((value) => !!value);
}

export function getErrors(payload) {
  let resultObj = {};
  Object.keys(payload).map((exp) => {
    if (!payload[exp]) {
      resultObj[exp] = true;
    } else {
      resultObj[exp] = false;
    }
  });
  return resultObj;
}

export function GetRequiredUserMenus(flag) {
  if (flag === "flag_4") {
    return customersMenuRoutes;
  } else {
    return sidebarRoutes;
  }
}

export function GetRequiredProtectedRoutes(flag) {
  if (flag === "flag_4") {
    return customerProtectedRoutes;
  } else {
    return protectedRoutes;
  }
}
