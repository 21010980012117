import {
    Box,
    CircularProgress,
    Grid, Paper, FormGroup, Radio, RadioGroup,
    IconButton, Container,
    makeStyles,
    Typography,
    Hidden,
} from "@material-ui/core";
import React, { useContext, useEffect, useState, Component } from "react";
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider";
import GaugeCard from "./_metricsCardGauge";
import SimpleSlider from "../../components/SlickSlider";



export function GaugeOverview() {
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);

    const [dataDepth, setdataDepth] = useState('')
    const [dataLabel, setdataLabel] = useState('')
    const [Offset_index, setOffset_index] = useState(0)
    const [plant_id, setPlantId] = useState('')

    //  console.log("default plant", currentUser)
    //  console.log("global plant", globalPlant ? globalPlant["globalPlant"]["id"] : "undefined");


    useEffect(() => {
        if (currentUser.default_plant !== null) {
            setPlantId(currentUser.default_plant);
        } else if (globalPlant && globalPlant["globalPlant"] && globalPlant["globalPlant"]["id"]) {
            setPlantId(globalPlant["globalPlant"]["id"]);
        }
    }, []);

    useEffect(() => {
        if (globalPlant && globalPlant["globalPlant"] && globalPlant["globalPlant"]["id"]) {
            setPlantId(globalPlant["globalPlant"]["id"]);
        } else {
            // Handle the case where the properties don't exist or are undefined
        }
    }, [globalPlant]);


    /*     useEffect(() => {
            if (currentUser.user_role_id > 3) { setdataDepth(1); setdataLabel("Day") }
            else if (currentUser.user_role_id >= 2 && currentUser.user_role_id <= 3) { setdataDepth(2); setdataLabel("Week") }
            else if (currentUser.user_role_id >= 1 && currentUser.user_role_id <= 2) { setdataDepth(3); setdataLabel("Month") }
        }, [currentUser.user_role_id]);
    
        useEffect(() => {
            setDateDisplay(currentDate => {
                let newDate = new Date();
                if (dataDepth === 1) {
                    setstartDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                    setendDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                    newDate = addDays(newDate, Offset_index);
                    setdataLabel("Day");
                }
                else if (dataDepth === 2) {
                    setstartDate(format(startOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                    setendDate(format(endOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                    newDate = addWeeks(newDate, Offset_index);
                    setdataLabel("Week")
                }
                else if (dataDepth === 3) {
                    setstartDate(format(startOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                    setendDate(format(endOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                    newDate = addMonths(newDate, Offset_index);
                    setdataLabel("Month");
                }
                return newDate;
            });
        }, [Offset_index, dataDepth]); */


    const appBarStyle = {
        position: 'sticky',
        backgroundColor: '#D6EAF8',
    };

    /*     const formatDate = (date) => {
            switch (dataDepth) {
                case 1:
                    return format(date, 'dd/MM/yyyy, EEEE');
                case 2:
                    const weekNumber = getWeek(date, { weekStartsOn: 1 });
                    const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 });
                    return `Week-${weekNumber} - ${format(firstDayOfWeek, 'dd/MM/yy')}`;
                case 3:
                    return format(date, 'MMM yyyy');
                default:
                    return '';
            }
        }; */


    return (

        <React.Fragment>
            <Container>
                <div
                    style={{
                        display: 'flex',
                        //flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >

                    <SimpleSlider>
                        <div>
                            <GaugeCard
                                cardName={"Calibration Status"}
                                cardNumber={4}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                            />
                        </div>



                        <div>
                            <GaugeCard
                                cardName={"Calibration Trend"}
                                cardNumber={2}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                            />
                        </div>

                        <div>
                            <GaugeCard
                                cardName={"Gauges"}
                                cardNumber={3}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                            />
                        </div>

                        <div>
                            <GaugeCard
                                cardName={"Gauge Overdue"}
                                cardNumber={6}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                            />
                        </div>



                    </SimpleSlider>
                </div>
            </Container>
        </React.Fragment >

    );
}
