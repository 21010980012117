import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid } from "@material-ui/core";
import CommonGrid from '../../../components/CommonGrid'
import { AuthContext } from '../../../context/AuthContextProvider';
import { PlantContext } from "../../../context/PlantContextProvider";
import { useFormik } from "formik";
import { object, array } from "yup";
import { AlertContext } from "../../../context/AlertContextProvider"
import { DGSelect } from '../../../components/shared/DGSelect';
import productService from "../../../services/productService"
import gaugeService from "../../../services/gaugeService"
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer"
import dateService from '../../../services/dateService';
import characterService from '../../../services/characterService'
import plantService from "../../../services/plantService"
const { v4: uuidv4 } = require("uuid");

export function GaugeProductLink({
    Footer = () => { },
    closeForm = () => { },
}) {
    const { updateAlert } = useContext(AlertContext);
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const { globalPlant } = useContext(PlantContext)


    let form = useFormik({
        initialValues: {
            gridData: [],
            part: null,
            plant: null,
            partOptions: [],
            gaugeOptions: [],
            // gauge: null,
            gridData: [],
            plantOptions: []
        },
        validationSchema: object({
            gridData: array().min(1),

        }),
        onSubmit: onSave
    });

    let tempform = useFormik({
        initialValues: {
            gauge: null,
            gaugeOptions2: []
        },
        validationSchema: object({
            gauge: object().required(),

        }),
        onSubmit: onAdd
    });

    function onClear() {
        form.setFieldValue("gridData", null);
        tempform.setFieldValue("gaugeOption2", form.values.gaugeOptions)
    }


    function onAdd() {
        let data = [];
        data?.push({
            id: uuidv4(),
            part_id: form.values.part?.id,
            gauge_id: tempform.values.gauge?.gauge_id,
            gauge_number: tempform.values.gauge?.gauge_number,
            gauge_name: tempform.values.gauge?.gauge_name,
        });
        form.setFieldValue("gridData", [...form.values.gridData, ...data]);
        tempform.setFieldValue("gauge", null)
    }
    function onGridReady(params) {
        setGridApi(params?.api);
    }


    useEffect(() => {
        plantService.getDefaultPlant(currentUser?.id)
            .then((res) => {
                const plantOptions = (res?.data ?? []).slice(1);
                form.setFieldValue("plantOptions", plantOptions);
            })
    }, []);

    useEffect(() => {
        if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
            form.setFieldValue("plant", (globalPlant?.globalPlant));
            form.setFieldValue("plant_flag", 0)

        } else {
            if (currentUser.default_plant) {
                const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 1)

            } else {
                const defaultPlant = form.values.plantOptions[0]
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 2)
            }
        }
    }, [form.values.plantOptions]);

    // console.log(form.values.plantOptions)

    useEffect(() => {
        var temp2 = form.values.gridData
        if (temp2 !== null) {
            var gaugeIdsNew = new Set(temp2.map(item => item.gauge_id));
            var templist = form.values.gaugeOptions
            var tempgaugeOptions = templist.filter(item => !gaugeIdsNew.has(item.gauge_id));
            tempform.setFieldValue("gaugeOptions2", tempgaugeOptions)
        }
    }, [form.values.gridData]);


    useEffect(() => {
        productService.getAProductByPlant(form.values.plant?.id)
            .then(res => {
                var temp = res.data
                form.setFieldValue("partOptions", temp);
            })
    }, [form.values.plant])


    useEffect(() => {
        const partId = form.values.part?.id
        if (partId !== null && partId !== undefined) {
            gaugeService.getGaugeProductAddNew(
                currentUser?.client_id,
                partId
            ).then(res => {
                var temp1 = res.data
                form.setFieldValue("gaugeOptions", temp1)
                tempform.setFieldValue("gaugeOptions2", temp1)
            })
        }
    }, [form.values.part])


    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...form.values.gridData];
        updatedGridData.splice(rowIndex, 1);
        form.setFieldValue("gridData", updatedGridData);
        gridApi?.updateRowData({ remove: [data] });
    }

    function onSave() {
        let gaugeObj = [];
        gridApi.forEachNode((node) => {
            let data = node?.data;
            gaugeObj.push({
                id: uuidv4(),
                gauge_id: data.gauge_id,
                character_id: null,
                part_id: data.part_id,
                created_by: currentUser.id,
                gauge_id: data.gauge_id,
                created_date: dateService.getDateInSqlFormat(new Date()),
                enabled: 1

            })
        })

        characterService.createGaugeCharacter(gaugeObj).then(res => {
            closeForm();
            updateAlert({ message: "Gauge Added SucessFully", type: "success", open: true });
        }).catch(err => {
            updateAlert({ message: "Failed To Add Gauge", type: "error", open: true })
        })
    }


    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            pinned: "left",
            resizable: true,
        },
        {
            headerName: "Gauge",
            resizable: true,
            minWidth: 200,
            pinned: "left",
            valueGetter: (params) => {
                return `${params.data?.gauge_number} ${params.data?.gauge_name}`
            }
        },]

    return (
        <Grid container spacing={4}>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="plant"
                    label="Plant"
                    options={form.values.plantOptions}
                    getOptionLabel={(option) => option.name}
                    value={form.values.plant}
                    onChange={(val) => {
                        form.setFieldValue("plant", val)
                        form.setFieldValue("part", null)
                        form.setFieldValue("gridData", [])
                    }}
                    required
                />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="part"
                    label="Part"
                    options={form.values.partOptions}
                    getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
                    value={form.values.part}
                    required
                    onChange={(val) => {
                        form.setFieldValue("part", val);
                        form.setFieldValue("gaugeOptions", null);
                        tempform.setFieldValue("gauge", null);
                        tempform.setFieldValue("gaugeOptions2", null);
                    }}
                />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="gauge"
                    label="Gauge"
                    options={tempform.values.gaugeOptions2}
                    getOptionLabel={(opt) => `${opt?.gauge_number}-${opt?.gauge_name}`}
                    value={tempform.values.gauge}
                    onChange={(val) => {
                        tempform.setFieldValue("gauge", val);
                    }}

                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <Button
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                    color="primary"
                    variant="outlined"
                    onClick={tempform.handleSubmit}
                    className="m-2"
                >
                    Add
                </Button>
                {/*                 <Button
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                    color="primary"
                    variant="outlined"
                    onClick={onClear}
                >
                    Clear
                </Button> */}
            </Grid>

            <Grid md={12} sm={12} lg={12}>
                <CommonGrid
                    columns={columns}
                    rows={form.values.gridData?.length ? form.values.gridData : []}
                    needExport={false}
                    onGridReady={onGridReady}
                />
            </Grid>

            <Footer onSave={form.handleSubmit} />
        </Grid>
    );

}