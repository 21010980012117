import axios from "../utils/axios";

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-in", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/sign-up", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/reset-password", credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAccessToken() {
  return localStorage.getItem('access-token');
}

export function setAccessToken(token) {
  return localStorage.setItem('access-token', token);
}

export function saveUserLocal(user) {
  return localStorage.setItem('current-user', user);
}

export function getUserLocal() {
  const user = localStorage.getItem('current-user');
  return user ? JSON.parse(user) : null;
}

export function clearLocalStorage() {
  return localStorage.clear();
}