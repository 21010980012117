import http from "./httpService";

//List customer for each client
const getCustomerClient = async (client_id) => {
  return await http.get("/customer-client-list", {
    params: {
      client_id: client_id,
    },
  });
};

const createCustomerAccess = async (data) => {
  return await http.post("/clientcustomer-access-initiate", data);
};

const getCustomerClientTable = async (client_id) => {
  return await http.get("/clientcustomer-access-table", {
    params: {
      client_id: client_id,
    },
  });
};

const updateCustomerAccess = async (data, id) => {
  return http.patch(`/customer-access/${id}`, data);
};

const updateCustomerPartAccess = async (data, id) => {
  return http.patch(`/customer-part-access/${id}`, data);
};

const getCustomerUser = async (client_id) => {
  return await http.get("/clientcustomer-user-list", {
    params: {
      client_id: client_id,
    },
  });
};

const createCustomerPartAccess = async (data) => {
  return await http.post("/clientcustomer-part-initiate", data);
};

const getClientCustomerProductAccessTable = async (client_id) => {
  return await http.get("/clientcustomer-dashboard-product-access", {
    params: {
      client_id: client_id,
    },
  });
};

// This line onwards "Customer Dashboard"

const getCustomerDashboardProductTable = async (user_id) => {
  return await http.get("/customer-product-table", {
    params: {
      user_id: user_id,
    },
  });
};

const getVendorPDIReport = async (user_id) => {
  return await http.get("/customer-pdi-table", {
    params: {
      user_id: user_id,
    },
  });
};

const getCustomerOverview = async (user_id) => {
  return await http.get("/customer-overview", {
    params: {
      user_id: user_id,
    },
  });
};

const getCustomerMSACCM = async (user_id) => {
  return await http.get("/customer-msa-ccm", {
    params: {
      user_id: user_id,
    },
  });
};

const getCustomerSPCCCM = async (user_id) => {
  return await http.get("/customer-spc-ccm", {
    params: {
      user_id: user_id,
    },
  });
};

const getCustomerCpkCCM = async (user_id) => {
  return await http.get("/customer-capability-ccm", {
    params: {
      user_id: user_id,
    },
  });
};



const getCustomerPICCM = async (user_id) => {
  return await http.get("/customer-pi-ccm", {
    params: {
      user_id: user_id,
    },
  });
};

const getCustomerPITable = async (user_id) => {
  return await http.get("/customer-pi-table", {
    params: {
      user_id: user_id,
    },
  });
};

const getCustomerMSAProduct = async (part_id) => {
  return await http.get("/customer-product-msa", {
    params: {
      part_id: part_id,
    },
  });
};

const getCustomerProductChar = async (part_id) => {
  return await http.get("/customer-product-character", {
    params: {
      part_id: part_id,
    },
  });
};

const getCustomerSPCChar = async (part_id, user_id) => {
  return await http.get("/customer-spc-select1", {
    params: {
      user_id: user_id,
      part_id: part_id,
    },
  });
};

const getCustomerSPCCL = async (part_id, user_id, character_id) => {
  return await http.get("/customer-spc-select2", {
    params: {
      user_id: user_id,
      part_id: part_id,
      character_id: character_id,
    },
  });
};

const getSPCCharPlot = async (part_id, user_id, character_id, spc_input_id) => {
  return await http.get("/customer-spc-char-plot", {
    params: {
      user_id: user_id,
      part_id: part_id,
      character_id: character_id,
      spc_input_id: spc_input_id,
    },
  });
};

const getSPCCharTable = async (
  part_id,
  user_id,
  character_id,
  spc_input_id
) => {
  return await http.get("/customer-spc-char-table", {
    params: {
      user_id: user_id,
      part_id: part_id,
      character_id: character_id,
      spc_input_id: spc_input_id,
    },
  });
};

const getCustomerPISelect = async (part_id, user_id) => {
  return await http.get("/customer-pi-select", {
    params: {
      user_id: user_id,
      part_id: part_id,
    },
  });
};

const getCustomerPIPlot = async (part_id, user_id, character_id) => {
  return await http.get("/customer-pi-char-plot", {
    params: {
      user_id: user_id,
      part_id: part_id,
      character_id: character_id,
    },
  });
};

const getCustomerPDISelect = async (part_id, user_id) => {
  return await http.get("/customer-pdi-select", {
    params: {
      user_id: user_id,
      part_id: part_id,
    },
  });
};

const getCustomerPDIPlot = async (part_id, user_id, character_id) => {
  return await http.get("/customer-pi-char-plot", {
    params: {
      user_id: user_id,
      part_id: part_id,
      character_id: character_id,
    },
  });
};



export default {
  getCustomerClient,
  createCustomerAccess,
  getCustomerClientTable,
  updateCustomerAccess,
  getCustomerUser,
  createCustomerPartAccess,
  getCustomerDashboardProductTable,
  getClientCustomerProductAccessTable,
  getVendorPDIReport,
  getCustomerOverview,
  getCustomerMSACCM,
  getCustomerSPCCCM,
  getCustomerPICCM,
  getCustomerMSAProduct,
  getCustomerProductChar,
  getCustomerSPCChar,
  getCustomerSPCCL,
  updateCustomerPartAccess,
  getSPCCharPlot,
  getSPCCharTable,
  getCustomerPIPlot,
  getCustomerPISelect,
  getCustomerPDISelect,
  getCustomerPDIPlot,
  getCustomerCpkCCM,
  getCustomerPITable
};
