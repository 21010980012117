import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import { DGInput } from "../../../../components/shared/DGInput";
import CommonGrid from "../../../../components/CommonGrid";
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer";
import machineService from "../../../../services/machineService";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { DGSelect } from "../../../../components/shared/DGSelect";
import characterService from "../../../../services/characterService";

export function ProcessParameterNewForm({
  Footer = () => null,
  closeForm = () => { },
  componentProps = null,
}) {

  const [GridApiContext, setGridApi] = useState(null);
  const { v4: uuidv4 } = require("uuid");
  const { updateAlert } = useContext(AlertContext);
  useEffect(() => {
    characterService.getCharacterUnits().then((res) => {
      form.setFieldValue("unitoptions", res?.data?.data ?? []);
    });
  }, []);
  let detailForm = useFormik({
    initialValues: {
      parameter_name: "",
      parameter_unit: null,
    },
    validationSchema: object({
      parameter_name: string().required(),
      parameter_unit: object().required(),
    }),
  });

  let form = useFormik({
    initialValues: {
      gridData: [],
      unitoptions: [],
    },
    validationSchema: object({
      gridData: array().min(1),
    }),
    onSubmit: onSave,
  });


  function onSave() {
    let payload = [];
    GridApiContext?.api?.forEachNode((node) => {
      // console.log(node.data);
      // debugger;
      payload.push({
        parameter_name: node?.data?.parameter_name ?? "",
        parameter_unit: node?.data?.parameter_unit,
      });
    });
    //debugger;
    machineService
      .createMachineParameter(
        payload.map((exp) => ({
          id: uuidv4(),
          created_by: componentProps.user_id,
          machine_id: componentProps.machine_id,
          parameter_name: exp?.parameter_name,
          parameter_unit: exp?.parameter_unit?.id,
        }))
      )
      .then((res) => {
        closeForm();
        updateAlert({
          open: true,
          message: "Process parameter created Successfully.",
          type: "success",
        });
      })
      .catch(() => {
        updateAlert({
          open: true,
          message: "Failed to create process parameter",
          type: "error",
        });
      });
  }
  function onAdd() {
    form.setFieldValue("gridData", [
      ...form.values.gridData,
      detailForm.values,
    ]);
    detailForm.resetForm();
  }

  function removeDetailRow(index, rowData) {
    let data = form.values.gridData;
    data.splice(index, 1);
    GridApiContext.api.updateRowData({ remove: [rowData] });
    form.setFieldValue("gridData", data);
  }

  function onGridReady(params) {
    setGridApi(params);
  }

  const columns = [
    {
      cellRenderer: DeleteRenderer,
      minWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
    },
    {
      field: "parameter_name",
      headerName: "Process Parameter",
      minWidth: 400,
    },
    {
      field: "parameter_unit.name",
      headerName: "Parameter Unit",
      minWidth: 400,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          required
          label="Process parameter"
          value={detailForm.values.parameter_name}
          onChange={detailForm.handleChange}
          id="parameter_name"
          isFormik
          error={
            detailForm.touched.parameter_name &&
            detailForm.errors.parameter_name
          }
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGSelect
          required
          label="Units"
          value={detailForm.values.parameter_unit}
          onChange={(val) => detailForm.setFieldValue("parameter_unit", val)}
          id="parameter_unit"
          isFormik
          options={form.values.unitoptions}
          error={
            detailForm.touched.parameter_unit &&
            detailForm.errors.parameter_unit
          }
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <Button
          size="small"
          style={{ margin: 2 }}
          variant="outlined"
          color="primary"
          disabled={!detailForm.isValid || !detailForm.dirty}
          onClick={onAdd}
        >
          ADD
        </Button>

      </Grid>
      <Grid item md={12} lg={12} sm={12}>
        <CommonGrid
          rows={form.values.gridData}
          columns={columns}
          needExport={false}
          onGridReady={onGridReady}
        />
      </Grid>

      <Footer onSave={form.handleSubmit} />

    </Grid>

  );
}
