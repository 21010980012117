
const getDateInSqlFormat = (inDate) => {
// console.log("🚀 ~ file: dateService.js ~ line 3 ~ getDateInSqlFormat ~ date", typeof inDate, inDate)
  if (!inDate || (typeof inDate !== 'string' && inDate === '')) {
    return null;
  }
  /* if (typeof inDate === "string" && date !== "") {
    date = new Date(date);
  } */
  let date = new Date(inDate);
  const dateString = date.getFullYear() + "-" +
    pad2(date.getMonth() + 1) + "-" +
    pad2(date.getDate()) + " " +
    pad2(date.getHours()) + ":" +
    pad2(date.getMinutes()) + ":" +
    pad2(date.getSeconds());
  return dateString;
};

const pad2 = (number) => {
    let str = '' + number;
    while (str.length < 2) {
      str = '0' + str;
    }
    return str;
};


// eslint-disable-next-line import/no-anonymous-default-export
export default { getDateInSqlFormat };
  