import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ACTION_TYPE } from "./commonenum";
import "./DGSelect.css";
import { CircularProgress } from "@material-ui/core";

export function DGSelect({
  Id = "",
  autoSelect = false,
  disableClearable = false,
  freeSolo = false,
  error = false,
  options = [{ name: "None", id: "-1" }],
  variant = "filled",
  getOptionLabel = (opt) => opt?.name ?? "",
  label = "",
  value = "",
  onChange = () => {},
  required = false,
  isAsynchronus = false,
  asyncCallback = () => {
    return Promise.resolve();
  },
  setOptions = () => {},
  getOptionDisabled = (opt) => opt?.id < 0,
  disabled = false,
  size = "medium",
}) {
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    if (isAsynchronus) {
      asyncCallback();
    }
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <>
      {ACTION_TYPE.view ? (
        <TextField
          label={label}
          variant={variant}
          InputProps={
            ACTION_TYPE?.view
              ? {
                  style: { background: "#fff", color: "#000" },
                  disableUnderline: true,
                }
              : null
          }
          inputProps={ACTION_TYPE?.view ? { style: { border: "0px" } } : {}}
          InputLabelProps={
            ACTION_TYPE?.view ? { style: { color: "#000" } } : {}
          }
          value={getOptionLabel(value)}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          disabled={ACTION_TYPE.view}
          required={required}
        />
      ) : (
        <Autocomplete
          id={Id}
          autoSelect={autoSelect}
          options={options}
          size={size}
          getOptionLabel={getOptionLabel}
          fullWidth
          value={value}
          onChange={(e, val) => onChange(val)}
          required={required}
          freeSolo={freeSolo}
          disableClearable={disableClearable}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          getOptionDisabled={getOptionDisabled}
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              variant={variant}
              required={required}
              style={{ cursor: "pointer" }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    </>
  );
}
