import {
  AppBar,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
  Toolbar,
  Typography as MuiTypography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { AuthContext } from "../../context/AuthContextProvider";
import customerService from "../../services/customerService";
import Lottie from "lottie-react-web";
import animationData from "../../assets/animation/gears-loading.json";
import AllCustomersList from "./AllCustomersList";
import AddIcon from "@material-ui/icons/Add";
import userService from "../../services/userService";
import { DGDrawer } from "../../components/DGDrawer";
import { CustomerForm } from "./CustomerForm";

const AllCustomersContainer = () => {
  const Typography = styled(MuiTypography)(spacing);
  const Button = styled(MuiButton)(spacing);
  const [addCustomerDialog, setAddCustomerDialog] = useState(false);
  const [customers, setCustomers] = useState();
  const { currentUser } = useContext(AuthContext);
  const [isKeyUser, setIsKeyUser] = useState(false);

  useEffect(() => {
    if (currentUser) {
      getAllCustomers();
      getUserPermissions();
    }
  }, [currentUser]);

  const getUserPermissions = async () => {
    const response = await userService.getUserRole(currentUser.id);
    if (response?.data?.data?.length && response?.data?.data[0]?.role_id <= 3) {
      setIsKeyUser(true);
    }
  };

  const getAllCustomers = async () => {
    try {
      const response = await customerService.getCustomers(
        currentUser.client_id,
        true
      );
      if (response?.data?.data) {
        setCustomers(response.data.data);
      }
    } catch (error) {}
  };

  function ButtonComponent() {
    return (
      <>
        {" "}
        {isKeyUser ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setAddCustomerDialog(true)}
          >
            CUSTOMER
          </Button>
        ) : null}
      </>
    );
  }
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <AllCustomersList
            customers={customers}
            getCustomers={() => getAllCustomers()}
            ButtonComponent={ButtonComponent}
          />
        </Grid>
      </Grid>
      <DGDrawer
        Component={CustomerForm}
        title="Add Customer"
        isOpen={addCustomerDialog}
        setOpen={() => {
          setAddCustomerDialog(false);
          getAllCustomers();
        }}
        needSave
        componentProps={{}}
      />
    </React.Fragment>
  );
};

export default AllCustomersContainer;
