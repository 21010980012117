import { Grid, makeStyles, Typography } from "@material-ui/core";
import {
  blue,
  brown,
  green,
  indigo,
  red,
  yellow,
} from "@material-ui/core/colors";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import dashboardService from "../../../services/dashboardService";
import { PlantContext } from "../../../context/PlantContextProvider";

export function GaugeFilter({ onClick = () => { } }) {
  const classes = useStyles();
  const [filters, setFilters] = useState([
    "Active",
    "Scrapped",
    "Not Traceable",
    "Product not in Production",
    "Quarantine/Repair",
  ]);
  const { globalPlant } = useContext(PlantContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (globalPlant?.globalPlant) {
      loadGaugeStatus(globalPlant?.globalPlant?.id);
    }
  }, [globalPlant]);

  function loadGaugeStatus(plantId) {
    dashboardService
      .gaugeDash({
        client_id: currentUser.client_id,
        graph_type: 3,
        plant_id: plantId
      })
      .then((res) => {
        setFilters(res?.data ?? []);
        onClick(res?.data[0]?.status_id, res?.data[0]?.status, plantId);
      });
  }



  function getClass(status) {
    switch (status) {
      case "Active":
        return classes.Active;
        break;
      case "Product not in production":
        return classes["Pnp"];
        break;
      case "Quarantine / Repair":
        return classes?.["Qr"];
        break;
      case "Scrapped":
        return classes?.Scrapped;
        break;
      case "Not Traceable":
        return classes?.["Nt"];
        break;
      default:
        return classes?.Active;
        break;
    }
  }

  return (
    <>
      {filters.map((exp, i) => (
        <Grid
          item
          container
          direction="column"
          md={2}
          sm={2}
          lg={2}
          className={`${getClass(exp?.status)} ${classes.common}`}
          justifyContent="space-between"
          onClick={() =>
            onClick(exp?.status_id, exp?.status, globalPlant?.globalPlant?.id)
          }
        >
          <Grid item>
            <Typography align="left" variant="h6" className={classes.header}>
              {exp?.status}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.count} variant="h3">
              {exp?.count_}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

const useStyles = makeStyles({
  common: {
    color: "#fff",
    borderRadius: 15,
    cursor: "pointer",
    width: "100%",
  },
  Active: {
    background: green[100],
  },
  Pnp: {
    background: indigo[100],
  },
  Scrapped: {
    background: blue[100],
  },
  Qr: {
    background: yellow[100],
  },
  Nt: {
    background: red[100],
  },
  header: {
    color: "#000",
    padding: 15,
    paddingBottom: 5,
  },
  subtitle: {
    color: "#0000008A",
    padding: 15,
    paddingRight: 0,
    paddingBottom: 8,
    paddingTop: 0,
  },
  count: {
    color: "#000",
    padding: 15,
    paddingTop: 0,
  },
  percentage: {
    color: "#000",
  },
});
