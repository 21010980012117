import React, { useState, useEffect } from "react";
import { Paper } from "@material-ui/core";
import { JsonToTable } from "react-json-to-table";
import { Typography } from "@material-ui/core";

const CardsForResult = ({ title, studyType, studyData }) => {
  const [resultValues, setResultValues] = useState();

  useEffect(() => {
    if (studyData) {
      setResultData();
      // console.log('STUDY DATA', studyData);
    }
  }, [studyData, studyType, title]);

  const setResultData = () => {
    if (studyType === "Gauge Capability") {
      let items = [];
      items.push({
        "Sl No": "1",
        "Test Parameter": "Resolution",
        Target: studyData?.target_resolution
          ? parseFloat(studyData.target_resolution).toFixed(2) + "%"
          : "",
        Actual: studyData?.result?.resolution
          ? parseFloat(studyData.result.resolution).toFixed(2) + "%"
          : "",
        Comments:
          studyData?.result?.resolution && studyData?.target_resolution
            ? parseFloat(studyData.result.resolution) >=
              parseFloat(studyData.target_resolution)
              ? "Does not meets the requirements"
              : "Meets the requirements"
            : "",
      });
      items.push({
        "Sl No": "2",
        "Test Parameter": "Bias",
        Target: "Bias Test < Bias Critical",
        Actual:
          studyData?.result?.bias_test && studyData?.result?.bias_critical
            ? parseFloat(studyData.result.bias_test).toFixed(3) +
              " < " +
              parseFloat(studyData.result.bias_critical).toFixed(3)
            : "",
        Comments:
          studyData?.result?.bias_test && studyData?.result?.bias_critical
            ? parseFloat(studyData.result.bias_test) >=
              parseFloat(studyData.result.bias_critical)
              ? "Not Significant"
              : "Significant"
            : "",
      });
      items.push({
        "Sl No": "3",
        "Test Parameter": "Cg",
        Target: studyData?.target_cg
          ? "Atleast " + parseFloat(studyData.target_cg).toFixed(2)
          : "",
        Actual:
          studyData?.result?.CI_higher &&
          studyData?.result?.cg &&
          studyData?.result?.CI_higher
            ? parseFloat(studyData.result.CI_higher).toFixed(2) +
              " ≤ " +
              parseFloat(studyData.result.cg).toFixed(2) +
              " ≤ " +
              parseFloat(studyData.result.CI_higher).toFixed(2)
            : "",
        Comments:
          studyData?.result?.cg && studyData?.target_cg
            ? parseFloat(studyData.result.cg) >= parseFloat(studyData.target_cg)
              ? "Not Significant"
              : "Significant"
            : "",
      });
      items.push({
        "Sl No": "4",
        "Test Parameter": "Cgk",
        Target: studyData?.target_cgk
          ? "Atleast " + parseFloat(studyData.target_cgk).toFixed(2)
          : "",
        Actual:
          studyData?.result?.cgk_CI_lower &&
          studyData?.result?.cgk_CI_higher &&
          studyData?.result?.Cgk
            ? parseFloat(studyData.result.cgk_CI_lower).toFixed(2) +
              " ≤ " +
              parseFloat(studyData.result.Cgk).toFixed(2) +
              " ≤ " +
              parseFloat(studyData.result.cgk_CI_higher).toFixed(2)
            : "",
        Comments:
          studyData?.result?.Cgk && studyData?.target_cgk
            ? parseFloat(studyData.result.Cgk) >=
              parseFloat(studyData.target_cgk)
              ? "Meets the requirements"
              : "Does not meets the requirements"
            : "",
      });
      setResultValues(items);
    } else if (studyType === "Gauge BIAS Analysis") {
      let items = [];
      items.push({
        "Sl No": "1",
        "Test Parameter": "Bias",
        Target: "Avg Bias < Confidence bounds",
        Actual:
          studyData?.result?.CI_bias_lower && studyData?.result?.CI_bias_higher
            ? parseFloat(studyData.result.CI_bias_lower).toFixed(3) +
              " ≤ 0 ≤ " +
              parseFloat(studyData.result.CI_bias_higher).toFixed(3)
            : "",
        Comments:
          studyData?.result?.CI_bias_higher && studyData?.result?.CI_bias_lower
            ? parseFloat(studyData.result.CI_bias_higher) >
                parseFloat(studyData.result.CI_bias_lower) ||
              (parseFloat(studyData.result.CI_bias_higher) === 0 &&
                parseFloat(studyData.result.CI_bias_lower) === 0)
              ? "Significant"
              : "Not Significant"
            : "",
      });
      setResultValues(items);
    } else if (
      studyType === "GRR Study (Without operator influence)" ||
      studyType === "GRR Study (With operator influence)"
    ) {
      let items = [];
      items.push({
        "Sl No": "1",
        "Test Parameter": "Resolution",
        Target:
          studyData?.target_resolution && studyData?.result?.resolution
            ? "Max " + parseFloat(studyData.target_resolution).toFixed(2) + "%"
            : "",
        Actual: studyData?.result?.resolution
          ? parseFloat(studyData.result.resolution).toFixed(2) + "%"
          : "",
        Comments:
          studyData?.result?.resolution && studyData?.target_resolution
            ? parseFloat(studyData.result.resolution) <=
              parseFloat(studyData.target_resolution)
              ? "Meets the requirements"
              : "Does not meets the requirements"
            : "",
      });
      items.push({
        "Sl No": "2",
        "Test Parameter": "GRR (% of Tolerance)",
        Target: studyData?.target_GRR
          ? "Max " + parseFloat(studyData.target_GRR).toFixed(2) + "%"
          : "",
        Actual: studyData?.result?.GRR
          ? parseFloat(studyData.result.GRR).toFixed(2) + "%"
          : "",
        Comments:
          studyData?.result?.GRR &&
          studyData?.target_GRR &&
          studyData?.secondary_target_GRR
            ? parseFloat(studyData.result.GRR) <=
              parseFloat(studyData.target_GRR)
              ? "GRR meets the requirements"
              : parseFloat(studyData.result.GRR) <=
                parseFloat(studyData.secondary_target_GRR)
              ? "GRR conditionally meets requirements"
              : "GRR does not meets the requirements"
            : "",
      });
      items.push({
        "Sl No": "3",
        "Test Parameter": "NDC",
        Target: studyData?.target_ndc ? "Atleast " + studyData.target_ndc : "",
        Actual: studyData?.result?.ndc ? studyData.result.ndc : "",
        Comments:
          studyData?.target_ndc && studyData?.result?.ndc
            ? parseFloat(studyData.result.ndc) >=
              parseFloat(studyData.target_ndc)
              ? "NDC meets Requirement"
              : "NDC does not meets requirements"
            : "",
      });
      setResultValues(items);
    } else if (studyType === "Attribute system analysis: Signal Method") {
      let items = [];
      items.push({
        "Sl No": "1",
        "Test Parameter": "GRR %",
        Target: studyData?.result?.grr ? "Max " + studyData.result.grr : "",
        Actual: studyData?.result?.grr ? studyData.result.grr : "",
        Comments:
          studyData?.result?.grr && studyData?.result?.target_GRR
            ? parseFloat(studyData.result.grr) >=
              parseFloat(studyData.result.target_GRR)
              ? "GRR meets requirement"
              : "GRR does not meets requirements"
            : "",
      });
      items.push({
        "Sl No": "2",
        "Test Parameter": "Cohen's Kappa",
        Target: studyData?.target_kappa
          ? "Atleast " + studyData.target_kappa
          : "",
        Actual: studyData?.result?.kappa_value
          ? studyData.result.kappa_value
          : "",
        Comments:
          studyData?.result?.kappa_value && studyData?.target_kappa
            ? parseFloat(studyData.result.kappa_value) >=
              parseFloat(studyData.target_kappa)
              ? "Kappa meets requirement"
              : "Kappa does not meets requirements"
            : "",
      });
      //setResultValues(items);
    } else if (studyType === "Attribute system analysis: Kappa Method") {
      let items = [];
      items.push({
        "Sl No": "1",
        "Test Parameter": "Fleis's Kappa",
        Target: studyData?.target_kappa
          ? "Atleast " + studyData.target_kappa
          : "",
        Actual: studyData?.result?.kappa_val ? studyData.result.kappa_val : "",
        Comments:
          studyData?.result?.kappa_val &&
          studyData?.target_kappa &&
          studyData?.secondary_target_kappa
            ? parseFloat(studyData.result.kappa_val) >=
              parseFloat(studyData.target_kappa)
              ? "Meets requirement"
              : parseFloat(studyData.result.kappa_val) >=
                parseFloat(studyData.secondary_target_kappa)
              ? "Conditionally meets requirement"
              : "Does not meets requirement"
            : "",
      });
      setResultValues(items);
    }
  };

  return (
    <React.Fragment>
      {resultValues ? (
        <Paper
          variant="outlined"
          square
          elevation={0}
          style={{ width: "100%" }}
        >
          <Typography variant="h6" style={{ padding: 8 }} className="bg-light">
            {title}
          </Typography>
          <JsonToTable json={resultValues} />
        </Paper>
      ) : null}
    </React.Fragment>
  );
};

export default CardsForResult;
