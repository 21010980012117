import React, { useContext, useEffect, useState, useRef } from "react";

import { Grid, Typography, Slider, Divider } from "@material-ui/core";
import { OverviewCard2 } from './dashboardCard'

import dashboardService from '../../services/dashboardService'
import errorLog from "../../services/errorLog";
import {
    format, addDays, addWeeks, addMonths, getWeek, startOfWeek,
    startOfMonth, endOfMonth, endOfWeek
} from 'date-fns'

const CCMDay = (props) => {
    const { v4: uuidv4 } = require("uuid");
    const [SlDayValue, setSlDayValue] = useState(0)
    const [Metrics, setMetrics] = useState([])
    const [piMetrics, pispcMetrics] = useState(0)
    const [DateDisplay, setDateDisplay] = useState(new Date())
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [dataLabel, setdataLabel] = useState('')
    const dataDepth = props.dataDepth
    const Offset_index = props.Offset_index
    const runeffect = props.runeffect
    const [guages, setGuages] = useState([]);


    useEffect(() => {
        setDateDisplay(currentDate => {
            let newDate = new Date();
            if (dataDepth === 1) {
                setstartDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                setendDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                newDate = addDays(newDate, Offset_index);
                setdataLabel("Day");
            }
            else if (dataDepth === 2) {
                setstartDate(format(startOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                setendDate(format(endOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                newDate = addWeeks(newDate, Offset_index);
                setdataLabel("Week")
            }
            else if (dataDepth === 3) {
                setstartDate(format(startOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                setendDate(format(endOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                newDate = addMonths(newDate, Offset_index);
                setdataLabel("Month");
            }
            return newDate;

        });
    }, [dataDepth, Offset_index]);



    useEffect(() => {
        if (startDate !== null && startDate !== '') {
            dashboardService.ccmDashboard({
                client_id: props.client_id,
                plant_id: props.plant_id,
                start_date: startDate,
                end_date: endDate,
                ccmtype: props.cardNumber,
                dataDepth: props.dataDepth
            })
                .then(res => {
                    setMetrics(res.data)
                })
                .catch((err) => {
                    console.error("An error occurred:", err);
                    let payload = {
                        id: uuidv4(),
                        error_description: err.message,
                        error_location: "ccmDashboard",
                        client_id: props.client_id,
                        user_id: null,
                        entity_id: props.cardNumber,
                    };
                    errorLog.createErrorLog(payload)
                });
        }


    }, [startDate, endDate, props.plant_id]);

    return (
        (() => {

            switch (props.cardNumber) {

                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    return (
                        <Grid >
                            <OverviewCard2
                                name={`${props.cardName} (${(dataLabel)})`}
                                mainHeading={Metrics[0] ? Metrics[0].main_head : ''}
                                subHeading={Metrics[0] ? Metrics[0].sub_head : ''}
                                backgroundColor="#73C6B6"
                                totalcount={Metrics[0] ? Metrics[0].total : 0}
                                okcount={Metrics[0] ? Metrics[0].ok : 0}
                                notokcount={Metrics[0] ? Metrics[0].notok : 0}
                                charactercount={Metrics[0] ? Metrics[0].character_count : 0}
                                partcount={Metrics[0] ? Metrics[0].part_count : 0}
                                dataDepth={dataDepth}
                                Component={props.Component}
                                dataLabel={dataLabel}

                            />
                        </Grid>
                    );

                case 400:

                    return (

                        null
                        /*                         <Grid >
                                                    <OverviewCard3
                                                        name={`${props.cardName} (${(dataLabel)})`}
                                                        backgroundColor="#73C6B6"
                                                        tobedonecount={Metrics[0] ? Metrics[0].tobedone : 0}
                                                        ontimecount={Metrics[0] ? Metrics[0].ontime : 0}
                                                        delayedcount={Metrics[0] ? Metrics[0].delayed : 0}
                                                        okcount={Metrics[0] ? Metrics[0].ok : 0}
                                                        notokcount={Metrics[0] ? Metrics[0].notok : 0}
                                                        charactercount={Metrics[0] ? Metrics[0].character_count : 0}
                                                        partcount={Metrics[0] ? Metrics[0].part_count : 0}
                        
                                                    />
                                                </Grid> */
                    );
                default: return null



            }
        })()
    )
};

export default CCMDay;
