import { Button } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import { CheckListFormView } from "./ChecklistFormView";
import { CheckListFormNew } from "./ChecklistFormNew";
import { StatusContext } from "../../../context/StatusContextProvider";

export function ChecklistListing({
  Footer = () => { },
  closeForm = () => { }
}) {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [newForm, setNewForm] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const { setStatus } = useContext(StatusContext);

  function loadForm(data) {
    setSelectedRow(data);
    setFormOpen(true);
  }

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadGridData,
    }));

    reloadGridData();
  }, []);

  function reloadGridData() {
    disptachInspection.getPDIStdTable(currentUser?.client_id).then((res) => {
      setRows(res?.data ?? []);
    });
  }



  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      width: 50,
    },
    {
      headerName: "Standard Checklist Name",
      field: "plan_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => loadForm(params?.data)}
          >
            {`${params?.data?.plan_name}`}
          </Button>
        );
      },
    },
    {
      headerName: "Plant",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },

    /*     {
          field: "enabled",
          headerName: "Status",
          minWidth: 150,
          resizable: true,
          filter: "agNumberColumnFilter",
          floatingFilter: true,
          suppressMenu: true,
          floatingFilterComponent: StatusFilter,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          cellRenderer: (params) => (
            <ActiveInActive
              status={params?.data?.enabled}
              onClick={() =>
                setStatus((prev) => ({
                  ...prev,
                  open: true,
                  callback: changeStatus,
                  reloadGrid: reloadGridData,
                  entityName: params?.data?.dispatch_plan_number ?? "",
                  currentStatus: params?.data?.enabled,
                  rowId: params?.data?.id,
                }))
              }
            />
          ),
        },
        {
          headerName: "Status History",
          resizable: true,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          cellRenderer: (params) => {
            return (
              <Button
                onClick={() =>
                  setStatusHistory((prev) => ({
                    ...prev,
                    isOpen: true,
                    rowId: params?.data?.id,
                    entity: params?.data?.ctool_name ?? "",
                  }))
                }
                size="small"
                color="primary"
              >
                View
              </Button>
            );
          },
        }, */
  ];

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setNewForm(true);
          }}
          style={{ marginLeft: 3 }}
        >
          STANDARD PLAN
        </Button>
      </>
    );
  }




  return (
    <>
      <CommonGrid
        gridheight={135}
        rows={rows}
        columns={columns}
        ButtonComponent={ButtonComponent}
      />
      <DGDrawer
        Component={CheckListFormView}
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        title="Standard CheckList"
        drawerWidth={800}
        needSave={false}
        componentProps={selectedRow}
      />

      <DGDrawer
        Component={CheckListFormNew}
        isOpen={newForm}
        setOpen={() => {
          setNewForm(false);
          reloadGridData();
        }}
        title="Add Standard CheckList"
        needSave={true}
        drawerWidth={1000}

      />
    </>
  );
}
