import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import AddIcon from "@material-ui/icons/Add";
import { deepOrange, orange, red, yellow, } from "@material-ui/core/colors";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import cuttingToolService from "../../../services/cuttingToolService";
import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { CuttingToolNumberAdd } from "./CuttingToolNumberAdd";
import { CtoolStatusManage } from "./CuttingToolStatusManage";


export function CuttingToolNumber() {
    const [rows, setRows] = useState([]);
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [entryFormOpen, setEntryFormOpen] = useState(false);
    const [statusFormOpen, setStatusFormOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [masterRows, setMasterRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { globalPlant } = useContext(PlantContext);
    const { currentUser } = useContext(AuthContext);
    const classes = useStyles();
    const gridApiRef = useRef();
    const [gridFilter, setGridFilter] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [Active_, setActive] = useState(0);
    const [Under_Refurbishing, setUnder_Refurbishing] = useState(0);
    const [Quarantine_, setQuarantine] = useState(0);
    const [Scrapped_, setScrapped] = useState(0);
    const [selected, setSelected] = useState(101);


    setHeaderPageTitle("Cutting Tool List")

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);


    useEffect(() => {
        reloadGridData()
    }, [globalPlant]);

    const statuses = [
        { title: 'Active', background: orange[100], value: Active_, id: 101 },
        { title: 'Under Refurbishing', background: yellow[100], value: Under_Refurbishing, id: 109 },
        { title: 'Quarantine', background: red[100], value: Quarantine_, id: 102 },
        { title: 'Scrapped', background: red[200], value: Scrapped_, id: 103 }
    ];

    function handleClick(id) {
        if (selected === id) {
            setSelected(null);
            updateGridData(null)
            return;
        } else {
            setSelected(id);
            updateGridData(id)
        }
    }

    const StatusGridItem = ({ title, background, value, onClick, selected }) => (
        <Grid item container direction="column" xs={2}
            className={`${classes.common} ${selected ? classes.selected : ''}`}
            onClick={onClick}
            style={{ background }}
        >
            <Typography align="left" variant="h6" className={classes.header}>
                {title}
            </Typography>
            <Typography align="left" className={classes.count} variant="h1">
                {value}
            </Typography>
        </Grid>
    );

    function onGridReady(params) {
        gridApiRef.current = params.api;
        setGridApi(params.api);
    }

    const handleFilterApplied = (event) => {
        const allDisplayedNodes = event.api.getRenderedNodes();
        const displayedData = allDisplayedNodes.map(node => node.data);
        setGridFilter(displayedData);
    };


    useEffect(() => {
        setActive(gridFilter.filter(item => item.ctool_status_id === 101).length);
        setQuarantine(gridFilter.filter(item => item.ctool_status_id === 102).length);
        setScrapped(gridFilter.filter(item => item.ctool_status_id === 103).length);
        setUnder_Refurbishing(gridFilter.filter(item => item.ctool_status_id === 109).length);

    }, [gridFilter]);



    function reloadGridData() {
        cuttingToolService.getCToolNumber
            ({
                plant_id: globalPlant?.globalPlant?.id,
                client_id: currentUser.client_id,
            })
            .then((res) => {
                let temp_data
                if (selected) {
                    temp_data = res.data.filter(item => item.ctool_status_id === selected)
                } else {
                    temp_data = res.data;
                }
                setGridFilter(res.data);
                setMasterRows(res.data);
                setRows(temp_data);
            });
    }

    function updateGridData(id) {
        if (id === null) {
            setRows(masterRows);
            return;
        }
        const temp_data = masterRows.filter(item => item.ctool_status_id === id)
        setRows(temp_data);

    }



    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },


        {
            headerName: "Tool Name",
            field: "ctool_name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 250,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            loadForm(data)
                        }}
                    >
                        {`${params?.data?.ctool_name}`}
                    </Button>
                );
            },
        },


        {
            headerName: "Tool Number",
            field: "ctool_number",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 250,
        },
        {
            headerName: "Status",
            field: "ctool_status",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        {
            headerName: "Tool Life",
            field: "ctoolnumber_life",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.tool_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entity: params?.data?.part_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];



    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return cuttingToolService.updateCToolNumber(rowId, { enabled: newStatus });
    }

    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setEntryFormOpen(1);
                    }}
                >
                    Add Cutting Tool Number
                </Button>

            </>
        );
    }

    function loadForm(data) {
        setSelectedRow(data);
        setStatusFormOpen(true);

    }

    return (
        <>
            <Grid container style={{ paddingBottom: "15px" }} >

                <Grid container justifyContent="space-evenly">
                    {statuses.map((status, index) => (
                        <StatusGridItem
                            key={index}
                            title={status.title}
                            value={status.value}
                            background={status.background}
                            onClick={() => handleClick(status.id)}
                            selected={selected === status.id}

                        />
                    ))}
                </Grid>
            </Grid>



            <Grid item md={12} sm={12} xs={12} justifyContent="flex-end">
                <CommonGrid
                    coldefs={{
                        filter: true,
                        filterParams: {
                            apply: true,
                            newRowsAction: "keep",
                            enabled: { filter: 1 },
                        },
                    }}
                    columns={columns}
                    ButtonComponent={ButtonComponent}
                    rows={rows}
                    gridheight={150}
                    onGridReady={onGridReady}
                    onFilterChanged={handleFilterApplied}
                //gridRef={gridApiRef}


                />

            </Grid>

            <DGDrawer
                isOpen={entryFormOpen}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()
                }}
                Component={CuttingToolNumberAdd}
                drawerWidth={800}
                title="Add Cutting Tool Number"
                needSave

            />

            <DGDrawer
                isOpen={statusFormOpen}
                setOpen={() => {
                    setStatusFormOpen(false)
                    reloadGridData()

                }}
                Component={CtoolStatusManage}
                componentProps={selectedRow}
                drawerWidth={800}
                title={selectedRow?.ctool_name + " - " + selectedRow?.ctool_number}
                subtitle={"Current Status: " + selectedRow?.ctool_status}
                needSave

            />

        </>

    )


}

const useStyles = makeStyles({
    common: {
        color: "#fff",
        borderRadius: 15,
        cursor: "pointer",
        // width: "100%",
    },
    header: {
        color: "#000",
        padding: 15,
        paddingBottom: 5,
    },
    count: {
        color: "#000",
        padding: 15,
        paddingTop: 0,
    },
    selected: {
        //border: "2px solid #000",
        backgroundColor: "#fff !important",
    },

});