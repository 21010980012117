import React, { useState, createContext } from "react";

const SubNavigationContext = createContext({
  subroutes: [],
  updateRoutes: () => { },
  isChildrenRequired: false,
  navigationCallback: () => { },
  pageTitle: "",
  setheaderPageTitle: () => { },
});

export const SubNavigationContextProvider = ({ children }) => {
  const [navObject, setNavObject] = useState({
    routes: [],
    isChildrenRequired: false,
    callback: () => { },
    pageTitle: "",
  });
  function updateRoutes(
    routes,
    state = false,
    navcallback = () => { },
    pageTitle = ""
  ) {
    setNavObject({
      routes: routes,
      isChildrenRequired: state,
      callback: navcallback,
      pageTitle: pageTitle,
    });
  }

  function setheaderPageTitle(title) {
    //console.log("SubNavigationContext", title)
    setNavObject((prev) => ({ ...prev, pageTitle: title }));
  }

  //console.log("navObject", navObject)

  return (
    <SubNavigationContext.Provider
      value={{
        subroutes: navObject.routes,
        updateRoutes: updateRoutes,
        isChildrenRequired: navObject.isChildrenRequired,
        navigationCallback: navObject?.callback,
        // pageTitle: navObject.pageTitle,
        setheaderPageTitle: setheaderPageTitle,
      }}
    >
      {children}
    </SubNavigationContext.Provider>
  );
};

export default SubNavigationContextProvider;
export { SubNavigationContext };
