/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { object, string, number } from "yup";
import { ACTION_TYPE } from "../../components/shared/commonenum";
import { DGDateInput } from "../../components/shared/DGDateInput";
import { DGInput } from "../../components/shared/DGInput";
import { DGSelect } from "../../components/shared/DGSelect";
import { AuthContext } from "../../context/AuthContextProvider";
import { AlertContext } from "../../context/AlertContextProvider";
import dateService from "../../services/dateService";
import vendorService from "../../services/vendorService";

export function VendorForm({
  componentProps = {},
  Footer = () => { },
  closeForm = () => { },
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [vendorType, setVendorType] = React.useState(null);


  let form = useFormik({
    initialValues: {
      calibration: null,
      certificate: "",
      certificate_number: "",
      issued_date: null,
      expiry_date: null,
      address1: "",
      address2: "",
      city: "",
      pincode: "",
      state: "",
      country: "India",
      calibrationOptions: [],
      vendorOptions: [
        { id: 11, name: "Calibration Management" },
        { id: 25, name: "Tool Refurbishing Vendor" },
      ],
      vendorTypeSelected: ""
    },
    validationSchema: object({
      address1: string().required(),
      address2: string().required(),
      city: string().required(),
      pincode: number().integer().required(),
      state: string().required(),
      country: string().required(),
    }),
    onSubmit: upsertForm,
  });

  /*   useEffect(() => {
      if (componentProps) {
        form.setValues({
          calibration: {
            id: "12234",
            business_name: componentProps?.business_name,
            NABL_Agency: componentProps?.business_name,
          },
          certificate: componentProps?.certificate_number,
          issued_date: componentProps?.issued_date,
          expiry_date: componentProps?.expiry_date,
          address1: componentProps?.address?.address_line_1,
          address2: componentProps?.address?.address_line_2,
          city: componentProps?.address?.city,
          pincode: componentProps?.address?.pincode,
          state: componentProps?.address?.state,
          country: componentProps?.address?.country,
          calibrationOptions: [
            {
              id: "12234",
              business_name: componentProps?.business_name,
              NABL_Agency: componentProps?.business_name,
            },
          ],
        });
      } else {
        getPIAgency();
      }
    }, [componentProps]); */

  useEffect(() => {
    getPIAgency();
  }, []);


  const getPIAgency = async () => {
    vendorService.getVendorList(currentUser?.client_id).then((res) => {
      form.setFieldValue("calibrationOptions", res?.data ?? []);
    });
  };

  function FillPIAgencyRelatedData(PIAgency) {
    if (PIAgency) {

      form.setFieldValue("country", PIAgency?.country ?? "");
      form.setFieldValue("state", PIAgency?.state ?? "");
      form.setFieldValue("city", PIAgency?.city ?? "");
      form.setFieldValue("pincode", PIAgency?.pincode ?? "");
      form.setFieldValue("address1", PIAgency?.address_line_1 ?? "");
      form.setFieldValue("address2", PIAgency?.address_line_2 ?? "");
      form.setFieldValue("calibration", PIAgency ?? "");
    }

  }


  function upsertForm(values) {
    let agency;
    if (form.values.vendorTypeSelected?.id === 11) {
      if (!form.values.calibration) {
        let val = document.getElementById("businessName").value;
        if (val) {
          agency = val;
        }
        else {
          form.setFieldError("calibration");
          return;
        }
      }
    }



    let payload = {
      business_name: agency ? agency : values?.calibration?.NABL_Agency,
      issued_date: values.issued_date ? dateService.getDateInSqlFormat(values.issued_date) : null,
      expiry_date: values.expiry_date ? dateService.getDateInSqlFormat(values.expiry_date) : null,
      certificate_number: values.certificate_number ? dateService.getDateInSqlFormat(values.certificate_number) : null,
      certificate_link: values.certificate_link ? dateService.getDateInSqlFormat(values.certificate_link) : null,
      address_id: values.calibration?.address_id ?? "",
      address_line_1: values.address1,
      address_line_2: values.address2,
      city: values.city,
      state: values.state,
      country: values.country,
      pincode: values.pincode,
      client_id: currentUser.client_id,
      vendor_type_id: form.values?.vendorTypeSelected?.id === 11
        ? 10
        : form.values?.vendorTypeSelected?.id,

    };


    vendorService.addNewVendor(payload)
      .then((res) => {
        updateAlert({
          open: true,
          message: "Added Vendor",
          type: "success",
        });
        closeForm();
      })
      .catch(() => {
        updateAlert({
          open: true,
          message: "Failed to add Vendor ",
          type: "error",
        });
      });

  }



  return (
    <>

      <Grid item md={12} sm={12} style={{ paddingBottom: "20px" }}>
        <DGSelect
          id="VendorTypeSelected"
          value={form.values.vendorTypeSelected}
          onChange={(val) => {
            form.setFieldValue("vendorTypeSelected", val);
            setVendorType(val?.id)
          }}
          options={form.values.vendorOptions}
          getOptionLabel={(opt) => opt?.name || ""}
        />
      </Grid>

      {vendorType === 11 && (
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <DGSelect
            Id={"businessName"}
            value={form.values.calibration}
            onChange={(val) => {
              form.setFieldValue("calibration", val);
              FillPIAgencyRelatedData(val)

            }}
            options={form.values.calibrationOptions}
            label="Caliberation Agency"
            getOptionLabel={(opt) => opt?.NABL_Agency || ""}
            error={Boolean(form.errors.calibration) && form.touched.calibration}
            freeSolo={true}
            required
          />

          {/*           <Grid item md={12} sm={12}>
            <DGInput
              id="certificate"
              label="Certificate Number"
              value={form?.values?.certificate_number}
              onChange={form.handleChange}
              isFormik
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGDateInput
              label="Issued Date"
              id="issued_date"
              value={form.values.issued_date}
              onChange={(date) => form.setFieldValue("issued_date", date)}
              isFormik
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGDateInput
              label="Expiry Date"
              isFormik
              id="expiry_date"
              value={form.values.expiry_date}
              onChange={(date) => form.setFieldValue("expiry_date", date)}
            />
          </Grid> */}

          <Grid item md={12} sm={12}>
            <DGInput
              required
              id="address1"
              isFormik
              label="Street Address Line 1"
              value={form.values.address1}
              onChange={form.handleChange}
              error={Boolean(form.errors.address1) && form.touched.address1}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <DGInput
              required
              isFormik
              id="address2"
              label="Street Address Line 2"
              value={form.values.address2}
              onChange={form.handleChange}
              error={Boolean(form.errors.address2) && form.touched.address2}
            />
          </Grid>

          <Grid item md={6} sm={6}>
            <DGInput
              required
              isFormik
              id="city"
              label="City"
              value={form.values.city}
              onChange={form.handleChange}
              error={Boolean(form.errors.city) && form.touched.city}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGInput
              required
              label="Pin Code"
              isFormik
              id="pincode"
              value={form.values.pincode}
              onChange={form.handleChange}
              error={Boolean(form.errors.pincode) && form.touched.pincode}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGInput
              required
              label="State"
              id="state"
              value={form.values.state}
              onChange={form.handleChange}
              error={Boolean(form.errors.state) && form.touched.state}
              isFormik
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGInput
              required
              label="Country"
              id="country"
              value={form.values.country}
              onChange={form.handleChange}
              error={Boolean(form.errors.country) && form.touched.country}
              isFormik
            />
          </Grid>

          <Footer onSave={form.handleSubmit} />
        </Grid>
      )}

      {vendorType > 11 && (
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <DGInput
            Id={"businessName"}
            value={form.values.calibration?.NABL_Agency}
            onChange={(val) => {
              form.setFieldValue("calibration.NABL_Agency", val);
            }}
            label="Vendor"
            required
          />
          <Grid item md={12} sm={12}>
            <DGInput
              required
              id="address1"
              isFormik
              label="Street Address Line 1"
              value={form.values.address1}
              onChange={form.handleChange}
              error={Boolean(form.errors.address1) && form.touched.address1}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <DGInput
              required
              isFormik
              id="address2"
              label="Street Address Line 2"
              value={form.values.address2}
              onChange={form.handleChange}
              error={Boolean(form.errors.address2) && form.touched.address2}
            />
          </Grid>

          <Grid item md={6} sm={6}>
            <DGInput
              required
              isFormik
              id="city"
              label="City"
              value={form.values.city}
              onChange={form.handleChange}
              error={Boolean(form.errors.city) && form.touched.city}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGInput
              required
              label="Pin Code"
              isFormik
              id="pincode"
              value={form.values.pincode}
              onChange={form.handleChange}
              error={Boolean(form.errors.pincode) && form.touched.pincode}
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGInput
              required
              label="State"
              id="state"
              value={form.values.state}
              onChange={form.handleChange}
              error={Boolean(form.errors.state) && form.touched.state}
              isFormik
            />
          </Grid>
          <Grid item md={6} sm={6}>
            <DGInput
              required
              label="Country"
              id="country"
              value={form.values.country}
              onChange={form.handleChange}
              error={Boolean(form.errors.country) && form.touched.country}
              isFormik
            />
          </Grid>

          <Footer onSave={form.handleSubmit} />
        </Grid>
      )}
    </>
  );

}
