import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "../../../assets/styles/hover.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "3% 10% 2% 10%",
  },
  paper: {},
  h3: {
    fontWeight: "normal",
    lineHeight: "normal",

    // margin: "10px",
  },
  p: {
    lineHeight: "normal",
    // margin: "10px",
    padding: "10px",
    textAlign: "justify",
  },
}));

export default function Features() {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#FFF5EB" }}>
      <div className={classes.root}>
        <div style={{}}>
          <h2
            style={{
              paddingLeft: "1%",
              fontSize: "32px",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Products and services
          </h2>
        </div>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="stretch"
        >
          <Grid item xs={12} sm={4}>
            <Paper
              style={{
                height: "93%",
                textAlign: "center",
                fontSize: "19px",
                borderRadius: "5px",
                padding: "13px",
                margin: "0px 10px 10px 10px",
                textAlign: "justify",
              }}
              className="card"
            >
              <div>
                <div>
                  <h3>
                    <span
                      style={{
                        fontSize: "26px",
                        marginTop: "0",
                        textDecoration: "underline",
                      }}
                    >
                      Product Management
                    </span>
                  </h3>
                </div>
                <div>
                  <p>
                    <span>
                      Manage your products (BOM) with heararcnial
                      &nbsp;structure. Manage BOM for multiple customers. Attach
                      drawing to each part with easy access to team.
                    </span>
                  </p>
                  <p>
                    The Product management module form the base of the
                    application.
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              style={{
                height: "93%",
                textAlign: "center",
                fontSize: "19px",
                borderRadius: "5px",
                padding: "13px",
                margin: "0px 15px 10px 15px",
                textAlign: "justify",
              }}
              className="card"
            >
              <div>
                <div>
                  <h3 style={{}}>
                    <span
                      style={{ fontSize: "26px", textDecoration: "underline" }}
                    >
                      Gauge Calibration
                    </span>
                  </h3>
                </div>
                <div>
                  <p style={{}}>
                    <span style={{}}>
                      Gauge defines the product quality, thus making calibration
                      an important activity in manufacturing. It is important to
                      ensure calibration is associated with NABL guidelines.
                    </span>
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              style={{
                height: "93%",
                textAlign: "center",
                fontSize: "19px",
                borderRadius: "5px",
                padding: "13px",
                margin: "0px 10px 10px 10px",
                textAlign: "justify",
              }}
              className="card"
            >
              <div>
                <div>
                  <h3 style={{}}>
                    <span
                      style={{ fontSize: "26px", textDecoration: "underline" }}
                    >
                      Dashboard
                    </span>
                  </h3>
                </div>
                <div>
                  <p style={{}}>
                    <span style={{}}>
                      Visualizing "is condition" of the system is important for
                      both management and execution team in this agile
                      environment.
                    </span>
                  </p>
                  <p style={{}}>
                    Reports which gives information on success and actionable
                    points is import.
                  </p>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
