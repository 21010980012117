import DateFnsUtils from "@date-io/date-fns";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { create } from "jss";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components/macro";
import "./assets/styles/global.css";
import AuthContextProvider from "./context/AuthContextProvider";
import Main from "./main";
import createTheme from "./theme";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS, always needed
// import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-community/dist/styles/ag-theme-material.css";
import StatusContextProvider from "./context/StatusContextProvider";
import StatusDialog from "./components/StatusDialog";
import StatusHistoryContextProvider from "./context/StatusHistoryProvider";
import StatusHistoryDialog from "./components/StatusHistoryDialog";
import PlantContextProvider from "./context/PlantContextProvider";
import { ChakraProvider } from "@chakra-ui/react";
import ModalContextProvider from "./context/ModalContextProvider";
import SubNavigationContextProvider from "./context/SubnavigationProvider";
import AlertContextProvider from "./context/AlertContextProvider";
import PageTitleContextProvider from "./context/PageTitleContextProvider";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const theme = useSelector((state) => state.themeReducer);
  LicenseManager.setLicenseKey(
    "CompanyName=Digital Gemba Software Pvt Limited,LicensedApplication=Digital Gemba,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-028990,ExpiryDate=5_June_2023_[v2]_MTY4NTkxOTYwMDAwMA==60b6c5cc179b87b1e184db127d601248"
  );
  // useEffect(() => {
  //   const initialValue = document.body.style.zoom;

  //   // Change zoom level on mount
  //   document.body.style.zoom = "90%";

  //   return () => {
  //     // Restore default value
  //     document.body.style.zoom = initialValue;
  //   };
  // }, []);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | Digital Gemba"
          defaultTitle="Digital Gemba - Dashboard"
        />

        <StylesProvider jss={jss}>
          <ChakraProvider>
            <ModalContextProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                  <ThemeProvider theme={createTheme(theme.currentTheme)}>
                    <AuthContextProvider>
                      <SubNavigationContextProvider>
                        <PlantContextProvider>
                          <PageTitleContextProvider>
                            <StatusHistoryContextProvider>
                              <StatusContextProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <StatusDialog />
                                  <StatusHistoryDialog />
                                  <AlertContextProvider>
                                    <Main />
                                  </AlertContextProvider>
                                </MuiPickersUtilsProvider>
                              </StatusContextProvider>
                            </StatusHistoryContextProvider>
                          </PageTitleContextProvider>
                        </PlantContextProvider>
                      </SubNavigationContextProvider>
                    </AuthContextProvider>
                  </ThemeProvider>
                </MuiThemeProvider>
              </MuiPickersUtilsProvider>
            </ModalContextProvider>
          </ChakraProvider>
        </StylesProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default App;
