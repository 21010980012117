import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet-async";
import AllCustomersContainer from "./AllCustomersContainer";

const Customers = () => {
  return (
    <React.Fragment>
      <Helmet title="Customers" />
      <Grid container>
        <Grid item xs={12}>
          <AllCustomersContainer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Customers;
