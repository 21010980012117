import React from "react";
import Highcharts from "highcharts/highstock";
// import Highcharts from "highcharts";
import HighchartsReact, { Tooltip } from "highcharts-react-official";
import "./highcharts.css";
import $ from "jquery";
import html2canvas from "html2canvas";
import downloadjs from "downloadjs";

export default function HighChartComponent({
  title1 = "",
  title2 = "",
  chartData1 = [],
  chartData2 = [],
  xaxis = [],
  highLimit1 = 0,
  lowLimit1 = 0,
  targetLimit1 = 0,
  onHoverData1 = "",
  highLimit2 = 0,
  lowLimit2 = 0,
  targetLimit2 = 0,
  onHoverData2 = "",
  limit = null,
}) {
  $("#chart").bind("mousemove mouseover", function (e) {
    var chart, points, i;

    for (i = 0; i < Highcharts?.charts?.length ?? 0; i++) {
      chart = Highcharts?.charts[i];
      if (chart?.pointer) {
        e = chart?.pointer?.normalize(e);
        points = chart?.series[0]?.searchPoint(e, true);
        if (points) {
          chart?.tooltip?.refresh(points);
          chart?.xAxis[0]?.drawCrosshair(e, points[0]);
          // let t =
          //   "Location : " +
          //   points?.loc_val +
          //   " || Dispersion : " +
          //   points?.dis_val +
          //   " || SPC Rules : " +
          //   points?.spcResult +
          //   " || Evaluation : " +
          //   points?.evaluation_number;
          // Highcharts?.charts[0]?.setSubtitle({
          //   text: t,
          // });
          // points?.spc_result == 1
          //   ? Highcharts?.charts[0]?.setSubtitle({ style: { color: "green" } })
          //   : Highcharts?.charts[0]?.setSubtitle({ style: { color: "red" } });
        }
      }
    }
  });

  // $("#chart").bind("mouseleave", function (e) {
  //   Highcharts?.charts[0]?.setSubtitle({ text: "test" });
  //   Highcharts?.charts[0]?.setSubtitle({ style: { color: "#fff" } });
  // });

  Highcharts?.charts?.forEach((chart) => {
    chart?.xAxis[0]?.update({
      events: {
        afterSetExtremes: function (event) {
          Highcharts?.charts?.forEach((otherChart) => {
            otherChart?.xAxis[0]?.setExtremes(event.min, event.max);
          });
        },
      },
    });
  });

  const exportChart = async () => {
    const canvas = await html2canvas(document.getElementById("chart"));
    const dataURL = canvas.toDataURL("image/png");
    downloadjs(dataURL, "download.png", "image/png");
  };

  const options1 = {
    rangeSelector: {
      selected: 0,
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1m",
          title: "View 1 month",
        },
        {
          type: "month",
          count: 3,
          text: "3m",
          title: "View 3 months",
        },
        {
          type: "month",
          count: 6,
          text: "6m",
          title: "View 6 months",
        },
        {
          type: "year",
          count: 1,
          text: "1y",
          title: "View 1 year",
        },
        {
          type: "all",
          text: "All",
          title: "View all",
        },
      ],
    },
    credits: {
      enabled: false,
    },
    chart: {
      // height: "35%",
      zoomType: "xy",
      panning: true,
    },
    animation: true,
    subtitle: {
      text: "test ",
      style: {
        fontWeight: "bold",
        color: "#fff",
      },
      useHTML: true,
    },
    xAxis: {
      crosshair: true,
    },
    time: {
      timezone: "Asia/Kolkata",
    },
    title: {
      text: title1,
    },
    tooltip: {
      enabled: true,
      // borderWidth: 1,
      borderWidth: 0,
      // backgroundColor: "#FCFFC5",
      pointFormat: onHoverData1,
      headerFormat: "",
      shadow: false,
      shared: true,

      positioner: function (labelWidth, labelHeight, point) {
        var chart = this.chart;
        var plotX = point.plotX + chart.plotLeft;
        var plotY = point.plotY + chart.plotTop;
        var tooltipX;
        var tooltipY;

        // Check if the point is towards the left side of the chart
        if (plotX < chart.plotWidth / 2) {
          tooltipX = plotX + 15; // Position the label to the right
        } else {
          tooltipX = plotX - labelWidth - 10; // Position the label to the left
        }

        // Check if the point is towards the top of the chart
        if (plotY < chart.plotHeight / 2) {
          tooltipY = plotY + labelHeight + 15; // Position the label below
        } else {
          tooltipY = plotY - labelHeight - 10; // Position the label above
        }

        return {
          x: tooltipX,
          y: tooltipY
        };
      },
    },


    yAxis: {
      max: limit?.loc_max,
      min: limit?.loc_min,
      opposite: false,
      plotLines: [
        {
          value: highLimit1,
          color: "green",
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "UCL",
            align: "right",
            x: -1,
          },
        },
        {
          value: lowLimit1,
          color: "green",
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "LCL",
            align: "right",
            x: 1,
          },
        },
        {
          value: targetLimit1,
          color: "black",
          width: 0.5,
          dashStyle: "solid",
          width: 2,
          label: {
            text: "Target",
            align: "right",
            x: -1,
          },
        },
      ],
    },
    series: [
      {
        data: chartData1,
        tooltip: { valueSuffix: " " + "dataset.unit" },
        stickyTracking: false
      },
    ],
  };

  const options2 = {
    rangeSelector: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    chart: {
      // height: "35%",
      zoomType: "xy",
      panning: true,
    },
    xAxis: {
      crosshair: true,
    },
    time: {
      timezone: "Asia/Kolkata",
    },
    title: {
      text: title2,
    },
    tooltip: {
      enabled: true,
      borderWidth: 0,
      pointFormat: onHoverData2,
      headerFormat: "",
      shadow: false,
      shared: true,
      positioner: function (labelWidth, labelHeight, point) {
        var chart = this.chart;
        var plotX = point.plotX + chart.plotLeft;
        var plotY = point.plotY + chart.plotTop;
        var tooltipX;
        var tooltipY;

        // Check if the point is towards the left side of the chart
        if (plotX < chart.plotWidth / 2) {
          tooltipX = plotX + 15; // Position the label to the right
        } else {
          tooltipX = plotX - labelWidth - 10; // Position the label to the left
        }

        // Check if the point is towards the top of the chart
        if (plotY < chart.plotHeight / 2) {
          tooltipY = plotY + labelHeight + 10; // Position the label below
        } else {
          tooltipY = plotY - labelHeight - 10; // Position the label above
        }

        return {
          x: tooltipX,
          y: tooltipY
        };
      },
    },
    yAxis: {
      max: limit?.dis_max,
      min: limit?.dis_min,
      // max: highLimit2,
      opposite: false,
      plotLines: [
        {
          value: highLimit2,
          color: "green",
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "UCL",
            align: "right",
            x: -1,
          },
        },
        {
          value: lowLimit2,
          color: "green",
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "LCL",
            align: "right",
            x: -1,
          },
        },
        {
          value: targetLimit2,
          color: "black",
          dashStyle: "shortdash",
          width: 2,
          label: {
            text: "Target",
            align: "right",
            x: -1,
          },
        },
      ],
    },
    series: [
      {
        data: chartData2,
        tooltip: {
          valueSuffix: " " + "dataset.unit",
          stickyTracking: false

        },
      },
    ],
  };

  return (
    <div>
      {/* <button
        onClick={() => {
          exportChart();
        }}
        style={{ cursor: "pointer" }}
      >
        export
      </button> */}
      <div id="chart">
        <div style={{}}>
          {chartData1?.length ?? 0 > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options1}
              constructorType={"stockChart"}
            />
          ) : null}
        </div>
        <div style={{ marginTop: "-80px" }}>
          {chartData2?.length ?? 0 > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options2}
              constructorType={"stockChart"}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
