import React, { useEffect, useState, useContext } from "react";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { array, object, string, number, ref } from "yup";
import Loader from "../../../../components/Loader";
import errorLog from "../../../../services/errorLog";
import spcService from "../../../../services/spcService";
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from "../../../../context/AuthContextProvider";
import {
    Grid, InputAdornment, TextField,
    AppBar, Divider, Toolbar, Button
} from "@material-ui/core";
import { useFormik } from "formik";
import { id } from "date-fns/locale";


export default function ManualEntryEval({
    componentProps = null,
    onSuccess = () => { },
    onPrevious = () => { }

}) {
    const { v4: uuidv4 } = require("uuid");
    const [evalData, setEvalData] = useState(0)
    const [columnDefs, setColumnDefs] = useState([])
    const [rowData, setRowData] = useState([])
    const [resultID, setResultID] = useState(null)
    const [dataLoaded, setDataLoaded] = useState(false);
    let { currentUser } = useContext(AuthContext);


    const isLessOrEqual = (value, context, lessThanValue) => {
        return value <= lessThanValue;
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await spcService.getSPCCLId(componentProps);
                if (res.data.length > 0) {
                    setEvalData(res?.data[0]);
                    setResultID(uuidv4());
                } else {
                    fetchData();
                }
            } catch (error) {
                let epayload = {
                    id: uuidv4(),
                    error_description: error.message,
                    error_location: "getSPCCLId",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: componentProps
                };
                errorLog.createErrorLog(epayload)
            }
        };

        fetchData();
    }, [componentProps]);


    let form = useFormik({
        initialValues: {
            loc_chart: "",
            loc_eval: "",
            dis_chart: "",
            dis_eval: "",
            guideline: "",
            machine_spindle: null,
            evaluation_date: null,
            part_detail: "",
            characteristics: null,
            dis_ucl: null,
            dis_target: "",
            dis_lcl: "",
            loc_ucl: "",
            loc_target: "",
            loc_lcl: "",
            part: "",
        },

        validationSchema: object().shape({
            loc_ucl: number()
                .required()
                .moreThan(ref('loc_target')),
            loc_lcl: number()
                .required()
                .lessThan(ref('loc_target')),
            loc_target: number().required(),

            dis_ucl: number()
                .required()
                .moreThan(ref('dis_target')),
            dis_lcl: number()
                .required(),
            //   .test(isLessOrEqual, ref('dis_target')),
            dis_target: number().required(),
        })
    })


    useEffect(() => {
        if (evalData) {
            form.setFieldValue("part", evalData.part)
            form.setFieldValue("characteristics", (evalData.character_name + "  " + evalData.character_specification))
            form.setFieldValue("machine_spindle", evalData.machine_spindle)
            form.setFieldValue("evaluation_date", evalData.evaluation_date)
            form.setFieldValue("template_name", evalData.template_name)
            form.setFieldValue("template_name", evalData.template_name)
            form.setFieldValue("evaluation_method", evalData.evaluation_method)

            form.setFieldValue("loc_chart", evalData.loc_chart)
            form.setFieldValue("dis_chart", evalData.dis_chart)

            form.setFieldValue("loc_eval", evalData.loc_eval)
            form.setFieldValue("dis_eval", evalData.dis_eval)
        }

    }, [evalData])

    function saveForm() {
        let payload = {
            id: resultID,
            character_id: evalData.character_id,
            cp: null,
            cp_low_ci: null,
            cp_high_ci: null,
            cpk: null,
            cpk_low_ci: null,
            cpk_high_ci: null,
            dis_target: form.values.dis_target,
            dis_lcl: form.values.dis_lcl,
            dis_ucl: form.values.dis_ucl,
            loc_target: form.values.loc_target,
            loc_lcl: form.values.loc_lcl,
            loc_ucl: form.values.loc_ucl,
            stability_flag: null,
            stability_model: null,
            dist_flag: null,
            outlier_flag: null,
            cl_eval_id: componentProps,
            enabled: 1,

        }

        spcService.createSPClimitsResult(payload)
            .then((res) => {
                if (res.data.length < 1) {
                    setDataLoaded(true);
                }
            })
    }

    async function updateForm() {
        await spcService.patchSPClimitseval(componentProps, { result_id: resultID })
            .then((res) => {
                if (res.data.length < 1) {
                    setDataLoaded(true);
                }
            })
    }


    function handleNext() {
        updateForm()
        saveForm()

        onSuccess(true)
    }

    function handlePrevious() {
        onPrevious(true)
    }

    if (dataLoaded) {
        return <Loader />;
    }


    return (

        <>

            <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={12}>
                    <DGInput

                        label="Part Number"
                        value={form?.values?.part}
                    />
                </Grid>

                <Grid item md={6} sm={6} lxs={12}>
                    <DGInput
                        label="Characteristics"
                        value={form?.values?.characteristics}

                    />
                </Grid>

                <Grid item md={4} sm={4} xs={12}>
                    <DGInput
                        label="Machine"
                        value={form?.values?.machine_spindle}

                    />
                </Grid>
                <Grid item md={3} sm={3} xs={12}>
                    <DGDateInput
                        label="Evaluation Date"
                        value={form?.values?.evaluation_date}
                        format="dd/MM/yyyy"


                    />
                </Grid>
                <Grid item md={5} sm={5} xs={12}>
                    <DGInput
                        // disabled
                        label="Guidelines"
                        value={form?.values?.template_name}
                    />
                </Grid>

            </Grid>

            <Grid container spacing={3} style={{ marginTop: '10px' }}>
                <Grid item md={6} sm={6} xs={12}>
                    <DGInput
                        label="Location Chart"
                        value={form?.values?.loc_chart}
                    />
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                    <DGInput
                        //disabled
                        label="Dispersion Chart"
                        value={form?.values?.dis_chart}
                    />
                </Grid>

            </Grid>

            <Grid container spacing={3} style={{ marginTop: '10px' }}>
                <Grid item md={12} sm={12} xs={12}>
                    <DGInput
                        //disabled
                        label="Evaluation Method"
                        value={form?.values?.evaluation_method}
                    />
                </Grid>
            </Grid >

            <Grid container spacing={3} style={{ marginTop: '10px' }}>
                <Grid item md={3} sm={3} xs={3}
                    style={{
                        display: 'flex', alignItems:
                            'center', justifyContent: 'center'
                    }}>
                    <h2>
                        Description
                    </h2>
                </Grid>

                <Grid item md={3} sm={3} xs={3}
                    style={{
                        display: 'flex', alignItems:
                            'center', justifyContent: 'center'
                    }}>
                    <h2>
                        Lower Control Limit
                    </h2>
                </Grid>
                <Grid item md={3} sm={3} xs={3}
                    style={{
                        display: 'flex', alignItems:
                            'center', justifyContent: 'center'
                    }}>
                    <h2>
                        Target
                    </h2>
                </Grid>
                <Grid item md={3} sm={3} xs={3}
                    style={{
                        display: 'flex', alignItems:
                            'center', justifyContent: 'center'
                    }}>
                    <h2>
                        Upper Control Limit
                    </h2>
                </Grid>

                {/* ------------------------------------- */}
                <Grid item md={3} sm={3} xs={3}
                    style={{
                        display: 'flex', alignItems:
                            'center', justifyContent: 'right'
                    }}>
                    <h2>Location </h2>
                </Grid>

                <Grid item md={3} sm={3} xs={3}>
                    <DGInput
                        isFormik
                        value={form?.values?.loc_lcl}
                        onChange={form.handleChange}
                        label="Lower Control Limit"
                        id="loc_lcl"
                    />
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                    <DGInput
                        id="loc_target"
                        isFormik
                        label="Target"
                        value={form?.values?.loc_target}
                        onChange={form.handleChange}

                    />
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                    <DGInput
                        id="loc_ucl"
                        label="Upper Control Limit"
                        value={form?.values?.loc_ucl}
                        onChange={form.handleChange}
                        isFormik
                    />
                </Grid>
                {/* ------------------------------------- */}
                <Grid item md={3} sm={3} xs={3}
                    style={{
                        display: 'flex', alignItems:
                            'center', justifyContent: 'right'
                    }}>
                    <h2>Dispersion </h2>
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                    <DGInput
                        id="dis_lcl"
                        value={form?.values?.dis_lcl}
                        onChange={form.handleChange}
                        label="Lower Control Limit"
                        isFormik
                    />
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                    <DGInput
                        label="Target"
                        id="dis_target"
                        value={form?.values?.dis_target}
                        onChange={form.handleChange}
                        isFormik

                    />
                </Grid>
                <Grid item md={3} sm={3} xs={3}>
                    <DGInput
                        id="dis_ucl"
                        label="Upper Control Limit"
                        value={form?.values?.dis_ucl}
                        onChange={form.handleChange}
                        isFormik
                    />
                </Grid>

            </Grid>

            <AppBar position="fixed" style={{ width: 800, top: "auto", bottom: 0 }}>
                <Divider />
                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>

                    {/*                   <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handlePrevious}

                    >
                        Previous
                    </Button> */}

                    <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => onSuccess(false)}
                    >
                        CANCEL
                    </Button>

                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleNext}
                        disabled={!form.isValid}

                    >
                        NEXT
                    </Button>
                </Toolbar>
            </AppBar>

        </>

    )


}
