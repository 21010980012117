import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import { CuttingToolAdd } from "./CuttingToolNew";
import cuttingToolService from "../../../services/cuttingToolService";





export function CuttingToolMaster() {
    const [rows, setRows] = useState([]);
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [entryFormOpen, setEntryFormOpen] = useState(false);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);

    const { currentUser } = useContext(AuthContext);

    setHeaderPageTitle("Cutting Tool Master")


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);



    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        {
            headerName: "Cutting Tool Name",
            field: "ctool_name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 200,
            field: "ctool_name",
        },
        {
            field: "ctool_cname",
            headerName: "Commercial Name",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "ctool_iso",
            headerName: "ISO Catalog Number",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "ctool_grade",
            headerName: "Grade",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },





        {
            field: "ctool_manufacturer",
            headerName: "Manufacturer",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "ctool_edges",
            headerName: "Cutting Edges",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        }
        ,
        {
            headerName: "Re-sharpen",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            cellRenderer: (params) =>
                <ResultRenderer
                    value={params?.data?.ctool_reuse}
                />
        },

        {
            field: "ctool_life",
            headerName: "Target Life",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.ctool_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            headerName: "Status History",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entity: params?.data?.ctool_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    function reloadGridData() {
        cuttingToolService.getCToolTable(currentUser.client_id)
            .then((res) => {
                setRows(res.data.data);
            });
    }

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return cuttingToolService.updateCuttingTool(rowId, { enabled: newStatus });
    }



    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setEntryFormOpen(1);
                    }}
                >
                    New Entry
                </Button>

            </>
        );
    }

    return (
        <>

            <Grid item md={12} sm={12} lg={12} xs={12} justifyContent="flex-end">
                <CommonGrid
                    coldefs={{
                        filter: true,
                        filterParams: {
                            apply: true,
                            newRowsAction: "keep",
                            enabled: { filter: 1 },
                        },
                    }}
                    columns={columns}
                    ButtonComponent={ButtonComponent}
                    rows={rows}
                    gridheight={115}


                />

            </Grid>

            <DGDrawer
                isOpen={entryFormOpen}
                //componentProps={selectedRow}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()
                }}
                Component={CuttingToolAdd}
                drawerWidth={1000}
                title="Add new Cutting Tool"
                needSave

            />

        </>

    )

}