import React from "react";
import { DGAlert } from "./components/shared/DGAlert";
import { DGWarningModal } from "./components/shared/DgWarningModal";
import Routes from "./routes/Routes";

const Main = () => {
  return (
    <>
      <DGWarningModal />
      <DGAlert />
      <Routes />
    </>
  );
};

export default Main;
