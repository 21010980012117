import React from "react";
import { ResultRenderer } from "../../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../../components/CommonGrid";
import { AppBar, Button, Divider, Toolbar } from "@material-ui/core";

export function MSAResult({
  rows = [],
  closeForm = () => {},
  handleNext = () => {},
}) {
  function onGridReady(params) {
    params?.api?.sizeColumnsToFit();
  }
  const columns = [
    {
      field: "parameter",
      headerName: "Parameter",
      resizable: true,
    },
    {
      field: "requirments",
      headerName: "Requirement",
      resizable: true,
    },
    {
      field: "actual_result",
      headerName: "Actual Result",
      resizable: true,

      valueGetter: (params) =>
        parseFloat(params?.data?.actual_result).toFixed(2),
    },

    {
      field: "conclusion",
      headerName: "Conclusion",
      resizable: true,
    },
    {
      field: "result",
      headerName: "Result",
      resizable: true,
      cellRenderer: (params) => {
        return <ResultRenderer value={params?.data?.result} />;
      },
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <CommonGrid
        rows={rows}
        columns={columns}
        onGridReady={onGridReady}
        needExport={false}
      />
      ;
      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleNext()}
          >
            RECORD
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
