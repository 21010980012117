import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { DGInput } from "../../../components/shared/DGInput";

export function UserHeader({
  data = [],
  form = null,
  hangeRecordDisabled = () => { },
}) {
  return (
    <>
      {" "}
      {data?.map((exp, i) => (
        <Grid item sm>
          <DGInput
            id={i}
            label={exp?.item_name}
            value={form?.values[i] ?? ""}
            onChange={(e) => {
              form?.handleChange(e);
              hangeRecordDisabled(true);
            }}
            isFormik
            size="small"
            required={exp?.item_mandatory === 1}
            error={form.errors[i]}
          />
        </Grid>
      ))}
    </>
  );
}
