import React from "react";
import CommonGrid from "../../../components/CommonGrid";

export function GridDetails({
  columns = [],
  rowData = [],
  onGridReady = () => { },
  hangeRecordDisabled = () => { },
}) {
  function processCellFromClipboard(params) {
    if (
      params?.node?.data?.measurement_id === 303 &&
      (params?.value === "OK" || params?.value === "NOT OK")
    ) {
      return params?.value;
    } else if (params?.value && !isNaN(Number(params?.value))) {
      return Number(params?.value);
    } else {
      return "";
    }
  }
  return (
    <CommonGrid
      rows={rowData}
      columns={columns}
      needExport={false}
      onGridReady={onGridReady}
      singleClickEdit
      horizontalTab
      processCellFromClipboard={processCellFromClipboard}
    />
  );
}
