import React from "react";
import styled from "styled-components/macro";

const UseDiv = styled.div`
  margin: 2% 10% -1.5% 10%;

  padding: 1%;
  font-size: 19px;
`;

const UsePara = styled.p`
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  margin: 0;
  display: block;
  text-align: justify;
`;

const UseSpan = styled.span`
  text-align: justify;
`;

function Variants() {
  return (
    <div
      style={{
        background: "#FEFAF5 ",
        paddingBottom: "3.5%",
      }}
    >
      <UseDiv>
        <UsePara>&nbsp;</UsePara>
        <UsePara>
          <UseSpan>
            In today's information-driven world, it is binding for companies and
            processes to embrace digitization and get accustomed to this
            transformation in order to survive. This rapidly digitizing world is
            the result of customers' and businesses' need for improved
            productivity, reduced costs and efforts.&nbsp;
          </UseSpan>
        </UsePara>

        <UsePara>&nbsp;</UsePara>

        <UsePara>
          <UseSpan>
            Shop floor management is intended to sustainably increase efficiency
            and quality in production. The cost benefit is a natural outcome.
          </UseSpan>
        </UsePara>

        <UsePara>&nbsp;</UsePara>

        <UsePara>
          <UseSpan>
            Synonymous to Digitization, Digital Gemba aims to assist businesses
            in making this big switch, with the help of applications that
            deliver secure, compliant and accurate analytical solutions.&nbsp;
          </UseSpan>
        </UsePara>

        <UsePara>&nbsp;</UsePara>

        <UsePara>
          <UseSpan>With Digitisation,​</UseSpan>
        </UsePara>

        <ul>
          <li>
            <UsePara>
              <UseSpan>The focus is on customer centric activities.</UseSpan>
            </UsePara>
          </li>
          <li>
            <UsePara>
              <UseSpan>Data-driven decision making is simple.</UseSpan>
            </UsePara>
          </li>
          <li>
            <UsePara>
              <UseSpan>Efforts for recurring activities reduces.</UseSpan>
            </UsePara>
          </li>
          <li>
            <UsePara>
              <UseSpan>Businesses can go paperless.</UseSpan>
            </UsePara>
          </li>
          <li>
            <UsePara>
              <UseSpan>
                Critical business data is virtually accessible to employees.
              </UseSpan>
            </UsePara>
          </li>
          <li>
            <UsePara>
              <UseSpan>
                There is transparency and accountability in the running of
                business.
              </UseSpan>
            </UsePara>
          </li>
        </ul>

        <UsePara style={{ lineHeight: "1.5em" }}>
          <UseSpan>
            With effective implementation of Digital Gemba tools, your
            business's Quality-Cost-Delivery metrics are sure to improve
            remarkably.
          </UseSpan>
        </UsePara>
      </UseDiv>
    </div>
  );
}

export default Variants;
