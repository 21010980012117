import React from "react";
import { LinearProgress } from "@material-ui/core";
function GridLoading() {
  return (
    <>
    <h3>Loading</h3>
      <LinearProgress style={{width:150}}/>
    </>
  );
}

export default GridLoading;
