import { blueGrey } from '@material-ui/core/colors';
import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../../components/CommonGrid'
import { AuthContext } from '../../../context/AuthContextProvider';
import gaugeService from '../../../services/gaugeService';
import { rgba } from "polished";
import { makeStyles } from "@material-ui/core";

export function GaugeProduct() {
  //style Definition
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    gaugeService.getGaugeChar(currentUser?.client_id).then(res => {
      setRows(res?.data ?? []);
    })
  }, [])

  const columns = [{
    field: "serial_number",
    headerName: "SI No",
    valueGetter: "node.rowIndex + 1",
    minWidth: 100,
    sorting: true,
  },
  {
    field: "plant_name",
    headerName: "Plant Name",
    minWidth: 200,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    sorting: true,
  },
  {
    field: "part_name",
    headerName: "Part Name ",
    minWidth: 200,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    //sorting: true,
  },
    /*  {
       field: "gauge_name",
       headerName: "Gauge Name",
       minWidth: 200,
       resizable: true,
       filter: "agTextColumnFilter",
       floatingFilter: true,
       sorting: true,
     }, 
     {
       field: "part_name",
       headerName: "Part Name",
       minWidth: 200,
       resizable: true,
       filter: "agTextColumnFilter",
       floatingFilter: true,
       sorting: true,
     }, {
       field: "specification",
       headerName: "Specification",
       minWidth: 200,
       resizable: true,
       cellRenderer: (params) => <GetFormattedValue value={params?.data?.specification !== null &&
           params?.data?.upper_specification !== null &&
           params?.data?.lower_specification !== null
             ? `${params?.data?.specification},${params?.data?.upper_specification},${params?.data?.lower_specification}`
             : null} />,
       sorting: true,
     }, */
  ]
  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }

  return <CommonGrid rows={rows} columns={columns} gridheight={215} />;
}

const useStyles = makeStyles({
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});
