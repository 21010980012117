import http from "./httpService";


const getRoles = () => {
    return http.get('/roles');
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getRoles
};