import http from "./httpService";

const getCharacter = () => {
  return http.get("/character");
};

const updateCharacter = (id, data) => {
  return http.patch(`/character/${id}`, data);
};

const getCharacterById = (charachterId) => {
  return http.get(`/character/${charachterId}`);
};

const getAllCharacterUsages = () => {
  return http.get("/character-usage");
};

const getCharacterUnits = () => {
  return http.get("/character-units");
};

const getCharactersByProductId = (productId) => {
  return http.get("/character", {
    params: {
      part_id: productId,
    },
  });
};

const getACharactersByProductId = (part_id) => {
  return http.get("/character-active", {
    params: {
      part_id: part_id,

    },
  });
};


const getCharactersByClientId = (clientId) => {
  return http.get("/character", {
    params: {
      client_id: clientId,
    },
  });
};

const getCharacterByChildId = (childId) => {
  return http.get("/character", {
    params: {
      child_id: childId,
    },
  });
};


const getCharacterforApproval = (approvalId) => {
  return http.get("/character", {
    params: {
      approval_id: approvalId,
    },
  });
};

const getCharacterType = () => {
  return http.get("/character-type");
};

const getCharacterCriticality = () => {
  return http.get("/character-criticality");
};

const createCharacterToCharacterUsage = (data) => {
  return http.post("/character-character-usage", data);
};

const getCharacterToCharacterUsage = (characterId) => {
  return http.get("/character-character-usage", {
    params: {
      character_id: `${characterId}`,
    },
  });
};


const createCharacterProcess = (data) => {
  return http.post("/character-process", data);
};

const getCharacterProcess = (data) => {
  return http.get("/character-process");
};


const updateCharacterProcess = (id, data) => {
  return http.patch(`/character-process/${id}`, data);
};

const createGaugeCharacter = (data) => {
  return http.post("/gauge-character", data);
};

const updateGaugeCharacter = (id, data) => {
  return http.patch(`/gauge-character/${id}`, data);
};

const getGaugeCharacter = (id) => {
  return http.get(`/gauge-character/${id}`);

};


const getGaugesOfCharacter = (characterId) => {
  return http.get("/gauge-character", {
    params: {
      character_id: characterId,
    },
  });
};

const getProcessCharacters = (characterId) => {
  return http.get("/character-process", {
    params: {
      character_id: characterId,
    },
  });
};


const createCharacterMachine = (data) => {
  return http.post("/character-machine", data)

};


const getCharacterMachine = (characterId) => {
  return http.get("/character-machine", {
    params: {
      character_id: characterId,
    },
  });
}




const updateGaugeCharstatus = async (id, newStatus) => {
  return http.patch(`/gauge-character/${id}`, newStatus)
}

const characterApproval = (data) => {
  return http.post("/character-approval", data);
};


const createCharacter = (data) => {
  return http.post("/character-create", data);
};

// enabled_char "null" --> Enabled at 0 level
const getCharacterPartId = (part_id, enabled_char) => {
  return http.get("/character-part-detail", {
    params: {
      part_id: part_id,
      enabled_char: enabled_char
    },
  });
};

const getCharacterForApproval = (approval_id) => {
  return http.get("/character-approval-detail", {
    params: {
      approval_id: approval_id
    },
  });
};





// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCharacter,
  createCharacterProcess,
  updateCharacterProcess,
  createGaugeCharacter,
  getAllCharacterUsages,
  updateGaugeCharacter,
  getCharacter,
  getCharactersByProductId,
  getCharacterType,
  getCharacterToCharacterUsage,
  getCharacterCriticality,
  getCharacterById,
  getGaugesOfCharacter,
  createCharacterToCharacterUsage,
  getProcessCharacters,
  updateCharacter,
  getCharacterByChildId,
  getCharactersByClientId,
  getGaugeCharacter,
  getCharacterUnits,
  getCharacterProcess,
  createCharacterMachine,
  getCharacterMachine,
  getCharacterforApproval,
  getACharactersByProductId,
  updateGaugeCharstatus,
  characterApproval,
  getCharacterPartId,
  getCharacterForApproval
};
