import React, { useContext, useEffect, useState } from "react";
import { SubNavigationContext } from "../../../context/SubnavigationProvider";
import { CustomerCharacters } from "./CustomerCharacters";
import { CustomerMsas } from "./CustomerMsas";
import { CustomerSpcs } from "./CustomerSpcs";
import { CustomerProductInspections } from "./CustomerProductInspections";
import { CustomerDispatchInspections } from "./CustomerDispatchInspection";
import productService from "../../../services/productService";
import { useParams } from "react-router-dom";

export function CustomerCharacterDetails() {
  const { updateRoutes, setheaderPageTitle } = useContext(SubNavigationContext);
  const [selectedTab, setSlectedTab] = useState(0);
  const { id } = useParams();
  useEffect(() => {
    updateRoutes(
      [
        {
          name: "Characterstics",
        },
        {
          name: "MSA",
        },
        {
          name: "SPC",
        },
        {
          name: "Production Inspection",
        },
        {
          name: "Pre Dispatch Inspection",
        },
      ],
      true,
      onNavigationChanged
    );
    setheaderPageTitle("Product Details");
    getPartDetails(id);
  }, []);

  function onNavigationChanged(index) {
    setSlectedTab(index);
  }

  const getPartDetails = async (productId) => {
    const res = await productService.getPartDetails(productId);
    if (res) {
      setheaderPageTitle((`${res?.data[0].part_number} - ${res?.data[0].part_name}
      - ${res?.data[0].client_name}`).slice(0, 60));
    }
  };

  return (
    <>
      {selectedTab === 0 ? <CustomerCharacters /> : null}
      {selectedTab === 1 ? <CustomerMsas /> : null}
      {selectedTab === 2 ? <CustomerSpcs /> : null}
      {selectedTab === 3 ? <CustomerProductInspections /> : null}
      {selectedTab === 4 ? <CustomerDispatchInspections /> : null}
    </>
  );
}
