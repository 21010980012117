import { Https } from "@material-ui/icons";
import http from "./httpService";

const getAllPIAgency = () => {
    return http.get("/pi-agency");
}

const getPIAgencyByClientIdAndPiAgencyId = (clientId, piAgencyId) => {
    return http.get("/pi-requests", {
        params: {
            client_id: clientId,
            pi_agency_id: piAgencyId,
        }
    });
}

const getPIAgencyByClientId = (clientId) => {
    return http.get("/pi-agency", {
        params: {
            client_id: clientId,
        }
    });
}

const createPIAgency = (data) => {
    return http.post("/pi-agency", data);
}


const getAllPIFrequencies = () => {
    return http.get("/pi-frequency");
}

const createPIFrequency = (data) => {
    return http.post("/pi-frequency", data);
}

const createPIRequest = (data) => {
    return http.post("/pi-requests", data);
}

const createPIResult = (data) => {
    return http.post("/pi-result", data);
}

const getPIResultByGaugeId = (gaugeId) => {
    return http.get("/pi-result", {
        params: {
            gauge_id: gaugeId,
            status: 'approved',
        }
    });
}

const getAllPIRequestsOfGaugeItem = (gaugeItemId) => {
    return http.get("/pi-requests", {
        params: {
            guage_item_id: gaugeItemId,
        }
    });
}


const plantlevelPI = (client_id) => {
    return http.get("/pi-consolidation", {
        params: {
            client_id: client_id
        }
    })
}

const getPIResultByPIRequestId = (id) => {
    return http.get("/pi-result", {
        params: {
            pi_request_id: `${id}`
        }
    });
}

const updatePIResult = (id, data) => {
    return http.patch(`/pi-result/${id}`, data);
}

const getPIrequestforID = (id) => {
    return http.get(`/pi-requests/${id}`);
}

const getCalibOverdue = (client_id, table_id, plant_id) => {
    return http.get(`/pi-overdue`, {
        params: {
            client_id: client_id,
            table_id: table_id,
            plant_id: plant_id
        }
    })
}


const getCalibOverview = (client_id, plant_id) => {
    return http.get(`/pi-overdue-overview`, {
        params: {
            client_id: client_id,
            plant_id: plant_id
        }
    })
}

const createPIActivity = (data) => {
    return http.post("/pi-calibration-activity", data);
}

const getCalibGauge = (guage_item) => {
    return http.get(`/pi-consolidation-gauge`, {
        params: {
            guage_item: guage_item,
        }
    })
}


const getPIAgencyDetail = (id) => {
    return http.get(`/pi-agency/${id}`);
}


const updatePIagencystatus = async (id, newStatus) => {
    return http.patch(`/pi-agency/${id}`, newStatus)
}


const getActivePIlist = async (client_id, vendor_type_id) => {
    return http.get('/vendor-active-pi', {
        params: {
            client_id: client_id,
            vendor_type_id: vendor_type_id
        }
    })
}

const createInlineAgency = async (data) => {
    return http.post('/pi-agency-inline', data)
}


export default {
    getAllPIAgency,
    getPIAgencyByClientIdAndPiAgencyId,
    createPIFrequency,
    createPIAgency,
    createPIRequest,
    getAllPIRequestsOfGaugeItem,
    getPIResultByPIRequestId,
    getAllPIFrequencies,
    updatePIResult,
    createPIResult,
    getPIAgencyByClientId,
    getPIResultByGaugeId,
    plantlevelPI,
    getPIrequestforID,
    getCalibOverdue,
    getCalibOverview,
    createPIActivity,
    getCalibGauge,
    getPIAgencyDetail,
    updatePIagencystatus,
    getActivePIlist,
    createInlineAgency
};