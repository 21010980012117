import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

/*
const Attribute_button = () => {
    const [radioValue, setRadioValue] = useState(null);



    return (
        <div className="radio-container">
            <GreenRadio
                value="1"
                id="radioOne"
                checked={radioValue === 1}
                onChange={() => setRadioValue(1)}

            />

            <RedRadio
                value="0"
                id="radioTwo"
                checked={radioValue === 0}
                onChange={() => setRadioValue(0)}

            />

        </div>
    );
};


*/


const Attribute_button = () => {
    return (
        <div>
            <GreenRadio id="option1" name="option" value="option1" />
            <label htmlFor="option1"></label>
            <RedRadio id="option2" name="option" value="option2" />
            <label htmlFor="option2"></label>
        </div>
    );
}

/*
const Attribute_button = () => {
    const [selectedValue, setSelectedValue] = useState(null);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };


    return (
        <RadioGroup value={selectedValue} onChange={handleChange}>
            <div>
                <GreenRadio value="option1" />

            </div>
            <div>
                <RedRadio value="option2" />

            </div>
        </RadioGroup>
    );

}

*/

export default Attribute_button



