import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import customerDashboardService from "../../../services/customerDashboardService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { DGFullScreenDrawer } from "../../../components/shared/DGFullscreenDrawer";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { DispatchPlanView } from "../Plan/DispatchPlanView";
import { Button } from "@material-ui/core";

export function CustomerDispatchInspection() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  useEffect(() => {
    customerDashboardService.getVendorPDIReport(currentUser?.id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);

  function loadForm(data) {
    setSelectedRow(data);
    ACTION_TYPE.view = true;
    setFormOpen(true);
  }

  const columns = [
    {
      headerName: "Vendor Name",
      field: "client_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      minWidth: 150,
    },
    {
      headerName: "Plant",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      minWidth: 150,
    },
    {
      headerName: "Part Details",
      field: "part_detail",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      minWidth: 150,
    },
    {
      headerName: "Report Number",
      field: "result_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.result_number ? (
              <Button
                size="small"
                color="primary"
                onClick={() => loadForm(params?.data)}
              >
                {`${params?.data?.result_number}`}
              </Button>
            ) : null}
          </>
        );
      },
      minWidth: 200,
    },
    {
      headerName: "Invoice Details",
      field: "dispatch_invoice_details",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Quantity",
      field: "dispatch_quantity",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Raw Material",
      field: "raw_material",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Heat Treatment",
      field: "heat_treatment_batch",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Surface Treatment",
      field: "surface_treatment_batch",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Tool Number",
      field: "tool_details",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
  ];
  return (
    <>
      <CommonGrid rows={rows} columns={columns} gridheight={110} />
      <DGFullScreenDrawer
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          ACTION_TYPE.view = false;
        }}
        //  Component={DispatchPlanForm}
        size="full"
        componentProps={selectedRow}
        needSave
      />
    </>
  );
}
