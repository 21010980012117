import http from "./httpService";

const createOP = async (data) => {
    return await http.post("/opl", data);
}

const updateOP = async (id, data) => {
    return await http.patch(`/opl/${id}`, data);
}

const getOPTable = async (data) => {
    return await http.get("/op-table", { params: { ...data } });
}

const getOPID = async (opid) => {
    return http.get("/op-opid", {
        params: {
            opid: opid,
        },
    });
};

export default {

    createOP,
    getOPTable,
    updateOP,
    getOPID
}