import React, { useContext, useEffect, useState } from "react";
import { Rules } from "../../../../components/cellRenderers/RulesRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from "../../../../context/AuthContextProvider";
import customerDashboardService from "../../../../services/customerDashboardService";
import { Link } from "react-router-dom";

import moment from "moment";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { Button } from "@material-ui/core";
import { DGDrawer } from "../../../../components/DGDrawer";
import { ResultForm } from "../../../productionInspection/product-inspection/Results/ResultForm";

export function CustomerPdiDetails() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  //const { globalPlant } = useContext(PlantContext);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    customerDashboardService.getCustomerPITable(currentUser?.id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);

  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      width: 100,
    },

    {
      headerName: "Vendor Name",
      field: "vendor_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      minWidth: 150,
    },
    {
      headerName: "Customer Name",
      field: "customer_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Vendor Plant",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Part Detail",
      field: "part_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      minWidth: 150,
      cellRenderer: (params) => (
        <>
          <Link
            style={{
              cursor: "pointer",
              color: "#2196f3",
            }}
            variant="body2"
            to={`/customer/characterstics/${params.data.part_id}`}
          >
            {`${params?.data?.part_number}-${params?.data?.part_name}`}
          </Link>
        </>
      ),
    },

    {
      field: "request_name",
      headerName: "Request Number",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => loadForm(params?.data)}
          >
            {`${params?.data?.request_name}`}
          </Button>
        );
      },
    },

    {
      field: "production_date",
      headerName: "Production Date",
      sortable: true,
      resizable: true,
      minWidth: "150",
      floatingFilter: true,
      autoHeight: true,
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        moment(new Date(params?.data?.production_date)).format("DD/MM/YYYY"),
    },

    {
      field: "production_time",
      headerName: "Production Time",
      sortable: true,
      resizable: true,
      minWidth: "150",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "request_result",
      headerName: "Result",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.request_result} />
      ),
    },

  ];
  // return <CommonGrid rows={rows} columns={columns} gridheight={220} />;

  function onRowSelection(data) {
    setSelectedRow(data);
  }

  function loadForm(data) {
    setSelectedRow(data);
    setFormOpen(true);
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={215}
        onRowSelect={onRowSelection}
      />
      <DGDrawer
        Component={ResultForm}
        title="Inspection Result"
        isOpen={formOpen}
        setOpen={(state) => {
          setFormOpen(state);
        }}
        subtitle={new Date().toLocaleDateString()}
        drawerWidth={1150}
        componentProps={selectedRow}
      />
    </>
  );
}