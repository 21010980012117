import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import PrintPageGridLayout from "./PrintPageGridLayout";
import CardAfterFooter from "./cards/cardAfterFooter";
import "./PrintPageContainer.css";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Toolbar,
} from "@material-ui/core";
import c1Logo from "../../assets/images/DigGemba.jpeg";
import userService from "../../services/userService";
import approvalService from "../../services/approvalService";
import Box from "@material-ui/core/Box";
import csrTemplateService from "../../services/csrTemplateService";

const PrintPageContainer = ({
  currentUser,
  studyTitle,
  studyData,
  gaugeCharacter,
  forNextPage,
}) => {
  const matchesPrint = useMediaQuery("print");
  const [client, setClient] = useState();
  const [createdUser, setCreatedUser] = useState();
  const [approvedUser, setApprovedUser] = useState();
  const [csrTemplateName, setCsrTemplateName] = useState("");

  useEffect(() => {
    currentUser && getClientDetails();
    studyData && getUserDetails();
    studyData && getApprovalDetails();
    gaugeCharacter && getCsrTemplate();
  }, [currentUser, studyData]);

  const getCsrTemplate = async () => {
    if (gaugeCharacter?.character?.part?.evaluation_guidelines) {
      const response = await csrTemplateService.getCsrTemplateById(
        gaugeCharacter.character.part.evaluation_guidelines
      );
      // console.log('CSR Response', response.data);
      if (response?.data?.template_name) {
        setCsrTemplateName(response.data.template_name);
      }
    }
  };

  const getApprovalDetails = async () => {
    const response = await approvalService.getApprovalsByEntityId(studyData.id);
    if (
      response?.data?.data?.length &&
      response.data.data[0]?.approver &&
      response.data.data[0]?.status_id !== 1
    ) {
      setApprovedUser(response.data.data[0].approver);
    }
  };

  const getClientDetails = async () => {
    const response = await userService.getClientById(currentUser.client_id);
    // console.log("Client Details", response.data);
    response?.data && setClient(response.data);
  };

  const getUserDetails = async () => {
    const response = await userService.getUser(studyData.created_by_id);
    // console.log("User Details", response.data);
    response?.data && setCreatedUser(response.data);
  };

  return (
    <React.Fragment style={{ pageBreakAfter: "always" }}>
      <Box
        display="flex"
        flexDirection="column"
        p={8}
        bgcolor="#fff"
        justifyContent="space-between"
        border={2}
        borderColor={"#ddd"}
        borderRadius={6}
      >
        <Box p={1} bgcolor="#fff">
          <Toolbar className="p-0">
            <div className="d-flex align-items-center justify-content-between w-100">
              <img
                style={{ width: "3.5rem", height: "3.5rem" }}
                src={client?.logo ? client.logo : ""}
                alt="Company 2 logo"
              />
              <Typography variant="h3" display="inline" className="p-0 m-0">
                {studyTitle}
              </Typography>
              <Typography variant="h6" display="inline" className="p-0 m-0">
                {client?.business_name ? client.business_name : ""}
              </Typography>
            </div>
          </Toolbar>
        </Box>
        <Box bgcolor="#fff">
          <PrintPageGridLayout
            studyTitle={studyTitle}
            studyData={studyData}
            gaugeCharacter={gaugeCharacter}
            forNextPage={false}
          />
        </Box>
        <Box bgcolor="#fff">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {createdUser ? (
              <p className="m-0 p-0">
                Prepared by: <b> {createdUser.name} </b>{" "}
              </p>
            ) : null}
            {approvedUser ? (
              <p className="m-0 p-0">
                Approved by: <b> {approvedUser.name} </b>{" "}
              </p>
            ) : null}
          </div>
        </Box>
      </Box>
      <Box
        bgcolor="#fff"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "6px",
        }}
      >
        <p
          className="m-0 p-0"
          style={{ fontSize: "12px", marginLeft: "4px", marginRight: "4px" }}
        >
          {new Date().toString()} Version 1.0.1 {csrTemplateName} Generated
          using https://www.digitalgemba.in
        </p>
      </Box>
    </React.Fragment>
  );
};

export default PrintPageContainer;
