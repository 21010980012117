import { AppBar, Button, Divider, Grid, Toolbar } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { useFormik } from "formik";
import { object, string } from "yup";
import piService from "../../../../services/piService";
import { green, red } from "@material-ui/core/colors";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";

export function Authorize({ componentProps = null, closeForm = () => { } }) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      comments: "",
      frequencies: null,
      location: "",
      report: "",
      gauge: "",
      freqoptions: [],
    },
    validationSchema: object().shape({
      comments: string().required(),
    }),
  });
  useEffect(() => {
    piService.getAllPIFrequencies().then((res) => {
      form.setFieldValue("freqoptions", res?.data?.data ?? []);
      if (
        res?.data?.data?.map(
          (exp) => exp?.name === componentProps?.calib_frequency
        )
      ) {
        var item = res?.data?.data?.find(
          (exp) => exp?.name === componentProps?.calib_frequency
        );
        form.setFieldValue("frequencies", item);
      }
    });
    form.setFieldValue("report", componentProps?.report_name ?? "");
    form.setFieldValue(
      "gauge",
      `${componentProps?.family_number}-${componentProps?.family_name} ` ?? ""
    );

    // console.log(componentProps);
  }, [componentProps]);

  function onSave(status, activity) {
    if (!form.values.comments) {
      form.setFieldError("comments", "Mandatory");
      return;
    }
    piService
      .createPIActivity({
        approved_by: currentUser?.id,
        comments: form.values.comments,
        status: status,
        pi_result_id: componentProps?.pi_result_id,
        activity: activity,
        guage_item: componentProps?.gauge_item_id,
        location: form.values?.location,
        pi_frequency_id: form.values.frequencies?.id,
      })
      .then((res) => {
        closeForm(true, { status: "s" });
        updateAlert({
          message: `Calibartion ${status} Successfully`,
          open: true,
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          message: `Failed to ${status === "Rejected" ? "Reject" : "Approve"
            } Calibration`,
          open: true,
          type: "error",
        });
      });
  }
  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          value={form.values.report}
          onChange={form.handleChange}
          label="Report Name"
          id="report"
          disabled
          isFormik
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          value={form.values.gauge}
          onChange={form.handleChange}
          label="Gauge"
          id="gauge"
          disabled
          isFormik
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          multiline
          rows={5}
          required
          value={form.values.comments}
          onChange={form.handleChange}
          label="Comments"
          id="comments"
          isFormik
          error={form.touched.comments && form.errors.comments}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          label="Calibration Frequencies"
          value={form.values.frequencies}
          onChange={(val) => form.setFieldValue("frequencies", val)}
          options={form.values.freqoptions}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          value={form.values.location}
          onChange={form.handleChange}
          label="Location"
          id="location"
          isFormik
        />
      </Grid>
      <AppBar position="fixed" style={{ width: 400, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          <Button
            style={{ margin: 4, backgroundColor: green[500] }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onSave("Approved", "approve")}
          >
            APPROVE
          </Button>
          <Button
            style={{ margin: 4, backgroundColor: red[500] }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onSave("Rejected", "approve")}
          >
            REJECT
          </Button>
        </Toolbar>
      </AppBar>
    </Grid>
  );
}
