import { Box, Card, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { GaugeAnalysis } from "./GaugeAnalysis";
import { GaugeInfo } from "./GaugeInfo";
import { ProductInfo } from "./ProductInfo";
import CardContent from "@material-ui/core/CardContent";
import { blue } from "@material-ui/core/colors";

export function GaugeRep({
  study_info = [{ report_number: "", study: "" }],
  product_info = [{ Part: "", Character: "", Specification: "" }],
  gauge_info = [
    { "Gauge Family": "", "Gauge Number": null, "Least Count": "" },
  ],
  test_info = [
    {
      "Study Date": "",
      Comments: "",
    },
  ],
}) {
  return (
    <>
      <div className="row mt-1 align-items-center" style={{backgroundColor:blue[100]}}>
      <div className="col-6">
          <Typography variant="h5">{study_info[0]?.study}</Typography>
        </div>
        <div className="col-6">
          <Typography variant="h5">{study_info[0]?.report_number}</Typography>
        </div>
      </div>
      <div  className="row mt-1" >
      <div className="col-4">
        {" "}
        <ProductInfo product_info={product_info} />
      </div>
      <div className="col-4">
        {" "}
        <GaugeInfo gauge_info={gauge_info} />
      </div>
      <div className="col-4">
        <GaugeAnalysis test_info={test_info} />
      </div>
      </div>
    </>
  );
}
{
  /* <Grid
        container
        item
        md={12}
        sm={12}
        lg={12}
        spacing={2}
      >
        <Grid item md={6} sm={6} lg={6}>
          <Typography variant="h5">
            Gauge Repeatability and Reproductibility study_info Report Number
          </Typography>
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <Typography variant="h5">{study_info[0]?.study}</Typography>
        </Grid>
        <Grid item md={2} sm={2} lg={2}>
          <Typography variant="h5">{study_info[0]?.report_number}</Typography>
        </Grid>
      </Grid>
      <Grid item md={4} sm={4} lg={4}  style={{ height: "100%",width:"32%" }} >
        <Box style={{ height: "100%",width:"100%" }}>
            <ProductInfo product_info={product_info} />
        </Box>
      </Grid>
      <Grid item md={4} sm={4} lg={4}  style={{ height: "100%",width:"32%" }} >
        <Box  style={{ height: "100%",width:"100%" }}>
            <GaugeInfo gauge_info={gauge_info} />
        </Box>
      </Grid>
      <Grid item md={4} sm={4} lg={4}  style={{ height: "100%",width:"32%" }}>
        <Box  style={{ height: "100%",width:"100%" }}>
            <GaugeAnalysis test_info={test_info} />
        </Box>
      </Grid> */
}
