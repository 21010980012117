import { Box, Button, Grid, Divider, AppBar, Toolbar } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { object, string } from "yup";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGInput } from "../../../components/shared/DGInput";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { AlertContext } from "../../../context/AlertContextProvider";


export function RequestForm1({
    Footer = () => { },
    closeForm = () => { },
}) {
    const { v4: uuidv4 } = require("uuid");
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { updateAlert } = useContext(AlertContext);

    let form = useFormik({
        initialValues: {
            partOptions: [],
            partSelected: null,
            planSelected: null,
            tableData: [],
            planOptions: [],
            pdiStandard: [],
            pdiPlanDetails: null,
            pdiRequestCount: 0,

        }
    });


    useEffect(() => {
        disptachInspection
            .getClientPDITable({
                client_id: currentUser?.client_id
            })
            .then((res) => {
                const uniqueArray = res.data.plan_table
                    .filter(item => item.enabled === 1)
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => t.part_id === item.part_id)
                    );
                form.setFieldValue("partOptions", uniqueArray);
                form.setFieldValue("tableData", res.data.plan_table);

            });
    }, []);

    useEffect(() => {
        if (form.values.partSelected !== null) {
            const plan = form.values.tableData.filter(
                (item) =>
                    item.part_id === form.values.partSelected.part_id &&
                    item.enabled === 1
            );
            form.setFieldValue("planOptions", plan);
        }

    }, [form.values.partSelected]);


    useEffect(() => {
        if (form.values.planSelected?.id) {
            disptachInspection.getPDIPlanDetails(form.values.partSelected?.id)
                .then((res) => {
                    form.setFieldValue("pdiStandard", res?.data?.pdi_standard);
                    form.setFieldValue("pdiPlanDetails", res?.data?.plan_details);
                    form.setFieldValue("pdiRequestCount", res?.data?.pdi_result_count);

                })
        }

    }, [form.values.planSelected]);

    useEffect(() => {
        if (form.values.pdiStandard.length > 0) {
            form.setFieldValue(`pdiStandard[1].default_value`,
                form.values.partSelected?.part_number
                + " " + form.values.partSelected?.part_name);
        }

    }, [form.values.pdiStandard]);



    function createPDIRequest(showEvalForm = false) {
        const pdiID = uuidv4();

        const data1 = {
            id: pdiID,
            client_id: currentUser?.client_id,
            plant_id: globalPlant.globalPlant?.id,
            part_id: form.values.partSelected?.part_id,
            pdi_dispatch_plan_id: form.values.planSelected?.id,
            pdi_standard_list_id: form.values.pdiPlanDetails?.pdi_standard_plan_id,
            created_by: currentUser?.id,
            pdi_result_number: form.values.pdiRequestCount?.pdi_result_count + 1,
            enabled: 1,
            pdi_result_status: 2
        }

        console.log(form.values.pdiStandard);

        const data2 = form.values.pdiStandard.map((item) => ({
            id: uuidv4(),
            pdi_result_id: pdiID,
            pdi_standard_list_id: form.values.pdiPlanDetails?.pdi_standard_plan_id,
            pdi_standard_item_id: item.pdi_std_item_id,
            standard_value: item.default_value,
        }));



        disptachInspection.createPDIRequest(data1).then((res) => {
            if (res?.status === 201) {
                disptachInspection.createPDIRequestStd(data2).then((res) => {
                    if (res?.status === 201) {
                        updateAlert({
                            message: "Dispatch Request Created Successfully",
                            open: true,
                            type: "success",
                        });

                        if (showEvalForm) {
                            closeForm(false, pdiID);
                        } else {
                            closeForm(false, null);
                        }


                        closeForm && closeForm(false);
                    } else {
                        updateAlert({
                            message: "Failed to create Dispatch Request",
                            open: true,
                            type: "error",
                        });
                    }
                })
            }
            else {
                updateAlert({
                    message: "Failed to create Dispatch Request",
                    open: true,
                    type: "error",
                });
            }
        })

    }



    return (

        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DGSelect
                    id="part"
                    label="Part"
                    options={form.values.partOptions}
                    getOptionLabel={(option) => `${option.part_number} ${option.part_name}`}
                    value={form.values.partSelected}
                    onChange={(val) => {
                        form.setFieldValue("partSelected", val);
                        form.setFieldValue("planSelected", null);
                        form.setFieldValue("pdiStandard", []);
                    }}

                />

            </Grid>

            <Grid item xs={6}>
                <DGSelect
                    id="part"
                    label="Inspection Plan"
                    options={form.values.planOptions}
                    getOptionLabel={(option) => `${option.ref_id}`}
                    value={form.values.planSelected}
                    onChange={(val) => {
                        form.setFieldValue("planSelected", val);
                        form.setFieldValue("pdiStandard", []);
                    }}

                />

            </Grid>
            <Grid container spacing={2} style={{ "margin-top": "25px" }}>
                {form.values.pdiStandard.map((item, index) => (
                    <Grid item xs={6} key={index}>
                        {item.pdi_std_item_id === "3012459c-24ae-4015-95e6-3ad15d3ca365" ? (
                            <DGDateInput
                                label={item.item_name}
                                value={item.default_value}
                                onChange={(val) => {
                                    form.setFieldValue(`pdiStandard[${index}].default_value`, val);
                                }}
                            />
                        ) : item.pdi_std_item_id === "7e373bba-fc6a-4f6b-b7f0-7fca72926759" ? (
                            <DGInput
                                label={item.item_name}
                                disabled={true}
                                value={form.values.partSelected?.part_number + " " + form.values.partSelected?.part_name}

                            />
                        ) : item.item_group_id !== 3 ? (
                            <DGInput
                                label={item.item_name}
                                value={item.default_value}
                                onChange={(val) => {
                                    form.setFieldValue(`pdiStandard[${index}].default_value`, val);
                                }}

                            />
                        ) : null}
                    </Grid>


                ))}
            </Grid>



            <AppBar position="fixed" style={{ width: 900, top: "auto", bottom: 0 }}>
                <Divider />
                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>

                    <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => closeForm && closeForm(false)}
                    >
                        CANCEL
                    </Button>

                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => createPDIRequest(false)}
                    >
                        Intiate & Close
                    </Button>

                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => createPDIRequest(true)}
                        disabled={!form.isValid}
                    >
                        Record Measurement
                    </Button>
                </Toolbar>
            </AppBar>

        </Grid>

    )

}
