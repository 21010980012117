import { makeStyles } from "@material-ui/core";
import { blueGrey, green, grey, red } from "@material-ui/core/colors";
import { rgba } from "polished";
import React from "react";
const useStyle = makeStyles({
  spec:{ fontWeight:"bold",background:rgba(blueGrey[600], 0.1), padding:"2px 8px",borderRadius:"3px",margin:5,cursor:"pointer"}
})
function ActiveInActive({status=-1,onClick=()=>{},disabled=false}) {
  const classes = useStyle();
  return (
    <>
    {status>-1?<span    
      style={{ color:disabled?grey[500]:(status === 1 && !disabled ? green[500] : red[500]) }}
      className={classes.spec}
      onClick={disabled?()=>{}:onClick}
    >
      {status=== 1 ? "Active" : "InActive"}
    </span>:null}
    </>
  );
}
export default ActiveInActive
