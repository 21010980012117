import React, { useState, createContext } from "react";

const StatusHistoryContext = createContext({
  isOpen: false,
  rowId: "",
  entity: "",
  setStatusHistory: () => {},
});

export const StatusHistoryContextProvider = ({ children }) => {
  const [status, setStatusHistory] = useState({
    isOpen: false,
    rowId: "",
    entity: "",
  });

  return (
    <StatusHistoryContext.Provider value={{...status,setStatusHistory}}>
      {children}
    </StatusHistoryContext.Provider>
  );
};

export default StatusHistoryContextProvider;
export { StatusHistoryContext };
