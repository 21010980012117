import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import processParameter from "../../../../services/processParameter";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";

import { DGDrawer } from "../../../../components/DGDrawer";
import { ProcessParameterForm } from "./ProcessParamterForm";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../../components/ActiveInActive";
import { StatusContext } from "../../../../context/StatusContextProvider";
import StatusFilter from "../../../../components/shared/StatusFilter";

export function ProcessParameter({ partId = "" }) {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);

  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData() {
    processParameter.ProcessParameterPartTable(partId).then((res) => {
      setRows(res?.data ?? []);
    });
  }
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "machine_name",
      headerName: "Machine",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        `${params?.data?.machine_number}-${params?.data?.machine_name}`,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "parameter_name",
      headerName: "Process Parameter",
      flex: 1,
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
    },
    {
      field: "parameter_unit",
      headerName: "Unit",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "Specification",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      valueGetter: (params) =>
        `${params?.data?.target ?? ""} 
        ${params?.data?.upper_limit ?? ""}
         ${params?.data?.lower_limit ?? ""}`,


      resizable: true,
      minWidth: 200,
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => {
        return (
          <ActiveInActive
            status={params?.data?.enabled}
            onClick={() =>
              setStatus((prev) => ({
                ...prev,
                open: true,
                callback: UpdateProcessPartParameter_,
                reloadGrid: reloadGridData,
                entityName: params?.data?.parameter_name ?? "",
                currentStatus: params?.data?.enabled,
                rowId: params?.data?.id,
              }))
            }
          />
        );
      },
    },

    {
      field: "enabled",
      headerName: "Status History",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.id,
                entity: params?.data?.parameter_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },

  ];

  function UpdateProcessPartParameter_(dummy1, dummy2, newStatus, rowId) {
    return processParameter.UpdateProcessPartParameter(rowId, { enabled: newStatus });
  }


  return (
    <>
      <CommonGrid
        coldefs={{
          filter: true,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
            enabled: { filter: 1 },
          },
        }}
        rows={rows}
        columns={columns}
        gridheight={210}
        ButtonComponent={() => (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setFormOpen(!formOpen);
            }}
          >
            Process Parameter
          </Button>
        )}
      />
      <DGDrawer
        Component={ProcessParameterForm}
        title="Process Parameter"
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        drawerWidth={800}
        componentProps={{ partId: partId }}
        needSave
      />
    </>
  );
}
