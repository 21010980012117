import { Grid, Typography, Button } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import processCapability from "../../../../services/processCapability";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { PageTitleContext } from "../../../../context/PageTitleContextProvider";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { DGDrawer } from "../../../../components/DGDrawer";
import CpkDetailForm from "./CpkDetail";
import { format } from 'date-fns';
import CpkTrendForm from "./CpkTrend";


export function ProcessCapabilityListing() {
  const [rows, setRows] = useState();
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const { currentUser } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cpkEvalTable, setCpkEvalTable] = useState(false);
  const [cpkTrend, setCpkTrend] = useState(false);
  const { globalPlant } = useContext(PlantContext);


  setHeaderPageTitle("Process Capability");

  //console.log(rows, "rows");


  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      pinned: "left",
    },


    {
      headerName: "Part",
      pinned: "left",
      minWidth: 300,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      cellRenderer: (params) => {
        return (
          <>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {params?.data?.part_number}
            </Typography>

            <Typography variant="body2" >
              {params?.data?.part_name}
            </Typography>

          </>
        )
      },
    },

    {
      headerName: "Specification",
      resizable: true,
      pinned: "left",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 250,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <>
            <Typography variant="body2" style={{ marginBottom: '-8px', }}>
              {params?.data?.character_name}
            </Typography>

            <Button
              size="small"
              color="primary"
              lineHeight={15}
              style={{ padding: 0 }}
              onClick={() => {
                setCpkTrend(true);
                setSelectedRow(params?.data)
              }}
            >
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {`${params?.data?.specification}  
                            ${params?.data?.upper_specification ?? ""
                  }  ${params?.data?.lower_specification ?? ""}`}
              </Typography>
            </Button>


            <Typography variant="body2" style={{ marginTop: '-8px', marginBottom: '4px' }}>
              {`${params?.data?.machine_name}  ${params?.data?.spindle_name}`}
            </Typography>


          </>
        )
      },
    },

    {
      field: "cpk",
      headerComponent: () => (
        <>
          <p>
            C<sub>pk</sub>
          </p>
        </>
      ),
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 130,
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            lineHeight={15}
            style={{ padding: 0 }}
            onClick={() => {
              setCpkEvalTable(true);
              setSelectedRow(params?.data)
            }}
          >
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {parseFloat(params?.data?.cpk).toFixed(2)}
            </Typography>
          </Button>
        )
      }
    },
    {
      field: "cpk_result",
      headerName: "Result",
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.cpk_result} />
      ),
      resizable: true,
      minWidth: 150,
    },

    {
      headerName: 'Evaluation Details',
      children: [

        {
          field: "created_date",
          headerName: "Evaluation Date",
          columnGroupShow: 'null',
          minWidth: 180,
          valueGetter: (params) =>
            format(new Date(params?.data?.created_date), 'dd/MM/yyyy'),


        },
        {
          field: "start_timestamp",
          headerName: "Start Date",
          minWidth: 180,
          filter: "agDateColumnFilter",
          floatingFilter: true,
          valueGetter: (params) =>
            format(new Date(params?.data?.start_timestamp), 'dd/MM/yyyy'),
          resizable: true,
          filterParams: filterParams,
          sorting: true,
          columnGroupShow: 'open',
        },
        {
          field: "end_timestamp",
          headerName: "End Date",
          minWidth: 180,
          filter: "agDateColumnFilter",
          floatingFilter: true,
          valueGetter: (params) =>
            format(new Date(params?.data?.end_timestamp), 'dd/MM/yyyy'),
          resizable: true,
          filterParams: filterParams,
          sorting: true,
          columnGroupShow: 'open',
        },
        {
          field: "sample_size",
          headerName: "Sample Count",
          editable: false,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          resizable: true,
          minWidth: 200,
          columnGroupShow: 'open',
        },
        {
          field: "probability_distribution",
          headerName: "Probability Distribution",
          editable: false,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          resizable: true,
          minWidth: 230,
          columnGroupShow: 'open',
        },
        {
          field: "cp",
          headerComponent: () => (
            <>
              <p>
                C<sub>p</sub>
              </p>
            </>
          ),
          editable: false,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          valueGetter: (params) =>
            parseFloat(params?.data?.cp)
              ? `${parseFloat(params?.data?.cp).toFixed(2)}`
              : "",
          resizable: true,
          minWidth: 200,
          columnGroupShow: 'open',
        },
      ]
    },
  ];


  useEffect(() => {
    processCapability.getCPKTable({
      client_id: currentUser?.client_id,
      id: globalPlant?.globalPlant?.id
    }).then((res) => {
      //console.log(res?.data, "res?.data");
      setRows(res?.data);
    });
  }, [globalPlant?.globalPlant?.id]);


  return (
    <Grid container>
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          needExport
          rows={rows}
          columns={columns}
          gridheight={100}
        />
      </Grid>

      <DGDrawer
        isOpen={cpkEvalTable}
        setOpen={() => {
          setCpkEvalTable(false);
        }}
        Component={CpkDetailForm}
        drawerWidth={1200}
        title="Process Capability"
        componentProps={selectedRow}
      />

      <DGDrawer
        isOpen={cpkTrend}
        setOpen={() => {
          setCpkTrend(false);
        }}
        Component={CpkTrendForm}
        drawerWidth={1000}
        title="Process Capability Trend"
        componentProps={selectedRow}
      />
    </Grid>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
