import { Box, Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { object, string } from "yup";
import CommonGrid from "../../../components/CommonGrid";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import plantService from "../../../services/plantService";
import { DateEditor } from "../../../components/cellRenderers/DateEditor";
import { pl } from "date-fns/locale";
const { v4: uuidv4 } = require("uuid");


export function CheckListFormNew({
  Footer = () => { },
  closeForm = () => { },
  componentProps = null,
}) {
  const [rows, setRows] = useState([]);
  const [gridapi, setGridApi] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const { globalPlant } = useContext(PlantContext);

  let form = useFormik({
    initialValues: {
      plan_name: "",

      plantOptions: [],
      plantSelected: [],
      clientSelected: "",

    },
    validationSchema: object({
      plan_name: string().required(),
    }),
    onSubmit: createStandardChecklist,
  });

  let detailForm = useFormik({
    initialValues: {
      checkpoint: "",
      requirement: null,
      defaultvalue: "",
    },
    validationSchema: object({
      checkpoint: string().required(),
      requirement: object().required(),
    }),
    onSubmit: AddUserDefinedplan,
  });

  function createStandardChecklist() {
    let rowDatas = [];
    gridapi.forEachNode((node) => {
      let data = node?.data;
      rowDatas?.push({
        ...data,
        client_id: currentUser?.client_id,
        plant_id: form.values.plantSelected
          ? form.values.plantSelected.id
          : null,
        plan_name: form.values.plan_name,
        created_by: currentUser?.id,
      });
    });

    disptachInspection
      .createStandardPDI(rowDatas)
      .then((res) => {
        closeForm();
        updateAlert({
          message: "Standard Checklist Created SuccessFully.",
          open: true,
          type: "success",
        });
      })
      .catch((exp) => {
        updateAlert({
          message: "Failed to create Standard Checklist.",
          open: true,
          type: "error",
        });
      });

  }
  const columns = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      minWidth: 70,
    },
    {
      headerName: "Check Point",
      field: "item_name",
      resizable: true,
      editable: (params) =>
        params?.data?.order_number > 30
    },
    {
      headerName: "Requirement",
      field: "item_mandatory",
      editable: (params) =>
        params?.data?.item_group_id !== 1 &&
        params?.data?.item_group_id !== 3,
      resizable: true,
      valueGetter: (params) => {
        if (params?.data?.item_mandatory === 1) {
          return "Mandatory";
        } else if (params?.data?.item_mandatory === 2) {
          return "Optional";
        } else if (params?.data?.item_mandatory === 3) {
          return "Not Required";
        } else {
          return null;
        }
      },
      filter: "agTextColumnFilter",
      valueSetter: (params) => {
        if (params?.newValue === "Mandatory") {
          params.data.item_mandatory = 1;
        } else if (params?.newValue === "Optional") {
          params.data.item_mandatory = 2;
        } else if (params?.newValue === "Not Required") {
          params.data.item_mandatory = 3;
        }
        return true;
      },
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Mandatory", "Optional", "Not Required"],
      },
    },
    {
      headerName: "Default Value",
      field: "default_value",
      resizable: true,
      editable: true,
      cellEditorSelector: (params) => {
        if (params?.data?.item_name === "Invoice Date") {
          return {
            component: DateEditor,
          };
        } else {
          return {
            component: "agTextCellEditor",
          };
        }
      },
    },
    {
      headerName: "Group",
      field: "item_group_id",
      resizable: true,
      valueGetter: (params) => `Header ${params?.data?.item_group_id}`,
      editable: (params) =>
        params?.data?.order_number > 30,
    },
  ];
  function AddUserDefinedplan(values) {
    let rowdata = {
      item_id: uuidv4(),
      item_name: values?.checkpoint,
      item_mandatory: values?.requirement?.id,
      default_value: values?.defaultvalue,
      item_group_id: 4,
      order_number: rows?.length + 1,
    };
    setRows((prev) => [...prev, rowdata]);
    detailForm?.resetForm();
  }

  useEffect(() => {
    plantService
      .getPlantlist(currentUser?.client_id, 1)

      .then((res) => {
        form.setFieldValue("plantOptions", res?.data ?? []);

      });


    disptachInspection.getStandardPlanPrefill().then((res) => {
      setRows(res?.data ?? []);
    });

  }, []);


  return (
    <Grid container spacing={2}>

      <Grid item xs={6}>
        <DGSelect
          label="Plant Name"
          id="plantSelected"
          value={form.values.plantSelected}
          getOptionLabel={(option) => option?.name || ''}
          options={form.values.plantOptions}
          onChange={(value) => {
            form.setFieldValue("plantSelected", value);
          }}

        />
      </Grid>
      <Grid item xs={6}>
        <DGInput
          label="Plan Name"
          onChange={form.handleChange}
          value={form.values.plan_name}
          id="plan_name"
          required
          isFormik
          error={form.touched.plan_name && form.errors.plan_name}
        />
      </Grid>
      <Grid container item md={12} sm={12} lg={12} spacing={2}>
        <Grid item md={12} lg={12} sm={12}>
          <Box fontWeight="fontWeightBold" m={1}>
            User Defined Plan
          </Box>
        </Grid>
        <Grid item md={4} lg={4} sm={4}>
          <DGInput
            label="Check Point"
            isFormik
            id="checkpoint"
            onChange={detailForm.handleChange}
            value={detailForm.values.checkpoint}
            error={
              detailForm.touched.checkpoint && detailForm.errors.checkpoint
            }
            required
          />
        </Grid>
        <Grid item md={4} lg={4} sm={4}>
          <DGSelect
            label="Requirement"
            options={[
              { name: "Mandatory", id: 1 },
              { name: "Optional", id: 2 },
              { name: "Not Required", id: 3 },
            ]}
            value={detailForm.values.requirement}
            onChange={(val) => detailForm.setFieldValue("requirement", val)}
            required
            error={
              detailForm.touched.requirement && detailForm.errors.requirement
            }
          />
        </Grid>
        <Grid item md={4} lg={4} sm={4}>
          <DGInput
            label="Default Value"
            isFormik
            id="defaultvalue"
            onChange={detailForm.handleChange}
            value={detailForm.values.defaultvalue}
            error={
              detailForm.touched.defaultvalue &&
              detailForm.errors.defaultvalue
            }
          />
        </Grid>
        <Grid item md={12} lg={12} sm={12}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disabled={
              !(detailForm.values.checkpoint && detailForm.values.requirement)
            }
            className="mr-2"
            onClick={detailForm.handleSubmit}
          >
            ADD
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={detailForm.resetForm}
          >
            CLEAR
          </Button>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          needExport={false}
          columns={columns}
          rows={rows}
          gridheight={280}
          singleClickEdit
          onGridReady={(params) => setGridApi(params?.api)}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
