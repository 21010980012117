/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import machineService from "../../../../services/machineService";
import CommonGrid from "../../../../components/CommonGrid";
import { Link } from "react-router-dom";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../../components/ActiveInActive";
import { AuthContext } from "../../../../context/AuthContextProvider"
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { ProcessParameterNewForm } from "./ProcessParameterNewForm"
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { ResultForm } from "../../../productionInspection/product-inspection/Results/ResultForm";


const ProcessPerformance = (machine_id = "") => {
    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [selectedRow, setSelectedRow] = useState(null);



    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: loadList,
        }));
    }, []);

    useEffect(() => {
        loadList()
    }, []);

    function loadForm(data) {
        setSelectedRow(data);
        setFormOpen(true);
    }


    function loadList() {
        return machineService.getMachineProcessPerformance(machine_id?.machine_id)
            .then((res) => {
                //  console.log("machine_list", res.data)
                setRows(res?.data ?? []);
            });
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 150
        },
        {
            field: "request_name",
            headerName: "Request Number",
            minWidth: 150,
            filter: "agTextColumnFilter",
            // valueGetter: (params) =>
            //   `${params?.data?.id_plan}-${params?.data?.plan_name}`,
            floatingFilter: true,
            resizable: true,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => loadForm(params?.data)}
                    >
                        {`${params?.data?.request_name}`}
                    </Button>
                );
            },
        },

        {
            headerName: "Part Name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 200,
            valueGetter: (p) => {
                return p?.data?.part_number + " - " + p?.data?.part_name;
            },
        },
        {
            headerName: "Parameter",
            flex: 1,
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 200,
            valueGetter: (p) => {
                return p?.data?.parameter_name + " (" + p?.data?.parameter_unit + ")";
            },
        },
        {
            headerName: "Specification",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 200,
            valueGetter: (params) =>
                `${params?.data?.target ?? ""} 
                 ${params?.data?.upper_limit ?? ""}
                 ${params?.data?.lower_limit ?? ""}`,
        },
        {
            headerName: "Raw Data",
            //field: "raw_data",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 200,
            valueGetter: (params) => {
                if (params?.data?.measurement_type === 303) {
                    let i = 0
                    const raw_data = []
                    for (let i = 0; i < params?.data.raw_data.length; i++) {
                        const value = params?.data.raw_data[i];
                        if (value === 1) {
                            raw_data.push('OK');
                        } else if (value === 0) {
                            raw_data.push('NOT OK');
                        } else {
                            raw_data.push('');
                        }
                    }
                    return raw_data
                } else {
                    return params?.data.raw_data;
                }
            },

        },

        {
            field: "request_result",
            headerName: "Result",
            sortable: true,
            resizable: true,
            minWidth: "100",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
                <ResultRenderer value={params?.data?.request_result} />
            ),
        },

    ];

    return (
        <>
            <Paper>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={100}
                />
            </Paper>
            <DGDrawer
                Component={ResultForm}
                title="Inspection Result"
                isOpen={formOpen}
                setOpen={(state) => {
                    setFormOpen(state);
                }}
                subtitle={new Date().toLocaleDateString()}
                drawerWidth={1160}
                componentProps={{ ...selectedRow, request_id: selectedRow?.id }}
            />
        </>
    );
};

export default ProcessPerformance;
