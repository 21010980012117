import { Button, Grid, Card, CardContent, } from "@material-ui/core";
import React from "react";
import { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { StatusContext } from "../../../context/StatusContextProvider";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { RequestForm } from "../../productionInspection/product-inspection/UnderInspection/RequestForm";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import { MacineMaintenanceRequestCreate } from "../../machines/machineMaintanance/machineMaintenanceRequest/macineMaintenanceRequestCreate";
import MachineMaintenanaceRequestView from "../../machines/machineMaintanance/machineMaintenanceRequest/machineMaintenanceRequestView";
import machineMaintenanceService from "../../../services/machineMaintenanceService";
import { set } from "date-fns";



export default function GembaMachineMaintenanceList() {


    const [rows, setRows] = useState([]);
    const [requestFormOpen, setRequestFormOpen] = useState(false);
    const [recordFormOpen, setRecordFormOpen] = useState(false);
    // const { setStatusHistory } = useContext(StatusHistoryContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { setStatus } = useContext(StatusContext);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow_, setSelectedRow_] = useState(null);
    const [maxID, setMaxID] = useState(0);





    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            // callback: changeStatus,
        }));
        reloadGridData();

    }, []);

    useEffect(() => {

        reloadGridData()
    }, [currentUser, globalPlant]);



    function reloadGridData() {
        if (!currentUser || !globalPlant) return;

        machineMaintenanceService.getMachineMaintenanceRequestG(
            currentUser.client_id,
            globalPlant?.globalPlant?.id)

            .then((res) => {
                const data = res?.data ?? []
                setMaxID(data.reduce((max, item) => item.request_number > max ? item.request_number : max, data[0].request_number))
                setRows(data);
            })
    }


    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        {
            field: "request_number",
            headerName: "Request Number",
            filter: "agTextColumnFilter",
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            loadRequestForm(data)
                        }}
                    >
                        Request# {params?.data?.request_number}
                    </Button>
                );
            },
        },
        {
            field: "plan_number",
            headerName: "Plan Number",
            minWidth: 300,
            sorting: true,
            resizable: true,
            cellRenderer: (params) => {
                return (
                    <span>{`${params?.data?.plan_number} / ${params?.data?.plan_name}`}</span>
                );
            },
        },

        {
            field: "machine_name",
            headerName: "Machine",
            minWidth: 200,
            resizable: true,
            sorting: true,
            cellRenderer: (params) => {
                if (params.data.machine_number) {
                    return `${params?.data?.machine_number} / ${params?.data?.machine_name}`
                } else {
                    return ""
                }
            }
        },
        {
            field: "evaluation_date",
            headerName: "Evaluation Date",
            minWidth: 150,
            resizable: true,
            filter: "agDateColumnFilter",
            floatingFilter: true,
            valueGetter: (params) => {
                return new Date(params?.data?.evaluation_date).toLocaleDateString();
            },


        },

        {
            field: "request_result",
            headerName: "Result",
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
                <ResultRenderer value={params?.data?.request_result} />
            ),

        },

    ]



    function getReqDetails(id) {

        machineMaintenanceService.getMachineMaintenanceRequestId(
            id
        )
            .then((res) => {
                //    console.log(res.data)
                //    console.log((res.data)[0])
                setSelectedRow_((res.data)[0])
            })
            .catch((err) => {
                console.log(err)
            })


    }




    function loadRequestForm(data) {
        setRecordFormOpen(true);
        setSelectedRow(data?.id);
        setSelectedRow_(data);
    }

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <Card style={{ border: '1px solid #ccc' }}>
                        <CardContent>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => setRequestFormOpen(true)}
                            >
                                New Evaluation
                            </Button>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid item xs={12}>

                    <CommonGrid
                        columns={columns}
                        rows={rows}
                        gridheight={115}
                        needExport={false}
                    />

                </Grid>

            </Grid>

            <DGDrawer
                isOpen={requestFormOpen}
                setOpen={(state, reqID) => {

                    if (reqID) {
                        setRequestFormOpen(state);
                        setSelectedRow_(reqID.id);
                        setRecordFormOpen(true);
                        getReqDetails(reqID.id)
                    } else {
                        setRequestFormOpen(state);
                    }
                    reloadGridData();
                }}
                title="Maintenance Request"
                drawerWidth={1000}
                Component={MacineMaintenanceRequestCreate}
                gemmbaRequest={true}

            />

            <DGDrawer
                isOpen={recordFormOpen}
                setOpen={() => {
                    setRecordFormOpen(false);
                    reloadGridData()
                }}
                // title={selectedRow_?.machine_number + " / " + selectedRow_?.machine_name}
                title={`Request Number: ${selectedRow_?.request_number}`}
                drawerWidth={1200}
                rowHeight={150}
                Component={MachineMaintenanaceRequestView}
                componentProps={selectedRow}

            />
        </>

    )


}