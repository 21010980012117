import http from "./httpService";


const createMachineMaintenanceQ = async (data) => {
    return await http.post("/machine-maintenanceQ", data);
}

const getMachineMaintenanceQ = async (client_id) => {
    return await http.get("/machine-maintenanceQ-table", {
        params: {
            client_id: client_id,
        },
    });
};


const createMachineMentananceCLP = async (data) => {
    return await http.post("/machine-maintenanceCLP", data);
}

const createMachineMentananceCLPQ = async (data) => {
    return await http.post("/machine-maintenanceCLPQ", data);
}


const UpdateMachineMentananceCLP = async (id, data) => {
    return http.patch(`/machine-maintenanceCLP/${id}`, data);
}

const updateMachineMaintenanceQ = (id, data) => {
    return http.patch(`/machine-maintenanceQ/${id}`, data);
};

const getMachineMaintenanceCLP = async (client_id, plant_id) => {
    return await http.get("/machine-maintenanaceCLPQ-table", {
        params: {
            client_id: client_id,
            plant_id: plant_id,
        },
    });
}

const getMachineMaintenanceCLPPlan = async (id) => {
    return await http.get("/machine-manitenanceCLPPlan", {
        params: {
            plan_id: id,
        },
    });
}

const createMachineMaintenanceCLPReq = async (data) => {
    return http.post("/machine-maintenanceCLPReq", data);
}

const createMachineMaintenanceCLPRes = async (data) => {
    return http.post("/machine-maintenanceCLPRes", data);
}

const getMachineMaintenanceRequest = async (client_id, plant_id) => {
    return http.get("/machine-maintenanceCLPReqTable", {
        params: {
            client_id: client_id,
            plant_id: plant_id,
        },
    });
}

const getMachineMaintenanceRequestG = async (client_id, plant_id) => {
    return http.get("/machine-maintenanceCLPReqTableG", {
        params: {
            client_id: client_id,
            plant_id: plant_id,
        },
    });
}

const getMachineMaintenanceRequestId = async (id) => {
    return http.get("/machine-maintenanceCLPReqId", {
        params: {
            id: id,
        },
    });
}

const getMachineMAintenanceCLPRes = async (clpRequest_id) => {
    return http.get("/machine-maintenanceCLPResTable", {
        params: {
            clpRequest_id: clpRequest_id,
        },
    });
}


const updateMachineMaintenanceCLPRes = (id, data) => {
    return http.patch(`/machine-maintenanceCLPRes/${id}`, data);
};

const updateMachineMaintenanceCLPReq = async (id, data) => {
    return http.patch(`/machine-maintenanceCLPReq/${id}`, data);
}




export default {

    getMachineMaintenanceQ,
    createMachineMaintenanceQ,
    updateMachineMaintenanceQ,
    getMachineMaintenanceCLP,
    createMachineMentananceCLP,
    createMachineMentananceCLPQ,
    getMachineMaintenanceCLPPlan,
    UpdateMachineMentananceCLP,
    createMachineMaintenanceCLPReq,
    createMachineMaintenanceCLPRes,
    getMachineMaintenanceRequest,
    getMachineMAintenanceCLPRes,
    updateMachineMaintenanceCLPRes,
    updateMachineMaintenanceCLPReq,
    getMachineMaintenanceRequestId,
    getMachineMaintenanceRequestG


}