/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import { useEffect, useContext, useState } from "react";
import { object, string, array } from "yup";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AuthContext } from "../../../context/AuthContextProvider";
import dateService from "../../../services/dateService";
import machineService from "../../../services/machineService";
import plantService from "../../../services/plantService";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider"
import CommonGrid from "../../../components/CommonGrid"
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer"
const { v4: uuidv4 } = require("uuid");

export function MachineForm({
  Footer = () => { },
  closeForm = () => { },
  componentProps = null,
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [gridApi, setGridApi] = useState(null);
  const globalplant = useContext(PlantContext);


  let form = useFormik({
    initialValues: {
      number: null,
      name: null,
      plant: null,
      plantOptions: [],
      gridData: [],
      spindle: "",
      plant_flag: null
    },
    validationSchema: object({
      number: string().required(),
      name: string().required(),
      gridData: array().min(1, 'At least one item is required in the grid data.'),
    }),
    onSubmit: saveForm,
  });


  useEffect(() => {
    plantService.getDefaultPlant(currentUser?.id)
      .then((res) => {
        const plantOptions = (res?.data ?? []).slice(1);
        form.setFieldValue("plantOptions", plantOptions);
        if (plantOptions.length < 2 || currentUser.user_role > 3) {
          form.setFieldValue("plant", (globalplant?.globalPlant?.globalPlant));
          form.setFieldValue("plant_flag", 0)
        } else {
          if (currentUser.default_plant) {
            const defaultPlant = plantOptions.find(plant => plant.default_flag === 1)
            form.setFieldValue("plant", defaultPlant)
            form.setFieldValue("plant_flag", 1)
          } else {
            const defaultPlant = plantOptions[0]
            form.setFieldValue("plant", defaultPlant)
            form.setFieldValue("plant_flag", 2)
          }
        }
      });
  }, [currentUser?.id]);


  function onGridReady(params) {
    setGridApi(params?.api);
  }



  function onAdd() {
    let data = [];
    data?.push({
      id: uuidv4(),
      spindle: form.values.spindle

    })
    form.setFieldValue("gridData", [...form.values.gridData, ...data]);
  }

  function removeDetailRow(rowIndex, data) {
    const updatedGridData = [...form.values.gridData];
    updatedGridData.splice(rowIndex, 1);
    form.setFieldValue("gridData", updatedGridData);
    gridApi?.updateRowData({ remove: [data] });
  }

  function spindlePayload(machine_id) {
    let payload = [];
    gridApi.forEachNode((node) => {
      let data = node.data;
      payload.push({
        id: uuidv4(),
        spindle_name: data.spindle,
        machine_id: machine_id,
        comments: data.comments,
      });
    });
    if (payload.length > 0) {
      machineService.createMachineSpindle(payload)

    }
  }

  // console.log(form.values.gridData)

  function saveForm(values) {
    const machine_id = uuidv4()
    const payload = {
      id: machine_id,
      name: form.values.name,
      machine_number: form.values.number,
      plant_id: form.values.plant?.id,
      client_id: currentUser.client_id,
      created_at: dateService.getDateInSqlFormat(new Date()),
    };

    machineService.createMachine(payload)
      .then((res) => {
        spindlePayload(machine_id)
        closeForm(true);
        updateAlert({
          open: true,
          type: "success",
          message: "Machine created successfully.",
        });
      })
      .catch((err) => {
        //  console.log(err)
        updateAlert({
          open: true,
          type: "error",
          message: "Failed to create Machine",
        });
      });


  }

  const columns = [
    {
      cellRenderer: DeleteRenderer,
      maxWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
      pinned: "left",
      resizable: true,
    },
    {
      headerName: "Spindle",
      field: "spindle",
      resizable: true,
      minWidth: 200,
      pinned: "left",

    },



  ]


  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12} lg={12}>

          {form.values.plant_flag > 0 && (
            <DGSelect
              id="plant"
              label="Plant"
              options={form.values.plantOptions}
              getOptionLabel={(option) => option.name}
              value={form.values.plant}
              onChange={(val) => {
                form.setFieldValue("plant", val)
              }}
              required
              disabled={form.values.plant_flag === 0}
            />
          )}
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            id={"number"}
            label="Machine Number"
            value={form.values.number}
            onChange={form.handleChange}
            error={form.touched.number && Boolean(form.errors.number)}
            isFormik
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            id={"name"}
            value={form.values.name}
            label="Machine Name"
            onChange={form.handleChange}
            error={form.touched.name && Boolean(form.errors.name)}
            required
            isFormik
          />
        </Grid>

        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            id={"spindle"}
            label={"Spindle Name"}
            value={form.values.spindle}
            onChange={form.handleChange}
            isFormik
            required

          />
        </Grid>

        <Grid item md={12} sm={12} lg={12}>
          <Button
            style={{ paddingLeft: 40, paddingRight: 40 }}
            color="primary"
            variant="outlined"
            onClick={onAdd}
            className="m-2"

          >
            Add
          </Button>
        </Grid>

        <Grid md={12} sm={12} lg={12}>
          <CommonGrid
            columns={columns}
            rows={form.values.gridData?.length ? form.values.gridData : []}
            needExport={false}
            onGridReady={onGridReady}

          />
        </Grid>

      </Grid>
      <Footer
        onSave={form.handleSubmit}
      />
    </div>
  );
}
