import React, { useState, createContext } from "react";

const PlantContext = createContext({});

export const PlantContextProvider = ({ children }) => {
  const [selectedPlant, setSelectedplant] = useState();
  const updatePlant = (plant) => {
    setSelectedplant({ globalPlant: plant })
  }

  //console.log(selectedPlant)

  return (
    <PlantContext.Provider value={{ updatePlant, globalPlant: selectedPlant }}>
      {children}
    </PlantContext.Provider>
  );
};

export default PlantContextProvider;
export { PlantContext };
