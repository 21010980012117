import React, { createContext, useState } from "react";

const PageTitleContext = createContext({});

export const PageTitleContextProvider = ({ children }) => {
    const [pageTitle, setPageTitle] = useState("");

    const setHeaderPageTitle = (title) => {
        setPageTitle(title);
    };

    return (
        <PageTitleContext.Provider
            value={{ setHeaderPageTitle, pageTitle: pageTitle }}
        >
            <div style={{ color: "blue", fontSize: "20px" }}>{children}</div>
        </PageTitleContext.Provider>
    );
};

export default PageTitleContextProvider
export { PageTitleContext };
