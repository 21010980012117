import { KeyboardDateTimePicker } from "@material-ui/pickers";
import React from "react";
import { ACTION_TYPE } from "./commonenum";

export function DGDateTimePicker({
  label = "",
  onChange = () => {},
  value = new Date(),
  format = "dd/MM/yyyy",
  required = false,
  error = false,
  variant = "filled",
  disabled = false,
  showvariant = "inline",
  id = "",
}) {
  return (
    <KeyboardDateTimePicker
      id={id}
      autoOk
      ampm={false}
      variant={showvariant}
      inputVariant={variant}
      label={label}
      format={format}
      value={value}
      fullWidth
      disabled={disabled || ACTION_TYPE.view}
      error={error}
      required={required}
      onChange={(date) => onChange(date)}
    />
  );
}
