import { makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { rgba } from "polished";
import React from "react";

export function Spec({ value }) {
  const classes = useStyles();
  if (value) {
    const specs = value.split(",");
    return (
      <>
        <b style={{ margin: 12 }}>{specs[0]}</b>
        <span className={classes.spec}> {specs[1]}</span>
        <span className={classes.spec}> {specs[2]}</span>
      </>
    );
  } else {
    return <></>;
  }
}
const useStyles = makeStyles({
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});
