import React, { useState, useEffect } from "react";
import { Card, Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Chart } from "react-google-charts";
import scatterData from "../dataSet/googleChartScatter.json";

const CardsForScatter = ({ title, studyType, studyData, gaugeCharacter }) => {
  const [graphData, setGraphData] = useState([]);
  const [chartType, setChartType] = useState("ScatterChart");
  const [chartOptions, setChartOptions] = useState();

  useEffect(() => {
    if (studyData?.study_input?.input_values) {
      setDataForGraph(JSON.parse(studyData.study_input.input_values));
    }
  }, [studyData, studyType, gaugeCharacter, title]);

  const setDataForGraph = (data) => {
    if (data?.length) {
      if (studyType === "GRR Study (Without operator influence)") {
        let intData = [],
          count = 0,
          min = data[0][0],
          max = data[0][0];
        intData.push(["", "", ""]);
        for (let i = 0; i < data.length - 1; i += i + 2) {
          for (let j = 0; j < data[i].length; j++) {
            // console.log("yData ", data[i][j]);
            intData.push([`${++count}`, data[i][j], data[i + 1][j]]);
            if (data[i][j] < min) {
              min = data[i][j];
            }
            if (data[i][j] > max) {
              max = data[i][j];
            }
          }
          // console.log("INT Data", intData);
        }
        // console.log("Chart Data", intData);
        setGraphData(intData);
        //setChartType("LineChart");
        const options = {
          chartArea: { width: "85%", height: "70%" },
          pointSize: 5,
          vAxis: {
            title: `${
              gaugeCharacter && gaugeCharacter.character
                ? gaugeCharacter.character.name
                : ""
            }`,
            minValue: parseFloat(min - min * 0.0003).toFixed(2),
            maxValue: parseFloat(max + max * 0.0003).toFixed(2),
            baselineColor: "white",
            viewWindowMode: "maximized",
          },
          hAxis: { title: "Sample Number" },
          legend: "none",
        };
        setChartOptions(options);
      } else if (studyType === "Gauge Capability") {
        let intData = [],
          count = 0,
          min = data[0],
          max = data[0];
        intData.push(["", "", ""]);
        for (let i = 0; i < data.length; i++) {
          //console.log("CGK", data[i])
          intData.push([`${++count}`, data[i], data[i]]);
          if (data[i] < min) {
            min = data[i];
          }
          if (data[i] > max) {
            max = data[i];
          }
        }
        //console.log("Chart Data ", intData);
        setGraphData(intData);
        setChartType("LineChart");
        const options = {
          chartArea: { width: "85%", height: "70%" },
          pointSize: 5,
          vAxis: {
            title: `${
              gaugeCharacter && gaugeCharacter.character
                ? gaugeCharacter.character.name
                : ""
            }`,
            minValue: parseFloat(min - min * 0.0003).toFixed(2),
            maxValue: parseFloat(max + max * 0.0003).toFixed(2),
            baselineColor: "white",
            viewWindowMode: "maximized",
          },
          hAxis: { title: "Sample Number" },
          legend: "none",
        };
        setChartOptions(options);
      } else if (studyType === "Gauge BIAS Analysis") {
        let intData = [],
          count = 0,
          min = data[0],
          max = data[0];
        intData.push(["", "", ""]);
        for (let i = 0; i < data.length; i++) {
          //console.log("Bias", data[i])
          intData.push([`${++count}`, data[i], data[i]]);
          if (data[i] < min) {
            min = data[i];
          }
          if (data[i] > max) {
            max = data[i];
          }
        }
        //console.log("Chart Data ", intData);
        setGraphData(intData);
        setChartType("LineChart");
        const options = {
          chartArea: { width: "85%", height: "70%" },
          pointSize: 5,
          vAxis: {
            title: `${
              gaugeCharacter && gaugeCharacter.character
                ? gaugeCharacter.character.name
                : ""
            }`,
            minValue: parseFloat(min - min * 0.0003).toFixed(2),
            maxValue: parseFloat(max + max * 0.0003).toFixed(2),
            baselineColor: "white",
            viewWindowMode: "maximized",
          },
          hAxis: { title: "Sample Number" },
          legend: "none",
        };
        setChartOptions(options);
      } else if (studyType === "GRR Study (With operator influence)") {
        let intData = [];
        let count = 0,
          min = data[0]?.trials[0][0],
          max = data[0]?.trials[0][0];
        intData.push(["Sample Number", "", "", "", ""]);
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].trials.length - 1; j += 2) {
            for (let k = 0; k < data[i].trials[j].length; k++) {
              // console.log("yData ", ++count, data[i].trials[j][k], data[i].trials[j+1][k]);
              intData.push([
                `${++count}`,
                data[i].trials[j][k] * 0.9995,
                data[i].trials[j][k],
                data[i].trials[j + 1][k] * 0.9995,
                data[i].trials[j + 1][k],
              ]);
              if (data[i].trials[j][k] < min) {
                min = data[i].trials[j][k];
              }
              if (data[i].trials[j][k] > max) {
                max = data[i].trials[j][k];
              }
            }
          }
          // console.log("INT Data", intData);
        }
        setGraphData(intData);
        setChartType("CandlestickChart");
        const options = {
          chartArea: { width: "85%", height: "70%" },
          pointSize: 5,
          vAxis: {
            title: `${
              gaugeCharacter?.character?.name
                ? gaugeCharacter.character.name
                : ""
            }`,
            minValue: parseFloat(min - min * 0.0003).toFixed(3),
            maxValue: parseFloat(max + max * 0.0003).toFixed(3),
            baselineColor: "white",
            viewWindowMode: "minimized",
          },
          hAxis: {
            title: "Sample Number",
          },
          legend: "none",
        };
        setChartOptions(options);
      }
    }
  };

  return (
    <React.Fragment>
      {graphData?.length && chartOptions ? (
        <Paper
          variant="outlined"
          square
          elevation={2}
          style={{ width: "100%" }}
        >
          <Typography variant="h6" style={{ padding: 8 }} className="bg-light">
            {title}
          </Typography>
          <React.Fragment>
            <Chart
              width={"100%"}
              height={275}
              chartType={chartType}
              data={graphData}
              options={chartOptions}
            />
          </React.Fragment>
        </Paper>
      ) : null}
    </React.Fragment>
  );
};

export default CardsForScatter;
