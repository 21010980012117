import React, { useContext } from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import TelegramIcon from "@material-ui/icons/Telegram";
import DashboardIcon from "@material-ui/icons/Dashboard";
import digitalGembaLogo from "../../../assets/images/digitalGembaLogo.png";
import { AppBar, Button, Container, Grid, Toolbar } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContextProvider";

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const AppBarComponent = () => {
  let { currentUser } = useContext(AuthContext);
  let history = useHistory();
  // console.log("Current User In App Bar", currentUser);

  return (
    <React.Fragment>
      <AppBar position="relative" color="black" elevation={0}>
        <Toolbar>
          <Container>
            <Grid
              container
              alignItems="center"
              style={{ position: "relative" }}
            >
              <Grid item>
                <Brand>
                  <div>
                    <img
                      src={digitalGembaLogo}
                      style={{ width: "7rem" }}
                      alt="logo"
                    />
                  </div>
                </Brand>
              </Grid>
              <Grid item xs />
              <Grid
                style={{
                  color: "#6b4619",
                  fontFamily: "'Coda', cursive",
                  display: "inline-block",
                  position: "absolute",
                  top: "25%",
                  left: "44%",
                  transform: "scale(3.5,2.5)",
                  // transformOrigin: "0px 0px",
                }}
              >
                <span>DIGITAL</span>
                <span>GEMBA</span>
              </Grid>
              <Grid item>
                {!currentUser ? (
                  <Button
                    mr={2}
                    color="primary"
                    startIcon={<TelegramIcon />}
                    variant="contained"
                    onClick={() => history.push("/auth/sign-in")}
                  >
                    LOGIN
                  </Button>
                ) : (
                  <>
                    <Button
                      style={{ marginRight: "10px" }}
                      color="primary"
                      startIcon={<DashboardIcon />}
                      variant="contained"
                      onClick={() => {
                        history.push("/gemba-dashboard");
                      }}
                    >
                      Gemba
                    </Button>
                    {currentUser?.user_role !== 5 && (
                      <Button
                        mr={2}
                        color="primary"
                        startIcon={<DashboardIcon />}
                        variant="contained"
                        onClick={() => {
                          if (
                            currentUser.client_id ||
                            currentUser?.screen_flag === "flag_4"
                          ) {
                            history.push("/overview");
                          } else {
                            history.push("/vendor-dashboard");
                          }
                        }}
                      >
                        DASHBOARD
                      </Button>
                    )}
                  </>
                )}
                {/* <Button
                  ml={2}
                  color="inherit"
                  component={Link}
                  to="/documentation/welcome"
                  target="_blank"
                >
                  Documentation
                </Button>
                <Button ml={2} color="inherit" href="/">
                  Support
                </Button> */}
                {/* <Button
                  ml={2}
                  color="inherit"
                  component={Link}
                  to="/auth/sign-up"
                >
                  Sign up
                </Button> */}
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default AppBarComponent;
