import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { GaugeMsa } from "./gaugeMsa";
import { GaugeProduct } from "./gaugeProduct";

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: 'none',
  },
  root:{
      textTransform: 'none',
  }
}))((props) => <Tab disableRipple {...props} />);

export function GaugeUtilization() {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Paper square>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setTabIndex(val)}
        aria-label="disabled tabs example"
      >
        <StyledTab label="Gauge Product" value={0} />
        <StyledTab label="Gauge - MSA" value={1} />
      </Tabs>
      <Box m={2}>
        {tabIndex === 0 ? <GaugeProduct /> : null}
        {tabIndex === 1 ? <GaugeMsa /> : null}
      </Box>
    </Paper>
  );
}

