import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import LinkIcon from "@material-ui/icons/Link";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import cuttingToolService from "../../../services/cuttingToolService";
import { CuttingToolProductLink } from "./CuttingToolPartLink";

import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";



export function CuttingToolPartDetail({
    Footer = () => null,
    closeForm = () => { },
    componentProps = {},

}) {

    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);


    function reloadGridData() {
        cuttingToolService.getCToolProduct
            ({
                plant_id: componentProps.plant_id,
                client_id: componentProps.client_id,
            })
            .then((res) => {
                const data = res.data;
                //  console.log(data, "data")
                const filteredData = data.filter(item => item.ctool_id === componentProps.ctool_id);
                setRows(filteredData);

                //setRows(res.data);

            });

    }
    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },
        {
            headerName: "Part",
            field: "part_number",
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 250,

            valueGetter: (p) => {
                return p?.data?.part_number + " - " + p?.data?.part_name;
            },
        },


        {
            headerName: "Tool Life",
            field: "ctool_life",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.tool_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entity: params?.data?.part_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];


    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return cuttingToolService.updateCuttingToolProduct(rowId, { enabled: newStatus });
    }


    return (

        <Grid item md={12} sm={12} lg={12} xs={12} justifyContent="flex-end">
            <CommonGrid
                coldefs={{
                    filter: true,
                    filterParams: {
                        apply: true,
                        newRowsAction: "keep",
                        enabled: { filter: 1 },
                    },
                }}
                columns={columns}
                rows={rows}
                gridheight={115}


            />

        </Grid>

    )
}