import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import { Button, IconButton } from "@material-ui/core";
import { DGFullScreenDrawer } from "../../../components/shared/DGFullscreenDrawer";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { DispatchPlanView } from "../Plan/DispatchPlanView";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import reportService from "../../../services/reportService";
import { PDIResultView } from "./PDIResultView";
import { DGDrawer } from "../../../components/DGDrawer";

export function ResultListing() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});


  function loadForm(data) {
    setSelectedRow(data);
    setFormOpen(true);
  }

  const handlePrint = function (data) {
    //debugger;
    reportService.generateDispatchReport(data).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(res.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "DispatchReport.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  function DownloadReport(data) {
    disptachInspection.getPDIResultReport(data?.pdi_result_id).then((res) => {
      console.log(res?.data);
      handlePrint(res?.data);
    });
  }
  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 105,
      pinned: "left",
    },
    {
      headerName: "Report Number",
      field: "result_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 300,
      cellRenderer: (params) => {
        return (
          <>

            {" "}
            <IconButton
              aria-label="delete"
              color="primary"
              onClick={() => (
                DownloadReport(params?.data)

              )}
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
            <Button
              size="small"
              color="primary"
              onClick={() => loadForm(params?.data)}
            >
              {`${params?.data?.result_number}`}
            </Button>
          </>
        );
      },
    },
    {
      headerName: "Part Number",
      field: "part_detail",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Invoice Details",
      field: "dispatch_invoice_details",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Quantity",
      field: "dispatch_quantity",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Raw Material",
      field: "raw_material",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Heat-Treatment",
      field: "heat_treatment_batch",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Surface Treatment",
      field: "surface_treatment_batch",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Tool Number",
      field: "tool_details",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
  ];

  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData() {
    disptachInspection.getPDIReportTable(currentUser?.client_id).then((res) => {
      setRows(res?.data ?? []);
    });
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={115}
      />

      <DGDrawer
        Component={PDIResultView}
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        needSave
        drawerWidth={1200}
        title={selectedRow?.part_detail}
        subtitle={"PDI/" + selectedRow?.result_number}
        componentProps={selectedRow?.pdi_result_id}
      />
    </>
  );
}
