import { Divider, Grid, Paper, Checkbox, TextareaAutosize } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/AuthContextProvider";

import { blue, brown } from "@material-ui/core/colors";
import { useFormik } from "formik";
import { DGInput } from "../../../components/shared/DGInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { object, array, ref } from "yup";
import uploadService from "../../../services/uploadService";
import { DropzoneArea } from "material-ui-dropzone";
import cuttingToolService from "../../../services/cuttingToolService";
import errorLog from "../../../services/errorLog";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import { format } from "date-fns";


export function CuttingToolRefurbishInitiate({
    componentProps = null,
    closeForm = () => { },
    Footer = () => null

}) {

    const { currentUser } = useContext(AuthContext);
    const [uploadedCertificate, setUploadedCertificate] = useState();

    let form = useFormik({
        initialValues: {
            refurbishedDate: new Date(),
            reportNumber: "",
            ctool_life: "",
            comments: "",
            uploadedLink: ""
        },
        validationSchema: object().shape({
            gridData: array().min(1)
        }),
        onSubmit: saveForm
    });

    async function uploadFileUntilSuccess(uploadedCertificate) {
        try {
            const uploadres = await uploadService.uploadFile({
                files: uploadedCertificate,
                client_id: currentUser?.client_id
            })
            const location = uploadres?.data?.files[0]?.location ?? "";
            //console.log("uploadres", location);
            return location;
        } catch (error) {
            // console.error("Upload failed, retrying...", error);
            // Wait for a short period before retrying
            await new Promise((resolve) => setTimeout(resolve, 1000));
            return await uploadFileUntilSuccess(uploadedCertificate);
        }
    }

    async function saveForm() {
        let payload;
        let temp;

        if (uploadedCertificate && uploadedCertificate?.length) {
            temp = await uploadFileUntilSuccess(uploadedCertificate);
            form.setFieldValue("uploadedLink", temp);

            payload = {
                refurbish_initiated_by: currentUser?.id,
                refurbish_initiated: format(form.values.refurbishedDate, "yyyy-MM-dd"),
                report_id: form.values.reportNumber,
                refurbish_remarks: form.values.comments ?? "",
                report_link: temp,
                ctool_status_id: 110,
                updated_at: format(new Date(), "yyyy-MM-dd"),
            };
        } else {
            payload = {
                refurbish_initiated_by: currentUser?.id,
                refurbish_initiated: format(form.values.refurbishedDate, "yyyy-MM-dd"),
                report_id: form.values.reportNumber,
                refurbish_remarks: form.values.comments ?? "",
                report_link: "",
                ctool_status_id: 110,
                updated_at: format(new Date(), "yyyy-MM-dd"),
            };
        }

        cuttingToolService.updateCtoolStatusChange(componentProps?.id, payload)

        closeForm()

    }



    return (

        <Grid container spacing={4}>


            <Grid item md={12} sm={12} lg={12}
                style={{
                    paddingBottom: '20px',
                }}>
                <DGInput
                    label="Vendor Name"
                    value={componentProps?.vendor_name}
                />
            </Grid>


            <Grid item md={6} sm={6} lg={6}
                style={{ paddingBottom: "10px" }}>
                <DGInput

                    label="Intiated By"
                    value={componentProps?.intiater_name}
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}
                style={{ paddingBottom: "10px" }}>
                <DGDateTimePicker
                    label="Initiated Date"
                    value={componentProps?.created_date}
                    format="dd/MM/yyyy"
                />
            </Grid>

            <Divider
                variant="middle"
                style={{
                    backgroundColor: blue[900],
                    width: '100%',
                    height: '1px',
                    margin: '3px',

                }}
            />

            <Grid item md={12} sm={12} lg={12}>
                <DropzoneArea
                    showFileNamesInPreview={true}
                    showFileNames={true}
                    showAlerts={true}
                    onChange={(file) => setUploadedCertificate(file)}
                    acceptedFiles={["image/*", "application/*"]}
                    filesLimit={1}
                    maxSize={10971520}
                />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <DGDateInput
                    required
                    variant="outlined"
                    label="Refurbished Date"
                    //   value={form.values.calibratedDate}
                    //  onChange={(date) => form.setFieldValue("calibratedDate", date)}
                    error={form.touched.calibratedDate && form.errors.calibratedDate}
                />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <DGInput
                    id="reportNumber"
                    label="Report Number"
                    variant="outlined"
                    value={form.values.reportNumber}
                    onChange={form.handleChange}
                    isFormik
                />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <TextareaAutosize
                    aria-label="comments"
                    placeholder="Add your comments here"
                    minRows={2}
                    className="border border-2"
                    style={{
                        width: "100%",
                        borderColor: "#bbb",
                        borderRadius: "5px",
                        maxHeight: '100px',
                    }}
                    onChange={(e) => {
                        form.setFieldValue("comments", e.target.value);
                    }}
                />
            </Grid>


            <Footer onSave={form.handleSubmit} />
        </Grid>
    )



}