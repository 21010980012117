import { Grid as MuiGrid, Typography as MuiTypography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    marginTop: '1%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));


const Help = () => {
  const Grid = styled(MuiGrid)(spacing);
  const Typography = styled(MuiTypography)(spacing);
  const classes = useStyles();
  const [expandedContentId, setExpandedContentId] = useState(0);
  const contentsList = [
    {
      id: 1,
      title: 'How to raise a concern',
      link: 'https://docs.google.com/forms/d/1njEB_lOrPDBHq5Jf5ILvH4BdhjJOf4h7WdmiB8kzSvg/edit',
      message: ' to raise a concern'
    },
    {
      id: 2,
      title: 'How to initiate inhouse calibration',
      link: 'https://test-react.nyc3.digitaloceanspaces.com/InhouseCalibration.pdf',
      message: ' to know more about inhouse calibration'
    },
    {
      id: 3,
      title: 'How to conduct vendor management',
      link: 'https://test-react.nyc3.digitaloceanspaces.com/VendorManagement.pdf',
      message: ' to know more about vendor management'
    },
    {
      id: 4,
      title: 'How to add new customer',
      link: 'https://test-react.nyc3.digitaloceanspaces.com/AddNewCustomer.pdf',
      message: ' to know how to add a new customer'
    },
    {
      id: 5,
      title: 'How to add new user',
      link: 'https://test-react.nyc3.digitaloceanspaces.com/AddnewUser.pdf',
      message: ' to know how to add a new user'
    },
    {
      id: 6,
      title: 'How to add organization details',
      link: 'https://test-react.nyc3.digitaloceanspaces.com/OrganisationSettings.pdf',
      message: ' to know how to add organization details'
    }
  ];

  return (
    <React.Fragment>
      <Helmet title="Help" />
      <Grid>
        <Typography variant="h3" color="error" gutterBottom style={{ display: 'flex', justifyContent: 'flex-start', textDecoration: 'underline' }}>
          FAQs
        </Typography>
        {contentsList && contentsList.length ? (
          contentsList.map((content) => (
            <Card className={classes.root}>
              <CardHeader
                onClick={() => expandedContentId === content.id ? setExpandedContentId(0) : setExpandedContentId(content.id)}
                action={
                  <IconButton
                    className={
                      clsx(classes.expand, {
                        [classes.expandOpen]: expandedContentId === content.id,
                      })
                    }
                    onClick={() => expandedContentId === content.id ? setExpandedContentId(0) : setExpandedContentId(content.id)}
                    aria-expanded={expandedContentId === content.id}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon color="primary" />
                  </IconButton>
                }
                title={`${content.title}`}
              />
              <Collapse in={expandedContentId === content.id} timeout="auto" unmountOnExit>
                <CardContent style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', fontSize: '16px' }}>
                  <a href={`${content.link}`} target="_blank" rel="noreferrer">
                    Click here{' '}
                  </a>
                  <span style={{ paddingLeft: '5px' }}>
                    {`${content.message}`}
                  </span>
                </CardContent>
              </Collapse>
            </Card>
          ))
        ) : null}
      </Grid>
    </React.Fragment>
  );
};

export default Help;
