
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";

import React, { useContext, useEffect, useState } from "react";
import disptachInspection from "../../../services/disptachInspection";
import { AuthContext } from "../../../context/AuthContextProvider";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { DGInput } from "../../../components/shared/DGInput";
import { useParams } from "react-router-dom";
import CommonGrid from "../../../components/CommonGrid";


export function DispatchPlanView({
  Footer = () => null,
  closeForm = () => { },
  componentProps = null,
}) {

  const [rows, setRows] = useState([]);
  const [stdPlan, setStdPlan] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [partDetails, setPartDetails] = useState([]);


  let form = useFormik({
    initialValues: {
      itemGroup1: [],
      itemGroup2: [],
      itemGroup3: [],
      itemGroup4: [],

    },

  });


  useEffect(() => {
    disptachInspection.getPDIPlanDetails(componentProps?.id)
      .then((res) => {
        const data1 = res?.data?.pdi_dispatch_char ?? [];
        setRows(data1);
        setStdPlan(res?.data?.pdi_standard);
        setPlanDetails(res?.data?.plan_details);
        setPartDetails((res?.data?.part_details)[0]);
      })

  }, []);


  useEffect(() => {
    const temp = stdPlan
    const temp1 = temp.filter((item) => item?.item_group_id === 1 && item?.item_mandatory !== 3);
    form.setFieldValue("itemGroup1", temp1);
    const temp2 = temp.filter((item) =>
      item?.item_group_id === 2 &&
      item?.item_mandatory !== 3 &&
      item?.item_mandatory !== null
    );
    form.setFieldValue("itemGroup2", temp2);

    const temp3 = temp.filter((item) =>
      item?.item_group_id === 3 &&
      item?.item_mandatory !== 3 &&
      item?.item_mandatory !== null
    );
    form.setFieldValue("itemGroup3", temp3);

    const temp4 = temp.filter((item) =>
      item?.item_group_id === 4 &&
      item?.item_mandatory !== 3 &&
      item?.item_mandatory !== null
    );
    form.setFieldValue("itemGroup4", temp4);;


  }, [stdPlan]);



  let columns = [

    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      maxWidth: 90,
    },
    {
      headerName: "Characterstics",
      field: "character_name",
      resizable: true,
      minWidth: 200,
    },
    {
      headerName: "Specification",
      field: "specification",
      resizable: true,
      minWidth: 200,
      valueGetter: (params) =>
        `  ${params?.data?.specification ? params.data.specification : ""}
                        ${params?.data?.upper_specification ? params.data.upper_specification : ""}
                        ${params?.data?.lower_specification ? params.data.lower_specification : ""}`,

    },

    {
      headerName: "Measurement Type",
      field: "measurement_type",
      resizable: true,
      minWidth: 150,
      cellEditor: "agSelectCellEditor",
      valueGetter: (params) =>
        `${params?.data?.measurement_name}`,
    },
    {
      headerName: "Gauge",
      field: "gauge_name",
      resizable: true,
      minWidth: 250,
      valueGetter: (params) =>
        `${params?.data?.gauge_number ? params.data.gauge_number : ""}
          ${params?.data?.gauge_name ? params.data.gauge_name : ""}`
      ,
    },

    {
      headerName: "Sample Size",
      field: "sample_size",
      resizable: true,
      minWidth: 50,

    }

  ];


  return (

    <>
      <Grid container spacing={2}>


        <>
          <Grid container spacing={2}>

            <Grid item xs={4}>
              <DGInput
                id="partName"
                label="Part Details"
                value={partDetails.part_number + " " + partDetails.part_name}
              />
            </Grid>
            <Grid item xs={2}>
              <DGInput
                label="Plan Number"
                value={planDetails.ref_id}
              />
            </Grid>
            <Grid item xs={4}>
              <DGInput
                id="planSelected"
                label="Standard Plan"
                value={stdPlan[0]?.std_plan_name}
              />
            </Grid>

          </Grid>

        </>

        <Grid container spacing={2} style={{ "margin-top": "5px" }}>
          {form.values.itemGroup1.map((item, index) => (
            <Grid item xs={true} key={index}>
              <DGInput
                label={item.item_name}
                value={item.default_value}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2} style={{ "margin-top": "10px" }}>
          {form.values.itemGroup2.map((item, index) => (
            <Grid item xs={true} key={index}>
              <DGInput
                label={item.item_name}
                value={item.default_value}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2} style={{ "margin-top": "10px" }}>
          {form.values.itemGroup4.map((item, index) => (
            <Grid item xs={true} key={index}>
              <DGInput
                label={item.item_name}
                value={item.default_value}
              />
            </Grid>
          ))}
        </Grid>


        <Grid item xs={12} style={{ "margin-top": "10px" }}>

          <CommonGrid
            rows={rows}
            columns={columns}
            gridheight={150}
            needExport={false}

          />


        </Grid>


        <Grid container spacing={2} style={{ "margin-top": "10px" }}>
          {form.values.itemGroup3.map((item, index) => (
            <Grid item xs={true} key={index}>
              <DGInput
                label={item.item_name}
              />
            </Grid>
          ))}
        </Grid>



      </Grid>
    </>

  );
}