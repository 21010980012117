import React, { useEffect, useState } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import {
  authLayoutRoutes,
  GembaAccessRouts,
  GembaBoardAccessRouts,
  presentationLayoutRoutes,
  protectedRoutes,
  RegisterRoutes,
  vendorAccessRouts,
} from "./index";
import VendorDashboardLayout from "../layouts/VendorDashboardLayout";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import Page404 from "../pages/auth/Page404";
import { createBrowserHistory } from "history";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import store from "../redux/store";
import { AuthContext } from "../context/AuthContextProvider";
import { useContext } from "react";
import plantService from "../services/plantService";
import { PlantContext } from "../context/PlantContextProvider";
import GembaDashboardLayout from "../layouts/GembaDasboardLayout";
import Register from "../layouts/RegisterLayout";
import { GlobalSpinner } from "../components/shared/GlobalSpinner";
import { GetRequiredProtectedRoutes } from "../services/commonService";
import userService from "../services/userService";
export const history = createBrowserHistory();
const useStyles = makeStyles((theme) => ({
  backdrop: {
    // zIndex: theme.zIndex.drawer+1,
    color: "#fff",
    zIndex: 12000,
  },
}));

const childRoutes = (
  Layout,
  routes,
  selectedPlant = {},
  handlePlantChange = () => { },
  previousplant = {},
  plants = [],
  openRoutes = {},
  setOpenRoutes = () => { },
  expandedIndex = -1,
  setExpandedIndex = () => { },
  selectedRoutes = [],
  setSelecteedRoutes = () => { }
) =>
  routes.map(({ component: Component, guard, children, path }, index) => {
    const Guard = guard || React.Fragment;

    return children ? (
      children.map((element, index) => {
        // const Guard = element.guard || React.Fragment;
        const ElementComponent = element.component || React.Fragment;

        return (
          <Route
            key={index}
            path={element.path}
            exact
            render={(props) => (
              <Layout
                routes={routes}
                selectedPlant={selectedPlant}
                handlePlantChange={handlePlantChange}
                previousplant={previousplant}
                plants={plants}
                openRoutes={openRoutes}
                setOpenRoutes={setOpenRoutes}
                expandedIndex={expandedIndex}
                setExpandedIndex={setExpandedIndex}
                selectedRoutes={selectedRoutes}
                setSelecteedRoutes={setSelecteedRoutes}
              >
                <Guard>
                  <ElementComponent {...props} />
                </Guard>
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout routes={routes}>
            <Guard>
              <Component {...props} />
            </Guard>
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => {
  const classes = useStyles();

  const [plants, setPlants] = useState([]);
  const [selectedlocalPlant, setSelectedPlant] = useState(null);
  const [previousplant, setPreviousPlant] = useState({});
  const [localUser, setLocalUser] = useState();
  const { currentUser } = useContext(AuthContext);
  const { updatePlant, globalPlant } = useContext(PlantContext);

  function handlePlantChange(val) {
    updatePlant(val);
    setPreviousPlant(selectedlocalPlant);
    setSelectedPlant(val);
    localStorage.setItem("plantId", val?.id);
    localStorage.setItem("plantName", val?.name);
  }

  useEffect(() => {
    if (currentUser && !globalPlant?.globalPlant) {
      setLocalUser(currentUser);
      if (currentUser?.client_id) {
        plantService.getDefaultPlant(currentUser?.id)
          .then((res) => {
            setPlants(res?.data ?? []);
            if (res?.data.length) {

              if (
                localStorage.getItem("plantId") &&
                localStorage.getItem("plantName")
              ) {
                let plant = {
                  id: localStorage.getItem("plantId"),
                  name: localStorage.getItem("plantName"),
                };
                setSelectedPlant(plant);
                updatePlant(plant);


              }
              else {
                // console.log("Print current storage null")
                if (plants.length > 2 && currentUser.user_role < 4) {

                  if (currentUser.default_plant) {
                    //  console.log("If 1")
                    const filteredPlant = plants.filter(item => item.id === currentUser.default_plant);
                    updatePlant(filteredPlant[0]);
                    setSelectedPlant(filteredPlant[0]);
                    localStorage.setItem("plantId", filteredPlant[0].id);
                    localStorage.setItem("plantName", filteredPlant[0].name);
                  }
                  else {
                    //  console.log("If 2")
                    updatePlant(plants[0]);
                    setSelectedPlant(plants[0]);
                    localStorage.setItem("plantId", plants[0].id);
                    localStorage.setItem("plantName", plants[0].name);
                  }
                }
                else if (currentUser.default_plant) {
                  // console.log("If 3")
                  const filteredPlant = plants.filter(item => item.id === currentUser.default_plant);
                  updatePlant(filteredPlant[0]);
                  setSelectedPlant(filteredPlant[0]);
                  localStorage.setItem("plantId", filteredPlant[0].id);
                  localStorage.setItem("plantName", filteredPlant[0].name);

                }
                else {
                  //   console.log("If 4")
                  //  console.log(plants[1])
                  updatePlant(plants[1]);
                  setSelectedPlant(plants[1]);
                  localStorage.setItem("plantId", plants[1].id);
                  localStorage.setItem("plantName", plants[1].name);
                }

                /*               console.log("IF ELSE")
                              let index = res?.data.find(
                                (exp) => exp?.id === currentUser?.client_id
                              );
                
                              if (index > -1) {
                                updatePlant(res?.data[index]);
                                setSelectedPlant(res?.data[index]);
                                localStorage.setItem("plantId", res?.data[index]?.id);
                                localStorage.setItem("plantName", res?.data[index]?.name);
                              } else {
                                updatePlant(res?.data[0]);
                                setSelectedPlant(res?.data[0]);
                                localStorage.setItem("plantId", res?.data[0]?.id);
                                localStorage.setItem("plantName", res?.data[0]?.name);
                              } */
              }
            }
            //  }, 1000)
          });
      }
    }
  }, [currentUser, selectedlocalPlant]);

  //console.log("routes", selectedlocalPlant)

  const [openRoutes, setOpenRoutes] = useState({});
  const [expandedIndex, setExpandedIndex] = useState(-1);

  useEffect(() => {
    initOpenRoutes();
  }, []);

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = history?.location?.pathname;
    let _routes = {};
    GetRequiredProtectedRoutes(currentUser?.screen_flag).forEach(
      (route, index) => {
        const isActive = pathName.indexOf(route.path) === 0;
        const isOpen = route.open;
        const isHome = route.containsHome && pathName === "/";

        _routes = Object.assign({}, _routes, {
          [index]: isActive || isOpen || isHome,
        });
      }
    );

    return _routes;
  };
  return (
    <>
      <GlobalSpinner />
      <Router history={history}>
        <Switch>
          {childRoutes(
            DashboardLayout,
            GetRequiredProtectedRoutes(currentUser?.screen_flag),
            selectedlocalPlant,
            handlePlantChange,
            previousplant,
            plants,
            openRoutes,
            setOpenRoutes,
            expandedIndex,
            setExpandedIndex
          )}
          {childRoutes(AuthLayout, authLayoutRoutes)}
          {childRoutes(PresentationLayout, presentationLayoutRoutes)}
          {childRoutes(VendorDashboardLayout, vendorAccessRouts)}
          {childRoutes(GembaDashboardLayout, GembaAccessRouts)}
          {childRoutes(GembaDashboardLayout, GembaBoardAccessRouts)}
          {childRoutes(Register, RegisterRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <Page404 />
              </AuthLayout>
            )}
          />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
