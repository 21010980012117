import React from "react";
import {
  Card,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  Paper,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import CharData from "../dataSet/charData.json";

const CardForChar = ({ title, character, studyData }) => {
  const getDateString = (inDate) => {
    const date = new Date(inDate);
    return (
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "/" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };

  return (
    <React.Fragment>
      <Paper variant="outlined" square elevation={0} style={{ width: "100%" }}>
        <Typography
          variant="h6"
          style={{ padding: 8 }}
          className="
          bg-light"
        >
          {title}
        </Typography>
        {character ? (
          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Characteristic Name</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>
                      {character.name.charAt(0).toUpperCase() +
                        character.name.slice(1)}
                    </b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Specification</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{character.specification}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Lower Tolerance</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{character.lower_specification}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Upper Tolerance</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{character.upper_specification}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Date of Evaluation</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{getDateString(studyData.created_date)}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Comments</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{studyData.comments}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
      </Paper>
    </React.Fragment>
  );
};

export default CardForChar;
