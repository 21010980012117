import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export function DrawingInfo({
  drawing_info = { within: [], with_reference: [] },
}) {
  const[rows,setRows]=useState([]);
  const[cols,setCols]=useState([{name:"Operator"},{name:"With Appraiser"},{name:"With Reference"}]);
  useEffect(()=>{
    if(drawing_info?.within?.length)
    {
      let rowData=[]
      for(let i=0;i<drawing_info?.within?.length;i++){
        rowData.push({operator:`${'operator '+(i+1)}`,wa:parseFloat(drawing_info?.within[i]??0).toFixed(2),wr:parseFloat(drawing_info?.with_reference[i]??0).toFixed(2)})
      }
      setRows(rowData);
    }
  },[drawing_info])
  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ backgroundColor: indigo[100] }}
      >
        DRAWING REQUIREMENTS
      </Typography>
      <table class="table">
        <thead>
          <tr>
            {cols?.map(col=>( <th scope="col">{col?.name}</th>))}
          </tr>
        </thead>
        <tbody>
          {rows?.map(r=>(  <tr>
            <td>{r?.operator}</td>
            <td>{r?.wa}</td>
            <td>{r?.wr}</td>
          </tr>))}
        </tbody>
      </table>
    </div>
  );
}
 