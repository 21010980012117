import { Step, StepLabel, Stepper } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { StudySelection } from "./StudySelection";
import { StudySelectionPlan } from "./StudySelectionPlan";
import { Grr1Studies } from "./studies/Grr1Studies";
import { useEffect } from "react";
import userService from "../../../../../services/userService";
import { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContextProvider";
import initiateStudyService from "../../../../../services/initiateStudyService";
import { MSAResult } from "./MsaResult";
import { Grr2Studies } from "./studies/Grr2Studies";
import { KappaStudies } from "./studies/KappaStudies";
import { SignalStudies } from "./studies/SignalStudies";
import { AlertContext } from "../../../../../context/AlertContextProvider";
import { PlantContext } from "../../../../../context/PlantContextProvider";

export function InitiateCharacterMSA({
  isOpen = false,
  setOpen = () => { },
  onClose = () => { },
  gaugeCharacter = {},
  msaPlanData = {},
  fromMSAPlan = false,
  // handleNext = () => {},
  closeForm = () => { },
}) {
  const [selectedStudy, setSelectedStudy] = useState({
    studies_name: "",
    studies_id: "",
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ["Study Selection", "Initiate", "Record"];
  const [errorStep, setErrorStep] = useState(-1);
  const [usersList, setUsersList] = useState();
  const { currentUser } = useContext(AuthContext);
  const [appraiser, setAppraiser] = useState();
  const [trails, setTrails] = useState();
  const [samples, setSamples] = useState();
  const [appraiserNames, setAppraiserNames] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ id: -1, name: "" });
  const [msaCsr, setMsaCsr] = useState();
  const [selectedgauge, setSelectedGauge] = useState({
    gauge: {
      number: "",
      name: "",
      id: "",
    },
  });
  const [gridApi, setGridApi] = useState(null);
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isGridGenerated, setGridGenerated] = useState(false);
  const [grrStudyId, setGrrStudyId] = useState();
  const [selectedMsaGauge, setSelectedMsaGauge] = useState({
    id: -1,
    name: "",
  });
  const [mastervalue, setMasterValue] = useState();
  const [characterId, setcharacterId] = useState("");
  const [partId, setPartId] = useState("");
  const [selectedGaugeItem, setSelectedGaugeItem] = useState("")
  const [evaluationDate, setEvaluationDate] = useState("")
  const { updateAlert } = useContext(AlertContext);
  const { globalPlant } = useContext(PlantContext)

  function onGridReady(params) { setGridApi(params?.api); }


  useEffect(() => {
    if (currentUser) {
      getAllUsers();
    }
  }, []);

  useEffect(() => {
    if (isGridGenerated) {
      setGridGenerated(false);
    }
  }, [appraiser, trails, samples]);

  useEffect(() => {
    if (appraiser && appraiser > -1) {
      setAppraiserNames(new Array(Number(appraiser)).fill(""));
    }
  }, [appraiser]);

  useEffect(() => {
    if (!isOpen) {
      resetStepper();
    }
  }, [isOpen]);

  function resetStepper() {
    setSelectedStudy(-1);
    setActiveStep(0);
    setAppraiser();
    setTrails();
    setSamples();
    setAppraiserNames([]);
    setSelectedUser({ id: -1, name: "" });
    setMsaCsr();
  }

  const getAllUsers = async () => {
    userService
      .getUserForApproval({
        client_id: currentUser?.client_id,
        current_user: currentUser?.id,
        plant_id: globalPlant?.globalPlant?.id
      })
      .then((res) => {
        setUsersList(res?.data ?? []);
      });
  };

  function handleNext() {
    setErrorStep(-1);
    setErrorStep(0);
    if (fromMSAPlan) {
      setSelectedStudy({
        studies_name: "",
        studies_id: msaPlanData.studies_id,
      });
      setSelectedGauge({
        gauge_id: msaPlanData.gauge_id,
      });
    }



    if (activeStep === 0 && selectedStudy?.studies_id) {
      if (selectedStudy?.studies_id) {
        setActiveStep((prev) => prev + 1);
      } else {
        setErrorStep(0);
      }
    } else if (activeStep === 1) {
      if (selectedStudy?.studies_id === 3) {
        InitateGrr1MSA();
      } else if (selectedStudy?.studies_id === 2) {
        InitateGrr2MSA();
      } else if (selectedStudy?.studies_id === 12) {
        InitiateKappMSA();
      } else if (selectedStudy?.studies_id === 5) {
        InitiateSignalMSA();
      }
    } else if (activeStep === 2) {
      RecordMsa();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  }


  async function InitateGrr1MSA() {
    if (
      Number(appraiser) >= Number(msaCsr?.grr1_min_appraiser) &&
      Number(trails) >= Number(msaCsr?.grr1_min_trials) &&
      Number(samples) >= Number(msaCsr?.grr1_min_sample)
    ) {
      let input_values = await getGrr1TableValues(trails, samples);
      setErrorStep(-1);
      //debugger;
      let payload = {
        part_id: partId,
        character_id: characterId,
        study_id: selectedStudy?.studies_id,
        created_by: currentUser?.id,
        guage_id: selectedgauge?.gauge_id,
        gauge_item_id: "",
        comments: "",
        with_operator: true,
        enabled: true,
        evaluation_date: evaluationDate,
        gauge_item_id: selectedGaugeItem?.id,
        k: Number(appraiser) ?? 0,
        r: Number(trails) ?? 0,
        msa_plan_id: "",
        input_values: input_values,
        activity: "initiate",
      };


      initiateStudyService
        .InitiateMSA(payload)
        .then((res) => {
          let data = res?.data;
          setGrrStudyId(data[0]?.grr_study_id ?? "");
          let result = data;
          result.pop();
          result.shift();
          setResult(result);
          setActiveStep(2);
        })
        .catch(() => {
          setErrorStep(1);
        });
    } else {
      setErrorStep(1);
    }
  }

  async function InitateGrr2MSA() {
    if (Number(samples) >= Number(msaCsr?.cgk_min_sample)) {
      let input_values = await getGrr2TableValues(trails, samples);
      setErrorStep(-1);
      let payload = {
        part_id: partId,
        character_id: characterId,
        study_id: selectedStudy?.studies_id,
        created_by: currentUser?.id,
        guage_id: selectedgauge?.gauge_id,
        input_values: input_values,
        activity: "initiate",
        master_value: Number(mastervalue),
        evaluation_date: evaluationDate,
        gauge_item_id: selectedGaugeItem?.id,
      };
      if (fromMSAPlan) {
        payload.part_id = msaPlanData.part_id;
        payload.character_id = msaPlanData.character_id;
        payload.guage_id = msaPlanData.gauge_id;
        payload["msa_plan_id"] = msaPlanData.msa_plan_id;
      }

      initiateStudyService
        .InitiateMSA(payload)
        .then((res) => {
          let data = res?.data;
          setGrrStudyId(data[0]?.cgk_study_id ?? "");
          let result = data;
          result.pop();
          result.shift();
          setResult(result);
          setActiveStep(2);
        })
        .catch(() => {
          setErrorStep(1);
        });
    } else {
      setErrorStep(1);
    }
  }

  async function InitiateKappMSA() {
    if (
      Number(appraiser) >= Number(msaCsr?.kappa_min_appraiser) &&
      Number(trails) >= Number(msaCsr?.kappa_min_trials) &&
      Number(samples) >= Number(msaCsr?.kappa_min_sample)
    ) {
      let input_values = await getKappaTableValues(trails);
      let payload = {
        activity: "initiate",
        study_id: 12,
        character_id: characterId,
        master_value: Number(mastervalue),
        created_by: currentUser?.id,
        part_id: partId,
        guage_id: selectedgauge?.gauge_id,
        k: Number(appraiser) ?? 0,
        r: Number(trails) ?? 0,
        input_values: input_values,
        evaluation_date: evaluationDate,
        gauge_item_id: selectedGaugeItem?.id,
      };
      if (fromMSAPlan) {
        payload.part_id = msaPlanData.part_id;
        payload.character_id = msaPlanData.character_id;
        payload.guage_id = msaPlanData.gauge_id;
        payload["msa_plan_id"] = msaPlanData.msa_plan_id;
      }
      initiateStudyService
        .InitiateMSA(payload)
        .then((res) => {
          let data = res?.data;
          setGrrStudyId(data[0]?.kappa_study_id ?? "");
          let result = data;
          result.pop();
          result.shift();
          setResult(result);
          setActiveStep(2);
        })
        .catch(() => {
          setErrorStep(1);
        });
    } else {
      setErrorStep(1);
    }
  }

  async function InitiateSignalMSA() {
    if (
      Number(appraiser) >= Number(msaCsr?.signal_min_appraiser) &&
      Number(trails) >= Number(msaCsr?.signal_min_trials) &&
      Number(samples) >= Number(msaCsr?.signal_min_sample)
    ) {
      let input_values = await getSignalTableValues(trails, samples);
      let payload = {
        activity: "initiate",
        study_id: 5,
        character_id: characterId,
        created_by: currentUser?.id,
        part_id: partId,
        guage_id: selectedgauge?.guage_id,
        k: Number(appraiser) ?? 0,
        r: Number(trails) ?? 0,
        input_values: input_values,
      };
      if (fromMSAPlan) {
        payload.character_id = msaPlanData.character_id;
        payload.part_id = msaPlanData.part_id;
        payload.guage_id = msaPlanData.gauge_id;
        payload["msa_plan_id"] = msaPlanData.msa_plan_id;
      }
      initiateStudyService
        .InitiateMSA(payload)
        .then((res) => {
          let data = res?.data;
          setGrrStudyId(data[0]?.signal_study_id ?? "");
          let result = data;
          result.pop();
          result.shift();
          setResult(result);
          setActiveStep(2);
        })
        .catch(() => {
          setErrorStep(1);
        });
    } else {
      setErrorStep(1);
    }
  }

  async function getGrr2TableValues(trails = 1, samples) {
    const gridArr = [];
    let rows = [];
    await gridApi.forEachNode((node) => gridArr.push(node.data));
    for (let i = 0; i < Number(1); i++) {
      gridArr?.map((exp, j) => {
        rows.push(exp["trial" + (i + 1)]);
      });
    }
    return rows;
  }

  function RecordMsa() {
    const payload = {
      activity: "record",
      approved_by: selectedUser?.id,
      study_id: selectedStudy?.studies_id,
      msa_plan_id: "",
      msa_result: 2,
    };
    if (fromMSAPlan) {
      payload.msa_plan_id = msaPlanData.msa_plan_id;
    }
    if (selectedStudy?.studies_id === 3) {
      payload["grr_study_id"] = grrStudyId;
    } else if (selectedStudy?.studies_id === 2) {
      payload["cgk_study_id"] = grrStudyId;
    } else if (selectedStudy?.studies_id === 12) {
      payload["kappa_study_id"] = grrStudyId;
    } else if (selectedStudy?.studies_id === 5) {
      payload["signal_study_id"] = grrStudyId;
    }
    initiateStudyService
      .InitiateMSA(payload)
      .then((res) => {
        onClose();
        closeForm();
        updateAlert({
          message: "MSA Record Created Successfully.",
          open: true,
          type: "success",
        });
      })
      .catch(() => {
        setErrorStep(2);
        updateAlert({
          message: "Failed to Create MSA Record.",
          open: true,
          type: "error",
        });
      });
  }

  const getGrr1TableValues = async (trialsCount, samples) => {
    let rowCount1 = Number(trialsCount);
    const gridArr = [];
    await gridApi.forEachNode((node) => gridArr.push(node.data));
    setRows(gridArr); //gridAPi to get the data entered in ag-grid rows
    const resultt = gridArr.map(Object.values);
    let arrTranspose = resultt[0]?.map((x, i) => resultt.map((x) => x[i])); //To transpose the array
    let arrNum = [];
    for (let i = 0; i < arrTranspose?.length; i++) {
      let list = [];
      arrTranspose[i].map((data) => {
        if (data && !isNaN(Number(data))) {
          list.push(Number(data));
        }
      });
      if (list?.length) {
        arrNum.push(list);
      }
    }
    if (arrNum?.length) {
      let bfa;
      for (let j = 0; j < arrNum?.length; j++) {
        if (arrNum[j].length != samples) {
          bfa = true;
          break;
        }
      }
      if (bfa) {
        return;
      }
      var res = arrNum.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / rowCount1);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
      let objectArray = [];
      for (let i = 0; i < appraiserNames.length; i++) {
        const obj = { "Operator name": `${appraiserNames[i]}`, trials: res[i] };
        objectArray.push(obj);
      }
      return objectArray ?? [];
    }
  };

  async function getKappaTableValues(trialsCount) {
    let rowCount1 = Number(trialsCount);
    const gridArr = [];
    gridApi.forEachNode((node) => gridArr.push(node.data));
    const resultt = gridArr.map(Object.values);
    let arrTranspose = resultt[0].map((x, i) => resultt.map((x) => x[i]));
    let arrNum = [];
    let refv;
    for (let i = 0; i < arrTranspose.length; i++) {
      let list = [];
      arrTranspose[i].map((data) => {
        if (!isNaN(Number(data))) {
          list.push(Number(data));
        }
      });
      if (list?.length) {
        arrNum.push(list);
      } else {
      }
    }
    refv = arrNum[arrNum.length - 1];
    arrNum.pop();
    var res = arrNum.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / rowCount1);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    let objectArray = [];
    for (let i = 0; i < appraiserNames.length; i++) {
      const obj = { "Operator name": `${appraiserNames[i]}`, trials: res[i] };
      objectArray.push(obj);
    }
    return [[{ ref_values: refv }], objectArray];
  }

  function getSignalTableValues(trialsCount, samples) {
    let rowCount1 = Number(trialsCount);
    const gridArr = [];
    gridApi.forEachNode((node) => gridArr.push(node.data));
    const resultt = gridArr.map(Object.values);
    let arrTranspose = resultt[0].map((x, i) => resultt.map((x) => x[i]));
    let arrNum = [];
    let refv;
    for (let i = 0; i < arrTranspose.length; i++) {
      let list = [];
      arrTranspose[i].map((data) => {
        if (!isNaN(Number(data))) {
          list.push(Number(data));
        }
      });
      if (list?.length) {
        arrNum.push(list);
      } else {
      }
    }
    refv = arrNum[arrNum.length - 1];
    arrNum.pop();
    var res = arrNum.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / rowCount1);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    let objectArray = [];
    for (let i = 0; i < appraiserNames.length; i++) {
      const obj = { Operatorname: `${appraiserNames[i]}`, trials: res[i] };
      objectArray.push(obj);
    }
    return [[{ ref_values: refv }], objectArray];
  }

  return (
    <>
      <Stepper activeStep={activeStep} style={{ width: "100%" }}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel error={index === errorStep}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 0 && !fromMSAPlan ? (
        <StudySelection
          selectedStudy={selectedStudy}
          setSelectedStudy={setSelectedStudy}
          selectedgauge={selectedgauge}
          setSelectedGauge={setSelectedGauge}
          selectedMsaGauge={selectedMsaGauge}
          setSelectedMsaGauge={setSelectedMsaGauge}
          handleNext={handleNext}
          setcharacterId={setcharacterId}
          setPartId={setPartId}
          closeForm={closeForm}
          evaluationDate={evaluationDate}
          setEvaluationDate={setEvaluationDate}
          selectedGaugeItem={selectedGaugeItem}
          setSelectedGaugeItem={setSelectedGaugeItem}
        />
      ) : null}

      {activeStep === 0 && fromMSAPlan ? (
        <StudySelectionPlan
          fromMSAPlan={fromMSAPlan}
          msaPlanData={msaPlanData}
          handleNext={handleNext}
          setSelectedStudy={setSelectedStudy}
          setSelectedGauge={setSelectedGauge}
          setPartId={setPartId}
          closeForm={closeForm}
          evaluationDate={evaluationDate}
          setEvaluationDate={setEvaluationDate}
          selectedGaugeItem={selectedGaugeItem}
          setSelectedGaugeItem={setSelectedGaugeItem}
          setcharacterId={setcharacterId}

        />
      ) : null}





      {activeStep === 1 && selectedStudy?.studies_id === 3 ? (
        <Grr1Studies
          appraiser={appraiser}
          trails={trails}
          samples={samples}
          usersList={usersList}
          studyId={selectedStudy?.studies_id}
          gaugeCharacter={
            fromMSAPlan
              ? { character_id: msaPlanData.character_id }
              : gaugeCharacter
          }
          setAppraiser={setAppraiser}
          setTrails={setTrails}
          setSamples={setSamples}
          appraiserNames={appraiserNames}
          setAppraiserNames={setAppraiserNames}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          msaCsr={msaCsr}
          setMsaCsr={setMsaCsr}
          onGridReady={onGridReady}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setColumns={setColumns}
          isGridGenerated={isGridGenerated}
          setGridGenerated={setGridGenerated}
          characterId={characterId}
          handleNext={handleNext}
          evaluationDate={evaluationDate}
          setEvaluationDate={setEvaluationDate}
          selectedGaugeItem={selectedGaugeItem}
          setSelectedGaugeItem={setSelectedGaugeItem}
          closeForm={closeForm}
        />
      ) : null}

      {activeStep === 1 && selectedStudy?.studies_id === 2 ? (
        <Grr2Studies
          trails={trails}
          samples={samples}
          usersList={usersList}
          studyId={selectedStudy?.studies_id}
          gaugeCharacter={
            fromMSAPlan
              ? { character_id: msaPlanData.character_id }
              : gaugeCharacter
          }
          setTrails={setTrails}
          setSamples={setSamples}
          mastervalue={mastervalue}
          setMasterValue={setMasterValue}
          appraiserNames={appraiserNames}
          setAppraiserNames={setAppraiserNames}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          evaluationDate={evaluationDate}
          setEvaluationDate={setEvaluationDate}
          selectedGaugeItem={selectedGaugeItem}
          setSelectedGaugeItem={setSelectedGaugeItem}
          msaCsr={msaCsr}
          setMsaCsr={setMsaCsr}
          onGridReady={onGridReady}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setColumns={setColumns}
          isGridGenerated={isGridGenerated}
          setGridGenerated={setGridGenerated}
          characterId={characterId}
          handleNext={handleNext}
          closeForm={closeForm}
        />
      ) : null}

      {activeStep === 1 && selectedStudy?.studies_id === 12 ? (
        <KappaStudies
          appraiser={appraiser}
          trails={trails}
          samples={samples}
          usersList={usersList}
          studyId={selectedStudy?.studies_id}
          gaugeCharacter={
            fromMSAPlan
              ? { character_id: msaPlanData.character_id }
              : gaugeCharacter
          }
          setAppraiser={setAppraiser}
          setTrails={setTrails}
          setSamples={setSamples}
          appraiserNames={appraiserNames}
          setAppraiserNames={setAppraiserNames}
          selectedUser={selectedUser}
          mastervalue={mastervalue}
          setMasterValue={setMasterValue}
          setSelectedUser={setSelectedUser}
          msaCsr={msaCsr}
          setMsaCsr={setMsaCsr}
          onGridReady={onGridReady}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setColumns={setColumns}
          isGridGenerated={isGridGenerated}
          setGridGenerated={setGridGenerated}
          characterId={characterId}
          handleNext={handleNext}
          closeForm={closeForm}
          evaluationDate={evaluationDate}
          setEvaluationDate={setEvaluationDate}
          selectedGaugeItem={selectedGaugeItem}
          setSelectedGaugeItem={setSelectedGaugeItem}
        />
      ) : null}

      {activeStep === 1 && selectedStudy?.studies_id === 5 ? (
        <SignalStudies
          appraiser={appraiser}
          trails={trails}
          samples={samples}
          usersList={usersList}
          studyId={selectedStudy?.studies_id}
          gaugeCharacter={
            fromMSAPlan
              ? { character_id: msaPlanData.character_id }
              : gaugeCharacter
          }
          setAppraiser={setAppraiser}
          setTrails={setTrails}
          setSamples={setSamples}
          appraiserNames={appraiserNames}
          setAppraiserNames={setAppraiserNames}
          selectedUser={selectedUser}
          mastervalue={mastervalue}
          setMasterValue={setMasterValue}
          setSelectedUser={setSelectedUser}
          msaCsr={msaCsr}
          setMsaCsr={setMsaCsr}
          onGridReady={onGridReady}
          rows={rows}
          columns={columns}
          setRows={setRows}
          setColumns={setColumns}
          isGridGenerated={isGridGenerated}
          setGridGenerated={setGridGenerated}
          characterId={characterId}
          handleNext={handleNext}
          closeForm={closeForm}
        />
      ) : null}

      {activeStep === 2 ? (
        <MSAResult
          gridApi={gridApi}
          rows={result}
          closeForm={closeForm}
          handleNext={handleNext}
        />
      ) : null}
    </>
  );
}
