import React, { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { ACTION_TYPE } from "./shared/commonenum";

export function DGDrawer({
  isOpen = false,
  setOpen = () => { },
  onClose = () => { },
  Component = () => null,
  drawerWidth = 400,
  title = "",
  subtitle = "",
  componentProps = {},
  rightFooterButtons = [],
  needNext = false,
  needSave = false,
  onCancel = () => {
    setOpen(false);
  },
}) {
  const [HeaderButtons, setHeaderButtons] = useState([() => <></>]);
  useEffect(() => {
    return () => {
      ACTION_TYPE.add = false;
      ACTION_TYPE.edit = false;
      ACTION_TYPE.view = false;
    };
  }, []);
  return (
    <Drawer anchor={"right"} open={isOpen} onClose={onClose}>
      <div style={{ width: drawerWidth }}>
        <AppBar position="fixed" style={{ width: drawerWidth, padding: 0 }}>
          <Toolbar style={{ padding: 4 }}>
            <IconButton
              aria-label="delete"
              size="small"
              onClick={() => setOpen(false)}
            >
              <ArrowBackRoundedIcon fontSize="large" />
            </IconButton>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignContent="flex-start"
            >
              {title ? (
                <Grid item>
                  <Typography variant="h6" align="left">
                    {title}
                  </Typography>
                </Grid>
              ) : null}
              {subtitle ? (
                <Grid item>
                  <Typography align="left" variant="subtitle2">
                    {subtitle}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <div style={{ flexGrow: 1 }}></div>
            {HeaderButtons?.map((Exp, i) => (
              <Exp key={i} />
            ))}
          </Toolbar>
          <Divider />
        </AppBar>
        <Grid
          container
          style={{ padding: 10, marginTop: 70, marginBottom: 60 }}
        >
          {isOpen && (
            <Component
              componentProps={componentProps}
              setHeaderButtons={setHeaderButtons}
              closeForm={(stat, data) => setOpen(false, data)}
              Footer={({ onSave }) => (
                <AppBar
                  position="fixed"
                  style={{ width: drawerWidth, top: "auto", bottom: 0 }}
                >
                  <Divider />
                  <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                      style={{ margin: 4 }}
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => onCancel()}
                    >
                      CANCEL
                    </Button>
                    {needSave ? (
                      <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onSave}
                      >
                        SAVE
                      </Button>
                    ) : null}


                    {needNext ? (
                      <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onSave}
                      >
                        NEXT
                      </Button>
                    ) : null}


                  </Toolbar>
                </AppBar>
              )}
            />
          )}
        </Grid>

      </div>
    </Drawer>
  );
}
