import { Box, Button, Grid, makeStyles, Divider } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
//import { array, number, object, string } from "yup";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import { AlertContext } from "../../../context/AlertContextProvider";
import { blueGrey } from "@material-ui/core/colors";
import { rgba } from "polished";

import approvalService from "../../../services/approvalService";
import characterService from "../../../services/characterService";
import productService from "../../../services/productService";
import userService from "../../../services/userService";
import MaskedInput from "react-text-mask";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import errorLog from "../../../services/errorLog";

const useStyle = makeStyles({
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});

export function ProductCharacteristicsApprovalForm({
  Footer = () => { },
  closeForm = () => { },
  componentProps = ""
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const classes = useStyle();
  const [approveFlag, setApproveFlag] = useState(0)
  const [rows, setRows] = useState([]);
  const { v4: uuidv4 } = require("uuid");





  useEffect(() => {
    characterService.getCharacterForApproval(componentProps.approval_id)
      .then((res) => {
        setRows(res?.data)
        determineApproveFlag(res?.data)

      });
  }, [])



  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }


  function ApproveORReject(statusId) {
    let payload = {
      approval_id: componentProps.approval_id,
      user_id: currentUser?.id,
      status_id: statusId,
    };
    approvalService.createApprovalDecision(payload)
      .then((res) => {
        let message;
        if (statusId === 2) {
          message = "Request Approved";
        } else { message = "Request Rejected" }
        updateAlert({
          open: true,
          type: "success",
          message: message
        })
      })
      .catch((err) => {
        updateAlert({
          open: true,
          type: "failure",
          message: "Failed to Approve/Reject"
        })
        let payload = {
          id: uuidv4(),
          error_description: err.message,
          error_location: "createInspectionEvaluate",
          client_id: currentUser.client_id,
          user_id: currentUser.id,
          entity_id: componentProps?.approval_id,
        }
        errorLog.createErrorLog(payload)
      });

    closeForm(true);
  }





  function determineApproveFlag(rData) {

    // console.log(rData[0].approval_status_id)

    if (rData[0].approval_status_id !== 1) {
      var approve_flag = 0

    } else {

      {
        if (currentUser.id === rData[0].approver_id) {
          var approve_flag = 1;
        }
        else if (currentUser.id === rData[0].requested_by) {
          var approve_flag = 0;
        }
        else if (currentUser.user_role < 4) {
          var approve_flag = 1;
        }
      }
      setApproveFlag(approve_flag)
    }
  }


  const columns = [
    {
      field: "name",
      headerName: "Characteristics",
      resizable: true,
      minWidth: 200,
      pinned: "left",
    },
    {
      field: "specification",
      headerName: "Specification",
      resizable: true,
      editable: false,
      pinned: "left",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      cellRenderer: (params) => (
        <>
          <GetFormattedValue
            value={
              params?.data?.specification !== null &&
                params?.data?.upper_specification !== null &&
                params?.data?.lower_specification !== null
                ? `${params?.data?.specification},${params?.data?.upper_specification},${params?.data?.lower_specification}`
                : null
            }
          />
        </>
      ),
      minWidth: 220,
    },

    {
      field: "character_unit",
      headerName: "Units",
      resizable: true,
      minWidth: 100,
    },
    {
      field: `character_type`,
      headerName: "Characteristics Type",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "position_in_drawing",
      headerName: "Position in Drawing",
      resizable: true,
      minWidth: 200,
    },
    {
      field: 'character_criticality',
      headerName: "Criticality",
      resizable: true,
      minWidth: 200,
    },

  ];


  return (


    <Grid container rowSpacing={4} columnSpacing={2}>


      <Grid container md={12} sm={12} lg={12} >
        {approveFlag === 1 && (

          <Button
            //className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => {
              ApproveORReject(2);
              closeForm();
            }}
            style={{ marginRight: 9 }}
          >
            APPROVE
          </Button>
        )}

        {approveFlag === 1 && (
          <Button
            //className={classes.button}
            variant="outlined"
            color="secondary"
            startIcon={<CancelOutlinedIcon />}
            onClick={() => {
              ApproveORReject(3);
              closeForm();
            }}
          >
            REJECT
          </Button>
        )}
      </Grid>
      <Grid md={12} sm={12} lg={12}>
        <Divider style={{ margin: '10px auto', width: '50%' }} />
      </Grid>
      <Grid md={12} sm={12} lg={12}>
        <CommonGrid
          columns={columns}
          rows={rows}
          needExport={false}
        />
      </Grid>

    </Grid>
  );
}
