import http from "./httpService";

const getClientsByVendorUserId = (userId) => {
    return http.get("/vendor-access", {
        params: {
            user_id: `${userId}`,
        }
    });
}

const getVendorAccessByClientIdAndPiAgencyId = (clientId, piAgencyId) => {
    return http.get("/vendor-access", {
        params: {
            client_id: `${clientId}`,
            pi_agency_id: `${piAgencyId}`,
        }
    });
}

const createVendorAccessByClientIdAndUserId = (data) => {
    return http.post("/vendor-access", data);
}

const revokeVendorAccessByClientIdAndUserId = (id) => {
    return http.delete(`/vendor-access/${id}`);
}

const changeAccessfromDate = (id) => {
    return http.patch(`/vendor-access/${id}`);
}


const getVendorTable = (client_id) => {
    return http.get("/vendor-table", {
        params: {
            client_id: client_id
        }
    })
}

const getVendorDetails = (id) => {
    return http.get(`/pi_agency/${id}`)
}

const changeVendorStatus = (data) => {
    return http.patch("/pi_agency", {
        params: {
            enabled: data
        }
    })
}


const deleteVendorAccess = (id) => {
    return http.delete(`/vendor-access-revoke/${id}`)
}

const createVendorAccess = (data) => {
    return http.post(`/vendor-access-provide`, { params: { ...data } })
}


const getCalibVendorClientlist = (user_id) => {
    return http.get('/calibvendor-client-list', {
        params: {
            user_id: user_id
        }
    })
}

const getCalibVendorClienttable = (client_id, pi_agency_id) => {
    return http.get('/calibvendor-client-table', {
        params: {
            pi_agency_id: pi_agency_id,
            client_id: client_id
        }
    })
}

const getVendorList = (client_id) => {
    return http.get('/vendor-agency-list', {
        params: {
            client_id: client_id
        }
    })
}



const addNewVendor = (data) => {
    return http.post('/vendor-agency-create', data)
}




// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getVendorAccessByClientIdAndPiAgencyId,
    createVendorAccessByClientIdAndUserId,
    revokeVendorAccessByClientIdAndUserId,
    getClientsByVendorUserId,
    getVendorTable,
    changeAccessfromDate,
    getVendorDetails,
    changeVendorStatus,
    deleteVendorAccess,
    createVendorAccess,
    getCalibVendorClientlist,
    getCalibVendorClienttable,
    addNewVendor,
    getVendorList
};
