import {
  AppBar,
  Button,
  Divider,
  Grid,
  TextareaAutosize, Typography,
  Toolbar,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useEffect } from "react";
import { array, date, object, string } from "yup";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDateTimePicker } from "../../../../components/shared/DGDateTimePicker";
import { DGInput } from "../../../../components/shared/DGInput";
import productionInspection from "../../../../services/productionInspection";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { useState } from "react";
import { DefectCatalogue } from "../DefectCatalogue";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { Text, Tooltip } from "@chakra-ui/react";
import { NumericEditor } from "../../../../components/cellRenderers/NumericEditor";
import { AngleEditor } from "../../../../components/cellRenderers/AngleEditor";
import Attribute_button from "../../../../components/cellRenderers/RadioSelect"
import { DGDrawer } from "../../../../components/DGDrawer";
import { format } from "date-fns";
import variants from "../../../../theme/variants";
import errorLog from "../../../../services/errorLog";
import Loader from "../../../../components/Loader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";




export function UnderInspectionForm({
  closeForm = () => { },
  componentProps = null,
  setHeaderButtons = () => { },
}) {
  const [selectedRow, setSelectedRow] = useState([]);
  const [columns, setColumns] = useState([]);
  const [maxsample, setMaxSample] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const { updateAlert } = useContext(AlertContext);
  const [defectCat, setdefectCat] = useState("");
  const [defectCatOpen, setdefectCatOpen] = useState(false)
  const [partID, setPartID] = useState("")
  const [evalData, setevalData] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { v4: uuidv4 } = require("uuid");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [widthG, setWidthG] = useState("")
  const [evaluatedChecked, setEvaluatedChecked] = useState(false);

  let form = useFormik({
    initialValues: {
      plant: "",
      part: "",
      production_date: new Date(),
      gridData: [],
      gridDataInitial: [],
      result: "",
      record_flag: "",
      batch: "",
      sourceData: [],
      comments: "",
      requestID: "",
    },
    validationSchema: object({
      plant: string().required(),
      part: string().required(),
      production_date: date().required(),
      gridData: array().min(1).required(),
    }),
  });

  console.log(componentProps?.request_id)


  useEffect(() => {
    loadColumns(maxsample);
  }, [maxsample]);

  function onGridReady(params) {
    setGridApi(params?.api);
  }


  useEffect(() => {
    if (!componentProps?.request_id) {
      return
    }
    LoadInspectRequestdata();
    loadGridData()

  }, [componentProps]);


  function loadGridData() {
    productionInspection
      .getInspectionRequest(componentProps?.request_id)
      .then((res) => {
        form.setFieldValue("record_flag", res?.data?.header[0].record_flag);
        form.setFieldValue("requestID", res?.data?.header[0].request_id);
        form.setFieldValue("comments", res?.data?.header[0].comments);
        res?.data?.header[0].max_sample_size
          ? setMaxSample(res?.data?.header[0].max_sample_size)
          : setMaxSample(0);
        loadColumns(res?.data?.header[0]?.max_sample_size);
        let arrayData = res?.data?.grid?.map((exp) => ({
          ...exp,
          ...exp?.raw_data?.map((e) => e),
        }));
        form.setFieldValue("gridData", arrayData ?? []);
        form.setFieldValue("gridDataInitial", arrayData ?? []);

        if (evaluatedChecked) {
          const filteredData = arrayData.filter(item => item.character_result === null);
          form.setFieldValue("gridData", filteredData)
        }
        else {
          form.setFieldValue("gridData", arrayData ?? [])
        }

      })
  }


  function LoadInspectRequestdata() {
    setPartID(componentProps.part_id);

    productionInspection
      .getInspectionRequest(componentProps?.request_id)
      .then((res) => {

        let header = res?.data?.header[0];
        form.setFieldValue(
          "part",
          `${header?.part_number}-${header?.part_name}`
        );
        let time = header?.production_time.split(":");
        let date = new Date(header?.production_date);
        date.setHours(time[0], time[1], time[2]);
        form.setFieldValue("production_date", date);
        form.setFieldValue("plant", `${header?.plant_name}`);

        form.setFieldValue("batch", `${header?.batch_details ?? ""}`);
        form.setFieldValue("tool", {
          tool_name: header?.tool_name,
          tool_number: header?.tool_number,
          machine_name: header?.machine_name,
          machine_number: header?.machine_number
        });
        header?.toolFlag === 1
          ? setWidthG(6)
          : setWidthG(12);

        setDataLoaded(true)
      });
  }

  function RecordRequest() {
    productionInspection.updateProductionInspection(form.values.requestID, { comments: form.values.comments })
    productionInspection
      .createInspectionRecord(componentProps?.request_id)
      .then((res) => {
        closeForm();
      });
  }

  function loadColumns(samples = 0) {
    let entries = [];
    for (let i = 0; i < samples; i++) {
      entries.push({
        field: `${i}`,
        editable: (params) => {
          let columnIndex = Number(params?.colDef?.field);
          return columnIndex < params?.data?.sample_size;
        },
        minWidth: 80,
        resizable: true,
        valueGetter: (params) => {
          if (
            (params?.data[i] === 1 || params?.data[i] === "OK") &&
            params?.data?.measurement_type === 303
          ) {
            return "OK";
          } else if (
            (params?.data[i] === 0 || params?.data[i] === "NOT OK") &&
            params?.data?.measurement_type === 303
          ) {
            return "NOT OK";
          } else {
            return params?.data[i];
          }
        },


        cellEditorSelector: (params) => {
          if (params?.data?.character_type_id !== 105) {
            if (params?.data?.measurement_type === 303) {
              return {
                component: "agSelectCellEditor",
                params: {
                  values: ["OK", "NOT OK"],
                  valueListMaxHeight: "5",
                  valueListMaxWidth: 100
                },
                popup: false,

              };
            }

            else if (
              params?.data?.measurement_type === 301 ||
              params?.data?.measurement_type === 302
            ) {
              return {
                component: NumericEditor,
              };
            } else {
              return {
                component: "agTextCellEditor",
              };
            }
          } else {
            return {
              component: AngleEditor,
            };
          }
        },


        colSpan: (params) => {
          if (
            Number(params?.colDef?.field) + 1 ===
            Number(params?.data?.sample_size)
          ) {
            return samples - Number(params?.data?.sample_size) + 1;
          } else {
            return 1;
          }
        },
        minWidth: 150,
        suppressMovable: true,
        headerValueGetter: (params) => Number(params?.colDef?.field) + 1,
      });
    }


    let static_columns = [
      {
        field: "order_number",
        headerName: "SI No",
        //valueGetter: "node.rowIndex + 1",
        filter: "agTextColumnFilter",
        minWidth: 100,
        resizable: true,
      },
      {
        field: "process_number",
        headerName: "Process #",
        filter: "agTextColumnFilter",
        minWidth: 150,
        resizable: true,
      },


      {
        field: "characterstics",
        headerName: "Characterstics and Specification",
        filter: "agTextColumnFilter",
        minWidth: 200,
        resizable: true,
        autoHeight: true,
        cellStyle: {
          whiteSpace: 'pre-wrap',
          lineHeight: '1.3' // 
        },
        cellRenderer: (params) => {
          return (
            <>

              <Typography variant="body1"
                style={{ marginTop: '6px', }}>
                {params?.data?.characterstics}
              </Typography>

              {params?.data?.specification !== null &&
                params?.data?.specification !== undefined && (
                  <Typography variant="body2">
                    {`${params?.data?.specification}  ${params?.data?.upper_specification ?? ""
                      }  ${params?.data?.lower_specification ?? ""}`}
                  </Typography>
                )}

              {params?.data?.machine_spindle_name !== null &&
                params?.data?.machine_spindle_name !== undefined && (
                  <Typography variant="body2">
                    {params?.data?.machine_spindle_name}
                  </Typography >
                )
              }

              {
                params?.data?.gauge_number !== null &&
                params?.data?.gauge_number !== undefined && (
                  <Typography variant="body2">
                    {params?.data?.gauge_number} {params?.data?.gauge_name}
                  </Typography>
                )
              }

              {
                params?.data?.reduced_tolerance !== null &&
                params?.data?.reduced_tolerance !== undefined && (
                  <Typography variant="body2">
                    {`${params?.data?.reduced_tolerance}% of Tolerance`}
                  </Typography>
                )
              }

              <Typography variant="body1"
                style={{ marginBottom: '6px', }}>
                {params?.data?.measurement_type_name}
              </Typography>
            </>
          );
        },
      },

      {
        field: "sample_size",
        headerName: "Samples",
        filter: "agTextColumnFilter",
        minWidth: 100,
        resizable: true,
      },
      {
        field: "character_result",
        headerName: "Result",
        cellRenderer: (params) => (
          <ResultRenderer value={params?.data?.character_result} />
        ),
        minWidth: 150,
        resizable: true,
      },

    ];

    let endColumns = [
      {
        field: "required_evaluation",
        headerName: "Evaluation Required",
        headerCheckboxSelection: false,
        checkboxSelection: true,
        suppressRowClickSelection: true,
        minWidth: 100,
        resizable: true,
        setSelected: true

      }

    ];
    setColumns([...static_columns, ...entries, ...endColumns]);
  }

  function evaluatedCheckedhide() {
    if (!evaluatedChecked) {
      setEvaluatedChecked(true)
      const filteredData = form.values.gridData.filter(item => item.character_result === null);
      form.setFieldValue("gridData", filteredData)
    }
    else {
      setEvaluatedChecked(false)
      form.setFieldValue("gridData", form.values.gridDataInitial)
    }

  }

  function arraysEqual(arr1, arr2) {

    for (let i = 0; i < arr1.length; i++) {

      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  function onRowSelect(data) {
    setSelectedRow((data.map((row) => row.id)))
  }

  useEffect(() => {
    eval_data_preparation()
  }, [selectedRow, form.values]);

  //console.log(selectedRow)


  function eval_data_preparation() {

    var temp_data = form.values.gridData
    var temp_data = temp_data.map((item) => ({
      ...item,
      select_flag: selectedRow.includes(item.id) ? 1 : 0,
    }));
    //console.log(temp_data)

    temp_data.forEach((item) => {
      let temp_type = item.measurement_type
      let raw_temp_data = []
      for (const key in item) {
        if (key === "id") {
          break;
        }
        switch (item.measurement_type) {
          case 301:
            switch (item.character_type_id) {
              case 105:
                var item_ = item[key]
                break;
              default:
                var item_ = parseFloat(item[key].toString())
                break;
            }
            break;
          case 302:
            var item_ = parseInt(item[key].toString())
            break;
          case 303:
            var item_ = item[key] === "OK" || item[key] === 1 ? 1 : 0;
            break;
          case 304:
            var item_ = item[key]
            break;
          default:
            break;
        }
        raw_temp_data.push(item_);
      }

      raw_temp_data = raw_temp_data.filter(item => item !== null)
      item.raw_data_temp = raw_temp_data

      //console.log(raw_temp_data)
      //console.log(item.measurement_type)

      // eval_flag = 0 => No change / no data
      // eval_flag = 1 => New Evaluation
      // eval_flag = 2 => Change in evaluation
      // eval_flag = 3 => No evaluation required
      // eval_flag = 4 => String entry / no evaluation required

      let eval_flag;
      if (item.select_flag !== 1) {
        if (raw_temp_data.length === item.sample_size) {
          if (item.measurement_type !== 304) {
            if (item.basic_eval_id != null) {
              if (arraysEqual(raw_temp_data, item.raw_data, item.measurement_type)) {
                eval_flag = 0;
              } else {
                eval_flag = 2;
              }
            } else { eval_flag = 1 }
          } else {
            eval_flag = 4
          }
        } else { eval_flag = 0; }
      } else { eval_flag = 3 }

      item.eval_flag = eval_flag

      //console.log(temp_data)

    });
    setevalData(temp_data)

  };

  function LoadDefectCat() {
    setdefectCatOpen(true)
  }

  //console.log("evalData", evalData)

  function EvaluateData() {
    let payload = [];
    evalData.forEach((node) => {
      let data = node;
      payload.push({
        //changed_data: 1,
        id: data?.id,
        character_id: data?.character_id,
        measured_by: currentUser?.id,
        reduced_tolerance: data?.reduced_tolerance,
        product_char_flag: data?.product_char_flag,
        values: data?.raw_data_temp,
        select_flag: data?.select_flag,
        eval_flag: data?.eval_flag,
        //eval_code: data?.eval_code,
        basic_eval_id: data?.basic_eval_id
      });
    });


    if (!payload) {
      updateAlert({
        open: true,
        message: "No data Updated",
        type: "error",
      });
    } else {

      productionInspection
        .createInspectionEvaluate(payload)
        .then((res) => {
          loadGridData();
          setDataLoaded(true)


          updateAlert({
            open: true,
            message: "Request evaluated",
            type: "success",
          });
        })
        .catch((err) => {
          updateAlert({
            open: true,
            message: "Failed to evaluate data",
            type: "error",
          });

          let payload = {
            id: uuidv4(),
            error_description: err.message,
            error_location: "createInspectionEvaluate",
            client_id: currentUser.client_id,
            user_id: currentUser.id,
            entity_id: componentProps?.request_id,
          };
          errorLog.createErrorLog(payload)

        })


    };
  }


  const getRowStyle = (params) => {
    const result_status = params?.data?.character_result;

    switch (result_status) {
      case 0:
        return { background: 'rgba(231,76,60, 0.1)' };
      case 1:
        return { background: 'rgba(39,174,96, 0.1)' };
      case 2:
        return { background: 'rgba(64,3,3, 0.1)' };
      case -1:
        return { background: 'rgba(153,12,24, 0.05)' };
      case 3:
        return { background: 'rgba(188,188,188,0.2)' };
      default:
        return {};
    }
  };


  function ButtonComponent() {
    return (
      <>


        {componentProps.catalogue_length == null ? (
          null
        ) : (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ImageSearchRoundedIcon />}
            onClick={() => { LoadDefectCat() }}

            style={{ marginLeft: 3 }}
          >
            CATALOGUE
          </Button>
        )}

        {componentProps?.request_result == null && (
          < Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={EvaluateData}
          >
            EVALUATE
          </Button >
        )}

      </>
    );
  }

  function processCellFromClipboard(params) {
    if (!params?.value || isNaN(Number(params?.value))) {
      return "";
    } else {
      return Number(params.value);
    }
  }

  if (!dataLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            id="plant"
            label="Plant"
            value={form.values.plant}
            onChange={form.handleChange}
            isFormik
            required
            disabled
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            id="part"
            label="Part"
            value={form.values.part}
            onChange={form.handleChange}
            isFormik
            required
            disabled
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGDateTimePicker
            reguired
            value={form.values.production_date}
            onChange={(val) => form.setFieldValue("production_date", val)}
            format="yyyy/MM/dd HH:mm"
            disabled
          />
        </Grid>
        <Grid item md={widthG} sm={widthG} lg={widthG}>
          <DGInput
            id="batch"
            label="Batch Details"
            value={form.values.batch}
            onChange={form.handleChange}
            isFormik
            error={form.touched.batch && Boolean(form.errors.batch)}
          //disabled
          />
        </Grid>
        {widthG === 6 && (
          <Grid item md={widthG} sm={widthG} lg={widthG}>
            <DGInput
              label="Mould Details"
              value={
                `${form.values?.tool?.tool_name} ${form.values.tool?.tool_number}  
              ${form.values?.tool?.machine_number} ${form.values.tool?.machine_name}`
              }
              disabled
            />
          </Grid>

        )}
        <Grid item md={12} sm={12} lg={12}>
          <CommonGrid
            rows={form.values.gridData}
            columns={columns}
            needExport={false}
            rowSelection={"multiple"}
            onRowSelect={onRowSelect}
            onCellValueChanged={eval_data_preparation}
            ButtonComponent={ButtonComponent}
            suppressRowClickSelection={true}
            showDisabledCheckboxes={true}
            onGridReady={onGridReady}
            horizontalTab
            processCellFromClipboard={processCellFromClipboard}
            getRowStyle={getRowStyle}
            gridheight={250}
            HeaderComponent={() => (
              <FormControlLabel
                control={
                  <Switch
                    checked={evaluatedChecked}
                    onChange={(e) => evaluatedCheckedhide(e.target.checked)}
                    color="primary"
                    name="Hide evaluated"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Hide evaluated"
              />
            )}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <TextareaAutosize
            id={"comments"}
            maxRows={4}
            minRows={3}
            className="w-100 border h-100"
            aria-label="maximum height"
            placeholder="Comments"
            //defaultValue=""
            value={form.values.comments}
            onChange={form.handleChange}
          //  isFormik
          />
        </Grid>

        <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
          <Divider />
          <Toolbar>
            <div style={{ flexGrow: 1 }}></div>
            <Button
              style={{ margin: 4 }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => closeForm()}
            >
              CANCEL
            </Button>
            {componentProps?.request_result == null && (
              <Button
                style={{ margin: 4 }}
                variant="contained"
                color="primary"
                size="small"
                onClick={RecordRequest}
                disabled={form.values.record_flag !== 0}
              >
                RECORD
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </Grid>

      <DGDrawer
        Component={DefectCatalogue}
        title="Inspection Catalogue"
        isOpen={defectCatOpen}
        drawerWidth={1200}
        componentProps={partID}
        setOpen={(state) => {
          setdefectCatOpen(state);
          // setSubtitle("");
          // reloadgridData();
        }}

      />
    </>
  );
}

/*  function EvaluateData() {
   let payload = [];
   selectedRow.forEach((node) => {
     let data = node;
     let values = [];
     for (let i = 0; i < data?.sample_size; i++) {
       if (data?.character_type_id === 105) {
         values.push(data[i]);
       } else {
         if (data?.measurement_type === 303) {
           values.push(data[i] === "OK" || data[i] === 1 ? 1 : 0);
         } else if (
           data?.measurement_type === 301 ||
           data?.measurement_type === 302
         ) {
           values.push(Number(data[i]));
         } else {
           values.push(data[i]);
         }
       }
     }
     payload.push({
       changed_data: 1,
       id: data?.id,
       character_id: data?.character_id,
       measured_by: currentUser?.id,
       reduced_tolerance: data?.reduced_tolerance,
       product_char_flag: data?.product_char_flag,
       values: values,
       eval_code: data?.eval_code,
       basic_eval_id: data?.basic_eval_id
     });
   });
        productionInspection
         .createInspectionEvaluate(payload)
         .then((res) => {
           LoadInspectRequestdata();
           updateAlert({
             open: true,
             message: "Request Evaluated",
             type: "success",
           });
         })
         .catch((res) => {
           updateAlert({
             open: true,
             message: "Failed to Evaluate Request",
             type: "error",
           });
         });
 } */
