import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContextProvider";
import customerDashboardService from "../../../../services/customerDashboardService";
import { Rules } from "../../../../components/cellRenderers/RulesRenderer";
import CommonGrid from "../../../../components/CommonGrid";

export function CustomerPdiOverview() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    customerDashboardService.getCustomerPICCM(currentUser?.id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);

  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      width: 100,
    },

    {
      headerName: "Vendor Name",
      field: "vendor_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Customer Name",
      field: "customer_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Vendor Plant",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Part Detail",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
      cellRenderer: (params) => (
        <>
          <Link
            style={{
              cursor: "pointer",
              color: "#2196f3",
            }}
            variant="body2"
            to={`/customer/characterstics/${params.data.part_id}`}
          >
            {`${params?.data?.part_number}-${params?.data?.part_name}`}
          </Link>
        </>
      ),
    },
    {
      headerName: "Last 5 Result",
      field: "msa_result_",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 300,
      cellRenderer: (params) => <Rules values={params?.data?.result_} />,
    },
  ];
  return <CommonGrid rows={rows} columns={columns} gridheight={220} />;
}
