import React, { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../../../components/CommonGrid";

export function DataInfo({ data_info = [] }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  function onGridReady(params) {
    var eGridDiv = document.querySelector("#kappa");
    eGridDiv.style.width = "100%";
    eGridDiv.style.height = "400px";
    params?.api.setDomLayout();
  }
  useEffect(() => {
    let data = JSON?.parse(data_info[0]?.input_values ?? "[]") ?? [];
    let rowData = [];
    let columnData = [];
    let formattedData = [];
    let refData = data && data[0] && data[0][0] ? data[0][0] : [];
    data[1]?.map((appraiser, i) => {
      appraiser?.trials?.map((trl, j) => {
        trl = trl?.map((exp) => {
          let obj = {};
          obj[`${appraiser["Operator name"]}-${i + 1}-${j}`] = exp;
          return obj;
        });
        formattedData?.push(trl);
      });
    });
    for (let i = 0; i < formattedData[0]?.length ?? 0; i++) {
      formattedData.map((exp) => {
        rowData[i] = { ...rowData[i], ...exp[i] };
      });
    }
    data[1]?.map((exp, k) => {
      let cl = { headerName: exp["Operator name"], children: [] };
      exp?.trials?.map((row, i) => {
        let childrens = [];
        childrens.push({
          field: `${exp["Operator name"]}-${k + 1}-${i}`,
          headerName: `Trial ${i + 1}`,
          minWidth: 150,
        });
        cl["children"] = [...cl["children"], ...childrens];
      });
      columnData?.push(cl);
    });
    refData?.ref_values?.map((ref, i) => {
      rowData[i] = { ...rowData[i], ref_values: ref };
    });
    columnData.unshift({
      field: "ref_values",
      headerName: "Ref Value",
      minWidth: 130,
      editable: true,
    });
    setColumns(columnData);
    setRows(rowData);
  }, [data_info]);

  return (
    <div>
      <CommonGrid
        onGridReady={onGridReady}
        needExport={false}
        rows={rows}
        columns={columns}
        id="kappa"
      />
    </div>
  );
}
