import http from "./httpService";

const uploadFile = async (files) => {

    const formData = new FormData();

    const filesArray = files?.files;

    if (filesArray?.length) {
        filesArray.forEach(file => formData.append('upload', file));
    }

    formData.append(`client_id`, files?.client_id);


    try {
        const response = await http.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
                "client_id": files?.client_id
            }
        });
        return response;
    } catch (error) {
        console.error('Error during file upload:', error);
        throw error;
    }
};

export default {
    uploadFile
};

