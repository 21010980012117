import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../../components/CommonGrid';
import machineService from '../../../services/machineService';
import { AuthContext } from '../../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import StatusFilter from "../../../components/shared/StatusFilter";
import ActiveInActive from "../../../components/ActiveInActive";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { StatusContext } from "../../../context/StatusContextProvider";
import { rgba } from "polished";

export function MachineProductDetail({
    componentProps = null,
}) {

    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { setStatus } = useContext(StatusContext);

    function reloadGridData() {
        return machineService.getProductMachineDetail2(componentProps?.id)
            .then((res) => {
                //debugger
                setRows(res?.data ?? []);
            });
    }

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
        }));
        reloadGridData();
    }, []);


    const columns = [{
        field: "serial_number",
        headerName: "SI No",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 75,
        sorting: true,
    },
    {
        headerName: "Part",
        minWidth: 400,
        resizable: true,
        sorting: true,
        cellRenderer: (params) => {
            const marginLeft = (params?.data?.level + 1) > 0 ?
                (params?.data?.level + 1) * 20 : 0;
            return (
                <div style={{ marginLeft }}>
                    {params?.data?.part_number}-{params?.data?.part_name}
                </div>
            );
        },
    },

    {
        field: "enabled",
        headerName: "Status",
        minWidth: 150,
        resizable: true,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        suppressMenu: true,
        floatingFilterComponent: StatusFilter,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        cellRenderer: (params) => (
            <ActiveInActive
                disabled={params?.data?.edit_flag !== 1 || params?.data?.part_enabled === 0}
                status={params?.data?.enabled}
            />
        ),
    },
    ]



    return (
        <Grid item md={12} sm={12} lg={12}>

            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={50}
                suppressCellSelection={true}
            />

        </Grid>
    )


}
