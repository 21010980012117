import { Box, Button, Grid, TextareaAutosize } from "@material-ui/core";
import productionMgmtService from "../../../services/productionMgmtService";
import formTool from "../../../services/formTool";
import { useEffect, useState, useContext } from "react";
import { StatusContext } from "../../../context/StatusContextProvider";
import CommonGrid from "../../../components/CommonGrid";
import { format, min } from "date-fns";
import { useFormik } from "formik";
import { DGSelect } from "../../../components/shared/DGSelect"
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { AlertContext } from "../../../context/AlertContextProvider";
import { fi } from "date-fns/locale";


export default function FormToolStatus({
    componentProps = {},
    closeForm = () => { },
}) {

    const { setStatus } = useContext(StatusContext);
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const { v4: uuidv4 } = require('uuid');
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { updateAlert } = useContext(AlertContext);




    let form = useFormik({
        initialValues: {
            comments: (""),
            toolStatusSelected: "",
            toolStatusOptions: [
                { tool_status_id: 101, tool_status: "Active" },
                { tool_status_id: 109, tool_status: "Rework/Quarantine" },
                { tool_status_id: 103, tool_status: "Scrapped" },
            ],
        },

        onSubmit: onSave

    })





    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,

        }));

        reloadGridData();
        reloadGridData2();
    }, []);


    function onSave() {
        const payload = {
            id: uuidv4(),
            request_id: null,
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            tool_id: componentProps.form_tool_id,
            tool_status_id: form.values.toolStatusSelected.tool_status_id,
            comments: form.values.comments,
            user_id: currentUser.id,
            enabled: 1
        }

        const payload2 = {
            tool_status: form.values.toolStatusSelected.tool_status_id,
            updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        }


        formTool.createFormToolStatus(payload)
            .then((res) => {
                formTool.updateFormToolStatusMainTable(componentProps.form_tool_id, payload2)
                closeForm();
                updateAlert({
                    message: "Tool status changed",
                    open: true,
                    type: "success",
                });
            })
            .catch((exp) => {
                updateAlert({
                    message: "Failed to change status, Try again",
                    open: true,
                    type: "error",
                });
            });

    }


    function reloadGridData() {
        formTool.getFormToolProduction(componentProps.form_tool_id)
            .then((res) => {
                setRows(res.data);
            })
        const filteredOptions = form.values.toolStatusOptions.filter(option => option.tool_status_id !== componentProps.tool_status_id);
        form.setFieldValue("toolStatusOptions", filteredOptions);

    }

    function reloadGridData2() {
        formTool.getFormToolStatusTable(componentProps.form_tool_id)
            .then((res) => {
                setRows2(res.data);

            })
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },

        {
            field: "production_date",
            headerName: "Production Date",
            filter: "agDateColumnFilter",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) =>
                format(new Date(params?.data?.production_date), "dd/mm/yyyy")

        },
        {
            field: "shots_sum",
            headerName: "Total Shots",
            filter: "agNumberColumnFilter",
            flex: 1,
            minWidth: 200,

        },
        {
            field: "production_quantity",
            headerName: "Production Quantity",
            filter: "agNumberColumnFilter",
            flex: 1,
            minWidth: 150,

        },

        {
            field: "rejections",
            headerName: "Rejections",
            filter: "agNumberColumnFilter",
            flex: 1,
            minWidth: 150,


        },

        {
            field: "machine_name",
            headerName: "Machine Name",
            filter: "agTextColumnFilter",
            flex: 1,
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.machine_number}-{params?.data?.machine_name}
                    </>
                )
            }

        },





    ]


    const columns2 = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            maxWidth: 100,
        },
        {
            field: "created_at",
            headerName: "Changed Date",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) =>
                format(new Date(params?.data?.created_at), "dd/mm/yyyy")

        },
        {
            field: "tool_status_id",
            headerName: "New Status",
            flex: 1,
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <>
                        {params?.data?.tool_status_id === 101 ? "Active" : params?.data?.tool_status_id === 109 ? "Rework/Quarantine" : "Scrapped"}
                    </>
                )
            }


        },
        {
            field: "comments",
            headerName: "Comments",
            flex: 1,
            minWidth: 200,

        },

        {
            field: "user_name",
            headerName: "Changed By",
            flex: 1,
            minWidth: 200,


        }



    ]


    return (

        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DGSelect
                        label="Cutting tool Status"
                        id="toolStatusSelected"
                        options={form.values.toolStatusOptions}
                        value={form.values.toolStatusSelected}
                        onChange={(value) => {
                            form.setFieldValue("toolStatusSelected", value);

                        }}
                        getOptionLabel={(option) => option?.tool_status ?? ""}
                        disabled={componentProps?.tool_status_id === 103 || componentProps?.ctool_status_id === 109}
                    />

                </Grid>

                <Grid item md={12} sm={12} lg={12}>
                    <TextareaAutosize
                        aria-label="checkPointDescription"
                        placeholder="Comments"
                        minRows={1}
                        className="border border-2"
                        style={{
                            width: "100%",
                            borderColor: "#bbb",
                            borderRadius: "2px",
                            maxHeight: '100px',
                            minHeight: '50px',
                        }}
                        onChange={(e) => {
                            form.setFieldValue("comments", e.target.value);
                        }}
                    />
                </Grid>

                <Grid item md={12} sm={12} lg={12}
                    style={{ padding: '10px' }}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={form.handleSubmit}
                        disabled={form.values.toolStatusSelected === "" || form.values.toolStatusSelected === null}
                    >
                        Change
                    </Button>

                </Grid>


                <Grid item md={12} sm={12} xs={12} justifyContent="flex-end">
                    <CommonGrid
                        columns={columns2}
                        rows={rows2}
                        gridheight={500}
                        tableName="Change Details"


                    />

                </Grid>

                <Grid item md={12} sm={12} xs={12} justifyContent="flex-end">
                    <CommonGrid
                        columns={columns}
                        rows={rows}
                        gridheight={150}
                        tableName="Production Details"


                    />

                </Grid>
            </Grid>
        </>
    );
}