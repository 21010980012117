import React from "react";
import { ResultRenderer } from "../../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../../components/CommonGrid";


export function ResultInfo({ result_info = [] }) {
  result_info?.pop();
  result_info?.shift();
  const columns = [
    {
      field: "parameter",
      headerName: "Parameter",
      resizable: true,
    },
    {
      field: "requirments",
      headerName: "Requirement",
      resizable: true,
    },
    {
      field: "actual_result",
      headerName: "Actual Result",
      resizable: true,
      valueGetter: (params) =>
        parseFloat(params?.data?.actual_result).toFixed(0),
    },
    {
      field: "conclusion",
      headerName: "Conclusion",
      resizable: true,
    },
    {
      field: "result",
      headerName: "Result",
      resizable: true,
      cellRenderer: (params) => {
        return <ResultRenderer value={params?.data?.result} />;
      },
    },
  ];

  function onGridReady(params) {
    var eGridDiv = document.querySelector("#grr1result");
    eGridDiv.style.width = "100%";
    eGridDiv.style.height = "200px";
    params?.api.setDomLayout();
  }
  return (
    <CommonGrid
      onGridReady={onGridReady}
      needExport={false}
      rows={result_info}
      columns={columns}
      gridheight={180}
      id="grr1result"
      fixedHeight
    />
  );
}
