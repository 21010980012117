import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { object } from "yup";


import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";

import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer"

import plantService from "../../../services/plantService"
import productService from "../../../services/productService";
import formToolService from "../../../services/formTool"
import CommonGrid from "../../../components/CommonGrid"
import dateService from '../../../services/dateService';
import machineService from "../../../services/machineService";
const { v4: uuidv4 } = require("uuid")



export function FormToolProductLinkAdd({
    componentProps = null,
    Footer = () => null,
    closeForm = () => { }

}) {
    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const { globalPlant } = useContext(PlantContext);
    const [plantOptions] = useState([])


    let form = useFormik({
        initialValues: {
            plant: null,
            part: null,
            tool: null,
            machine: null,
            plant_flag: null,
            gridData: [],
            partOptions: [],
            toolOptions: [],
            plantOptions: [],
            machineOptions: [],
            machineOptions2: []
        },

        validationSchema: object().shape({
            plant_id: object().required(),
            part_id: object().required(),
            machine_id: object().required()
        }),

        onSubmit: saveForm

    });

    useEffect(() => {
        plantService.getDefaultPlant(currentUser?.id)
            .then((res) => {
                const plantOptions = (res?.data ?? []).slice(1);
                form.setFieldValue("plantOptions", plantOptions);
            })
    }, []);

    useEffect(() => {
        if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
            form.setFieldValue("plant", (globalPlant?.globalPlant));
            form.setFieldValue("plant_flag", 0)

        } else {
            if (currentUser.default_plant) {
                const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 1)

            } else {
                const defaultPlant = form.values.plantOptions[0]
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 2)
            }
        }
    }, [form.values.plantOptions]);

    function onGridReady(params) {
        setGridApi(params?.api);
    }

    useEffect(() => {

        productService.getAProductByPlant(form.values.plant?.id)
            .then(res => {
                var temp = res.data;
                form.setFieldValue("partOptions", temp);
            });

    }, [form.values.plant]);




    useEffect(() => {
        if (form.values.plant !== null) {
            formToolService.getFormProductList1(form.values.plant?.id,
                form.values.part?.id)
                .then(res => {
                    var temp = res.data
                    form.setFieldValue("toolOptions", temp);
                })
        }
    }, [form.values.part])


    useEffect(() => {
        if (form.values.tool !== null) {
            formToolService.getFormProductList2(
                {
                    client_id: currentUser.client_id,
                    plant_id: form.values.plant?.id,
                    part_id: form.values.part?.id,
                    tool_id: form.values.tool.form_tool_id
                })
                .then(res => {
                    var temp = res.data
                    form.setFieldValue("machineOptions", temp);
                    form.setFieldValue("machineOptions2", temp);
                })
        }
    }, [form.values.tool])

    useEffect(() => {
        var temp2 = form.values.gridData
        if (temp2 !== null) {
            var machineIdsNew = new Set(temp2.map(item => item.machine_id));
            var templist = form.values.machineOptions
            var tempOptions = templist.filter(item => !machineIdsNew.has(item.machine_id));
            form.setFieldValue("machineOptions2", tempOptions)
        }
    }, [form.values.gridData]);


    function onAdd() {
        let data = [];
        data?.push({
            id: uuidv4(),
            client_id: currentUser.client_id,
            plant_id: form.values.plant?.id,
            tool_id: form.values.tool?.form_tool_id,
            part_id: form.values.part?.id,
            part_number: form.values.part?.part_number,
            part_name: form.values.part?.part_name,
            user_id: currentUser.id,
            created_date: dateService.getDateInSqlFormat(new Date()),
            enabled: 1,
            tool_name: form.values.tool?.tool_name,
            tool_number: form.values.tool?.tool_number,
            machine_id: form.values.machine?.machine_id,
            machine_name: form.values.machine?.machine_name,
            machine_number: form.values.machine?.machine_number,
        })
        form.setFieldValue("gridData", [...form.values.gridData, ...data]);

        //const temp_1 = (form.values.toolOptions).filter(item => item.form_tool_id !== form.values.tool?.form_tool_id)
        //form.setFieldValue("toolOptions", temp_1)
        form.setFieldValue("tool", null)
        form.setFieldValue("machine", null)

    }




    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...form.values.gridData];
        updatedGridData.splice(rowIndex, 1);
        form.setFieldValue("gridData", updatedGridData);
        gridApi?.updateRowData({ remove: [data] });
    }

    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            pinned: "left",
            resizable: true,
        },
        /*         {
                    headerName: "Part",
                    resizable: true,
                    minWidth: 200,
                    pinned: "left",
                    valueGetter: (params) => {
                        return `${params.data?.part_number} ${params.data?.part_name}`
                    }
                }, */
        {
            headerName: "Tool",
            resizable: true,
            minWidth: 200,
            pinned: "left",
            valueGetter: (params) => {
                return `${params.data?.tool_name} ${params.data?.tool_number}`
            }
        },
        {
            headerName: "Machine",
            resizable: true,
            minWidth: 200,
            pinned: "left",
            valueGetter: (params) => {
                return `${params.data?.machine_number} ${params.data?.machine_name}`
            }
        },


    ]

    function saveForm() {
        let toolObj = [];
        gridApi.forEachNode((node) => {
            let data = node?.data;
            toolObj.push({
                id: uuidv4(),
                client_id: data.client_id,
                plant_id: data.plant_id,
                tool_id: data.tool_id,
                part_id: data.part_id,
                user_id: currentUser.id,
                machine_id: data.machine_id,
                enabled: 1

            })
        })

        let payload = [];
        gridApi.forEachNode((node) => {
            let data = node?.data;
            payload.push({
                id: uuidv4(),
                client_id: data.client_id,
                character_id: null,
                part_id: data.part_id,
                machine_id: data.machine_id,
                machine_spindle_id: null,
                process_id: null,
                created_at: dateService.getDateInSqlFormat(new Date()),
                updated_at: dateService.getDateInSqlFormat(new Date()),
                created_by: currentUser.id,
                plan_name: null,
                enabled: 1,
                machine_spindle_drop: null

            })
        })


        formToolService.createToolProduct(toolObj)
            .then(machineService.createCharacterMachine(payload))
            .then(res => {
                closeForm();
                updateAlert({
                    message: "Tool linked successfully",
                    type: "success",
                    open: true
                });
            }).catch(err => {
                updateAlert({
                    message: "Failed To link Tool",
                    type: "error",
                    open: true
                })
            })
    }

    return (

        <Grid container spacing={3}>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="plant"
                    label="Plant"
                    options={form.values.plantOptions}
                    getOptionLabel={(option) => option.name}
                    value={form.values.plant}
                    onChange={(val) => {
                        form.setFieldValue("plant", val)
                        form.setFieldValue("part", null)
                        form.setFieldValue("gridData", [])
                    }}
                    required
                    disabled={form.values.plant_flag === 0}
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="part"
                    label="Part"
                    options={form.values.partOptions}
                    getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
                    value={form.values.part}
                    onChange={(val) => {
                        form.setFieldValue("part", val);
                        form.setFieldValue("tool", null);
                        form.setFieldValue("gridData", [])

                    }}
                    required
                    error={form.touched.part && Boolean(form.errors.part)}
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="tool"
                    label="Tool"
                    options={form.values.toolOptions}
                    getOptionLabel={(opt) => `${opt?.tool_name}-${opt?.tool_number}`}
                    value={form.values.tool}
                    onChange={(val) => {
                        form.setFieldValue("tool", val);
                        form.setFieldValue("machine", null);

                    }}
                    required
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="machine"
                    label="Machine"
                    options={form.values.machineOptions2}
                    getOptionLabel={(opt) => `${opt?.machine_number}-${opt?.machine_name}`}
                    value={form.values.machine}
                    onChange={(val) => {
                        form.setFieldValue("machine", val);
                    }}
                    required
                />
            </Grid>


            <Grid item md={12} sm={12} lg={12}>
                <Button
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                    color="primary"
                    variant="outlined"
                    onClick={onAdd}
                    className="m-2"
                >
                    Add
                </Button>

            </Grid>

            <Grid md={12} sm={12} lg={12}>
                <CommonGrid
                    columns={columns}
                    rows={form.values.gridData?.length ? form.values.gridData : []}
                    needExport={false}
                    onGridReady={onGridReady}
                />
            </Grid>

            <Footer
                onSave={saveForm}
                isValid={form.isValid} />
        </Grid>
    );

}
export default FormToolProductLinkAdd;