import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import CommonGrid from "../../../components/CommonGrid";
import disptachInspection from "../../../services/disptachInspection";
import { AuthContext } from "../../../context/AuthContextProvider";
import { DGFullScreenDrawer } from "../../../components/shared/DGFullscreenDrawer";
import { DispatchPlanForm } from "./DispatchPlanForm";
import { DGDrawer } from "../../../components/DGDrawer";
import AddIcon from "@material-ui/icons/Add";
import { RequestForm1 } from "./RequestForm1";
import { PDIEvaluation } from "./PDIEvaluation";

export function UnderInspectionListing() {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [newRequestForm, setNewRequestForm] = useState(false);
  const [evlationForm, setEvlationForm] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState({});
  const [resultID, setResultID] = useState(null);

  function loadForm(data) {
    setSelectedRow(data);
    setEvlationForm(true);
    setResultID(data?.pdi_result_id)
  }
  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 105,
      pinned: "left",
    },
    {
      headerName: "Report Number",
      field: "result_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 300,
      cellRenderer: (params) => {
        return (
          <>
            <Button
              size="small"
              color="primary"
              onClick={() => loadForm(params?.data)}
            >
              {`${params?.data?.result_number}`}
            </Button>
          </>
        );
      },
    },
    {
      headerName: "Part Number",
      field: "part_detail",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Invoice Details",
      field: "dispatch_invoice_details",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Quantity",
      field: "dispatch_quantity",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Raw Material",
      field: "raw_material",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Heat-Treatment",
      field: "heat_treatment_batch",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Surface Treatment",
      field: "surface_treatment_batch",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Tool Number",
      field: "tool_details",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
  ];

  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData() {
    disptachInspection
      .getPDIReportTable(currentUser?.client_id, 2)
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }


  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setNewRequestForm(true);
          }}
          style={{ marginLeft: 3 }}
        >
          REQUEST
        </Button>
      </>
    );
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={150}
        ButtonComponent={ButtonComponent}

      />

      <DGFullScreenDrawer
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        Component={DispatchPlanForm}
        size="full"
        componentProps={selectedRow}
        needSave
      />

      <DGDrawer
        Component={RequestForm1}
        title="New Request"
        isOpen={newRequestForm}
        setOpen={(state, reqID) => {
          if (reqID) {
            setNewRequestForm(state);
            setResultID(reqID);
            setEvlationForm(true);
          } else {
            setNewRequestForm(state);
          }
          reloadGridData();
        }}
        needSave
        drawerWidth={900}
      />

      <DGDrawer
        Component={PDIEvaluation}
        isOpen={evlationForm}
        setOpen={() => {
          setEvlationForm(false);
          reloadGridData();
        }}
        needSave
        drawerWidth={1200}
        title={selectedRow?.part_detail ?? ""}
        subtitle={"PDI/" + selectedRow?.result_number}
        componentProps={resultID}
      />



    </>
  );
}
