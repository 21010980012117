import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import ImageIcon from "@material-ui/icons/Image";
import React from "react";

export function GaugeInfo({
  gauge_info = [{ "Gauge Family": "", "Gauge Number": "", "Least Count": "" }],
}) {
  return (
    <Paper elevation={5}>
     <Typography variant="h5" gutterBottom align="center" style={{backgroundColor:indigo[100]}} >
        GAUGE INFORMATION
      </Typography>
      <List>
        <Divider />
        <ListItem>
          <ListItemText primary={"Gauge Family"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={
                gauge_info[0] && gauge_info[0]["Gauge Family"]
                  ? gauge_info[0]["Gauge Family"]
                  : ""
              }
              primaryTypographyProps={{ style:{fontWeight:"bolder"}}} 
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Gauge Number"} />
          <ListItemSecondaryAction>
            <ListItemText primary={gauge_info[0]["Gauge Number"]} primaryTypographyProps={{ style:{fontWeight:"bolder"}}}  />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Least Count"} />
          <ListItemSecondaryAction>
            <ListItemText primary={gauge_info[0]["Least Count"]} primaryTypographyProps={{ style:{fontWeight:"bolder"}}}  />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
    </Paper>
  );
}
