/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import machineService from "../../../../services/machineService";
import CommonGrid from "../../../../components/CommonGrid";
import { Link } from "react-router-dom";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../../components/ActiveInActive";
import { AuthContext } from "../../../../context/AuthContextProvider"
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { ProcessParameterNewForm } from "./ProcessParameterNewForm"
import { useParams } from "react-router-dom";



const ProcessList = () => {
    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    let id = useParams();


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: loadList,
            callback: updateStatus
        }));
        loadList()
    }, []);

    function updateStatus(comments, newId, newStatus, rowId) {
        return machineService.updateMachineParameter(rowId, { enabled: newStatus });
    }

    function LoadAddForm(data) {
        //setSubtitle(data?.request_name ?? "");
        setFormOpen(true);
    }

    function loadList() {
        return machineService.getMachineProcess(id?.id)
            .then((res) => {
                // console.log("machine_list", res.data)
                setRows(res?.data ?? []);
            });
    }

    function ButtonComponent() {
        return (

            <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                    LoadAddForm();
                }}
                style={{ marginLeft: 3 }}
            >
                ADD Parameter
            </Button>

        );
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 150
        },
        {
            field: "parameter_name",
            headerName: "Parameter",
            minWidth: 150

        },
        {
            field: "units",
            headerName: "Units",
            minWidth: 150

        },
        {
            field: "enabled",
            headerName: "Status",
            minWidth: 200,
            filter: "agNumberColumnFilter",
            /*             floatingFilter: true,
                        suppressMenu: true,
                        floatingFilterComponent: StatusFilter,
                        floatingFilterComponentParams: {
                            suppressFilterButton: true,
                        }, */
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            entityName: params?.data?.parameter_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params.data.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "history",
            headerName: "Status History",
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params.data.id,
                                entity: params?.data?.name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <Paper>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={115}
                    ButtonComponent={ButtonComponent}
                />
            </Paper>
            <DGDrawer
                Component={ProcessParameterNewForm}
                isOpen={formOpen}
                drawerWidth={600}
                setOpen={(state) => {
                    setFormOpen(false);
                    loadList();
                }}
                title={"Process Parameter"}
                componentProps={{
                    user_id: currentUser.id,
                    machine_id: id?.id
                }}
                needSave

            />
        </>
    );
};

export default ProcessList;
