import { Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";

export function TenantInfo() {
  const { currentUser } = useContext(AuthContext);
  return (
    <Grid
      container
      item
      style={{ padding: 20, paddingBottom: 20, paddingTop: 20 }}
      justifyContent="space-between"
    >
      {/*       <Grid container item md={6} sm={6} lg={6}>
        <Grid item md={12} sm={12} lg={12}>
          <Typography style={{ color: "#616161" }} align="left" variant="h3">
            {"Hello "} {currentUser.name.split(' ')[0]} {","}
          </Typography>
        </Grid>

      </Grid> */}

      {/*       < Grid md={6} sm={6} lg={6}>
        <img
          alt="client logo"
          src={currentUser && currentUser?.logo ? currentUser?.logo : ""}
          style={{ height: 60, marginLeft: "auto" }}
        />
      </Grid> */}

    </Grid>
  );
}
