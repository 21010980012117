import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ProcessList from "./processList";
import ProcessPerformance from "./machineProcessPerformance";
import { useParams } from "react-router-dom";
import { SubNavigationContext } from "../../../../context/SubnavigationProvider"
import { StatusContext } from "../../../../context/StatusContextProvider";
import ProcessPartTable from "./parameterPartTable"
import machineService from "../../../../services/machineService";
import { PageTitleContext } from "../../../../context/PageTitleContextProvider";


function MachineParameterDetail({ }) {
  let { id } = useParams();
  const { setStatus } = useContext(StatusContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const { setHeaderPageTitle } = useContext(PageTitleContext);


  const buttons = [
    "Parameter",
    "Parameter Part Matrix",
    "Parameter Performance",
  ];


  /*   useEffect(() => {
      return machineService.getMachineforID(id)
        .then((res) => {
          setHeaderPageTitle(`${res.data.machine_number} ${res.data.name}`);
        })
    }, []);
   */

  return (
    <Paper square>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setSelectedTab(val)}
        aria-label="disabled tabs example"
      >
        {buttons.map((btn, index) => (
          <StyledTab label={btn} value={index} />
        ))}
      </Tabs>
      <Box m={2}>
        {selectedTab === 0 ? <ProcessList machine_id={id} /> : null}
        {selectedTab === 1 ? <ProcessPartTable machine_id={id} /> : null}
        {selectedTab === 2 ? <ProcessPerformance machine_id={id} /> : null}
      </Box>
    </Paper>
  );
}

export default MachineParameterDetail;

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);
