import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { green, red, yellow } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import React from "react";
import { transparentize } from 'polished';
import { useState } from "react";

const useStyles = makeStyles({
  approved: {
    //background: green[100],
    color: "#fff",
    width: "100%",
    borderRadius: 15,
    cursor: "pointer",
    // "&:hover": { background: green[800] },
  },
  pending: {
    //background: yellow[100],
    width: "100%",
    color: "#fff",
    borderRadius: 15,
    cursor: "pointer",
    // "&:hover": { background: yellow[800] },
  },
  rejection: {
    // background: red[100],
    color: "#fff",
    width: "100%",
    borderRadius: 15,
    cursor: "pointer",
    // "&:hover": { background: red[800] },
  },
  header: {
    color: "#000",
    padding: 15,
    paddingBottom: 5,
  },
  subtitle: {
    color: "#0000008A",
    padding: 15,
    paddingRight: 0,
    paddingBottom: 8,
    paddingTop: 0,
  },
  count: {
    color: "#000",
    padding: 15,
    paddingTop: 0,
  },
  percentage: {
    color: "#000",
  },
});
function ApprovalFilter({
  count = { approved: 0, pending: 0, rejected: 0 },
  setRows = () => { },
  rows = [],
  setTableName = () => { },
}) {
  const classes = useStyles();
  const [activeTable, setActiveTable] = useState(0)



  return (
    <>
      <Grid
        container
        item
        md={3}
        sm={3}
        lg={3}
        className={classes.approved}
        onClick={() => {
          setTableName("Approved");
          setRows(0);
          setActiveTable(0)
        }}
        alignContent="center"
        alignItems="center"
        style={{
          backgroundColor
            : activeTable === 0 ? transparentize(0.5, 'green')
              : transparentize(0.95, 'green')
        }}
      >
        <Grid item md={6} sm={6}>
          <Typography align="left" variant="h6" className={classes.header}>
            Approved
          </Typography>
          <Typography align="left" className={classes.subtitle}>
            Total Request Approved
          </Typography>
          <Typography align="left" className={classes.count} variant="h3">
            {count?.approved ?? 0}
          </Typography>
        </Grid>
        <Grid item md={6} sm={6} alignContent="center" justifyContent="center">
          <CircularProgressWithLabel
            value={
              ((count?.approved ?? 0) /
                ((count?.pending ?? 0) +
                  (count?.rejected ?? 0) +
                  (count?.approved ?? 0))) *
              100
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        item md={3} sm={3} lg={3}
        className={classes.pending}
        onClick={() => {
          setTableName("Pending");
          setRows(1);
          setActiveTable(1)
        }}
        alignContent="center"
        alignItems="center"
        style={{
          backgroundColor
            : activeTable === 1 ? transparentize(0, 'yellow')
              : transparentize(0.95, 'yellow')
        }}
      >
        <Grid item md={6} sm={6}>
          <Typography align="left" variant="h6" className={classes.header}>
            Pending
          </Typography>
          <Typography align="left" className={classes.subtitle}>
            Total Request Pending
          </Typography>
          <Typography align="left" className={classes.count} variant="h3">
            {count?.pending ?? 0}
          </Typography>
        </Grid>
        <Grid item md={6} sm={6} alignContent="center" justifyContent="center">
          <CircularProgressWithLabel
            value={
              ((count?.pending ?? 0) /
                ((count?.pending ?? 0) +
                  (count?.rejected ?? 0) +
                  (count?.approved ?? 0))) *
              100
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={3}
        sm={3}
        lg={3}
        className={classes.rejection}
        onClick={() => {
          setTableName("Rejected");
          setRows(2);
          setActiveTable(2)
        }}
        alignContent="center"
        alignItems="center"
        style={{
          backgroundColor
            : activeTable === 2 ? transparentize(0.4, 'red')
              : transparentize(0.95, 'red')
        }}
      >
        <Grid item md={6} sm={6}>
          <Typography align="left" variant="h6" className={classes.header}>
            Rejected
          </Typography>
          <Typography align="left" className={classes.subtitle}>
            Total Request Rejected
          </Typography>
          <Typography align="left" className={classes.count} variant="h3">
            {count?.rejected ?? 0}
          </Typography>
        </Grid>
        <Grid item md={6} sm={6} alignContent="center" justifyContent="center">
          <CircularProgressWithLabel
            value={
              ((count?.rejected ?? 0) /
                (count?.pending ??
                  0 + (count?.rejected ?? 0) + (count?.approved ?? 0))) *
              100
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ApprovalFilter;
function CircularProgressWithLabel({ value = 0 }) {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        style={{ color: "#000" }}
        value={parseInt(value)}
        size={60}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          className={classes.percentage}
          variant="h6"
          component="div"
          color="textSecondary"
        >
          {value ? parseInt(value) : 0}%
        </Typography>
      </Box>
    </Box>
  );
}
