import { MenuItem, Select } from "@material-ui/core";
import React, {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from "react";

export default forwardRef((props, ref) => {
  const [currentValue, setCurrentValue] = useState(1);
  const inputRef = useRef(null);
  

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          inputRef.current.value = "";
          setCurrentValue(null);
        } else {
          inputRef.current.value = parentModel.filter + "";
          setCurrentValue(parentModel.filter);
        }
      },
      afterGuiAttached(params)
      {
        props.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged("equals", currentValue);
        });
      }
    };
  });
  useEffect(()=>{
    afterGuiAttached();
  },[])
  function afterGuiAttached(params)
  {
    props.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged("equals", currentValue);
    });
  }

  const onInputBoxChanged = (input) => {
    if (input.target.value === "") {
      // Remove the filter
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    setCurrentValue(Number(input.target.value));
    props.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged("equals", input.target.value);
    });
  };

  const style = {
    color: props.color,
    width: "30px",
  };

  return (
    <>
      <Select  ref={inputRef} value={currentValue} onChange={onInputBoxChanged} >
        <MenuItem value={1}>Active</MenuItem>
        <MenuItem value={0}>InActive</MenuItem>{" "}
      </Select>
    </>
  );
});
