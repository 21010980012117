import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { object, string } from "yup";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AuthContext } from "../../../context/AuthContextProvider";
import gaugeService from "../../../services/gaugeService";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
//import globalPlant from "../../../constants/PlantContextProvider"
import plantService from "../../../services/plantService";

export function GaugeIssueForm({
  Footer = () => null,
  componentProps = null,
  closeForm = () => { },
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const { globalPlant } = useContext(PlantContext);

  let form = useFormik({
    initialValues: {
      plant: null,
      plantOptions: [],
      gauge: null,
      gaugeoptions: [],
      user: null,
      userOptions: [],
      location: "",
    },
    validationSchema: object({
      plant: object().required(),
      gauge: object().required(),
      location: string().required(),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    plantService.getDefaultPlant(currentUser?.id)
      .then((res) => {
        const plantOptions = (res?.data ?? []).slice(1);
        form.setFieldValue("plantOptions", plantOptions);
      })
  }, []);

  useEffect(() => {
    if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
      form.setFieldValue("plant", (globalPlant?.globalPlant));
      form.setFieldValue("plant_flag", 0)

    } else {
      if (currentUser.default_plant) {
        const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
        form.setFieldValue("plant", defaultPlant)
        form.setFieldValue("plant_flag", 1)

      } else {
        const defaultPlant = form.values.plantOptions[0]
        form.setFieldValue("plant", defaultPlant)
        form.setFieldValue("plant_flag", 2)
      }
    }
  }, [form.values.plantOptions]);

  useEffect(() => {
    //form.setFieldValue("plantOptions", componentProps?.plantList);
    form.setFieldValue("userOptions", componentProps?.userList);
  }, []);
  function handleSubmit() {
    gaugeService
      ?.createGaugeIssue(
        form.values?.gauge?.id,
        form.values?.user?.id ?? "",
        currentUser?.id,
        form?.values?.location
      )
      .then((res) => {
        closeForm();
        updateAlert({
          open: true,
          message: "Gauge Issue Created SuccessFully",
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          open: true,
          message: "Failed to create Gauge Issues",
          type: "error",
        });
      });
  }

  function loadAllGauges() {
    if (form?.values?.plant?.id) {
      return gaugeService.getGaugeList(form?.values?.plant?.id).then((res) => {
        form.setFieldValue("gaugeoptions", res?.data ?? []);
      });
    } else {
      form.setFieldValue("gaugeoptions", []);
      return Promise.resolve();
    }
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <DGSelect
          label="Plant"
          value={form.values.plant}
          options={form.values.plantOptions}
          onChange={(val) => form.setFieldValue("plant", val)}
          error={Boolean(form.errors.plant) & form.touched.plant}
          required
        />
      </Grid>
      <Grid item md={12}>
        <DGSelect
          label="Gauge"
          value={form.values.gauge}
          options={form.values.gaugeoptions}
          onChange={(val) => form.setFieldValue("gauge", val)}
          error={Boolean(form.errors.gauge) & form.touched.gauge}
          getOptionLabel={(opt) => opt?.guage_list ?? ""}
          required
          isAsynchronus={true}
          asyncCallback={loadAllGauges}
        />
      </Grid>
      <Grid item md={12}>
        <DGSelect
          label="User"
          value={form.values.user}
          options={form.values.userOptions}
          onChange={(val) => form.setFieldValue("user", val)}
          error={Boolean(form.errors.user) & form.touched.user}
        />
      </Grid>
      <Grid item md={12}>
        <DGInput
          id="location"
          isFormik
          label="Location"
          value={form.values.location}
          onChange={form.handleChange}
          error={Boolean(form.errors.location) & form.touched.location}
          required
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
