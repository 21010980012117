import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from '../../../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import StatusFilter from "../../../../components/shared/StatusFilter";
import ActiveInActive from "../../../../components/ActiveInActive";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import machineMaintenanceService from '../../../../services/machineMaintenanceService';
import { DGDrawer } from '../../../../components/DGDrawer';
import { MaintenanceCheckPointAdd } from './maintenanceCheckPointAdd';
import AddIcon from '@material-ui/icons/Add';
import { set } from 'date-fns';


export function MachineMaintenanceStdQ({

}) {

    const { setStatus } = useContext(StatusContext);
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [entryFormOpen, setEntryFormOpen] = useState(false);
    const [maxID, setMaxID] = useState(0);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
        }));
        reloadGridData();

    }, []);


    function reloadGridData() {
        return machineMaintenanceService.getMachineMaintenanceQ(currentUser.client_id)
            .then((res) => {
                setRows(res?.data ?? []);
                const data = res?.data ?? []
                setMaxID(data.reduce((max, item) => item.q_number > max ? item.q_number : max, data[0].q_number))
            });
    }


    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return machineMaintenanceService.updateMachineMaintenanceQ(rowId, { enabled: newStatus });
    }

    const columns = [
        {
            field: "q_number",
            headerName: "Check Point Number",
            minWidth: 75,
            sorting: true,
        },

        {
            field: "maintenance_question",
            headerName: "Check Point",
            minWidth: 400,
            resizable: true,
            sorting: true,
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive

                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.q_number ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        disabled={params?.data?.edit_flag !== 1}
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.q_number ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        }
    ]


    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setEntryFormOpen(1);
                    }}
                >
                    Add Check Points
                </Button>

            </>
        );
    }

    return (

        <>
            <Grid item md={12} sm={12} lg={12}>

                <CommonGrid
                    ButtonComponent={ButtonComponent}
                    rows={rows}
                    columns={columns}
                    gridheight={115}

                />

            </Grid>



            <DGDrawer
                isOpen={entryFormOpen}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()

                }}
                Component={MaintenanceCheckPointAdd}
                title='Add Check Points'
                drawerWidth={800}
                needSave
                componentProps={{ maxID: maxID }}

            />

        </>
    )
}
