import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Link,
  Typography,
} from "@material-ui/core";
// import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useContext, useState, useEffect } from "react";
// import { Briefcase, User, MapPin } from "react-feather";
import styled from "styled-components/macro";
import { AuthContext } from "../../context/AuthContextProvider";
import userService from "../../services/userService";
// import DomainIcon from "@material-ui/icons/Domain";
// import AddCompanyDialog from "../depreciated/AddCompanyDialog";
// import AddIcon from "@material-ui/icons/Add";
// import EditIcon from "@material-ui/icons/Edit";
// import addressService from "../../services/addressService";
import { Card, CardBody, CardHeader, Heading, Image, Stack, StackDivider, Text } from "@chakra-ui/react";

//const Button = styled(MuiButton)(spacing);



const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Centered = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  display: inline-block;
  height: 128px;
  width: 128px;
`;

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

const CompanyDetails = () => {
  const [addCompanyDialog, setAddCompanyDialog] = useState(false);
  const [company, setCompany] = useState();
  const [editingCompany, setEditingCompany] = useState();
  const [editingAddress, setEditingAddress] = useState();
  const [isKeyUser, setIsKeyUser] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    currentUser && getUserRole();
    currentUser && getCompanyDetails();
  }, [currentUser]);

  const getUserRole = async () => {
    // console.log("currentUser", currentUser);
    try {
      const response = await userService.getUserRole(currentUser.id);
      //console.log("🚀  -> file: CompanyDetails.js -> line 73 -> getUserRole -> response", response.data.data);
      if (response.data && response.data.data && response.data.data[0] && response.data.data[0].role) {
        if (response.data.data[0].role.id == '1') {
          setIsKeyUser(true);
        }
      }
    } catch (error) {
      // console.log("🚀  -> file: CompanyDetails.js -> line 133 -> getUserRole -> error", error);
    }
  };

  const getCompanyDetails = async () => {
    // console.log("currentUser", currentUser);
    try {
      const response = await userService.getClientById(currentUser.client_id);
      // console.log("🚀  -> file: CompanyDetails.js -> line 73 -> getCompanyDetails -> response", response);
      if (response && response.data) {
        setCompany(response.data);
      }
    } catch (error) {
      // console.log("🚀  -> file: CompanyDetails.js -> line 133 -> getCompanyDetails -> error", error);
    }
  };

  // console.log("company", company);

  const handleEditCompany = async (data) => {
    if (data && data.address) {
      setEditingAddress({
        address_line_1: data.address.address_line_1,
        address_line_2: data.address.address_line_2,
        city: data.address.city,
        pincode: data.address.pincode,
        state: data.address.state,
        country: data.address.country,
      });
      setEditingCompany(data);
      setAddCompanyDialog(true);
    }
  };

  return (
    <React.Fragment>
      {/* <Card mb={6} style={{ height: "100%" }}>
        <CardContent>
          <img src={company?.logo} width="100%"/>
          <Spacer mb={4} />
          {company?.business_name && (
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <AboutIcon>
                  <DomainIcon />
                </AboutIcon>
              </Grid>
              <Grid item>
                {company?.business_name ? company?.business_name : ""}
              </Grid>
            </Grid>
          )}
          <Spacer my={2} />
          {company?.address && (
            <Grid
              direction="row"
              alignItems="start"
              flexWrap="nowrap"
              mb={2}
              className="d-flex nowrap"
            >
              <Grid item>
                <AboutIcon>
                  <MapPin />
                </AboutIcon>
              </Grid>
              <Grid item>
                <span>
                  {company?.address?.address_line_1},{" "}
                  {company?.address?.address_line_2}, {company?.address?.city} -{" "}
                  {company?.address?.pincode}, {company?.address?.state},{" "}
                  {company?.address?.country}
                </span>
              </Grid>
            </Grid>
          )}
          <Spacer my={2} />
          {company?.gst && (
            <Grid container direction="row" alignItems="center" mb={2}>
              <Grid item>
                <AboutIcon>
                  <Briefcase />
                </AboutIcon>
              </Grid>
              <Grid item>
                <b>GST : </b> {company?.gst ? company?.gst : ""}
              </Grid>
            </Grid>
          )}
          <Spacer my={4} />
          <Grid
            item
            mr={2}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            {company && isKeyUser ? (<>
              <Button
                mr={2}
                my={3}
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<EditIcon />}
                onClick={() => handleEditCompany(company)}
              >
                Edit Company Details
              </Button>
            </>) : null}
          </Grid>
        </CardContent>
      </Card> */}
      <Card style={{ height: "100%", backgroundColor: "#fff" }}>
        <CardHeader style={{ height: 'auto' }}>
          <Image src={company?.logo}
            width="100%"
            height="100%"
            alt="client Logo" />
        </CardHeader>


        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Company
              </Heading>
              <Text pt='2' fontSize='sm'>
                {company?.business_name ? company?.business_name : ""}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Address
              </Heading>
              <Text pt='2' fontSize='sm'>
                {company?.address?.address_line_1},{" "}
                {company?.address?.address_line_2}, {company?.address?.city} -{" "}
                {company?.address?.pincode}, {company?.address?.state},{" "}
                {company?.address?.country}
              </Text>
            </Box>
            {company && isKeyUser ? <Box>
              <Heading size='xs' textTransform='uppercase'>
                GST
              </Heading>
              <Text pt='2' fontSize='sm'>
                {company?.gst ? company?.gst : ""}
              </Text>
            </Box> : null}
          </Stack>
        </CardBody>
      </Card>
      {/*       {addCompanyDialog && (
        <AddCompanyDialog
          addCompanyDialog={addCompanyDialog}
          setAddCompanyDialog={setAddCompanyDialog}
          onSuccess={(e) => getCompanyDetails()}
          isEdit={true}
          editingCompany={editingCompany}
          editingAddress={editingAddress}
        />
      )} */}
    </React.Fragment>
  );
};

export default CompanyDetails;
