import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { DGInput } from "../../components/shared/DGInput";
import { DGDateInput } from "../../components/shared/DGDateInput";
import { DGSelect } from "../../components/shared/DGSelect";
import CommonGrid from "../../components/CommonGrid";
import { useFormik } from "formik";
import cuttingToolService from "../../services/cuttingToolService";
import { AuthContext } from "../../context/AuthContextProvider";
import { grid } from "@material-ui/system";
import { DeleteRenderer } from "../../components/cellRenderers/DeleteRenderer";
import { object, number } from "yup";
import { AlertContext } from "../../context/AlertContextProvider";
import errorLog from "../../services/errorLog";
import vendorService from "../../services/vendorService";
import { format, parseISO } from "date-fns"





export default function VendorAccesForm({
    Footer = () => null,
    closeForm = () => { },
    componentProps = null

}) {

    const { updateAlert } = useContext(AlertContext);
    const { currentUser } = useContext(AuthContext);

    const { v4: uuidv4 } = require('uuid');


    let form = useFormik({
        initialValues: {
            access_date: new Date(),
            contact_number: "",
        },
        validationSchema: object().shape({
            contact_number: number()
                .integer()
                .required("Contact number is required")

        }),

        onSubmit: onSave


    });



    function onSave() {

        let payload = {
            client_id: currentUser.client_id,
            phone_number: form.values?.contact_number,
            pi_agency_id: componentProps?.vendorPiAgency?.id,
            vendorTypeId: componentProps?.vendorPiAgency?.vendor_type_id === "10"
                ? 11
                : componentProps?.vendorPiAgency?.vendor_type_id,
            vendor_access_from: form.values?.access_date,
            vendor_added_by: currentUser.id
        }


        vendorService.createVendorAccess(payload)
            .then((res) => {
                updateAlert({
                    open: true,
                    message: "Vendor Access Created",
                    type: "success"
                })
                closeForm()
            }
            )
            .catch((err) => {
                updateAlert({
                    open: true,
                    message: "Vendor Access Creation Failed",
                    type: "error"
                })
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "createVendorAccess",
                    client_id: currentUser.client_id,
                    user_id: null,
                    entity_id: null,
                };
                errorLog.createErrorLog(payload)
            });
    }

    return (

        <Grid container spacing={3}>
            <Grid item xs={12}>
                <DGInput
                    id="contact_number"
                    name="Contact Number"
                    value={form.values.contact_number}
                    onChange={form.handleChange}
                    isFormik
                />
            </Grid>

            <Grid item xs={12}>
                <DGDateInput
                    label="Access Date"
                    name="access_date"
                    value={form.values.access_date}
                    format="dd/MM/yyyy"
                    onChange={(date) => form.setFieldValue("access_date", date)}
                    error={form.touched.access_date && Boolean(form.errors.access_date)}
                />
            </Grid>

            <Footer onSave={form.handleSubmit} />

        </Grid>



    )



}