import {
    Box,
    CircularProgress,
    Grid, Paper, FormGroup, Radio, RadioGroup,
    IconButton, Container,
    makeStyles,
    Typography,
    Hidden,
} from "@material-ui/core";
import { cyan, green } from "@material-ui/core/colors";
import React, { useContext, useEffect, useState, Component } from "react";
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import customerService from '../../services/customerService'
import productService from '../../services/productService'
import Divider from "@material-ui/core/Divider";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
import { DGSelect } from "../../components/shared/DGSelect";
import MuiSlider from '@material-ui/core/Slider';
// import Slider from "react-slick";
import {
    format, addDays, addWeeks, addMonths, getWeek, startOfWeek,
    startOfMonth, endOfMonth, endOfWeek
} from 'date-fns'
import Autocomplete from "@material-ui/lab/Autocomplete";
import CCMDay from "./metricsCardCCM";
import SimpleSlider from "../../components/SlickSlider";
import { maxWidth } from "@material-ui/system";


export function CCMOverview() {
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);

    const [dataDepth, setdataDepth] = useState('')
    const [dataLabel, setdataLabel] = useState('')
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [DateDisplay, setDateDisplay] = useState(new Date())
    const [Offset_index, setOffset_index] = useState(0)
    const [Offset_type, setOffset_type] = useState(0)
    const [Customers, setCustomers] = useState('')
    const [customerSelect, setCustomerSelect] = useState('')
    const [Parts, setParts] = useState('')
    const [PartSelect, setPartSelect] = useState('')
    const [plant_id, setPlantId] = useState(currentUser.client_id)


    useEffect(() => {
        if (currentUser.default_plant !== null) {
            setPlantId(currentUser.default_plant);
        } else if (globalPlant && globalPlant["globalPlant"] && globalPlant["globalPlant"]["id"]) {
            setPlantId(globalPlant["globalPlant"]["id"]);
        }
    }, []);

    useEffect(() => {
        if (globalPlant && globalPlant["globalPlant"] && globalPlant["globalPlant"]["id"]) {
            setPlantId(globalPlant["globalPlant"]["id"]);
        } else {
            // Handle the case where the properties don't exist or are undefined
        }
    }, [globalPlant]);

    useEffect(() => {

        customerService.getCustomers(currentUser.client_id)
            .then((res) => {
                setCustomers(res?.data?.data ?? []);
            })
            .catch((error) => {
                console.error("Error fetching customers:", error);
            });


        productService.getAProductByPlant(currentUser.default_plant)
            .then((res) => {
                setParts(res?.data ?? []);
            })
            .catch((error) => {
                console.error("Error fetching parts:", error);
            });
    }, []);


    useEffect(() => {
        if (!customerSelect) {
            productService.getAProductByPlant(currentUser.default_plant)
                .then((res) => {
                    setParts(res?.data ?? []);
                })
                .catch((error) => {
                    console.error("Error fetching parts:", error);
                })

        }
        else {
            customerService.getCustomerProduct(customerSelect.id).then
                ((res) => {
                    setParts(res?.data ?? []);
                })
        }

    }, [customerSelect])

    useEffect(() => {
        if (currentUser.user_role_id > 3) { setdataDepth(1); setdataLabel("Day") }
        else if (currentUser.user_role_id >= 2 && currentUser.user_role_id <= 3) { setdataDepth(2); setdataLabel("Week") }
        else if (currentUser.user_role_id >= 1 && currentUser.user_role_id <= 2) { setdataDepth(3); setdataLabel("Month") }
    }, [currentUser.user_role_id]);

    useEffect(() => {
        setDateDisplay(currentDate => {
            let newDate = new Date();
            if (dataDepth === 1) {
                setstartDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                setendDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                newDate = addDays(newDate, Offset_index);
                setdataLabel("Day");
            }
            else if (dataDepth === 2) {
                setstartDate(format(startOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                setendDate(format(endOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                newDate = addWeeks(newDate, Offset_index);
                setdataLabel("Week")
            }
            else if (dataDepth === 3) {
                setstartDate(format(startOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                setendDate(format(endOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                newDate = addMonths(newDate, Offset_index);
                setdataLabel("Month");
            }
            return newDate;
        });
    }, [Offset_index, dataDepth]);


    const appBarStyle = {
        position: 'sticky',
        backgroundColor: '#D6EAF8',
    };

    const formatDate = (date) => {
        switch (dataDepth) {
            case 1:
                return format(date, 'dd/MM/yyyy, EEEE');
            case 2:
                const weekNumber = getWeek(date, { weekStartsOn: 1 });
                const firstDayOfWeek = startOfWeek(date, { weekStartsOn: 1 });
                return `Week-${weekNumber} - ${format(firstDayOfWeek, 'dd/MM/yy')}`;
            case 3:
                return format(date, 'MMM yyyy');
            default:
                return '';
        }
    };


    return (

        <React.Fragment>
            <Container style={{ padding: '3px' }}>

                <AppBar style={appBarStyle}>
                    <Grid container spacing={5}
                        style={{ width: '99%', marginLeft: 5, marginRight: 5 }}>
                        <Grid item md={4} sm={4} lg={4}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography style={{
                                    gutterBottom: true
                                }}>
                                    Select Period
                                </Typography>
                                <RadioGroup
                                    row
                                    value={dataDepth.toString()}
                                    onChange={(event) => {
                                        const newValue = parseInt(event.target.value);
                                        setdataDepth(newValue);
                                        setOffset_index(0);
                                    }}
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="Day" />
                                    <FormControlLabel value="2" control={<Radio />} label="Week" />
                                    <FormControlLabel value="3" control={<Radio />} label="Month" />
                                </RadioGroup>
                            </div>
                        </Grid>
                        <Grid item md={4} sm={4} lg={4}>

                            <Typography
                                variant="h3"
                                component="h1"
                                style={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    color: 'black'
                                }}>
                                {formatDate(DateDisplay)}
                            </Typography>

                        </Grid>

                        <Grid item md={4} sm={4} lg={4}>
                            <div style={{ width: '80%' }}>
                                <Typography gutterBottom>
                                    Previous {' '}
                                    {dataDepth === 1 && 'Day'}
                                    {dataDepth === 2 && 'Week'}
                                    {dataDepth === 3 && 'Month'}

                                </Typography>
                                <MuiSlider
                                    value={Offset_index}
                                    valueLabelDisplay="off"
                                    step={1}
                                    marks={Array.from({ length: 7 }, (_, index) => ({
                                        value: -index,
                                        label: index === 0 ? 'Current' : `${-index}`,
                                    }))}
                                    min={-6}
                                    max={0}
                                    onChange={(event, newValue) => {
                                        setOffset_index(newValue);
                                    }}
                                />
                            </div>


                        </Grid>


                    </Grid>
                </AppBar>
            </Container >


            <SimpleSlider>
                <div>
                    <CCMDay
                        cardName={"SPC"}
                        cardNumber={0}
                        datalabel={dataLabel}
                        client_id={currentUser.client_id}
                        plant_id={plant_id}
                        dataDepth={dataDepth}
                        Offset_index={Offset_index}
                        Offset_type={0}
                    />
                </div>

                <div>
                    <CCMDay
                        cardName={"Product Inspection"}
                        cardNumber={1}
                        datalabel={dataLabel}
                        client_id={currentUser.client_id}
                        plant_id={plant_id}
                        dataDepth={dataDepth}
                        Offset_index={Offset_index}
                        Offset_type={0}

                    />
                </div>

                <div>
                    <CCMDay
                        cardName={"Pre-Dispatch Inspection"}
                        cardNumber={2}
                        datalabel={dataLabel}
                        client_id={currentUser.client_id}
                        plant_id={plant_id}
                        dataDepth={dataDepth}
                        Offset_index={Offset_index}
                        Offset_type={0}
                    />
                </div>

                <div>
                    <CCMDay
                        cardName={"Process Capability"}
                        cardNumber={3}
                        datalabel={dataLabel}
                        client_id={currentUser.client_id}
                        plant_id={plant_id}
                        dataDepth={dataDepth}
                        Offset_index={Offset_index}
                        Offset_type={0}
                    />
                </div>

                <div>

                    <CCMDay
                        cardName={"MSA"}
                        cardNumber={4}
                        datalabel={dataLabel}
                        client_id={currentUser.client_id}
                        plant_id={plant_id}
                        dataDepth={3}
                        Offset_index={Offset_index}
                        Offset_type={0}
                    />
                </div>

            </SimpleSlider>


            <Container style={{ padding: '3px' }}>
                <AppBar style={appBarStyle}>
                    <Grid container spacing={5}
                        style={{
                            width: '99%', marginLeft: 5, marginRight: 5,
                            marginTop: 5, marginBottom: 5
                        }}>

                        <Grid item md={6} sm={6} lg={6}>
                            <DGSelect
                                label="Customer"
                                options={Customers}
                                value={customerSelect}
                                getOptionLabel={(option) => option?.company_name ?? ""}
                                fullWidth
                                onChange={(val) => {
                                    setCustomerSelect(val);
                                    setPartSelect('')
                                }}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} lg={6}>
                            <DGSelect
                                label="Part Number"
                                options={Parts}
                                value={PartSelect}
                                getOptionLabel={(option) =>
                                    `${option?.part_number ?? ""}  ${option?.part_name ?? ""}`
                                }
                                onChange={(val) => {
                                    setPartSelect(val);
                                }}
                            />
                        </Grid>
                    </Grid>

                </AppBar>



            </Container >
        </React.Fragment >
    );
}


