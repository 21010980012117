import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { blueGrey } from "@material-ui/core/colors";
import { rgba } from "polished";
import { AuthContext } from "../../../context/AuthContextProvider";
import spcService from "../../../services/spcService";
import CommonGrid from "../../../components/CommonGrid";
import { DGDrawer } from "../../../components/DGDrawer";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { PlantContext } from "../../../context/PlantContextProvider";
import { format } from 'date-fns'
import { SpcDetail } from "./SpcDetail";
import SPCEntryForm from "./SpcEntryForm";


export function CriticalCharacterSPC(props) {
  //Component States
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext);
  const [spcData, setSpcData] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [detailFormOpen, setDetailFormOpen] = useState(false);
  const [entryFormOpen, setEntryFormOpen] = useState(false);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const [selectedRow, setSelectedRow] = useState(null);

  //Component Triggers

  setHeaderPageTitle("Statistical Process Control");

  useEffect(() => {
    reloadGridData();

  }, [globalPlant]);

  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }


  function reloadGridData() {
    spcService
      .getSPCTable({
        query_id: globalPlant?.globalPlant?.id,
        client_id: currentUser?.client_id,
      })
      .then((res) => {
        setSpcData(res?.data ?? []);
      });
  }


  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      resizable: true,
    },
    {
      field: "plant_name",
      headerName: "Plant",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },
    {
      headerName: "Part",
      valueGetter: (params) =>
        `${params?.data?.part_number}-${params?.data?.part_name}`,
      minWidth: 300,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      cellRenderer: (params) => {
        return (
          <>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {params?.data?.part_number}
            </Typography>

            <Typography variant="body2" >
              {params?.data?.part_name}
            </Typography>

          </>
        )
      },
    },

    {
      headerName: "Specification",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 250,
      autoHeight: true,
      cellRenderer: (params) => {
        return (
          <>
            <Typography variant="body2" style={{ marginBottom: '-8px', }}>
              {params?.data?.character_name}
            </Typography>

            <Button
              size="small"
              color="primary"
              lineHeight={15}
              style={{ padding: 0 }}
              onClick={() => {
                setSelectedRow(params?.data)
                setDetailFormOpen(true);
              }}
            >
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                {`${params?.data?.specification}  
                  ${params?.data?.upper_specification ?? ""
                  }  ${params?.data?.lower_specification ?? ""}`}
              </Typography>
            </Button>


            <Typography variant="body2" style={{ marginTop: '-8px', marginBottom: '4px' }}>
              {`${params?.data?.machine_name}  ${params?.data?.spindle_name}`}
            </Typography>


          </>
        )
      },
    },

    {
      field: "count_character",
      headerName: "Evaluations",
      resizable: true,
      minWidth: 200,
    },

    {
      field: "latest_date",
      headerName: "Last Evaluated",
      resizable: true,
      minWidth: 200,
      valueGetter: (params) =>
        format(new Date(params?.data?.latest_date), 'dd/MM/yyyy')

    },

  ];

  function ButtonComponent() {
    return (

      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setEntryFormOpen(1);
          }}
        >
          New Entry
        </Button>

      </>
    );
  }

  const classes = useStyles();
  return (
    <>
      <CommonGrid
        needExport={true}
        ButtonComponent={ButtonComponent}
        rows={spcData}
        columns={columns}
        gridheight={100}
      />

      <DGDrawer
        isOpen={entryFormOpen}
        setOpen={() => {
          setEntryFormOpen(false);
        }}
        Component={SPCEntryForm}
        drawerWidth={1200}
        title="SPC ENTRY"
      />

      <DGDrawer
        isOpen={detailFormOpen}
        componentProps={selectedRow}
        setOpen={() => {
          setDetailFormOpen(false)
        }}
        Component={SpcDetail}
        drawerWidth={1200}

      />
    </>
  );
}
const useStyles = makeStyles({
  addButton: {
    textAlign: "end",
  },
  addButtonLayout: {
    border: "solid 1px",
    borderColor: "#babfc7",
    textAlign: "end",
    borderBottom: 0,
    padding: 10,
  },
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});
