import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Grid } from "@material-ui/core";
import CommonGrid from '../../../components/CommonGrid'
import { AuthContext } from '../../../context/AuthContextProvider';
import { useFormik } from "formik";
import { object, array } from "yup";
import { AlertContext } from "../../../context/AlertContextProvider"
import { PlantContext } from "../../../context/PlantContextProvider";
import { DGSelect } from '../../../components/shared/DGSelect';
import productService from "../../../services/productService"
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer"
import dateService from '../../../services/dateService';
import machineService from '../../../services/machineService'
import plantService from "../../../services/plantService"
const { v4: uuidv4 } = require("uuid");

export function ProductMachineLink({
    Footer = () => { },
    closeForm = () => { },
}) {
    const { updateAlert } = useContext(AlertContext);
    const { currentUser } = useContext(AuthContext);
    const [gridApi, setGridApi] = useState(null);
    const { globalPlant } = useContext(PlantContext);


    let form = useFormik({
        initialValues: {
            gridData: [],
            part: null,
            plant: null,
            partOptions: [],
            machineOptions: [],
            gridData: [],
            plantOptions: [],
            plant_flag: null,
        },
        validationSchema: object({
            gridData: array().min(1),

        }),
        onSubmit: onSave
    });

    let tempform = useFormik({
        initialValues: {
            machine: null,
            machineOptions2: []
        },
        validationSchema: object({
            machine: object().required(),

        }),
        onSubmit: onAdd
    });

    function onGridReady(params) {
        setGridApi(params?.api);
    }

    useEffect(() => {
        plantService.getDefaultPlant(currentUser?.id)
            .then((res) => {
                const plantOptions = (res?.data ?? []).slice(1);
                form.setFieldValue("plantOptions", plantOptions);
            })
    }, []);

    useEffect(() => {
        if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
            form.setFieldValue("plant", (globalPlant?.globalPlant));
            form.setFieldValue("plant_flag", 0)

        } else {
            if (currentUser.default_plant) {
                const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 1)

            } else {
                const defaultPlant = form.values.plantOptions[0]
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 2)
            }
        }
    }, [form.values.plantOptions]);

    useEffect(() => {
        if (form.values.plant !== null) {
            productService.getAProductByPlant(form.values.plant?.id)
                .then(res => {
                    var temp = res.data
                    form.setFieldValue("partOptions", temp);
                })
        }
    }, [form.values.plant])


    useEffect(() => {
        if (form.values.part !== null) {
            machineService.getProductMachineAdd(
                form.values.part.id,
                form.values.plant?.id
            )
                .then(res => {
                    var temp1 = res?.data
                    tempform.setFieldValue('machineOptions2', temp1)
                    form.setFieldValue('machineOptions', temp1)
                })
        }
    }, [form.values.part])

    //console.log(form.values.gridData)

    function onClear() {
        form.setFieldValue("gridData", null);
        tempform.setFieldValue("machineOptions", form.values.machineOptions)
    }

    function onAdd() {
        let data = [];
        data?.push({
            part_id: form.values.part?.id,
            machine_id: tempform.values.machine?.id,
            machine_number: tempform.values.machine?.machine_number,
            name: tempform.values.machine?.name,
        });
        form.setFieldValue("gridData", [...form.values.gridData, ...data]);

    }

    useEffect(() => {
        var temp2 = form.values.gridData
        if (temp2 !== null) {
            var machineIDNew = new Set(temp2.map(item => item.machine_id));
            var templist = form.values.machineOptions
            var tempOptions = templist.filter(item => !machineIDNew.has(item.id));
            tempform.setFieldValue("machineOptions2", tempOptions)
            tempform.setFieldValue("machine", null)
        }
    }, [form.values.gridData]);


    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...form.values.gridData];
        updatedGridData.splice(rowIndex, 1);
        form.setFieldValue("gridData", updatedGridData);
        gridApi?.updateRowData({ remove: [data] });
    }

    function onSave() {
        let payload = [];
        gridApi.forEachNode((node) => {
            let data = node?.data;
            payload.push({
                id: uuidv4(),
                client_id: currentUser.client_id,
                character_id: null,
                part_id: data.part_id,
                machine_id: data.machine_id,
                machine_spindle_id: null,
                process_id: null,
                created_at: dateService.getDateInSqlFormat(new Date()),
                updated_at: dateService.getDateInSqlFormat(new Date()),
                created_by: currentUser.id,
                plan_name: null,
                enabled: 1,
                machine_spindle_drop: null

            })
        })

        machineService.createCharacterMachine(payload).then(res => {
            closeForm();
            updateAlert({ message: "Machine added Successfully", type: "success", open: true });
        }).catch(err => {
            updateAlert({ message: "Failed To add Machine", type: "error", open: true })
        }
        )
    }


    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            pinned: "left",
            resizable: true,
        },
        {
            headerName: "Machine",
            resizable: true,
            minWidth: 200,
            pinned: "left",
            valueGetter: (params) => {
                return `${params.data?.machine_number} ${params.data?.name}`
            }
        },]

    return (
        <Grid container spacing={4}>
            <Grid item md={12} sm={12} lg={12}>

                {form.values.plant_flag > 0 && (
                    <DGSelect
                        id="plant"
                        label="Plant"
                        options={form.values.plantOptions}
                        getOptionLabel={(option) => option.name}
                        value={form.values.plant}
                        onChange={(val) => {
                            form.setFieldValue("plant", val)
                            form.setFieldValue("part", null)
                            form.setFieldValue("gridData", [])
                        }}
                        required
                        disabled={form.values.plant_flag === 0}
                    />
                )}
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="part"
                    label="Part"
                    options={form.values.partOptions}
                    getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
                    value={form.values.part}
                    required
                    // disabled={(form.values.part).length === 1}
                    onChange={(val) => {
                        form.setFieldValue("part", val);
                        //form.setFieldValue("gridData", null);
                    }}
                />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="machine"
                    label="Machine"
                    options={tempform.values.machineOptions2}
                    getOptionLabel={(opt) => `${opt?.machine_number}-${opt?.name}`}
                    value={tempform.values.machine}
                    onChange={(val) => {
                        tempform.setFieldValue("machine", val);
                    }}
                    disabled={(tempform.values.machineOptions2).length === 0}

                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <Button
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                    color="primary"
                    variant="outlined"
                    onClick={tempform.handleSubmit}
                    className="m-2"
                >
                    Add
                </Button>

            </Grid>

            <Grid md={12} sm={12} lg={12}>
                <CommonGrid
                    columns={columns}
                    rows={form.values.gridData?.length ? form.values.gridData : []}
                    needExport={false}
                    onGridReady={onGridReady}
                />
            </Grid>

            <Footer onSave={form.handleSubmit} />
        </Grid>
    );

}