import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core";
import { cyan, green } from "@material-ui/core/colors";
import React, { useContext, useEffect, useState } from "react";
import { StackedBarGraph } from "../../../components/graphs/StackedBarGraphs";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import dashboardService from "../../../services/dashboardService";
import SpeedRoundedIcon from "@material-ui/icons/SpeedRounded";


export function CalibrationOverview() {
    const { currentUser } = useContext(AuthContext);
    const [callibs, setCallibs] = useState({});
    const [gaugeStatus, setGaugeStatus] = useState(null);
    const [callibStatus, setcallibStatus] = useState(null);
    const [gaugeOverDue, setGaugeOverdue] = useState(0);
    const { globalPlant } = useContext(PlantContext);

    useEffect(() => {
        if (globalPlant?.globalPlant, currentUser.client_id) {
            loadCalibrationHistory();
        }
    }, [globalPlant, currentUser]);

    function loadCalibrationHistory() {
        dashboardService.gaugeDash({
            client_id: currentUser.client_id,
            graph_type: 2,
            plant_id: globalPlant?.globalPlant?.id
        })
            .then((res) => {
                let data = res?.data ?? [];
                let option = {
                    // theme: myTheme,
                    overrides: {
                        bar: {
                            series: {
                                fill: ['#FFAA33', '#C4B454', '#663399', '#9FE2BF', '#F4BB44', '#B4C424', '#808000', '#E4D00A'],
                            },
                        },
                    },
                    title: {
                        text: "Calibration History",
                    },
                    subtitle: {
                        text: "Monthly Report",
                    },
                };
                let graphData = [];
                let series = new Map();
                data.map((exp) => {
                    for (let key in exp?.key_) {
                        series.set(key, {
                            type: "column",
                            xKey: "Calib_date",
                            yKey: key,
                            yName: key,
                            stacked: true,
                            tooltip: {
                                renderer: (params) => {
                                    return {
                                        content: params?.yValue,
                                        title: params?.xValue
                                    };
                                }
                            },
                        });
                    }
                    graphData.push({ Calib_date: exp.Calib_date, ...exp.key_ });
                });
                option["series"] = [...series.values()];
                option["data"] = graphData;
                setCallibs(option);
            });
    }
    const classes = useStyles();

    return (
        <Grid container spacing={4} style={{ paddingTop: 10, overflow: "scroll" }}>

            <Grid item md={12} sm={12} lg={12}>
                {callibs && <StackedBarGraph options={callibs} />}
            </Grid>
        </Grid>
    );
}
const useStyles = makeStyles({
    approved: {
        background: cyan[100],
        color: "#fff",
        width: "100%",
        cursor: "pointer",
        paddingTop: 40,
        // "&:hover": { background: green[800] },
    },
    gauges: {
        background: cyan[100],
        textAlign: "center",
        paddingTop: 40
    },
    header: {
        color: "#000",
        padding: 15,
        paddingBottom: 5,
    },
    subtitle: {
        color: "#0000008A",
        padding: 15,
        paddingRight: 0,
        paddingBottom: 8,
        paddingTop: 0,
    },
    count: {
        color: "#000",
        padding: 15,
        paddingTop: 0,
        background: cyan[100],
        height: "100%",
        textAlign: 'center'
    },
    percentage: {
        color: "#000",
    },
});

/* export function CircularProgressWithLabel({ value = 0 }) {
    const classes = useStyles();
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                variant="determinate"
                style={{ color: "#000" }}
                value={parseInt(value)}
                size={60}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    className={classes.percentage}
                    variant="h6"
                    component="div"
                    color="textSecondary"
                >
                    {value ? parseInt(value) : 0}%
                </Typography>
            </Box>
        </Box>
    );
} */

var myTheme = {
    baseTheme: 'ag-solar',
    palette: {
        fills: ['#FFAA33', '#C4B454', '#663399', '#9FE2BF', '#F4BB44', '#B4C424', '#808000', '#E4D00A'],
        strokes: [],
    },
    overrides: {
        cartesian: {
            title: {
                fontSize: 24,
            }
        },
    },
};
