import { Box, Grid, makeStyles, Button } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { StatusContext } from "../../../context/StatusContextProvider";
import { DGInput } from "../../../components/shared/DGInput";
import ActiveInActive from "../../../components/ActiveInActive";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import productionMgmtService from "../../../services/productionMgmtService";
import { AuthContext } from "../../../context/AuthContextProvider";
import AddIcon from '@material-ui/icons/Add';
import ProductionValueStreamAdd from "./production_valueStreamAdd";
import StatusFilter from "../../../components/shared/StatusFilter";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";




export default function ProductionValueStreamList() {

    const [formOpen, setFormOpen] = useState(false)
    const { globalPlant } = useContext(PlantContext);
    const { currentUser } = useContext(AuthContext);
    const [rows, setRows] = useState([])
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [plantShowFlag, setPlantShowFlag] = useState(false);



    function onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    function reloadGridData() {
        productionMgmtService.getVSTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        })
            .then((response) => {
                if (response.data) {
                    setRows(response.data)
                }
            })

    }

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            callback: changeStatus,
        }));
        reloadGridData();

    }, []);

    useEffect(() => {
        if (globalPlant?.globalPlant?.id === currentUser?.client_id) {
            setPlantShowFlag(true)
        }
        else { setPlantShowFlag(false) }
        reloadGridData()

    }, [globalPlant]);


    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return productionMgmtService.updateVSRecord(rowId, { enabled: newStatus });
    }


    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },
        ...(plantShowFlag ? [{
            headerName: "Plant",
            field: "plant_name",
            width: 200,
        }] : [])
        ,
        {
            headerName: "Value Stream",
            field: "valueStream_name",
            width: 200
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.valueStream_name,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.valueStream_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        }

    ];


    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setFormOpen(true);
                    }}
                >
                    Add Value Stream
                </Button>

            </>
        );
    }



    return (
        <>

            <Grid item xs={12}>
                <CommonGrid

                    rows={rows}
                    columns={columns}
                    onGridReady={onGridReady}
                    ButtonComponent={ButtonComponent}
                />
            </Grid>


            <DGDrawer
                isOpen={formOpen}
                setOpen={() => {
                    setFormOpen(false)
                    reloadGridData()
                }}
                title="Add a Value Stream"
                drawerWidth={800}
                Component={ProductionValueStreamAdd}
                needExport={false}
                needSave

            />

        </>
    )


};
