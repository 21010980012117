/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import machineService from "../../../services/machineService";
import CommonGrid from "../../../components/CommonGrid";
import { Link } from "react-router-dom";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import moment from "moment";
import { MachineForm } from "./MachineForm";
import { SpindleForm } from "./SpindleForm";
import StatusFilter from "../../../components/shared/StatusFilter";

const MachineListing = () => {
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [formOpen, setFormOpen] = useState(false);
  const [formOpen1, setFormOpen1] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const globalplant = useContext(PlantContext);
  const [plants, setPlants] = useState([]);
  const [plant, setPlant] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    if (globalplant?.globalPlant?.globalPlant) {
      setPlant(globalplant?.globalPlant?.globalPlant);
    }
  }, [globalplant]);





  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: loadAllMachines,
      callback: updateMachineStatus,
    }));
    setHeaderPageTitle(`Machine Master List`);
  }, []);

  function loadForm(data) {
    setSelectedRow(data.machine_id);
    setFormOpen1(true);
  }


  useEffect(() => {
    loadAllMachines();
  }, [plant]);

  function updateMachineStatus(comments, newId, newStatus, rowId) {
    return machineService.updateMachine(rowId, { enabled: newStatus });
  }

  function loadAllMachines() {
    return machineService.getMachineTable(currentUser?.client_id,
      plant.id)
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }


  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
    },
    {
      field: "machine_number",
      headerName: "Number",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <>
            <Link
              style={{ color: "#2196f3" }}
              variant="body2"
              onClick={() => loadForm(params?.data)}
            >
              {params.value}
            </Link>
          </>
        );
      },
    },
    {
      field: "machine_name",
      headerName: "Name",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "plant_name",
      headerName: "Plant Name",
      floatingFilter: true,
      filter: true,
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },

      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              callback: updateMachineStatus,
              reloadGrid: loadAllMachines,
              entityName: `${params?.data?.machine_number ?? ""
                }  ${params?.data?.machine_name ?? ""}`,
              currentStatus: params?.data?.enabled,
              rowId: params.data.machine_id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Paper>
        <CommonGrid
          ButtonComponent={() => (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setFormOpen(true);
              }}
            >
              MACHINE
            </Button>
          )}
          rows={rows}
          columns={columns}
          gridheight={100}
        />
      </Paper>
      <DGDrawer
        Component={MachineForm}
        isOpen={formOpen}
        drawerWidth={600}
        setOpen={setFormOpen}
        title={"New Machine"}
        needSave
        subtitle={moment(new Date()).format("DD/MM/YYYY")}
      />

      <DGDrawer
        Component={SpindleForm}
        isOpen={formOpen1}
        drawerWidth={600}
        //setOpen={setFormOpen1}
        title={"Spindle Details"}
        componentProps={selectedRow}
        setOpen={(state) => {
          setFormOpen1(false);
          loadAllMachines();
        }}

      />


    </>
  );
};

export default MachineListing;
