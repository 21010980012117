import http from "./httpService";

const createChangeManagementRecord = (data) => {
  return http.post("/change-management", data);
};

const changeAccessLog = (data) => {
  return http.post("/access-log", data)
  
};
const getAcessLogforentity = (entityId) => {
  return http.get("/access-log", {
    params: {
      entity_id:entityId
    }
  })
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createChangeManagementRecord,
    changeAccessLog,
    getAcessLogforentity
};
