import http from "./httpService";

const initiateStudy = (data) => {
  return http.post("/initiate-study", data);
};


const createMSAStudy = (data) => {

  return http.post("/initiate-study-msa", data)

}


const getMSACSR = (character_id, studies_id) => {
  return http.get("/csr-msa-char", {
    params: {
      character_id: character_id,
      studies_id: studies_id
    }
  })
};


const InitiateMSA = (data) => {
  return http.post("/msa-initiate", data)
}


const RecordMSA = (data) => {
  return http.post("/msa-initiate", data)
}



const ResultGRR = (grr_study_id) => {
  return http.get("/grr-study-result", {
    params: {
      grr_study_id: grr_study_id
    }
  })
}



const MSAReport = (study_id, studies_id) => {
  return http.get("/msa-report", {
    params: {
      study_id: study_id,
      studies_id: studies_id
    }
  })
}


const getMSAtestList = (data) => {
  return http.get("/msa-test-list", { params: { ...data } })
}


const updateMSA = async (data) => {
  return http.post(`/msa-disable`, data);
  //Data :  Studies_id , msa_eval_id,new_status
}

const getProductMSATable = async (data) => {
  return http.get('/msa-table-product',
    {
      params: {
        ...data,
      }
    })

}


export default {
  initiateStudy,
  getMSACSR,
  createMSAStudy,
  InitiateMSA,
  RecordMSA,
  ResultGRR,
  MSAReport,
  getMSAtestList,
  updateMSA,
  getProductMSATable

}