import React, { useContext, useEffect, useState, Component } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import CCMDay from "../../ccm/metricsCardCCM"
import SimpleSlider from "../../../components/SlickSlider";
import { Container, Grid } from "@material-ui/core";
import AssetmgmtCard from "../../assetmanagement/assetmgmtCard"


export function MainDashboard() {
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [dataDepth, setdataDepth] = useState('')
    const [dataLabel, setdataLabel] = useState('')
    //const [Offset_index, setOffset_index] = useState(0)
    const [plant_id, setPlantId] = useState(currentUser.client_id)
    const { setHeaderPageTitle } = useContext(PageTitleContext);

    const Offset_index = 0


    useEffect(() => {
        if (currentUser.default_plant !== null) {
            setPlantId(currentUser.default_plant);
        } else if (globalPlant && globalPlant["globalPlant"] && globalPlant["globalPlant"]["id"]) {
            setPlantId(globalPlant["globalPlant"]["id"]);
        }
    }, []);

    useEffect(() => {
        if (globalPlant && globalPlant["globalPlant"] && globalPlant["globalPlant"]["id"]) {
            setPlantId(globalPlant["globalPlant"]["id"]);
        } else {
            // Handle the case where the properties don't exist or are undefined
        }
    }, [globalPlant]);


    useEffect(() => {
        const uname = currentUser.name;
        setHeaderPageTitle(`Welcome ${uname}`);
    }, [currentUser]);


    useEffect(() => {
        if (currentUser.user_role > 3) { setdataDepth(1); setdataLabel("Day") }
        else if (currentUser.user_role >= 2 && currentUser.user_role_id <= 3) { setdataDepth(2); setdataLabel("Week") }
        else if (currentUser.user_role >= 1 && currentUser.user_role_id <= 2) { setdataDepth(3); setdataLabel("Month") }
    }, [currentUser.user_role_id]);

    return (

        <React.Fragment>
            <Container>
                <div className="dg_content">
                    <Grid container spacing={2} className="dg_dash_entry1">
                        <Grid item xs={12} md={3} className="dg_dash_grid">
                            <CCMDay cardName={"CCM Status"}
                                cardNumber={1}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                                Component={"DashboardDetailsPI"}
                            />

                        </Grid>

                        <Grid item xs={12} md={3} className="dg_dash_grid">
                            <CCMDay cardName={"CCM Status"}
                                cardNumber={0}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0} />

                        </Grid>

                        <Grid item xs={12} md={3} className="dg_dash_grid">
                            <CCMDay cardName={"CCM Status"} cardNumber={3} datalabel={dataLabel} client_id={currentUser.client_id} plant_id={plant_id} dataDepth={dataDepth} Offset_index={Offset_index} Offset_type={0} />

                        </Grid>

                        <Grid item xs={12} md={3} className="dg_dash_grid">
                            <CCMDay cardName={"CCM Status"} cardNumber={4} datalabel={dataLabel} client_id={currentUser.client_id} plant_id={plant_id} dataDepth={dataDepth} Offset_index={Offset_index} Offset_type={0} />
                        </Grid>
                    </Grid>

                    {/* second dash counts */}
                    <Grid container spacing={2} className="dg_dash_entry2">

                        <Grid item xs={12} md={4} className="dg_dash_grid">
                            <AssetmgmtCard
                                cardName={"Asset Management"}
                                Component={"GaugeOverDueList"}
                                drawerWidth={800}
                                cardNumber={0}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                            />
                        </Grid>

                        <Grid item xs={12} md={4} className="dg_dash_grid">
                            <AssetmgmtCard
                                cardName={"Asset Management"}
                                Component={"GaugeCalibrationList"}
                                drawerWidth={800}
                                cardNumber={1}
                                datalabel={dataLabel}
                                client_id={currentUser.client_id}
                                plant_id={plant_id}
                                dataDepth={dataDepth}
                                Offset_index={Offset_index}
                                Offset_type={0}
                            />
                        </Grid>

                    </Grid>
                </div>
            </Container>
        </React.Fragment >
    );
}


