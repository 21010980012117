import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import productService from "../../../../services/productService";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { DGDrawer } from "../../../../components/DGDrawer";
import { UploadDocumentForm } from "./UploadDocumentForm";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../../components/ActiveInActive";
import { StatusContext } from "../../../../context/StatusContextProvider";
import StatusFilter from "../../../../components/shared/StatusFilter";

export function ProductDocument({ partId = "" }) {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);


  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData() {
    productService.getPartDocumentTable(partId).then((res) => {
      setRows(res?.data ?? []);
    });
  }
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 75,
    },
    {
      field: "document_type",
      headerName: "Document Type",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "document_name",
      headerName: "Document Name",
      flex: 1,
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
      cellRenderer: (params) => {
        const { value, data } = params;
        return (
          <a
            href={data.document_link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "blue" }}
          >
            {value}
          </a>
        );
      },
    },
    {
      field: "document_date",
      headerName: "Document Date",
      flex: 1,
      valueGetter: (params) =>
        params?.data?.document_date
          ? new Date(params?.data?.document_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
    },

    {

      field: "document_file_type",
      headerName: "File Type",
      flex: 1,
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 100,
    },
    {
      field: "document_description",
      headerName: "Document Description",
      flex: 1,
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
    },

    {
      field: "document_enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => {
        return (
          <ActiveInActive
            status={params?.data?.document_enabled}
            onClick={() =>
              setStatus((prev) => ({
                ...prev,
                open: true,
                callback: updateDocumentStatus_,
                reloadGrid: reloadGridData,
                entityName: params?.data?.document_name ?? "",
                currentStatus: params?.data?.document_enabled,
                rowId: params?.data?.document_id,
              }))
            }
          />
        );
      },
    },

    {
      field: "enabled",
      headerName: "Status History",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.document_id,
                entity: params?.data?.document_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },



  ];

  function updateDocumentStatus_(dummy1, dummy2, newStatus, rowId) {
    return productService.UpdateDocumentStatus(rowId, { enabled: newStatus });
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={210}
        ButtonComponent={() => (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setFormOpen(!formOpen);
            }}
          >
            DOCUMENT
          </Button>
        )}
      />
      <DGDrawer
        Component={UploadDocumentForm}
        title="Upload Document"
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        drawerWidth={600}
        componentProps={{ partId: partId }}
        needSave
      />
    </>
  );
}
