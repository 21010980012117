import React from "react";
import TextField from "@material-ui/core/TextField";
import { ACTION_TYPE } from "./commonenum";

export function DGInput({
  isFormik = false,
  onChange = () => { },
  variant = "filled",
  value = "",
  label = "",
  disabled = false,
  required = false,
  error = false,
  multiline = false,
  rows = 1,
  type = "text",
  id = "",
  size = "medium",
  ...props
}) {
  // const classes = useStyles();

  return (
    <>
      {!isFormik ? (
        <TextField
          size={size}
          {...props}
          label={label}
          variant={variant}
          InputProps={
            ACTION_TYPE?.view
              ? {
                style: { background: "#fff", color: "#000" },
                disableUnderline: true,
              }
              : { style: {} }
          }
          inputProps={
            ACTION_TYPE?.view
              ? { style: { border: "0px" } }
              : { style: { backgroundColor: "#F7F9FC" } }
          }
          InputLabelProps={
            ACTION_TYPE?.view ? { style: { color: "#000" } } : {}
          }
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          disabled={disabled || ACTION_TYPE.view}
          required={required}
          multiline={multiline}
          rows={rows}
          error={error}
          type={type}
          // classes={{ input: classes?.input }}
          id={id}
          name={id}
        />
      ) : (
        <TextField
          {...props}
          size={size}
          label={label}
          variant={variant}
          InputProps={
            ACTION_TYPE?.view
              ? {
                // style: { background: "#fff", color: "#000" },
                disableUnderline: true,
              }
              : { style: {} }
          }
          inputProps={
            ACTION_TYPE?.view
              ? { style: { border: "0px" } }
              : { style: { backgroundColor: "#F7F9FC" } }
          }
          InputLabelProps={
            ACTION_TYPE?.view ?
              { style: { color: "#000" } } : {}
          }
          value={value}
          onChange={(e) => onChange(e)}
          fullWidth
          disabled={disabled || ACTION_TYPE.view}
          required={required}
          multiline={multiline}
          rows={rows}
          error={error}
          type={type}
          // classes={{ input: classes?.input }}
          id={id}
          name={id}
        />
      )}
    </>
  );
}
// const useStyles = makeStyles({input:{
//   backgroundColor:"#F7F9FC"
// }})
