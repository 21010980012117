import { Grid, TextareaAutosize } from "@material-ui/core";
import { useFormik } from "formik";
import { DropzoneArea } from "material-ui-dropzone";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { date, object, string } from "yup";

import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";

import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";

import uploadService from "../../../../services/uploadService";
import productService from "../../../../services/productService";
import errorLog from "../../../../services/errorLog";
import { useParams } from "react-router-dom";

export function UploadDocumentForm({
  Footer = () => { },
  componentProps = null,
  closeForm = () => { },

}) {
  const [uploadDocument, setUploadedCertificate] = useState();
  const { v4: uuidv4 } = require("uuid");
  const { updateAlert } = useContext(AlertContext);
  let { currentUser } = useContext(AuthContext);
  let { id } = useParams();


  useEffect(() => {
    loaddocumenttype();
  }, [componentProps?.partId]);

  function loaddocumenttype() {
    productService.getDocumentType(componentProps?.partId).then((res) => {
      form.setFieldValue("doctypeoptions", res?.data ?? []);
    });
  }

  let form = useFormik({
    initialValues: {
      uploadDocument: null,
      documentType: null,
      documentDate: new Date(),
      documentName: "",
      documentLink: null,
      documentDescription: "",
      doctypeoptions: [],
      documentFileType: ""
    },

    validationSchema: object().shape({
      documentType: object().required(),
      documentDate: date().required(),
      documentName: string().required(),
    }),
    onSubmit: handleSubmit,
  });

  function handleSubmit() {
    let payload = {
      created_by: currentUser?.id,
      part_id: id,
      document_type_id: form.values.documentType.document_type_id,
      document_name: form.values.documentName,
      document_description: form.values.documentDescription,
      document_date: moment(form.values.documentDate).format("YYYY/MM/DD"),
      document_link: "",
      document_file_type: ""

    };


    console.log("payload", currentUser);

    uploadService.uploadFile({
      files: uploadDocument,
      client_id: currentUser?.client_id
    })
      //uploadService.uploadFile(uploadDocument)
      .then((uploadres) => {
        payload["document_link"] = uploadres?.data?.files[0]?.location ?? "";
        payload["document_file_type"] = (uploadres?.data?.files[0]?.mimetype ?? "").split('/')[1].toUpperCase();
        productService.addDocument(payload)
          .then((res) => { closeForm(); })
          .then((res) => {
            updateAlert({
              open: true,
              message: "Document saved Successfully",
              type: "success",
            });
          })
          .catch((err) => {
            updateAlert({
              open: true,
              message: "Failed to save Document",
              type: "error",
            });
            let payload = {
              id: uuidv4(),
              error_description: err.message,
              error_location: "addDocument",
              client_id: currentUser.client_id,
              user_id: currentUser.id,
              entity_id: componentProps?.id,
            };
            errorLog.createErrorLog(payload)
          });

      });
  }


  return (
    <Grid container spacing={4}>


      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="Document Type"
          value={form.values.documentType}
          options={form.values.doctypeoptions}
          getOptionLabel={(opt) => opt?.document_type}
          onChange={(val) => { form?.setFieldValue("documentType", val) }}
          required
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGDateInput
          required
          id="documentDate"
          variant="outlined"
          label="Document Date"
          value={form.values.documentDate}
          onChange={(date) => form.setFieldValue("documentDate", date)}
          error={form.touched.documentDate && form.errors.documentDate}
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          id="documentName"
          label="Document Details"
          variant="outlined"
          value={form.values.documentName}
          onChange={form.handleChange}
          isFormik
          required
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <TextareaAutosize
          aria-label="documentDescription"
          placeholder="Document detail Description"
          minRows={3}
          className="border border-2"
          style={{
            width: "100%",
            // borderColor: "#bbb",
            borderRadius: "5px",
          }}
          onChange={(e) => {
            form.setFieldValue("documentDescription", e.target.value);
          }}
        />
      </Grid>


      <Grid item md={12} sm={12} lg={12}>
        <DropzoneArea
          showFileNamesInPreview={true}
          showFileNames={true}
          showAlerts={true}
          onChange={(file) => setUploadedCertificate(file)}
          acceptedFiles={["image/*", "application/*"]}
          filesLimit={1}
          maxSize={10971520}
        />
      </Grid>


      <Footer
        onSave={form.handleSubmit}
      />
    </Grid>
  );
}
