import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  chartSkeleton: {
    marginBottom: theme.spacing(2),
    backgroundColor: "#f5f5f5",
    borderRadius: theme.spacing(1),
    overflow: "hidden",
  },
  skeletonTitle: {
    height: theme.spacing(4),
    backgroundColor: "#e0e0e0",
    marginBottom: theme.spacing(2),
  },
}));

export const ChartSkeleton = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.chartSkeleton}>
        <div className={classes.skeletonTitle} />
        <Skeleton animation variant="rect" width="100%" height={500} />
      </div>
    </div>
  );
};
