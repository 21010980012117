import { Grid, Radio, RadioGroup, Button, Typography } from "@material-ui/core";
import React from "react";
import { useContext, useState, useEffect } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { DGDrawer } from "../../../components/DGDrawer";
import dashboardService from "../../../services/dashboardService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { StatusContext } from "../../../context/StatusContextProvider";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { StackedBarGraph } from "../../../components/graphs/StackedBarGraphs";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core";
import { Text } from "@chakra-ui/react"
import { PITrendDetail } from "./Trends/PITrendDetail";


export function DashboardDetailsPI(
    componentProps = ""

) {
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow2, setSelectedRow2] = useState();
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [subtitle, setSubtitle] = useState("");
    const [titleDetails, setTitleDetails] = useState("");
    const [evaluatedChecked, setEvaluatedChecked] = useState(false);
    const [rollingChecked, setRollingChecked] = useState(false);
    const [dateDepth, setDateDepth] = useState(componentProps.componentProps)
    const [graphOptions, setGraphOptions] = useState([])
    const [graphDataLoad, setGraphDataLoad] = useState([])
    const [formOpen, setFormOpen] = useState(false);
    const [componentProps1, setComponentProps] = useState(null)


    //  console.log(componentProps1)

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadgridData,

        }));

        reloadgridData()
        reloadgridData2()

    }, [globalPlant, dateDepth, rollingChecked]);

    useEffect(() => {
        if (selectedRow) {
            graph_generate();
            graph_options()
        }
    }, [selectedRow])

    useEffect(() => {
        if (graphDataLoad) {
            graph_options()
        }
    }, [graphDataLoad])



    function onRowSelection(data) {
        setSelectedRow(data[0].part_id);
        setSubtitle(`${data[0].part_number} ${data[0].part_name}`);

    }

    function reloadgridData() {
        dashboardService
            .dashPIDetails({
                client_id: currentUser?.client_id,
                plant_id: globalPlant?.globalPlant?.id,
                dateDepth: dateDepth,
                dataDepth: 1,
                rollingDates: rollingChecked
            })
            .then((res) => {
                setRows(res?.data ?? []);
            });
    }

    function reloadgridData2() {
        dashboardService
            .dashPIChar({
                client_id: currentUser?.client_id,
                plant_id: globalPlant?.globalPlant?.id,
                dateDepth: dateDepth,
                dataDepth: 1,
                rollingDates: rollingChecked
            })
            .then((res) => {
                //   console.log(res)
                setRows2(res?.data ?? []);
            })
            .catch((error) => {
                //   console.log(error)
            })
    }

    const columns = [

        {
            headerName: "Part Number",
            minWidth: "200",
            autoHeight: true,
            sortable: false,
            cellRenderer: (params) => {
                return (
                    `${params?.data?.part_number} ${params?.data?.part_name}`
                );
            },
        },
        {
            field: "total_eval",
            headerName: "Total",
            minWidth: "100",
            autoHeight: true,

        },

        {
            field: "ok",
            headerName: "OK",
            minWidth: "100",
            autoHeight: true,

        },
        {
            field: "nok",
            headerName: "Not OK",
            sortable: true,
            resizable: true,
            minWidth: "100",
            autoHeight: true,

        },
        {
            field: "pending",
            headerName: "Pending",
            minWidth: "100",
            autoHeight: true,

        },




    ];

    const columns2 = [

        {
            headerName: "Part Number",
            minWidth: "200",
            autoHeight: true,
            sortable: false,
            cellRenderer: (params) => {
                return (
                    <>
                        <Typography variant="body2" style={{ marginBottom: "0px" }}>{params?.data?.part_number}</Typography>
                        <Typography variant="body2" style={{ marginBottom: "-8px" }}>{params?.data?.part_name}</Typography>

                        <Typography
                            component="a"
                            href="#"
                            variant="body2"
                            style={{ color: 'blue', fontWeight: 'bold' }}
                            onClick={() => {
                                setComponentProps({
                                    ...params?.data,
                                    count_character_graph: 1,
                                    only_graph: 1
                                });
                                setFormOpen(true);
                            }}
                        >
                            {`${params?.data?.character_name}`}
                        </Typography>

                        {params?.data?.specification !== null &&
                            params?.data?.specification !== undefined && (
                                <Typography variant="body2"
                                    style={
                                        { marginBottom: "3px", marginTop: "-10px" }
                                    }
                                >
                                    {`${params?.data?.specification}  
            ${params?.data?.upper_specification ?? ""}
            ${params?.data?.lower_specification ?? ""}`}
                                </Typography>
                            )}
                    </>
                );
            },

        },
        {
            field: "total_eval",
            headerName: "Total",
            minWidth: "100",
            autoHeight: true,

        },

        {
            field: "ok",
            headerName: "OK",
            minWidth: "100",
            autoHeight: true,

        },
        {
            field: "nok",
            headerName: "Not OK",
            sortable: true,
            resizable: true,
            minWidth: "100",
            autoHeight: true,

        },
        {
            field: "pending",
            headerName: "Pending",
            minWidth: "100",
            autoHeight: true,

        },

    ];

    function graph_generate() {
        dashboardService
            .dashPIGraph({
                part_id: selectedRow,
                dateDepth: dateDepth,
                dataDepth: 2
            })
            .then((res) => {
                setGraphDataLoad([...res.data])
            })
            .catch((error) => {
                console.error("Error fetching customers:", error);
            });
    }

    function graph_options() {
        let option = {
            theme: myTheme,
            title: {
                text: subtitle,
            },
        };
        let graphData = [];
        let series = new Map();

        graphDataLoad.map((exp) => {
            for (let key in exp?.count_data) {
                series.set(key, {
                    type: "column",
                    xKey: "date_range",
                    yKey: key,
                    yName: key,
                    stacked: true,
                    tooltip: {
                        renderer: (params) => {
                            return {
                                content: params?.yValue,
                                title: params?.xValue
                            };
                        }
                    },
                });
            }
            graphData.push({ date_range: exp.date_range, ...exp.count_data });
        });

        option["series"] = [...series.values()];
        option["data"] = graphData;
        setGraphOptions(option);
    };

    function datadepthswitch() {
        if (!evaluatedChecked) {
            setEvaluatedChecked(true)
        }
        else {
            setEvaluatedChecked(false)

        }

    }

    function rollingSwitch() {
        if (!rollingChecked) {
            setRollingChecked(true)
        }
        else {
            setRollingChecked(false)

        }

    }


    return (
        <>

            <Grid container spacing={2}>
                <Grid item md={4} sm={4} lg={4}>
                    <RadioGroup
                        row
                        value={dateDepth.toString()}
                        onChange={(event) => {
                            const newValue = parseInt(event.target.value);
                            setDateDepth(newValue);
                            setGraphOptions(null)
                            setSelectedRow(null)
                        }}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Day" />
                        <FormControlLabel value="2" control={<Radio />} label="Week" />
                        <FormControlLabel value="3" control={<Radio />} label="Month" />
                    </RadioGroup>

                </Grid>

                <Grid container justifyContent="center" item md={4} sm={4} lg={4}>
                    <FormControlLabel
                        control=
                        {<Switch
                            checked={evaluatedChecked}
                            onChange={(e) => datadepthswitch(e.target.checked)}
                            color="primary"
                            name="Characteristics Trend"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />}
                        label="Characteristic"
                    ></FormControlLabel>


                </Grid>
                <Grid container justifyContent="center" item md={4} sm={4} lg={4}>
                    <FormControlLabel
                        control=
                        {<Switch
                            checked={rollingChecked}
                            onChange={(e) => rollingSwitch(e.target.checked)}
                            color="primary"
                            name="Rolling Date"
                            inputProps={{ "aria-label": "primary checkbox" }}
                        />}
                        label="Rolling"
                    ></FormControlLabel>


                </Grid>

                {evaluatedChecked && (

                    <Grid item md={12} sm={12} lg={12}>
                        <CommonGrid
                            rows={rows2}
                            columns={columns2}
                            gridheight={100}

                        />
                    </Grid>
                )}

                {
                    !evaluatedChecked && (
                        <>
                            <Grid item md={12} sm={12} lg={12}>
                                <CommonGrid
                                    rows={rows}
                                    columns={columns}
                                    gridheight={400}
                                    onRowSelect={onRowSelection}
                                />
                            </Grid>

                            {selectedRow && (
                                <Grid item md={12} sm={12} lg={12}>
                                    <StackedBarGraph options={graphOptions} />
                                </Grid>
                            )}
                        </>
                    )
                }


            </Grid>

            <DGDrawer
                Component={PITrendDetail}
                setOpen={() => {
                    setFormOpen(false);
                    setSelectedRow2(null);
                }}
                isOpen={formOpen}
                isClose={() => {
                    setFormOpen(false);
                }}


                drawerWidth={1150}
                componentProps={componentProps1}
            />
        </>


    )

}






var myTheme = {
    baseTheme: 'ag-solar',
    palette: {
        fills: ['#558b2f', '#836fa9', '#f44336', '#FDDE02', '#F76700', '#4e342e'],
        strokes: [],
    },
    overrides: {
        cartesian: {
            title: {
                fontSize: 24,
            }
        },
    },
};
