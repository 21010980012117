import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  TextField as MuiTextField,
  Typography as MuiTypography,
  CardActionArea,
} from "@material-ui/core";
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useParams } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import "./GaugeDetails.css";
import gaugeService from "../../../services/gaugeService";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { GaugeFamilyForm } from "./GaugeFamilyForm";
import { DGDrawer } from "../../../components/DGDrawer";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import CommonGrid from "../../../components/CommonGrid";
import { GaugeItemList } from "./GaugeDetailList";
//import AccessTimeIcon from '@mui/icons-material/AccessTime';
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#1890ff",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
}

const defaultTheme = createMuiTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#1d1d1d",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color:
          theme.palette.type === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.65)",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
      label: {
        marginTop: theme.spacing(1),
      },
      flexDirection: "column",
      "& .ant-empty-img-1": {
        fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
      },
      "& .ant-empty-img-2": {
        fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
      },
      "& .ant-empty-img-3": {
        fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
      },
      "& .ant-empty-img-4": {
        fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
      },
      "& .ant-empty-img-5": {
        fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
        fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
      },
    },
  }),
  { defaultTheme }
);

const GaugeDetails = () => {
  let { id } = useParams();
  const classes = useStyles();
  const [gauge, setGauge] = useState();
  const [addGaugeDialog, setAddGaugeDialog] = useState(false);
  let history = useHistory();
  useEffect(() => {
    if (addGaugeDialog) {
      ACTION_TYPE.edit = true;
    } else {
      getGaugeDetails();
    }
    return () => {
      ACTION_TYPE.edit = false;
    };
  }, [addGaugeDialog]);

  useEffect(() => {
    if (id) {
      getGaugeDetails();
    }
  }, []);

  const getGaugeDetails = async () => {
    const response = await gaugeService.getGaugeDetails(id);
    // console.log("Gauge Details", response.data);
    if (response && response.data) {
      setGauge(response.data);
    }
  };

  const handleEditGauge = async () => {
    setAddGaugeDialog(true);
  };

  return (
    <React.Fragment>
      <Helmet title="Gauge Information" />
      <DGDrawer
        componentProps={gauge}
        Component={GaugeFamilyForm}
        isOpen={addGaugeDialog}
        drawerWidth={500}
        setOpen={setAddGaugeDialog}
        title={gauge?.name}
        subtitle={gauge?.number}
        needSave={true}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={5}>
          <Card mb={6} style={{ width: "100%" }}>
            <CardContent>
              {gauge ? (
                <>
                  {/*  <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      mr={2}
                      startIcon={<VisibilityIcon />}
                    >
                      View Characteristics
                    </Button> */}
                  {/* <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      mr={2}
                      startIcon={<BlockIcon />}
                      onClick={() => handleDeactivateGauge()}  
                    >
                      Deactivate
                    </Button> */}
                </>
              ) : null}

              {gauge ? (
                <>
                  <Grid item md={12}>
                    <TableContainer style={{ width: "100%" }}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                        size="small"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Name
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                <b>{gauge?.name}</b>
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Number
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.number}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Make
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.make}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Gauge Type
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.guage_type?.type_name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Least Count
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.least_count}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Range
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.range}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Lead Time
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.lead_time
                                  ? gauge?.lead_time + " days"
                                  : ""}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Inspection Guidelines
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.inspection_guidelines}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Comments
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {gauge?.comments}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              ) : null}
            </CardContent>
            <CardActionArea
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<VisibilityIcon />}
                onClick={() => history.push(`/gauge-changes/${id}`)}
              >
                View Changes
              </Button>
              {gauge ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={() => handleEditGauge()}
                >
                  Edit Gauge
                </Button>
              ) : null}
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12}>
          {/* <GaugeItemsContainer gaugeId={id} /> */}
          <GaugeItemList gaugeId={id} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GaugeDetails;
