import http from "./httpService";


const ProcessPartSelectCharacter = (part_id) => {
    return http.get("/machine-parameter-select1", {
        params: {
            part_id: part_id,
        },
    });
};


const ProcessPartSelectProcess = async (data) => {
    return http.get(`/machine-parameter-select2`, {
        params: {
            ...data,
        },
    });
};


const ProcessParameterAddSpec = (data) => {
    return http.post("/machine-parameter-part-add", data);
};

const ProcessParameterPartTable = (part_id) => {
    return http.get("/machine-parameter-table", {
        params: {
            part_id: part_id,
        },
    });
};

const UpdateProcessPartParameter = async (id, newStatus) => {
    return http.patch(`/machine-parameter-part/${id}`, newStatus);
};






export default {

    ProcessPartSelectCharacter,
    ProcessPartSelectProcess,
    ProcessParameterAddSpec,
    ProcessParameterPartTable,
    UpdateProcessPartParameter

}