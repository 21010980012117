import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../../../../components/CommonGrid";
import { DGInput } from "../../../../../../components/shared/DGInput";
import { DGSelect } from "../../../../../../components/shared/DGSelect";
import initiateStudyService from "../../../../../../services/initiateStudyService";

export function Grr2Studies({
  gaugeCharacter = {},
  studyId = -1,
  usersList = [],
  trails,
  samples,
  appraiserNames,
  selectedUser,
  msaCsr,
  rows,
  columns,
  mastervalue,
  setTrails = () => { },
  setSamples = () => { },
  setMasterValue = () => { },
  setSelectedUser = () => { },
  setMsaCsr = () => { },
  onGridReady = () => { },
  setRows = () => { },
  setColumns = () => { },
  isGridGenerated = false,
  setGridGenerated = () => { },
  characterId = "",
  closeForm = () => { },
  handleNext = () => { },
}) {
  const [expanded, setExpanded] = useState(-1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (!isGridGenerated) {
      generateGrid(1, samples);
    }
  }, []);

  useEffect(() => {
    if (expanded === 1) {
      generateGrid(1, Number(samples));
    }
  }, [expanded]);

  useEffect(() => {
    if (!msaCsr) {
      getMsaCsr(characterId, studyId);
    }
  }, []);

  function getMsaCsr(characterId, studiesId) {
    initiateStudyService.getMSACSR(characterId, studiesId).then((res) => {
      let data = res?.data[0];
      if (data) {
        setMsaCsr(data);
      }
    });
  }

  const generateGrid = async (trialsCount, samplesCount) => {
    let columns = [];
    for (let i = 1; i <= trialsCount; i++) {
      columns.push("trial" + i);
    }
    // console.log("Row count array", columns); //Row count array is an array which stores all the trial numbers
    const array = [];
    for (let i = 1; i <= samplesCount; i++) {
      let x = {};
      for (let j = 1; j <= trialsCount; j++) {
        x["trial" + j] = "";
      }
      array.push(x);
    }
    if (!isGridGenerated) {
      setRows(array);
    }
    // console.log("Array one:", array); //rowData in ag-grid

    const data = columns.map((value, index) => ({
      field: value,
      headerName: "Trial " + ((index % trialsCount) + 1),
      editable: true,
      valueSetter: (params) => {
        if (
          Number(params.newValue) > Number(msaCsr?.llimit_) &&
          Number(params.newValue) < Number(msaCsr?.ulimit_)
        ) {
          params.data[value] = Number(params.newValue);
          return true;
        }
        return false;
      },
    }));
    data.unshift({
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
    });
    // console.log("data: ", data);
    setColumns(data);
    setGridGenerated(true);
  };

  function processCellFromClipboard(params) {
    if (
      Number(params.value) > Number(msaCsr?.llimit_) &&
      Number(params.value) < Number(msaCsr?.ulimit_)
    ) {
      return Number(params.value);
    }
    return "";
  }

  return (
    <>


      <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Basic Details</Typography>

      <Grid container spacing={4}>
        {/* <Grid item md={6} sm={6}>
                <DGInput
                  variant="outlined"
                  type="number"
                  label="Number of Trails"
                  value={trails}
                  error={Number(trails) < msaCsr?.grr1_min_trials}
                  onChange={setTrails}
                />
              </Grid> */}
        <Grid item md={3} sm={3}>
          <DGInput
            type="number"
            label="Number of Measurements"
            value={samples}
            error={Number(samples) < msaCsr?.grr1_min_sample}
            onChange={setSamples}
            required
          />
        </Grid>
        <Grid item md={3} sm={3}>
          <DGInput
            type="number"
            label="Master Value"
            value={mastervalue}
            onChange={setMasterValue}
            required
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGSelect
            label="Approver"
            options={usersList}
            value={selectedUser}
            error={!selectedUser?.id}
            onChange={setSelectedUser}
            required
          />
        </Grid>
      </Grid>


      <Accordion
        style={{ width: "100%" }}
        square
        expanded={expanded === 1}
        onChange={handleChange(1)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Values</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} lg={12}>
              <CommonGrid
                rows={rows}
                columns={columns}
                onGridReady={onGridReady}
                processCellFromClipboard={processCellFromClipboard}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>


      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleNext()}
          >
            NEXT
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
