import {Grid} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet-async";
import ApprovalPage from "./ApprovalPage";

const Approvals = () => {
  return (
    <>
      <Helmet title="Approvals" />
      <Grid container>
        <Grid item xs={12}>
          <ApprovalPage/>
        </Grid>
      </Grid>
    </>
  );
};

export default Approvals;
