/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import machineService from "../../../../services/machineService";
import CommonGrid from "../../../../components/CommonGrid";
import { Link } from "react-router-dom";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../../components/ActiveInActive";
import { AuthContext } from "../../../../context/AuthContextProvider"
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { ProcessParameterNewForm } from "./ProcessParameterNewForm"
import { PageTitleContext } from "../../../../context/PageTitleContextProvider";




const ProcessPartTable = (machine_id = "") => {
    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const { setHeaderPageTitle } = useContext(PageTitleContext);




    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: loadList,
            callback: updateStatus
        }));
    }, []);

    useEffect(() => {
        loadList()
    }, []);



    function updateStatus(comments, newId, newStatus, rowId) {
        return machineService.updateMachineParameter(rowId, { enabled: newStatus });
    }

    function loadList() {
        return machineService.getProcessPartMatrix(machine_id?.machine_id)
            .then((res) => {
                setRows(res?.data ?? []);
            });
    }

    function ButtonComponent() {
        return (

            <Button
                variant="outlined"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                    setFormOpen(true);
                }}
                style={{ marginLeft: 3 }}
            >
                ADD Process
            </Button>

        );
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 150
        },

        {
            headerName: "Part Name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 200,
            valueGetter: (p) => {
                return p?.data?.part_number + " - " + p?.data?.part_name;
            },
        },
        {
            headerName: "Parameter",
            flex: 1,
            resizable: true,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            minWidth: 200,
            valueGetter: (p) => {
                return p?.data?.parameter_name + " (" + p?.data?.parameter_unit + ")";
            },
        },
        {
            headerName: "Specification",
            flex: 1,
            editable: false,
            resizable: true,
            minWidth: 200,
            valueGetter: (params) =>
                `${params?.data?.target ?? ""} 
                 ${params?.data?.upper_limit ?? ""}
                 ${params?.data?.lower_limit ?? ""}`,
        },

    ];

    return (
        <>
            <Paper>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={115}
                // ButtonComponent={ButtonComponent}
                />
            </Paper>
            {/*             <DGDrawer
                Component={ProcessParameterNewForm}
                isOpen={formOpen}
                drawerWidth={600}
                setOpen={setFormOpen}
                title={"Process Parameter"}
                componentProps={{
                    user_id: currentUser.id,
                    machine_id: machine_id?.machine_id
                }}
                needSave
            /> */}
        </>
    );
};

export default ProcessPartTable;
