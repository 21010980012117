import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Button as MuiButton } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import productService from "../../../services/productService";
import CommonGrid from "../../../components/CommonGrid";
import AddIcon from "@material-ui/icons/Add";
import { AuthContext } from "../../../context/AuthContextProvider";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import { DGDrawer } from "../../../components/DGDrawer";
import ComponentForm from "./ComponentForm";

function ComponentsList({
  productComponents,
  setProductComponents = () => { },
  partId,
}) {
  const Button = styled(MuiButton)(spacing);
  const currentUser = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      callback: updateComponentStatus,
      reloadGrid: reloadGridData,
    }));
  }, []);
  let columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      resizable: true,
    },
    {
      field: "partNameNumber",
      headerName: "Part Name Number",
      editable: false,
      minWidth: 400,
      floatingFilter: true,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <>
            <Link
              variant="body2"
              to={`/product-details/${params?.data?.child_part_id}`}
              style={{
                color: "#376FD0",
                marginLeft:
                  params?.data?.level > 0 ? params?.data?.level * 20 : 0,
              }}
            >
              {params?.data?.part_number}-{params?.data?.part_name}
            </Link>
          </>
        );
      },
    },
    {
      field: "component_enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      filter: "StatusFilter",
      cellRenderer: (params) => {
        return (
          <ActiveInActive
            disabled={params?.data?.level !== 0}
            status={params?.data?.component_enabled}
            onClick={() =>
              setStatus((prev) => ({
                ...prev,
                open: true,
                callback: updateComponentStatus,
                reloadGrid: reloadGridData,
                entityName: params?.data?.part_name ?? "",
                currentStatus: params?.data?.component_enabled,
                rowId: params?.data?.components_id,
              }))
            }
          />
        );
      },
    },
    {
      field: "enabled",
      headerName: "Status History",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.components_id,
                entity: params?.data?.part_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];


  function reloadGridData() {
    return productService
      .getPartHierarchy(partId)
      .then((res) => {
        //debugger
        setRows(res?.data ?? []);
      });
  }

  function updateComponentStatus(dummy1, dummy2, newStatus, rowId) {
    return productService.updateComponents(rowId, { enabled: newStatus });
  }
  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(),
      callback: updateComponentStatus,
    }));
    reloadGridData();
  }, [partId]);
  return (
    <>
      <CommonGrid
        columns={columns}
        rows={rows}
        needExport={false}
        // tableName="Component"
        gridheight={153}
        ButtonComponent={() => (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setFormOpen(!formOpen);
            }}
          >
            Add Component
          </Button>
        )}
      />
      <DGDrawer
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        Component={ComponentForm}
        needSave
        title="Add Component"
        componentProps={{ id: partId }}
      />
    </>
  );
}

export default ComponentsList;
