import React from "react";
import { useContext } from "react";
import { useState, useEffect } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import gaugeService from "../../../services/gaugeService";
import { GaugeIssueForm } from "./gaugeissueForm";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { Button, Grid } from "@material-ui/core";
import plantService from "../../../services/plantService";
import userService from "../../../services/userService";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import { GaugeReturnForm } from "./gaugeReturnForm";
import { PlantContext } from "../../../context/PlantContextProvider"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export function GaugeIssues({
  gridheight = 100,
  componentProps = "" }) {
  const [rows, setRows] = useState([]);
  const [issueOpen, setIssueOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [plantList, setPlantList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const globalPlant = useContext(PlantContext)
  const [evaluatedChecked, setEvaluatedChecked] = useState(false);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const [gembaFlag, setGembaFlag] = useState(componentProps?.gemba_flag ?? 0)


  //const gemba_flag = componentProps?.gemba_flag ?? 0;
  //console.log(globalPlant.globalPlant.globalPlant.id)


  useEffect(() => {
    reloadGridData();
    plantService.getPlantlist(currentUser?.client_id, 1).then((res) => {
      setPlantList(res?.data ?? []);
    });

    userService.getActiveUsersByClientId(currentUser?.client_id).then((res) => {
      setUsersList(res?.data?.data ?? []);
    });
  }, [globalPlant, evaluatedChecked]);

  useEffect(() => {
    setHeaderPageTitle(`Gauge Issue Register`);
  }, []);


  function afterGaugeissue(status, submitStatus) {
    setIssueOpen(status);
    if (submitStatus) {
      reloadGridData();
    }
  }
  function afterGaugeReturn(status, submitStatus) {
    setReturnOpen(status);
    if (submitStatus) {
      reloadGridData();
    }
  }

  function onRowSelection(data) {
    if (data[0]?.issued === 1) {
      setSelectedRow(data[0]);
    } else {
      setSelectedRow(null);
    }
  }

  function reloadGridData() {
    setSelectedRow(null);
    gaugeService
      .getGaugeIssueTable(
        currentUser?.client_id,
        globalPlant.globalPlant.globalPlant.id,
        gembaFlag
      )
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }
  const columns = [
    {
      headerName: "SI No",
      minWidth: 100,
      sortable: false,
      pinned: "left",
      valueGetter: "node.rowIndex + 1",
      cellStyle: { backgroundColor: "#f1f1f1" },
      resizable: true,
    },
    {
      field: "gauge_number_name",
      headerName: "Gauge Number",
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 250,
      resizable: true,
    },
    {
      field: "issued_by",
      headerName: "Issued By",
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      resizable: true,
    },
    {
      field: "issued_date",
      headerName: "Issued date",
      sortable: true,
      floatingFilter: true,
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      minWidth: 200,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.issued_date
          ? moment(params?.data?.issued_date).format("DD/MM/YYYY")
          : "",
    },
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      resizable: true,
    },
    {
      field: "issued_to",
      headerName: "Issued To",
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      resizable: true,
    },
    {
      field: "returned_date",
      headerName: "Returned",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.returned_date
          ? moment(params?.data?.returned_date).format("DD/MM/YYYY")
          : "",
    },
  ];
  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setFormOpen(true)}
          style={{ marginRight: 5 }}
        >
          ISSUE
        </Button>
        {selectedRow ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CancelOutlinedIcon />}
            onClick={() => setReturnOpen(true)}
          >
            Return
          </Button>
        ) : null}
      </>
    );
  }

  function evaluatedCheckedhistory() {
    if (!evaluatedChecked) {
      setEvaluatedChecked(true)
      setGembaFlag(0)
    }
    else {
      setEvaluatedChecked(false)
      setGembaFlag(1)
    }
  }

  return (
    <>
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          rows={rows}
          columns={columns}
          ButtonComponent={ButtonComponent}
          onRowSelect={onRowSelection}
          gridheight={gridheight}
          HeaderComponent={() => (
            <FormControlLabel
              control={
                <Switch
                  checked={evaluatedChecked}
                  onChange={(e) => evaluatedCheckedhistory(e.target.checked)}
                  color="primary"
                  name="Hide evaluated"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Issue History"
            />
          )}
        />
      </Grid>


      <DGDrawer
        isOpen={formOpen}
        setOpen={(state) => {
          setFormOpen(state);
          reloadGridData();
        }}
        componentProps={{
          userList: usersList,
          plantList: plantList,
          gauge_item: selectedRow,
        }}
        Component={GaugeIssueForm}
        title="Issue"
        subtitle={new Date().toLocaleDateString()}
        needSave
      />
      {/* {selectedRow && returnOpen && (
        <GaugeReturn
          isOpen={returnOpen}
          setOpen={afterGaugeReturn}
          gauge_item={selectedRow}
        />
      )} */}
      <DGDrawer
        Component={GaugeReturnForm}
        isOpen={returnOpen}
        setOpen={(status, data) => {
          afterGaugeReturn(false, data?.status === "s");
        }}
        title="Gauge Return"
        needSave
        componentProps={{ ...selectedRow }}
      />
    </>
  );
}
var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
}
