/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { object, string, number } from "yup";
import { ACTION_TYPE } from "../../components/shared/commonenum";
import { DGDateInput } from "../../components/shared/DGDateInput";
import { DGInput } from "../../components/shared/DGInput";
import { DGSelect } from "../../components/shared/DGSelect";
import { AuthContext } from "../../context/AuthContextProvider";
import { AlertContext } from "../../context/AlertContextProvider";
import { format } from 'date-fns';


export function VendorFormView({
  componentProps = {},
  Footer = () => { },
  closeForm = () => { },
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);


  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10 }}>


        <Grid item md={12} sm={12}>
          <DGInput
            required
            id="address1"
            label="Street Address Line 1"
            value={componentProps?.address?.address_line_1}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <DGInput
            id="address2"
            label="Street Address Line 2"
            value={componentProps?.address?.address_line_2}
          />
        </Grid>

        <Grid item md={6} sm={6}>
          <DGInput

            id="city"
            label="City"
            value={componentProps?.address?.city}

          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Pin Code"
            id="pincode"
            value={componentProps?.address?.pincode}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="State"
            id="state"
            value={componentProps?.address?.state}

          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput

            label="Country"
            id="country"
            value={componentProps?.address?.country}

          />
        </Grid>

      </Grid>
      {/* 
      {componentProps?.vendor_type?.id == 10 && (
        <>
          <Grid item md={12} sm={12}>
            <DGInput
              id="certificate"
              label="Certificate Number"
              value={componentProps.certificate_number}
            />
          </Grid>

          <Grid item md={6} sm={6}>
            <DGInput
              label="Issued Date"
              id="issued_date"
              value={componentProps?.issued_date ?
                format(new Date(componentProps.issued_date), 'dd/MM/yyyy') : ''}

            />
          </Grid>

          <Grid item md={6} sm={6}>
            <DGInput
              label="Expiry Date"
              id="expiry_date"
              value={componentProps?.expiry_date ?
                format(new Date(componentProps.expiry_date), 'dd/MM/yyyy') : ''}
            />

          </Grid>

        </>

      )
      } */}



    </>
  );

}
