/* eslint-disable react-hooks/exhaustive-deps */
import { Button as MuiButton, Grid } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import AddIcon from "@material-ui/icons/Add";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import productService from "../../services/productService";
import ComponentsList from "./component/ComponentsList";
import SelectProductsDialog from "./component/ComponentForm";
import { AuthContext } from "../../context/AuthContextProvider";
import { DGDrawer } from "../../components/DGDrawer";
import { SubNavigationContext } from "../../context/SubnavigationProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider";
//import AddProductDialog from "./depreciated-AddProductDialog";
import AddProductDialog from "./_AddProductDialog"

//import { ResourceTab } from "./resourcetab/ResourceTab";
const Button = styled(MuiButton)(spacing);

const ProductDetails = () => {
  //Component State
  const [selectProductDialog, setSelectProductDialog] = useState(false);
  const [addProductDialog, setAddProductDialog] = useState(false);
  const [product, setProduct] = useState({});
  const [productComponents, setProductComponents] = useState([]);
  const [editingProduct, setEditingProduct] = useState();
  let { id } = useParams();
  const [selectedTab, setSlectedTab] = useState(0);
  const { setHeaderPageTitle } = useContext(PageTitleContext);


  const { updateRoutes, setheaderPageTitle } = useContext(SubNavigationContext);



  useEffect(() => {
    productService.getProductById(id)
      .then((res) => {
        setHeaderPageTitle(`${res.data.part_number} ${res.data.part_name}`);

      })

  }, []);


  useEffect(() => {
    updateRoutes(
      [
        {
          name: "Component",
        },
        {
          name: "Resources",
        }
      ],
      true,
      onNavigationChanged
    );
  }, []);

  function onNavigationChanged(index) {
    setSlectedTab(index);
  }

  //Component Triggers
  useEffect(() => {
    getProductById(id);
    getProductComponentsById(id);
    setSlectedTab(0);
  }, [id]);


  const getProductById = async (productId) => {
    const res = await productService.getProductById(productId);
    if (res) {
      setheaderPageTitle(`${res?.data?.part_number} - ${res?.data?.part_name}`);
      setProduct(res.data);
    }
  };

  const getProductComponentsById = async (productId) => {
    const productComp = [];
    const response = await productService.getProductComponentsById(productId);
    if (response && response?.data) {
      const components = response.data.data;
      await Promise.all(
        components.map(async (component) => {
          const res = await productService.getProductById(
            component.component_part_id
          );
          productComp.push({ ...res.data, payload_id: component.id });
        })
      );
      setProductComponents([...productComp]);
    }
  };


  return (
    <React.Fragment>
      <Helmet title="Product Information" />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {selectedTab === 0 ? (
            <ComponentsList
              productComponents={productComponents}
              setProductComponents={setProductComponents}
              partId={id}
            />
          ) : null}
          {selectedTab === 2 ? (
            <Grid item xs={12} sm={12} md={12}>
              {/* <ResourceTab partId={id} /> */}
            </Grid>
          ) : null}

          {/* </Card> */}
        </Grid>
      </Grid>

      {selectProductDialog && (
        <SelectProductsDialog
          currentProduct={product}
          selectProductDialog={selectProductDialog}
          setSelectProductDialog={setSelectProductDialog}
          onSuccess={(flag) => {
            if (flag) {
              getProductById(id);
              getProductComponentsById(id);
            }
          }}
        />
      )}
      {addProductDialog && (
        <AddProductDialog
          addProductDialog={addProductDialog}
          setAddProductDialog={setAddProductDialog}
          isEdit={editingProduct ? true : false}
          editingProduct={editingProduct}
          onSuccess={(flag) => {
            if (flag) {
              getProductById(id);
              getProductComponentsById(id);

            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ProductDetails;
