import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import gaugeService from "../../../services/gaugeService";
import { Link } from "react-router-dom";
import { GaugeFilter } from "./GaugeFilters";

export function AllGaugesPage() {
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [tableName, setTableName] = useState("");
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  function loadGauges(status = 1, statusName, plantId) {
    setTableName(statusName);
    gaugeService
      ?.getAllitemsQueried(currentUser?.client_id, status, plantId)
      .then((res) => {
        setRows(res?.data);
      });
  }
  useEffect(() => {
    setHeaderPageTitle(`Gauge Master List`);
  }, []);

  const columnDefs = [
    {
      headerName: "#",
      minWidth: 80,
      sortable: false,
      pinned: "left",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      cellStyle: { backgroundColor: "#f1f1f1" },
      cellRenderer: (params) => {
        if (params.value !== undefined) {
          return params.value;
        } else {
          return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
        }
      },
    },
    {
      field: "serial_number",
      headerName: "Gauge Number",
      sortable: true,
      minWidth: 200,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left",
      cellRenderer: (params) => (
        <>
          <Link
            style={{ color: "#2196f3" }}
            variant="body2"
            to={`/pi/${params.data.id}`}
          >
            {params.value}
          </Link>
        </>
      ),
    },
    {
      field: "family_number",
      headerName: "Gauge Family",
      pinned: "left",
      sortable: true,
      resizable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <>
          <Link
            style={{ color: "#2196f3" }}
            variant="body2"
            to={`/gauge-details/${params.data.gauge_id}`}
          >
            {params.value}
          </Link>
        </>
      ),
    },
    {
      field: "family_name",
      headerName: "Gauge Name",
      resizable: true,
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },

    {
      field: "least_count",
      headerName: "Least Count",
      filter: "agMultiColumnFilter",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
    },

    {
      field: "location",
      headerName: "Location",
      filter: "agMultiColumnFilter",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
      hide: tableName !== "Active",
    },

    {
      field: "range",
      headerName: "Range",
      filter: "agMultiColumnFilter",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
    },

    {
      field: "type_name",
      headerName: "Gauge Type",
      filter: "agMultiColumnFilter",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 250,
      cellRenderer: (params) => <span>{params.value}</span>,
    },

    {
      field: "pi_last_date",
      headerName: "Last calibrated",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      valueGetter: (params) =>
        params?.data?.pi_last_date
          ? new Date(params.data.pi_last_date).toLocaleDateString()
          : "",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
      hide: tableName !== "Active",
    },
    {
      field: "pi_due_date",
      headerName: "Calibration due",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      valueGetter: (params) =>
        params?.data?.pi_due_date
          ? new Date(params.data.pi_due_date).toLocaleDateString()
          : "",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
      hide: tableName !== "Active",
    },
    {
      field: "status",
      headerName: "Status",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
      cellRenderer: (params) => <span>{params.value}</span>,
    },

    {
      field: "make",
      headerName: "Make",
      filter: "agMultiColumnFilter",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
    },

    {
      field: "plant_name",
      headerName: "Plant",
      filter: "agMultiColumnFilter",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-evenly"
      alignContent="center"
    >
      <GaugeFilter
        onClick={(id, status, plantId) => loadGauges(id, status, plantId)}
      />
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          rows={rows}
          columns={columnDefs}
          tableName={tableName}
          gridheight={250}
        />
      </Grid>
    </Grid>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
