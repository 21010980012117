import { Heading } from "@chakra-ui/react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";

export function GembaCard({
  index = -1,
  name = "",
  onClick = () => { },
  color = "#fff",
  textColor = ""
}) {
  return (
    <Grid
      item
      md={3}
      spacing={0}
      justifyContent="center"
      className="text-center"
      onClick={() => onClick(index)}
      alignContent="center"
      style={{ height: "100%", width: "100%", cursor: "pointer" }}
    >
      <Card
        style={{
          backgroundColor: color,
          borderRadius: "15px",
        }}>
        <CardContent style={{
          height: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Heading
            as="h3"
            size="lg"
            style={{ color: textColor }}>
            {name}
          </Heading>
        </CardContent>
      </Card>
    </Grid>
  );
}
