import React, { useState, useEffect, useContext } from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  TextField as MuiTextField,
  AppBar,
  Toolbar,
  Card,
  Typography as MuiTypography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import { AuthContext } from "../../context/AuthContextProvider";
import vendorService from "../../services/vendorService";
import animationData from "../../assets/animation/search-error.json";
import Lottie from "lottie-react-web";
import { Autocomplete } from "@material-ui/lab";
import VendorClientsList from "./VendorClientsList";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import { RecordCalibration } from "../gauge/calibration/RecordCalbrationdialog";
import piService from "../../services/piService";

const VendorDashboardContainer = ({
  setBusinessName = () => { },
  setBusinessDetails = () => { },
}) => {
  const useStyle = makeStyles({ button: {} });
  const classes = useStyle();
  const [selectedRow, setSelectedRow] = useState(null);
  const [recordOpen, setRecordOpen] = useState(false);
  const [returnOpen, setReturnOpen] = useState(false);
  const Typography = styled(MuiTypography)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  const { currentUser } = useContext(AuthContext);
  const [uploadCertificateDialog, setUploadCertificateDialog] = useState();
  const [customersList, setCustomersList] = useState([]);
  const [piHistoryList, setPiHistoryList] = useState();
  const [statusId, setStatusId] = useState();
  useEffect(() => {
    currentUser && getCustomersList();
  }, [currentUser]);

  const getCustomersList = async () => {
    // const response = await vendorService.getClientsByVendorUserId(currentUser.id);
    // // console.log('getCustomersList response', response.data.data);
    // if (response && response.data && response.data.data && response.data.data.length) {
    //     setCustomersList(response.data.data);
    // }
    vendorService?.getCalibVendorClientlist(currentUser?.id).then((res) => {
      setCustomersList(res?.data ?? []);
    });
  };

  function onRowSelection(data) {
    setSelectedRow(data[0]);
  }

  const ButtonComponent = () => {
    return (
      <>
        {selectedRow ? (
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<CloudUploadOutlinedIcon />}
            onClick={() => {
              handleRecordClicked();
              setRecordOpen(true);
            }}
          >
            RECORD
          </Button>
        ) : null}
      </>
    );
  };

  const handleRecordClicked = () => {
  };

  const handleCustomerSelection = async (item) => {
    setBusinessDetails(item);
    // const response = await piService.getPIAgencyByClientIdAndPiAgencyId(item.client_id, item.pi_agency_id);
    // //console.log("response from PI Requests", response.data.data);
    // if (response && response.data && response.data.data && response.data.data.length) {
    //     setBusinessName(response.data.data[0]['gauge_item']['pi_agency']['business_name'])
    //     setPiHistoryList(response.data.data);
    // }
    if (item) {
      vendorService
        .getCalibVendorClienttable(item.client_id, item.pi_agency_id)
        .then((res) => {
          setPiHistoryList(res?.data ?? []);
        });
    }
  };

  function afterRecordCalibration(status, submitstatus = false) {
    setRecordOpen(status);
    if (submitstatus) {
      setRecordOpen(false);
      // loadAllFilters(statusId, tableName);
      // reloadGriddata();
    }
  }
  return (
    <React.Fragment>
      {customersList && customersList.length ? (
        <Card style={{ height: "auto", width: "30%" }}>
          <Autocomplete
            id="combo-box-demo"
            options={customersList}
            getOptionLabel={(option) => option?.client_name ?? ""}
            style={{ height: "auto", width: "100%", padding: "1rem" }}
            renderInput={(params) => (
              <TextField {...params} label="Customer" variant="outlined" />
            )}
            onChange={(e, value) => {
              // console.log("Client", value);
              handleCustomerSelection(value);
            }}
          />
          <Typography
            variant="caption"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "-0.5rem",
              marginBottom: "0.5rem",
              marginLeft: "1rem",
              color: "#101B55",
              fontWeight: "bold",
            }}
          >
            Please select any one of your customer
          </Typography>
        </Card>
      ) : null}
      <div style={{ marginTop: "12rem" }}>
        <VendorClientsList
          piHistoryList={piHistoryList}
          customerList={customersList}
          ButtonComponent={ButtonComponent}
          onRowSelection={onRowSelection}
        />
      </div>
      {selectedRow && recordOpen && (
        <RecordCalibration
          isOpen={recordOpen}
          setOpen={afterRecordCalibration}
          gauge_item={selectedRow}
          setRecordOpen={setRecordOpen}
        />
      )}
    </React.Fragment>
  );
};

export default VendorDashboardContainer;
