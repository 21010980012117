import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { DGSelect } from "../../../components/shared/DGSelect";
import customerDashboardService from "../../../services/customerDashboardService";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContextProvider";
import HighChartComponent from "../../../components/HighChartsComponent";

export function CustomerSpcs() {
  let form = useFormik({
    initialValues: {
      dropdown1: null,
      dropdownoptions1: [],
      dropdown2: null,
      dropdownoptions2: [],
    },
  });
  let { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [chartData1, setchartData1] = useState(null);
  const [chart1UpperLimit, setChart1UpperLimit] = useState(0);
  const [chart1LowerLimit, setChart1LowerLimit] = useState(0);
  const [chart2UpperLimit, setChart2UpperLimit] = useState(0);
  const [chart2LowerLimit, setChart2LowerLimit] = useState(0);
  const [chart1CenterLimit, setChart1CenterLimit] = useState(0);
  const [chart2CenterLimit, setChart2CenterLimit] = useState(0);
  const [limit, setLimit] = useState({
    loc_min: null,
    loc_max: null,
    dis_min: null,
    dis_max: null,
  });
  const [onHoverChartData1] = useState(`Location : {point.loc_val}
       <br/>  Evaluation :{point.evaluation_number}`);
  const [onHoverChartData2] = useState(
    ` Dispersion :{point.dis_val} <br/>  Evaluation :{point.evaluation_number}`
  );
  const [chartData2, setchartData2] = useState(null);
  const chart1Title = "Location Chart";
  const chart2Title = "Dispersion Chart";

  useEffect(() => {
    customerDashboardService
      .getCustomerSPCChar(id, currentUser?.id)
      .then((res) => {
        form.setFieldValue("dropdownoptions1", res?.data ?? []);
      });
  }, []);

  function loadControlLimits(character_id) {
    customerDashboardService
      .getCustomerSPCCL(id, currentUser?.id, character_id)
      .then((res) => {
        form.setFieldValue("dropdownoptions2", res?.data ?? []);
      });
  }

  function getChartData(char_id = "", spc_input_id = "") {
    customerDashboardService
      .getSPCCharPlot(id, currentUser?.id, char_id, spc_input_id)
      .then((res) => {
        let obj = res?.data?.common[0];
        setchartData1(res?.data?.evaluation_1 ?? []);
        setchartData2(res?.data?.evaluation_2 ?? []);
        setChart1UpperLimit(obj?.loc_ucl);
        setChart1LowerLimit(obj?.loc_lcl);
        setChart1CenterLimit(obj?.loc_target);
        setChart2UpperLimit(obj?.dis_ucl);
        setChart2LowerLimit(obj?.dis_lcl);
        setChart2CenterLimit(obj?.dis_target);
        setLimit({
          loc_min: obj?.loc_min ?? null,
          loc_max: obj?.loc_max ?? null,
          dis_min: obj?.dis_min ?? null,
          dis_max: obj?.dis_max ?? null,
        });
      });
  }
  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          options={form.values.dropdownoptions1}
          value={form.values.dropdown1}
          onChange={(val) => {
            form.setFieldValue("dropdown1", val);
            form.setFieldValue("dropdown2", null);
            loadControlLimits(val?.id);
          }}
          label="Character"
          getOptionLabel={(opt) =>
            `${opt?.name} ${opt?.specification} ${opt?.upper_specification} ${opt?.lower_specification}`
          }
          disabled={form.values.dropdownoptions1?.length === 0}
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          options={form.values.dropdownoptions2}
          value={form.values.dropdown2}
          onChange={(val) => {
            form.setFieldValue("dropdown2", val);
            getChartData(val?.character_id, val?.spc_input_id);
          }}
          getOptionLabel={(opt) => opt?.list_items}
          label="Control Limit"
          disabled={form.values.dropdownoptions2?.length === 0}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <HighChartComponent
          title2={chart2Title}
          title1={chart1Title}
          chartData1={chartData1}
          chartData2={chartData2}
          highLimit1={chart1UpperLimit}
          lowLimit1={chart1LowerLimit}
          targetLimit1={chart1CenterLimit}
          onHoverData1={onHoverChartData1}
          highLimit2={chart2UpperLimit}
          lowLimit2={chart2LowerLimit}
          targetLimit2={chart2CenterLimit}
          onHoverData2={onHoverChartData2}
          limit={limit}
        />
      </Grid>
    </Grid>
  );
}
