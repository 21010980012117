import { Avatar, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { UserMenu } from "../../pages/auth/UserMenu";
import { ModalContext } from "../../context/ModalContextProvider";
import { AuthContext } from "../../context/AuthContextProvider"
import { useHistory } from "react-router-dom";


export function SideBarProfile({ phoneNumber = "", name = "", logo = "" }) {
  const classes = useStyle();
  const history = useHistory();
  const { currentUser, logoutUser } = useContext(AuthContext);
  const { updateModel, resetModal } = useContext(ModalContext);
  const handleSignOut = () => {
    logoutUser();
    resetModal();
    history.replace("/auth/sign-in");
  };


  const handleMenuClick = () => {
    updateModel({
      open: true,
      title: "Do you want to logout ?",
      subtitle: "Are you sure you want to logout?  You can log in again with your login Phone Number",

      actionButtons: [
        { name: "Cancel", onClick: resetModal, color: "gray" },
        { name: "Yes", onClick: handleSignOut, color: "red" },
      ],
    })
  };


  return (
    <Card className={classes.card}
      onClick={handleMenuClick}>
      <Grid
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        className={classes.content}
      >
        <Grid item md={12} dm={12} lg={12}>
          <Typography
            variant="h6"
            className={classes.h6}
            style={{ fontSize: "14px" }}
            align="left"
          >
            {name}
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className={classes.subtitle1}
            align="left"
          >
            {phoneNumber ? "+91 " + phoneNumber : ""}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
const useStyle = makeStyles({
  h6: {
    fontSize: "0.875rem",
    margin: "0px",
    fontWeight: 600,
    lineHeight: 1.5714,
    fontFamily: "Public Sans, sans-serif",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  subtitle1: {
    fontSize: "0.875rem",
    margin: "0px",
    fontWeight: 600,
    lineHeight: 1.5714,
    fontFamily: "Public Sans, sans-serif",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#637681",
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    padingRight: 20,
    paddingLeft: 20,
    fontSize: "0.875rem",
  },
  card: {
    backgroundColor: "#919EAB1F",
    borderRadius: 12,
    cursor: "pointer",
  },
});
