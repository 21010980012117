/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Button, IconButton } from "@material-ui/core";
import CommonGrid from "../../../components/CommonGrid";
import disptachInspection from "../../../services/disptachInspection";
import { AuthContext } from "../../../context/AuthContextProvider";
import { DGDrawer } from "../../../components/DGDrawer";
import ActiveInActive from "../../../components/ActiveInActive";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DispatchPlanView } from "./DispatchPlanView";
import { DispatchPlanNew } from "./DispatchPlanNew";
import { useParams } from "react-router-dom";


export function DispatchPlanListing() {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [formView, setFormView] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [selectedRow, setSelectedRow] = useState({});
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [maxId, setMaxId] = useState(0);
  let id = useParams();

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadGridData,
    }));

    reloadGridData();
  }, []);



  function ButtonComponent() {
    if (id.id) {
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setFormOpen(true);
            }}
            style={{ marginLeft: 3 }}
          >
            Dispatch Plan
          </Button>
        </>
      );
    }
    return null;
  }

  function loadForm(data) {
    setSelectedRow(data);
    setFormView(true);
  }

  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 105,
      width: 100,
    },
    /*     {
          headerName: "Part Details",
          field: "plan_name",
          resizable: true,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          cellRenderer: (params) => {
            return (
              <>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => loadForm(params?.data)}
                >
                  {`${params?.data?.part_number}-${params?.data?.part_name}`}
                </Button>
              </>
            );
          },
        }, */
    {
      headerName: "Plan Number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <>
            <Button
              size="small"
              color="primary"
              onClick={() => loadForm(params?.data)}
            >
              DG/PDI/{`${params?.data?.ref_id}`}
            </Button>
          </>
        );
      },
    },
    {
      headerName: "Standard Checklist Name",
      field: "std_plan",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              callback: changeStatus,
              reloadGrid: reloadGridData,
              entityName: params?.data?.dispatch_plan_number ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params?.data?.id,
            }))
          }
        />
      ),
    },
    {
      headerName: "Status History",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.id,
                entity: params?.data?.ctool_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];


  function reloadGridData() {
    disptachInspection
      .getClientPDITable({
        client_id: currentUser?.client_id,
        part_id: id.id,
      })
      .then((res) => {
        setRows(res?.data?.plan_table ?? []);
        setMaxId(res?.data?.max_id ?? 1);
      });
  }

  function changeStatus(dummy1, dummy2, newStatus, rowId) {
    return disptachInspection.UpdateClientPDITable(rowId, { enabled: newStatus });
  }

  function loadForm(data) {
    setSelectedRow(data);
    setFormView(true);

  }


  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        ButtonComponent={ButtonComponent}
        gridheight={150}
      />

      <DGDrawer
        Component={DispatchPlanNew}
        isOpen={formOpen}
        setOpen={(state) => {
          setFormOpen(state);
          reloadGridData();
        }}
        title="Add Dispatch Inspection Plan"
        //componentProps=
        drawerWidth={1200}
        needSave={true}
        componentProps={maxId}
      />


      <DGDrawer
        Component={DispatchPlanView}
        isOpen={formView}
        setOpen={(state) => {
          setFormView(state);
          reloadGridData();
        }}
        drawerWidth={1200}
        componentProps={selectedRow}
        title="Dispatch Inspection Plan"

      />


    </>
  );
}



