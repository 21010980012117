import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

export function Evaluationfo({
  evaluation_info = 
    {
      effectiveness: [],
      miss_rate: [],
      false_alarm_rate: [],
    }
}) {
  const[rows,setRows]=useState([]);
  const[cols,setCols]=useState([{name:"Operator"},{name:"Effectiveness"},{name:"Miss Rate"},{name:"False Alaram"}]);
  useEffect(()=>{
    let rowData=[]
      for(let i=0;i<evaluation_info?.effectiveness?.length;i++){
      rowData.push({operator:`Operator ${i+1}`,effectivness:parseFloat(evaluation_info?.effectiveness[i]??0).toFixed(1),mr:parseFloat(evaluation_info?.miss_rate[i]??0).toFixed(1),far:parseFloat(evaluation_info?.false_alarm_rate[i]??0).toFixed(1)})
    }
    setRows(rowData);
  },[evaluation_info])
  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        style={{ backgroundColor: indigo[100] }}
      >
        EVALUATION
      </Typography>
      <table class="table">
        <thead>
          <tr>
            {cols?.map(col=>( <th scope="col">{col?.name}</th>))}
          </tr>
        </thead>
        <tbody>
          {rows?.map(r=>(  <tr>
            <td>{r?.operator}</td>
            <td>{r?.effectivness}</td>
            <td>{r?.mr}</td>
            <td>{r?.far}</td>
          </tr>))}
        </tbody>
      </table>
    </div>
  );
}
