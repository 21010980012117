import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useRef } from "react";
import { DataInfo } from "./DataInfo";
import { GaugeRep } from "./GaugeRepeatandRepro";
import { DrawingInfo } from "./DrawingInfo";
import { ResultInfo } from "./ResultInfo";
import { ApprovaInfo } from "./approval_info";
import { indigo } from "@material-ui/core/colors";
import reportService from "../../../../../services/reportService";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";

export function SignalReport({ reportData = {}, disabledDownload = false }) {
  const componentRef = useRef();
  const handlePrint = function () {
    reportService.generateSignalReport(reportData).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(res.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "signalReport.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <div className="text-end w-100 mb-2">
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudDownloadOutlinedIcon />}
          style={{ margin: 2, marginRight: 4 }}
          onClick={handlePrint}
          disabled={disabledDownload}
        >
          Download
        </Button>
      </div>
      <div
        className="container  text-center"
        style={{ backgroundColor: "#fff" }}
        ref={componentRef}
      >
        <div className="row align-items-center">
          <div className="col-3 justify-self-start">
            <Box>
              <img
                src={reportData?.company_info[0]?.logo}
                style={{ width: 200, height: 100 }}
                className="p-1"
              />
            </Box>
          </div>
          <div className="col-6 align-self-center text-center">
            <Box>
              <Typography variant="h4" align="center">
                {reportData?.company_info[0]?.business_name}
              </Typography>
            </Box>
          </div>
          <div className="col-3 ">
            <Box>
              <Typography variant="h4" align="center">
                {reportData?.company_info[0]?.plant_name}
              </Typography>
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <GaugeRep
              study_info={reportData?.study_info}
              product_info={reportData?.product_info}
              gauge_info={reportData?.gauge_info}
              test_info={reportData?.test_info}
            />
          </div>
        </div>
        <div className="row mt-1 pl-0 pr-0">
          <div className="col pl-0 pr-0 ml-0 mr-0">
            <DataInfo data_info={reportData?.data_info} />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <DrawingInfo drawing_info={reportData?.kappa_info} />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <ResultInfo result_info={reportData?.result_info} />
          </div>
        </div>
        <div
          className="row mt-2 align-items-center"
          style={{ backgroundColor: indigo[100] }}
        >
          <ApprovaInfo approval_info={reportData?.approval_info[0]} />
        </div>
      </div>
    </>
  );
}
