import http from "./httpService";

const createCsrTemplate = (data) => {
  return http.post('/csr-template', data);
};

const updateCsrTemplate = (id, data) => {
  return http.patch(`/csr-template/${id}`, data);
};

const getCsrTemplateById = (id) => {
  return http.get(`/csr-template/${id}`);
};

const getCsrTemplateByCustomerId = (customerId) => {
  return http.get('/csr-template', {
    params: {
      customer_id: customerId,
      $limit: 100,
    }
  });
};

const getCsrTemplates = (clientId) => {
  return http.get('/csr-template', {
    params: {
      client_id: clientId,
      $limit: 100,
    }
  });
};


const getCSR = (csr_id) => {
  return http.get('/csr-master', {
    params: {
      id: csr_id,
      $limit: 100,
    }
  });
};


const getCSRList = () => {
  return http.get('/csr-list')
};



const getCSRPart = (part_id) => {
  return http.get('/csr-part', {
    params: {
      part_id: part_id
    }
  });
};



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCsrTemplate,
  updateCsrTemplate,
  getCsrTemplates,
  getCsrTemplateByCustomerId,
  getCsrTemplateById,
  getCSR,
  getCSRList,
  getCSRPart

};
