import http from "./httpService";

const createInvite = (data) => {
    return http.post("/invite", data);
};

const getInvites = (clientId) => {
    return http.get('/invite', {
        params: {
            client_id: clientId
        }
    });
};



const checkInvitePhone = (phone_number) => {
    return http.get("/check-phone", {
        params: {
            phone_number: phone_number,
        }
    })
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createInvite,
    getInvites,
    checkInvitePhone
};