import { Button, Typography as MuiTypography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { green, red } from "@material-ui/core/colors";
import { spacing } from "@material-ui/system";
import { rgba } from "polished";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import DataGridTable from "../../components/DataGridTable";
import animationData from "../../assets/animation/search-error.json";
import Lottie from "lottie-react-web";
import CommonGrid from "../../components/CommonGrid";
import ActiveInActive from "../../components/ActiveInActive";
import { StatusContext } from "../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { AuthContext } from "../../context/AuthContextProvider";
import inviteService from "../../services/inviteService";
import userService from "../../services/userService";
import AddIcon from "@material-ui/icons/Add";
import InvitesDialog from "./InvitesDialog";
import { DGDrawer } from "../../components/DGDrawer";
import { InviteForm } from "./InviteForm";
import { PageTitleContext } from "../../context/PageTitleContextProvider";

const InvitesList = ({ ButtonComponent = () => null }) => {
  const [rows, setRows] = useState([]);
  const [addInvitesDialog, setAddInvitesDialog] = useState(false);
  const {
    setStatus,
  } = useContext(StatusContext);
  const { setStatusHistory } = useContext(
    StatusHistoryContext
  );
  const { currentUser } = useContext(AuthContext);
  const [isKeyUser, setIsKeyUser] = useState(false);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      callback: changeStatus,
      reloadGrid: getAllInvites,
    }));
    setStatusHistory((prev) => ({ ...prev }));
    currentUser && getAllInvites();
    getUserPermissions();

  }, []);

  function ButtonComponent() {
    return <> {isKeyUser ? (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setAddInvitesDialog(true)}
      >
        INVITE
      </Button>
    ) : null}</>
  }


  const getUserPermissions = async () => {
    const response = await userService.getUserRole(currentUser.id);
    if (response.data && response.data.data && response.data.data[0] && response.data.data[0].role_id <= 3) {
      setIsKeyUser(true);
    }
  }

  const getAllInvites = async () => {
    try {
      const response = await inviteService.getInvites(currentUser.client_id);
      // console.log("Invites", response.data.data);
      if (response.data && response.data.data) {
        setRows(response?.data?.data ?? []);
        setHeaderPageTitle(`Team Members Invite`);
      }
    } catch (error) {
      // console.log("🚀 -> file: Team.js -> line 87 -> getAllInvites -> error", error);
    }
  };

  function changeStatus() {
    return Promise.resolve();
  }


  const getDateString = (inDate) => {
    const date = new Date(inDate);
    return (
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "/" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };

  // const listInvitesToRows = () => {
  //   let items = [];
  //   invites.length && invites.map((invite, index) => {
  //     items.push({
  //       id: invite.id,
  //       sl_no: index+1,
  //       phone_number: invite.invited_phone_number ? invite.invited_phone_number : 'N/A',
  //       created_date: invite.created_date ? getDateString(invite.created_date) : 'N/A',
  //       assigned_role: (invite.assigned_role && invite.assigned_role.name) ? invite.assigned_role.name.charAt(0).toUpperCase()+invite.assigned_role.name.slice(1) : 'N/A',
  //       invited_by: (invite.invited_by_user && invite.invited_by_user.name) ? invite.invited_by_user.name.charAt(0).toUpperCase()+invite.invited_by_user.name.slice(1) : 'N/A'
  //     });
  //   });
  //   setRows(items);
  // };

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      resizable: true
    },
    {
      field: "invited_phone_number",
      headerName: "Invite For",
      minWidth: 150,
      resizable: true
    },
    {
      field: "created_date",
      headerName: "Invited On",
      valueGetter: (params) => getDateString(params.data.created_date),
      minWidth: 150,
      resizable: true
    },
    {
      field: "assigned_role.name",
      headerName: "Assigned Role",
      minWidth: 150,
      resizable: true
    },
    {
      field: "invited_by_user.name",
      headerName: "Invited By",
      minWidth: 250,
      resizable: true
    },
    {
      field: "process.status",
      headerName: "Status",
      minWidth: 150,
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled ?? -1}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.invited_phone_number ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
      resizable: true,
    },
    {
      field: "process.statusHistory",
      headerName: "Status History",
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.invited_phone_number ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
      minWidth: 200,
      resizable: true,
    },
  ];

  return (
    <React.Fragment>
      <Card style={{ height: "auto", width: "100%", padding: "1rem" }}>
        <CommonGrid rows={rows} columns={columns} ButtonComponent={ButtonComponent} gridheight={180} />
        {!rows && !columns && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
            }}
            height={150}
            width={"100%"}
          />
        )}
      </Card>
      <DGDrawer
        Component={InviteForm}
        isOpen={addInvitesDialog}
        setOpen={(e) => { setAddInvitesDialog(e); getAllInvites() }}
        onClose={setAddInvitesDialog}
        needSave
        title="Add Invite"
      />
    </React.Fragment>
  );
};

export default InvitesList;
