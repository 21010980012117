import {
  AppBar,
  Button as MuiButton,
  Card as MuiCard,
  Grid,
  Toolbar,
  Typography as MuiTypography,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components/macro";
import { AuthContext } from "../../context/AuthContextProvider";
import productService from "../../services/productService";
import plantService from "../../services/plantService";
import AllPlantsList from "./AllPlantsList";
//import AddPlantDialog from "../depreciated/AddPlantDialog";
import animationData from "../../assets/animation/gears-loading.json";
import Lottie from "lottie-react-web";
import userService from "../../services/userService";
const Card = styled(MuiCard)(spacing);

const AllPlantsContainer = () => {
  const Button = styled(MuiButton)(spacing);
  const Typography = styled(MuiTypography)(spacing);
  const [addPlantDialog, setAddPlantDialog] = useState(false);
  const [plants, setPlants] = useState([]);
  const CloudUpload = styled(MuiCloudUpload)(spacing);
  const { currentUser } = useContext(AuthContext);
  const [isKeyUser, setIsKeyUser] = useState(false);

  useEffect(() => {
    currentUser && getAllPlants();
    currentUser && getUserPermissions();
  }, [currentUser]);

  const getUserPermissions = async () => {
    const response = await userService.getUserRole(currentUser.id);
    if (
      response.data &&
      response.data.data &&
      response.data.data[0] &&
      response.data.data[0].role_id <= 3
    ) {
      setIsKeyUser(true);
    }
  };

  const getAllPlants = async () => {
    try {
      const response = await plantService.getPlants(currentUser.client_id);
      if (response && response.data) {
        setPlants(response.data.data);
      }
    } catch (error) {
      // console.log("🚀  -> file: AllPlantsContainer.js -> line 39 -> getAllPlants -> error", error);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}></Grid>
        {plants ? (
          <Grid item xs={12}>
            <AllPlantsList
              plants={plants}
              isKeyUser={isKeyUser}
              getAllPlants={getAllPlants}
            />
          </Grid>
        ) : (
          <div item xs={12} style={{ textAlign: "center", paddingTop: 160 }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
              }}
              height={100}
              width={"100%"}
            />
            <Typography variant="h6" gutterBottom>
              Loading
            </Typography>
          </div>
        )}
      </Grid>
      {/* {addPlantDialog && (
        <AddPlantDialog
          addPlantDialog={addPlantDialog}
          setAddPlantDialog={setAddPlantDialog}
          onSuccess={(e) => getAllPlants()}
        />
      )} */}
    </React.Fragment>
  );
};

export default AllPlantsContainer;
