import http from "./httpService";

const createOTP = (data) => {
  return http.post("/otp", data);
};

const verifyOTP = (data) => {
  return http.post("/authentication", data);
}

export default {
  createOTP,
  verifyOTP
}