import { Grid } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useContext, useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { DGInput } from "../../../components/shared/DGInput";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import { DGSelect } from "../../../components/shared/DGSelect";
import productionInspection from "../../../services/productionInspection";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import CommonGrid from "../../../components/CommonGrid";
import plantService from "../../../services/plantService";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContextProvider";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import automatedInspectionService from "../../../services/automatedInspectionService";


export function UploadClientForm({
  Footer = () => { },
  closeForm = () => { },
}) {
  const [file, setFile] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext);
  const [dropdownColumns, setDropdowncolumns] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [plantId, setPlantId] = useState();
  const { updateAlert } = useContext(AlertContext);

  /*   useEffect(() => {
      automatedInspectionService
        .getAutomatedInspectionDetail({
          client_id: currentUser?.client_id,
          id: componentProps?.id,
        })
        .then((res) => {
          let header = res?.data?.header;
          let body = res?.data?.body;
          form.setFieldValue("batch", header[0]?.batch);
          form.setFieldValue("part", header[0]);
          form.setFieldValue("partOptions", header);
          // console.log("res", res);
  
          const transposedData = body.map((item) => {
            const rowData = {};
            item.raw_data.forEach((value, index) => {
              rowData[index] = value;
            });
            return rowData;
          });
  
          form.setFieldValue("gridData", transposedData);
          let columndefs = body.map((exp, i) => {
            dropdownColumns[i] = exp;
            return {
              headerName: `${i}`,
              field: `${i}`,
              minWidth: 100,
              headerComponent: () => <HeaderComponent i={i} options={body} />,
              cellStyle: (params) => {
                if (!params?.data[i]) {
                  return { backgroundColor: "#ffebee" };
                } else {
                  return null;
                }
              },
            };
          });
          setColumnDefs(columndefs);
        });
  
    }, []); */


  let form = useFormik({
    initialValues: {
      part: null,
      batch: null,
      gridData: [],
      partOptions: [],
      columns: [],
      characteristicsOption: [],
      inspection_date: new Date(),
      inspection_time: new Date(),
      gaugeOptions: [],
      gauge: []
    },
    validationSchema: object({
      part: object()
        .shape({
          part_id: string().required(),
          part_name: string().required(),
          part_number: string().required(),
        })
        .required(),
      gridData: array().min(1).required(),
    }),
    onSubmit: createRequest,
  });

  function HeaderComponent({ i = -1, options = [] }) {
    return (
      <DGSelect
        id="characterstics"
        label="Characteristics"
        options={options}
        isFormik
        required
        value={dropdownColumns[i] ?? null}
        onChange={(val) => {
          dropdownColumns[i] = val;
          setDropdowncolumns([...dropdownColumns]);
        }}
        getOptionLabel={(opt) =>
          `${opt?.character_name} ${opt?.specification !== null ? opt?.specification : ""
          } ${opt?.upper_specification !== null ? opt?.upper_specification : ""
          } ${opt?.lower_specification !== null ? opt?.lower_specification : ""
          } `
        }
        error={
          form.touched.characterstics && Boolean(form.errors.characterstics)
        }
      />
    );
  }

  useEffect(() => {
    const readFile = async () => {
      if (file) {
        try {
          const fileData = file[0];
          const arrayBuffer = await readAsArrayBuffer(fileData);
          const workbook = read(arrayBuffer, { type: "array" });
          for (const sheetName of workbook.SheetNames) {
            const sheet = workbook.Sheets[sheetName];
            const range = utils.decode_range(sheet["!ref"]);
            const totalColumns = range.e.c + 1;
            let i = 0;
            const raw_data = utils.sheet_to_json(sheet, { header: 1 });
            raw_data?.shift();
            const rowObjects = raw_data
              .filter((exp) => exp?.length > 0)
              .map((row) => {
                if (Array.isArray(row) && row.length > 0) {
                  return row.reduce((acc, value, index) => {
                    if (value !== null) {
                      acc[index.toString()] = value;
                    }
                    return acc;
                  }, {});
                }
                return {};
              });
            let dropdownColumns = [];
            let array = new Array(totalColumns).fill(0);
            let columnDefs = array.map((col, i) => {
              dropdownColumns.push(null);
              return {
                headerName: `${i}`,
                field: `${i}`,
                headerComponent: () => <HeaderComponent i={i} options={[]} />,
                cellStyle: (params) => {
                  if (!params?.data[i]) {
                    return { backgroundColor: "#ffebee" };
                  } else {
                    return null;
                  }
                },
              };
            });
            setDropdowncolumns([...dropdownColumns]);
            form.setFieldValue("gridData", rowObjects);
            form.setFieldValue("columns", columnDefs);
            setColumnDefs(columnDefs);
          }
        } catch (error) {
          console.error("Error reading XLSX file:", error);
        }
      }
    };
    if (file) {
      readFile();
    }
  }, [file]);

  const readAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  useEffect(() => {
    plantService.getDefaultPlant(currentUser?.id).then((res) => {
      res?.data?.map((exp) => {
        if (exp?.default_flag) {
          setPlantId(exp?.plant_id);
        }
      });
    });
  }, []);

  useEffect(() => {
    loadParts();
  }, [globalPlant]);


  function createRequest() {


    let hasError = false;


    if (
      !form.values.batch ||
      !form.values.gridData ||
      !form.values.part ||
      dropdownColumns.filter((exp) => exp == null).length > 0
    ) {
      hasError = true;
      // console.log("error");
      return;
    }

    //console.log(form.values.gridData);


    const transposedData = Object.keys(
      form.values.gridData[0]
    ).map((colIndex) => form.values.gridData.map((row) => row[colIndex]));
    let requestObject = transposedData.map((exp, i) => ({
      client_id: currentUser?.client_id,
      plant_id: plantId,
      part_id: form.values?.part?.part_id,
      created_by: currentUser?.id,
      batch_details: form.values.batch,
      inspection_date: moment(form.values.inspection_date).format("YYYY/MM/DD"),
      inspection_time: moment(form.values.inspection_time).format("HH:mm:ss"),
      character_id: dropdownColumns[i]?.character_id,
      measurement_type: 301,
      gauge_family_id: null,
      values_: exp,
      machine_spindle_id: null,
    }));

    console.log(requestObject)

    automatedInspectionService
      .createAutomatedInspection(requestObject)
      .then((res) => {
        closeForm(true)
        updateAlert({
          open: true,
          message: "Record created Successfully",
          type: "success",
        });

      })
      .catch(() => {
        updateAlert({
          open: true,
          message: "Failed To Create Record",
          type: "error",
        });
      });

  }

  function loadParts() {
    productionInspection
      .getProductionFilter({
        client_id: currentUser?.client_id,
        plant_id: globalPlant?.globalPlant?.id,
        query_id: 2,
      })
      .then((res) => {
        form.setFieldValue("partOptions", res?.data ?? []);
      });
  }

  function loadCharcter(partid) {
    productionInspection
      .getInspectionPlanChar(partid)
      .then((res) => {
        const temp12 = res.data.map(item => {
          const partNumber = item.part_number;
          const characterName = item.character_name.replace(partNumber + '-', '');
          return { ...item, character_name: characterName };
        });


        var cols = columnDefs.map((exp, i) => ({
          ...exp,
          headerComponent: () => (
            <HeaderComponent i={i} options={temp12 ?? []} />
          ),
        }));
        setColumnDefs([...cols]);
      })
      .catch((exp) => {
        var cols = columnDefs.map((exp, i) => ({
          ...exp,
          headerComponent: () => <HeaderComponent i={i} options={[]} />,
        }));
        setColumnDefs([...cols]);
      });
  }

  function ResetForm() {
    form.setFieldValue("gridData", []);
    form.setFieldValue("columns", []);
  }

  return (
    <>

      <>
        <DropzoneArea
          showFileNamesInPreview={true}
          showFileNames={true}
          onChange={(file) => setFile(file)}
          onDelete={ResetForm}
        />
      </>

      <Grid container spacing={2} className="mt-2">
        <Grid item md={6} lg={6} sm={6}>
          <DGSelect
            id="part"
            label="Part"
            options={form.values.partOptions}
            getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
            value={form.values.part}
            onChange={(val) => {
              loadCharcter(val?.part_id);
              form.setFieldValue("part", val);
            }}
            required
            error={form.touched.part && Boolean(form.errors.part)}
          />
        </Grid>
        <Grid item md={6} lg={6} sm={6}>
          <DGInput
            id="batch"
            label="Batch Details"
            value={form.values.batch}
            onChange={form.handleChange}
            isFormik
            error={form.touched.batch && Boolean(form.errors.batch)}
          />
        </Grid>

        <Grid item container md={12} sm={12} lg={12} spacing={2}>
          {/*           <Grid item md={4} sm={4} lg={4}>
            <DGSelect id="part" label="Gauge Details" options={[]} required />
          </Grid> */}

          <Grid item md={6} sm={6} lg={6}>
            {" "}
            <DGDateTimePicker
              label="Inspection Date"
              format="dd/MM/yyyy"
              value={form.values.inspection_date}
              onChange={(val) => {
                form.setFieldValue("inspection_date", val);
              }}
              required
              error={
                form.touched.production_date &&
                Boolean(form.errors.production_date)
              }
            />
          </Grid>
          <Grid item md={6} sm={6} lg={6}>
            <DGDateTimePicker
              label="Inspection Date"
              format="HH:mm"
              value={form.values.inspection_time}
              onChange={(val) => {
                form.setFieldValue("inspection_time", val);
              }}
              required
              error={
                form.touched.production_date &&
                Boolean(form.errors.production_date)
              }
            />
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <CommonGrid
            rows={form.values.gridData}
            columns={columnDefs}
            needExport
          />
        </Grid>
        <Footer onSave={createRequest} />
      </Grid>
    </>
  );
}
