import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Paper,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";

import CharControlLimitListing from "../controllimit/CharControlLimitListing";
import SPCPlanListing from "../spcPlan/SpcPlanListing";

function SPCcharacterTab({
  partNumber = "",
  specification = "",
  charcterstics = "",
  ids = "",
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const buttons = [
    "Control Limits Evaluation",
    "SPC Plan",

  ];

  return (
    <Paper square>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setSelectedTab(val)}
        aria-label="disabled tabs example"
      >
        {buttons.map((btn, index) => (
          <StyledTab label={btn} value={index} />
        ))}
      </Tabs>
      <Box m={2}>
        {selectedTab === 0 ? <CharControlLimitListing /> : null}
        {selectedTab === 1 ? <SPCPlanListing /> : null}
        {/*         {selectedTab === 0 ? (
          <CharControlLimitListing
            ids={ids}
            partNumber={partNumber}
            specification={specification}
            charcterstics={charcterstics}
          />
        ) : null} */}
      </Box>
    </Paper>
  );
}

export default SPCcharacterTab;

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);
