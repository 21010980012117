import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../components/CommonGrid";
import { Button, Grid } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContextProvider";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import productionMgmtService from "../../../services/productionMgmtService";
import { AlertContext } from "../../../context/AlertContextProvider";
import errorLog from "../../../services/errorLog";
import { PlantContext } from "../../../context/PlantContextProvider";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { array, object, string, number } from "yup";
import { format, set } from "date-fns";


export function DefectIncidentProduction({
    componentProps = null,
    Footer = () => null,
    closeForm = () => { },

}) {

    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { v4: uuidv4 } = require("uuid")
    const { gridApi, setGridApi } = useState(null)
    const { updateAlert } = useContext(AlertContext);


    let form = useFormik({
        initialValues: {
            productionStartTimeOptions: [],
            productionStartTime: [],
            productionEndTimeOptions: [],
            productionEndTime: [],
            quantity: "",
            rejections: "",
            productionRecordData: [],
            defectMasterOptions: [],
            defectMasterSelected: [],
            incidentQuantity: 0,
            gridData: [],
            defectQuantityAdded: 0

        },

        validationSchema: object({

            gridData: array().min(1).required(),

        }),


        onSubmit: saveForm

    });

    useEffect(() => {
        getInitialValues()
    }, [])


    function getInitialValues() {
        productionMgmtService.getProductionRecordSelect1
            ({
                machine_id: componentProps?.machine_id,
                operator_id: componentProps?.operator_id,
                production_date: format(new Date(componentProps?.production_date,), 'yyyy/MM/dd'),
                part_id: componentProps?.part_id
            }).
            then((res) => {
                const data = res?.data;
                form.setFieldValue("productionRecordData", data)
                const temp1 = data.map(item => ({
                    key: "prod_start",
                    value: item.production_start_time
                }));

                const temp2 = data.map(item => ({
                    key: "prod_end",
                    value: item.production_end_time
                }));

                form.setFieldValue("productionStartTimeOptions", temp1)
                form.setFieldValue("productionEndTimeOptions", temp2)
            })


    }


    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...form.values.gridData];
        updatedGridData.splice(rowIndex, 1);
        form.setFieldValue("gridData", updatedGridData);
        gridApi?.updateRowData({ remove: [data] });
    }

    function onGridReady(params) {
        setGridApi(params?.api);
    }



    function onAdd() {
        let data = [];
        data?.push({
            id: uuidv4(),
            defect_name: form.values.defectMasterSelected?.defect_name,
            defect_quantity: form.values.incidentQuantity,
            defect_master_id: form.values.defectMasterSelected?.id,
            defect_quantity: form.values.incidentQuantity

        })
        form.setFieldValue("gridData", [...form.values.gridData, ...data]);
    }

    function refresh1() {
        const temp2 = form.values?.productionRecordData.map(item => ({
            key: "prod_end",
            value: item.production_end_time
        }));
        form.setFieldValue("productionEndTimeOptions", temp2)

    }

    useEffect(() => {
        if (form.values.gridData.length !== 0) {

            const totalDefectQuantity = form.values?.gridData.reduce((sum, item) => {
                return sum + parseInt(item.defect_quantity);
            }, 0);

            form.setFieldValue("defectQuantityAdded", totalDefectQuantity);
        }


    }, [form.values.gridData])


    function getMasterDefects() {
        return qualityAnalyticsService.getDefectMasterTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((res) => {
                const data = res?.data ?? []
                form.setFieldValue("defectMasterOptions", data ?? []);
            });

    }

    useEffect(() => {
        if (form.values.rejections !== "") {
            getMasterDefects()
        }
    }, [form.values.rejections])



    useEffect(() => {
        let indexId
        if (form.values.productionStartTime !== "") {
            indexId = form.values.productionStartTimeOptions.findIndex(
                (item) => item.value === form.values?.productionStartTime?.value
            );
            const temp = form.values.productionEndTimeOptions.slice(indexId);
            form.setFieldValue("productionEndTimeOptions", temp)
        }

    }, [form.values.productionStartTime])

    useEffect(() => {

        let totalProductionQuantity = 0;
        let totalRejectionQuantity = 0;
        const temp = form.values?.productionRecordData;

        temp.forEach(item => {
            if (item.production_start_time >= form.values.productionStartTime?.value
                && item.production_end_time <= form.values.productionEndTime?.value) {
                totalProductionQuantity += item.production_quantity;
                totalRejectionQuantity += item.rejections;
            }
        });

        form.setFieldValue("quantity", totalProductionQuantity);
        form.setFieldValue("rejections", totalRejectionQuantity);

    }, [form.values.productionEndTime])

    const columns = [

        {
            cellRenderer: DeleteRenderer,
            maxWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            pinned: "left",
            resizable: true,
        },

        {

            headerName: "Defect",
            field: "defect_name",
            width: 200,
        }
        ,

        {

            headerName: "Defect Quantity",
            field: "defect_quantity",
            width: 200,
        }

    ]

    function saveForm() {
        let payload = []
        form.values.gridData.forEach(exp => {
            payload.push({
                id: exp.id,
                plant_id: globalPlant?.globalPlant?.id,
                client_id: currentUser?.client_id,
                part_id: componentProps?.part_id,
                production_record_id: componentProps?.id,
                defect_master_id: exp.defect_master_id,
                production_quantity: form.values?.quantity,
                defect_quantity: parseInt(exp.defect_quantity),
                measurement_type: null,
                basic_eval_id: null,
                comments: null,
                ps_project_id: null,
                machine_id: componentProps?.machine_id,
                machine_spindle_id: null,
                production_date: format(new Date(componentProps?.production_date), 'yyyy/MM/dd'),
                production_start_time: form.values.productionStartTime?.value,
                production_end_time: form.values.productionEndTime?.value,
                defect_incident_id: null,
                operator_id: componentProps?.operator_id,
                enabled: 1
            })
        })


        qualityAnalyticsService.createDefectClassification(payload)
            .then((res) => {
                updateAlert({
                    open: true,
                    message: "Defects recorded Successfully",
                    type: "success",
                });
                closeForm();
            })
            .catch((error) => {
                updateAlert({
                    open: true,
                    message: "Failed to record defects",
                    type: "error",
                });
                let payload = {
                    id: uuidv4(),
                    error_description: error.message,
                    error_location: "createDefectClassification",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: null,
                };
                errorLog.createErrorLog(payload)

            });




    }


    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <DGInput
                        label="Part Details"
                        value={componentProps?.part_number + "  " + componentProps?.part_name}
                    />


                </Grid>


                <Grid item xs={4}>
                    <DGInput
                        label="Machine "
                        value={componentProps?.machine_number + "  " + componentProps?.machine_name}
                    />

                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Operator "
                        value={componentProps?.operator_name + "  " + componentProps?.operator_eid}
                    />

                </Grid>

                <Grid item xs={4}>
                    <DGDateInput
                        label="Production Date"
                        value={componentProps?.production_date}
                        format="dd/MM/yyyy"
                    />

                </Grid>

                <Grid item xs={4}>
                    <DGSelect
                        id="productionStartTime"
                        label="Production Start Time"
                        options={form.values.productionStartTimeOptions}
                        getOptionLabel={(opt) => opt?.value}
                        value={form.values.productionStartTime ?? ""}
                        //error={form.errors.machine && form.touched.machine}
                        onChange={(val) => {
                            form.setFieldValue("productionStartTime", val);
                            form.setFieldValue("productionEndTime", "");
                            refresh1()
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <DGSelect
                        id="productionEndTime"
                        label="Production End Time"
                        options={form.values.productionEndTimeOptions}
                        getOptionLabel={(opt) => opt?.value}
                        value={form.values.productionEndTime ?? ""}
                        //error={form.errors.machine && form.touched.machine}
                        onChange={(val) => {
                            form.setFieldValue("productionEndTime", val);
                        }}
                    />
                </Grid>


                <Grid item xs={4}>
                    <DGInput
                        label="Production Quantity "
                        value={form.values?.quantity}
                    />

                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Rejections "
                        value={form.values?.rejections}
                    />

                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Total Rejections "
                        value={form.values?.defectQuantityAdded}
                    />

                </Grid>


                <Grid item xs={4}>
                    <DGSelect
                        id="defectMasterSelected"
                        label="Defect Master"
                        options={form.values.defectMasterOptions}
                        getOptionLabel={(opt) => opt?.defect_name}
                        value={form.values.defectMasterSelected ?? ""}
                        //error={form.errors.machine && form.touched.machine}
                        onChange={(val) => {
                            form.setFieldValue("defectMasterSelected", val);
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        id="incidentQuantity"
                        isFormik
                        label="Incident Quantity"
                        onChange={form.handleChange}
                        value={form.values.incidentQuantity}
                        required
                    // error={form.errors.quantity && form.touched.quantity}
                    />

                </Grid>

                <Grid item xs={12}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        disabled={form.values.defectMasterSelected === "" || form.values.incidentQuantity === ""}
                        style={{
                            marginTop: '5px',
                            width: '30%',
                        }}

                        onClick={() => {
                            onAdd()
                            form.setFieldValue("incidentQuantity", "")
                            form.setFieldValue("defectMasterSelected", "")
                        }}


                    >
                        Add
                    </Button>
                </Grid>

                <Grid item xs={12}>

                    <CommonGrid
                        columns={columns}
                        rows={form.values.gridData?.length ? form.values.gridData : []}
                        needExport={false}
                        onGridReady={onGridReady}
                        gridheight={350}
                    />
                </Grid>


            </Grid>

            <Footer onSave={form.handleSubmit} />

        </>
    )

}