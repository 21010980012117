import React, { useEffect, useState } from "react";
import {
  Card,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";

const CardForEval = ({ title, param, studyData, studyType }) => {
  const [evalData, setEvalData] = useState();

  useEffect(() => {
    setEvaluation();
  }, [studyData, studyType, title]);

  const getKappaSummaryTable = () => {
    const data = JSON.parse(studyData.result.attribute_effectiveness);
    const falseAlarm = data?.length && data[0] ? data[0] : null;
    const missRate = data?.length && data[1] ? data[1] : null;
    const effectiveness = data?.length && data[2] ? data[2] : null;

    return (
      <Paper variant="outlined" square elevation={0} style={{ width: "100%" }}>
        <Typography
          variant="h6"
          style={{ padding: 8 }}
          className="
          bg-light"
        >
          Summary Table
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Appraiser</TableCell>
              <TableCell align="center">Effectiveness</TableCell>
              <TableCell align="center">False Alarm</TableCell>
              <TableCell align="center">Miss Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {effectiveness &&
              effectiveness.length &&
              effectiveness.map((value, index) => {
                return (
                  <TableRow>
                    <TableCell align="center">Operator {index + 1}</TableCell>
                    <TableCell align="center">
                      {parseFloat(value).toFixed(1)}
                    </TableCell>
                    <TableCell align="center">
                      {falseAlarm[index]
                        ? parseFloat(falseAlarm[index]).toFixed(3)
                        : null}
                    </TableCell>
                    <TableCell align="center">
                      {missRate[index]
                        ? parseFloat(missRate[index]).toFixed(3)
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  const getKappaTable = () => {
    const data = JSON.parse(studyData.result.kappa_tab);
    const withReference = data && data.length && data[1] ? data[1] : null;
    const within = data && data.length && data[0] ? data[0] : null;

    return (
      <Paper variant="outlined" square elevation={0} style={{ width: "100%" }}>
        <Typography
          variant="h6"
          style={{ padding: 8 }}
          className="
          bg-light"
        >
          Fleis's Kappa
        </Typography>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Appraiser</TableCell>
              <TableCell align="center">With Reference</TableCell>
              <TableCell align="center">Within</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {withReference &&
              withReference.length &&
              withReference.map((value, index) => {
                return (
                  <TableRow>
                    <TableCell align="center">Operator {index + 1}</TableCell>
                    <TableCell align="center">
                      {value ? parseFloat(value).toFixed(3) : null}
                    </TableCell>
                    <TableCell align="center">
                      {within[index]
                        ? parseFloat(within[index]).toFixed(3)
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  const setEvaluation = async () => {
    if (studyType === "Gauge Capability" && studyData?.result) {
      let items = [];
      studyData?.result?.average_bias &&
        items.push({
          name: "Average Bias Value",
          value: parseFloat(studyData.result.average_bias).toFixed(4),
        });
      studyData?.result?.bias_critical &&
        items.push({
          name: "Bias Critical",
          value: parseFloat(studyData.result.bias_critical).toFixed(4),
        });
      studyData?.result?.std_deviation &&
        items.push({
          name: "Standard Deviation",
          value: parseFloat(studyData.result.std_deviation).toFixed(4),
        });
      setEvalData(items);
    } else if (studyType === "Gauge BIAS Analysis" && studyData?.result) {
      let items = [];
      studyData?.result?.avg_bias &&
        items.push({
          name: "Average Bias Value",
          value: parseFloat(studyData.result.avg_bias).toFixed(4),
        });
      studyData?.result?.std_deviation &&
        items.push({
          name: "Standard Deviation",
          value: parseFloat(studyData.result.std_deviation).toFixed(4),
        });
      studyData?.result?.std_error_mean &&
        items.push({
          name: "Standard Error Mean",
          value: parseFloat(studyData.result.std_error_mean).toFixed(4),
        });
      setEvalData(items);
    } else if (
      (studyType === "GRR Study (Without operator influence)" ||
        studyType === "GRR Study (With operator influence)") &&
      studyData?.result
    ) {
      let items = [];
      // console.log("Inside pushing card for Eval for GRR Study");
      items.push({
        name: "Equipment Variation (%)",
        value:
          studyData?.result?.ev !== null
            ? parseFloat(studyData.result.ev).toFixed(2)
            : "N/A",
      });
      items.push({
        name: "Part Variation (%)",
        value:
          studyData?.result?.pv !== null
            ? parseFloat(studyData.result.pv).toFixed(2)
            : "N/A",
      });
      items.push({
        name: "Appraiser Variation (%)",
        value:
          studyData?.result?.av !== null
            ? parseFloat(studyData.result.av).toFixed(2)
            : "N/A",
      });
      items.push({
        name: "Interaction",
        value:
          studyData?.result?.int !== null
            ? parseFloat(studyData.result.int).toFixed(2)
            : "N/A",
      });
      setEvalData(items);
    }
  };

  return (
    <React.Fragment>
      {studyType !== "Attribute system analysis: Kappa Method" &&
        studyType !== "Attribute system analysis: Signal Method" && (
          <Paper
            variant="outlined"
            square
            elevation={0}
            style={{ width: "100%" }}
          >
            <Typography
              variant="h6"
              style={{ padding: 8 }}
              className="bg-light"
            >
              {title}
            </Typography>
            {evalData?.length &&
              evalData.map((data, index) => (
                <Table aria-label="simple table" size="small" key={index}>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={{ borderBottom: "none" }}>
                        <Typography color="initial">{data.name}</Typography>
                      </TableCell>
                      <TableCell align="right" style={{ borderBottom: "none" }}>
                        <Typography component="h5" color="textPrimary">
                          <b>{data.value}</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
          </Paper>
        )}
      {studyData && studyType === "Attribute system analysis: Kappa Method" ? (
        <>
          <Box
            display="flex"
            flexWrap="nowrap"
            my={2}
            bgcolor="#fff"
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <Box p={1} mr={5} bgcolor="#fff" style={{ width: "100%" }}>
              {getKappaTable()}
            </Box>
            <Box p={1} ml={5} bgcolor="#fff" style={{ width: "100%" }}>
              {getKappaSummaryTable()}
            </Box>
          </Box>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default CardForEval;
