import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { StatusContext } from "../context/StatusContextProvider";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import changeManagementService from "../services/changeManagementService";
import { AuthContext } from "../context/AuthContextProvider";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function StatusDialog() {
  const {
    open,
    rowId,
    currentStatus,
    entityName,
    setStatus,
    callback,
    reloadGrid,
    comments,
    extraData,
  } = useContext(StatusContext);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { v4: uuidv4 } = require("uuid");
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!open) {
      setComment("");
    }
  }, [open]);

  function changeStatus() {
    setLoading(true);
    callback(comments, extraData, currentStatus === 1 ? 0 : 1, rowId)
      ?.then((res) => {})
      .then(() => {
        setStatus((prev) => ({
          ...prev,
          open: false,
          currentStatus: -1,
          entityName: "",
        }));
        return changeManagementService
          .changeAccessLog({
            id: uuidv4(),
            entity_id: rowId,
            created_by: currentUser.id,
            new_status: currentStatus === 1 ? 0 : 1,
            comments: comment,
          })
          ?.then(() => {});
      })
      .then(() => {
        setLoading(false);
        return reloadGrid()?.then((res) => {});
      })
      .catch(() => {
        setLoading(false);
      });
  }
  return (
    <Dialog open={open} disableBackdropClick={true} fullWidth>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#5c6bc0",
          color: "#fff",
        }}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle id="form-dialog-title">Change Status</DialogTitle>
        <div>
          <Button
            onClick={() =>
              setStatus((prev) => ({
                ...prev,
                open: false,
                callback: () => {},
                entityName: "",
                currentStatus: -1,
              }))
            }
          >
            <CloseRoundedIcon style={{ fill: "#fff" }} />
          </Button>
        </div>
      </div>
      <DialogContent pb={4} style={{ width: "100%" }}>
        <Grid container spacing={4}>
          <Grid item md={6} sm={6} lg={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              fullWidth
              label="Entity Name"
              variant="filled"
              value={entityName ?? ""}
            />
          </Grid>
          <Grid item md={6} sm={6} lg={6}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              disabled
              label="Status"
              variant="filled"
              value={currentStatus === 1 ? "ACTIVE" : "DEACTIVE"}
            />
          </Grid>
          <Grid item md={12} sm={12} lg={12}>
            <Grid md={12} sm={12} lg={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Comments"
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            item
            md={12}
            sm={12}
            lg={12}
            style={{ textAlign: "center", marginBottom: 10 }}
          >
            <Button
              onClick={changeStatus}
              variant="contained"
              style={{ backgroundColor: "#5c6bc0", color: "#fff" }}
            >
              Change Status
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
export default StatusDialog;
