import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Divider,
  Grid,
  Toolbar,
  Typography, Chip
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../../../../components/CommonGrid";
import { DGInput } from "../../../../../../components/shared/DGInput";
import { DGSelect } from "../../../../../../components/shared/DGSelect";
import initiateStudyService from "../../../../../../services/initiateStudyService";

export function Grr1Studies({
  gaugeCharacter = {},
  studyId = -1,
  usersList = [],
  appraiser,
  trails,
  samples,
  appraiserNames,
  selectedUser,
  msaCsr,
  rows,
  columns,
  setAppraiser = () => { },
  setTrails = () => { },
  setSamples = () => { },
  setAppraiserNames = () => { },
  setSelectedUser = () => { },
  setMsaCsr = () => { },
  onGridReady = () => { },
  setRows = () => { },
  setColumns = () => { },
  isGridGenerated = false,
  setGridGenerated = () => { },
  characterId = "",
  handleNext = () => { },
  closeForm = () => { },
}) {
  // const [appraiserList, setAppraiserList] = useState([]);

  const [expanded, setExpanded] = useState(-1);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (!isGridGenerated) {
      generateGrid(trails, appraiser, samples);
    }
  }, []);

  useEffect(() => {
    if (expanded === 2) {
      generateGrid(Number(trails), Number(appraiser), Number(samples));
    }
  }, [expanded]);

  useEffect(() => {
    if (gaugeCharacter && !msaCsr) {
      getMsaCsr(characterId, studyId);
    }
  }, [gaugeCharacter]);

  function getMsaCsr(characterId, studiesId) {
    initiateStudyService.getMSACSR(characterId, studiesId).then((res) => {
      let data = res?.data[0];
      if (data) {
        setMsaCsr(data);
      }
    });
  }

  const generateGrid = async (trialsCount, appraisersCount, samplesCount) => {
    const array = [];
    let columns = [];
    let tab = trialsCount * appraisersCount; //tab stores the total number of columns that should be generated
    for (let i = 0; i <= appraiserNames.length - 1; i++) {
      const childrens = [];
      for (let j = 0; j < trialsCount; j++) {
        childrens.push({
          field: `${appraiserNames[i]}-${i}-Trial ${j + 1}`,
          headerName: `Trial ${j + 1}`,
          minWidth: 150,
          editable: true,
          resizable: true,
          cellStyle: { border: "1px solid #F2F2F2" },
          valueSetter: (params) => {
            if (
              Number(params.newValue) > Number(msaCsr?.llimit_) &&
              Number(params.newValue) < Number(msaCsr?.ulimit_)
            ) {
              params.data[`${appraiserNames[i]}-${i}-Trial ${j + 1}`] = Number(
                params.newValue
              );
              return true;
            }
            return false;
          },
        });
      }
      columns.push({
        headerName: appraiserNames[i],
        childrens: childrens,
      });
    }
    for (let i = 1; i <= samplesCount; i++) {
      let x = {};
      let tab = trialsCount * appraisersCount;
      for (let j = 1; j <= tab; j++) {
        x["trial" + j] = "";
      }
      array.push(x);
    }
    let num = -1;
    const data = [];
    if (!isGridGenerated) {
      setRows(array);
    }
    columns.map((value, index) => {
      if (index % trialsCount === 0) {
        ++num;
      }
      data.push({
        headerName: value.headerName,
        field: value.headerName,
        children: value.childrens,
        cellRenderer: "agGroupCellRenderer",
      });
    });
    data.unshift({
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      width: 100,
      resizable: true,
    });
    setColumns(data);
    setGridGenerated(true);
  };

  function processCellFromClipboard(params) {
    if (
      Number(params.value) > Number(msaCsr?.llimit_) &&
      Number(params.value) < Number(msaCsr?.ulimit_)
    ) {
      return Number(params.value);
    }
    return "";
  }

  return (
    <>

      <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Basic Details</Typography>
      <Grid item xs={12}><Divider style={{ width: '75%', margin: '2px auto' }} /></Grid>
      <Grid container spacing={2}>
        <Grid item md={2} sm={2}>
          <DGInput
            type="number"
            label="Number of Appraisers"
            value={appraiser}
            error={Number(appraiser) < msaCsr?.grr1_min_appraiser}
            onChange={setAppraiser}
          />
        </Grid>
        <Grid item md={2} sm={2}>
          <DGInput
            type="number"
            label="Number of Trails"
            value={trails}
            error={Number(trails) < msaCsr?.grr1_min_trials}
            onChange={setTrails}
          />
        </Grid>
        <Grid item md={2} sm={2}>
          <DGInput
            type="number"
            label="Number of Samples"
            value={samples}
            error={Number(samples) < msaCsr?.grr1_min_sample}
            onChange={setSamples}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGSelect
            label="Approver"
            options={usersList}
            value={selectedUser}
            onChange={setSelectedUser}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}><Divider style={{ width: '75%', margin: '12px auto' }} /></Grid>



      <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Appraiser</Typography>
      <Grid item xs={12}><Divider style={{ width: '75%', margin: '2px auto' }} /></Grid>
      <Grid container spacing={2}>
        {appraiserNames &&
          appraiserNames.map((exp, i) => (
            <Grid item md={4} sm={4}>
              <DGInput
                label="Name of Appraisers"
                value={appraiserNames[i]}
                onChange={(val) => {
                  appraiserNames[i] = val;
                  setAppraiserNames([...appraiserNames]);
                }}
              />
            </Grid>
          ))}
      </Grid>
      <Grid item xs={12}><Divider style={{ width: '75%', margin: '12px auto' }} /></Grid>

      <Accordion
        style={{ width: "100%" }}
        square
        expanded={expanded === 2}
        onChange={handleChange(2)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Values</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} lg={12}>
              <CommonGrid
                rows={rows}
                columns={columns}
                onGridReady={onGridReady}
                processCellFromClipboard={processCellFromClipboard}
                needExport={false}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleNext()}
          >
            NEXT
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );

}
