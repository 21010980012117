import { Grid } from "@material-ui/core";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AuthContext } from "../../../context/AuthContextProvider";
import gaugeService from "../../../services/gaugeService";
import piService from "../../../services/piService";
import plantService from "../../../services/plantService";
import { getErrors, hasErrors } from "../../../services/commonService";
import moment from "moment";

export function SerialNumberForm({
  Footer = () => <></>,
  componentProps = {},
  closeForm = () => { },
}) {
  const { v4: uuidv4 } = require("uuid");
  const [gauges, setGauges] = useState([]);
  const [piAgencyList, setPIAgencyList] = useState([]);
  const [gaugeUsageList, setGaugeUsageList] = useState([]);
  const [gaugeStatusList, setGaugeStatusList] = useState([]);
  const [piFrequencies, setPiFrequencies] = useState([]);
  const [plantsList, setPlantsList] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [serialNumber, setSerialNumber] = useState("");
  const [plant, setSelectedPlant] = useState("");
  const [calibdate, setCalibDate] = useState(new Date());
  const [calibdue, setCalibDue] = useState(new Date());
  const [seletedGauge, setSeletctedGauge] = useState({
    id: "",
    name: "None",
    number: "",
  });
  const [seletedCalib, setSeletctedCalib] = useState({
    id: "",
    name: "None",
  });
  const [selectedPi, setSeletctedPi] = useState({ id: "", name: "None" });
  const [seletedGaugeUsage, setSeletctedGaugeUsage] = useState({
    id: "",
    name: "None",
  });
  const [seletedStatus, setSeletctedStatus] = useState({
    id: "",
    name: "None",
  });
  const [location, setLocation] = useState("");
  const [comments, setComments] = useState("");
  const [errorModel, setErrorModel] = useState({
    serialNumber: false,
    plant: false,
    calibdate: false,
    seletedGauge: false,
    seletedCalib: false,
    selectedPi: false,
    seletedGaugeUsage: false,
    seletedStatus: false,
    calibDue: false,
  });

  useEffect(() => {
    let data = componentProps?.gaugeItem;
    gaugeService.getGauges(currentUser.client_id).then((res) => {
      setGauges(res?.data?.data ?? []);
      if (data) {
        setSeletctedGauge(data?.guage);
      }
    });

    gaugeService.getGaugeUsages().then((res) => {
      setGaugeUsageList(res?.data?.data ?? []);
      if (data) {
        setSeletctedGaugeUsage(
          res?.data?.data?.find((exp) => exp?.id === data?.guage_usage?.id)
        );
      }
    });

    gaugeService.getGaugeStatuses().then((res) => {
      setGaugeStatusList(res?.data?.data ?? []);
      if (data) {
        setSeletctedStatus(
          res?.data?.data?.find((exp) => exp?.id === data?.guage_status_id)
        );
      }
    });

    piService.getActivePIlist(
      currentUser.client_id,
      10)
      .then((res) => {
        setPIAgencyList(res?.data ?? []);
        if (data) {
          setSeletctedPi(
            res?.data?.find((exp) => exp?.id === data?.pi_agency_id)
          );
        }
      });
    piService.getAllPIFrequencies().then((res) => {
      setPiFrequencies(res?.data?.data ?? []);
      if (data) {
        setSeletctedCalib(
          res?.data?.data.find((exp) => exp?.id === data?.pi_frequency_id)
        );
      }
    });
    plantService.getPlants(currentUser.client_id).then((res) => {
      setPlantsList(res?.data?.data ?? []);
      if (data) {
        setSelectedPlant(
          res?.data?.data?.find((exp) => exp?.id === data?.plant_id)
        );
      }
    });

    if (data) {
      setSerialNumber(data?.serial_number);
      setCalibDate(data?.pi_last_date);
      setSeletctedCalib(data?.pi_frequency);
      setLocation(data?.location);
      setComments(data?.comments);
      setCalibDue(data?.pi_due_date);
    }
  }, []);

  function saveForm() {
    const requiredPayload = {
      serial_number: serialNumber,
      pi_agency_id: selectedPi?.id,
      pi_frequency_id: seletedCalib?.id,
      pi_last_date: moment(calibdate).format("YYYY/MM/DD"),
      guage_status_id: seletedStatus.id,
      client_id: currentUser?.client_id,
      plant_id: plant?.id,
      guage_id: seletedGauge?.id,
      pi_due_date: moment(calibdue).format("YYYY/MM/DD"),
    };
    if (hasErrors(requiredPayload)) {
      setErrorModel(
        getErrors({
          serialNumber,
          plant: plant?.id,
          calibdate,
          seletedGauge: seletedGauge?.id,
          seletedCalib: seletedCalib?.id,
          selectedPi: selectedPi?.id,
          seletedGaugeUsage: seletedGaugeUsage?.id,
          seletedStatus: seletedStatus?.id,
          calibdue
        })
      );
      return;
    } else {
      setErrorModel(
        getErrors({
          serialNumber,
          plant: plant?.id,
          calibdate,
          seletedGauge: seletedGauge?.id,
          seletedCalib: seletedCalib?.id,
          selectedPi: selectedPi?.id,
          seletedGaugeUsage: seletedGaugeUsage?.id,
          seletedStatus: seletedStatus?.id,
          calibdue
        })
      );
    }

    const optPayload = {
      comments: comments,
      location: location,
    };

    if (ACTION_TYPE.edit) {
      requiredPayload["id"] = componentProps?.gaugeItem?.id;
      gaugeService
        .updateGaugeItem(componentProps?.gaugeItem?.id, {
          ...requiredPayload,
          ...optPayload,
        })
        .then((res) => {
          closeForm();
        });
    } else if (ACTION_TYPE.add) {
      requiredPayload["id"] = uuidv4();
      gaugeService
        ?.createGaugeItem({ ...requiredPayload, ...optPayload })
        .then((res) => {
          closeForm();
        });
    }
  }

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            label="Serial Number"
            value={serialNumber}
            onChange={setSerialNumber}
            required
            error={errorModel.serialNumber}
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGSelect
            label="Plant"
            value={plant}
            onChange={setSelectedPlant}
            options={plantsList}
            getOptionLabel={(opt) => opt?.name}
            required
            error={errorModel.plant}
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGSelect
            label="Gauge Usage"
            value={seletedGaugeUsage}
            onChange={setSeletctedGaugeUsage}
            options={gaugeUsageList}
            getOptionLabel={(opt) => opt?.usage}
            required
            error={errorModel.seletedGaugeUsage}
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGSelect
            label="Calibration Frequency"
            value={seletedCalib}
            onChange={setSeletctedCalib}
            options={piFrequencies}
            getOptionLabel={(opt) => opt?.name}
            required
            error={errorModel.seletedCalib}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            label="PI Agency"
            value={selectedPi}
            onChange={setSeletctedPi}
            options={piAgencyList}
            getOptionLabel={(opt) => opt?.NABL_Agency}
            error={errorModel.selectedPi}
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            label="Gauge"
            value={seletedGauge}
            onChange={setSeletctedGauge}
            options={gauges}
            getOptionLabel={(opt) => `${opt?.number} - ${opt?.name}`}
            error={errorModel.seletedGauge}
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            label="Gauge Status"
            value={seletedStatus}
            onChange={setSeletctedStatus}
            options={gaugeStatusList}
            getOptionLabel={(opt) => opt?.status}
            error={errorModel.seletedStatus}
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGDateInput
            type="date"
            label="Last Calibration Performed Date"
            value={calibdate}
            onChange={setCalibDate}
            error={errorModel.calibdate}
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGDateInput
            type="date"
            label="Calibration Due"
            value={calibdue}
            onChange={setCalibDue}
            error={errorModel.calibDue}
            required
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            label="Location"
            value={location}
            onChange={setLocation}
            error={errorModel.location}
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput label="Comments" value={comments} onChange={setComments} />
        </Grid>
      </Grid>
      <Footer onSave={saveForm} />
    </>
  );
}
