import { Divider, Grid, Link, Typography, TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import { useFormik } from "formik";
import { DGInput } from "../../../components/shared/DGInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { object, array, ref } from "yup";
import cuttingToolService from "../../../services/cuttingToolService";
import { DGSelect } from "../../../components/shared/DGSelect";
import errorLog from "../../../services/errorLog";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import { blue, brown } from "@material-ui/core/colors";



import { format } from "date-fns";



export function CuttingToolRefurbishApprove({
    componentProps = null,
    closeForm = () => { },
    Footer = () => null,
    setHeaderButtons = () => { },

}) {

    const { currentUser } = useContext(AuthContext);

    let form = useFormik({
        initialValues: {
            approveDate: new Date(),
            reportNumber: "",
            ctool_life: "",
            comments: "",
            ctoolStatusSelected: { ctool_status_id: 101, ctool_status: "Active" },
            ctoolStatusOptions: [
                //  { ctool_status_id: 101, ctool_status: "Active" },
                { ctool_status_id: 102, ctool_status: "Quarantine" },
                { ctool_status_id: 103, ctool_status: "Scrapped" },
            ],
        },
        validationSchema: object().shape({
            gridData: array().min(1)
        }),
        onSubmit: saveForm
    });

    useEffect(() => {
        setHeaderButtons([
            () => (
                <>
                    {componentProps?.report_link && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Link
                                href={componentProps?.report_link}
                                target="_blank"
                                rel="noopener"
                                style={{ textDecoration: 'none' }}
                            >
                                <Typography variant="body2" style={{ marginRight: '2px' }}>
                                    Inspection Report
                                </Typography>
                            </Link>
                        </div>
                    )}
                </>
            ),
        ]);

    }, []);

    async function saveForm() {
        let payload;

        payload = {
            refurbish_approved_by: currentUser?.id,
            refurbish_approved: format(form.values.approveDate, "yyyy-MM-dd"),
            ctool_status_id: form.values.ctoolStatusSelected?.ctool_status_id,
            updated_at: format(new Date(), "yyyy-MM-dd"),
            ctoolnumber_newlife: form.values.ctool_life ? parseInt(form.values.ctool_life, 10) : null

        };


        cuttingToolService.updateCtoolStatusChange(componentProps?.id, payload)

        let payload2 = {
            ctool_status: form.values.ctoolStatusSelected?.ctool_status_id,
            updated_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        };

        cuttingToolService.modifyCToolNumber(componentProps.ctool_number_id, payload2);

        closeForm()

    }

    return (

        <Grid container spacing={4}>
            <Grid item md={12} sm={12} lg={12}
                style={{
                    paddingBottom: '20px',
                }}>
                <DGInput
                    label="Vendor Name"
                    value={componentProps?.vendor_name}
                />
            </Grid>


            <Grid item md={6} sm={6} lg={6}
                style={{ paddingBottom: "10px" }}>
                <DGInput

                    label="Intiated By"
                    value={componentProps?.intiater_name}
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}
                style={{ paddingBottom: "10px" }}>
                <DGDateTimePicker
                    label="Initiated Date"
                    value={componentProps?.created_date}
                    format="dd/MM/yyyy"
                />
            </Grid>

            <Divider
                variant="middle"
                style={{
                    backgroundColor: blue[900],
                    width: '100%',
                    height: '1px',
                    margin: '3px',

                }}
            />

            <Grid item md={6} sm={6} lg={6}
                style={{ paddingTop: "10px" }}>
                <DGInput

                    label="Refurbished By"
                    value={componentProps?.refurbish_initiated_by}
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}
                style={{ paddingTop: "10px" }}>
                <DGDateTimePicker
                    label="Refurbished Date"
                    value={componentProps?.refurbish_initiated}
                    format="dd/MM/yyyy"
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}
                style={{ paddingBottom: "10px" }}>
                <DGInput

                    label="Refurbished By"
                    value={componentProps?.refurbish_remarks}
                />
            </Grid>

            <Divider
                variant="middle"
                style={{
                    backgroundColor: blue[900],
                    width: '100%',
                    height: '2px',
                    margin: '3px',

                }}
            />

            <Grid item xs={6} style={{ marginTop: "10px" }}>
                <DGDateInput
                    required
                    variant="outlined"
                    label="Approve Date"
                    value={form.values.approveDate}
                    onChange={form.handleChange}
                    error={form.touched.calibratedDate && form.errors.calibratedDate}
                />
            </Grid>

            <Grid item xs={6} style={{ marginTop: "10px" }}>
                <DGSelect
                    label="Cutting tool Status"
                    id="ctoolStatusSelected"
                    options={form.values.ctoolStatusOptions}
                    value={form.values.ctoolStatusSelected}
                    onChange={(value) => {
                        form.setFieldValue("ctoolStatusSelected", value);
                    }}
                    getOptionLabel={(option) => option?.ctool_status ?? ""}
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <TextareaAutosize
                    aria-label="comments"
                    placeholder="Add your comments here"
                    minRows={6}
                    className="border border-2"
                    style={{
                        width: "100%",
                        borderColor: "#bbb",
                        borderRadius: "5px",
                        height: '90px',

                    }}
                    onChange={(e) => {
                        form.setFieldValue("comments", e.target.value);
                    }}
                />
            </Grid>

            {/*             <Grid item xs={12}>
                <DGInput
                    label="Tool Life"
                    id="ctool_life"
                    options={form.values.ctool_life}
                    value={form.values.ctool_life}
                    onChange={(value) => {
                        form.setFieldValue("ctool_life", value);
                    }}
                />
            </Grid> */}

            <Footer onSave={form.handleSubmit} />
        </Grid>
    )



}