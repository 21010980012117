import React from "react";

const ImageLinkCellRenderer = ({ imageURL, altText }) => {
    return (
        <img src={imageURL} alt={altText} style={{ width: '100%', height: '100%' }} />
    );
};

export default ImageLinkCellRenderer;


