import http from "./httpService";


//Gauge PI Management




const gaugeDash = async (data) => {
  return http.get("/dashboard-gauge", { params: { ...data }, });
};

const ccmDash = async (data) => {
  return http.get("/dashboard-msa", { params: { ...data } })
}


const ccmDashboard = async (data) => {
  return http.get("/dashboard-ccm", { params: { ...data } })
}

const assetDashboard = async (data) => {
  return http.get("/dashboard-asset", { params: { ...data } })
}

const dashPIDetails = async (data) => {
  return http.get("/production-inspection-dashdetails", { params: { ...data } })
}

const dashPIGraph = async (data) => {
  return http.get("/production-inspection-dashgraph", { params: { ...data } })
}

const dashPIChar = async (data) => {
  return http.get("/production-inspection-dashchar", { params: { ...data } })
}

export default {
  gaugeDash,
  ccmDash,
  ccmDashboard,
  assetDashboard,
  dashPIDetails,
  dashPIGraph,
  dashPIChar

}