import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

export function DGCheckbox({
  label = "",
  id = "",
  onChange = () => { },
  value = false,
  disabled = ""
}) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            name={label}
            id={id}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            disabled={disabled}

          />
        }
        label={label}
      />
    </>
  );
}
