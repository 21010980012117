import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import store from "../../redux/store";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    // zIndex: theme.zIndex.drawer+1,
    color: "#fff",
    zIndex: 12000,
  },
}));
export function GlobalSpinner() {
  const classes = useStyles();
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    let unsubscribe = store.subscribe(() => {
      let count = store.getState()?.globalReducer?.reqCount;
      if (count > 0 && !spinner) {
        setSpinner(true);
      }
      if (count <= 0) {
        setSpinner(false);
      }
    });
    return unsubscribe;
  }, []);
  return (
    <Backdrop className={classes.backdrop} open={spinner}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
