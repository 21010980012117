import http from "./httpService";


const getAutomatedInspectionTable = (data) => {
    return http.get("/automated-inspection-table", { params: { ...data } })
};

const getAutomatedInspectionDetail = (data) => {
    return http.get("/automated-inspection-details", { params: { ...data } })
};

const createAutomatedInspection = (data) => {
    return http.post("/automated-inspection-initiate", data)
};




export default {

    getAutomatedInspectionTable,
    getAutomatedInspectionDetail,
    createAutomatedInspection


}