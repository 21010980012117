import http from "./httpService";

const intiateSPCeval = async (data) => {
  return http.post("/initiate-spccl", data);
};

const recordSPCeval = async (data) => {
  return http.post("/initiate-spccl", data);
};

//"activity":"record","spc_limits_id","approver_id"

const getSPCevalmethod = async () => {
  return http.get("/studies", {
    params: {
      "id[$gte]": 1500,
      "id[$lte]": 1501,
    },
  });
};

const getmaskevalmethod = () => {
  return http.get("/studies", {
    params: {
      "id[$gte]": 25,
      "id[$lte]": 26,
    },
  });
};

// below is for SPC Limits Eval //

const deletespcrecord = (id) => {
  return http.delete(`/spc-limits-eval/${id}`);
};

const updateSPCEval = async (id, newStatus) => {
  return http.patch(`/spc-limits-eval/${id}`, newStatus);
};

const getSPCEvaltable = (part_id) => {
  return http.get("/spc-limits-evaltable", {
    params: {
      part_id: part_id,
      //"result_id[$ne]": "",
    },
  });
};

const getSPCCL = async (characterId) => {
  return http.get("/spc-limits-evaltable", {
    params: {
      character_id: characterId,
    },
  });
};

const getSPCEvalrecord = (id) => {
  return http.get(`/spc-limits-eval/${id}`);
};

const getSPCPlotdata1 = async (spc_input_id, spc_eval_id) => {
  return http.get("/spc-eval-plot1", {
    params: {
      spc_input_id: spc_input_id,
      spc_eval_id: spc_eval_id
    },
  });
};

const getSPCPlotdata2 = async (spc_input_id, spc_eval_id) => {
  return http.get("/spc-eval-plot2", {
    params: {
      spc_input_id: spc_input_id,
      spc_eval_id: spc_eval_id
    },
  });
};



const getSPCPltlist = async (data) => {
  return http.get("/spc-plot-select", { params: { ...data } })
}

const createSPClimitseval = async (data) => {
  return http.post("/spc-limits-eval", data);
};

const createSPClimitsResult = async (data) => {
  return http.post("/spc-limits-result", data);
};

const patchSPClimitseval = (id, data) => {
  return http.patch(`/spc-limits-eval/${id}`, data);
}

// SPC PLAN //

const getSPCPlanInput = async (data) => {
  return http.get("/spc-input-select", { params: { ...data } });
};

const createSPCPlan = async (data) => {
  return http.post("/spc-input-new", data);
};
/* 
const getSPCInputTable = async (part_id) => {
  return http.get("/spc-input-table", {
    params: {
      part_id: part_id,
    },
  });
}; */


const getSPCInputTable = async (data) => {
  return http.get("/spc-input-table", { params: { ...data } });
};


const updateSPCPlan = async (id, newStatus) => {
  return http.patch(`/spc-input/${id}`, newStatus);
};

const getSPCPlan = async (partId) => {
  return http.post("spc-input-select", {
    params: {
      part_id: partId,
    },
  });
};

const getASPCPlan = async (partId) => {
  return http.get("/spc-input", {
    params: {
      part_id: partId,
      enabled: 1,
    },
  });
};

const getSPCtable = async (data) => {
  return http.get("/spc-input", {
    params: {
      ...data,
    },
  });
};


const getSPCInputForEntry = async (data) => {
  return http.get("/spc-input-spcentry", { params: data }
  )
};
const createSpcinput = async (data) => {
  return http.post("/spc-input", data);
};

// below is for SPC //

const getSPCSelect = async (data) => {
  return http.get("/spc-eval-select", { params: data });
};

const getSPCTable = async (data) => {
  return http.get("/spc-eval-table", { params: data });
};

const createSPCdata = async (data) => {
  return http.post("/spc-eval-out", data);
};

/*
const getSPCChar = async (character_id) => {
  return http.get("/spc-eval", {
    params: {
      character_id: character_id,
    },
  });
};
*/

const getSPCChar = async (data) => {
  return http.get("/spc-eval-table-char", { params: data });
}

const updateSPC = async (id, newStatus) => {
  return http.patch(`/spc-eval/${id}`, newStatus);
};

const getSPC = async (id) => {
  return http.get(`/spc-eval-result`, { params: { spc_eval: id } });
};

const updateSPCDeviationMgmt = async (id, data) => {
  return http.patch(`/spc-eval/${id}`, {
    params: {
      out_actions: data,
    },
  });
};

const updateSPCICA = async (data) => {
  return http.post("/spc-out-ica", data);
};

const getSPCEval = async (spc_eval_id) => {
  return http.get(`/spc-result`, {
    params: {
      spc_eval_id: spc_eval_id,
    },
  });
};

const getSPCCLId = async (spc_cl_eval) => {
  return http.get(`/spc-limits-id`, {
    params: {
      spc_cl_eval: spc_cl_eval,
    },
  });
};

const UpdateMSA = async (spc_cl_eval) => {
  return http.get(`/spc-limits-id`, {
    params: {
      spc_cl_eval: spc_cl_eval,
    },
  });
};


const getICAStd = async () => {
  return http.get("/spc-action-select1")
}

const get5ms = async () => {
  return http.get("/spc-action-select2")
}

const getActionListChar = async (character_id) => {
  return http.get(`/spc-eval-reaction-table`,
    {
      params: { character_id: character_id }
    })
}

const getSPCEvalActionView = async (spc_eval_id) => {
  return http.get(`/spc-action-view`,
    {
      params: { spc_eval_id: spc_eval_id }
    })
}

const updateSPCAction = async (data) => {
  return http.post(`/spc-action-update`, data)
}



const recordSPCAction = async (data) => {
  return http.post(`/spc-action-record`, data)
}


const getMaxSPCEvalID = async (client_id) => {
  return http.get(`/spc-limits-maxid`, {
    params: {
      client_id: client_id
    }
  })
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getSPCevalmethod,
  intiateSPCeval,
  createSPClimitseval,
  getmaskevalmethod,
  createSpcinput,
  deletespcrecord,
  getSPCPlotdata1,
  getSPCPlotdata2,
  createSPCPlan,
  getSPCPlan,
  getSPCEvalrecord,
  getASPCPlan,
  getSPCCL,
  getSPCEvaltable,
  updateSPCEval,
  getSPCPlanInput,
  getSPCtable,
  updateSPCPlan,
  getSPCInputTable,
  getSPCSelect,
  getSPCTable,
  createSPCdata,
  getSPCChar,
  updateSPC,
  getSPC,
  updateSPCDeviationMgmt,
  getSPCPltlist,
  updateSPCICA,
  getSPCEval,
  getSPCCLId,
  UpdateMSA,
  recordSPCeval,
  getICAStd,
  get5ms,
  getActionListChar,
  getSPCEvalActionView,
  updateSPCAction,
  recordSPCAction,
  createSPClimitsResult,
  patchSPClimitseval,
  getMaxSPCEvalID,
  getSPCInputForEntry
};
