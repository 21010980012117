import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../components/CommonGrid';
import { AuthContext } from '../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { StatusContext } from "../../context/StatusContextProvider";
import piService from '../../services/piService';
import { PlantContext } from '../../context/PlantContextProvider';
import gaugeService from '../../services/gaugeService';

export function GaugeOverDueList({
    componentProps = null,
}) {

    const [rows, setRows] = useState([]);
    const [rows1, setRows1] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { setStatus } = useContext(StatusContext);
    const { globalPlant } = useContext(PlantContext);


    function reloadGridData() {
        piService.getCalibOverdue(
            currentUser.client_id,
            24,
            globalPlant.globalPlant.id
        )
            .then((res) => {
                setRows(res?.data ?? []);
            });
    }

    function reloadGridData1() {
        gaugeService.getGaugeIssueOverdueList({
            client_id: currentUser.client_id,
            plant_id: globalPlant.globalPlant.id
        })
            .then((res) => {
                setRows1(res?.data ?? []);
            });
    }


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            reloadGrid: () => reloadGridData1(),
        }));
        reloadGridData();
        reloadGridData1();
    }, []);


    const columns = [{
        field: "serial_number",
        headerName: "SI No",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 75,
        sorting: true,
    },
    {
        field: "serial_number",
        headerName: "Gauge",
        minWidth: 200,
        resizable: true,
        sorting: true,
    },
    {
        field: "location",
        headerName: "Location",
        minWidth: 100,
        resizable: true,
        sorting: true,
    },
    {
        field: "Due in Days",
        headerName: "Due In Days",
        sortable: true,
        maxWidth: 100,
        floatingFilter: true,
        resizable: true,
    },

    {
        field: "family_name",
        headerName: "Gauge Name",
        sortable: true,
        minWidth: 200,
        floatingFilter: true,
        resizable: true,
        valueGetter: (params) => {
            return params?.data?.family_number + " - " + params?.data?.family_name;
        },
    },


    ]

    return (
        <>
            <Grid item md={12} sm={12} lg={12}>

                <CommonGrid
                    rows={rows1}
                    columns={columns}
                    gridheight={250}
                    tableName={"Issued gauges Overdue"}

                />

            </Grid>
            <Grid item md={12} sm={12} lg={12}>

                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={250}
                    tableName={"Total Overdue Gauges"}

                />
            </Grid>

        </>
    )

}

