import {
  Box,
  Button as MuiButton,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  TextareaAutosize as MuiTextareaAutosize,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import styled from "styled-components/macro";
import * as Yup from "yup";
import dateService from "../../services/dateService";
import piService from "../../services/piService";
import uploadService from "../../services/uploadService";
import gaugeService from "../../services/gaugeService";
import { AuthContext } from "../../context/AuthContextProvider";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { ModeCommentSharp } from "@material-ui/icons";



const UploadCertificateDialog = ({
  addUploadCertificateDialog,
  setAddUploadCertificateDialog,
  piHistory,
  onSuccess
}) => {
  // console.log('vendorPiAgency', piHistory);
  const { v4: uuidv4 } = require("uuid");
  const Divider = styled(MuiDivider)(spacing);
  const Button = styled(MuiButton)(spacing);
  const Alert = styled(MuiAlert)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  const TextareaAutosize = styled(MuiTextareaAutosize)(spacing);
  const Spacer = styled.div(spacing);
  const { currentUser } = useContext(AuthContext);
  const [uploadedCertificate, setUploadedCertificate] = useState();
  const timeOut = (time) => new Promise((res) => setTimeout(res, time));

  const initialValues = {
    phoneNumber: "",
    comments: ""
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.number().required("Required"),
    comments: Yup.string().required("Required")
  });

  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    let uploadResponse = null;
    try {
      await timeOut(1500);
      setStatus({ sent: true });
      setSubmitting(false);
      // console.log("values", values);
      // console.log("🚀  -> file: PICertificateDialog.js -> line 90 -> PICertificateDialog -> values", uploadedCertificate);
      if (uploadedCertificate) {
        uploadResponse = await uploadService.uploadFile(uploadedCertificate);
        // console.log("Uploading File Response", uploadResponse);
      }

      if (uploadResponse && uploadResponse.data && uploadResponse.data.files && uploadResponse.data.files.length) {
        if (piHistory.gauge_item) {
          let tempDate = values.calibrated_date
            ? new Date(values.calibrated_date).setDate(new Date(values.calibrated_date).getDate() + parseInt(piHistory.gauge_item.pi_frequency.interval_in_days))
            : new Date().setDate(new Date().getDate() + parseInt(piHistory?.gauge_item?.pi_frequency.interval_in_days));
          const updateData = {
            pi_last_date: values.calibrated_date ? dateService.getDateInSqlFormat(values.calibrated_date) : dateService.getDateInSqlFormat(new Date()),
            pi_due_date: dateService.getDateInSqlFormat(tempDate),
            guage_status_id: 3
          };
          const response2 = await gaugeService.updateGaugeItem(piHistory?.gauge_item?.id, updateData);
          // console.log('updating gauge item response', response2);
        }

        const obj = {
          id: uuidv4(),
          gauge_id: piHistory.piRequestGaugeId,
          created_date: dateService.getDateInSqlFormat(new Date()),
          status: 'pending',
          certificate_link: uploadResponse.data.files[0].location,
          pi_request_id: piHistory.id,
          calibration_comments: values.comments
        }
        const response = await piService.createPIResult(obj);
        // console.log("Creating PI Result response", response);
        if (response && response.data) {
          onSuccess(true);
          resetForm();
          setAddUploadCertificateDialog(false);
        } 
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
      // console.log('error in upload certificate/create pi result');
    }
  };

  return (
    <>
      {currentUser && (
        <Dialog
          open={addUploadCertificateDialog}
          onClose={() => setAddUploadCertificateDialog(false)}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullWidth
        >
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#0d51ca', color: '#fff'}}>
            <DialogTitle id="form-dialog-title">
              Upload the Calibration Result Certificate below
            </DialogTitle>
            <div>
              <Button onClick={() => setAddUploadCertificateDialog(false)}>
                <CloseRoundedIcon style={{ fill: '#fff' }} />
              </Button>
            </div>
          </div>
          <DialogContent pb={4}>
            <Spacer my={6} />
            <Formik
              initialValues={{ id: '', calibrated_date: null, comments: '' }}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue,
              }) => (
                <Card mb={6}>
                  {status && status.sent && (
                    <Alert severity="success" my={3}>
                      Your data has been submitted successfully!
                    </Alert>
                  )}

                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={6}>
                        <Grid item md={12}>
                          <DropzoneArea
                            showFileNamesInPreview={true}
                            showFileNames={true}
                            showAlerts={true}
                            onChange={(file) => setUploadedCertificate(file) }
                            acceptedFiles={['image/*', 'application/*']}
                            maxSize={10971520} 
  
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            name="calibrated_date"
                            required
                            label="Calibrated Date"
                            value={values.calibrated_date ? values.calibrated_date : ""}
                            error={Boolean(
                              touched.calibrated_date && errors.calibrated_date
                            )}
                            fullWidth
                            helperText={
                              touched.calibrated_date && errors.calibrated_date
                            }
                            onBlur={handleBlur}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            type="date"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12}>
                          <TextareaAutosize
                            required
                            aria-label="comments"
                            placeholder="Add your comments here"
                            minRows={6}
                            style={{ width: '100%', borderColor: '#bbb', borderRadius: '5px' }}
                            onChange={(e) => setFieldValue('comments', e.target.value)}
                          />
                        </Grid>
                      </Grid>
                      <Divider my={6} />
                      <div className="d-flex justify-content-end">
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          mr={3}
                          mb={3}
                          position="right"
                          onClick={() => setAddUploadCertificateDialog(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          mb={3}
                          position="right"
                        >
                          Submit
                        </Button>
                      </div>
                    </form>
                  )}
                </Card>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default UploadCertificateDialog;
