import React, { useState, createContext } from "react";

const StatusContext = createContext({
  rowId: "",
  entityName: "",
  open: false,
  currentStatus: -1,
  setStatus: () => {},
  callback: () => {},
  extraData: "",
});

export const StatusContextProvider = ({ children }) => {
  const [status, setStatus] = useState({
    rowId: "",
    entityName: "",
    open: false,
    currentStatus: -1,
    setStatus: () => {},
    callback: () => {},
    extraData: "",
  });

  return (
    <StatusContext.Provider value={{...status,setStatus}}>
      {children}
    </StatusContext.Provider>
  );
};

export default StatusContextProvider;
export { StatusContext };
