import { Typography } from "@material-ui/core";
import react from "react";
import { useEffect } from "react";
import { useState } from "react";
export function ApprovaInfo({
  approval_info = {
    initiated_by: "",
    approved_by: "",
  },
}) {
  return (
    <>
      <div className="col-6 align-self-center text-center">
        <Typography variant="h5" gutterBottom align="center">
          Initiated By : {approval_info?.initiated_by}
        </Typography>
      </div>
      <div className="col-6 align-self-center text-center">
        <Typography variant="h5" gutterBottom align="center">
          Approved By : {approval_info?.approved_by}
        </Typography>
      </div>
    </>
  );
}
