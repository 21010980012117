import React, { useState, useEffect } from "react";
import { Card, Paper } from "@material-ui/core";
import { JsonToTable } from "react-json-to-table";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import TableData from "../dataSet/tableData.json";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

const CardsForTable = ({ title, studyType, studyData, forNextPage }) => {
  const classes = useStyles();
  const tableForGaugeCapability = () => {
    const values = JSON.parse(studyData?.study_input?.input_values);
    if (values && values.length) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          p={0}
          bgcolor="#fff"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Box pr={1} pl={1} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index < 10) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={1} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index >= 10 && index < 20) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={1} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index >= 20 && index < 30) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={1} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index >= 30 && index < 40) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={1} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index >= 40 && index < 50) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  const tableForBias = () => {
    const values = JSON.parse(studyData?.study_input?.input_values);
    // console.log("Study data", values);
    if (values && values.length) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          ml={1}
          bgcolor="#fff"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box pr={2} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index < 10) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={2} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Sl.No
                  </TableCell>
                  <TableCell align="center" size="small">
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.map((value, index) => {
                  if (index >= 10 && index < 15) {
                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                        }}
                      >
                        <TableCell align="center" size="small">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" size="small">
                          {value}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  const tableForAttributesAndGrr1 = () => {
    const values = JSON.parse(studyData?.study_input?.input_values);
    if (values && values.length) {
      return (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#EBEEEB" }}>
              {values.map((value, index) => {
                return (
                  <TableCell align="center" size="small">
                    {value["operator name"]}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              {values[0] && values[0].trials && values[0].trials.length && (
                <TableCell align="center" size="small">
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    ml={0}
                    bgcolor="#fff"
                    alignItems="flex-start"
                    justifyContent="space-around"
                  >
                    {values[0].trials.map((trialsArray, index) => {
                      return (
                        <Box pr={1} pl={0} bgcolor="#fff" width="100%">
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                                <TableCell align="center" size="small">
                                  Trial {index + 1}{" "}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {trialsArray.length &&
                                trialsArray.map((trialValue, index) => {
                                  // console.log("trialValue", trialValue);
                                  return (
                                    <TableRow
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? "#E9EFEC"
                                            : "#D7DAD9",
                                      }}
                                    >
                                      <TableCell align="center" size="small">
                                        {trialValue}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Box>
                      );
                    })}
                  </Box>
                </TableCell>
              )}
              {values[1] && values[1].trials && values[1].trials.length && (
                <TableCell align="center" size="small">
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    ml={0}
                    bgcolor="#fff"
                    alignItems="flex-start"
                    justifyContent="space-around"
                  >
                    {values[1].trials.map((trialsArray, index) => {
                      return (
                        <Box pr={1} pl={0} bgcolor="#fff" width="100%">
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                                <TableCell align="center" size="small">
                                  Trial {index + 1}{" "}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {trialsArray.length &&
                                trialsArray.map((trialValue, index) => {
                                  // console.log("trialValue", trialValue);
                                  return (
                                    <TableRow
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? "#E9EFEC"
                                            : "#D7DAD9",
                                      }}
                                    >
                                      <TableCell align="center" size="small">
                                        {trialValue}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Box>
                      );
                    })}
                  </Box>
                </TableCell>
              )}
              {values[2] && values[2].trials && values[2].trials.length && (
                <TableCell align="center" size="small">
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    ml={0}
                    bgcolor="#fff"
                    alignItems="flex-start"
                    justifyContent="space-around"
                  >
                    {values[2].trials.map((trialsArray, index) => {
                      return (
                        <Box pr={1} pl={0} bgcolor="#fff" width="100%">
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                                <TableCell align="center" size="small">
                                  Trial {index + 1}{" "}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {trialsArray.length &&
                                trialsArray.map((trialValue, index) => {
                                  // console.log("trialValue", trialValue);
                                  return (
                                    <TableRow
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? "#E9EFEC"
                                            : "#D7DAD9",
                                      }}
                                    >
                                      <TableCell align="center" size="small">
                                        {trialValue}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Box>
                      );
                    })}
                  </Box>
                </TableCell>
              )}
              {values[3] && values[3].trials && values[3].trials.length && (
                <TableCell align="center" size="small">
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    ml={0}
                    bgcolor="#fff"
                    alignItems="flex-start"
                    justifyContent="space-around"
                  >
                    {values[3].trials.map((trialsArray, index) => {
                      return (
                        <Box pr={1} pl={0} bgcolor="#fff" width="100%">
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                                <TableCell align="center" size="small">
                                  Trial{" "}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {trialsArray.length &&
                                trialsArray.map((trialValue, index) => {
                                  // console.log("trialValue", trialValue);
                                  return (
                                    <TableRow
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? "#E9EFEC"
                                            : "#D7DAD9",
                                      }}
                                    >
                                      <TableCell align="center" size="small">
                                        {trialValue}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Box>
                      );
                    })}
                  </Box>
                </TableCell>
              )}
              {values[4] && values[4].trials && values[4].trials.length && (
                <TableCell align="center" size="small">
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={0}
                    ml={0}
                    bgcolor="#fff"
                    alignItems="flex-start"
                    justifyContent="space-around"
                  >
                    {values[4].trials.map((trialsArray, index) => {
                      return (
                        <Box pr={1} pl={0} bgcolor="#fff" width="100%">
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                                <TableCell align="center" size="small">
                                  Trial{" "}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {trialsArray.length &&
                                trialsArray.map((trialValue, index) => {
                                  // console.log("trialValue", trialValue);
                                  return (
                                    <TableRow
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0
                                            ? "#E9EFEC"
                                            : "#D7DAD9",
                                      }}
                                    >
                                      <TableCell align="center" size="small">
                                        {trialValue}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Box>
                      );
                    })}
                  </Box>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        </Table>
      );
    } else {
      return null;
    }
  };

  const tableForGrr2 = () => {
    const values = JSON.parse(studyData?.study_input?.input_values);
    let trial1 = values && values.length && values[0];
    let trial2 = values && values.length && values[1];
    // console.log("Study data", values);
    if (values && trial1 && trial2 && trial1.length && trial2.length) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          p={1}
          ml={1}
          my={1}
          bgcolor="#fff"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box pr={2} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Part Number
                  </TableCell>
                  <TableCell align="center" size="small">
                    1st Trial
                  </TableCell>
                  <TableCell align="center" size="small">
                    2nd Trial
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trial1 &&
                  trial1.length &&
                  trial1.map((value, index) => {
                    if (trial2 && trial2.length && index < 10) {
                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                          }}
                        >
                          <TableCell align="center" size="small">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center" size="small">
                            {value}
                          </TableCell>
                          <TableCell align="center" size="small">
                            {trial2[index] ? trial2[index] : null}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={2} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Part Number
                  </TableCell>
                  <TableCell align="center" size="small">
                    1st Trial
                  </TableCell>
                  <TableCell align="center" size="small">
                    2nd Trial
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trial1 &&
                  trial1.length &&
                  trial1.map((value, index) => {
                    // console.log("value", value)
                    if (trial2 && trial2.length && index >= 10 && index < 20) {
                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                          }}
                        >
                          <TableCell align="center" size="small">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center" size="small">
                            {value}
                          </TableCell>
                          <TableCell align="center" size="small">
                            {trial2[index] ? trial2[index] : null}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
              </TableBody>
            </Table>
          </Box>
          <Box pr={1} pl={0} bgcolor="#fff" width="100%">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#EBEEEB" }}>
                  <TableCell align="center" size="small">
                    Part Number
                  </TableCell>
                  <TableCell align="center" size="small">
                    1st Trial
                  </TableCell>
                  <TableCell align="center" size="small">
                    2nd Trial
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trial1 &&
                  trial1.length &&
                  trial1.map((value, index) => {
                    if (trial2 && trial2.length && index >= 20 && index < 25) {
                      return (
                        <TableRow
                          key={index}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#E9EFEC" : "#D7DAD9",
                          }}
                        >
                          <TableCell align="center" size="small">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center" size="small">
                            {value}
                          </TableCell>
                          <TableCell align="center" size="small">
                            {trial2[index] ? trial2[index] : null}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
              </TableBody>
            </Table>
          </Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      {
        <Paper
          variant="outlined"
          square
          elevation={0}
          style={{
            overflowY: "scroll",
            height:
              studyType === "Attribute system analysis: Kappa Method"
                ? "750px"
                : forNextPage === true
                ? "1640px"
                : "400px",
            width: "100%",
          }}
        >
          {studyType !== "GRR Study (With operator influence)" ? (
            <Typography variant="h6" className="bg-light">
              {title}
            </Typography>
          ) : null}
          {studyData &&
            studyType === "Gauge Capability" &&
            tableForGaugeCapability()}
          {studyData && studyType === "Gauge BIAS Analysis" && tableForBias()}
          {studyData &&
            studyType === "GRR Study (Without operator influence)" &&
            tableForGrr2()}
          {studyData &&
            (studyType === "GRR Study (With operator influence)" ||
              studyType === "Attribute system analysis: Signal Method" ||
              studyType === "Attribute system analysis: Kappa Method") &&
            tableForAttributesAndGrr1()}
        </Paper>
      }
    </React.Fragment>
  );
};

export default CardsForTable;
