import { Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import spcService from "../../../services/spcService";
import spc from "../../../services/spcService";
import { StatusContext } from "../../../context/StatusContextProvider";
import CommonGrid from "../../../components/CommonGrid";
import { format } from 'date-fns'
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import StatusFilter from "../../../components/shared/StatusFilter";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import HighChartComponent from "../../../components/HighChartsComponent";
import { ChartSkeleton } from "../../../components/shared/DGSkeleton";
import { DGSelect } from "../../../components/shared/DGSelect";
import SpcPlot from "./SpcPlot"
import Loader from "../../../components/Loader";


export function SpcDetail({
    componentProps = null,
    closeForm = () => { },
}) {


    const [tableData, setTableData] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [renderKey, setRenderKey] = useState(0);
    const [sPCInputPlanOptions, setSpcInputPlanOptions] = useState([])
    const [sPCInput, setSpcInput] = useState(null)
    const [cpkFlag, setCpkFlag] = useState(0)


    useEffect(() => {
        if (componentProps?.cpk_flag === 1) {
            setCpkFlag(1)
        }
        else { setCpkFlag(0) }
    }, [componentProps]);

    useEffect(() => {
        reloadGridData()

        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => evalTableData(),
            callback: updateListStatus,
        }));
        reloadGridData();
    }, []);



    function reloadGridData() {

        spcService.getSPCPltlist({
            character_id: componentProps.character_id,
            machine_spindle: componentProps.machine_spindle,
            start_timestamp: componentProps?.start_timestamp,
            end_timestamp: componentProps?.end_timestamp
        })
            .then((res) => {
                const temp = res?.data ?? [];
                //console.log(temp)
                if (componentProps?.cpk_flag === 1) {
                    //console.log('cpkFlag', cpkFlag)
                    setSpcInputPlanOptions(temp)
                    const filteredData = temp.filter(item =>
                        item.count_spc_input_id !== 0
                        && item.count_spc_input_id !== null
                    );
                    // console.log('filteredData', filteredData)
                    setSpcInputPlanOptions(filteredData)
                    setSpcInput(filteredData[0])
                } else {
                    setSpcInputPlanOptions(temp)
                    const filteredData = temp.filter(item => item.display_flag === 1);
                    setSpcInput(filteredData[0])

                }

            })
    }




    useEffect(() => {
        if (sPCInput !== null) {
            evalTableData()
        }
    }, [sPCInput])

    function evalTableData() {
        spcService.getSPCChar({
            spc_input_id: sPCInput.spc_input_id
        })
            .then((res) => {
                const data = res?.data ?? [];

                if (cpkFlag === 1) {
                    const startDate = new Date(componentProps.start_timestamp);
                    const endDate = new Date(componentProps.end_timestamp);
                    const filteredItems = data.filter(item => {
                        const productionDate = new Date(item.production_date);
                        return productionDate > startDate && productionDate < endDate;
                    })
                    setTableData(filteredItems)
                }
                else {
                    setTableData(data)
                }
            });
        setRenderKey((prevRenderKey) => prevRenderKey + 1);
    }


    function updateListStatus(dummy1, dummy2, newStatus, rowId) {
        return spcService.updateSPC(rowId, { enabled: newStatus })
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 75,
            resizable: true,
        },
        {
            field: "evaluation_number",
            headerName: "Evaluation Number",
            minWidth: 200,
            filter: "agTextColumnFilter",
            floatingFilter: true,

            resizable: true,
        },

        {

            headerName: "Evaluation Date",
            minWidth: 150,
            filter: "agDateColumnFilter",
            floatingFilter: true,
            resizable: true,
            valueGetter: (params) =>
                format(new Date(params?.data?.production_date), 'dd/MM/yyyy')
        },
        {
            field: "production_time",
            headerName: "Evaluation Time",
            minWidth: 150,
            filter: "agTextColumnFilter",
            floatingFilter: true,
            resizable: true,
        },
        {
            headerName: 'Rejection Detial',
            children: [
                {
                    field: "spc_result",
                    headerName: "Evaluation Result",
                    columnGroupShow: 'null',
                    minWidth: 100,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    resizable: true,
                    cellRenderer: (params) => (
                        <ResultRenderer value={params?.data?.spc_result} />
                    ),
                },
                {
                    field: "loc_rules",
                    headerName: "Failed Location Rules",
                    columnGroupShow: 'open',
                    minWidth: 200,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    resizable: true,
                    tooltipValueGetter: (params) => {
                        if (params.value !== null) {
                            return params.value
                        }
                    }
                },



                {
                    field: "dis_rules",
                    headerName: "Failed Spread Rules",
                    minWidth: 200,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    resizable: true,
                    /*        cellRenderer: (params) => {
                               return params.value || '';
                           }, */
                    tooltipValueGetter: (params) => {
                        if (params.value !== null) {
                            return params.value
                        }
                    }
                },

                {
                    field: "raw_data",
                    headerName: "Input Data",
                    columnGroupShow: 'open',
                    minWidth: 200,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    resizable: true,
                },
            ]
        },

        /*         {
                    field: "plan_name",
                    headerName: "Plan Name",
                    minWidth: 100,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    resizable: true,
                }, */

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 100,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>

                        setStatus((prev) => ({
                            ...prev,
                            callback: updateListStatus,
                            open: true,
                            entityName: params?.data?.evaluation_number,
                            currentStatus: params?.data?.enabled,
                            rowId: params.data.id,
                        }))
                    }
                />
            ),
        },

        {
            field: "history",
            headerName: "Status History",
            minWidth: 100,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params.data.id,
                                entity: params?.data?.evaluation_number ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },



    ];

    const forceRerender = () => {
        setRenderKey(prevKey => prevKey + 1);
    };


    return (


        <>
            <Grid item md={12} sm={12} lg={12} xs={12} justifyContent="flex-end">
                <Grid item xs={12}>
                    {sPCInput && (
                        <SpcPlot
                            renderKey={renderKey}
                            character_id={componentProps.character_id}
                            spc_input_id={sPCInput?.spc_input_id}
                            spc_eval_id={sPCInput?.spc_eval_id}
                            gembaDisplay={0}
                            cpkFlag={cpkFlag}


                        />
                    )}
                </Grid>

                <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                    <Grid item md={12} sm={12} lg={12} >
                        <DGSelect
                            label="SPC Evaluation"
                            value={sPCInput}
                            options={sPCInputPlanOptions}
                            getOptionLabel={(opt) => `${opt?.spc_eval_number}`}
                            onChange={(val) => {
                                setSpcInput(val)
                            }}
                        />
                    </Grid>
                </Grid>

                <CommonGrid
                    needExport={true}
                    rows={tableData}
                    columns={columns}
                    gridheight={100}

                />

            </Grid>
        </>
    )

}