import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { date, object, string } from "yup";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import customerDashboardService from "../../../services/customerDashboardService";
import { AuthContext } from "../../../context/AuthContextProvider";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContextProvider";

export function CustomerContactForm({
  Footer = () => null,
  closeForm = () => {},
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      customer: null,
      customerOptions: [],
      phone_number: "",
      name: "",
      access_start_date: new Date(),
      access_end_date: new Date(),
    },
    validationSchema: object({
      customer: object().required(),
      phone_number: string().max(10).required(),
      name: string().required(),
      access_start_date: date().required(),
      access_end_date: date().required(),
    }),
    onSubmit: saveForm,
  });

  useEffect(() => {
    customerDashboardService
      .getCustomerClient(currentUser?.client_id)
      .then((res) => {
        form.setFieldValue("customerOptions", res?.data ?? []);
      });
  }, []);

  function saveForm() {
    let start_date = moment(form.values.access_start_date).format("YYYY-MM-DD");
    let end_date = moment(form.values.access_end_date).format("YYYY-MM-DD");
    let payload = {
      phone_number: form.values.phone_number,
      name: form.values.name,
      client_id: currentUser?.client_id,
      user_id: currentUser?.id,
      customer_id: form.values.customer?.customer_id,
      access_start_date: start_date,
      access_end_date: end_date,
    };
    customerDashboardService
      .createCustomerAccess(payload)
      .then((res) => {
        closeForm();
        updateAlert({
          open: true,
          message: "Customer Contact created successfully.",
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          open: true,
          message: "Failed to create Customer Contact.",
          type: "error",
        });
      });
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          required
          label="Customer"
          value={form.values.customer}
          options={form.values.customerOptions}
          onChange={(val) => form.setFieldValue("customer", val)}
          error={form.errors.customer && form.touched.customer}
          getOptionLabel={(opt) => opt?.company_name}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          required
          label="Phone Number"
          value={form.values.phone_number}
          isFormik
          onChange={form.handleChange}
          id="phone_number"
          type="number"
          error={form.errors.phone_number && form.touched.phone_number}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          required
          label="Name"
          value={form.values.name}
          isFormik
          onChange={form.handleChange}
          id="name"
          error={form.errors.name && form.touched.name}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGDateInput
          required
          label="Access Start Date"
          value={form.values.access_start_date}
          onChange={(val) => form.setFieldValue("access_start_date", val)}
          error={
            form.errors.access_start_date && form.touched.access_start_date
          }
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGDateInput
          required
          label="Access End Date"
          value={form.values.access_end_date}
          onChange={(val) => form.setFieldValue("access_end_date", val)}
          error={form.errors.access_end_date && form.touched.access_end_date}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
