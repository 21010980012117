import http from "./httpService";

const createProcess = (data) => {
  return http.post("/process", data);
};

const createProcessMachine = (data) => {
  return http.post("/process-machine", data);
};

const getProcesses = () => {
  return http.get("/process", {
    params: {
      $limit: 100,
    },
  });
};

const getMachineProcesses = (machineId) => {
  return http.get("/process-machine", {
    params: {
      machine_id: machineId,
      $limit: 100,
    },
  });
};

const getFormProcess = () => {
  return http.get("/process-form")
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createProcess,
  createProcessMachine,
  getProcesses,
  getMachineProcesses,
  getFormProcess
};
