import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { StatusHistoryContext } from "../context/StatusHistoryProvider";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CommonGrid from "./CommonGrid";
import { useEffect } from "react";
import changeManagementService from "../services/changeManagementService";
import ActiveInActive from "./ActiveInActive";

function StatusHistoryDialog() {
  const { entity, rowId, isOpen, setStatusHistory } = useContext(
    StatusHistoryContext
  );

  const [rows, setRows] = useState();
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "new_status",
      headerName: "New Status",
      minWidth: 80,
      cellRenderer: (params) => (
        <ActiveInActive status={params?.data?.new_status === "1" ? 1 : 0} />
      ),
    },
    {
      field: "user.name",
      headerName: "Modified",
      minWidth: 230,
      resizable: true,
    },
    {
      field: "created_at",
      headerName: "Modified Date",
      resizable: true,
      minWidth: 150,
      valueGetter: (params) =>
        params?.data?.created_at
          ? new Date(params?.data?.created_at).toLocaleDateString()
          : "No Date Added",
    },
    {
      field: "comments",
      headerName: "Comments",
      minWidth: 240,
      resizable: true,
    },
  ];
  useEffect(() => {
    if (isOpen) {
      changeManagementService
        .getAcessLogforentity(rowId)
        .then((res) => {
          setRows(res?.data?.data ?? []);
        })
        .catch(() => {
          setRows([]);
        });
    }
  }, [isOpen]);
  function onGridReady(params) {
    params.api.sizeColumnsToFit();
  }
  return (
    <Dialog
      open={isOpen}
      disableBackdropClick={true}
      fullWidth
      keepMounted={false}
      maxWidth="md"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#5c6bc0",
          color: "#fff",
        }}
      >
        <DialogTitle id="form-dialog-title">{entity} History</DialogTitle>
        <div>
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: false,
                initialize: () => {},
                entity: "",
              }))
            }
          >
            <CloseRoundedIcon style={{ fill: "#fff" }} />
          </Button>
        </div>
      </div>
      <DialogContent style={{ width: "100%", margin: 0, padding: 0 }}>
        <CommonGrid
          onGridReady={onGridReady}
          needExport={false}
          rows={rows}
          columns={columns}
        />
      </DialogContent>
    </Dialog>
  );
}
export default StatusHistoryDialog;
