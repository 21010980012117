import React, { useContext, useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { object, string } from "yup";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { DGInput } from "../../../../components/shared/DGInput";
import spcService from "../../../../services/spcService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";

export function SpcPlanFormNew({
  Footer = () => null,
  closeForm = () => { },
  componentProps = null,
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const { id } = useParams();
  const [sPCEvalList, setSPCEvalList] = useState([]);
  const { globalPlant } = useContext(PlantContext);


  let form = useFormik({
    initialValues: {
      character: null,
      characterOptions: [],
      character_id: null,

      spindle: null,
      spindleOptions: [],
      spindle_id: null,

      evaluation: null,
      evaluationOptions: [],
      evaluation_id: null,

      maskMethodOptions: [
        { id: 25, study: "Absolute Method" },
        { id: 26, study: "Deviation Method" }
      ],
      maskMethod: null,
      maskValue: "",
      samples: "",
      instructions: "",
      comments: "",
      preparedby: currentUser?.name,
      prepareddate: new Date(),
      transition_flag: true,
    },
    validationSchema: object({
      character: object().required(),
      spindle: object().required(),
      evaluation: object().required(),
      maskMethod: object().required(),
      maskValue: string().required(),
      samples: string().required(),
    }),

    onSubmit: onSave,
  });

  useEffect(() => {
    spcService.getSPCEvaltable(String(id))
      .then((res) => {
        const data = res?.data ?? [];
        const filter1 = data.filter((item) => item?.enabled === 1 && item?.approval_status_id === 2);
        setSPCEvalList(filter1);
        const filter2 = filter1.filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.character_id === item.character_id && t.machine_spindle_id === item.machine_spindle_id
          ))
        );
        form.setFieldValue("characterOptions", filter2);
      });


  }, []);



  function onSave(values) {
    let payload = {
      character_id: form.values.character?.character_id,
      machine_spindle_id: form.values?.spindle_id,
      cl_eval_id: form.values.evaluation?.id,
      comments: form.values.comments,
      // approved_by: selelctedApprover?.id,
      created_by: currentUser?.id,
      instructions: form.values.instructions,
      sample_size: parseInt(form.values.samples) ?? 0,
      mask_value: parseFloat(form.values.maskValue) ?? 0,
      mask_evaluation: form.values.maskMethod?.id,
      enabled: 1,
      transition_flag: form.values.transition_flag,
    };


    spcService
      .createSPCPlan(payload)
      .then((res) => {
        updateAlert({
          message: "Plan Created SucessFully",
          open: true,
          type: "success",
        });
        closeForm(true);
      })
      .catch(() => {
        updateAlert({
          message: "Failed to Add Plan",
          open: true,
          type: "error",
        });
      });
  }


  function getMachineSpindle(charcterId) {

    const data = form.values.characterOptions.find((item) => item.character_id === charcterId);
    form.setFieldValue("spindleOptions", data ? [data] : []);
  }

  function getCLEvaluation(charcterId, spindleId) {
    spcService.getSPCInputTable({
      query_id: globalPlant?.globalPlant?.id,
      client_id: currentUser?.client_id,
    })
      .then((res) => {
        const data = res?.data ?? [];

        const filter1 = data.filter((item) =>
          item?.enabled === 1
          && item?.character_id === charcterId
          && item?.machine_spindle_id === spindleId)

        if (filter1.length > 0) {
          updateAlert({
            message: `There is an active plan for this Characterstics and Machine Spindle.
                     Deactivate the existing plan to create a new plan.`,
            type: "warning",
            open: true,
          });
        } else {
          const filter1 = sPCEvalList.filter((item) =>
            item?.character_id === charcterId
            && item?.machine_spindle_id === spindleId)
          form.setFieldValue("evaluationOptions", filter1);
        }

      });

  }



  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          label="Characterstics Specification"
          value={form.values.character}
          options={form.values.characterOptions}
          getOptionLabel={(option) =>
            `${option.characteristics || ""} ${option.specification || ""} ${option.upper_specification || ""}  ${option.lower_specification || ""}`}
          onChange={(val) => {
            form.setFieldValue("character", val);
            form.setFieldValue("character_id", val.character_id);
            getMachineSpindle(val.character_id);
            form.setFieldValue("spindle", null);
            form.setFieldValue("evaluation", null);
          }}
          required
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="Machine Spindle"
          value={form.values.spindle}
          options={form.values.spindleOptions}
          getOptionLabel={(option) => option?.machine_spindle ?? ""}
          onChange={(val) => {
            form.setFieldValue("spindle", val);
            form.setFieldValue("spindle_id", val.machine_spindle_id);
            getCLEvaluation(form.values.character?.character_id, val.machine_spindle_id);
          }}
          error={form.touched.spindle && form.errors.spindle}
          required
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="Evaluation"
          value={form.values.evaluation}
          options={form.values.evaluationOptions}
          getOptionLabel={(option) => option?.report_name ?? ""}
          onChange={(val) => {
            form.setFieldValue("evaluation", val)
            form.setFieldValue("evaluation_id", val.id);
          }}
          error={form.touched.evaluation && form.errors.evaluation}
          required
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGSelect
          label="Mask Method"
          value={form.values.maskMethod}
          options={form.values.maskMethodOptions}
          getOptionLabel={(option) => option?.study ?? ""}
          onChange={(val) => form.setFieldValue("maskMethod", val)}
          //error={form.touched.maskMethod && form.errors.maskMethod}
          required
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          label="Mask Value"
          value={form.values.maskValue}
          onChange={form.handleChange}
          id="maskValue"
          isFormik
          required
        //error={form.touched.mask && form.errors.mask}
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGInput
          label="Number of Samples"
          value={form.values.samples}
          onChange={form.handleChange}
          id="samples"
          isFormik
          required
          error={form.touched.samples && form.errors.samples}

        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="checkedC"
              checked={form.values.transition_flag}
              onChange={(e) => form.setFieldValue('transition_flag', e.target.checked)}
              value={form.values.transition_flag}
            />
          }
          label="Transition Flag"
        />
      </Grid>
      {/*       <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Instructions"
          value={form.values.instructions}
          onChange={form.handleChange}
          id="instructions"
          isFormik
          multiline
          rows={5}
        />
      </Grid> */}
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Comments"
          value={form.values.comments}
          onChange={form.handleChange}
          id="comments"
          multiline
          rows={5}
          isFormik
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput disabled label="Prepared By" value={form.values.preparedby} />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGDateInput
          disabled
          label="Prepared Date"
          value={form.values.prepareddate}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
