import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import { useState, useEffect, useContext } from "react";
//import { RequestListing } from "./_Requests/RequestsListing";
import { ResultListing } from "./Results/ResultListing";
import { UnderInspectionListing } from "./UnderInspection/UnderInspectionListing";
import { PITrendList } from "./Trends/PITrendList";
import { UploadClientListing } from "../uploadClient/uploadclientListing"
import { PageTitleContext } from "../../../context/PageTitleContextProvider"


const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);

export function ProductInspectionListing() {
  const [tabIndex, setTabIndex] = useState(0);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setHeaderPageTitle("Production Inspection");
  }, []);


  return (
    <Paper square style={{ height: "83vh" }}>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setTabIndex(val)}
        aria-label="disabled tabs example"
      >
        {/* <StyledTab label="Requests" value={0} /> */}
        <StyledTab label="Under Inspection" value={0} />
        <StyledTab label="Results" value={1} />
        <StyledTab label="Upload Measurements" value={2} />
        <StyledTab label="Trends" value={3} />

      </Tabs>
      <Box m={2}>
        {/* {tabIndex === 0 ? <RequestListing /> : null} */}
        {tabIndex === 0 ? <UnderInspectionListing /> : null}
        {tabIndex === 1 ? <ResultListing /> : null}
        {tabIndex === 2 ? <UploadClientListing /> : null}
        {tabIndex === 3 ? <PITrendList /> : null}

      </Box>
    </Paper>
  );
}
