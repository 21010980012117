import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import { useState, useContext, useEffect } from "react";
import { ChecklistListing } from "./Checklist/ChecklistListing";
//import { DispatchPlanListing } from "./_Plan/DispatchPlanListing";
import { DispatchPlanListing } from "./Plan/DispatchPlanListing";
import { ResultListing } from "./Results/ResultListing";
import { UnderInspectionListing } from "./UnderInspection/UnderInspectionListing";
import { PageTitleContext } from "../../context/PageTitleContextProvider";

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);

export function PreDispatchInspectionListing() {
  const [tabIndex, setTabIndex] = useState(0);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setHeaderPageTitle("Pre-Dispatch Inspection");
  }, []);

  return (
    <Paper square>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setTabIndex(val)}
        aria-label="disabled tabs example"
      >
        <StyledTab label="Under Inspection" value={0} />
        <StyledTab label="Results" value={1} />
        <StyledTab label="Plan" value={2} />
        <StyledTab label="CheckList" value={3} />

      </Tabs>
      <Box m={2} width="100%">
        {tabIndex === 0 ? <UnderInspectionListing /> : null}
        {tabIndex === 1 ? <ResultListing /> : null}
        {tabIndex === 2 ? <DispatchPlanListing /> : null}
        {tabIndex === 3 ? <ChecklistListing /> : null}
      </Box>
    </Paper>
  );
}
