/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Button, Paper } from "@material-ui/core";
import machineService from "../../../services/machineService";
import CommonGrid from "../../../components/CommonGrid";
import { Link } from "react-router-dom";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import { AuthContext } from "../../../context/AuthContextProvider";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import { MachineProductDetail } from "./machineProductList"
import StatusFilter from '../../../components/shared/StatusFilter';


const MachineProductTable = () => {
    const [rows, setRows] = useState([]);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [formOpen, setFormOpen] = useState(false);
    const { currentUser } = useContext(AuthContext);
    const [selectedRow, setSelectedRow] = useState(null);


    useEffect(() => {

        setStatus((prev) => ({
            ...prev,
            reloadGrid: loadAllMachines,
        }));
    }, []);


    useEffect(() => {
        loadAllMachines();
    }, []);


    function loadAllMachines() {
        return machineService.getMachines(currentUser?.client_id).then((res) => {
            setRows(res?.data?.data ?? []);
            return Promise.resolve();
        });
    }

    function loadForm(data) {
        // setSubtitle(data?.request_name ?? "");
        setSelectedRow(data);
        setFormOpen(true);
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 150
        },
        {
            field: "machine_number",
            headerName: "Machine",
            floatingFilter: true,
            filter: true,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => loadForm(params?.data)}
                    >
                        {`${params?.data?.machine_number} ${params?.data?.name} `}
                    </Button>
                );
            },
        },
        {
            field: "plant.name",
            headerName: "Plant Name",
            floatingFilter: true,
            filter: true,
        },
        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                />
            ),
        },

    ];

    return (
        <>
            <Paper>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    gridheight={100}
                />
            </Paper>

            <DGDrawer
                Component={MachineProductDetail}
                isOpen={formOpen}
                drawerWidth={600}
                setOpen={setFormOpen}
                componentProps={selectedRow}
                title={selectedRow ? selectedRow.machine_number + '/' + selectedRow.name : ''}


            />
        </>
    );
};

export default MachineProductTable;

