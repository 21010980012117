import { Grid, makeStyles, Typography } from "@material-ui/core";
import { deepOrange, orange, red, yellow } from "@material-ui/core/colors";
import React from "react";

export function CalibrationFilter({ onClick = () => {},filters=[] }) {
  const classes = useStyles();


  function getClass(status) {
    switch (status) {
      case "Under Inspection":
        return classes.UI;
        break;
      case "For Approval":
        return classes.FA;
        break;
      case "Early Warning":
        return classes?.EW;
        break;
      case "Overdue":
        return classes?.OD;
        break;
      default:
        return classes?.UI;
        break;
    }
  }

  return (
    <>
      {filters.map((exp, i) => (
        <Grid
          item
          container
          direction="column"
          md={2}
          sm={2}
          lg={2}
          className={`${getClass(exp?.query_name)} ${classes.common}`}
          justifyContent="space-between"
          onClick={() => onClick(exp?.table_number,exp?.query_name)}
        >
          <Grid item>
            <Typography align="left" variant="h6" className={classes.header}>
              {exp?.query_name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="left" className={classes.count} variant="h3">
              {exp?.count_temp ?? 0}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

const useStyles = makeStyles({
  common: {
    color: "#fff",
    borderRadius: 15,
    cursor: "pointer",
    // width: "100%",
  },
  UI: {
    background: orange[100],
  },
  FA: {
    background: yellow[100],
  },
  EW: {
    background: red[50],
  },
  OD: {
    background: deepOrange[300],
  },
  header: {
    color: "#000",
    padding: 15,
    paddingBottom: 5,
  },
  subtitle: {
    color: "#0000008A",
    padding: 15,
    paddingRight: 0,
    paddingBottom: 8,
    paddingTop: 0,
  },
  count: {
    color: "#000",
    padding: 15,
    paddingTop: 0,
  },
  percentage: {
    color: "#000",
  },
});
