import { Button, Typography as MuiTypography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { green, red } from "@material-ui/core/colors";
import { spacing } from "@material-ui/system";
import { rgba } from "polished";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import ActiveInActive from "../../components/ActiveInActive";
import CommonGrid from "../../components/CommonGrid";
import DataGridTable from "../../components/DataGridTable";
import { AuthContext } from "../../context/AuthContextProvider";
import { StatusContext } from "../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import userService from "../../services/userService";
import { PageTitleContext } from "../../context/PageTitleContextProvider";
import StatusFilter from "../../components/shared/StatusFilter";
const Typography = styled(MuiTypography)(spacing);

const TeamList = ({ users }) => {
  const {
    open,
    rowId,
    entityName,
    currentStatus,
    setStatus,
    callback,
    reloadGrid,
  } = useContext(StatusContext);
  const { entity, initialize, isOpen, setStatusHistory } = useContext(
    StatusHistoryContext
  );
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState();
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  setHeaderPageTitle("Team")

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      callback: changeStatus,
      reloadGrid: getAllTeamMembers,
    }));
    setStatusHistory((prev) => ({ ...prev }));
    currentUser && getAllTeamMembers();
  }, []);



  const getAllTeamMembers = async () => {
    try {
      const response = await userService.getUsersByClientId(currentUser.client_id);
      // console.log("Users", response.data.data);
      if (response.data && response.data.data) {
        setRows(response?.data?.data ?? []);
        setHeaderPageTitle(`Team List`);
      }
    } catch (error) {
      // console.log("🚀 -> file: Team.js -> line 99 -> getAllTeamMembers -> error", error);
    }
  };

  function changeStatus(dummy1, dummy2, newStatus, rowId) {
    return userService.updateUser(rowId, { enabled: newStatus });
  }

  // console.log("Getting Users in TeamList", users);

  // useEffect(() => {
  //   console.log("users", users[0]);
  //   users && listUsersToRows();
  // }, [users]);

  // const listUsersToRows = async () => {
  //   let items = [],
  //     count = 0;
  //   users &&
  //     users.length &&
  //     (await Promise.all(
  //       users.map(async (user) => {
  //         let response = await userService.getUserRole(user.id);
  //         const userRole =
  //           response &&
  //           response.data &&
  //           response.data.data &&
  //           response.data.data[0]
  //             ? response.data.data[0]
  //             : null;
  //         items.push({
  //           id: user.id,
  //           sl_no: ++count,
  //           name: user.name
  //             ? user.name.charAt(0).toUpperCase() + user.name.slice(1)
  //             : "N/A",
  //           phone_number: user.phone_number ? user.phone_number : "N/A",
  //           email: user.email ? user.email : "N/A",
  //           role:
  //             userRole && userRole.role && userRole.role.name
  //               ? userRole.role.name.charAt(0).toUpperCase() +
  //                 userRole.role.name.slice(1)
  //               : "N/A",
  //         });
  //       })
  //     ));
  //   // console.log("Items", items);
  //   setRows(items);
  // };
  function onGridReady(params) {
    params.api.sizeColumnsToFit();
  }
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      resizable: true,
    },
    /* {
      field: "role",
      headerName: "Role",
      flex: 1,
      editable: false,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
    }, */
    {
      field: "phone_number",
      headerName: "Phone Number",
      minWidth: 200,
      resizable: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      resizable: true,
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.name ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
      resizable: true,
    },
    {
      field: "process.statusHistory",
      headerName: "Status History",
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
      minWidth: 200,
      resizable: true,
    },
  ];

  return (
    <React.Fragment>
      <Card style={{ height: "auto", width: "100%", padding: "1rem" }}>
        <CommonGrid
          rows={rows}
          columns={columns}
          onGridReady={onGridReady}
          gridheight={115}
        />
      </Card>
    </React.Fragment>
  );
};

export default TeamList;
