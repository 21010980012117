import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { Typography as MuiTypography } from "@material-ui/core";
import Lottie from "lottie-react-web";
import animationData from "../assets/animation/search-error.json";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import Container from "@material-ui/core/Container";

const defaultTheme = createMuiTheme();
const Typography = styled(MuiTypography)(spacing);

const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      width: "1500px",
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#1d1d1d",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },

      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color:
          theme.palette.type === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.65)",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
      ...hscroll(theme),
      label: {
        marginTop: theme.spacing(1),
      },
      flexDirection: "column",
      "& .ant-empty-img-1": {
        fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
      },
      "& .ant-empty-img-2": {
        fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
      },
      "& .ant-empty-img-3": {
        fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
      },
      "& .ant-empty-img-4": {
        fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
      },
      "& .ant-empty-img-5": {
        fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
        fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
      },
    },
  }),
  { defaultTheme }
);

const hscroll = (theme) => {
  return {
    "& MuiPaper-root MuiCard-root MuiPaper-elevation1 MuiPaper-rounded": {
      overflow: "auto",
    },
  };
};

const customCheckbox = (theme) => {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#1890ff",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
};

const CustomNoRowsOverlay = () => {
  return (
    <div item xs={12} style={{ textAlign: "center", paddingTop: 160 }}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData,
        }}
        height={100}
        width={"100%"}
      />
      <Typography variant="h6" gutterBottom>
        No Data Found
      </Typography>
    </div>
  );
};

const DataGridTable = ({
  rows,
  columns,
  checkboxSelection = false,
  sortModel,
  sortingOrder,
  onSortModelChange,
  isStickyHeader,
}) => {
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (rows && columns) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [rows, columns]);

  return (
    <Container style={{ overflow: "auto", padding: "0%" }}>
      <DataGrid
        density="compact"
        autoHeight={isStickyHeader ? false : true}
        autoWidth={isStickyHeader ? false : true}
        loading={loading}
        className={classes.root}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          Toolbar: GridToolbar,
        }}
        checkboxSelection={checkboxSelection}
        columns={columns}
        rows={rows}
        sortingOrder={sortingOrder}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        style={
          isStickyHeader
            ? { height: "80vh", width: "80wh", overflow: "auto" }
            : {}
        }
      />
    </Container>
  );
};

export default DataGridTable;
