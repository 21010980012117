import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import customerDashboardService from "../../../services/customerDashboardService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { Link } from "react-router-dom";
import { SubNavigationContext } from "../../../context/SubnavigationProvider";

export function CustomerProducts() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { updateRoutes } = useContext(SubNavigationContext);
  useEffect(() => {
    customerDashboardService
      .getCustomerDashboardProductTable(currentUser?.id)
      .then((res) => {
        setRows(res?.data ?? []);
      });
    updateRoutes([]);
  }, []);
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 110,
    },
    {
      field: "vendor_name",
      headerName: "Vendor Name",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      rowGroup: true,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "plant_name",
      headerName: "Vendor Plant",
      //rowGroup: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "part_number",
      headerName: "Part Detail",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: (params) => (
        <>
          <Link
            style={{
              cursor: "pointer",
              color: "#2196f3",
            }}
            variant="body2"
            to={`/customer/characterstics/${params.data.part_id}`}
          >
            {`${params?.data?.part_number}-${params?.data?.part_name}`}
          </Link>
        </>
      ),
      minWidth: 200,
    },
    {
      field: "plant_name",
      headerName: "Vendor Plant",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "access_start_date",
      headerName: "Access Start Date",
      resizable: true,
      floatingFilter: true,
      minWidth: 200,
      valueGetter: (params) =>
        params?.data?.access_end_date
          ? new Date(params.data.access_start_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      field: "access_end_date",
      headerName: "Access End Date",
      resizable: true,
      floatingFilter: true,
      minWidth: 200,
      valueGetter: (params) =>
        params?.data?.access_end_date
          ? new Date(params.data.access_end_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
  ];
  return <CommonGrid rows={rows} columns={columns} gridheight={100} />;
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
