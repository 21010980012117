import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";

export function OverviewCard({ name = "", count = 0 }) {
  return (
    <Card style={{ backgroundColor: "#ffe57f", height: 100 }}>
      <CardContent>
        <Grid container>
          <Grid item md={7} sm={7} lg={7}></Grid>
          <Grid item md={5} sm={5} lg={5}>
            <Typography align="left" variant="h1">
              {count}
            </Typography>
            <Typography align="left" variant="h4">
              {name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
