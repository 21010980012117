import React, { useState } from "react";
import { CustomerPdiOverview } from "./CustomerPdiOverview";
import { CustomerPdiDetails } from "./CustomerPdiDetails";
import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";

export function CustomerProductInspection() {
  const [selectedtab, setSelectedTab] = useState(0);
  return (
    <>
      <Paper square>
        <Tabs
          value={selectedtab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, val) => setSelectedTab(val)}
          aria-label="disabled tabs example"
        >
          <StyledTab label="Overview" value={0} />
          <StyledTab label="Details" value={1} />
        </Tabs>
        <Box m={2} width="100%">
          {selectedtab === 0 ? <CustomerPdiOverview /> : null}
          {selectedtab === 1 ? <CustomerPdiDetails /> : null}
        </Box>
      </Paper>
    </>
  );
}

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);
