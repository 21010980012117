import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { DispatchPlanListing } from "./DispatchPlanListing";

export function PDIPlanTab({ partDetails = null }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const buttons = [
    "Plan",
    //"Results",
    //"Trends"
  ];
  return (
    <Paper square>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setSelectedTab(val)}
        aria-label="disabled tabs example"
      >
        {buttons.map((btn, index) => (
          <StyledTab label={btn} value={index} />
        ))}
      </Tabs>
      <Box m={2}>
        {selectedTab === 0 ? <DispatchPlanListing /> : null}

      </Box>
    </Paper>
  );
}

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);
