import { Button } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDrawer } from "../../../../components/DGDrawer";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import productionInspection from "../../../../services/productionInspection";
import ActiveInActive from "../../../../components/ActiveInActive"
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import StatusFilter from "../../../../components/shared/StatusFilter";
import moment from "moment";
import { ResultForm } from "../../../productionInspection/product-inspection/Results/ResultForm";
import { useParams } from "react-router-dom";

export function ResultListing() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [subtitle, setSubtitle] = useState("");
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  let id = useParams();

  function changeStatus(dummy1, dummy2, newStatus, rowId) {
    return productionInspection.updateProductionInspectionRequest(rowId, { enabled: newStatus });
  }

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadgridData,
      callback: changeStatus,
    }));
    if (globalPlant && globalPlant?.globalPlant?.id) {
      reloadgridData();
    }
  }, []);


  function reloadgridData() {
    productionInspection.getInspectTablePlant({
      client_id: currentUser?.client_id,
      query_id: id.id,
      production_inspection_status: 3
    })
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }


  function onRowSelection(data) {
    setSelectedRow(data);
  }

  function loadForm(data) {
    setSubtitle(data?.request_name ?? "");
    setSelectedRow(data);
    setFormOpen(true);
  }

  const getRowStyle = (params) => {
    const request_result = params?.data?.request_result;

    if (request_result === 0) {
      return { background: 'rgba(231, 76, 60, 0.05)' };
    }
  }

  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 75,
      pinned: "left",
    },
    {
      field: "request_name",
      headerName: "Request Number",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      pinned: "left",
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => loadForm(params?.data)}
          >
            {`${params?.data?.request_name}`}
          </Button>
        );
      },
    },

    {
      field: "production_date",
      headerName: "Production Date",
      sortable: true,
      resizable: true,
      minWidth: "150",
      floatingFilter: true,
      autoHeight: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      valueGetter: (params) =>
        moment(new Date(params?.data?.production_date)).format("DD/MM/YYYY"),
    },
    {
      field: "production_time",
      headerName: "Production Time",
      sortable: true,
      resizable: true,
      minWidth: "150",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "plan_name",
      headerName: "Plan Name",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "batch_details",
      headerName: "Batch Details",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "request_result",
      headerName: "Result",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.request_result} />
      ),
    },

    {
      field: "enabled",
      headerName: "Status",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
        /*           onClick={() =>
                    setStatus((prev) => ({
                      ...prev,
                      open: true,
                      entityName: params?.data?.request_name ?? "",
                      currentStatus: params?.data?.enabled,
                      rowId: params.data.request_id,
                    }))
                  } */
        />
      ),
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellClass: "text-center",
      minWidth: 200,
    },
    {
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                entityName: params?.data?.request_name ?? "",
                currentStatus: params?.data?.enabled,
                rowId: params.data.request_id,
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },

  ];

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={215}
        onRowSelect={onRowSelection}
        getRowStyle={getRowStyle}
      />
      <DGDrawer
        Component={ResultForm}
        title="Inspection Result"
        isOpen={formOpen}
        setOpen={(state) => {
          setSubtitle("");
          setFormOpen(state);
        }}
        subtitle={subtitle}
        drawerWidth={1150}
        componentProps={selectedRow}
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
