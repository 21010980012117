import React, { useState, createContext } from "react";

const ModalContext = createContext({
  open: false,
  title: "",
  subtitle: "",
  actionButtons: [],
  updateModel: () => {},
  resetModal: () => {},
});

export const ModalContextProvider = ({ children }) => {
  const [modalObject, setModalObject] = useState({
    actionButtons: [],
    open: false,
    onClose: () => {},
    resetModal: resetModal,
    updateModal: updateModal,
  });

  function resetModal() {
    setModalObject((prev) => ({
      ...prev,
      open: false,
      title: "",
      subtitle: "",
      onClose: () => {},
    }));
  }
  function updateModal(obj) {
    setModalObject((prev) => ({ ...prev, ...obj }));
  }

  return (
    <ModalContext.Provider
      value={{
        ...modalObject,
        updateModel: updateModal,
        resetModal: resetModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
export { ModalContext };
