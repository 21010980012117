import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import { useState, useContext, useEffect } from "react";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { MsaPlantListing } from "./msa_listing/MsaPlantListing";
import { MsaPlanListing } from "./msa_plan_listing/MsaPlanListing";


const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);

export function MsaListing() {
  const [tabIndex, setTabIndex] = useState(0);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setHeaderPageTitle("Measurement System Analysis");
  }, []);

  return (
    <Paper square>
      <Tabs
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setTabIndex(val)}
        aria-label="disabled tabs example"
      >
        <StyledTab label="Msa" value={0} />
        <StyledTab label="Msa-Plan" value={1} />
      </Tabs>
      <Box m={2}>
        {tabIndex === 0 ? <MsaPlantListing /> : null}
        {tabIndex === 1 ? <MsaPlanListing /> : null}
      </Box>
    </Paper>
  );
}
