import React, { useContext, useEffect, useState, useRef } from "react";

import { Grid, Typography, Slider, Divider } from "@material-ui/core";
import { OverviewCard3 } from "./dashboardCard";
import dashboardService from '../../services/dashboardService'

import {
    format, addDays, addWeeks, addMonths, getWeek, startOfWeek,
    startOfMonth, endOfMonth, endOfWeek
} from 'date-fns'

const AssetmgmtCard = (props) => {

    const [Metrics, setMetrics] = useState([])
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [dataLabel, setdataLabel] = useState('')


    useEffect(() => {
        dashboardService.assetDashboard({
            client_id: props.client_id,
            assettype: props.cardNumber,
            plant_id: props.plant_id
        })
            .then(res => {
                setMetrics(res.data)

            })
            .catch(error => {
                console.error("An error occurred:", error);
            });


    }, [props.plant_id]);



    return (
        (() => {

            switch (props.cardNumber) {

                case 0:
                case 1:
                case 2:
                    return (
                        <Grid >
                            <OverviewCard3
                                name={`${props.cardName} (${(dataLabel)})`}
                                mainHeading={Metrics.main_head}
                                subHeading={Metrics.sub_head}
                                backgroundColor="#73C6B6"
                                metrics={Metrics.Metrics}
                                Component={props.Component}
                                drawerWidth={props.drawerWidth}

                            />
                        </Grid>
                    );

                // case 6:
                //     return (
                //         <Grid >
                //             <OverviewCard6
                //                 name={`${props.cardName}`}
                //                 backgroundColor="#F9E79F"
                //                 countData={Metrics}
                //             />
                //         </Grid>
                //     );


                // case 2:
                //     return (
                //         <Grid>
                //             <OverviewCard5
                //                 name={`${props.cardName}`}
                //                 backgroundColor="#73C6B6"
                //                 graphData={Metrics}
                //             />
                //         </Grid>
                //     );


                default:
                    return null



            }
        })()
    )
};

export default AssetmgmtCard;
