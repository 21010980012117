import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import {  green, red, yellow } from "@material-ui/core/colors";
const useStyles = makeStyles({
  approved: {backgroundColor:green[100],color:green[800]},
  pending: {backgroundColor:yellow[100],color:yellow[800]},
  rejected: {backgroundColor:red[100],color:red[800]},
  approvedIcon:{color:green[400]},
  rejectedIcon:{color:red[400]},
  pendingIcon:{ color:yellow[400]}
});
export function ApprovalStatus({ status = "" }) {
    const classes= useStyles();
  return (
    <>
      {status === "Pending" ? (
        <Button fullWidth className={classes.pending} endIcon={<WarningOutlinedIcon className={classes.pendingIcon} />}>Pending</Button>
      ) : null}
      {status === "Approved" ? (
        <Button fullWidth  className={classes.approved} endIcon={<CheckCircleOutlinedIcon className={classes.approvedIcon} />}>Approved</Button>
      ) : null}
      {status === "Rejected" ? (
        <Button fullWidth  className={classes.rejected} endIcon={<CancelOutlinedIcon className={classes.rejectedIcon} />}>Rejected</Button>
      ) : null}
    </>
  );
}
