import React, { useEffect, useState } from "react";
import { Box, Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import ControlLimitStudy from "./ControlLimitFormStudy";
import ControlLimitResult from "./ControlLimitResult";
import ManualEntryEval from "./ManualEntry"
import TimeLimitEntry from "./TimeLimitEntry";


export function ControlLimitFormNew({
    componentProps = null,
    Footer = () => null,
    setHeaderButtons = () => { },
    closeForm = () => { },
}) {
    const { v4: uuidv4 } = require("uuid");
    const [activeStep, setActiveStep] = useState(0);
    // const [spcCLEvalID, setSpcCLEvalID] = useState('0f10e199-7618-46f3-b800-9338ccd6c7ab')
    const [spcCLEvalID, setSpcCLEvalID] = useState(null)
    const [evalMethod, setEvalMethod] = useState(null)

    //console.log(activeStep, spcCLEvalID, "activeStep")

    let steps = ["Select Study", "Input & Evaluate Data", "Review Result & Save"]

    return (
        <>
            {" "}
            <Stepper activeStep={activeStep} style={{ width: "100%" }}>
                {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <Grid container>
                {activeStep === 0 ? (
                    <Grid item md={12} lg={12} sm={12}>
                        <ControlLimitStudy
                            componentProps={componentProps}
                            isOpen={true}
                            key={0}
                            onSuccess={(e, evalID, evaluation_method) => {
                                if (e) {
                                    setSpcCLEvalID(evalID)
                                    setEvalMethod(evaluation_method);
                                    setActiveStep(1);
                                } else {
                                    closeForm();
                                }
                            }}

                        />
                    </Grid>
                ) : null}

                {activeStep === 1 && evalMethod === 1500 ? (
                    <Grid item md={12} lg={12} sm={12}>
                        <ManualEntryEval
                            componentProps={spcCLEvalID}
                            onSuccess={(e) => {
                                if (e) {
                                    setActiveStep(2);
                                } else {
                                    closeForm();
                                }
                            }}
                        />
                    </Grid>
                ) : null}

                {activeStep === 1 && evalMethod === 1501 ? (
                    <Grid item md={12} lg={12} sm={12}>
                        <TimeLimitEntry
                            componentProps={spcCLEvalID}
                            onSuccess={(e) => {
                                if (e) {
                                    setActiveStep(2);
                                } else {
                                    closeForm();
                                }
                            }}
                        />
                    </Grid>
                ) : null}

                {activeStep === 2 ? (
                    <Grid item md={12} lg={12} sm={12}>
                        <ControlLimitResult
                            componentProps={spcCLEvalID}
                            onSuccess={(e) => {
                                if (e) {
                                    closeForm();
                                } else {
                                    closeForm();



                                }
                            }}

                        />
                    </Grid>
                ) : null}

            </Grid>
        </>
    );

}
