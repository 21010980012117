import http from "./httpService";

const getCPKTable = (data) => {
  return http.get("/process-capability-table", { params: { ...data } })
}


const getCPKPart = (part_id) => {
  return http.get("/process-capability-part", {
    params: {
      part_id: part_id
    },
  });
};

const getCPKClient = (client_id) => {
  return http.get("/process-capability-client", {
    params: {
      client_id: client_id,
    },
  });
};

const getCPKCharacter = (data) => {
  return http.get("/cpk-plot1", { params: { ...data } })
}




export default {

  getCPKTable,
  getCPKPart,
  getCPKClient,
  getCPKCharacter


}