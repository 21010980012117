import http from "./httpService";

const getFormTools = () => {
    return http.get("/form-tool");
};


const getFormToolTable = (client_id, plant_id) => {
    return http.get("/form-tool-table", {
        params: {
            client_id: client_id,
            plant_id: plant_id
        }
    })
};

const createFormTool = (data) => {
    return http.post("/form-tool-add", data);
};

const getToolProductList = (client_id, plant_id) => {
    return http.get("/form-product-table", {
        params: {
            client_id: client_id,
            plant_id: plant_id
        }
    })
};

const createToolProduct = (data) => {
    return http.post("/form-tool-product", data);
};

const updateToolProduct = async (id, newStatus) => {
    return http.patch(`/form-tool-product/${id}`, newStatus)
}

const updateFormTool = async (id, newStatus) => {
    return http.patch(`/form-tool/${id}`, newStatus)
}

const getFormProductList1 = (plant_id, part_id) => {
    return http.get(`/form-product-list1`, {
        params: {
            plant_id: plant_id,
            part_id: part_id,

        }
    })
};

const getFormProductList2 = (data) => {
    return http.get(`/form-product-list2`,
        { params: { ...data } })

}

const getFormToolProduction = (tool_id) => {
    return http.get(`/form-tool-production`, {
        params: {
            tool_id: tool_id
        }
    })
}

const createFormToolStatus = (data) => {
    return http.post("/form-tool-status", data);
};

const updateFormToolStatus = async (id, newStatus) => {
    return http.patch(`/form-tool-status/${id}`, newStatus)
}

const updateFormToolStatusMainTable = async (id, data) => {
    return http.patch(`/form-tool/${id}`, data)
}

const getFormToolStatusTable = (tool_id) => {
    return http.get("/formtool-status-table", {
        params: {
            tool_id: tool_id
        }
    })
}


export default {
    getFormTools,
    getFormToolTable,
    createFormTool,
    getToolProductList,
    createToolProduct,
    updateToolProduct,
    updateFormTool,
    getFormProductList1,
    getFormProductList2,
    getFormToolProduction,
    createFormToolStatus,
    updateFormToolStatus,
    updateFormToolStatusMainTable,
    getFormToolStatusTable
}