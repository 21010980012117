/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { date, number, object, string } from "yup";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { AuthContext } from "../../../../context/AuthContextProvider";
import characterService from "../../../../services/characterService";
import dateService from "../../../../services/dateService";
import gaugeService from "../../../../services/gaugeService";
import initiateStudyService from "../../../../services/initiateStudyService";
import msaPlanService from "../../../../services/msaPlanService";
import productService from "../../../../services/productService";
import { AlertContext } from "../../../../context/AlertContextProvider";
const { v4: uuidv4 } = require("uuid");

export function MsaPlanForm({ Footer = () => null, closeForm = () => { } }) {
  let form = useFormik({
    initialValues: {
      product: null,
      productOptions: [],
      character: null,
      characterOptions: [],
      study: null,
      studyOptions: [],
      scheduledDate: null,
      gauge: null,
      gaugeOptions: [],
      serialNumber: null,
      serialNumberOptions: [],
    },
    validationSchema: object({
      product: object().shape({
        part_number: string().required(),
        part_name: string().required(),
      }),
      character: object().shape({
        id: string().required(),
        name: string().required(),
      }),
      study: object().shape({
        studies_id: number().required(),
        studies_name: string().required(),
      }),
      scheduledDate: date().required(),
      gauge: object().shape({
        id: string().required(),
      }),
    }),
    onSubmit: upserForm,
  });
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  useEffect(() => {
    productService.getProducts(currentUser.client_id).then((res) => {
      form.setFieldValue("productOptions", res?.data?.data ?? []);
    });
  }, []);

  function loadStudies(val) {
    initiateStudyService
      .getMSAtestList({ id: val?.id, filter_name: "msa_study" })
      .then((res) => {
        form.setFieldValue("studyOptions", res?.data ?? []);
      });
    loadCharacters(val);
  }

  function loadCharacters(val) {
    characterService.getCharactersByProductId(val?.id).then((res) => {
      form.setFieldValue("characterOptions", res?.data?.data ?? []);
    });
  }

  function loadGauges(val) {
    gaugeService.getGaugeCharacterById(val?.id).then((res) => {
      form.setFieldValue("gaugeOptions", res?.data?.data ?? []);
    });
  }

  function loadGaugesSerialNumber(id) {
    gaugeService.getGaugeItemsOfGauge(id).then((res) => {
      form.setFieldValue("serialNumberOptions", res?.data?.data ?? []);
    });
  }

  function upserForm(values) {
    var lastDay = new Date(
      values?.scheduledDate?.getFullYear(),
      values?.scheduledDate?.getMonth() + 1,
      0
    );
    const payload = {
      id: uuidv4(),
      gauge_id: values.gauge?.gauge_id ? values?.gauge?.gauge_id : null,
      gauge_item_id: values.serialNumber?.id ? values.serialNumber?.id : null,
      character_id: values.character?.id ? values?.character?.id : null,
      study_id: values?.study?.studies_id ? values?.study?.studies_id : null,
      created_date: dateService.getDateInSqlFormat(new Date()),
      scheduled_date: values.scheduledDate
        ? dateService.getDateInSqlFormat(lastDay)
        : null,
      parent_id: null,
      client_id: currentUser.client_id,
      status_id: 1,
    };
    msaPlanService
      .createMSAPlan(payload)
      .then((res) => {
        closeForm(true);
        updateAlert({
          open: true,
          message: "MSA plan created sucessfully.",
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          open: true,
          message: "Failed to Created MSA plan.",
          type: "error",
        });
      });
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            id="product"
            label="Product"
            options={form.values.productOptions}
            getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
            onChange={(val) => {
              form.setFieldValue("product", val);
              loadStudies(val);
            }}
            value={form.values.product}
            required
            error={form.touched.product && Boolean(form.errors.product)}
            isFormik
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            id="character"
            label="Character"
            options={form.values.characterOptions}
            getOptionLabel={(opt) =>
              `${opt?.name} ${opt?.specification !== null ? opt?.specification : ""
              } ${opt?.upper_specification !== null
                ? opt?.upper_specification
                : ""
              } ${opt?.lower_specification !== null
                ? opt?.lower_specification
                : ""
              } `
            }
            onChange={(val) => {
              form.setFieldValue("character", val);
              loadGauges(val);
            }}
            error={form.touched.character && Boolean(form.errors.character)}
            value={form.values.character}
            required
            isFormik
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            id="study"
            label="Study"
            options={form.values.studyOptions}
            getOptionLabel={(opt) => opt?.studies_name}
            onChange={(val) => {
              form.setFieldValue("study", val);
            }}
            value={form.values.study}
            error={form.touched.study && Boolean(form.errors.study)}
            required
            isFormik
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGDateInput
            label="Schedule Date"
            id="scheduledDate"
            format="MM/yyyy"
            onChange={(date) => form.setFieldValue("scheduledDate", date)}
            value={form.values.scheduledDate}
            required
            error={
              form.touched.scheduledDate && Boolean(form.errors.scheduledDate)
            }
            views={["year", "month"]}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            id="gauge"
            label="Gauge Family"
            options={form.values.gaugeOptions}
            getOptionLabel={(option) =>
              option?.gauge?.number + " (" + option?.gauge?.name + ")"
            }
            onChange={(val) => {
              form.setFieldValue("gauge", val);
              loadGaugesSerialNumber(val?.gauge_id);
            }}
            value={form.values.gauge}
            error={form.touched.gauge && Boolean(form.errors.gauge)}
            required
            isFormik
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGSelect
            id="serialNumber"
            label="Gauge Number"
            options={form.values.serialNumberOptions}
            getOptionLabel={(option) => option?.serial_number}
            onChange={(val) => {
              form.setFieldValue("serialNumber", val);
            }}
            value={form.values.serialNumber}
            isFormik
          />
        </Grid>
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </>
  );
}
