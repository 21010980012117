import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import studiesResultService from "../../../services/studiesResultService";
import { useParams } from "react-router-dom";
import initiateStudyService from "../../../services/initiateStudyService";
import { DGDrawer } from "../../../components/DGDrawer";
import { MsaReport } from "./reports";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import { ApprovalStatus } from "../../../components/approvalStatus";
import { Button } from "@material-ui/core";
import ActiveInActive from "../../../components/ActiveInActive";
import { Text } from "@chakra-ui/react"
import StatusFilter from "../../../components/shared/StatusFilter"



export function MsaListing({ gaugeCharacter = null }) {
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [rows, setRows] = useState([]);
  const [studyOpen, setStudyOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  let { currentUser } = useContext(AuthContext);


  let id = useParams();

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadMSATable,
      callback: updateStudyStatus,
    }));
    reloadMSATable();
  }, []);


  const handleInitiateStudy = () => {
    setStudyOpen(true);
  };

  useEffect(() => {
    if (!formOpen) {
      reloadMSATable()
    }
  }, [formOpen]);

  function updateStudyStatus(dummy1, extras, newStatus, rowId) {
    return initiateStudyService.updateMSA({
      Studies_id: extras,
      msa_eval_id: rowId,
      new_status: newStatus,
    });
  }

  let columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      minWidth: 100,
      pinned: 'left',
    },
    /*     {
          headerName: "Report Number",
          field: "report_Number",
          resizable: true,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
          pinned: 'left',
          cellRenderer: (params) => {
            return (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  setFormOpen(true);
                }}
              >
                {params.data?.report_number}
              </Button>
            );
          },
        }, */

    {
      headerName: "Plan",
      field: "part_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 250,
      autoHeight: true,
      pinned: 'left',
      cellRenderer: (params) => {
        return (
          <>



            <Text height={15} fontSize="xs">
              {params?.data?.name}
            </Text>

            {params?.data?.specification !== null &&
              params?.data?.specification !== undefined && (
                <Text height={15} fontSize="xs">
                  {`${params?.data?.specification}  
                  ${params?.data?.upper_specification ?? ""
                    }  ${params?.data?.lower_specification ?? ""}`}
                </Text>
              )}

            <Text height={15} fontSize="xs">
              {params?.data?.Study_Name}
            </Text>

            <Text height={15} fontSize="xs">
              {params?.data?.Gauge}
            </Text>

            <Button
              size="small"
              color="primary"
              lineHeight={15}
              onClick={() => {
                setFormOpen(true);
              }}
            >
              {params.data?.report_number}
            </Button>

            {/* <Text marginBottom={6}></Text> */}
          </>
        )
      }
    }
    ,
    /*     {
          headerName: "Study",
          field: "Study_Name",
          resizable: true,
          floatingFilter: true,
          filter: "agMultiColumnFilter",
          minWidth: 200,
        }, */
    {
      headerName: "Created Date",
      field: "Test_Date",
      resizable: true,
      floatingFilter: true,
      valueGetter: (params) =>
        params?.data?.Test_Date
          ? new Date(params.data.Test_Date).toLocaleDateString()
          : "",
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      minWidth: 150,
    },
    /*     {
          headerName: "Gauge Name - Number",
          field: "Gauge",
          editable: false,
          resizable: true,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          minWidth: 200,
        }, */
    {
      headerName: "MSA Result",
      field: "Result",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <ResultRenderer value={params?.data?.Result} />,
      minWidth: 150,
    },
    {
      headerName: "Approval Status",
      field: "Status",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <ApprovalStatus status={params?.data?.Aprroval_Status} />
      ),
      minWidth: 200,
    },
    {
      field: "Status",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.Status}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.study_name,
              currentStatus: params?.data?.Status,
              rowId: params.data.id,
              extraData: params?.data?.studies_id,
            }))
          }
        />
      ),
      minWidth: 200,
    },
    {
      headerName: "Status History",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.id,
                entity: params?.data?.Study_Name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];


  function reloadMSATable() {
    initiateStudyService.getProductMSATable({
      client_id: currentUser.client_id,
      query_id: id.id
    })
      .then((data) => {
        let response = data.data;
        setRows(response);
      });
  }
  return (
    <>
      <CommonGrid
        columns={columns}
        rows={rows}
        // ButtonComponent={ButtonComponent}
        gridheight={158}
        onRowSelect={(data) => {
          setSelectedRow({
            studies_id: data[0]?.studies_id,
            study_id: data[0]?.id,
          });
        }}
      />
      <DGDrawer
        Component={MsaReport}
        setOpen={() => {
          setFormOpen(false);
          setSelectedRow(null);
        }}
        isOpen={formOpen}
        isClose={() => {
          setFormOpen(false);
          reloadMSATable()

        }}
        componentProps={{ ...selectedRow }}
        drawerWidth={1150}
        title="Report View"
        onClose={reloadMSATable}
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
