import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import LinkIcon from "@material-ui/icons/Link";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import cuttingToolService from "../../../services/cuttingToolService";
import { CuttingToolProductLink } from "./CuttingToolPartLink";
import { CuttingToolPartDetail } from "./CuttingToolPartDetail";

import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";



export function CuttingToolProduct() {
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [entryFormOpen, setEntryFormOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { globalPlant } = useContext(PlantContext);
    const { currentUser } = useContext(AuthContext);

    setHeaderPageTitle("Cutting Tool Product")

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);

    useEffect(() => {
        reloadGridData()

    }, [globalPlant]);



    function reloadGridData() {
        cuttingToolService.getCToolProduct
            ({
                plant_id: globalPlant?.globalPlant?.id,
                client_id: currentUser.client_id,
            })
            .then((res) => {
                const uniqueArray = res.data
                    .filter(item => item.enabled === 1)
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => t.ctool_id === item.ctool_id)
                    );
                setRows(uniqueArray);

                //setRows(res.data);

            });

    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },
        /*        {
                   headerName: "Part",
                   field: "part_number",
                   resizable: true,
                   floatingFilter: true,
                   filter: "agTextColumnFilter",
                   minWidth: 250,
       
                   valueGetter: (p) => {
                       return p?.data?.part_number + " - " + p?.data?.part_name;
                   },
               }, */

        {
            headerName: "Tool Name",
            // field: "ctool_name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 250,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            const updatedData = {
                                ...data,
                                client_id: currentUser.client_id,
                                plant_id: globalPlant?.globalPlant?.id
                            };
                            loadForm(updatedData)
                        }}
                    >
                        {`${params?.data?.ctool_name}`}
                    </Button>
                );
            },
        },

        {
            headerName: "Tool Life",
            field: "ctool_life",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 150,
        },

        /*     {
                field: "enabled",
                headerName: "Status",
                minWidth: 150,
                resizable: true,
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponent: StatusFilter,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => (
                    <ActiveInActive
                        status={params?.data?.enabled}
                        onClick={() =>
                            setStatus((prev) => ({
                                ...prev,
                                open: true,
                                callback: changeStatus,
                                reloadGrid: reloadGridData,
                                entityName: params?.data?.tool_name ?? "",
                                currentStatus: params?.data?.enabled,
                                rowId: params?.data?.id,
                            }))
                        }
                    />
                ),
            },
            {
                field: "enabled",
                headerName: "Status History",
                resizable: true,
                minWidth: 150,
                cellRenderer: (params) => {
                    return (
                        <Button
                            onClick={() =>
                                setStatusHistory((prev) => ({
                                    ...prev,
                                    isOpen: true,
                                    rowId: params?.data?.id,
                                    entity: params?.data?.part_name ?? "",
                                }))
                            }
                            size="small"
                            color="primary"
                        >
                            View
                        </Button>
                    );
                },
            }, */
    ];


    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return cuttingToolService.updateCuttingToolProduct(rowId, { enabled: newStatus });
    }

    function loadForm(updatedData) {
        //setSubtitle(data?.request_name ?? "");

        setSelectedRow(updatedData);
        setFormOpen(true);

    }


    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<LinkIcon />}
                    onClick={() => {
                        setEntryFormOpen(1);
                    }}
                >
                    Link Product
                </Button>

            </>
        );
    }



    return (
        <>

            <Grid item md={12} sm={12} lg={12} xs={12} justifyContent="flex-end">
                <CommonGrid
                    coldefs={{
                        filter: true,
                        filterParams: {
                            apply: true,
                            newRowsAction: "keep",
                            enabled: { filter: 1 },
                        },
                    }}
                    columns={columns}
                    ButtonComponent={ButtonComponent}
                    rows={rows}
                    gridheight={115}


                />

            </Grid>

            <DGDrawer
                isOpen={entryFormOpen}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()
                }}
                Component={CuttingToolProductLink}
                drawerWidth={1000}
                title="Link Product to Cutting Tool"
                needSave

            />


            <DGDrawer
                isOpen={formOpen}
                setOpen={() => {
                    setFormOpen(false)
                    reloadGridData()
                }}
                Component={CuttingToolPartDetail}
                drawerWidth={800}
                title={selectedRow?.ctool_name ?? "Cutting Tool Product"}
                componentProps={selectedRow}
                needSave

            />

        </>

    )


}