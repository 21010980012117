/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { Button as MuiButton, Paper } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import vendorService from "../../services/vendorService";
import VendorAccesForm from "./AddVendorAccess";
import CommonGrid from "../../components/CommonGrid";
import { Rules } from "../../components/cellRenderers/RulesRenderer";
import { useContext } from "react";
import { StatusContext } from "../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { useEffect } from "react";
import ActiveInActive from "../../components/ActiveInActive";
import piService from "../../services/piService";
import { ACTION_TYPE } from "../../components/shared/commonenum";
import moment from "moment";
import { VendorForm } from "./vendorForm";
import { DGDrawer } from "../../components/DGDrawer";
import AddIcon from "@material-ui/icons/Add";
import { AuthContext } from "../../context/AuthContextProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider";
import StatusFilter from "../../components/shared/StatusFilter";
import { VendorFormView } from "./vendorFormView";

const VendorListing = ({ changeGridData = () => { } }) => {
  const { currentUser } = useContext(AuthContext);
  const [vendorPiAgency, setVendorPiAgency] = useState();
  const [onGridreadyParams, setOnGridreadyParams] = useState();
  const Button = styled(MuiButton)(spacing);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [childData, setchildData] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  const [formAcessOpen, setFormAccessOpen] = useState(false);
  const [title, setTitle] = useState("Add Vendor");
  const [subtitle, setSubtitle] = useState(new Date().toLocaleDateString());
  const [rows, setRows] = useState([]);
  const [formdata, setFormdata] = useState({});
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const [formViewOpen, setFormViewOpen] = useState(false);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: changeGridData,
      reloadGrid: reloadGridData,
      callback: updateVendor,
    }));
    return () => {
      ACTION_TYPE.view = false;
    };
  }, []);

  useEffect(() => {

    setHeaderPageTitle(`Vendor Management`);



  }, []);

  function updateVendor(dummy1, dummy2, newStatus, rowId) {
    return piService.updatePIagencystatus(rowId, { enabled: newStatus });
  }

  const renderDate = (p) => {
    let date = p?.data?.vendor_access_from?.substring(0, 10);

    if (date && date !== "") {
      return (
        <div>{moment(p?.data?.vendor_access_from).format("DD/MM/YYYY")}</div>
      );
    }
    return <div></div>;
  };

  function loadFormdata(data) {
    piService.getPIAgencyDetail(data?.id).then((res) => {
      setFormdata(res?.data);
      setFormViewOpen(true);
    });
  }

  const newColumnDefs = [
    {
      headerName: "SL No",
      maxWidth: 100,
      resizable: true,
      sortable: false,
      valueGetter: "node.rowIndex + 1",
      cellRenderer: (params) => {
        if (params.value !== undefined) {
          return params.value;
        } else {
          return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
        }
      },
    },
    {
      field: "business_name",
      headerName: "Business Name",
      sortable: true,
      minWidth: 400,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              ACTION_TYPE.view = true;
              loadFormdata(params?.data);
            }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "vendor_category",
      headerName: "Vendor Category",
      sortable: true,
      minWidth: 210,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "enabled",
      headerName: "Access Status",
      sortable: true,
      minWidth: 200,
      resizable: true,
      cellRenderer: (params) => (
        <>
          {params.data.vendor_access_id ? (
            <Button
              variant="text"
              color="primary"
              onClick={() => handleRevokeAccess(params.data)}
            >
              <span style={{ color: "#F93154" }}> Revoke Access </span>
            </Button>
          ) : (
            <Button
              variant="text"
              color="primary"
              onClick={() =>
                handleProvideAccess(
                  params.data,
                  currentUser?.client_id)
              }
            >
              <span style={{ color: "#00B74A" }}> Provide Access </span>
            </Button>
          )}
        </>
      ),
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "vendor_access_from",
      headerName: "Access From",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: (params) => renderDate(params),
    },
    /*     {
          field: "certification_status",
          headerName: "Certification validity status",
          sortable: true,
          minWidth: 230,
          floatingFilter: true,
          filter: "agTextColumnFilter",
          resizable: true,
          cellRenderer: (params) => {
            return getIcon(params);
          },
        }, */
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.business_name ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.business_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];



  const handleProvideAccess = async (vendor) => {
    setFormAccessOpen(true);
    setVendorPiAgency(vendor);
  };


  const handleRevokeAccess = async (vendorAccess) => {
    const response = vendorService.deleteVendorAccess(
      vendorAccess?.vendor_access_id
    );
    response.then((data) => {
      changeGridData();
      reloadGridData();
    });
  };

  const onGridReadySetup = useCallback((params) => {
    setOnGridreadyParams(params);
  }, []);

  const handleChildClick = (num) => {
    setchildData(num);
  };

  useEffect(() => {
    changeGridData();
  }, [childData]);

  useEffect(() => {
    if (formOpen) {
      ACTION_TYPE.add = true;
    } else {
      vendorService.getVendorTable(currentUser?.client_id).then((data) => {
        setRows(data.data);
      });
    }
    return () => {
      ACTION_TYPE.add = false;
    };
  }, [formOpen]);

  /*   useEffect(() => {
      reloadGridData();
    }, [addVendorAccessDialog]);
   */
  function reloadGridData() {
    vendorService.getVendorTable(currentUser?.client_id).then((res) => {
      setRows(res?.data);
    });
  }

  function ButtonComponent() {
    return (

      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            ACTION_TYPE.add = true;
            ACTION_TYPE.view = false;
            setTitle("Add Vendor");
            setSubtitle(new Date().toLocaleDateString());
            setFormOpen(true);
          }}
        >
          Add Vendor
        </Button>

      </>
    );
  }




  return (
    <Paper>
      <CommonGrid
        coldefs={{
          filter: true,
          filterParams: {
            apply: true,
            newRowsAction: "keep",
            enabled: { filter: 1 },
          },
        }}
        onGridReady={onGridReadySetup}
        needExport={true}
        rows={rows}
        columns={newColumnDefs}
        gridheight={100}
        ButtonComponent={ButtonComponent}
      />
      {/*       {addVendorAccessDialog && (
        <AddVendorAccessDialog
          addVendorAccessDialog={addVendorAccessDialog}
          setAddVendorAccessDialog={setAddVendorAccessDialog}
          vendorPiAgency={vendorPiAgency}
          onSuccess={() => {
            setAddVendorAccessDialog(false);
          }}
          addvendorSuccess={handleChildClick}
        />
      )} */}
      <DGDrawer
        Component={VendorForm}
        needSave={!ACTION_TYPE.view}
        isOpen={formOpen}
        setOpen={(state) => {
          reloadGridData();
          setFormOpen(state);
        }}
        title={title}
        subtitle={subtitle}
        componentProps={formdata}
        drawerWidth={500}
      />

      <DGDrawer
        Component={VendorFormView}
        needSave={false}
        isOpen={formViewOpen}
        setOpen={(state) => {
          setFormViewOpen(state);
        }}
        title={formdata?.business_name}
        subtitle={formdata.vendor_type?.name}
        componentProps={formdata}
        drawerWidth={500}
      />

      <DGDrawer
        Component={VendorAccesForm}
        needSave={true}
        componentProps={{ vendorPiAgency, }}
        drawerWidth={600}
        title="Add Vendor Access"
        isOpen={formAcessOpen}
        setOpen={() => {
          setFormAccessOpen(false)
          reloadGridData()
        }}
      />
    </Paper>
  );
};

export default VendorListing;
