import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../../components/CommonGrid';
import machineService from '../../../services/machineService';
import { AuthContext } from '../../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import StatusFilter from "../../../components/shared/StatusFilter";
import ActiveInActive from "../../../components/ActiveInActive";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { StatusContext } from "../../../context/StatusContextProvider";
import { rgba } from "polished";

export function ProductMachineDetailList({
    componentProps = null,
}) {

    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { setStatus } = useContext(StatusContext);

    function updateListStatus(dummy1, dummy2, newStatus, rowId) {
        return machineService.updateProductMachineDetail(rowId, { enabled: newStatus });
    }


    function reloadGridData() {
        return machineService.getProductMachineDetail(componentProps?.part_id, currentUser?.client_id)
            .then((res) => {
                //debugger
                setRows(res?.data ?? []);
            });
    }

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            callback: updateListStatus,
        }));
        reloadGridData();
    }, []);


    const columns = [{
        field: "serial_number",
        headerName: "SI No",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 75,
        sorting: true,
    },
    {
        headerName: "Part",
        minWidth: 400,
        resizable: true,
        sorting: true,
        cellRenderer: (params) => {
            const marginLeft = (params?.data?.level + 1) > 0 ?
                (params?.data?.level + 1) * 20 : 0;
            return (
                <div style={{ marginLeft }}>
                    {params?.data?.part_number}-{params?.data?.part_name}
                </div>
            );
        },

        //filter: "agTextColumnFilter",
        //floatingFilter: true,
    },

    {
        field: "machine_detail",
        headerName: "Machine",
        minWidth: 200,
        resizable: true,
        //filter: "agTextColumnFilter",
        //floatingFilter: true,
        sorting: true,
    },
    {
        field: "enabled",
        headerName: "Status",
        minWidth: 150,
        resizable: true,
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        suppressMenu: true,
        floatingFilterComponent: StatusFilter,
        floatingFilterComponentParams: {
            suppressFilterButton: true,
        },
        cellRenderer: (params) => (
            <ActiveInActive
                disabled={params?.data?.edit_flag !== 1 || params?.data?.part_enabled === 0}
                status={params?.data?.enabled}
                onClick={() =>
                    setStatus((prev) => ({
                        ...prev,
                        open: true,
                        callback: updateListStatus,
                        reloadGrid: reloadGridData,
                        entityName: params?.data?.machine_detail ?? "",
                        currentStatus: params?.data?.enabled,
                        rowId: params?.data?.id,
                    }))
                }
            />
        ),
    },
    {
        field: "enabled",
        headerName: "Status History",
        resizable: true,
        minWidth: 150,
        cellRenderer: (params) => {
            return (
                <Button
                    disabled={params?.data?.edit_flag !== 1}
                    onClick={() =>
                        setStatusHistory((prev) => ({
                            ...prev,
                            isOpen: true,
                            rowId: params?.data?.gauge_character_id,
                            entityName: params?.data?.gauge_detail ?? "",
                        }))
                    }
                    size="small"
                    color="primary"
                >
                    View
                </Button>
            );
        },
    }


    ]

    const getRowStyle = (params) => {
        //console.log(params)
        const rowData = params.data;
        const partFlag = rowData.part_enabled;

        return partFlag === 0
            ? { background: 'rgba(245, 183, 177, .1) !important' }
            : {};
    };


    /*     function loadForm(data) {
            setSubtitle(data?.request_name ?? "");
            setSelectedRow(data);
            setFormOpen(true);
        } */


    return (
        <Grid item md={12} sm={12} lg={12}>

            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={50}
                getRowStyle={getRowStyle}
                //suppressRowClickSelection={true}
                suppressCellSelection={true}
            />

        </Grid>
    )


}
