import React, { useContext, useEffect, useState } from "react";
import { Button, Grid } from "@material-ui/core";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import CommonGrid from "../../../components/CommonGrid";
import { useFormik } from "formik";
import cuttingToolService from "../../../services/cuttingToolService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { grid } from "@material-ui/system";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import { object, number } from "yup";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";





export function CuttingToolNumberAdd({
    Footer = () => { },
    closeForm = () => { },
}) {

    const [rows, setRows] = useState([])
    const { currentUser } = useContext(AuthContext)
    const [gridApi, setGridApi] = useState(null);
    const { updateAlert } = useContext(AlertContext);
    const { v4: uuidv4 } = require('uuid');
    const { globalPlant } = useContext(PlantContext);




    let form = useFormik({
        initialValues: {
            cuttingToolOptions: [],
            cuttingToolSelected: null,
            cuttingToolNumber: "",
            cuttingToolUsage: "",
            gridData: [],

        },
        validationSchema: object().shape({
            cuttingToolNumber: number().integer()
        }),
    })

    let gridForm = useFormik({

        initialValues: {
            gridData: [],
        },

        onSubmit: onSave
    })

    useEffect(() => {

        cuttingToolService.getCToolTable(currentUser.client_id)
            .then((res) => {
                const data = res?.data?.data
                    .filter(item => item.enabled === 1)
                form.setFieldValue("cuttingToolOptions", data);
            });

    }, [])

    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function onAdd() {
        gridForm.setFieldValue("gridData", [
            ...gridForm.values.gridData,
            form.values,
        ])
        form.setFieldValue("cuttingToolNumber", "");
        form.setFieldValue("cuttingToolUsage", "");

    }

    function onSave() {
        let payload = [];
        gridApi?.forEachNode((node) => {
            payload.push({
                id: uuidv4(),
                client_id: currentUser.client_id,
                plant_id: globalPlant?.globalPlant?.id,
                ctool_id: node?.data?.cuttingToolSelected?.id ?? "",
                ctool_number: node?.data?.cuttingToolNumber ?? "",
                user_id: currentUser.id,
                ctool_status: 101,
                ctoolnumber_life: node?.data?.cuttingToolUsage ?? "",
                enabled: 1,
            });
        });


        cuttingToolService.createCToolNumber(payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    open: true,
                    message: "Added tool Cutting Tool",
                    type: "success",
                });
            })
            .catch(() => {
                updateAlert({
                    open: true,
                    message: "Failed to add tool ",
                    type: "error",
                });
            });

    }

    function removeDetailRow(index, rowData) {
        //   console.log(index, rowData, "index")
        let data = gridForm.values.gridData;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
        gridForm.setFieldValue("gridData", data);
    }



    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 75,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },
        {
            field: "ctool_name",
            headerName: "Cutting Tool Name",
            filter: "agTextColumnFilter",
            minWidth: 200,
            valueGetter: (p) => {
                return p?.data?.cuttingToolSelected?.ctool_name;
            },
        },
        {
            field: "cuttingToolNumber",
            headerName: "Tool Number",
            filter: "agTextColumnFilter",
            minWidth: 150,

        },
        {
            field: "cuttingToolUsage",
            headerName: "Tool Usage",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },


    ]

    return (

        <>

            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <DGSelect
                        name="cuttingToolSelected"
                        label="Cutting Tool"
                        options={form.values.cuttingToolOptions}
                        value={form.values.cuttingToolSelected}
                        getOptionLabel={(opt) => `${opt?.ctool_name}`}
                        disabled={form.values.cuttingToolSelected}

                        onChange={(val) => form.setFieldValue
                            ("cuttingToolSelected", val)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DGInput
                        id="cuttingToolNumber"
                        label="Cutting Tool Number"
                        value={form.values.cuttingToolNumber}
                        onChange={form.handleChange}
                        isFormik
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DGInput
                        id="cuttingToolUsage"
                        label="Cutting Tool Usage"
                        value={form.values.cuttingToolUsage}
                        onChange={form.handleChange}
                        isFormik
                    />
                </Grid>


            </Grid>


            <Grid item md={12} sm={12} lg={12}
                style={{ padding: '10px' }}>
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    //  disabled={form.values.cuttingToolNumber == null || !form.isValid}
                    onClick={onAdd}
                >
                    ADD
                </Button>

            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}
                justifyContent="flex-end">


                <CommonGrid
                    coldefs={{
                        filter: true,
                        filterParams: {
                            apply: true,
                            newRowsAction: "keep",
                            enabled: { filter: 1 },
                        },
                    }}
                    columns={columns}
                    rows={gridForm.values.gridData}
                    gridheight={115}
                    onGridReady={onGridReady}


                />
            </Grid>

            <Footer onSave={gridForm.handleSubmit} />
        </>

    )

}