import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { OverviewCard } from "../../components/DashboardCard"
import { AuthContext } from "../../context/AuthContextProvider";

export function FormToolOverview() {
    const [cardData, setCardData] = useState({
        characteristics_count: 0,
        part_count: 0,
        vendor_count: 0,
    });
    const { currentUser } = useContext(AuthContext);
    useEffect(() => {
        /*
        customerDashboardService
            .getCustomerOverview(currentUser?.id)
            .then((res) => {
                setCardData(res?.data);
            });
            */
    }, []);
    return (
        <Grid
            container
            spacing={3}
            className="mt-2"
            style={{ width: "99%", marginLeft: 2 }}
        >
            <Grid item md={3} sm={3} lg={3}>
                <OverviewCard
                    name="Active Tool"
                    count={cardData?.vendor_count}
                    backgroundColor="#73C6B6"
                />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
                <OverviewCard
                    name="Early Warning"
                    count={cardData.part_count}
                />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
                <OverviewCard
                    name="Overdue "
                    count={cardData?.characteristics_count}
                    backgroundColor="#D8634A"
                />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
                <OverviewCard
                    name="Initiated"
                    count={cardData?.characteristics_count}
                    backgroundColor="#ABEBC6"
                />
            </Grid>

        </Grid>
    );
}