import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { CalibrationForm } from "./calibrationForm";
import CommonGrid from "../../../components/CommonGrid";
import { DGDrawer } from "../../../components/DGDrawer";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import piService from "../../../services/piService";
import Functions from "@material-ui/icons/Functions"
import { CalibrationOverview } from "./calibrationGraph"

export function CalibrationHistory() {
  //style Definition
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [formOpen, setFormOpen] = useState(false);
  const [graphTable, setGraphTable] = useState(false);
  const [selctedData, setSelectedData] = useState({});
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    piService.plantlevelPI(currentUser?.client_id).then((res) => {
      setRows(res?.data ?? []);
    });
    ACTION_TYPE.view = true;
    return () => {
      ACTION_TYPE.view = false;
    }
  }, []);

  function loadFormData(data) {
    piService.getPIrequestforID(data?.id ?? "").then((res) => {
      setSelectedData({ ...data, apiData: res?.data });
      return Promise.resolve();
    }).then(() => {
      setFormOpen(!formOpen);
    });
  }

  useEffect(() => {
    setHeaderPageTitle(`Gauge Calibration History`);
  }, []);
  const columns = [
    {
      field: "serial",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      minWidth: 100,
      sorting: true,
    },
    {
      field: "report_name",
      headerName: "Report Number",
      minWidth: 200,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              loadFormData(params?.data);
            }}
          >
            {params.value}
          </Button>
        );
      },
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "serial_number",
      headerName: "Gauge Serial Number",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "name",
      headerName: "Plant",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "certificate_number",
      headerName: "Certificate Number",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "requested_date",
      headerName: "Requested Date",
      minWidth: 200,
      resizable: true,
      filterParams: filterParams,
      valueGetter: (params) =>
        params?.data?.requested_date
          ? new Date(params?.data?.requested_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "calibrated_date",
      headerName: "Calibrated Date",
      minWidth: 200,
      resizable: true,
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        params?.data?.calibrated_date
          ? new Date(params?.data?.calibrated_date).toLocaleDateString()
          : "",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "approved_date",
      headerName: "Approved Date",
      minWidth: 200,
      resizable: true,
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        params?.data?.calibrated_date
          ? new Date(params?.data?.calibrated_date).toLocaleDateString()
          : "",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "approver_name",
      headerName: "Approved By",
      minWidth: 200,
      resizable: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "business_name",
      headerName: "Calibration Agency",
      minWidth: 200,
      resizable: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "status",
      headerName: "Calibration Result",
      minWidth: 200,
      resizable: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "number",
      headerName: "Gauge Family",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
  ];

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Functions />}
          onClick={() => {
            setGraphTable(true);
          }}
          style={{ marginLeft: 3 }}
        >
          Analyze
        </Button>
      </>
    );
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={115}
        ButtonComponent={ButtonComponent}
      />
      <DGDrawer
        componentProps={selctedData}
        Component={CalibrationForm}
        isOpen={formOpen}
        drawerWidth={500}
        setOpen={setFormOpen}
        title={selctedData?.report_name ?? ""}
        subtitle={selctedData?.serial_number ?? ""}
      />

      <DGDrawer
        Component={CalibrationOverview}
        isOpen={graphTable}
        drawerWidth={800}
        setOpen={setGraphTable}
        title={"Calibration History"}
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
