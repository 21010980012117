/* eslint-disable react-hooks/exhaustive-deps */
import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import gaugeService from "../../../services/gaugeService";
import { useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import CommonGrid from "../../../components/CommonGrid";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.grey,
    color: "rgba(255,255,255)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const ViewGaugeItemEditHistory = () => {
  let { id } = useParams();
  const [rows, setRows] = useState([]);
  const [gaugeItemEditHistoryList, setGaugeItemEditHistoryList] = useState([]);

  useEffect(() => {
    if (gaugeItemEditHistoryList?.length) {
      listHistoryToRows();
    }
  }, [gaugeItemEditHistoryList]);

  useEffect(() => {
    if (id) {
      getGaugeItemEditHistory();
    }
  }, [id]);

  const getJSONParsedList = (list) => {
    return list.map((gauge) => {
      gauge.previous_data = JSON.parse(gauge.previous_data);
      gauge.present_data = JSON.parse(gauge.present_data);
      return gauge;
    });
  };

  const getGaugeItemEditHistory = async () => {
    try {
      const response = await gaugeService.getEditHistory(id);
      // console.log("ChangeManagement", response.data);
      if (response?.data?.data) {
        const parsedDataList = getJSONParsedList(response.data.data);
        // console.log('parsedDataList', parsedDataList);
        setGaugeItemEditHistoryList(parsedDataList);
      }
    } catch (err) {
      // console.log('Error in ViewGaugeEditHistory ~ getGaugeEditHistory', err);
    }
  };

  const getDateString = (inDate) => {
    const date = new Date(inDate);
    return (
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "/" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };

  const listHistoryToRows = async () => {
    let items = [];
    await Promise.all(
      gaugeItemEditHistoryList.map(async (gaugeItem) => {
        // console.log("gaugeItem", gaugeItem);
        items.push({
          id: gaugeItem.id,
          serial_number: gaugeItem.previous_data.serial_number,
          usage: gaugeItem.previous_data.guage_usage
            ? gaugeItem.previous_data.guage_usage.usage
            : "",
          location: gaugeItem.previous_data.location,
          status: gaugeItem.previous_data.guage_status
            ? gaugeItem.previous_data.guage_status.status
            : "",
          comments: gaugeItem.previous_data.comments,
          created_at: gaugeItem.created_at
            ? getDateString(gaugeItem.created_at)
            : "",
          changed_by: gaugeItem?.changed_by_user?.name
            ? gaugeItem.changed_by_user.name
            : "",
          date_for_sorting_purpose: gaugeItem?.created_at,
          pi_frequency: gaugeItem?.previous_data?.pi_frequency?.name
            ? gaugeItem.previous_data.pi_frequency.name
            : "",
        });
      })
    );
    const sortedDataList = items.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
    // console.log('sortedDataList', sortedDataList);
    setRows(sortedDataList);
  };

  const columns = [
    {
      field: "serial_number",
      headerName: "Serial Number",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "usage",
      headerName: "Usage",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "comments",
      headerName: "Comments",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "pi_frequency",
      headerName: "Calibration Frequency",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "created_at",
      headerName: "Edited On",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "changed_by",
      headerName: "Edited By",
      minWidth: 200,
      resizable: true,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
  ];

  return (
    <Paper>
      <CommonGrid columns={columns} rows={rows} gridheight={100} />
    </Paper>
  );
};

export default ViewGaugeItemEditHistory;
