import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import Header from "../components/AppBar";
import Settings from "../components/Settings";
import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isWidthUp } from "@material-ui/core/withWidth";
import { useContext } from "react";
import { SubNavigationContext } from "../context/SubnavigationProvider";
import { AuthContext } from "../context/AuthContextProvider";
import { PageTitleContext } from "../context/PageTitleContextProvider"

const drawerWidth = 280;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100px;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  
`;

const Dashboard = ({
  children,
  routes,
  width,
  selectedPlant,
  handlePlantChange,
  previousplant,
  plants,
  setOpenRoutes = () => { },
  openRoutes,
  expandedIndex = -1,
  setExpandedIndex = () => { },
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [pageTitles, setPageTitle] = useState("");
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  //const { isChildrenRequired, pageTitle } = useContext(SubNavigationContext);
  const { pageTitle } = useContext(PageTitleContext)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigateBack = () => {
    history.goBack();
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            openRoutes={openRoutes}
            setOpenRoutes={setOpenRoutes}
            expandedIndex={expandedIndex}
            setExpandedIndex={setExpandedIndex}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            routes={routes}
            PaperProps={{ style: { width: drawerWidth } }}
            openRoutes={openRoutes}
            setOpenRoutes={setOpenRoutes}
            pageTitle={pageTitle}
            plants={plants}
            selectedPlant={selectedPlant}
            setSelectedPlant={handlePlantChange}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header
          onDrawerToggle={handleDrawerToggle}
          pageTitle={pageTitle}
          onNavigateBack={handleNavigateBack}
          plants={plants}
          selectedPlant={selectedPlant}
          setSelectedPlant={handlePlantChange}
        />
        <MainContent
          p={isWidthUp("lg", width) ? (pageTitle === "Overview" ? 0 : 6) : 5}
        >
          {(selectedPlant && selectedPlant?.id !== previousplant?.id) ||
            currentUser?.screen_flag === "flag_4"
            ? children
            : null}
        </MainContent>
        {/* <Footer /> */}
      </AppContent>
      <Settings />
    </Root>
  );
};

export default withWidth()(Dashboard);
