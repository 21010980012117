import { Box, Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { object, array } from "yup";
import { DGSelect } from "../../../../components/shared/DGSelect";
import CommonGrid from "../../../../components/CommonGrid";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { AuthContext } from "../../../../context/AuthContextProvider";
import characterService from "../../../../services/characterService";
import dateService from "../../../../services/dateService";
import gaugeService from "../../../../services/gaugeService";
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer"



const { v4: uuidv4 } = require("uuid");

export function GaugeCharacterForm({
  Footer = () => { },
  componentProps = null,
  closeForm = () => { },
}) {
  let { id } = useParams();
  const { updateAlert } = useContext(AlertContext);
  const { currentUser } = useContext(AuthContext);
  const [gridApi, setGridApi] = useState(null);

  let form = useFormik({
    initialValues: {
      gridData: []
    },
    validationSchema: object({
      gridData: array().min(1),
    }),
    onSubmit: onSave
  });

  let tempform = useFormik({
    initialValues: {
      gauges: null,
      gaugeOptions: [],
      characteristics: null,
      characterOptions: [],
      gaugeOptions2: []
    },
    validationSchema: object({
      characteristics: object().required(),
      gauges: object().required(),


    }),
    onSubmit: onAdd
  });

  useEffect(() => {
    loadCharacter()
  }, []);

  useEffect(() => {
    if (tempform.values.characteristics) {
      GetAllGauges();
    }
  }, [tempform.values.characteristics]);


  function gaugeChar(characterID) {
    if ((form.values.gridData).length > 0) {
      var temp2 = form.values.gridData;
      temp2.forEach(obj => {
        obj.gridentry = { ...obj.gauges, ...obj.name };
      });
      const temp3 = temp2.map(({ gridentry }) => gridentry);
      var temp4 = temp3.filter(item => item.id === characterID);
      var temp5 = temp4.map(item => item.gauge_id);
      //console.log(temp4)

    } else { var temp5 = [] }
    return temp5
  }


  function onClear() {
    form?.resetForm();
  }

  function removeDetailRow(rowIndex, data) {
    let chars = form.values.gridData;
    chars.splice(rowIndex, 1);
    gridApi?.updateRowData({ remove: [data] });
  }

  function onAdd() {
    let data = [];
    data?.push({
      name: tempform.values.characteristics,
      gauges: tempform.values.gauges
    });
    form.setFieldValue("gridData", [...form.values.gridData, ...data]);
    tempform.resetForm();
    loadCharacter()
    GetAllGauges()
  }

  function loadCharacter() {
    characterService.getACharactersByProductId(componentProps?.partId?.partId)
      .then((res) => {
        var temp = res.data
        tempform.setFieldValue("characterOptions", temp);

      })
  }

  function GetAllGauges() {
    const gaugeList = gaugeChar(tempform.values.characteristics?.id)
    gaugeService.getGaugePlantDrop({
      character_id: tempform.values.characteristics?.id,
      client_id: currentUser.client_id,
      gauge_list: gaugeList
    })
      .then((res) => {
        var temp = res.data
        // console.log(temp)
        tempform.setFieldValue("gaugeOptions", temp);
      });
  }

  function onGridReady(params) {
    setGridApi(params?.api);
  }

  function onSave() {
    let gaugeObj = [];
    gridApi.forEachNode((node) => {
      let data = node?.data;
      gaugeObj.push({
        id: uuidv4(),
        part_id: null,
        created_by: currentUser.id,
        gauge_id: data.gauges.gauge_id,
        created_date: dateService.getDateInSqlFormat(new Date()),
        character_id: data.name.id
      })
    })


    characterService.createGaugeCharacter(gaugeObj).then(res => {
      closeForm();
      updateAlert({ message: "Gauge linked Successfully", type: "success", open: true });
    }).catch(err => {
      updateAlert({ message: "Failed to link Gauge", type: "error", open: true })
    })

  }


  const columns = [
    {
      cellRenderer: DeleteRenderer,
      maxWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
      pinned: "left",
      resizable: true,
      hide: !!componentProps?.approvalId,
    },
    {
      headerName: "Charcterstics",
      resizable: true,
      minWidth: 200,
      pinned: "left",
      valueGetter: (params) => {
        return ` ${params?.data?.name.name ?? ""}  ${params?.data?.name.specification ?? ""} 
             ${params?.data?.name.upper_specification ?? ""}  ${params?.data?.name.lower_specification ?? ""}`;
      }
    },
    {
      headerName: "Gauge",
      resizable: true,
      minWidth: 200,
      pinned: "left",
      valueGetter: (params) => {
        return `${params.data.gauges?.gauge_number} ${params.data.gauges?.gauge_name}`
      }
    },]

  return (
    <Grid container spacing={4}>


      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="Characteristics"
          value={tempform.values.characteristics}
          options={tempform.values.characterOptions}
          getOptionLabel={(opt) =>
            `${opt?.name} ${opt?.specification !== null ? opt?.specification : ""
            } ${opt?.upper_specification !== null
              ? opt?.upper_specification
              : ""
            } ${opt?.lower_specification !== null
              ? opt?.lower_specification
              : ""
            } `
          }
          onChange={(val) => {
            tempform?.setFieldValue("characteristics", val);
          }}
          required
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          required
          id="gauges"
          label="Gauge"
          value={tempform.values.gauges}
          options={tempform.values.gaugeOptions}
          getOptionLabel={(opt) => `${opt?.gauge_number} ${opt?.gauge_name}`}
          onChange={(val) => {
            tempform.setFieldValue("gauges", val);
          }}
          error={
            tempform.touched.gauges && tempform.errors.gauges
          }
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <Button
          style={{ paddingLeft: 40, paddingRight: 40 }}
          color="primary"
          variant="outlined"
          onClick={tempform.handleSubmit}
          className="m-2"
        >
          Add
        </Button>
        <Button
          style={{ paddingLeft: 40, paddingRight: 40 }}
          color="primary"
          variant="outlined"
          onClick={onClear}
        >
          Clear
        </Button>
      </Grid>

      <Grid md={12} sm={12} lg={12}>
        <CommonGrid
          columns={columns}
          rows={form.values.gridData?.length ? form.values.gridData : []}
          needExport={false}
          onGridReady={onGridReady}
        //gridheight={componentProps?.approvalId ? 138 : 420}
        />
      </Grid>




      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
