import React, { useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import SPCEntryForm from "../../spc/spc/SpcEntryForm";




export default function SpcGembaEntry({ setSelectedCard = () => { } }) {
    const [formOpen, setFormOpen] = useState(true);
    return (
        <>
            {" "}

            <DGDrawer
                isOpen={formOpen}
                setOpen={(state, reqID) => {
                    if (reqID) {
                        setFormOpen(state);
                    } else {
                        setFormOpen(state);
                        setSelectedCard(-1);
                    }
                }}
                Component={SPCEntryForm}
                drawerWidth={1200}
                title="SPC ENTRY"
            />

        </>
    );
}
