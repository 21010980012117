import { Grid } from "@material-ui/core";
import { Country, City, State } from "country-state-city";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { object, string } from "yup";
import { DGInput } from "../../components/shared/DGInput";
import { DGSelect } from "../../components/shared/DGSelect";
import { AlertContext } from "../../context/AlertContextProvider";
import { AuthContext } from "../../context/AuthContextProvider";
import addressService from "../../services/addressService";
import customerService from "../../services/customerService";
import dateService from "../../services/dateService";
const { v4: uuidv4 } = require("uuid");

export function CustomerForm({
  Footer = () => null,
  closeForm = () => {},
  componentProps = null,
}) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      company_name: "",
      address1: "",
      address2: "",
      state: null,
      city: null,
      pincode: "",
      country: null,
      gst: "",
    },
    validationSchema: object({
      company_name: string().required(),
      address1: string().required(),
      address2: string().required(),
      state: object().required(),
      city: object().required(),
      pincode: string().required(),
      country: object().required(),
    }),
    onSubmit: onSave,
  });

  async function onSave(values) {
    if (form.values.country?.isoCode === "IN" && !form.values.gst) {
      return form.setFieldError("gst", " ");
    }
    const obj1 = {
      id: uuidv4(),
      address_line_1: form.values.address1,
      address_line_2: form.values.address2,
      city: form.values.city?.name,
      pincode: form.values.pincode,
      state: form.values.state?.name,
      country: form.values.country?.name,
      // GST: form.values.gst,
    };
    const response1 = await addressService.createAddress(obj1);
    if (response1 && response1.status === 201) {
      const obj2 = {
        id: uuidv4(),
        company_name: values.company_name,
        GST: values?.gst,
        address_id: response1.data.id,
        client_id: currentUser.client_id,
        created_at: dateService.getDateInSqlFormat(new Date()),
      };
      const response2 = await customerService.createCustomer(obj2);
      if (response2 && response2.status === 201) {
        updateAlert({
          open: true,
          message: "Customer Added SuccessFully",
          type: "success",
        });
        closeForm();
      }
    }
  }
  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Company Name"
          value={form.values.company_name}
          onChange={form.handleChange}
          id="company_name"
          error={form.touched.company_name && form.errors.company_name}
          isFormik
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Street Address Line 1"
          value={form.values.address1}
          onChange={form.handleChange}
          id="address1"
          isFormik
          error={form.touched.address1 && form.errors.address1}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Street Address Line 2"
          value={form.values.address2}
          onChange={form.handleChange}
          id="address2"
          isFormik
          error={form.touched.address2 && form.errors.address2}
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="Country"
          options={Country.getAllCountries()}
          value={form.values.country}
          onChange={(val) => {
            form.setFieldValue()
            form.setFieldValue("country", val);
            form.setFieldValue("state",null)
            form.setFieldValue("city",null)
            form.setFieldValue("gst", "");
          }}
          error={form.touched.country && form.errors.country}
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="State"
          options={State.getStatesOfCountry(form.values.country?.isoCode) ?? []}
          value={form.values.state}
          onChange={(val) => {
            form.setFieldValue("state", val);
          }}
          error={form.touched.state && form.errors.state}
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="City"
          options={City.getCitiesOfState(
            form.values.country?.isoCode,
            form.values.state?.isoCode
          )}
          value={form.values.city}
          onChange={(val) => form.setFieldValue("city", val)}
          error={form.touched.city && form.errors.city}
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          label="Pin Code"
          value={form.values.pincode}
          onChange={form.handleChange}
          id="pincode"
          isFormik
          error={form.touched.pincode && form.errors.pincode}
        />
      </Grid>
      {form.values.country?.isoCode === "IN" ? (
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            label="GST"
            value={form.values.gst}
            onChange={form.handleChange}
            id="gst"
            isFormik
            error={form.touched.gst && form.errors.gst}
          />
        </Grid>
      ) : null}

      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
