import { Box, Button, Grid, Divider, AppBar, Toolbar, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { object, boolean } from "yup";
import { AuthContext } from "../../../context/AuthContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import { DGInput } from "../../../components/shared/DGInput";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import CommonGrid from "../../../components/CommonGrid";
import { NumericEditor } from "../../../components/cellRenderers/NumericEditor";
import { AngleEditor } from "../../../components/cellRenderers/AngleEditor";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import { StatusContext } from "../../../context/StatusContextProvider";
import { format } from "date-fns";


export function PDIResultView({
    Footer = () => { },
    closeForm = () => { },
    componentProps = null,
}) {

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const { setStatus } = useContext(StatusContext);

    let form = useFormik({
        initialValues: {
            generalContent: [],
            itemGroup: [],
            gridCheck: false,
            generalCheck: false,
            finalResult: 0
        },
    });

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);


    function reloadGridData() {
        disptachInspection.getPDIReportView(componentProps)
            .then((res) => {
                form.setFieldValue("generalContent", res.data?.general);
                form.setFieldValue("itemGroup", res.data?.static_header);
                if (res.data?.grid_details.length > 0) {

                    let arrayData = res?.data?.grid_details?.map((exp) => ({
                        ...exp,
                        ...exp?.values?.map((e) => e),
                    }));
                    setRows(arrayData);
                } else {
                    const temp = res.data?.grid_plan_details.map((item) => ({
                        ...item,
                        pdi_result_id: null,
                    }));

                    setRows(temp);
                }
            })

    }

    useEffect(() => {
        if (form.values.generalContent) {
            loadColumns(form.values.generalContent?.max_sample_size);
        }
    }, [form.values.generalContent]);



    function loadColumns(samples = 0) {

        let entries = [];
        for (let i = 0; i < samples; i++) {
            entries.push({
                field: `${i}`,
                editable: (params) => {
                    let columnIndex = Number(params?.colDef?.field);
                    return columnIndex < params?.data?.sample_size;
                },
                minWidth: 80,
                resizable: true,
                valueGetter: (params) => {
                    if (
                        (params?.data[i] === 1 || params?.data[i] === "OK") &&
                        params?.data?.measurement_id === 303
                    ) {
                        return "OK";
                    } else if (
                        (params?.data[i] === 0 || params?.data[i] === "NOT OK") &&
                        params?.data?.measurement_id === 303
                    ) {
                        return "NOT OK";
                    } else {
                        return params?.data[i];
                    }
                },


                cellEditorSelector: (params) => {
                    if (params?.data?.character_type_id !== 105) {
                        if (params?.data?.measurement_id === 303) {
                            return {
                                component: "agSelectCellEditor",
                                params: {
                                    values: ["OK", "NOT OK"],
                                    valueListMaxHeight: "5",
                                    valueListMaxWidth: 100
                                },
                                popup: false,

                            };
                        }

                        else if (
                            params?.data?.measurement_id === 301 ||
                            params?.data?.measurement_id === 302
                        ) {
                            return {
                                component: NumericEditor,
                            };
                        } else {
                            return {
                                component: "agTextCellEditor",
                            };
                        }
                    } else {
                        return {
                            component: AngleEditor,
                        };
                    }
                },


                colSpan: (params) => {
                    if (
                        Number(params?.colDef?.field) + 1 ===
                        Number(params?.data?.sample_size)
                    ) {
                        return samples - Number(params?.data?.sample_size) + 1;
                    } else {
                        return 1;
                    }
                },
                minWidth: 150,
                suppressMovable: true,
                headerValueGetter: (params) => Number(params?.colDef?.field) + 1,
            });
        }


        let static_columns = [
            {
                field: "order_number",
                headerName: "#",
                filter: "agTextColumnFilter",
                minWidth: 75,
                resizable: true,
            },
            {
                field: "characterstics",
                headerName: "Characterstics and Specification",
                filter: "agTextColumnFilter",
                minWidth: 200,
                resizable: true,
                autoHeight: true,
                cellStyle: {
                    whiteSpace: 'pre-wrap',
                    lineHeight: '1.3' // 
                },
                cellRenderer: (params) => {
                    return (
                        <>

                            <Typography variant="body1"
                                style={{ marginTop: '6px', }}>
                                {params?.data?.character_name}
                            </Typography>

                            {params?.data?.specification !== null &&
                                params?.data?.specification !== undefined && (
                                    <Typography variant="body2">
                                        {`${params?.data?.specification}  ${params?.data?.upper_specification ?? ""
                                            }  ${params?.data?.lower_specification ?? ""}`}
                                    </Typography>
                                )}

                            {
                                params?.data?.gauge_number !== null &&
                                params?.data?.gauge_number !== undefined && (
                                    <Typography variant="body2">
                                        {params?.data?.gauge_number} {params?.data?.gauge_name}
                                    </Typography>
                                )
                            }
                        </>
                    );
                },
            },

            {
                field: "character_result",
                headerName: "Result",
                cellRenderer: (params) => (
                    <ResultRenderer value={params?.data?.pdi_char_result} />
                ),
                minWidth: 150,
                resizable: true,
            },

        ];

        setColumns([...static_columns, ...entries]);
    }


    return (
        <Grid container spacing={2}>

            <Grid container spacing={2} style={{ "margin-top": "15px" }}>
                {form.values.itemGroup.map((item, index) => (
                    item.item_group_id !== 3 ? (

                        <Grid item xs={3} key={index}>
                            {item.pdi_standard_item_id === "3012459c-24ae-4015-95e6-3ad15d3ca365" ? (
                                <DGDateInput
                                    label={item.item_name}
                                    value={item.default_value}

                                />
                            ) : item.pdi_standard_item_id === "7e373bba-fc6a-4f6b-b7f0-7fca72926759"
                                || item.pdi_standard_item_id === "048c25e4-7f49-4b5f-9831-35067979327d"
                                || item.item_group_id === 0 ?
                                (
                                    <DGInput
                                        label={item.item_name}
                                        disabled={true}
                                        value={item.default_value}

                                    />
                                ) :
                                item.item_group_id !== 3 ? (
                                    <DGInput
                                        label={item.item_name}
                                        value={item.default_value}


                                    />
                                ) : null}
                        </Grid>

                    ) : null
                ))}
            </Grid>


            <Grid item xs={12}>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    needExport={true}

                />
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
                {form.values.itemGroup.map((item, index) =>
                    item.item_group_id === 3 ? (
                        <Grid item xs={3} key={index}>
                            <DGInput
                                label={item.item_name}
                                value={item.default_value}

                            />
                        </Grid>
                    ) : null
                )}
            </Grid>




        </Grid>
    )


}