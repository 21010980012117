import {
    Grid, Button, Box, TextareaAutosize,
    Typography, AppBar, Divider, Toolbar
} from "@material-ui/core";
import { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider";
import { StatusContext } from "../../context/StatusContextProvider"
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { useFormik } from "formik";
import { DGSelect } from "../../components/shared/DGSelect";
import { object, string } from "yup";
import plantService from "../../services/plantService";
import { DGInput } from "../../components/shared/DGInput";
import { DGDateInput } from "../../components/shared/DGDateInput";
import userService from "../../services/userService";
import studyInputService from "../../services/studyInputService";
import oplService from "../../services/oplService";
import { format, isEqual, max } from 'date-fns'

import { AlertContext } from "../../context/AlertContextProvider";





export default function NewOpenPoint({

    closeForm = () => { },
    componentProps = '',



}) {


    const { updateAlert } = useContext(AlertContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [levelFlag, setLevelFlag] = useState(false);
    const [opVersionsFlag, setopVersionFlag] = useState(false);
    const [changeFlag, setChangeFlag] = useState(false);
    const { v4: uuidv4 } = require('uuid');


    let form = useFormik({
        initialValues: {

            plantOptions: [],
            plantSelected: [],
            openPoint: "",
            ica: "",
            actions: "",
            requestedDate: new Date(),
            requestedBy: [],
            requestedBySelected: [],
            itemOptions: [],
            itemSelected: [],
            opIntiatedBy: "",
            opIntiatedDate: new Date(),
            opExpectedDate: new Date(),
            opTargetDate: null,
            opClossureDate: null,
            openPointID: parseInt(componentProps.maxId) + 1 || '',
            openPointVersion: 1,
            openPointVersionOptions: [],
            comments: "",
            closureCriteria: "",
            actionByOptions: [],
            actionBySelected: [],
            criticalityOptions: [

                { id: 1, name: "Urgent" },
                { id: 2, name: "Critical" },
                { id: 3, name: "Critical & Urgent" },
                { id: 4, name: "Non-Critical" }
            ],
            criticalitySelected: [],
            actionImpactOptions: [
                { id: 1, name: "Safety" },
                { id: 2, name: "Quality" },
                { id: 3, name: "Cost" },
                { id: 4, name: "Delivery" },
            ],
            actionImpactSelected: [],

            moduleSelected: [],
            moduleOptions: [],
            opidData: [],

        },
        validationSchema: object().shape({
            moduleSelected: object().required(),
            openPoint: string().required(),
        }),


        onSubmit: onAdd

    });


    useEffect(() => {
        if (!currentUser) {
            return;
        }


        plantService.getPlants(currentUser.client_id).then((res) => {
            const tempPlant = res?.data?.data
            form.setFieldValue("plantOptions", tempPlant)

            const tempFilter = tempPlant.filter((item) => item.id === currentUser.default_plant)
            form.setFieldValue("plantSelected", tempFilter[0])
        });


    }, [currentUser]);

    useEffect(() => {

        userService.getUsersByPlantId(
            form.values.plantSelected.id,
            currentUser.client_id).then((res) => {

                const tempUser = res?.data
                form.setFieldValue("requestedBy", tempUser)

                const tempuser2 = tempUser.filter((item) => item.user_id === currentUser.id)
                form.setFieldValue("requestedBySelected", tempuser2[0])

                form.setFieldValue("actionByOptions", tempUser)
            });


        studyInputService.getAllStudies()
            .then((res) => {
                const tempModule = res?.data?.data
                const filterModules = tempModule.filter((item) => item.group === 'OPL')
                form.setFieldValue("moduleOptions", filterModules)
            })


    }, [currentUser, form.values.plantSelected]);


    useEffect(() => {
        if (!currentUser) {
            return;
        }
        const temp = currentUser
        if (temp.user_role <= 3) {
            setLevelFlag(true)
        }

    }, [currentUser]);






    function onAdd() {

        const data = {
            opid: uuidv4(),
            client_id: currentUser.client_id,
            plant_id: form.values.plantSelected.id,
            op_id: form.values?.openPointID,
            op_version: form.values.openPointVersion,
            raised_by: form.values.requestedBySelected.user_id,

            criticality_id: form.values.criticalitySelected.id,

            raised_date: form.values.opIntiatedDate
                ? format(new Date(form.values.opIntiatedDate), "yyyy-MM-dd")
                : null,

            op_description: form.values.openPoint,
            source_id: form.values.moduleSelected.id,
            expected_close_date: form.values.opExpectedDate
                ? format(new Date(form.values.opExpectedDate), "yyyy-MM-dd")
                : null,
            ica: form.values.ica,
            ica_document_link: null,

            action_by: form.values?.actionBySelected?.user_id,

            action_target_date: form.values.opTargetDate
                ? format(new Date(form.values.opTargetDate), "yyyy-MM-dd")
                : null,

            closure_criteria: form.values.closureCriteria,
            action_descritption: form.values.actions,
            action_document_link: null,
            action_impact_id: form.values.actionImpactSelected?.id,
            action_status: -1,
            closure_date: form.values.opClossureDate
                ? format(new Date(form.values.opClossureDate), "yyyy-MM-dd")
                : null,
            closure_comment: form.values.comments
        }

        oplService.createOP(data)
            .then((res) => {
                updateAlert({
                    message: "Oplen Point Updated SucessFully",
                    open: true,
                    type: "success",
                });
                closeForm(true);
            })
            .catch(() => {
                updateAlert({
                    message: "Failed to Update, Please try again",
                    open: true,
                    type: "error",
                });
            });

    }


    return (


        <Grid container spacing={2}>


            <Grid item xs={7}>
                <Box
                    sx={{
                        border: '1px solid grey',
                        width: '98%',
                        padding: '5px',
                        marginTop: '5px',
                        //height: '70%',
                        marginRight: '2%',
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: "700px",
                        borderRadius: '10px'

                    }}
                >
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        Open Point
                    </Typography>

                    <Grid container spacing={2}>

                        <Grid item xs={5}>
                            <DGSelect
                                id="plant"
                                label="Plant"
                                options={form.values.plantOptions}
                                getOptionLabel={(option) => option?.name || ''}
                                value={form.values.plantSelected}
                                disabled={!levelFlag}
                                onChange={(value) =>
                                    form.setFieldValue("plantSelected", value)}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <DGInput
                                id="plant"
                                label="Open Point ID"
                                value={form.values.openPointID}

                            />
                        </Grid>

                        <Grid item xs={3}>
                            <DGInput
                                id="openPointVersion"
                                label="Version"
                                value={form.values.openPointVersion}

                            />
                        </Grid>

                        <Grid item xs={4}>
                            <DGSelect
                                id="criticality"
                                label="Criticality"
                                options={form.values.criticalityOptions}
                                getOptionLabel={(option) => option?.name || ''}
                                value={form.values.criticalitySelected}
                                onChange={(value) => {
                                    form.setFieldValue("criticalitySelected", value);
                                }}
                            />


                        </Grid>

                        <Grid item xs={5}>
                            <DGSelect
                                id="requestedBy"
                                label="Raised By"
                                options={form.values.requestedBy}
                                getOptionLabel={(option) => option?.user_name || ''}
                                value={form.values.requestedBySelected}
                                onChange={(value) =>
                                    form.setFieldValue("requestedBySelected", value)}
                            />


                        </Grid>

                        <Grid item xs={3}>
                            <DGDateInput
                                required
                                label="Requested Date"
                                value={form.values.requestedDate}
                                onChange={(val) =>
                                    form.setFieldValue("requestedDate", val)}

                            />



                        </Grid>


                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="openPoint"
                                placeholder="Open Point"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '150px',
                                    minHeight: '150px',
                                    padding: '10px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={form?.values?.openPoint || ""}
                                onChange={(e) => {
                                    form.setFieldValue("openPoint", e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DGSelect
                                id="module"
                                label="Source"
                                options={form.values.moduleOptions}
                                getOptionLabel={(option) => option?.study || ''}

                                value={form.values.moduleSelected}
                                onChange={(value) =>
                                    form.setFieldValue("moduleSelected", value)}
                            />
                        </Grid>



                        <Grid item xs={6}>

                            <DGDateInput
                                required
                                label="Expected Close By"
                                value={form.values.opExpectedDate || ""}
                                onChange={(val) => {
                                    form.setFieldValue("opExpectedDate", val);

                                }}
                            />

                        </Grid>

                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="ica"
                                placeholder="Immediate Containment Actions"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '150px',
                                    minHeight: '150px',
                                    padding: '10px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={form.values.ica || ""}

                                onChange={(e) => {
                                    form.setFieldValue("ica", e.target.value);

                                }}
                            />
                        </Grid>
                    </Grid >

                </Box >
            </Grid >


            <Grid item xs={5}>
                <Box
                    sx={{
                        border: '1px solid grey',
                        width: '98%',
                        padding: '5px',
                        marginTop: '5px',
                        //height: '70%',
                        marginRight: '2%',
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: "700px",
                        borderRadius: '25px'
                    }}
                >

                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        Actions
                    </Typography>

                    <Grid container spacing={2}>


                        <Grid item xs={7}>
                            <DGSelect
                                id="itemDescription"
                                label="Responsible"
                                options={form.values.actionByOptions}
                                getOptionLabel={(option) => option?.user_name || ''}
                                value={form.values.actionBySelected}
                                onChange={(value) => {
                                    form.setFieldValue("actionBySelected", value);

                                }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGDateInput
                                required
                                label="Target Date"
                                value={form.values.opTargetDate}
                                onChange={(val) => {
                                    form.setFieldValue("opTargetDate", val)
                                }}
                            />

                        </Grid>


                        <Grid item xs={12}>
                            <DGInput
                                label="Closure Criteria"
                                onChange={(e) => {
                                    form.handleChange(e);

                                }}
                                value={form.values.closureCriteria}
                                id="closureCriteria"
                                isFormik
                            />

                        </Grid>

                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="actions"
                                placeholder="Actions"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '200px',
                                    minHeight: '200px',
                                    padding: '10px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={form.values.actions || ""}
                                onChange={(e) => {
                                    form.setFieldValue("actions", e.target.value);

                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DGSelect
                                id="plant"
                                label="Action Benifiet"
                                options={form.values.actionImpactOptions}
                                getOptionLabel={(option) => option?.name || ''}
                                value={form.values.actionImpactSelected}
                                onChange={(value) => {
                                    form.setFieldValue("actionImpactSelected", value);


                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DGDateInput
                                label="Closure Date"
                                value={form.values.opClossureDate}
                                onChange={(val) => {
                                    form.setFieldValue("opClossureDate", val);

                                }}

                            />
                        </Grid>



                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="comments"
                                placeholder="Closure Comments"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '100px',
                                    minHeight: '100px',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    padding: '10px',
                                }}
                                value={form.values.comments || ""}
                                onChange={(e) => {
                                    form.setFieldValue("comments", e.target.value);

                                }}


                            />
                        </Grid>




                    </Grid>


                </Box>
            </Grid>



            <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
                <Divider />
                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>

                    <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={closeForm}
                    >
                        CANCEL
                    </Button>

                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={form.handleSubmit}
                    //   disabled={!(opVersionsFlag && changeFlag)}

                    >
                        SAVE
                    </Button>

                    {/*              <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handleClose}

                    >
                        Close
                    </Button> */}
                </Toolbar>
            </AppBar>

        </Grid >

    )

}