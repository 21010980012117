import React, { useRef, useState, useContext, forwardRef, useEffect } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, useHistory, useLocation, withRouter } from "react-router-dom";
import { darken } from "polished";
//import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import { AuthContext } from "../context/AuthContextProvider";
import {
  Chip,
  Grid,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
//import { sidebarRoutes as routes } from "../routes/index";
//import BrandLogo from "./Brand";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
//import IconButton from "@material-ui/core/IconButton";
import { SideBarProfile } from "../layouts/Sidebar/Profile";
//import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import LensIcon from "@material-ui/icons/Lens";
import { SubNavigationContext } from "../context/SubnavigationProvider";
import { GetRequiredUserMenus } from "../services/commonService";
import { DGSelect } from "./shared/DGSelect";


const StyledScrollbar = styled.div`
  background-color: ${(props) => props.theme.sidebar.background};
  overflow-y: scroll;
  height: 100%;
`;

const Scrollbar = forwardRef(({ children }, ref) => (
  <StyledScrollbar ref={ref}>
    {children}
  </StyledScrollbar>
));

const Drawer = styled(MuiDrawer)`

  > div {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }
`;

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;


const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(3)}px;
  padding-right: ${(props) => props.theme.spacing(3)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  border-radius: 8px;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 24px;
    width: 24px;
    height: 24px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: rgba(0, 171, 85, 0.08);
    color: #1a237e;

    span {
      color: #1a237e;
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 14px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
  &.${(props) =>
    (!props.isOpen && props.isCollapsable) || props?.activeClassName} {
    color: #1a237e;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  border-radius: 5px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: 14px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 1;
  display: block;
`;

const SidebarFooter = styled.div`
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
`;

const SidebarCategory = ({
  name,
  Icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  onClick = () => { },
  index = -2,
  path = [],
  ...rest
}) => {
  const location = useLocation();
  return (
    <div
      onClick={onClick}
      style={{
        marginTop: 3,
        backgroundColor: path?.includes(location.pathname) ? "#c5cae9" : "#FFF",
        borderRadius: 5,
      }}
    >
      <Category {...rest} isOpen={isOpen} isCollapsable={isCollapsable}>
        {path?.includes(location.pathname) ? (
          <Icon style={{ color: "#1a237e", fontSize: 24 }} />
        ) : (
          <Icon style={{ fontSize: 24 }} />
        )}
        <CategoryText {...rest} isOpen={isOpen} isCollapsable={isCollapsable}>
          {name}
        </CategoryText>
        {isCollapsable ? (
          isOpen ? (
            <ExpandMoreOutlinedIcon />
          ) : (
            <ChevronRightIcon />
          )
        ) : null}
        {badge ? <CategoryBadge label={badge} /> : ""}
      </Category>
    </div>
  );
};

const usestyle = makeStyles({
  subtitle2: {
    fontWeight: "bold",
  },
});

const SidebarLink = ({ name, to, badge, Icon, path = "" }) => {
  const location = useLocation();
  const classes = usestyle();
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      style={{ width: "100%", borderRadius: 5 }}
    >
      <Grid container>
        <Grid item md={2} sm={2} lg={2} justifyContent="center">
          <LensIcon
            style={{
              paddingRight: location?.pathname === path ? 5 : 0,
              fontSize: location?.pathname === path ? 15 : 8,
              color: location?.pathname === path ? "#1a237e" : "#637381",
            }}
          />
        </Grid>
        <Grid item md={10} sm={10} lg={10}>
          <Typography
            classes={classes?.subtitle2}
            variant="subtitle2"
            component="span"
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
      {/* "#919EAB" */}
    </Link>
  );
};

function renderSidebarItem(index, category, openRoutes, updateRoutes, history, toggle) {
  return (
    <React.Fragment key={index}>
      <SidebarCategory
        isOpen={openRoutes[index]}
        isCollapsable={true}
        name={category.id}
        Icon={category.icon}
        activeClassName="active"
        button={true}
        onClick={() => {
          history.push(category?.path);
          toggle(index);
        }}
        index={index}
        path={category?.children?.map((exp) => exp?.path)}
      />
      <Collapse
        in={openRoutes[index]}
        timeout="auto"
        style={{ paddingLeft: 20 }}
      >
        {category.children.map((routes, kindex) => (
          // console.log("Line 302", kindex, routes),
          < SidebarLink
            key={kindex}
            name={routes.name}
            to={routes.path}
            Icon={routes.icon}
            badge={routes.badge}
            index={kindex}
            path={routes?.path}
          />
        ))}
      </Collapse>
    </React.Fragment>
  );
}

function renderSidebarItem2(index, jindex, route, openRoutes, updateRoutes, history, toggle, toggle_) {
  return (
    <React.Fragment key={jindex}>
      {
        route.children ? (
          <React.Fragment key={jindex}>
            <SidebarCategory
              isOpen={openRoutes[index]}
              isCollapsable={true}
              name={route.id}
              Icon={route.icon}
              activeClassName="active"
              button={true}
              onClick={() => {
                //updateRoutes(route?.children ?? []);
                history.push(route?.path);
                toggle_(jindex);
              }}
              index={jindex}
              path={route?.children?.map((exp) => exp?.path)}
            />

            <Collapse
              in={openRoutes[index]}
              timeout="auto"
              style={{ paddingLeft: 20 }}
            >
              {route.children.map((routes, kindex) => (
                //  console.log("Line 346", kindex, routes),
                <SidebarLink
                  key={kindex}
                  name={routes.name}
                  to={routes.path}
                  index={kindex}
                  path={routes?.path}
                />
              ))}

            </Collapse>

          </React.Fragment>
        ) :
          (
            // console.log("Line 354", jindex, route),
            < SidebarCategory
              isCollapsable={false}
              name={route.id}
              to={route.path}
              activeClassName="active"
              component={NavLink}
              Icon={route.icon}
              exact
              button
              onClick={() => {
                updateRoutes([]);
                //toggle(jindex);

              }}
              index={jindex}
              path={[route?.path]}


            />

          )

      }
    </React.Fragment >
  )
}

const Sidebar = ({
  plants = [],
  pageTitle = "11",
  classes,
  staticContext,
  openRoutes,
  setOpenRoutes,
  openRoutes_,
  setOpenRoutes_,
  location,
  expandedIndex = -1,
  setExpandedIndex = () => { },
  selectedPlant = {},
  setSelectedPlant = () => { },
  ...rest
}) => {
  const { currentUser } = useContext(AuthContext);
  const { updateRoutes } = useContext(SubNavigationContext);
  const { updateRoutes2 } = useContext(SubNavigationContext);
  const [currentScrollPosition, setCurrentScrollPosition] = useState({
    beforeToggle: 0, afterToggle: 0,
  });

  const history = useHistory();
  const scrollRef = useRef();

  useEffect(() => {
    /*     setCurrentScrollPosition({
          ...currentScrollPosition,
          beforeToggle: scrollRef.current?.scrollTop || 0,
        }); */
    const handleScroll = () => {
      setCurrentScrollPosition({
        ...currentScrollPosition,
        afterToggle: scrollRef.current.scrollTop,
      });
    };
    scrollRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const toggle = (index) => {

    let currentstatus = openRoutes[index];
    Object.keys(openRoutes).forEach((key) => {
      openRoutes[key] = false;
    });

    openRoutes[index] = !currentstatus;
    setOpenRoutes({ ...openRoutes });
  };


  return (
    <Drawer variant="permanent" {...rest}>
      <Grid container style={{ padding: 4, justifyContent: 'center', }}  >
        <img src={currentUser.logo}
          alt="logo"
          style={{ height: 65, paddingLeft: 20 }} />
      </Grid>

      <SidebarFooter>
        <SideBarProfile
          phoneNumber={currentUser.phone_number}
          name={currentUser ? currentUser.name : ""}
        //logo={currentUser && currentUser.logo ? currentUser.logo : ""}
        />

      </SidebarFooter>

      <Grid item md={16} sm={16} lg={16} style={{ paddingRight: 16, paddingLeft: 16 }}>
        {(plants?.length > 2 && currentUser.user_role < 4)
          ? (
            <DGSelect
              size="medium"
              disableClearable={true}
              getOptionLabel={(opt) => opt?.name}
              //getOptionLabel={(opt) => opt?.id}
              options={plants}
              value={selectedPlant}
              onChange={setSelectedPlant}
              style={{ backgroundColor: 'white' }}
            />
          ) : null}
      </Grid>
      <Scrollbar ref={scrollRef}>
        <List style={{ paddingRight: 16, paddingLeft: 16 }}>
          <Items>
            {GetRequiredUserMenus(currentUser?.screen_flag).map(
              (category, index) => (
                <React.Fragment key={index}>
                  {category.header ? (
                    <SidebarSection>
                      {category.header}
                    </SidebarSection>
                  ) : null}

                  {category.levels === 0 && (
                    //  console.log("levels===0", category),
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      Icon={category.icon}
                      exact
                      button
                      badge={category.badge}
                      onClick={() => {
                        //  console.log("category.path 507", category.path);
                        updateRoutes([]);
                        toggle(index);
                      }}
                      index={index}
                      path={[category?.path]}
                    />
                  )}
                  {category.levels === 1 && (
                    renderSidebarItem(index, category, openRoutes, updateRoutes, history, toggle)
                  )}
                  {category.levels > 1 && (
                    // console.log("levels>1", category),
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={openRoutes[index]}
                        isCollapsable={true}
                        name={category.id}
                        Icon={category.icon}
                        activeClassName="active"
                        button={true}
                        onClick={() => {
                          //  updateRoutes(category?.children ?? []);
                          //  console.log("category.path 530", category.path);
                          history.push(category?.path);
                          toggle(index);
                        }}
                        index={index}
                        path={category?.children?.map((exp) => exp?.path)}
                      />

                      <Collapse in={openRoutes[index]}
                        timeout="auto" style={{ paddingLeft: 10 }}>
                        {category.children && category.children.map((route, jindex) => (
                          <React.Fragment key={jindex}>
                            {renderSidebarItem2(index, jindex, route, openRoutes, updateRoutes, history, toggle)}
                          </React.Fragment>
                        ))}
                      </Collapse>


                    </React.Fragment>
                  )}

                </React.Fragment>
              )
            )}
          </Items>
        </List>


      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(Sidebar);
