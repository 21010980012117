import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import ImageIcon from "@material-ui/icons/Image";
import React from "react";

export function ProductInfo({
  product_info = [{ Part: "", Character: "", Specification: "" }],
}) {
  return (
    <div style={{width:"100%"}}>
      <Typography variant="h5" gutterBottom align="center" style={{backgroundColor:indigo[100]}}>
        PRODUCT INFORMATION
      </Typography>
      <List>
        <Divider />
        <ListItem>
          <ListItemText primary={"Part"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={product_info[0]?.Part}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Character"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={product_info[0]?.Character}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Specification"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={product_info[0]?.Specification}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}
