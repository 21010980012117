import {
  Box,
  Button as MuiButton,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Field, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContextProvider";
import machineService from "../../services/machineService";
import plantService from "../../services/plantService";
import processService from "../../services/processService";

const AddProcessDialog = ({
  addProcessDialog,
  setAddProcessDialog,
  onSuccess,
}) => {
  const { v4: uuidv4 } = require("uuid");
  const Divider = styled(MuiDivider)(spacing);
  const Button = styled(MuiButton)(spacing);
  const Alert = styled(MuiAlert)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  const Spacer = styled.div(spacing);
  const [machines, setMachines] = useState([
    { id: "add_new_machine", name: "ADD NEW MACHINE" },
  ]);
  const [addNewMachine, setAddNewMachine] = useState(false);
  const [plants, setPlants] = useState();
  const { currentUser } = useContext(AuthContext);

  const timeOut = (time) => new Promise((res) => setTimeout(res, time));
  const initialValues = {
    id: "",
    name: "",
    machine_id: "",
    machineNumber: "",
    machineName: "",
    spindle: "",
    plant: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    machine_id: Yup.string().required("Required"),
  });

  useEffect(() => {
    getAllMachines();
    getAllPlants();
  }, []);

  const getAllMachines = async () => {
    try {
      const response = await machineService.getMachines(currentUser.client_id);
      if (response && response?.data) {
        setMachines([...machines, ...response?.data.data]);
      }
    } catch (error) {
      // console.log("🚀  -> file: AddProcessDialog.js -> line 80 -> getAllMachines -> error", error);
    }
  };

  const getAllPlants = async () => {
    try {
      const response = await plantService.getPlants(currentUser.client_id);
      if (response && response.data) {
        setPlants(response?.data?.data);
      }
    } catch (error) {
      // console.log("🚀  -> file: AddProductDialog.js -> line 69 -> getAllPlants -> error", error);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    // console.log("🚀  -> file: AddProcessDialog.js -> line 77 -> values", values);
    try {
      await timeOut(1500);
      resetForm();
      setAddNewMachine(false);
      setStatus({ sent: true });
      setSubmitting(false);
      let processId = uuidv4();
      let newMachineId = uuidv4();
      const createProcessObj = {
        id: processId,
        name: values.name,
        client_id: currentUser.client_id,
      };
      const createProcessResponse = await processService.createProcess(
        createProcessObj
      );
      if (createProcessResponse && createProcessResponse.status === 201) {
        let processMachineResponse;
        if (values.machine_id !== "add_new_machine") {
          const selectedMachineObj = {
            id: newMachineId,
            process_id: processId ? processId : null,
            machine_id: values.machine_id ? values.machine_id : null,
          };
          processMachineResponse = await processService.createProcessMachine(
            selectedMachineObj
          );
        } else {
          const newMachineObj = {
            id: newMachineId,
            name: values.machineName,
            machine_number: values.machineNumber,
            spindel: values.spindle,
            plant_id: values.plantId ? values.plantId : null,
            client_id: currentUser.client_id,
          };
          const createMachineResponse = await machineService.createMachine(
            newMachineObj
          );
          if (createMachineResponse && createMachineResponse.status === 201) {
            const selectedNewMachineObj = {
              id: uuidv4(),
              process_id: processId,
              machine_id: newMachineId,
            };
            processMachineResponse = await processService.createProcessMachine(
              selectedNewMachineObj
            );
          }
        }
        if (processMachineResponse && processMachineResponse.status === 201) {
          resetForm();
          setAddProcessDialog(false);
          onSuccess(true);
          processId = "";
          newMachineId = "";
        }
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={addProcessDialog}
      onClose={() => setAddProcessDialog(false)}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
    >
      <DialogTitle id="form-dialog-title">Add Process</DialogTitle>
      <DialogContent pb={4}>
        <DialogContentText>
          Please fill in the following details to add a process
        </DialogContentText>
        <Spacer my={6} />
        <Formik
          initialValues={initialValues}
          //validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
            setFieldValue,
          }) => (
            <Card mb={6}>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Your data has been submitted successfully!
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <TextField
                        name="name"
                        label="Process Name"
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Autocomplete
                        id="machine_id"
                        options={machines}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <Field
                            component={TextField}
                            {...params}
                            name="machine_id"
                            label="Type"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        onChange={(e, value) => {
                          if (value.id === "add_new_machine") {
                            setAddNewMachine(true);
                          } else {
                            setAddNewMachine(false);
                          }
                          setFieldValue("machine_id", value ? value.id : "");
                        }}
                      />
                    </Grid>
                  </Grid>
                  {addNewMachine && (
                    <>
                      <Grid container spacing={6}>
                        <Grid item md={6}>
                          <TextField
                            name="machineNumber"
                            label="Machine Number"
                            value={values.machineNumber}
                            error={Boolean(
                              touched.machineNumber && errors.machineNumber
                            )}
                            fullWidth
                            helperText={
                              touched.machineNumber && errors.machineNumber
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            name="machineName"
                            label="Machine Name"
                            value={values.machineName}
                            error={Boolean(
                              touched.machineName && errors.machineName
                            )}
                            fullWidth
                            helperText={
                              touched.machineName && errors.machineName
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            name="spindle"
                            label="Spindle"
                            value={values.spindle}
                            error={Boolean(touched.spindle && errors.spindle)}
                            fullWidth
                            helperText={touched.spindle && errors.spindle}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Autocomplete
                            id="plantId"
                            options={plants}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <Field
                                component={TextField}
                                {...params}
                                name="plantId"
                                label="Plant"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue("plantId", value ? value.id : "");
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Divider my={6} />
                  <div className="d-flex justify-content-end">
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      mr={3}
                      mb={3}
                      position="right"
                      onClick={() => setAddProcessDialog(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mb={3}
                      position="right"
                      disabled={
                        !(
                          values.name &&
                          values.machineNumber &&
                          values.machineName &&
                          values.spindle
                        ) ||
                        errors.machine_id ||
                        errors.plantId
                      }
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddProcessDialog;
