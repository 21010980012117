import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../../../../components/CommonGrid";
import { DGInput } from "../../../../../../components/shared/DGInput";
import { DGSelect } from "../../../../../../components/shared/DGSelect";
import initiateStudyService from "../../../../../../services/initiateStudyService";

export function KappaStudies({
  gaugeCharacter = {},
  studyId = -1,
  usersList = [],
  appraiser,
  trails,
  samples,
  appraiserNames,
  selectedUser,
  msaCsr,
  rows,
  columns,
  mastervalue,
  setAppraiser = () => { },
  setTrails = () => { },
  setSamples = () => { },
  setAppraiserNames = () => { },
  setSelectedUser = () => { },
  setMsaCsr = () => { },
  onGridReady = () => { },
  setRows = () => { },
  setColumns = () => { },
  isGridGenerated = false,
  setGridGenerated = () => { },
  setMasterValue = () => { },
  characterId = "",
  closeForm = () => { },
  handleNext = () => { },
}) {
  const [expanded, setExpanded] = useState(-1);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    if (!isGridGenerated) {
      generateGrid(trails, appraiser, samples);
    }
  }, []);

  useEffect(() => {
    if (expanded === 2) {
      generateGrid(Number(trails), Number(appraiser), Number(samples));
    }
  }, [expanded]);

  useEffect(() => {
    if (!msaCsr) {
      getMsaCsr(characterId, studyId);
    }
  }, [characterId]);

  function getMsaCsr(characterId, studiesId) {
    initiateStudyService.getMSACSR(characterId, studiesId).then((res) => {
      let data = res?.data[0];
      if (data) {
        setMsaCsr(data);
      }
    });
  }

  const generateGrid = async (trialsCount, appraisersCount, samplesCount) => {
    let columns = [];
    for (let i = 0; i <= appraiserNames.length - 1; i++) {
      const childrens = [];
      for (let j = 0; j < trialsCount; j++) {
        childrens.push({
          field: `${appraiserNames[i]}-${i}-Trial ${j + 1}`,
          headerName: `Trial ${j + 1}`,
          minWidth: 150,
          resizable: true,
          editable: true,
          cellStyle: { border: "1px solid #F2F2F2" },
          valueParser: (params) => {
            let c = Number(params.newValue);
            if (c === 0 || c === 1) return `${c}`;
            return null;
          },
        });
      }
      columns.push({
        headerName: appraiserNames[i],
        childrens: childrens,
      });
    }
    const array = [];
    for (let k = 1; k <= samplesCount; k++) {
      let x = {};
      for (let i = 0; i <= appraiserNames.length - 1; i++) {
        for (let j = 0; j < trialsCount; j++) {
          x[`${appraiserNames[i]}-${i}-Trial ${j + 1}`] = "";
        }
      }
      array.push(x);
    }
    let num = -1;
    const data = [];
    columns.map((value, index) => {
      if (index % trialsCount === 0) {
        ++num;
      }
      data.push({
        headerName: value.headerName,
        field: value.headerName,
        cellStyle: { textAlign: "center" },
        children: value.childrens,
        minWidth: 200,
        resizable: true,
        cellRenderer: "agGroupCellRenderer",
      });
    });

    data.unshift({
      field: "refValue",
      headerName: "Ref Value",
      minWidth: 130,
      editable: true,
    });
    data.unshift({
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      minWidth: 130,
    });

    if (!isGridGenerated) {
      setRows(array);
    }
    setColumns(data);
    setGridGenerated(true);
  };

  function processCellFromClipboard(params) {
    if (Number(params.value) === 0 || Number(params.value) === 1) {
      return `${params.value}`;
    }
    return "";
  }

  return (
    <>
      <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Basic Details</Typography>


      <Grid container spacing={2}>
        <Grid item md={2} sm={2}>
          <DGInput
            type="number"
            label="Number of Appraisers"
            value={appraiser}
            error={Number(appraiser) < msaCsr?.kappa_min_appraiser}
            onChange={setAppraiser}
            required
          />
        </Grid>
        <Grid item md={2} sm={2}>
          <DGInput
            type="number"
            label="Number of Trails"
            value={trails}
            error={Number(trails) < msaCsr?.kappa_min_trials}
            onChange={setTrails}
            required
          />
        </Grid>
        <Grid item md={2} sm={2}>
          <DGInput
            type="number"
            label="Number of Samples"
            value={samples}
            error={Number(samples) < msaCsr?.kappa_min_sample}
            onChange={setSamples}
            required
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGSelect
            label="Approver"
            options={usersList}
            value={selectedUser}
            onChange={setSelectedUser}
            required
          />
        </Grid>
      </Grid>



      <Typography variant="h6" align="left"
        style={{ marginBottom: '16px', marginTop: '16px' }}>Enter Appraiser</Typography>
      <Grid container spacing={2}>
        {appraiserNames &&
          appraiserNames.map((exp, i) => (
            <Grid item md={4} sm={4}>
              <DGInput
                label="Name of Appraisers"
                value={appraiserNames[i]}
                onChange={(val) => {
                  appraiserNames[i] = val;
                  setAppraiserNames([...appraiserNames]);
                }}
              />
            </Grid>
          ))}
      </Grid>

      <Accordion
        style={{ width: "100%" }}
        square
        expanded={expanded === 2}
        onChange={handleChange(2)}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h6" align="left" style={{ marginBottom: '16px' }}>Enter Values</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} lg={12}>
              <CommonGrid
                rows={rows}
                columns={columns}
                onGridReady={onGridReady}
                processCellFromClipboard={processCellFromClipboard}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>
          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleNext()}
          >
            NEXT
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
