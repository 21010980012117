import React from "react";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import DrawData from "../dataSet/drawData.json";

const CardForDraw = ({ title, param, studyData, studyType }) => {
  const customStyle = {
    minHeight: "170px",
  };

  return (
    <React.Fragment>
      {studyType !== "Attribute system analysis: Kappa Method" &&
      studyType !== "Attribute system analysis: Signal Method" ? (
        <Paper
          variant="outlined"
          square
          elevation={0}
          style={{ width: "100%", marginRight: 16 }}
        >
          <Typography variant="h6" style={{ padding: 8 }} className="bg-light">
            {title}
          </Typography>
          {studyData && studyData.result ? (
            <Table aria-label="simple table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Upper Specification</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>
                        {parseFloat(
                          studyData.result.upper_specification
                        ).toFixed(4)}
                      </b>
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Lower Specification</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>
                        {parseFloat(
                          studyData.result.lower_specification
                        ).toFixed(4)}
                      </b>
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Tolerance</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>{parseFloat(studyData.result.tolerance).toFixed(4)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : null}
        </Paper>
      ) : null}
    </React.Fragment>
  );
};

export default CardForDraw;
