import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { object, string, number } from "yup";

import { DGSelect } from "../../../../components/shared/DGSelect";
import { DGInput } from "../../../../components/shared/DGInput";
import CommonGrid from "../../../../components/CommonGrid";

import processParameter from "../../../../services/processParameter"
import { AuthContext } from "../../../../context/AuthContextProvider";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer"

export function ProcessParameterForm({
  Footer = () => null,
  componentProps = null,
  closeForm = () => { },
}) {
  const [gridApi, setGridApi] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);

  let form = useFormik({
    initialValues: {
      machineoptions: [],
      parameteroptions: [],
      gridData: []
    },
    onSubmit: onSave,
  });


  let detailForm = useFormik({
    initialValues: {
      machine: null,
      parameter: null,
      target: "",
      unit: "",
      upper: "",
      lower: "",
    },
    validationSchema: object().shape({
      machine: object().required(),
      parameter: object().required(),
      unit: string().required(),
      target: string().required(),
      upper: number().when("parameter", {
        is: (obj) => obj?.unit_id > 500,
        then: number().notRequired(),
        otherwise: number().required(),
      }),
      lower: number().when("parameter", {
        is: (obj) => obj?.unit_id > 500,
        then: number().notRequired(),
        otherwise: number()
          .required()
          .test(
            'less-than-greater',
            'Value should be less than the Upper value',
            function (value) {
              const { upper } = this.parent;
              return value < upper;
            }
          ),
      }),
    }),
    onSubmit: onAdd,
  });

  function onAdd() {
    form.setFieldValue("gridData", [
      ...form.values.gridData,
      detailForm.values,
    ]);
    detailForm.resetForm();
  }


  useEffect(() => {
    loadMachines(componentProps?.partId);
  }, [componentProps?.partId]);

  function removeDetailRow(index, rowData) {
    let data = form.values.gridData;
    data.splice(index, 1);
    gridApi.updateRowData({ remove: [rowData] });
    form.setFieldValue("gridData", data);
  }


  function loadMachines(partId) {
    processParameter.ProcessPartSelectCharacter(partId).then((res) => {
      form.setFieldValue("machineoptions", res?.data ?? []);
    });
  }

  //(form.values.parameteroptions)

  useEffect(() => {
    if (detailForm.values.machine) {
      loadParameters();
    }
  }, [detailForm.values.machine])



  function loadParameters() {
    processParameter.ProcessPartSelectProcess({
      machine_id: detailForm.values.machine.machine_id,
      part_id: componentProps?.partId,
      new_process: null
    })
      .then((res) => {
        form.setFieldValue("parameteroptions", res?.data ?? []);
      });
  }



  function onGridReady(params) {
    setGridApi(params.api);
  }



  function onSave() {
    let payload = [];
    gridApi?.forEachNode((node) => {
      let data = node?.data;
      payload.push({
        part_id: componentProps?.partId,
        machine_id: data?.machine?.machine_id,
        machine_parameter_id: data?.parameter?.machine_parameter_id,
        target: data?.target,
        upper_limit: data?.upper,
        lower_limit: data?.lower,
        created_by: currentUser?.id,
      });
    });


    processParameter
      .ProcessParameterAddSpec(payload)
      .then((res) => {
        closeForm();
      })
      .then((res) => {
        updateAlert({
          open: true,
          message: "Parameter updated saved Successfully",
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          open: true,
          message: "Failed to update Parameter",
          type: "error",
        });
      });
    ;



  }

  const columns = [
    {
      cellRenderer: DeleteRenderer,
      maxWidth: 75,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
    },
    {
      field: "parameter.parameter_name",
      headerName: "Parameter",
      editable: true,
      minWidth: 150,
      resizable: true,
    },
    {
      field: "target",
      headerName: "Target",
      editable: true,
      minWidth: 150,
      resizable: true,
    },
    {
      field: "upper",
      headerName: "Upper",
      editable: true,
      minWidth: 150,
      resizable: true,
    },
    {
      field: "lower",
      headerName: "Lower",
      editable: true,
      minWidth: 150,
      resizable: true,
    },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          label="Machine"
          value={detailForm.values.machine}
          options={form.values.machineoptions}
          getOptionLabel={(opt) =>
            opt?.machine_number + " " + opt?.machine_name
          }
          onChange={(val) => {
            detailForm.setFieldValue("machine", val);
          }}
          required
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          label="Parameter"
          value={detailForm.values.parameter}
          options={form.values.parameteroptions}
          onChange={(val) => {
            detailForm?.setFieldValue("parameter", val);
            detailForm?.setFieldValue("unit", val?.parameter_unit);
          }}
          getOptionLabel={(opt) => opt?.parameter_name ?? ""}
          required
          disabled={form.values.parameteroptions === null}
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          isFormik
          id="unit"
          label="Units"
          value={detailForm.values.unit}
          disabled
          required
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          isFormik
          id="target"
          label="Target"
          value={detailForm.values.target}
          onChange={detailForm?.handleChange}
          required
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          isFormik
          id="upper"
          label="Upper"
          value={detailForm.values.upper}
          onChange={detailForm?.handleChange}
          type="number"
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGInput
          isFormik
          id="lower"
          label="Lower"
          value={detailForm.values.lower}
          onChange={detailForm?.handleChange}
          type="number"
        />
      </Grid>
      <Grid
        item
        md={2}
        sm={2}
        lg={2}
        style={{ textAlign: "center", marginTop: 8 }}
      >
        <Button
          style={{ paddingLeft: 40, paddingRight: 40 }}
          color="primary"
          variant="contained"
          onClick={onAdd}
          disabled={!detailForm.isValid || !detailForm?.dirty
          }
        >
          Add
        </Button>
      </Grid>
      <Grid item md={12} lg={12} sm={12}>
        <CommonGrid
          onGridReady={onGridReady}
          needExport={false}
          rows={form.values.gridData}
          columns={columns}
          horizontalTab={true}
        />
      </Grid>
      <Footer onSave={onSave} />
    </Grid>
  );
}
