import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Dialog,
  Grid,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import styled from "styled-components/macro";
import { DGInput } from "../../../components/shared/DGInput";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import uploadService from "../../../services/uploadService";
import piService from "../../../services/piService";
import { getErrors, hasErrors } from "../../../services/commonService";
import moment from "moment";

export function RecordCalibration({
  isOpen = false,
  setOpen = () => { },
  gauge_item = {},
  setRecordOpen = () => { },
}) {
  let { currentUser } = useContext(AuthContext);
  const [uploadedCertificate, setUploadedCertificate] = useState();
  const [comments, setComments] = useState("");
  const [calibratedDate, setCalibratedDate] = useState(new Date());
  const [certificateNumber, setCertNumber] = useState("");
  const Spacer = styled.div(spacing);
  const [errorModel, setErrorModel] = useState({ calibratedDate: "" });

  function handleSubmit(values) {
    let payload = {
      created_by: currentUser?.id,
      calibrated_date: moment(calibratedDate).format("YYYY/MM/DD"),
      calibration_comments: comments,
      pi_request_id: gauge_item?.pi_request_id,
      threeP_certificate: certificateNumber,
      guage_item: gauge_item?.gauge_item_id,
      activity: "record",
      certificate_link: "",
    };
    if (hasErrors({ calibratedDate: calibratedDate })) {
      return setErrorModel(getErrors({ calibratedDate: calibratedDate }));
    }
    if (uploadedCertificate && uploadedCertificate?.length) {

      uploadService.uploadFile({
        files: uploadedCertificate,
        client_id: currentUser?.client_id
      })
        //uploadService.uploadFile(uploadedCertificate)
        .then((uploadres) => {
          payload["certificate_link"] = uploadres?.data?.files[0]?.location ?? "";
          if (payload["certificate_link"]) {
            piService.createPIActivity(payload).then((res) => {
              setOpen(true, true);
              setRecordOpen(false);
            });
          }
        });
    } else {
      piService.createPIActivity(payload).then((res) => {
        setOpen(true, true);
        setRecordOpen(false);
      });
    }
  }
  return (
    <>
      {" "}
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        disableBackdropClick={true}
        fullWidth
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#0d51ca",
            color: "#fff",
          }}
        >
          <DialogTitle id="form-dialog-title">
            Upload the Calibration Result Certificate below
          </DialogTitle>
          <div>
            <Button onClick={() => setOpen(false)}>
              <CloseRoundedIcon style={{ fill: "#fff" }} />
            </Button>
          </div>
        </div>
        <DialogContent pb={4}>
          <Spacer my={6} />
          <Formik
            initialValues={{ id: "", calibrated_date: null }}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              setFieldValue,
            }) => (
              <Card mb={6}>
                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    Your data has been submitted successfully!
                  </Alert>
                )}

                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <DropzoneArea
                          showFileNamesInPreview={true}
                          showFileNames={true}
                          showAlerts={true}
                          onChange={(file) => setUploadedCertificate(file)}
                          acceptedFiles={["image/*", "application/*"]}
                          filesLimit={1}
                          maxSize={10971520}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <DGDateInput
                          required
                          error={errorModel?.calibratedDate}
                          variant="outlined"
                          label="Calibrated Date"
                          value={calibratedDate}
                          onChange={setCalibratedDate}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <DGInput
                          label="Certificate Number"
                          variant="outlined"
                          value={certificateNumber}
                          onChange={setCertNumber}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <TextareaAutosize
                          aria-label="comments"
                          placeholder="Add your comments here"
                          minRows={6}
                          className="border border-2"
                          style={{
                            width: "100%",
                            // borderColor: "#bbb",
                            borderRadius: "5px",
                          }}
                          onChange={(e) => setComments(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Divider my={6} />
                    <div className="d-flex justify-content-end">
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        position="right"
                        onClick={() => setOpen(false)}
                        style={{ margin: 1 }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        mb={3}
                        position="right"
                        style={{ margin: 1 }}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </Card>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
