import React, { Component } from "react";
import Slider from "react-slick";
import "./SlickSlider.css"

class SimpleSlider extends Component {
    render() {
        const settings = {
            className: "slider variable-width",
            dots: false,
            arrow: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            autoplay: true,
            // speed: 5000,
            autoplaySpeed: 5000,
            pauseOnHover: true
        };




        return (

            <div >
                <Slider {...settings} >
                    {this.props.children}
                </Slider>
            </div>
        );
    }
}

export default SimpleSlider;

