import { Card, Grid, Paper } from "@material-ui/core";
import React from "react";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import useWindowSize from "../../../hooks/windowSize.hook";

export function UserSetting() {
  const size = useWindowSize();
  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={4} lg={4} style={{ height: size.height - 110 }}>
        <Card style={{ height: "100%" }}></Card>
      </Grid>

      <Grid item md={8} sm={8} lg={8}>
        <Paper className="w-100 h-100 p-2">
          <Grid item container md={12} sm={12} spacing={6}>
            <Grid item md={6} sm={6} lg={6}>
              <DGInput label="Name" />
            </Grid>
            <Grid item md={6} sm={6} lg={6}>
              <DGInput label="Email" />
            </Grid>
            <Grid item md={6} sm={6} lg={6}>
              <DGInput label="Phone Number" />
            </Grid>
            <Grid item md={6} sm={6} lg={6}>
              <DGSelect label="Default Plant" />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <DGInput label="Company" disabled />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <DGInput label="Company Address" multiline rows={5} disabled />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
