import React, { useEffect, useState } from "react";
import DataGridTable from "../../components/DataGridTable";
import Card from "@material-ui/core/Card";
import { Button as MuiButton } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import piService from "../../services/piService";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import UploadCertificateDialog from "./UploadCertificateDialog";
import CertificateViewRenderer from "./../../components/cellRenderers/CertificateViewRenderer";
import CertificateUploadRenderer from "./../../components/cellRenderers/CertificateUploadRenderer";
import CommonGrid from "../../components/CommonGrid";

const VendorClientsList = ({
  piHistoryList,
  customerList = [],
  ButtonComponent = () => null,
  onRowSelection = () => {},
}) => {
  const [rows, setRows] = useState([]);
  const [selectedPIHistory, setSelectedPIHistory] = useState();
  const [addUploadCertificateDialog, setAddUploadCertificateDialog] = useState(
    false
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const Button = styled(MuiButton)(spacing);

  useEffect(() => {
    if (piHistoryList) {
      // listPIHistoryToRows();
      setRows(piHistoryList);
      //console.log("Getting PI Requests List", piHistoryList);
    }
  }, [piHistoryList]);

  const getDateString = (inDate) => {
    try {
      const date = new Date(inDate);
      return (
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
        "/" +
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) +
        "/" +
        date.getFullYear()
      );
    } catch (error) {
      // console.log("error in getDateString", error)
      return null;
    }
  };

  const getSortedList = async (list) => {
    if (list?.length) {
      try {
        const res = await list.sort(
          (item1, item2) =>
            new Date(item1.date_for_sorting_purpose).getTime() -
            new Date(item2.date_for_sorting_purpose).getTime()
        );
        return res;
      } catch (error) {
        // console.log('error in getSortedList PIList.js', error)
      }
    } else {
      return list;
    }
  };

  const listPIHistoryToRows = async () => {
    let items = [];
    await Promise.all(
      piHistoryList.map(async (pi) => {
        // console.log("pi-request",pi?.requested_date,"vendor created",customerList[0]?.created_at)
        if (
          (pi?.gauge_item?.guage_status_id == 2 ||
            pi?.gauge_item?.guage_status_id == 3) &&
          new Date(customerList[0]?.created_at) <= new Date(pi?.requested_date)
        ) {
          const response = await piService.getPIResultByPIRequestId(pi.id);
          items.push({
            id: pi.id,
            date_for_sorting_purpose: pi.requested_date,
            comments: response?.data?.data[0]?.comments,
            calibration_comments: response?.data.data[0]?.calibration_comments,
            serial_number: pi.gauge_item?.serial_number,
            gauge_item: pi.gauge_item,
            gauge_name: pi.gauge_item?.guage?.name,
            pi_agency: pi.gauge_item?.pi_agency?.business_name,
            pi_last_date: pi.gauge_item?.pi_last_date
              ? getDateString(pi.gauge_item.pi_last_date)
              : null,
            piResultId:
              response.data && response.data.data && response.data.data[0]
                ? response.data.data[0].id
                : null,
            requested_date: pi.requested_date
              ? getDateString(pi.requested_date)
              : null,
            certificate_link:
              response.data && response.data.data && response.data.data[0]
                ? response.data.data[0].certificate_link
                : null,
            status:
              response.data && response.data.data && response.data.data[0]
                ? response.data.data[0].status.toUpperCase()
                : "Under Inspection",
          });
        }
      })
    );
    // console.log("Items", items);
    const sortedList = await getSortedList(items);
    setRows(sortedList);
  };

  const handleOpenDialog = (item) => {
    setSelectedPIHistory(item);
    setAddUploadCertificateDialog(true);
  };

  const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
    maxValidYear: 2021,
  };

  const columns = [
    {
      field: "serial_number",
      headerName: "Serial #",
      // valueGetter: (params) => Number(params.node.id) + 1,
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "report_name",
      headerName: "Report Number",
      filter: "agTextColumnFilter",
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      resizable: true,
      floatingFilter: true,
    },
    {
      field: "serial_number",
      headerName: "Gauge Number",
      filter: "agTextColumnFilter",
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
    },
    {
      field: "gauge_name",
      headerName: "Gauge Name",
      filter: "agTextColumnFilter",
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
    },
    {
      field: "plant_name",
      headerName: "Plant Id",
      filter: "agTextColumnFilter",
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
    },
    {
      field: "requested_date",
      headerName: "Requested Date",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.requested_date
          ? new Date(params.data.requested_date).toLocaleDateString()
          : "",
    },
    {
      field: "calibrated_date",
      headerName: "Calibrated Date",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      valueGetter: (params) =>
        params?.data?.calibrated_date
          ? new Date(params.data.calibrated_date).toLocaleDateString()
          : "",
    },
    {
      field: "certificate_link",
      headerName: "Certificate Link",
      filter: "agTextColumnFilter",
      lockPinned: true,
      pinned: "left",
      cellClass: "lock-pinned",
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
    },
  ];

  return (
    //console.log("Rows", rows),
    <React.Fragment>
      <Card style={{ height: "auto", width: "100%", padding: "1rem" }}>
        <CommonGrid
          columns={columns}
          rows={rows}
          gridheight={300}
          // columnToolpanel
          ButtonComponent={ButtonComponent}
          onRowSelect={onRowSelection}
        />
      </Card>
      {addUploadCertificateDialog && (
        <UploadCertificateDialog
          addUploadCertificateDialog={addUploadCertificateDialog}
          setAddUploadCertificateDialog={setAddUploadCertificateDialog}
          piHistory={selectedPIHistory}
          onSuccess={() => {
            setAddUploadCertificateDialog(false);
            listPIHistoryToRows();
          }}
        />
      )}
    </React.Fragment>
  );
};

export default VendorClientsList;
var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
