function Dashboards() {
  return (
    <div style={{ backgroundColor: "#FFF9F2" }}>
      <div
        style={{
          textAlign: "justify",
          paddingLeft: "0.8%",
          margin: "1% 10% 0% 10%",
        }}
      >
        <div>
          <div>
            <div>
              <h2
                style={{
                  fontSize: "32px",
                  textDecoration: "none",
                  borderBottom: "1px solid",
                  fontWeight: "bold",
                }}
              >
                About Us
              </h2>

              <p
                style={{
                  fontSize: "19px",
                }}
              >
                <span style={{ fontSize: "19px" }}>
                  We are start up based out of Bangalore (Where else ?).
                </span>
              </p>

              <p
                style={{
                  fontSize: "19px",
                }}
              >
                <span style={{ fontSize: "19px" }}>
                  Our team members have expertise in “Statistics - Domain -
                  Computing”, which helps us to develop applications which adds
                  value to the supply chain.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <h1 style={{ fontSize: "1.5rem", marginBottom: "10rem" }}>
            <a
              href="mailto:solutions@digitalgemba.in"
              style={{
                textDecoration: "none",
                color: "#163254",
              }}
            >
              solutions@digitalgemba.in
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Dashboards;
