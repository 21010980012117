import http from "./httpService";

const createStudyInput = (data) => {
  return http.post("/study-input", data);
};


const getStudyInput = (Id) => {
  return http.get("/study-input", {
    params:{
      id:Id
    }
  });
};



const getAllStudies = () => {
  return http.get("/studies");
};


const getAllStudiesMSA = () => {
  return http.get("/studies", {
    params: {
      //group: 'MSA',
      'id[$lte]': 6,
      'id[$gte]': 2
    }
    }
  );
};

const getMeasurementType = () => {
  return http.get("/studies", {
    params: {
      'id[$lte]': 304,
      'id[$gte]': 301
    }
    }
  );
};




// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createStudyInput,
  getAllStudies,
  getAllStudiesMSA,
  getStudyInput,
  getMeasurementType
};