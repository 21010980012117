import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@material-ui/core";
import { DGSelect } from "../../../components/shared/DGSelect";
import customerDashboardService from "../../../services/customerDashboardService";
import HC_more from "highcharts/highcharts-more";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import { AuthContext } from "../../../context/AuthContextProvider";
import { useParams } from "react-router-dom";

HC_more(Highcharts);
export function CustomerDispatchInspections() {
  let form = useFormik({
    initialValues: {
      dropdown1: null,
      dropdownoptions1: [],
    },
  });
  let { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    customerDashboardService
      .getCustomerPDISelect(id, currentUser?.id)
      .then((res) => {
        form.setFieldValue("dropdownoptions1", res?.data ?? []);
      });
  }, []);

  function loadGraph(char_id) {
    customerDashboardService
      .getCustomerPDIPlot(id, currentUser?.id, char_id)
      .then((res) => {
        setChartOptions((prev) => ({
          ...prev,
          series: [
            {
              name: `${""} - ${""}`,
              data: res?.data ?? [],
              showInNavigator: true,
            },
          ],
        }));
      });
  }

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "dumbbell",
      inverted: false,
      zoomType: "xy",
      panning: {
        enabled: false,
        type: "xy",
      },
      height: 550,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          "<b>" +
          this.point.request_name +
          "</b>" +
          "</br>" +
          "HIGH <b>" +
          this.point.high +
          "</b>" +
          " " +
          "LOW <b>" +
          this.point.low +
          "</b>"
        );
      },
    },
    legend: {
      enabled: false,
    },
    time: {
      timezone: "Asia/Kolkata",
    },
    title: {
      text: `${""} - ${""}`,
    },
    xAxis: {
      type: "category",
      title: {
        text: "Production Date",
      },
      labels: {
        formatter: function (params) {
          return new Date(this.value).toLocaleDateString();
        },
      },
    },
    time: {
      timezone: "Asia/Kolkata",
    },
    yAxis: {
      max: null,
      min: null,
      title: {
        text: "Values",
      },
    },
    plotOptions: {
      series: {
        lineColor: "#303030",
      },
    },
    series: [
      {
        name: "Product Inspection",
        data: [],
      },
    ],
  });

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          options={form.values.dropdownoptions1}
          value={form.values.dropdown1}
          onChange={(val) => {
            form.setFieldValue("dropdown1", val);
            loadGraph(val?.id);
          }}
          label="Character"
          disabled={form.values.dropdownoptions1?.length === 0}
          getOptionLabel={(opt) =>
            `${opt?.name} ${opt?.specification} ${opt?.upper_specification} ${opt?.lower_specification}`
          }
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Grid>
    </Grid>
  );
}
