import React, { useState, createContext } from "react";

const AlertContext = createContext({
  open: false,
  message: "",
  type: "",
  updateAlert: () => {},
  resetAlert: () => {},
});

export const AlertContextProvider = ({ children }) => {
  const [alertObject, setAlertObject] = useState({
    open: false,
    onClose: () => {},
    resetAlert: resetAlert,
    updateAlert: updateAlert,
  });

  function resetAlert() {
    setAlertObject((prev) => ({
      ...prev,
      open: false,
      message: "",
      type: "",
      onClose: () => {},
    }));
  }
  function updateAlert(obj) {
    setAlertObject((prev) => ({ ...prev, ...obj }));
  }

  return (
    <AlertContext.Provider
      value={{
        ...alertObject,
        updateAlert: updateAlert,
        resetAlert: resetAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
export { AlertContext };
