import axios from "axios";
import { BaseUrlFeather } from "../constants/index";
import { getAccessToken } from "./authService";
import { history } from "../routes/Routes";
import "./loading.css";
import { startSpinner, stopSpinner } from "../redux/actions/globalAction";

const axiosInstance = axios.create({
  baseURL: BaseUrlFeather,
});
var count = 0;

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken();
    if (token) {
      count = count + 1;
      if (count > 0 && count === 1) {
        startSpinner();
      }
      config.headers.Authorization = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (res) => {
    count = count - 1;
    if (count === 0) {
      stopSpinner();
    }
    return res;
  },
  function (error) {
    count = count - 1;
    if (count === 0) {
      stopSpinner();
    }
    return Promise.reject(error);
  },
  (rej) => {
    count = count - 1;
    if (count === 0) {
      stopSpinner();
    }
  }
);

axiosInstance.interceptors.response.use(null, (error) => {
  count = count - 1;
  // errorLogService.CreateErrorLog(error);
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // console.log("An unexpected error occurrred.");
  }

  if (error.response && error.response.status === 401) {
    // redirect to login page
    history.replace("/auth/sign-in");
  }

  return Promise.reject(error);
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
  patch: axiosInstance.patch,
};
