import {
  AppBar,
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  LinearProgress,
  TextField as MuiTextField,
  Toolbar,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import processService from "../../services/processService";
import AddProcessDialog from "./AddProcessDialog";
import AllProcessList from "./AllProcessList";
import Lottie from "lottie-react-web";
import AddIcon from "@material-ui/icons/Add";
import animationData from "../../assets/animation/search-error.json";
import { AuthContext } from "../../context/AuthContextProvider";


const AllProcessContainer = () => {
  const Divider = styled(MuiDivider)(spacing);
  const Button = styled(MuiButton)(spacing);
  const Alert = styled(MuiAlert)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  const [open, setOpen] = useState(false);
  const Spacer = styled.div(spacing);
  const [processes, setProcesses] = useState();
  const CloudUpload = styled(MuiCloudUpload)(spacing);
  const [addProcessDialog, setAddProcessDialog] = useState(false);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    getAllProcess();
  }, [currentUser]);

  const getAllProcess = async () => {
    try {
      const response = await processService.getProcesses();
      if (response && response.data) {
        // console.log("🚀  -> file: AllProcessContainer.js -> line 77 -> getAllProcesses -> response", response);
        setProcesses(response?.data?.data);
      }
    } catch (error) {
      // console.log("🚀  -> file: AllGaugesContainer.js -> line 40 -> getAllGauges -> error", error);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <AppBar position="static">
            <Toolbar>
              <div className="d-flex align-items-center justify-content-end w-100">
                <Grid item mr={2}>
                  <input
                    accept="csv/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                   {/*  <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      mr={2}
                    >
                      <CloudUpload mr={2} /> Upload
                    </Button> */}
                  </label>
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => setAddProcessDialog(true)}
                >
                  Add Process
                </Button>
              </div>
            </Toolbar>
          </AppBar>
          {processes && <AllProcessList processes={processes} />}
        </Grid>
      </Grid>
      {addProcessDialog && (
        <AddProcessDialog
          addProcessDialog={addProcessDialog}
          setAddProcessDialog={setAddProcessDialog}
          onSuccess={(e) => {
            if (e) {
              getAllProcess();
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default AllProcessContainer;
