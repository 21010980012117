import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Grr1Report } from "./Grr1Report/Grr1Report";
import { Grr2Report } from "./Grr2Report/Grr2Report";
import { KappaReport } from "./kappaReport/KappaReport";
import { SignalReport } from "./SignalReport/SignalReport";
import initiateStudyService from "../../../../services/initiateStudyService";
import Loader from "../../../../components/Loader";

export function MsaReport({ componentProps = null }) {
  const [studyId, setStudyId] = useState(-1);
  const [studiesId, setStudiesId] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false);


  useEffect(() => {
    setStudyId(componentProps.studies_id);
    setStudiesId(componentProps.study_id);
  }, [componentProps]);

  if (!studiesId) {
    return <Loader />;
  }

  return (

    <>
      {studyId === 3 ? (
        <Grr1Report
          study_id={studiesId}
          disabledDownload={componentProps?.disabledDownload ?? false}
        />
      ) : null}
      {studyId === 2 ? (
        <Grr2Report
          study_id={studiesId}
          disabledDownload={componentProps?.disabledDownload ?? false}
        />
      ) : null}
      {studyId === 12 ? (
        <KappaReport
          study_id={studiesId}
          disabledDownload={componentProps?.disabledDownload ?? false}
        />
      ) : null}
      {studyId === 5 ? (
        <SignalReport
          //reportData={reportData}
          disabledDownload={componentProps?.disabledDownload ?? false}
        />
      ) : null}
    </>
  );
}
