import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
  Typography as MuiTypography,
  CardActionArea,
} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  createMuiTheme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import { spacing } from "@material-ui/system";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import gaugeService from "../../../../services/gaugeService";
import piService from "../../../../services/piService";
import PIList from "./PIList";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Lottie from "lottie-react-web";
import animationData from "../../../../assets/animation/gears-loading.json";
import AssessmentIcon from "@material-ui/icons/Assessment";
import EditIcon from "@material-ui/icons/Edit";
import PICertificateDialog from "./PICertificateDialog";
import { green, deepOrange } from "@material-ui/core/colors";
import { rgba } from "polished";
import dateService from "../../../../services/dateService";
import { DGDrawer } from "../../../../components/DGDrawer";
import { SerialNumberForm } from "../SerialNumberForm";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContextProvider";

const { v4: uuidv4 } = require("uuid");
const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const Spacer = styled.div(spacing);
const Typography = styled(MuiTypography)(spacing);

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#1890ff",
      borderColor: "#1890ff",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#1890ff",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
}
const defaultTheme = createMuiTheme();
const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: 0,
      color:
        theme.palette.type === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      WebkitFontSmoothing: "auto",
      letterSpacing: "normal",
      "& .MuiDataGrid-columnsContainer": {
        backgroundColor: theme.palette.type === "light" ? "#fafafa" : "#1d1d1d",
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },
      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        borderRight: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        borderBottom: `1px solid ${
          theme.palette.type === "light" ? "#f0f0f0" : "#303030"
        }`,
      },
      "& .MuiDataGrid-cell": {
        color:
          theme.palette.type === "light"
            ? "rgba(0,0,0,.85)"
            : "rgba(255,255,255,0.65)",
      },
      "& .MuiPaginationItem-root": {
        borderRadius: 0,
      },
      ...customCheckbox(theme),
      label: {
        marginTop: theme.spacing(1),
      },
      flexDirection: "column",
      "& .ant-empty-img-1": {
        fill: theme.palette.type === "light" ? "#aeb8c2" : "#262626",
      },
      "& .ant-empty-img-2": {
        fill: theme.palette.type === "light" ? "#f5f5f7" : "#595959",
      },
      "& .ant-empty-img-3": {
        fill: theme.palette.type === "light" ? "#dce0e6" : "#434343",
      },
      "& .ant-empty-img-4": {
        fill: theme.palette.type === "light" ? "#fff" : "#1c1c1c",
      },
      "& .ant-empty-img-5": {
        fillOpacity: theme.palette.type === "light" ? "0.8" : "0.08",
        fill: theme.palette.type === "light" ? "#f5f5f5" : "#fff",
      },
    },
  }),
  { defaultTheme }
);

const PIDetails = () => {
  let { id } = useParams();
  let history = useHistory();
  const classes = useStyles();
  const [gaugeItem, setGaugeItem] = useState();
  const [piHistoryList, setPIHistoryList] = useState();
  const [addPICertificateDialog, setAddPICertificateDialog] = useState(false);
  const [uploadingPIRequest, setUploadingPIRequest] = useState();
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [addGaugeItemDialog, setAddGaugeItemDialog] = useState(false);

  useEffect(() => {
    if (addGaugeItemDialog) {
      ACTION_TYPE.edit = true;
    } else {
      getGaugeItemDetails();
    }
    return () => {
      ACTION_TYPE.edit = false;
    };
  }, [addGaugeItemDialog]);

  useEffect(() => {
    if (id) {
      getGaugeItemDetails();
      getPIHistory();
    }
  }, [id]);

  const getGaugeItemDetails = async () => {
    const response = await gaugeService.getGaugeItemDetails(id);
    // console.log("Gauge Details", response);
    if (response?.data) {
      setGaugeItem(response.data);
    }
  };

  const getDateString = (inDate) => {
    if (!inDate) {
      return "";
    }
    const date = new Date(inDate);
    return (
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "/" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };

  const getPIHistory = async () => {
    const response = await piService.getAllPIRequestsOfGaugeItem(id);
    // console.log("response from PI Requests", response.data.data);
    if (response?.data?.data) {
      setPIHistoryList(response.data.data);
    }
  };
  const { currentUser } = useContext(AuthContext);
  const handleRequestToPI = async () => {
    piService
      .createPIActivity({
        guage_item: gaugeItem?.id,
        user_id: currentUser?.id,
        activity: "initiate",
        pi_agency_id: gaugeItem?.pi_agency_id,
      })
      .then(() => {
        getGaugeItemDetails();
        getPIHistory();
        setShowConfirmBox(false);
      });
  };

  const Percentage = styled(MuiTypography)`
    span {
      color: ${(props) => props.percentagecolor};
      font-weight: ${(props) => props.theme.typography.fontWeightBold};
      background: ${(props) => rgba(props.percentagecolor, 0.1)};
      padding: 2px 8px;
      border-radius: 3px;
      margin: ${(props) => props.theme.spacing(2)}px;
    }
  `;

  return (
    <React.Fragment>
      <Helmet title="Gauge Information" />
      {gaugeItem ? (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <Card mb={6} style={{ width: "100%" }}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item md={12}>
                    <div className="d-flex flex-direction-row align-items-center w-100">
                      <Typography variant="h5" style={{ width: "200px" }}>
                        Gauge Details
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <TableContainer style={{ width: "100%" }}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                        size="small"
                      >
                        <TableBody>
                          {gaugeItem?.guage?.number && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Gauge
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem?.guage?.number}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.serial_number && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Gauge Serial Number
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.serial_number}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.guage?.name && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Gauge Name
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.guage.name}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.pi_agency?.business_name && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Calibration Agency
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.pi_agency.business_name}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.pi_frequency?.name && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Calibration Frequency
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.pi_frequency.name}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.pi_last_date && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Last Calibrated
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {getDateString(gaugeItem.pi_last_date)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell align="left">
                              <Typography color="textSecondary">
                                Due Date
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Typography component="h5" color="textPrimary">
                                {getDateString(gaugeItem.pi_due_date)}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          {gaugeItem?.comments && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Comments
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.comments}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.gauge_usage_id && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Gauge Usage
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.gauge_usage_id}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.location && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Location
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.location}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.plant && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Plant
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5" color="textPrimary">
                                  {gaugeItem.plant}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                          {gaugeItem?.guage_status?.status && (
                            <TableRow>
                              <TableCell align="left">
                                <Typography color="textSecondary">
                                  Status
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography component="h5">
                                  <Percentage
                                    percentagecolor={
                                      gaugeItem &&
                                      gaugeItem.guage_status_id === 1
                                        ? green[600]
                                        : deepOrange[500]
                                    }
                                  >
                                    <span>
                                      {gaugeItem?.guage_status?.status}
                                    </span>
                                  </Percentage>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActionArea>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    m={4}
                    startIcon={<VisibilityIcon />}
                    onClick={() => history.push(`/gauge-item-changes/${id}`)}
                  >
                    View Changes
                  </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      m={4}
                    startIcon={<EditIcon />}
                    disabled={gaugeItem.guage_status_id === 2 ||
                  gaugeItem.guage_status_id === 3 }
                      onClick={() => setAddGaugeItemDialog(true)}
                    >
                      Edit Serial Number
                    </Button>
                  {gaugeItem.guage_status_id === 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      m={4}
                      startIcon={<AssessmentIcon />}
                      onClick={() => setShowConfirmBox(true)}
                    >
                      Request for Calibration
                    </Button>
                  )}
                </div>
              </CardActionArea>
            </Card>
          </Grid>
          <DGDrawer
            Component={SerialNumberForm}
            isOpen={addGaugeItemDialog}
            setOpen={setAddGaugeItemDialog}
            title={gaugeItem?.guage?.name}
            subtitle={gaugeItem?.guage?.number}
            componentProps={{ gaugeItem }}
            needSave
          />

          <Grid container>
            {piHistoryList && gaugeItem && (
              <>
                <Typography variant="h5" gutterBottom display="inline">
                  Calibration Requests
                </Typography>
                <Grid item xs={12}>
                  <PIList
                    piHistoryList={piHistoryList}
                    gaugeItem={gaugeItem}
                    openDialog={(piRequest) => {
                      if (piRequest) {
                        setUploadingPIRequest(piRequest);
                        setAddPICertificateDialog(true);
                      }
                    }}
                    onSuccess={(flag) => {
                      if (flag) {
                        getGaugeItemDetails();
                        getPIHistory();
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      ) : (
        <div item xs={12} style={{ textAlign: "center", paddingTop: 160 }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
            }}
            height={100}
            width={"100%"}
          />
          <Typography variant="h6" gutterBottom>
            Loading
          </Typography>
        </div>
      )}
      {showConfirmBox && gaugeItem && gaugeItem.pi_agency_id && (
        <Dialog
          open={showConfirmBox}
          onClose={() => setShowConfirmBox(false)}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#0d51ca",
              color: "#fff",
            }}
          >
            <DialogTitle id="form-dialog-title">
              Initiate Calibration
            </DialogTitle>
            <div>
              <Button onClick={() => setShowConfirmBox(false)}>
                <CloseRoundedIcon style={{ fill: "#fff" }} />
              </Button>
            </div>
          </div>
          <DialogContent pb={4}>
            <DialogContentText>
              Do you want to initiate calibration{" "}
              {gaugeItem.pi_agency
                ? "with " + gaugeItem.pi_agency.business_name
                : " "}{" "}
              ?
            </DialogContentText>
            <Spacer my={6} />
            <Button
              variant="outlined"
              color="primary"
              mr={3}
              mb={3}
              position="right"
              onClick={() => setShowConfirmBox(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              mb={3}
              position="right"
              onClick={() => handleRequestToPI()}
            >
              Yes
            </Button>
          </DialogContent>
        </Dialog>
      )}
      {showConfirmBox && gaugeItem && !gaugeItem.pi_agency_id && (
        <Dialog
          open={showConfirmBox}
          onClose={() => setShowConfirmBox(false)}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <DialogTitle id="form-dialog-title">
            Sorry you can not initiate calibration, since you have not linked
            any PI Agency to this gauge.
          </DialogTitle>
          <DialogContent pb={4}>
            <Spacer my={6} />
            <Button
              variant="contained"
              color="primary"
              mb={3}
              position="right"
              onClick={() => setShowConfirmBox(false)}
            >
              OK
            </Button>
          </DialogContent>
        </Dialog>
      )}
      {addPICertificateDialog && uploadingPIRequest && (
        <PICertificateDialog
          addPICertificateDialog={addPICertificateDialog}
          setAddPICertificateDialog={setAddPICertificateDialog}
          piRequest={uploadingPIRequest}
          onSuccess={(flag) => {
            if (flag) {
              getGaugeItemDetails();
              getPIHistory();
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PIDetails;
