import { IconButton, makeStyles, Step, StepLabel, Stepper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import React from "react";
import { green, grey, red, yellow, } from "@material-ui/core/colors";
const useStyle = makeStyles({
  root: {
    padding: 5,
  },
});
export function ResultRenderer({ value = -1 }) {
  const classes = useStyle();
  return (
    <>
      {value === 1 ?
        <IconButton
          style={{ color: green[400], padding: "2px" }}>
          <CheckCircleIcon />
        </IconButton> : null}

      {value === 0 ?
        <IconButton style={{ color: red[400], padding: "2px" }}>
          <CancelIcon /></IconButton> : null}

      {value === -1 ?
        <IconButton style={{ color: grey[400], padding: "2px" }}>
          <RadioButtonUncheckedRoundedIcon />
        </IconButton> : null}

      {value === 2 ?
        <IconButton style={{ color: yellow[400], padding: "2px" }}
        ><CheckCircleIcon />
        </IconButton> : null}

      {value === -2 ?
        <IconButton style={{ color: grey[400], padding: "2px", }}>
          <CancelIcon />
        </IconButton> : null}

      {value === 4 ? <IconButton style={{
        color: green[200],
        padding: "2px"
      }}><CancelIcon /></IconButton> : null}

      {value === 3 ? <IconButton style={{
        color: grey[400],
        padding: "2px"
      }}><RadioButtonUncheckedRoundedIcon /></IconButton> : null}

    </>
  );
}
