import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import LightTooltip from "../../../components/Tooltip";
import gaugeService from "../../../services/gaugeService";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { DGDrawer } from "../../../components/DGDrawer";
import { SerialNumberForm } from "../gauges/SerialNumberForm";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { Link } from "react-router-dom";

export function GaugeItemList({ gaugeId = "" }) {
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  useEffect(() => {
    if (formOpen) {
      ACTION_TYPE.add = true;
    }
    return () => {
      ACTION_TYPE.add = false;
    };
  }, [formOpen]);
  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData() {
    gaugeService.getGaugeItemsOfGauge(gaugeId).then((res) => {
      setRows(res?.data?.data ?? []);
    });
  }
  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columnDefs}
        ButtonComponent={() => (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setFormOpen(true);
            }}
          >
            SERIAL NUMBER
          </Button>
        )}
      />
      <DGDrawer
        Component={SerialNumberForm}
        isOpen={formOpen}
        setOpen={(state) => {
          setFormOpen(state);
          reloadGridData();
        }}
        title={"New Serial Number"}
        onCancel={() => {
          setFormOpen(false);
        }}
        subtitle={new Date().toDateString()}
        componentProps={{}}
        needSave
      />
    </>
  );
}
const columnDefs = [
  {
    field: "serial_number",
    headerName: "Serial Number",
    cellRenderer: (params) => (
      <>
        <Link variant="body2" to={`/pi/${params.data?.id}`}>
          {params.value}
        </Link>
      </>
    ),
    minWidth: 150,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "usage",
    headerName: "Usage",
    minWidth: 150,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "guage_status.status",
    headerName: "Status",
    minWidth: 150,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    field: "location",
    headerName: "Location",
    minWidth: 150,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
];
