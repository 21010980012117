import {
    Grid, Button, Box, TextareaAutosize,
    Typography, AppBar, Divider, Toolbar
} from "@material-ui/core";
import { useState, useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider";
import { StatusContext } from "../../context/StatusContextProvider"
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { useFormik } from "formik";
import { DGSelect } from "../../components/shared/DGSelect";
import { object, string } from "yup";
import plantService from "../../services/plantService";
import { DGInput } from "../../components/shared/DGInput";
import { DGDateInput } from "../../components/shared/DGDateInput";
import userService from "../../services/userService";
import studyInputService from "../../services/studyInputService";
import oplService from "../../services/oplService";
import { format, isEqual } from 'date-fns'

import { AlertContext } from "../../context/AlertContextProvider";




export default function EditOpenPoint({

    closeForm = () => { },
    componentProps = '',



}) {

    const { updateAlert } = useContext(AlertContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [levelFlag, setLevelFlag] = useState(false);
    const [opVersionsFlag, setopVersionFlag] = useState(true);
    const [changeFlag, setChangeFlag] = useState(false);
    const [closeFlag, setCloseFlag] = useState(false);

    const [activeVersion, setActiveVersion] = useState([]);

    const { v4: uuidv4 } = require('uuid');
    const importData = useRef()





    useEffect(() => {
        if (componentProps?.data?.opid) {
            const fetchData = async () => {
                try {
                    const res = await oplService.getOPID(componentProps.data.opid);
                    const temp = res?.data;
                    importData.current = temp;
                    setActiveVersion(temp[0]);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [componentProps]);


    let form = useFormik({
        initialValues: {
            openPointVersionOptions: [],
            openPointVersion: { version: componentProps?.data?.op_version },

            criticalityOptions: [
                { id: 1, name: "Urgent" },
                { id: 2, name: "Critical" },
                { id: 3, name: "Critical & Urgent" },
                { id: 4, name: "Non-Critical" }
            ],
            criticalitySelected: {
                id: componentProps?.data?.criticality_id,
                name: componentProps?.data?.criticality_id === 1 ? "Urgent" :
                    componentProps?.data?.criticality_id === 2 ? "Critical" :
                        componentProps?.data?.criticality_id === 3 ? "Critical & Urgent" :
                            componentProps?.data?.criticality_id === 4 ? "Non-Critical" :
                                ""
            },

            requestedBy: [],
            requestedBySelected: {
                user_name: componentProps?.data?.raised_by_name,
                user_id: componentProps?.data?.raised_by
            },

            opInitiatedDate: componentProps?.data?.raised_date
                ? new Date(componentProps?.data?.raised_date)
                : null,


            openPoint: componentProps?.data?.op_description,

            moduleOptions: [],
            moduleSelected:
            {
                id: componentProps?.data?.source_id,
                study: componentProps?.data?.source_name
            },
            opExpectedDate: componentProps?.data?.expected_close_date
                ? new Date(componentProps?.data?.expected_close_date)
                : null,

            ica: componentProps?.data?.ica,

            actionByOptions: [],
            actionBySelected: {
                user_name: componentProps?.data?.action_by_name,
                user_id: componentProps?.data?.action_by_id
            },
            opTargetDate: componentProps?.data?.action_target_date
                ? new Date(componentProps?.data?.action_target_date)
                : '',
            closureCriteria: componentProps?.data.closure_criteria,
            actions: componentProps?.data?.action_descritption,


            actionImpactOptions: [
                { id: 1, name: "Safety" },
                { id: 2, name: "Quality" },
                { id: 3, name: "Cost" },
                { id: 4, name: "Delivery" },
            ],
            actionImpactSelected: [],

            actionImpactSelected: {
                id: componentProps?.data?.action_impact_id,
                name: componentProps?.data?.action_impact_id === 1 ? "Safety" :
                    componentProps?.data?.action_impact_id === 2 ? "Quality" :
                        componentProps?.data?.action_impact_id === 3 ? "Cost" :
                            componentProps?.data?.action_impact_id === 4 ? "Delivery" :
                                ""
            },

            itemOptions: [],
            itemSelected: [],

            opClossureDate: componentProps?.data?.closure_date
                ? new Date(componentProps?.data?.closure_date)
                : '',

            comments: componentProps?.data?.closure_comment

        },
        /* 
                validationSchema: object().shape({
                    operatorName: string().required()
                }), */


        onSubmit: onAdd

    });



    useEffect(() => {
        if (activeVersion && importData.current) {
            const currentver = form.values.openPointVersion.version;
            const importVer = componentProps?.data?.op_version;

            if (currentver === importVer) {
                setopVersionFlag(false);
                form.resetForm()
            } else {
                setopVersionFlag(true);
                versionUpdate(currentver)

            }
        }

        if (!importData.current) {
            return;
        }
    }, [form.values.openPointVersion]);




    useEffect(() => {

        if (!importData.current) {
            return;
        }

        userService.getUsersByPlantId(
            componentProps?.data?.plant_id,
            currentUser.client_id).then((res) => {

                const tempUser = res?.data
                form.setFieldValue("actionByOptions", tempUser)
                form.setFieldValue("requestedBy", tempUser)

            });

        studyInputService.getAllStudies()
            .then((res) => {
                const tempModule = res?.data?.data
                const filterModules = tempModule.filter((item) => item.group === 'OPL')
                form.setFieldValue("moduleOptions", filterModules)
            })

        const tempVer = importData?.current.map(item => ({ version: item.op_version }));
        form.setFieldValue("openPointVersionOptions", tempVer)

    }, [importData.current, form.values.openPointVersion]);


    useEffect(() => {
        if (!currentUser) {
            return;
        }
        const temp = currentUser
        if (temp.user_role <= 3) {
            setLevelFlag(true)
        }

    }, [currentUser]);



    useEffect(() => {
        if (!form.values && !importData.current) {
            return;
        }

        if (form?.values?.opClossureDate &&
            form?.values?.actions &&
            form?.values?.actionBySelected &&
            !changeFlag &&
            opVersionsFlag
            &&
            activeVersion?.action_status !== 1

        ) {
            setCloseFlag(true)
        }

    }, [form.values]);

    //  console.log(form.values)


    function onAdd() {

        const data = {
            opid: importData?.current[0]?.opid,
            client_id: currentUser.client_id,
            plant_id: importData?.current[0]?.plant_id,
            op_id: importData?.current[0]?.op_id,
            op_version: importData?.current[0]?.op_version + 1,
            raised_by: importData?.current[0]?.raised_by,

            criticality_id: form.values.criticalitySelected.id,

            raised_date: form.values.opInitiatedDate
                ? format(new Date(form.values.opInitiatedDate), "yyyy-MM-dd")
                : null,



            op_description: form.values.openPoint,
            source_id: form.values.moduleSelected.id,
            expected_close_date: form.values.opExpectedDate
                ? format(new Date(form.values.opExpectedDate), "yyyy-MM-dd")
                : null,
            ica: form.values.ica,
            ica_document_link: null,

            action_by: form.values?.actionBySelected?.user_id,

            action_target_date: form.values.opTargetDate
                ? format(new Date(form.values.opTargetDate), "yyyy-MM-dd")
                : null,

            closure_criteria: form.values.closureCriteria,
            action_descritption: form.values.actions,
            action_document_link: null,
            action_impact_id: form.values.actionImpactSelected?.id,
            action_status: -1,
            closure_date: form.values.opClossureDate
                ? format(new Date(form.values.opClossureDate), "yyyy-MM-dd")
                : null,
            closure_comment: form.values.comments
        }

        oplService.createOP(data)
            .then((res) => {
                updateAlert({
                    message: "Oplen Point Updated SucessFully",
                    open: true,
                    type: "success",
                });
                closeForm(true);
            })
            .catch(() => {
                updateAlert({
                    message: "Failed to Update, Please try again",
                    open: true,
                    type: "error",
                });
            });

    }

    function handleClose() {

        oplService.updateOP(importData?.current[0]?.id, { action_status: 1 })
            .then((res) => {
                updateAlert({
                    message: "Oplen Point Closed ",
                    open: true,
                    type: "success",
                });
                closeForm(true);
            })
            .catch(() => {
                updateAlert({
                    message: "Failed to Close, Please try again",
                    open: true,
                    type: "error",
                });
            });


    }


    function versionUpdate(currentver) {


        const temp1 = importData.current?.filter((item) =>
            item.op_version == currentver)
        const temp2 = temp1[0]
        setActiveVersion(temp2)


        const tempModule = form.values.moduleOptions.filter((item) =>
            item.id == temp2?.source_id)
        form.setFieldValue("moduleSelected", tempModule[0])

        const tempCriticality = form?.values?.criticalityOptions.filter((item) =>
            item.id === temp2?.criticality_id)
        form.setFieldValue("criticalitySelected", tempCriticality[0])


        const tempRequestedBy = form.values.requestedBy.filter((item) =>
            item.user_id == temp2?.raised_by)
        form.setFieldValue("requestedBySelected", tempRequestedBy[0])

        const tempuser2 = form?.values?.actionByOptions.filter((item) =>
            item.user_id === temp2?.action_by_id)
        form.setFieldValue("actionBySelected", tempuser2[0])

        const tempImpact = form.values.actionImpactOptions.filter((item) =>
            item.id == temp2?.action_impact_id)
        form.setFieldValue("actionImpactSelected", tempImpact[0])


        form.setFieldValue("openPoint", temp2?.op_description)
        form.setFieldValue("ica", temp2?.ica)
        form.setFieldValue("opIntiatedDate", new Date(temp2?.raised_date));
        form.setFieldValue("opExpectedDate", new Date(temp2?.expected_close_date));
        form.setFieldValue("opTargetDate", activeVersion?.action_target_date)
        form.setFieldValue("closureCriteria", temp2?.closure_criteria)
        form.setFieldValue("actions", temp2?.action_descritption)

        form.setFieldValue("opClossureDate", temp2?.closure_date)
        form.setFieldValue("comments", temp2?.closure_comment)


    }



    return (


        <Grid container spacing={2}>


            <Grid item xs={7}>
                <Box
                    sx={{
                        border: '1px solid grey',
                        width: '98%',
                        padding: '5px',
                        marginTop: '5px',
                        //height: '70%',
                        marginRight: '2%',
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: "700px",
                        borderRadius: '10px'

                    }}
                >
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        Open Point
                    </Typography>

                    <Grid container spacing={2}>

                        <Grid item xs={5}>
                            <DGInput
                                id="plant"
                                label="Plant"
                                value={importData?.current?.[0].plant_name}

                            />
                        </Grid>

                        <Grid item xs={4}>
                            <DGInput
                                id="plant"
                                label="Open Point ID"
                                value={importData?.current?.[0]?.op_id}

                            />
                        </Grid>

                        <Grid item xs={3}>
                            <DGSelect
                                id="openPointVersion"
                                label="Version"
                                options={form.values.openPointVersionOptions}
                                getOptionLabel={(option) => String(option?.version)}
                                value={form.values.openPointVersion}
                                disableClearable
                                onChange={(value) => {
                                    form.setFieldValue("openPointVersion", value)
                                }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <DGSelect
                                id="criticality"
                                label="Criticality"
                                options={form.values.criticalityOptions}
                                getOptionLabel={(option) => option?.name || ''}
                                value={form.values.criticalitySelected}
                                onChange={(value) => {
                                    form.setFieldValue("criticalitySelected", value);
                                    if (value.id != importData?.current[0]?.criticality_id) {
                                        setChangeFlag(true)
                                    } else {
                                        setChangeFlag(false)
                                    }
                                }}
                            />


                        </Grid>

                        <Grid item xs={5}>
                            <DGInput
                                id="requestedBy"
                                label="Raised By"
                                value={form.values?.requestedBySelected?.user_name}
                            />


                        </Grid>

                        <Grid item xs={3}>

                            <DGDateInput
                                required
                                label="Requested  By"
                                value={form.values.opInitiatedDate}

                            />



                        </Grid>


                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="openPoint"
                                placeholder="Open Point"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '150px',
                                    minHeight: '150px',
                                    padding: '10px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={form?.values?.openPoint || ""}
                                onChange={(e) => {
                                    form.setFieldValue("openPoint", e.target.value);
                                    if (e.target.value !== importData?.current[0]?.op_description) {
                                        setChangeFlag(true)
                                    }
                                    setChangeFlag(true)
                                }}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <DGInput
                                id="module"
                                label="Source"
                                value={form.values?.moduleSelected?.study}

                            />
                        </Grid>



                        <Grid item xs={6}>

                            <DGDateInput
                                required
                                label="Expected Close By"
                                value={form.values.opExpectedDate || ""}
                                onChange={(val) => {
                                    form.setFieldValue("opExpectedDate", val);
                                    if (!isEqual(val, new Date(importData?.current[0]?.expected_close_date))) {
                                        setChangeFlag(true);
                                    }
                                    else {
                                        setChangeFlag(false);
                                    }
                                }}
                            />

                        </Grid>

                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="ica"
                                placeholder="Immediate Containment Actions"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '150px',
                                    minHeight: '150px',
                                    padding: '10px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={form.values.ica || ""}

                                onChange={(e) => {
                                    form.setFieldValue("ica", e.target.value);
                                    if (e.target.value !== importData?.current[0]?.ica) {
                                        setChangeFlag(true)
                                    }
                                    else { setChangeFlag(true) }
                                }}
                            />
                        </Grid>
                    </Grid >

                </Box >
            </Grid >


            <Grid item xs={5}>
                <Box
                    sx={{
                        border: '1px solid grey',
                        width: '98%',
                        padding: '5px',
                        marginTop: '5px',
                        //height: '70%',
                        marginRight: '2%',
                        boxSizing: 'border-box',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: "700px",
                        borderRadius: '25px'
                    }}
                >

                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        Actions
                    </Typography>

                    <Grid container spacing={2}>


                        <Grid item xs={7}>
                            <DGSelect
                                id="itemDescription"
                                label="Responsible"
                                options={form.values.actionByOptions}
                                getOptionLabel={(option) => option?.user_name}
                                value={form.values.actionBySelected}
                                onChange={(value) => {
                                    form.setFieldValue("actionBySelected", value);
                                    if (value.user_id !== importData?.current[0]?.raised_by) {
                                        setChangeFlag(true)
                                    } else {
                                        setChangeFlag(false)
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={5}>
                            <DGDateInput
                                required
                                label="Target Date"
                                value={form.values.opTargetDate || null}
                                onChange={(val) => {
                                    form.setFieldValue("opTargetDate", val);
                                    if (!isEqual(val, new Date(importData?.current[0]?.action_target_date))) {
                                        setChangeFlag(true);
                                    }
                                    else {
                                        setChangeFlag(false);
                                    }
                                }}

                            />
                        </Grid>


                        <Grid item xs={12}>
                            <DGInput
                                label="Closure Criteria"
                                onChange={(e) => {
                                    form.handleChange(e);
                                    if (e.target.value !== importData?.current[0]?.closure_criteria) {
                                        setChangeFlag(true);
                                    }
                                }}
                                value={form.values.closureCriteria}
                                id="closureCriteria"
                                isFormik
                            />

                        </Grid>

                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="actions"
                                placeholder="Actions"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '200px',
                                    minHeight: '200px',
                                    padding: '10px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                                value={form.values.actions || ""}
                                onChange={(e) => {
                                    form.setFieldValue("actions", e.target.value);
                                    if (e.target.value !== importData?.current[0]?.action_descritption) {
                                        setChangeFlag(true)
                                    }
                                    else {
                                        setChangeFlag(false)
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DGSelect
                                id="plant"
                                label="Action Benifiet"
                                options={form.values.actionImpactOptions}
                                getOptionLabel={(option) => option?.name || ""}
                                value={form.values.actionImpactSelected}
                                onChange={(value) => {
                                    form.setFieldValue("actionImpactSelected", value);

                                    if (value.id !== importData?.current[0]?.action_impact_id) {
                                        setChangeFlag(true)
                                    } else {
                                        setChangeFlag(false)
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DGDateInput
                                label="Closure Date"
                                value={form.values.opClossureDate || null}
                                onChange={(val) => {
                                    form.setFieldValue("opClossureDate", val);
                                    if (!isEqual(val, new Date(importData?.current[0]?.closure_date))) {
                                        setChangeFlag(true);
                                    }
                                    else {
                                        setChangeFlag(false);
                                    }
                                }}

                            />
                        </Grid>



                        <Grid item md={12} sm={12} lg={12}>
                            <TextareaAutosize
                                aria-label="comments"
                                placeholder="Closure Comments"
                                minRows={1}
                                className="border border-2"
                                style={{
                                    width: "100%",
                                    borderColor: "#bbb",
                                    borderRadius: "2px",
                                    maxHeight: '100px',
                                    minHeight: '100px',
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    padding: '10px',
                                }}
                                value={form.values.comments || ""}
                                onChange={(e) => {
                                    form.setFieldValue("comments", e.target.value);
                                    if (e.target.value !== importData?.current[0]?.comments) {
                                        setChangeFlag(true)
                                    }
                                    else {
                                        setChangeFlag(false)
                                    }
                                }}


                            />
                        </Grid>




                    </Grid>


                </Box>
            </Grid>



            <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
                <Divider />


                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>

                    <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={closeForm}
                    >
                        CANCEL
                    </Button>

                    {importData?.current?.[0]?.action_status !== 1 && (
                        <>
                            <Button
                                style={{ margin: 4 }}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={form.handleSubmit}
                                disabled={!(opVersionsFlag && changeFlag)}
                            >
                                SAVE
                            </Button>

                            <Button
                                style={{ margin: 4 }}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleClose}
                                disabled={!closeFlag}

                            >
                                Close
                            </Button>
                        </>
                    )}

                </Toolbar>
            </AppBar>

        </Grid >

    )

}