import React from "react";
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from "styled-components/macro";

import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import { THEMES } from "../constants";
import createTheme from "../theme";

const Register = ({ children }) => {
  return (
    <MuiThemeProvider theme={createTheme(THEMES.DEFAULT)}>
      <ThemeProvider theme={createTheme(THEMES.DEFAULT)}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default Register;
