import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { date, object } from "yup";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import customerDashboardService from "../../../services/customerDashboardService";
import plantService from "../../../services/plantService";
import { AuthContext } from "../../../context/AuthContextProvider";
import productService from "../../../services/productService";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContextProvider";

export function PartAccessForm({ Footer = () => {}, closeForm = () => [] }) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      plant_number: "",
      plant_number_options: [],
      part_number: "",
      part_number_options: [],
      customer_user: null,
      customer_user_options: [],
      access_start_date: new Date(),
      access_end_date: new Date(),
    },
    validationSchema: object({
      plant_number: object().required(),
      part_number: object().required(),
      customer_user: object().required(),
      access_start_date: date().required(),
      access_end_date: date().required(),
    }),
    onSubmit: saveForm,
  });

  useEffect(() => {
    plantService.getPlantlist(currentUser?.client_id, 1).then((res) => {
      form.setFieldValue("plant_number_options", res?.data ?? []);
    });
    customerDashboardService
      .getCustomerUser(currentUser?.client_id)
      .then((res) => {
        form.setFieldValue("customer_user_options", res?.data);
      });
  }, []);

  function loadparts(plantid) {
    productService.getAProductByPlant(plantid).then((res) => {
      form.setFieldValue("part_number_options", res?.data ?? []);
    });
  }

  function saveForm() {
    let payload = {
      client_id: currentUser?.client_id,
      user_id: currentUser?.id,
      part_id: form.values.part_number?.id,
      customer_id: form.values.part_number?.customer_id,
      customer_user_id: form.values.customer_user?.customer_user_id,
      access_start_date: moment(form.values.access_start_date).format(
        "YYYY-MM-DD"
      ),
      access_end_date: moment(form.values.access_end_date).format("YYYY-MM-DD"),
    };

    customerDashboardService
      .createCustomerPartAccess(payload)
      .then((res) => {
        closeForm();
        updateAlert({
          open: true,
          message: "Part Access Created Successfully.",
          type: "success",
        });
      })
      .catch((res) => {
        updateAlert({
          open: true,
          message: "Failed to create Part Access",
          type: "error",
        });
      });
  }
  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          required
          label="Plant Number"
          value={form.values.plant_number}
          options={form.values.plant_number_options}
          onChange={(val) => {
            form.setFieldValue("plant_number", val);
            if (val) {
              loadparts(val?.id);
            } else {
              form.setFieldValue("part_number_options", []);
              form.setFieldValue("part_number", null);
            }
          }}
          error={form.errors.plant_number && form.touched.plant_number}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          required
          label="Part Number"
          value={form.values.part_number}
          options={form.values.part_number_options}
          onChange={(val) => form.setFieldValue("part_number", val)}
          error={form.errors.part_number && form.touched.part_number}
          getOptionLabel={(opt) =>
            opt?.part_number ? `${opt?.part_number}-${opt?.part_name}` : ""
          }
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          required
          label="Customer User"
          value={form.values.customer_user}
          options={form.values.customer_user_options}
          onChange={(val) => form.setFieldValue("customer_user", val)}
          error={form.errors.customer_user && form.touched.customer_user}
          getOptionLabel={(opt) => opt?.customer_name}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGDateInput
          required
          label="Access Start Date"
          value={form.values.access_start_date}
          onChange={(val) => form.setFieldValue("access_start_date", val)}
          error={
            form.errors.access_start_date && form.touched.access_start_date
          }
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGDateInput
          required
          label="Access End Date"
          value={form.values.access_end_date}
          onChange={(val) => form.setFieldValue("access_end_date", val)}
          error={form.errors.access_end_date && form.touched.access_end_date}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
