import { Button, Grid, AppBar, Divider, Toolbar } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from "../../../../context/AuthContextProvider";
import AccountCircle from "@material-ui/icons/AccountCircle";
import spcService from "../../../../services/spcService";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { useFormik } from "formik";
import errorLog from "../../../../services/errorLog";
import { DGDateInput } from "../../../../components/shared/DGDateInput";
import { set } from "date-fns";
import Loader from "../../../../components/Loader";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { DGAlert } from "../../../../components/shared/DGAlert";
import { array, object, string, number } from "yup";
import { transparentize } from "polished";

export default function TimeLimitEntry({
  componentProps = null,
  onSuccess = () => { },
  onPrevious = () => { }

}) {
  const { v4: uuidv4 } = require("uuid");
  const { updateAlert } = useContext(AlertContext);

  const [resultID, setResultID] = useState(null)
  const [studyInputId, setStudyInputId] = useState(null)
  const { currentUser } = useContext(AuthContext);
  const [evalData, setEvalData] = useState(0)
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [columnsCount, setColumnsCount] = useState(0);
  const [gridApi, setGridApi] = useState(null);
  const [cols, setCols] = useState([])
  const [nCount, setNCount] = useState(null)
  const [evalFlag, setEvalFlag] = useState(false)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await spcService.getSPCCLId(componentProps);
        if (res.data.length > 0) {
          setEvalData(res?.data[0]);

        } else {
          fetchData();
        }
      } catch (error) {
        let epayload = {
          id: uuidv4(),
          error_description: error.message,
          error_location: "getSPCCLId",
          client_id: currentUser.client_id,
          user_id: currentUser.id,
          entity_id: componentProps
        };
        errorLog.createErrorLog(epayload)
      }
    };

    fetchData();
  }, [componentProps]);


  let form = useFormik({
    initialValues: {
      loc_chart: "",
      loc_eval: "",
      dis_chart: "",
      dis_eval: "",
      guideline: "",
      machine_spindle: null,
      evaluation_date: null,
      part_detail: "",
      characteristics: null,
      dis_ucl: null,
      dis_target: "",
      dis_lcl: "",
      loc_ucl: "",
      loc_target: "",
      loc_lcl: "",
      part: "",
      sample_width: "",
      sample_length: "",
      gridData: [],
      inputData: [],
      nullCount: 0,
      specs: "",
    },

    validationSchema: object().shape({
      sample_width: number().required("Minimum Sub-group sample width is required"),
      nullCount: number()
        .test({ test: (value) => value === 0, }),
      sample_length: number().required("Sub-group is required")
        .test(
          "Minimum sample required for evaluation",
          function (value) {
            return value * form.values.sample_width >= evalData.csr_min_sample;
          }
        ),

    }),
  });


  useEffect(() => {
    if (evalData) {
      form.setFieldValue("part", evalData.part)
      form.setFieldValue("characteristics", (evalData.character_name + "    " + evalData.character_specification))
      form.setFieldValue("specs", evalData?.character_specification?.split(" "))
      form.setFieldValue("machine_spindle", evalData.machine_spindle)
      form.setFieldValue("evaluation_date", evalData.evaluation_date)
      form.setFieldValue("template_name", evalData.template_name)
      form.setFieldValue("template_name", evalData.template_name)
      form.setFieldValue("evaluation_method", evalData.evaluation_method)

      form.setFieldValue("loc_chart", evalData.loc_chart)
      form.setFieldValue("dis_chart", evalData.dis_chart)

      form.setFieldValue("loc_eval", evalData.loc_eval)
      form.setFieldValue("dis_eval", evalData.dis_eval)
    }

  }, [evalData])



  useEffect(() => {

    if (rowsCount !== 0 && columnsCount !== 0) {
      FormatStudyInput()
    }
  }, [rowsCount, columnsCount])



  function FormatStudyInput() {

    const rowData = Array.from({ length: rowsCount }, (_, index) => {
      let row = { serial_number: index + 1 };
      for (let i = 1; i <= columnsCount; i++) {
        row[i] = "";
      }
      return row;
    });

    form.setFieldValue("gridData", rowData);


    const columns = Array.from({ length: columnsCount }).map((_, i) => ({
      field: `${i + 1}`,
      headerName: `${i + 1}`,
      editable: true,
      cellStyle: params => {
        const value = parseFloat(params.value);
        if (value === null || value === '' || isNaN(value)) {
          return { 'background-color': transparentize(0.9, 'red') };
        }

        if (
          //isNaN(value)
          value <= (JSON.parse(form.values.specs[0]) + (JSON.parse(form.values.specs[1])))
          && value >= (JSON.parse(form.values.specs[0]) + (JSON.parse(form.values.specs[2])))) {
          return null;
        } else {
          return { color: 'red' };
        }
      },
    }));

    setCols([
      {
        field: "serial_number",
        headerName: "SI No",
        valueGetter: "node.rowIndex + 1",
        filter: "agNumberColumnFilter",
        editable: false
      },
      ...columns
    ]);
  }


  function onGridReady(params) {
    params.api.sizeColumnsToFit();
    setGridApi(params?.api);

  }



  async function prepareData() {

    if (form?.values?.nullCount === 0) {
      const rowsToDisplay = gridApi.rowModel.rowsToDisplay;
      const rowDataArray = rowsToDisplay.map((rowNode) => rowNode.data);
      const temp1 = rowDataArray.map(obj => Object.values(obj).slice(0, -1));
      const temp = temp1.map(innerArray => innerArray.map(value => parseFloat(value)));
      const tempt = temp.flat();

      for (let i = 0; i < tempt.length; i++) {
        const element = tempt[i];
        if (element === null || element === '' || isNaN(element)) {
          var flag = 1
          break;
        } else {

          var flag = 0
        }
      }

      if (temp.length === 0 || flag === 1) {
        setNCount(1);
        updateAlert({
          message: "Verify input Data, Null values are not allowed",
          type: "error",
          open: true,
        });
      } else {
        setNCount(0);
        form.setFieldValue("inputData", temp);
      }
    }
  }



  async function IntiateSPCEval() {

    setDataLoaded(true)

    let count = 0
    let data = null
    while (data === null || count < 5000) {
      data = form.values.inputData;
      count++
    }

    const payload = {
      result_id: uuidv4(),
      character_id: evalData.character_id,
      loc_eval_id: evalData.loc_eval_id,
      dis_eval_id: evalData.dis_eval_id,
      outlier_eval_id: evalData.outlier_eval_id,
      csr_id: evalData.csr_id,
      input_eval_values: data,
      cl_eval_id: componentProps,
      study_input_id: uuidv4(),
      created_by: currentUser.id,
    }


    if (nCount === 0 && data !== null) {

      await spcService.intiateSPCeval(payload)
        .then((res) => {
          if (res.status) {
            setEvalFlag(true)
            setResultID(payload.result_id)
            setStudyInputId(payload.study_input_id)
            setDataLoaded(false)
          }
        })


    }
    else {
      updateAlert({
        message: "Oops!! Re-verify",
        type: "info",
        open: true,
      })
      setDataLoaded(false)

    }
  }




  async function updateForm() {
    try {
      await spcService.patchSPClimitseval(componentProps, {
        result_id: resultID,
        study_input_id: studyInputId
      });
    } catch (error) {
      setDataLoaded(true);
    }
  }



  async function evaluateNext() {
    try {
      prepareData();
      IntiateSPCEval();

    }
    catch (error) {
      updateAlert({
        message: "Control Limit evaluation failed,Try again",
        type: "error",
        open: true,
      });
      onSuccess(false);
    }
  }

  async function handleNext() {
    try {
      updateForm();
      onSuccess(true);
    }
    catch (error) {
      updateAlert({
        message: "Control Limit evaluation failed,Try again",
        type: "error",
        open: true,
      });
      onSuccess(false);
    }
  }

  if (dataLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} sm={6} xs={12}>
          <DGInput

            label="Part Number"
            value={form?.values?.part}
          />
        </Grid>

        <Grid item md={6} sm={6} lxs={12}>
          <DGInput
            label="Characteristics"
            value={form?.values?.characteristics}

          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <DGInput
            label="Machine"
            value={form?.values?.machine_spindle}

          />
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <DGDateInput
            label="Evaluation Date"
            value={form?.values?.evaluation_date}
            format="dd/MM/yyyy"


          />
        </Grid>
        <Grid item md={5} sm={5} xs={12}>
          <DGInput
            // disabled
            label="Guidelines"
            value={form?.values?.template_name}
          />
        </Grid>

      </Grid>

      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            label="Location Chart"
            value={form?.values?.loc_chart}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            //disabled
            label="Dispersion Chart"
            value={form?.values?.dis_chart}
          />
        </Grid>

      </Grid>

      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item md={12} sm={12} xs={12}>
          <DGInput
            //disabled
            label="Evaluation Method"
            value={form?.values?.evaluation_method}
          />
        </Grid>
      </Grid >

      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item md={6} sm={6} xs={6}>
          <DGInput
            label="Subgroup Sample Size"
            value={form?.values?.sample_width}
            onChange={(value) => {
              form.setFieldValue("sample_width", value)
              setColumnsCount(value)
            }}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <DGInput
            label="Number of Subgroup"
            value={form?.values?.sample_length}
            onChange={(value) => {
              form.setFieldValue("sample_length", value)
              setRowsCount(value)
            }}

          />
        </Grid>

      </Grid>

      <Grid item md={12} lg={12} style={{ marginTop: '10px' }}>
        <CommonGrid
          needExport={true}
          columns={cols}
          rows={form.values.gridData}
          onGridReady={onGridReady}

        />
      </Grid>

      <AppBar position="fixed" style={{ width: 800, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>


          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => onSuccess(false)}
          >
            CANCEL
          </Button>

          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="default"
            size="small"
            onClick={() => {
              evaluateNext()

            }}
            disabled={!form.isValid || evalFlag}
          >
            VERIFY
          </Button>

          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleNext}
            disabled={!evalFlag}

          >
            NEXT
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
