import { Redirect } from "react-router-dom";
import { getAccessToken, getUserLocal } from "../services/authService";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const token = getAccessToken();
  const currentUser = getUserLocal();
  if (!token) {
    return <Redirect to="/auth/sign-in" />;
  } else if (
    token &&
    currentUser &&
    !currentUser.client_id &&
    currentUser?.screen_flag === "flag_3"
  ) {
    return <Redirect to="/vendor-dashboard" />;
  }
  return children;
}

export default AuthGuard;
