import { AppBar, Button, Divider, Grid, Toolbar } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { DGSelect } from "../../../../../components/shared/DGSelect";
import { DGDateInput } from "../../../../../components/shared/DGDateInput";
import { DGInput } from "../../../../../components/shared/DGInput";
import initiateStudyService from "../../../../../services/initiateStudyService";
import { useFormik } from "formik";
import productService from "../../../../../services/productService";
import { AuthContext } from "../../../../../context/AuthContextProvider";
import characterService from "../../../../../services/characterService";
import gaugeService from "../../../../../services/gaugeService";
import { array, date, object, string } from "yup";

export function StudySelectionPlan({
  msaPlanData,
  fromMSAPlan,
  selectedStudy = null,
  setSelectedStudy = () => { },
  setSelectedGauge = () => { },
  setSelectedGaugeItem = () => { },
  closeForm = () => { },
  handleNext = () => { },
  setcharacterId = () => { },
  setPartId = () => { },
  setEvaluationDate = () => { }
}) {
  const form = useFormik({
    initialValues: {
      product: { part_name: "", part_number: "" },
      character: null,
      gauge: null,
      gauge_item: null,
      study_id: null,
      gaugeItemOptions: [],
      evaluationDate: new Date(),
      selectedStudy: null
    },
    validationSchema: object({
      selectedStudy: string().required('Study is not required'),
    })
  });

  const { currentUser } = useContext(AuthContext);

  //console.log(msaPlanData)

  useEffect(() => {
    setEvaluationDate(form.values.evaluationDate);
    form.setFieldValue("product", msaPlanData.part_number + " " + msaPlanData.part_name);
    form.setFieldValue("gauge", msaPlanData.gauge_)
    form.setFieldValue("character", msaPlanData.character_)
    form.setFieldValue("gauge_id", msaPlanData.gauge_id)
    form.setFieldValue("selectedStudy", msaPlanData.plan_study_id)
    setPartId(msaPlanData.part_id)
    setSelectedGauge(msaPlanData.gauge_id)
    setcharacterId(msaPlanData.character_id)



  }, [])


  useEffect(() => {
    gaugeService.getActiveGaugeItemsByGaugeId(msaPlanData.gauge_id)
      .then((res) => {
        form.setFieldValue("gaugeItemOptions", res?.data?.data ?? []);
      })
  }, [form.values.gauge_id])


  return (
    <Grid container spacing={4}>
      <Grid item md={4} sm={4} lg={4}>

        <DGInput
          label="Part Number"
          value={form.values.product}
        />
      </Grid>

      <Grid item md={5} sm={5} lg={5}>
        <DGInput
          label="Characterstics"
          value={form.values.character}
        />
      </Grid>



      <Grid item md={3} sm={3} lg={3}>
        <DGDateInput
          label="Evaluation Date"
          id="evaluationDate"
          format="dd/MM/yyyy"
          onChange={(date) => {
            form.setFieldValue("evaluationDate", date);
            setEvaluationDate(date)
          }}
          value={form.values.evaluationDate}
          required
          error={
            form.touched.evaluationDate && Boolean(form.errors.evaluationDate)
          }

        />
      </Grid>
      <Grid item md={5} sm={5} lg={5}>
        <DGInput
          label="Gauge Family"
          value={form.values.gauge}
        />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <DGSelect
          label="Gauge Number"
          options={form.values.gaugeItemOptions}
          value={form.values.gauge_item}
          getOptionLabel={(opt) => `${opt?.serial_number}`}
          disabled={form.values.gaugeItemOptions.length === 0}
          onChange={(val) => {
            form.setFieldValue("gauge_item", val);
            setSelectedGaugeItem(val);
          }}

        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <DGInput
          label="Study"
          value={form.values.selectedStudy}

        />
      </Grid>
      <AppBar position="fixed" style={{ width: 1150, top: "auto", bottom: 0 }}>
        <Divider />
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            style={{ margin: 4 }}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => closeForm()}
          >
            CANCEL
          </Button>

          <Button
            style={{ margin: 4 }}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleNext()}
          >
            NEXT
          </Button>

        </Toolbar>
      </AppBar>
    </Grid>
  );
}
