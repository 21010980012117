/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CommonGrid from "../../components/CommonGrid";
import { StatusContext } from "../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import ActiveInActive from "../../components/ActiveInActive";
import productService from "../../services/productService";
import { AuthContext } from "../../context/AuthContextProvider";
//import ProductForm from "../depreciated/_ProductForm";
import customerService from "../../services/customerService";
import csrTemplateService from "../../services/csrTemplateService";
import plantService from "../../services/plantService";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../components/DGDrawer";
import { ProductNewForm } from "./ProductNewForm";
import StatusFilter from "../../components/shared/StatusFilter";
import { PlantContext } from "../../context/PlantContextProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider"


const ProductListing2 = () => {
  const [rows, setRows] = useState([]);
  const [customerDeliveryChecked, setCustomerDeliveryChecked] = useState(false);
  const [addProductDialog, setAddProductDialog] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [evaluation, setEvaluation] = useState([]);
  const [plants, setPlants] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const globalplant = useContext(PlantContext);
  const [plant, setPlant] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);


  useEffect(() => {
    setPlant(currentUser.default_plant);
  }, []);

  useEffect(() => {
    if (globalplant?.globalPlant?.globalPlant) {
      setPlant(globalplant?.globalPlant?.globalPlant?.id);
    }
  }, [globalplant]);


  useEffect(() => {
    reloadGridData(customerDeliveryChecked)
  }, [plant]);


  const handleCustomerDelivery = (flag) => {
    reloadGridData(flag);
    setCustomerDeliveryChecked(flag);
  };


  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(customerDeliveryChecked),
      callback: updateProductStatus,
    }));

    reloadGridData(customerDeliveryChecked);

    /*     plantService.getPlants(currentUser.client_id).then((res) => {
          setPlant(res?.data?.data ?? []);
        }); */

    setHeaderPageTitle("Product Master List");
  }, []);

  function updateProductStatus(dummy1, dummy2, newStatus, rowId) {
    return productService.updateProduct(rowId, { enabled: newStatus });
  }



  function reloadGridData(customerDeliveryChecked) {
    if (plant === null) return;
    productService
      .getProductTableByClient(
        plant,
        currentUser.client_id
      )
      .then((res) => {
        if (customerDeliveryChecked === true) {
          setRows(res?.data?.filter((exp) => exp?.customer_delivery === 1));
        } else {
          setRows(res?.data ?? []);
        }
      });
  }



  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "part_number",
      flex: 1,
      headerName: "Part Number",
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
      cellRenderer: (params) => (
        <>
          <Link
            style={{
              cursor: "pointer",
              color: "#2196f3",
            }}
            variant="body2"
            to={`/product-details/${params.data.id}`}
          >
            {params.value}
          </Link>
        </>
      ),
    },
    {
      field: "part_name",
      headerName: "Part Name",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "customer_name",
      headerName: "Customer",
      flex: 1,
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
    },
    {
      field: "change_number",
      headerName: "Change Number",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "Plant Name",
      flex: 1,
      editable: false,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
        /* onClick={() =>
          setStatus((prev) => ({
            ...prev,
            open: true,
            entityName: params?.data?.part_name ?? "",
            currentStatus: params?.data?.enabled,
            rowId: params?.data?.id,
          }))
        } */
        />
      ),
    },
    /*  {
       field: "enabled",
       headerName: "Status History",
       resizable: true,
       minWidth: 150,
       cellRenderer: (params) => {
         return (
           <Button
             onClick={() =>
               setStatusHistory((prev) => ({
                 ...prev,
                 isOpen: true,
                 rowId: params?.data?.id,
                 entity: params?.data?.part_name ?? "",
               }))
             }
             size="small"
             color="primary"
           >
             View
           </Button>
         );
       },
     }, */
  ];

  return (
    <Paper>
      <Grid container>
        <Grid item md={12} sm={12}>
          <CommonGrid
            coldefs={{
              filter: true,
              filterParams: {
                apply: true,
                newRowsAction: "keep",
                enabled: { filter: 1 },
              },
            }}
            columns={columns}
            rows={rows}
            gridheight={100}
            needExport={false}
            HeaderComponent={() => (
              <FormControlLabel
                control={
                  <Switch
                    checked={customerDeliveryChecked}
                    onChange={(e) => handleCustomerDelivery(e.target.checked)}
                    color="primary"
                    name="Products for Customer Delivery"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Products for Customer Delivery"
              />
            )}
          />
        </Grid>
      </Grid>


    </Paper>
  );
};

export default ProductListing2;
