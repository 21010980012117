import http from "./httpService";

const createGauge = (data) => {
    return http.post("/guage", data);
};

const updateGauge = (id, data) => {
    return http.patch(`/guage/${id}`, data);
};

const createGaugeItem = (data) => {
    return http.post("/guage-item", data);
};

const getGaugeDetails = (id) => {
    return http.get(`/guage/${id}`);
};

const getGaugeUsages = () => {
    return http.get("/guage-usage");
};

const getGaugeStatuses = () => {
    return http.get("/guage-status", {
        params: {
            "id[$in]": [1, 4, 5, 6, 7]
        }
    });
};

const getGaugeItemDetails = (id) => {
    return http.get(`/guage-item/${id}`);
};

const getGauges = (clientId) => {
    return http.get("/guage", {
        params: {
            client_id: `${clientId}`,
        },
    });
};

const getEditHistory = (entityId) => {
    return http.get("/change-management", {
        params: {
            entity_id: `${entityId}`,
        },
    });
};

const getGaugesByCustomerId = async (customerId) => {
    const response1 = await http.get("/parts", {
        params: {
            customer_id: `${customerId}`,
        },
    });
    if (response1.data && response1.data.data && response1.data.data.length) {
        let charsList = [];
        await Promise.all(
            response1.data.data.map(async (part) => {
                const response2 = await http.get("/character", {
                    params: {
                        part_id: part.id,
                    },
                });
                if (
                    response2.data &&
                    response2.data.data &&
                    response2.data.data.length
                ) {
                    charsList = charsList.concat(response2.data.data);
                }
            })
        );
        if (charsList && charsList.length) {
            let gauges = [];
            await Promise.all(
                charsList.map(async (character) => {
                    const response3 = await http.get("/gauge-character", {
                        params: {
                            character_id: character.id,
                        },
                    });
                    response3 &&
                        response3.data &&
                        response3.data.data &&
                        gauges.push(response3.data.data);
                })
            );
            return gauges;
        }
    }
};

const getGaugeType = () => {
    return http.get("/guage-type");
};

const getGaugeItemsOfGauge = (gaugeId) => {
    return http.get(`/guage-item`, {
        params: {
            guage_id: gaugeId,
        },
    });
};

const updateGaugeItem = (id, data) => {
    return http.patch(`/guage-item/${id}`, data);
};

const getGaugeCharacterById = (characterId) => {
    return http.get("/gauge-character", {
        params: {
            character_id: `${characterId}`,
        },
    });
};

const getGaugeItemsByPlantId = (plantId) => {
    return http.get("/guage-item", {
        params: {
            plant_id: `${plantId}`,
        },
    });
};

const getActiveGaugeItemsCountByPlantId = (plantId) => {
    return http.get("/guage-item", {
        params: {
            $limit: 0,
            plant_id: `${plantId}`,
            guage_status_id: 1,
        },
    });
};

const getActiveGaugeItemsOverdueCountByPlantId = (plantId) => {
    return http.get("/guage-item", {
        params: {
            $limit: 0,
            plant_id: `${plantId}`,
            guage_status_id: 1,
            "pi_due_date[$lt]": new Date()
        },
    });
};

const getActiveGaugeItemsCountByClientId = (clientId) => {
    return http.get("/guage-item", {
        params: {
            $limit: 0,
            client_id: `${clientId}`,
            guage_status_id: 1,
        },
    });
};

const getActiveGaugeItemsByClientId = (clientId) => {
    return http.get("/guage-item", {
        params: {
            client_id: `${clientId}`,
            guage_status_id: 1,
        },
    });
};

const getActiveGaugeItemsOverdueCountByClientId = (clientId) => {
    return http.get("/guage-item", {
        params: {
            $limit: 0,
            client_id: `${clientId}`,
            guage_status_id: 1,
            "pi_due_date[$lt]": new Date()
        },
    });
};

const getGaugeItemsByClientId = (clientId) => {
    return http.get("/guage-item", {
        params: {
            client_id: `${clientId}`,
        },
    });
};

const getActiveGaugeItemsByGaugeId = (guage_id) => {
    return http.get("/guage-item", {
        params: {
            guage_id: `${guage_id}`,
            guage_status_id: 1
        },
    });
};


const getAllitemsQueried = (client_id, status_id, plant_id) => {
    return http.get("/guage-allitem", {
        params: {
            client_id: client_id,
            status_id: status_id,
            plant_id: plant_id
        },
    });
};

const getGaugeChar = (client_id) => {
    return http.get("/gauge-character-table", {
        params: {
            client_id: client_id
        }
    })
}

const getGaugeFamily = (client_id) => {
    return http.get("/guage-table", {
        params: { client_id: client_id }
    })
}

const getCalibGauge = (guage_item) => {
    return http.get("/pi-consolidation-gauge", {
        params: { guage_item: guage_item }
    })
}

const createGaugeIssue = (gauge_item_id, issued_to, current_user, location) => {
    return http.post("/gauge-register-issue", {
        gauge_item_id: gauge_item_id,
        issued_to: issued_to,
        current_user: current_user,
        location: location
    })
}

const createGaugeReturn = (gauge_item_id, gauge_issue_id, comments, new_location) => {
    return http.post("/gauge-register-return", {
        gauge_issue_id: gauge_issue_id,
        gauge_item_id: gauge_item_id,
        comments: comments,
        new_location: new_location

    })
}

const getGaugeList = (plant_id) => {
    return http.get("/gauge-issue-list", {
        params: {
            plant_id: plant_id,
        },
    });
};

const getGaugeIssueTable = (client_id, plant_id,gemba_flag) => {
    return http.get("/gauge-register-table", {
        params: {
            client_id: client_id,
            plant_id: plant_id,
            gemba_flag:gemba_flag
        },
    });
};

const updateGaugeCharstatus = async (id, newStatus) => {
    return http.patch(`/gauge-character/${id}`, newStatus);
};

const getGaugeProductTable = async (part_id) => {
    return http.get(`/gauge-product-table`, {
        params: {
            part_id: part_id,
        },
    });
};

const getGaugeProductDetail = async (part_id, client_id) => {
    return http.get(`/gauge-product-detail`, {
        params: {
            part_id: part_id,
            client_id: client_id
        },
    });
};

const getGaugePlantDrop = async (data) => {
    return http.get(`/guage-resource`, {
        params: {
            ...data,
        },
    });
};

const getGaugeProductAddNew = async (client_id, part_id) => {
    return http.get(`/gauge-product-add-list`, {
        params: {
            client_id: client_id,
            part_id: part_id
        },
    });
};

const updateGaugeProductLink = async (id, newStatus) => {
    return http.patch(`/gauge-character/${id}`, newStatus);
};



const getGaugeIssueOverdueList = async (data) => {
    return http.get(`/gauge-issue-overdue`, {
        params: { ...data }
    })
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createGauge,
    getGaugeUsages,
    updateGauge,
    createGaugeItem,
    getGauges,
    getGaugeItemDetails,
    getGaugeDetails,
    getGaugeType,
    getGaugeItemsOfGauge,
    getGaugeCharacterById,
    updateGaugeItem,
    getGaugesByCustomerId,
    getGaugeItemsByPlantId,
    getGaugeItemsByClientId,
    getActiveGaugeItemsCountByPlantId,
    getActiveGaugeItemsCountByClientId,
    getActiveGaugeItemsOverdueCountByPlantId,
    getActiveGaugeItemsOverdueCountByClientId,
    getGaugeStatuses,
    getActiveGaugeItemsByClientId,
    getEditHistory,
    getAllitemsQueried,
    getGaugeChar,
    getGaugeFamily,
    getCalibGauge,
    createGaugeIssue,
    getGaugeList,
    createGaugeReturn,
    getGaugeIssueTable,
    updateGaugeCharstatus,
    getGaugeProductTable,
    getGaugePlantDrop,
    getActiveGaugeItemsByGaugeId,
    getGaugeProductDetail,
    getGaugeProductAddNew,
    updateGaugeProductLink,
    getGaugeIssueOverdueList
};
