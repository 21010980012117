import { borderRadius } from "polished";
import React from "react";
import Carousel from "react-material-ui-carousel";

const items = [
  {
    src: "/static/img/screenshots/slider/img1.png",
  },
  {
    src: "/static/img/screenshots/slider/img2.png",
  },
  {
    src: "/static/img/screenshots/slider/img3.png",
  },
];
function Introduction() {
  return (
    <div
      style={{
        margin: "2% 5% 0 5%",
        paddingBottom: "2%",
      }}
    >
      <Carousel
        timeout={800}
        animation="slide"
      >
        {items.map((item, i) => (
          <ImageComponent key={i} srcPath={item} />
        ))}
      </Carousel>
    </div>
  );
}

const ImageComponent = ({ srcPath }) => {
  return (
    <img
      style={{
        borderRadius: "10px",
      }}
      className="w-100"
      src={srcPath.src}
    />
  );
};

export default Introduction;
