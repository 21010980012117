import http from "./httpService";

const updateClient = async (id, data) => {
  return http.patch(`/clients/${id}`, data);
}



const getClientDetails = async (id) => {
  return http.get(`/clients/${id}`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateClient,
  getClientDetails
};
