import React, { useState, useEffect, useRef } from 'react';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import spcService from "../../../services/spcService";
import highchartsMore from "highcharts/highcharts-more";
import errorLog from "../../../services/errorLog";
import $ from "jquery";
//import Highcharts from "highcharts";
import { filterableGridColumnsIdsSelector } from '@material-ui/data-grid';
import CpkDetailForm from '../spctab/processCapability/CpkDetail';


export default function SpcPlot({
    character_id = "",
    gembaDisplay = "",
    spc_input_id = "",
    spc_eval_id = "",
    renderKey = "",
    cpkFlag = ""
}) {

    const { v4: uuidv4 } = require("uuid");
    const [sPCInput, setSpcInput] = useState(null)
    const [sPCInputId, setSpcInputId] = useState(null)
    const [spcCLEvalId, setSpcCLEvalId] = useState(null)

    const [minData, setMinData] = useState(0)
    const [chartData1, setchartData1] = useState(null);
    const [chartLimits1, setChartLimits1] = useState(null);

    const [chartData2, setchartData2] = useState(null);
    const [chartLimits2, setChartLimits2] = useState(null);



    Highcharts.Pointer.prototype.reset = function () {
        return undefined;
    };



    Highcharts?.charts?.forEach((chart) => {
        chart?.xAxis[0]?.update({
            events: {
                afterSetExtremes: function (event) {
                    Highcharts?.charts?.forEach((otherChart) => {
                        otherChart?.xAxis[0]?.setExtremes(event.min, event.max);
                    });
                },
            },
        });
    });

    Highcharts.Point.prototype.highlight = function (event) {
        event = this.series.chart.pointer.normalize(event);
        this.onMouseOver(); // Show the hover marker
        this.series.chart.tooltip.refresh(this); // Show the tooltip
        this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
    };

    /* ['mousemove', 'touchmove', 'touchstart'].forEach(function (eventType) {
        $('#container').on(eventType, function (e) {
            var chart, point, i, event;

            for (i = 0; i < Highcharts.charts.length; i++) {
                chart = Highcharts.charts[i];
                // Find coordinates within the chart
                event = chart.pointer.normalize(e.originalEvent);
                // Get the hovered point
                point = chart.series[0].searchPoint(event, true);

                if (point) {
                    point.highlight(event);
                }
            }
        });
    }); */


    useEffect(() => {
        if (gembaDisplay === 0) {
            setSpcInputId(spc_input_id)
            setSpcCLEvalId(spc_eval_id)
        }
        else {
            spcInput();
        }

    }, [gembaDisplay, spc_input_id])

    function spcInput() {
        spcService.getSPCPltlist(character_id)
            .then((res) => {
                const temp = res?.data ?? [];
                const filteredData = temp.filter(item => item.display_flag === 1);
                setSpcInputId(filteredData[0].spc_input_id)
                setSpcCLEvalId(filteredData[0].spc_eval_id)
            })

    }



    useEffect(() => {
        if (sPCInputId && spcCLEvalId) {
            getPlotData();
        }

    }, [sPCInputId, spcCLEvalId, renderKey])

    function getPlotData() {

        spcService.getSPCPlotdata1(sPCInputId, spcCLEvalId)
            .then((res) => {
                setchartData1(res?.data?.evaluation_1 ?? []);
                setChartLimits1(res?.data?.common ?? []);
            })
            .catch((err) => {
                //console.log(err)
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "getSPCPlotdata",
                    client_id: null,
                    user_id: null,
                    entity_id: sPCInputId,
                };
            })

        spcService.getSPCPlotdata2(sPCInputId, spcCLEvalId)
            .then((res) => {
                setchartData2(res?.data?.evaluation_1 ?? []);
                setChartLimits2(res?.data?.common ?? []);
            })
            .catch((err) => {
                //console.log(err)
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "getSPCPlotdata",
                    client_id: null,
                    user_id: null,
                    entity_id: sPCInputId,
                };
            })
    }



    const [chartOptions1, setChartOptions1] = useState({


        chart: {
            type: "line",
            height: '28%',
            inverted: false,
            /*             marginRight: -20,
                        marginLeft: - 20, */
            zoomType: "x",
            panning: {
                enabled: true,
                type: 'x'
            },
            panKey: 'shift',


        },



        boost: { enabled: true },

        tooltip: {
            enabled: true,
        },

        animation: true,
        // title: {
        //     text: gembaDisplay !== 1 ? `${name}  ${spec ?? ''}` : undefined,
        // },

        yAxis: {
            min: null,
            plotLines: null
        },

        xAxis: {
            crosshair: true,
            ordinal: true,
            title: false,

        },

        credits: {
            enabled: false,
        },

        legend: false,



        series: [
            {
                name: "",
                data: [],

            },
        ],

    });

    useEffect(() => {
        let formattedData
        let minTime

        if (chartData1 && chartLimits1) {
            let color

            formattedData = chartData1.map((item) => ({
                x: item.x,
                y: item.y,
                loc_rules: item.loc_rules,
                spc_result: item.spc_result,
                evaluation_number: item.evaluation_number,
                color: item.spc_result === 0 ? 'red'
                    : (item.spc_result === 1 ? 'green'
                        : undefined),

            }));

            minTime = chartData1[chartLimits1?.minData]?.x
        }


        setChartOptions1((prev) => {
            return {
                ...prev,

                rangeSelector: {
                    enabled: gembaDisplay === 0 && cpkFlag === 0 ? true : false,
                    verticalAlign: 'bottom',
                    y: 20,
                    buttons: [

                        {
                            type: 'week',
                            count: 1,
                            text: '1w',
                            title: 'View 1 week',
                        },
                        {
                            type: 'week',
                            count: 2,
                            text: '2w',
                            title: 'View 2 week',
                        },
                        {
                            type: 'month',
                            count: 1,
                            text: '1m',
                            title: 'View 1 month',
                        },

                        {
                            type: 'all',
                            text: 'All',
                            title: 'View all data',
                        },
                        {
                            type: 'custom',
                            text: 'Now',
                            title: 'View current data',
                            events: {
                                click: function () {
                                    const xAxis = this.xAxis && this.xAxis[0];
                                    if (xAxis && xAxis.dataMax) {
                                        xAxis.setExtremes(minTime, xAxis.dataMax);
                                    }
                                }
                            }
                        },

                    ],
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function () {
                            return Highcharts.dateFormat('%d/%m', this.value);
                        }
                    },
                    min: minTime,

                },

                yAxis: {

                    opposite: false,

                    plotLines: [
                        {
                            value: chartLimits1?.loc_ucl,
                            color: "green",
                            dashStyle: "shortdash",
                            width: 2,
                            label: {
                                text: "UCL",
                                align: "right",
                                x: -1,
                            },
                        },
                        {
                            value: chartLimits1?.loc_lcl,
                            color: "green",
                            dashStyle: "shortdash",
                            width: 2,
                            label: {
                                text: "LCL",
                                align: "right",
                                x: -1,
                            },
                        },
                        {
                            value: chartLimits1?.loc_target,
                            color: "black",
                            dashStyle: "shortdash",
                            width: 2,
                            label: {
                                text: "Target",
                                align: "right",
                                x: -1,
                            },
                        },
                    ],
                },

                tooltip: {
                    positioner: function () {
                        return {
                            x: this.chart.chartWidth - this.label.width - 5,
                            y: 20,

                        };
                    },
                    shape: 'square',

                    formatter: function () {
                        var tooltipContent =
                            '<b>' + this.point.evaluation_number + '</b><br/>' +
                            '<b>' + Highcharts.dateFormat('%d/%m/%y %H:%M', this.x) + '</b><br/>' +
                            '<b>' + this.y + '</b><br/>' +
                            '<b>' + this.point.loc_rules


                        return tooltipContent;
                    }
                },
                title: {
                    text: "<b> Location Chart</b> ",
                    align: 'left',
                    margin: 10,
                    x: 30,


                },

                series: [{
                    data: formattedData,
                    lineWidth: 1,

                    marker: {
                        symbol: 'circle',
                        radius: 2,
                    },

                }]
            }
        })


    }, [chartData1, chartLimits1])


    const [chartOptions2, setChartOptions2] = useState({
        rangeSelector: {
            enabled: false,
            verticalAlign: 'bottom',
            y: 20,
            buttons: [

                {
                    type: 'week',
                    count: 1,
                    text: '1w',
                    title: 'View 1 week',
                },
                {
                    type: 'week',
                    count: 2,
                    text: '2w',
                    title: 'View 2 week',
                },
                {
                    type: 'month',
                    count: 1,
                    text: '1m',
                    title: 'View 1 month',
                },

                {
                    type: 'all',
                    text: 'All',
                    title: 'View all data',
                },


            ],
        },

        chart: {
            type: "line",
            height: '24%',
            inverted: false,
            /*             marginRight: 20,
                        marginLeft: 20, */
            zoomType: "x",
            panning: {
                enabled: true,
                type: 'x'
            },
            panKey: 'shift',


        },

        boost: { enabled: true },

        tooltip: {
            enabled: true,
        },

        animation: true,
        // title: {
        //     text: gembaDisplay !== 1 ? `${name}  ${spec ?? ''}` : undefined,
        // },

        yAxis: {
            min: null,
            // crosshair: true,
            title: {
                text: "Values",
            },
            plotLines: null,

        },

        xAxis: {
            crosshair: true,
            ordinal: true,


        },

        credits: {
            enabled: false,
        },

        legend: false,



        series: [
            {
                name: "",
                data: [],
            },
        ],

    });

    useEffect(() => {
        let formattedData
        let minTime

        if (chartData2 && chartLimits2) {
            let color

            formattedData = chartData2.map((item) => ({
                x: item.x,
                y: item.y,
                loc_rules: item.dis_rules,
                spc_result: item.spc_result,
                evaluation_number: item.evaluation_number,
                color: item.spc_result === 0 ? 'red'
                    : (item.spc_result === 1 ? 'green'
                        : undefined),

            }));

            minTime = chartData2[chartLimits2.minData]?.x
        }


        setChartOptions2((prev) => {
            return {
                ...prev,


                xAxis: {
                    type: 'datetime',
                    title: { text: 'Production Date' },
                    labels: {
                        formatter: function () {
                            return Highcharts.dateFormat('%d/%m', this.value);
                        }
                    },
                    min: minTime,


                },

                yAxis: {

                    opposite: false,
                    plotLines: [
                        {
                            value: chartLimits2?.dis_ucl,
                            color: "green",
                            dashStyle: "shortdash",
                            width: 2,
                            label: {
                                text: "UCL",
                                align: "right",
                                x: -1,
                            },
                        },
                        {
                            value: chartLimits2?.dis_lcl,
                            color: "green",
                            dashStyle: "shortdash",
                            width: 2,
                            label: {
                                text: "LCL",
                                align: "right",
                                x: -1,
                            },
                        },
                        {
                            value: chartLimits2?.dis_target,
                            color: "black",
                            dashStyle: "shortdash",
                            width: 2,
                            label: {
                                text: "Target",
                                align: "right",
                                x: -1,
                            },
                        },
                    ],
                },

                tooltip: {
                    positioner: function () {
                        return {
                            x: this.chart.chartWidth - this.label.width - 5,
                            y: 20,

                        };
                    },
                    shape: 'square',

                    formatter: function () {
                        var tooltipContent =
                            '<b>' + this.point.evaluation_number + '</b><br/>' +
                            '<b>' + Highcharts.dateFormat('%d/%m/%y %H:%M', this.x) + '</b><br/>' +
                            '<b>' + this.y + '</b><br/>' +
                            '<b>' + this.point.loc_rules


                        return tooltipContent;
                    }
                },
                title: {
                    text: "<b>Dispersion Chart</b>",
                    align: 'left',
                    margin: 10,
                    x: 30,

                },

                series: [{
                    data: formattedData,
                    //    key: ["x", "y", "color"],
                    lineWidth: 1,

                    /*        marker: {
                               symbol: 'circle',
                               radius: 2,
                           }, */

                }]
            }
        })


    }, [chartData2, chartLimits2])


    return (

        <div id="chart">
            <HighchartsReact
                options={chartOptions1}
                highcharts={Highcharts}

            />

            <HighchartsReact
                options={chartOptions2}
                highcharts={Highcharts}

            />
        </div>
    )
}



