import { Box, Button, Grid } from "@material-ui/core";
import { isInteger, useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { array, object, string, number } from "yup";


import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import moment from "moment";

import plantService from "../../../services/plantService"
import customerService from "../../../services/customerService";
import processService from "../../../services/processService";
import formToolService from "../../../services/formTool"


export function FormToolNewForm({
    componentProps = null,
    Footer = () => null,
    closeForm = () => { }

}) {
    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [plantOptions] = useState([])
    const [customerOptions] = useState([])
    const [processOptions] = useState([])
    const { globalPlant } = useContext(PlantContext)

    let form = useFormik({
        initialValues: {
            plant: null,
            customer: null,
            process: null,
            process_id: null,
            tool_number: null,
            tool_name: null,
            tool_make: null,
            cavity_count: null,
            lead_time: null,
            tool_release_date: null,
            tool_life: null,
            threshold_quantity: null,
            current_tool_life: null,
            plantOptions: [],
            customerOptions: [],
            processOptions: []

        },

        validationSchema: object().shape({
            plant: object().required(),
            customer: object().required(),
            process: object().required(),
            tool_number: string().required(),
            tool_name: string().required(),
            cavity_count: number().integer().required(),
            threshold_quantity: number().integer().required().max(100),
            tool_life: number().integer().required(),
            current_tool_life: number().integer().required(),
        }),

        onSubmit: saveForm



    });

    //console.log(form.values.plantOptions)
    //console.log(globalPlant.globalPlant)
    //console.log(currentUser)

    useEffect(() => {
        plantService.getDefaultPlant(currentUser?.id)
            .then((res) => {
                const plantOptions = (res?.data ?? []).slice(1);
                form.setFieldValue("plantOptions", plantOptions);
            })
    }, []);

    useEffect(() => {
        if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
            form.setFieldValue("plant", (globalPlant?.globalPlant));
            form.setFieldValue("plant_flag", 0)

        } else {
            if (currentUser.default_plant) {
                const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 1)

            } else {
                const defaultPlant = form.values.plantOptions[0]
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 2)
            }
        }
    }, [form.values.plantOptions]);

    useEffect(() => {
        customerService.getCustomers(currentUser?.client_id)
            .then((res) => {
                //   console.log(res)
                const customerOptions = res?.data.data ?? []
                //   console.log("customerOptions", customerOptions)
                form.setFieldValue("customerOptions", customerOptions);
            })

        processService.getFormProcess()
            .then((res) => {
                const processOptions = res?.data ?? []
                //    console.log("processOptions", processOptions)
                form.setFieldValue("processOptions", processOptions);
            })

    }, [currentUser?.id, currentUser?.client_id])





    function saveForm() {
        let payload = {
            plant_id: form.values?.plant?.id,
            customer_id: form.values.customer.id,
            process_id: form.values.process.process_id,
            tool_name: form.values.tool_name,
            tool_number: form.values.tool_number,
            tool_make: form.values.tool_make,
            threshold_quantity: form.values.threshold_quantity,
            tool_life: form.values.tool_life,
            current_tool_life: form.values.current_tool_life,
            cavity_count: form.values.cavity_count,
            user_id: currentUser.id,
            client_id: currentUser.client_id,
            tool_release_date: moment(form.values.tool_release_date).format("YYYY-MM-DD")
        }

        formToolService.createFormTool(payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    open: true,
                    message: "Tool added successfully.",
                    type: "success",
                });
            })
            .catch((res) => {
                updateAlert({
                    open: true,
                    message: "Failed to added tool",
                    type: "error",
                });
            });
    }



    return (

        <Grid container spacing={3}>
            <Grid item md={4} sm={4} lg={4}>
                <DGSelect
                    id="plant"
                    label="Plant"
                    options={form.values.plantOptions}
                    getOptionLabel={(option) => option.name}
                    value={form.values.plant}
                    onChange={(val) => form.setFieldValue("plant", val)}
                    error={form.touched.plant && Boolean(form.errors.plant)}
                    required
                />
            </Grid>
            <Grid item md={5} sm={5} lg={5}>
                <DGSelect
                    label="Customer"
                    value={form.values.customer}
                    options={form.values.customerOptions}
                    getOptionLabel={(option) => option?.company_name ?? ""}
                    onChange={(val) => form.setFieldValue("customer", val)}
                    error={form.touched.customer && Boolean(form.errors.customer)}
                    required
                />
            </Grid>
            <Grid item md={3} sm={3} lg={3}>
                <DGSelect
                    label="Process"
                    value={form.values.process}
                    options={form.values.processOptions}
                    getOptionLabel={(option) => option?.process_name ?? ""}
                    onChange={(val) => form.setFieldValue("process", val)}
                    error={form.touched.process && Boolean(form.errors.process)}
                    required
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}>
                <DGInput
                    id="tool_name"
                    label="Tool name"
                    isFormik
                    value={form.values?.tool_name}
                    onChange={form.handleChange}
                    required

                />
            </Grid>
            <Grid item md={2} sm={2} lg={2}>
                <DGInput
                    id="tool_number"
                    label="Tool Number"
                    isFormik
                    value={form.values?.tool_number}
                    onChange={form.handleChange}
                    required

                />
            </Grid>


            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="tool_make"
                    label="Tool Make"
                    isFormik
                    value={form.values?.tool_make}
                    onChange={form.handleChange}

                />
            </Grid>
            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="cavity_count"
                    label="Cavity Count"
                    isFormik
                    value={form.values?.cavity_count}
                    onChange={form.handleChange}
                    error={form.touched.cavity_count && Boolean(form.errors.cavity_count)}
                    helperText={form.touched.cavity_count ? "Cavity count is integer only" : ''}
                    required

                />
            </Grid>
            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="lead_time"
                    label="Lead Time (Days)"
                    isFormik
                    value={form.values?.lead_time}
                    error={form.touched.lead_time && Boolean(form.errors.lead_time)}
                    helperText={form.touched.lead_time ? "Cavity count is integer only" : ''}
                    onChange={form.handleChange}
                />
            </Grid>

            <Grid item md={4} sm={4} lg={4}>
                <DGDateInput
                    id="tool_release_date"
                    label="Tool Released Date"
                    isFormik
                    value={form.values?.tool_release_date}
                    onChange={(date) => form.setFieldValue("tool_release_date", date)}
                />
            </Grid>


            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="tool_life"
                    label="Tool Life (Shots)"
                    isFormik
                    value={form.values?.tool_life}
                    error={form.touched.tool_life && Boolean(form.errors.tool_life)}
                    helperText={form.touched.tool_life ? "Tool life (shots) is integer only" : ''}
                    onChange={form.handleChange}
                    required
                />
            </Grid>
            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="threshold_quantity"
                    label="Threshold Quantity (% of life)"
                    isFormik
                    value={form.values?.threshold_quantity}
                    onChange={form.handleChange}
                    error={form.touched.threshold_quantity && Boolean(form.errors.threshold_quantity)}
                    helperText={form.touched.threshold_quantity ? "Tool life should be less than 100" : ''}
                    required
                />
            </Grid>
            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="current_tool_life"
                    label="Current Tool Life (Shots)"
                    isFormik
                    value={form.values?.current_tool_life}
                    onChange={form.handleChange}
                    error={form.touched.current_tool_life && Boolean(form.errors.current_tool_life)}
                    helperText={form.touched.current_tool_life ? "Tool life (shots) is integer only" : ''}
                    required
                />
            </Grid>



            <Footer onSave={form.handleSubmit} isValid={form.isValid} />
        </Grid>
    );

}
export default FormToolNewForm;