/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Button, AppBar, Toolbar } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from "../../../../context/AuthContextProvider";
import spcService from "../../../../services/spcService";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGDateTimePicker } from "../../../../components/shared/DGDateTimePicker"
import { useFormik } from "formik";
import { CLGraph } from "./TimeLimitEntryGraph";
import Loader from "../../../../components/Loader";
import { AlertContext } from "../../../../context/AlertContextProvider";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import approvalService from "../../../../services/approvalService";


export default function ControlLimitView({
  componentProps = {},
  onSuccess = () => { },

}) {
  const { v4: uuidv4 } = require("uuid");
  const [evalData, setEvalData] = useState(0)
  const [rowData1, setRowData1] = useState(null);
  const [rowData2, setRowData2] = useState(null);
  const [cols, setCols] = useState([]);
  let { currentUser } = useContext(AuthContext);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { updateAlert } = useContext(AlertContext);
  const [approveFlag, setApproveFlag] = useState(0)

  let form = useFormik({
    initialValues: {
      guideline: "",
      part: "",
      char: "",
      spec: "",
      rows: [],
      short_term_capability: "",
      characteristics: "",
      report_name: "",
      machine_spindle: "",
      evaluation_date: "",
      evaluation_method: "",
      template_name: "",
      short_term_capability: "",
      loc_chart: "",
      loc_eval: "",
      dis_chart: "",
      dis_eval: "",
      Location_Chart: [],
      Dispersion_Chart: [],
      dist_model: "",
      outlier_flag: "",
      input_values: [],
      approver: null,
      approverOptions: [],
      requested_by: "",
      approved_by: "",
      report_name: "",

    },


  })


  useEffect(() => {

    const fetchData = async () => {
      try {
        const res = await spcService.getSPCCLId(componentProps.id);
        if (res.data?.[0]?.result_id) {
          setEvalData(res?.data[0]);
          determineApproveFlag(res?.data[0])
          setDataLoaded(false)
        } else {
          fetchData();
          setDataLoaded(true)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [componentProps]);

  function determineApproveFlag(rData) {
    if (
      !rData?.approval_status_id < 2
    ) {
      if (currentUser.id === rData?.approver_id) {
        var approve_flag = 1;
      } else if (currentUser.id === rData?.requested_by_id) {
        var approve_flag = 0;
      } else if (currentUser.user_role < 4) {
        var approve_flag = 1;
      }
    }
    setApproveFlag(approve_flag)
  }


  function ApproveORReject(statusId) {
    let payload = {
      approval_id: evalData?.approval_id,
      user_id: currentUser?.id,
      status_id: statusId,
    };
    approvalService.createApprovalDecision(payload)
      .then((res) => {
        let message;
        if (statusId === 2) {
          message = "Request Approved";
        } else { message = "Request Rejected" }
        updateAlert({
          open: true,
          type: "success",
          message: message
        })
      })
      .catch((err) => {
        updateAlert({
          open: true,
          type: "error",
          message: "Approval decision failed to update",
        });
      })

  }


  useEffect(() => {

    if (evalData) {

      form.setFieldValue("part", evalData.part)
      form.setFieldValue("characteristics", (evalData.character_name + "  " + evalData.character_specification))
      form.setFieldValue("report_name", evalData.report_name)
      form.setFieldValue("machine_spindle", evalData.machine_spindle)
      form.setFieldValue("evaluation_date", evalData.evaluation_date)
      form.setFieldValue("template_name", evalData.template_name)
      form.setFieldValue("short_term_capability", evalData.short_term_capability)
      form.setFieldValue("template_name", evalData.template_name)

      form.setFieldValue("loc_chart", evalData.loc_chart)
      form.setFieldValue("loc_eval", evalData.loc_eval)
      form.setFieldValue("dis_chart", evalData.dis_chart)
      form.setFieldValue("dis_eval", evalData.dis_eval)
      form.setFieldValue("dist_model", evalData.dist_model)
      form.setFieldValue("outlier_flag", evalData.outlier_flag)

      form.setFieldValue("evaluation_method", evalData.evaluation_method)

      form.setFieldValue("requested_by", evalData.requested_by)
      form.setFieldValue("approved_by", evalData.approver_name)

      form.setFieldValue("report_name", evalData.report_name)

      if (evalData.input_values != null) {
        form.setFieldValue("input_values", JSON.parse(evalData.input_values));
      }
      if (evalData.Location_Chart != null) {
        form.setFieldValue("Location_Chart", evalData.Location_Chart.split(" "))
        form.setFieldValue("Dispersion_Chart", evalData.Distribution_Chart.split(" "))
      }

      if (evalData.loc_lcl) {
        form.setFieldValue("loc_lcl", evalData.loc_lcl)
        form.setFieldValue("loc_target", evalData.loc_target)
        form.setFieldValue("loc_ucl", evalData.loc_ucl)
        form.setFieldValue("dis_lcl", evalData.dis_lcl)
        form.setFieldValue("dis_target", evalData.dis_target)
        form.setFieldValue("dis_ucl", evalData.dis_ucl)

      }


    }

  }, [evalData])



  useEffect(() => {
    const fetchData = async () => {
      try {
        let temp = null
        temp = [
          {
            header: 'Location',
            lower: form?.values?.Location_Chart[0],
            target: form?.values?.Location_Chart[1],
            upper: form?.values?.Location_Chart[2]
          },
          {
            header: 'Dispersion',
            lower: form?.values?.Dispersion_Chart[0],
            target: form?.values?.Dispersion_Chart[1],
            upper: form?.values?.Dispersion_Chart[2]
          },
        ]
        setRowData1(temp);

      }
      catch (error) {
        console.error(error);
      }
    }
    fetchData()

  }, [form.values.Location_Chart])


  useEffect(() => {
    const fetchData = async () => {
      try {
        let sampleSize = null;
        let sampleLength = null;

        if (form.values.input_values) {
          sampleSize = form.values.input_values[0]?.length;
          sampleLength = form.values.input_values?.length;
        }

        setRowData2(form.values.input_values);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [form.values]);


  useEffect(() => {
    if (evalData.input_values) {

      const data = JSON.parse(evalData.input_values);
      const colcount = data[0].length;

      setRowData2(data);

      const columnDefs = [];
      for (let i = 0; i < colcount; i++) {
        columnDefs.push({
          headerName: (i + 1).toString(),
          field: i.toString(),
        });
      }

      setCols([
        {
          field: "serial_number",
          headerName: "SI No",
          valueGetter: "node.rowIndex + 1",
          filter: "agNumberColumnFilter",
          editable: false
        },
        ...columnDefs
      ]);

    }

  }, [evalData])


  if (dataLoaded) {
    return <Loader />;
  }


  return (
    <>

      {approveFlag === 1 && (
        <div>
          <Button
            //className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => { ApproveORReject(2) }}
            style={{ marginRight: 9 }}
          >
            APPROVE
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            startIcon={<CancelOutlinedIcon />}
            onClick={() => { ApproveORReject(3); }}
          >
            REJECT
          </Button>
        </div>
      )}




      <Grid container spacing={3} style={{ marginTop: '10px' }} >
        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            // disabled
            label="Part Number"
            value={form?.values?.part}
          />
        </Grid>

        <Grid item md={5} sm={5} lg={5}>
          <DGInput
            // fullWidth
            //disabled
            label="Characteristics"
            value={form?.values?.characteristics}
          />
        </Grid>

        <Grid item md={3} sm={3} lg={3}>
          <DGInput
            //fullWidth
            //disabled
            label="Report"
            value={form?.values?.report_name}
          />
        </Grid>

        <Grid item md={4} sm={4} lg={4} >
          <DGInput
            // disabled
            label="Machine"
            value={form?.values?.machine_spindle}
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGDateTimePicker
            // disabled
            label="Evaluation Date"
            value={form?.values?.evaluation_date}
            format="dd/MM/yyyy"
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGInput
            // disabled
            label="Guidelines"
            value={form?.values?.template_name}
          />
        </Grid>
      </Grid>


      <Grid container spacing={3} style={{ marginTop: '10px' }} >

        <Grid item md={9} sm={9} xs={12}>
          <DGInput
            label="Evaluation Method"
            value={form?.values?.evaluation_method}
          //disabled
          />
        </Grid>
        <Grid item md={3} sm={3} xs={12}>
          <DGInput
            label="Distribution Model"
            value={form?.values?.dist_model || "Not Applicable"}
          />
        </Grid>

        <Grid container spacing={3} style={{ marginTop: '10px' }}>
          <Grid item md={3} sm={3} xs={3}
            style={{
              display: 'flex', alignItems:
                'center', justifyContent: 'center'
            }}>
            <h2>
              Control Limit
            </h2>
          </Grid>

          <Grid item md={3} sm={3} xs={3}
            style={{
              display: 'flex', alignItems:
                'center', justifyContent: 'center'
            }}>
            <h2>
              Lower Control Limit
            </h2>
          </Grid>
          <Grid item md={3} sm={3} xs={3}
            style={{
              display: 'flex', alignItems:
                'center', justifyContent: 'center'
            }}>
            <h2>
              Target
            </h2>
          </Grid>
          <Grid item md={3} sm={3} xs={3}
            style={{
              display: 'flex', alignItems:
                'center', justifyContent: 'center'
            }}>
            <h2>
              Upper Control Limit
            </h2>
          </Grid>

          {/* ------------------------------------- */}
          <Grid item md={3} sm={3} xs={3}
            style={{
              display: 'flex', alignItems:
                'center', justifyContent: 'right'
            }}>
            <h2>Location </h2>
          </Grid>

          <Grid item md={3} sm={3} xs={3}>
            <DGInput
              value={form?.values?.loc_lcl?.toFixed(4)}
              label="Lower Control Limit"
              id="loc_lcl"
            />
          </Grid>
          <Grid item md={3} sm={3} xs={3}>
            <DGInput
              id="loc_target"
              label="Target"
              value={form?.values?.loc_target?.toFixed(4)}

            />
          </Grid>
          <Grid item md={3} sm={3} xs={3}>
            <DGInput
              id="loc_ucl"
              label="Upper Control Limit"
              value={form?.values?.loc_ucl?.toFixed(4)}
            />
          </Grid>
          {/* ------------------------------------- */}
          <Grid item md={3} sm={3} xs={3}
            style={{
              display: 'flex', alignItems:
                'center', justifyContent: 'right'
            }}>
            <h2>Dispersion </h2>
          </Grid>
          <Grid item md={3} sm={3} xs={3}>
            <DGInput
              id="dis_lcl"
              value={form?.values?.dis_lcl?.toFixed(4)}
              label="Lower Control Limit"
            />
          </Grid>
          <Grid item md={3} sm={3} xs={3}>
            <DGInput
              label="Target"
              id="dis_target"
              value={form?.values?.dis_target?.toFixed(4)}

            />
          </Grid>
          <Grid item md={3} sm={3} xs={3}>
            <DGInput
              id="dis_ucl"
              label="Upper Control Limit"
              value={form?.values?.dis_ucl?.toFixed(4)}
            />
          </Grid>

        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            label="Location Chart"
            value={form?.values?.loc_chart}
          //disabled
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            //disabled
            label="Location Evaluation for Process Capability"
            value={form?.values?.loc_eval}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            //disabled
            label="Dispersion Chart"
            value={form?.values?.dis_chart}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            //disabled
            label="Dispersion Evaluation for Process Capability"
            value={form?.values?.dis_eval}
          />
        </Grid>



        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            // disabled
            label="Outliers"
            value={evalData.study_id !== 1500 ? form?.values?.outlier_flag : "Not Applicable"}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <DGInput
            //disabled
            label="Short Term Capability"
            value={form?.values?.short_term_capability || "Not Applicable"}

          />
        </Grid>



      </Grid>

      {/*       <Box width="50%" display="inline-block" padding={1}>
        <CommonGrid
          needExport={false}
          rows={rowData1}
          columns={columnDefs1}
          gridheight={450}
          tableName="Control Limit"

        />
      </Box> */}



      <Grid container spacing={3} style={{ marginTop: '10px' }} >
        <Grid item md={6} sm={6} xs={6}>
          <DGInput
            label="Approval Requested By"
            value={form?.values?.requested_by}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <DGInput
            label={approveFlag === 1 ? "Approved By" : "To be Approved By"}
            value={form?.values?.approved_by}

          />
        </Grid>


      </Grid>

      <Box width="100%" display="inline-block" padding={1}>

        <CLGraph
          input_values={form.values.input_values}
          specifications={evalData.character_specification}
          rowData1={rowData1}
          clgraphtype={0}
          chartTitle={form?.values?.loc_chart}
        />

        {/* <Divider style={{ backgroundColor: 'green' }} /> */}

        {evalData.study_id !== 1500 &&
          <CLGraph
            input_values={form.values.input_values}
            specifications={evalData.character_specification}
            rowData1={rowData1}
            clgraphtype={1}
            chartTitle={form?.values?.dis_chart}
          />
        }
      </Box>

      {/* <Divider style={{ backgroundColor: '#ff0000' }} /> */}


      {evalData.study_id !== 1500 &&
        <Grid item md={12} sm={12} xs={12}>
          <CommonGrid
            needExport={true}
            rows={rowData2}
            columns={cols}
            gridheight={250}
            tableName={"Input Data"}



          />
        </Grid>
      }


    </>
  )

}
