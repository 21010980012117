import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    Paper,
    Tab,
    Tabs,
    withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SubNavigationContext } from "../../context/SubnavigationProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider"
import ProductCharacteristicsList from "./characteristicstab/ProductCharacteristicsList"
import { ResourceTab } from "./resourcetab/ResourceTab";
import { MsaListing } from "../msa/msatab/MsaListing"
import { ProductInspetionTab } from "../productionInspection/productinspection/ProductInspectionTab"
import productService from "../../services/productService";
import SPCcharacterTab from "../spc/spctab/spctab/SPCcharacterTab"
import { PDIPlanTab } from "../preDispatchInspection/Plan/PdiPlanTab"



function CharacterProductDetails({ }) {
    let { id } = useParams();
    const [selectedTab, setSelectedTab] = useState(0);
    const [pageTitles, setPageTitle] = useState("");
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const buttons = [
        "Character List",
        "Resource",
        "MSA",
        "Production Inspection",
        "SPC",
        "Pre-Dispatch Inspection",

    ];

    useEffect(() => {
        productService.getProductById(id)
            .then((res) => {
                setHeaderPageTitle(`${res.data.part_number} ${res.data.part_name}`);

            })

    }, []);


    return (

        <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => setSelectedTab(val)}
                aria-label="disabled tabs example"
            >
                {buttons.map((btn, index) => (
                    <StyledTab label={btn} value={index} />
                ))}
            </Tabs>
            <Box m={2}>
                {selectedTab === 0 ? <ProductCharacteristicsList /> : null}
                {selectedTab === 1 ? <ResourceTab /> : null}
                {selectedTab === 2 ? <MsaListing /> : null}
                {selectedTab === 3 ? <ProductInspetionTab /> : null}
                {selectedTab === 4 ? <SPCcharacterTab /> : null}
                {selectedTab === 5 ? <PDIPlanTab /> : null}

                {/*{selectedTab === 4 ? <MachineProductTable /> : null}
                {selectedTab === 5 ? <MachineProductTable /> : null} */}

            </Box>
        </Paper>
    );
}

export default CharacterProductDetails;

const StyledTab = withStyles((theme) => ({
    wrapper: {
        textTransform: "none",
    },
    root: {
        textTransform: "none",
    },
}))((props) => <Tab disableRipple {...props} />);
