import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ActiveInActive from "../../../../components/ActiveInActive";
import CommonGrid from "../../../../components/CommonGrid";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import productionInspection from "../../../../services/productionInspection";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { PlanForm } from "./PlanForm";
import { NewFromPlanForm } from "./NewFromPlanForm";
import { ACTION_TYPE } from "../../../../components/shared/commonenum";
import StatusFilter from "../../../../components/shared/StatusFilter";
import KeyboardOutlinedIcon from "@material-ui/icons/KeyboardOutlined";

export function PlanListing({ partDetails = null }) {
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [newformOpen, setNewFormOpen] = useState(false);
  let id = useParams();

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(),
      callback: updatePlanStatus,
    }));
    reloadGridData();
  }, []);

  function updatePlanStatus(dummy1, dummy2, newStatus, rowId) {
    return productionInspection.updateInspectionPlan(rowId, {
      enabled: newStatus,
    });
  }

  function reloadGridData() {
    productionInspection.getInspectPartPlan(id?.id).then((res) => {
      setRows(res?.data ?? []);
    });
  }

  function loadForm(data) {
    ACTION_TYPE.view = true;
    setFormData(data?.data);
    setFormOpen(true);
  }

  function onRowSelection(data) {
    //  console.log(data);
    setSelectedId(data[0]["id"]);
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      pinned: "left",
    },
    {
      field: "plan_name",
      headerName: "Plan Number",
      minWidth: 250,
      filter: "agTextColumnFilter",
      pinned: "left",
      floatingFilter: true,
      cellRenderer: (params) => {
        return (
          <Button size="small" color="primary" onClick={() => loadForm(params)}>
            {`${params?.data?.id_plan}-${params?.data?.plan_name}`}
          </Button>
        );
      },
      resizable: true,
    },
    {
      field: "count_character",
      headerName: "# of Charcterstics",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },
    {
      field: "count_plan",
      headerName: "# of Evaluations",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.report_name,
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.plan_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setFormOpen(true)}
        >
          PLAN
        </Button>

        {selectedId ? (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<KeyboardOutlinedIcon />}
            onClick={() => setNewFormOpen(true)}
            style={{ marginLeft: 3 }}
          >
            New From
          </Button>
        ) : null}
      </>
    );
  }
  return (
    <Grid item md={12} sm={12} lg={12}>
      <CommonGrid
        rows={rows}
        columns={columns}
        ButtonComponent={ButtonComponent}
        onRowSelect={onRowSelection}
        gridheight={212}
      />
      <DGDrawer
        Component={PlanForm}
        isOpen={formOpen}
        drawerWidth={1150}
        title={ACTION_TYPE.view ? formData?.id_plan : "New Plan"}
        subtitle={
          ACTION_TYPE.view
            ? formData?.plan_name
            : new Date().toLocaleDateString()
        }
        setOpen={(state) => {
          setFormData(null);
          setFormOpen(state);
          reloadGridData();
        }}
        needSave={!ACTION_TYPE.view}
        componentProps={{ partDetails, formData }}
      />
      <DGDrawer
        Component={NewFromPlanForm}
        isOpen={newformOpen}
        drawerWidth={1150}
        /*         title={ACTION_TYPE.view ? formData?.id_plan : "New Plan"}
                subtitle={
                  ACTION_TYPE.view
                    ? formData?.plan_name
                    : new Date().toLocaleDateString()
                } */
        setOpen={(state) => {
          //setFormData(null);
          setNewFormOpen(state);
          reloadGridData();
        }}
        componentProps={{ selectedId }}
        needSave
      />
    </Grid>
  );
}
