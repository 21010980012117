import { AppBar, Button, Grid, Paper, Switch, Divider, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import productionInspection from "../../../../services/productionInspection";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import { DGDrawer } from "../../../../components/DGDrawer";
import { useFormik } from "formik";
import { ResultForm } from "../Results/ResultForm"
import moment from "moment";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { PIResultGraph } from "./PIResultGraph";
import Chip from '@material-ui/core/Chip';
import { transparentize } from 'polished';
import errorLog from "../../../../services/errorLog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { black } from "material-ui/styles/colors";


export function PITrendDetail({
    componentProps = null,
}) {
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [formOpen, setFormOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [rows, setRows] = useState([]);
    const [plantOptions] = useState([])
    const [spindleFilter, setSpindleFilter] = useState([])
    const [charDetails, setCharDetails] = useState([])
    const { v4: uuidv4 } = require("uuid");
    const [specChecked, setSpecChecked] = useState(false);
    const [uspec, setUspec] = useState('');

    let form = useFormik({
        initialValues: {
            machine_spindle_id: [],
            machine_spindle_name: [],
            color: null,
            spindleOption: [],

        },
    });



    useEffect(() => {
        setCharDetails(componentProps);
    }, []);

    useEffect(() => {
        reloadGridData();
    }, []);

    useEffect(() => {
        reloadGridData();
    }, [componentProps]);


    useEffect(() => {
        if (componentProps.gemba_display === 1) {
            setSpecChecked(true)
        }
        reloadGridData();
    }, [componentProps]);


    function reloadGridData() {
        productionInspection.getInspectCharResult(componentProps.id)
            .then((res) => {
                setRows(res?.data ?? []);
            })
            .catch((err) => {
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "getInspectCharResult",
                    client_id: null,
                    user_id: null,
                    entity_id: componentProps?.id,
                };
                errorLog.createErrorLog(payload)
            });
        productionInspection.getCharacterSpindle(componentProps.id)
            .then((res) => {
                var temp = res?.data ?? []
                form.setFieldValue("spindleOption", temp);
            })
            .catch((err) => {
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "getCharacterSpindle",
                    client_id: null,
                    user_id: null,
                    entity_id: componentProps?.id,
                };
                errorLog.createErrorLog(payload)
            });
    }

    useEffect(() => {
        if (componentProps.upper_specification)
            if (componentProps.upper_specification > 0) {
                setUspec(` +` + componentProps.upper_specification)
            }
            else {
                setUspec(componentProps.upper_specification)
            }

    }, [componentProps]);

    console.log("componentProps", uspec);



    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }));




    const chipclasses = useStyles()

    function loadForm(data) {

        setSelectedRow(data);
        setFormOpen(true);
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 100,
        },
        {
            field: "request_name",
            headerName: "Request Number",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => loadForm(params?.data)}
                    >
                        {`${params?.data?.request_name}`}
                    </Button>
                );
            },
        },

        {
            field: "production_date",
            headerName: "Production Date",
            sortable: true,
            resizable: true,
            minWidth: "150",
            floatingFilter: true,
            autoHeight: true,
            filter: "agDateColumnFilter",
            filterParams: filterParams,
            valueGetter: (params) =>
                moment(new Date(params?.data?.production_date)).format("DD/MM/YYYY"),
        },
        {
            field: "production_time",
            headerName: "Production Time",
            sortable: true,
            resizable: true,
            minWidth: "150",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
        },
        {
            // field: "raw_data",
            headerName: "Raw Data",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
            valueGetter: (params) => {
                if (params?.data?.measurement_type === 303) {
                    let i = 0
                    const raw_data = []
                    for (let i = 0; i < params?.data.raw_data.length; i++) {
                        const value = params?.data.raw_data[i];
                        if (value === 1) {
                            raw_data.push('OK');
                        } else if (value === 0) {
                            raw_data.push('NOT OK');
                        } else {
                            raw_data.push('');
                        }
                    }
                    return raw_data
                } else {
                    return params?.data.raw_data;
                }
            },
        },
        {
            field: "batch_details",
            headerName: "Batch Details",
            sortable: true,
            resizable: true,
            minWidth: "200",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
        },
        {
            field: "machine_spindle_name",
            headerName: "Machine Spindle",
            minWidth: 150,
            filter: "agTextColumnFilter",
            floatingFilter: true,
            resizable: true,
        },

        {
            field: "character_result",
            headerName: "Result",
            sortable: true,
            resizable: true,
            minWidth: "100",
            floatingFilter: true,
            autoHeight: true,
            filter: "agTextColumnFilter",
            cellRenderer: (params) => (
                <ResultRenderer value={params?.data?.character_result} />
            ),
        },


    ];

    const handleChipClick = (machine_spindle_id) => {
        if (!spindleFilter.includes(machine_spindle_id)) {
            setSpindleFilter([...spindleFilter, machine_spindle_id]);
        } else {
            setSpindleFilter(spindleFilter.filter((id) => id !== machine_spindle_id));
        }
    };

    function handleSwitch() {
        if (!specChecked) {
            setSpecChecked(true)
        }
        else {
            setSpecChecked(false)

        }

    }


    return (

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={8}
        >
            {componentProps.gemba_display &&
                <>
                    <Grid item md={12} sm={12} lg={12} direction="column"
                        className="dg_dash_cc" alignItems="stretch"
                    >
                        <Typography style={{ color: black }} align="center" variant="h3">
                            {componentProps?.part_number} {componentProps.part_name} {"   -   "} {componentProps.character_name} {"  "}
                            {componentProps.specification ?? ''}
                            {componentProps.upper_specification
                                ? uspec
                                : ''}
                            {"  "}{componentProps.lower_specification ?? ''}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Divider style={{ margin: '10px auto', width: '50%' }} />
                    </Grid>
                </>

            }
            <Grid container md={12} sm={12} lg={12} spacing={4}>
                {componentProps.count_character_graph > 0 &&
                    <Grid item md={12} sm={12} lg={12}>
                        <PIResultGraph
                            id={componentProps.id}
                            name={componentProps.character_name}
                            spec={`${componentProps.specification ?? ''} ${componentProps.upper_specification ?? ''} ${componentProps.lower_specification ?? ''}`}
                            spindleFilter={spindleFilter}
                            specDisplay={specChecked}
                            gembaDisplay={componentProps.gemba_display}
                        />
                    </Grid>
                }
                <>
                    {!componentProps.gemba_display &&
                        <Grid item md={3} sm={3} lg={3}>
                            <FormControlLabel
                                control=
                                {<Switch
                                    checked={specChecked}
                                    onChange={(e) => handleSwitch(e.target.checked)}
                                    color="primary"
                                    name="Characteristics Trend"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                />}
                                label="Specification Display"
                            >
                            </FormControlLabel>

                        </Grid>
                    }


                    <Grid item md={9} sm={9} lg={9}>
                        <div className={chipclasses.root}>
                            {form.values.spindleOption.map((item) => (
                                <Chip
                                    key={item.machine_spindle_id}
                                    label={item.machine_spindle_name}
                                    style={{
                                        backgroundColor: spindleFilter.includes(item.machine_spindle_id)
                                            ? item.color
                                            : transparentize(0.8, item.color),
                                        margin: '10px',
                                        fontWeight: spindleFilter.includes(item.machine_spindle_id) ? 'bold' : 'normal'
                                    }}
                                    onClick={componentProps.gemba_display !== 1 ? () => handleChipClick(item.machine_spindle_id) : undefined}
                                />
                            ))}
                        </div>

                    </Grid>
                </>
                {componentProps.only_graph === 0 && (
                    <>
                        <Grid item md={12} sm={12} lg={12}>
                            <CommonGrid
                                rows={rows}
                                columns={columns}
                            />
                        </Grid>
                        ,
                        <DGDrawer
                            Component={ResultForm}
                            title="Inspection Result"
                            isOpen={formOpen}
                            setOpen={(state) => {
                                setFormOpen(state);
                            }}
                            subtitle={new Date().toLocaleDateString()}
                            drawerWidth={1150}
                            componentProps={selectedRow}
                        />
                    </>
                )}
            </Grid>
        </Grid>
    );
}

var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
};