import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Button,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import studiesResultService from "../../services/studiesResultService";
import styled from "styled-components/macro";
import PrintPageContainer from "./PrintPageContainer";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { AuthContext } from "../../context/AuthContextProvider";
import CardsForTable from "./cards/cardForTable";
const Card = styled(MuiCard)(spacing);

const Reports = (props) => {
  let componentRef = useRef(null);
  let { currentUser } = useContext(AuthContext);
  const [studyData, setStudyData] = useState();
  const [studyTitle, setStudyTitle] = useState("");
  const studyId = props?.location?.state?.studyId
    ? props.location.state.studyId
    : null;
  const studyType = props?.location?.state?.studyType
    ? props.location.state.studyType
    : null;
  const gaugeCharacter = props?.location?.state?.gaugeCharacter
    ? props.location.state.gaugeCharacter
    : null;
  // console.log("🚀 -> file: Reports.js -> line 28 -> Reports -> studyId", studyId);
  // console.log("🚀 -> file: Reports.js -> line 29 -> Reports -> studyType", studyType);
  // console.log("🚀 -> file: Reports.js -> line 30 -> Reports -> gaugeCharacter", gaugeCharacter);
  // console.log("🚀 -> file: Reports.js -> line 30 -> Reports -> currentUser", currentUser);

  useEffect(() => {
    getReportData(studyId, studyType, gaugeCharacter);
  }, [studyId, studyType]);

  const getReportData = async (studyId, studyType) => {
    if (studyType === "GRR Study (With operator influence)") {
      const grrStudyResults = await studiesResultService.getGrrStudy(studyId);
      grrStudyResults.data && setStudyData(grrStudyResults.data);
      setStudyTitle("GRR Study (With operator influence)");
    } else if (studyType === "GRR Study (Without operator influence)") {
      const grrStudyResults = await studiesResultService.getGrrStudy(studyId);
      grrStudyResults.data && setStudyData(grrStudyResults.data);
      setStudyTitle("GRR Study (Without operator influence)");
    } else if (studyType === "Gauge BIAS Analysis") {
      const biasStudyResults = await studiesResultService.getBiasStudy(studyId);
      biasStudyResults.data && setStudyData(biasStudyResults.data);
      biasStudyResults.data && setStudyTitle("Gauge BIAS Analysis");
    } else if (studyType === "Attribute system analysis: Signal Method") {
      const signalStudyResults = await studiesResultService.getSignalStudy(
        studyId
      );
      signalStudyResults.data && setStudyData(signalStudyResults.data);
      signalStudyResults.data &&
        setStudyTitle("Attribute system analysis: Signal Method");
    } else if (studyType === "Attribute system analysis: Kappa Method") {
      const kappaStudyResults = await studiesResultService.getKappaStudy(
        studyId
      );
      kappaStudyResults.data && setStudyData(kappaStudyResults.data);
      kappaStudyResults.data &&
        setStudyTitle("Attribute system analysis: Kappa Method");
    } else if (studyType === "Gauge Capability") {
      const cgkStudyResults = await studiesResultService.getCgkStudy(studyId);
      cgkStudyResults.data && setStudyData(cgkStudyResults.data);
      cgkStudyResults.data && setStudyTitle("Gauge Capability");
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Reports" />
      <Container>
        <div className="w-100 mb-2" style={{ textAlign: "right" }}>
          <ReactToPrint
            className="d-flex align-items-end justify-content-end"
            trigger={() => (
              <Button variant="contained" color="primary">
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <Card ref={componentRef}>
          <CardContent>
            <PrintPageContainer
              currentUser={currentUser}
              studyData={studyData}
              gaugeCharacter={gaugeCharacter}
              studyTitle={studyTitle}
              forNextPage={true}
            />
            {/* <div className="page-break" />
            {studyTitle === "Attribute system analysis: Signal Method" ||
            studyTitle === "Attribute system analysis: Kappa Method" ? (
              <>
                <div className="page-break" />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    marginBottom: 15,
                    marginTop:
                      studyTitle === "Attribute system analysis: Kappa Method"
                        ? 300
                        : 10,
                  }}
                  className="print-container"
                >
                  <CardsForTable
                    title={"Data Table"}
                    studyType={studyTitle}
                    studyData={studyData}
                    forNextPage={true}
                  />
                </Box>
              </>
            ) : null} */}
          </CardContent>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Reports;
