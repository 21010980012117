import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deepOrange, orange, red, yellow, } from "@material-ui/core/colors";


import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";

import AddIcon from "@material-ui/icons/Add";

import formTool from "../../../services/formTool"
import FormToolNewForm from "./FormToolNewForm"
import FormToolStatus from "./FormToolStatus";

export default function FormTool() {
    const [rows, setRows] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [evaluation, setEvaluation] = useState([]);
    const [plants, setPlants] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const globalplant = useContext(PlantContext);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [Active_, setActive] = useState(0);
    const [Under_Refurbishing, setUnder_Refurbishing] = useState(0);
    const [Quarantine_, setQuarantine] = useState(0);
    const [Scrapped_, setScrapped] = useState(0);
    const [Threshold_, setThreshold] = useState(0);
    const [selected, setSelected] = useState(101);
    const [gridFilter, setGridFilter] = useState([]);
    const [masterRows, setMasterRows] = useState([]);
    const [statusFormOpen, setStatusFormOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,

        }));

        reloadGridData();
    }, []);


    const statuses = [
        { title: 'Active', background: orange[100], value: Active_, id: 101 },
        { title: 'Rework/Quarantine', background: yellow[100], value: Under_Refurbishing, id: 109 },
        { title: 'Scrapped', background: red[200], value: Scrapped_, id: 103 },
        { title: 'Threshold ', background: deepOrange[100], value: Threshold_, id: 111 },
    ];


    useEffect(() => {
        setActive(gridFilter.filter(item => item.tool_status_id === 101).length);
        setUnder_Refurbishing(gridFilter.filter(item => item.tool_status_id === 109).length);
        setScrapped(gridFilter.filter(item => item.tool_status_id === 103).length);
        setThreshold(gridFilter.filter(item => item.tool_status_id === 101 && item.threshold_flag === 1).length);

    }, [gridFilter]);




    function updateGridData(id) {
        if (id === null) {
            setRows(masterRows);
            return;
        }
        if (id == 111) {
            var temp_data = masterRows.filter(item => item.tool_status_id === 101 && item.threshold_flag === 1)
            setRows(temp_data);
            return;
        }
        var temp_data = masterRows.filter(item => item.tool_status_id === id)
        setRows(temp_data);

    }

    useEffect(() => {
        reloadGridData()
    }, [globalplant]);



    function reloadGridData() {
        formTool.getFormToolTable(
            currentUser?.client_id,
            globalplant?.id ? currentUser?.default_plant : currentUser.client_id)
            .then((res) => {
                let temp_data
                if (selected) {
                    temp_data = res.data.filter(item => item.tool_status_id === selected)
                } else {
                    temp_data = res.data;
                }
                setGridFilter(res.data);
                setMasterRows(res.data);
                setRows(temp_data);
            });

        setHeaderPageTitle(`Form tool Master List`);
    }

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return formTool.updateFormTool(rowId, { enabled: newStatus });
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        {
            headerName: "Tool Name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agTextColumnFilter",
            resizable: true,
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            loadForm(data)
                        }}
                    >
                        {`${params?.data?.tool_name}-${params?.data?.tool_number}`}
                    </Button>
                );
            },
        },
        {
            field: "cavity_count",
            headerName: "Cavity Count",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "tool_life",
            headerName: "Tool Life (Shots)",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "total_shots",
            headerName: "Cumulative Shots",
            flex: 1,
            filter: "agMultiColumnFilter",
            minWidth: 200,
            valueGetter: (p) => {
                const count1 = parseInt(p?.data?.shots_sum) || 0;
                const count2 = parseInt(p?.data?.current_tool_life) || 0;
                return count1 + count2;
            },


        },

        {
            field: "current_tool_life",
            headerName: "Initial Shots ",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "threshold_quantity",
            headerName: "Threshold ",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <div>
                        {params?.data?.threshold_quantity}%
                    </div>
                );
            }
        },
        {
            field: "company_name",
            headerName: "Customer ",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "process_name",
            headerName: "Process ",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },
        {
            field: "tool_make",
            headerName: "Tool Make",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 200,
        },



        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            entityName: params?.data?.part_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                callback: changeStatus,
                                reloadGrid: reloadGridData,
                                rowId: params?.data?.id,
                                entity: params?.data?.tool_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];

    const StatusGridItem = ({ title, background, value, onClick, selected }) => (
        <Grid item container direction="column" xs={2}
            className={`${classes.common} ${selected ? classes.selected : ''}`}
            onClick={onClick}
            style={{ background }}
        >
            <Typography align="left" variant="h6" className={classes.header}>
                {title}
            </Typography>
            <Typography align="left" className={classes.count} variant="h1">
                {value}
            </Typography>
        </Grid>
    );


    function handleClick(id) {

        if (selected === id) {
            setSelected(null);
            updateGridData(null)
            return;
        } else {
            setSelected(id);
            updateGridData(id)
        }
    }

    const handleFilterApplied = (event) => {
        const allDisplayedNodes = event.api.getRenderedNodes();
        const displayedData = allDisplayedNodes.map(node => node.data);
        setGridFilter(displayedData);
    };


    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => setFormOpen(true)}
                >
                    Add Tool
                </Button>

            </>
        );
    }

    function loadForm(data) {
        setSelectedRow(data);
        setStatusFormOpen(true);

    }

    return (
        <>

            <Grid container style={{ paddingBottom: "15px" }} >

                <Grid container justifyContent="space-evenly">
                    {statuses.map((status, index) => (
                        <StatusGridItem
                            key={index}
                            title={status.title}
                            value={status.value}
                            background={status.background}
                            onClick={() => handleClick(status.id)}
                            selected={selected === status.id}

                        />
                    ))}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <CommonGrid
                        coldefs={{
                            filter: true,
                            filterParams: {
                                apply: true,
                                newRowsAction: "keep",
                                enabled: { filter: 1 },
                            },
                        }}
                        ButtonComponent={ButtonComponent}
                        columns={columns}
                        rows={rows}
                        gridheight={100}
                        onFilterChanged={handleFilterApplied}

                    />
                </Grid>
            </Grid>
            <DGDrawer
                needSave
                drawerWidth={800}
                isOpen={formOpen}
                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData();
                }}
                Component={FormToolNewForm}
                componentProps={{ evals: evaluation, plants, customers: customers }}
                title="New Tool "
                subtitle={new Date().toLocaleDateString()}
            />

            <DGDrawer
                isOpen={statusFormOpen}
                setOpen={() => {
                    setStatusFormOpen(false)
                    reloadGridData();

                }}
                Component={FormToolStatus}
                componentProps={selectedRow}
                drawerWidth={800}
                title={selectedRow?.tool_name + " - " + selectedRow?.tool_number}
                subtitle={selectedRow?.tool_status_id === 101 ? "Active"
                    : selectedRow?.tool_status_id === 109 ? "Under Refurbishing"
                        : selectedRow?.tool_status_id === 103 ? "Scrapped"
                            : null}
                needSave

            />

        </>
    );
};



const useStyles = makeStyles({
    common: {
        color: "#fff",
        borderRadius: 15,
        cursor: "pointer",
        // width: "100%",
    },
    header: {
        color: "#000",
        padding: 15,
        paddingBottom: 5,
    },
    count: {
        color: "#000",
        padding: 15,
        paddingTop: 0,
    },
    selected: {
        //border: "2px solid #000",
        backgroundColor: "#fff !important",
    },

});