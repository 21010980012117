import { Grid, IconButton, Typography, AppBar as AppBarTemp } from "@material-ui/core";
import React, { useContext } from "react";
import styled, { withTheme } from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider"
import { useState } from "react";
import { GembaGaugeIssue } from "./GaugeIssues/gauge_issue_gemba";
import { GembaProductInspect } from "./productInspection/productInspection";
import { GembaTooLifeRecord } from "./productionRecord/productionRecord"
import { GembaCard } from "./GembaCard";
//import digitalgembalogo from "../assets/images/digitalGembaLogo.png"
import digitalgembalogo from "../../assets/images/digitalGembaLogo.png"
import SpcGembaEntry from "./Spc/SpcGemba"
import { GembaMachineMaintenance } from "./machineManitenance/machineMaintenance";
import GembaUnderInspectionListing from "./productInspection/productionInspectionList";
import { SideBarProfile } from "../../layouts/Sidebar/Profile";


export default function Gemba() {
  let history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [selectedcard, setSelectedCard] = useState(-1);
  const { globalPlant } = useContext(PlantContext);


  /*   const image1 = currentUser.logo ? currentUser.logo : '';
    const text1 = currentUser.business_name ? currentUser.business_name : '';
    const text2 = globalPlant.globalPlant.name ? globalPlant.globalPlant.name : '';
    const text3 = currentUser.name ? currentUser.name : ''; */



  let cards = [
    //{ name: "MSA", color: "#7F0093", onClick: () => setSelectedCard(1) },
    /*    {
         name: "Gauge Issues",
         color: "#ECE1BF",
         index: 2,
       }, */
    {
      name: "SPC",
      color: "#414141",
      index: 3,
      textColor: "white"
    },
    //{ name: "PDI", color: "#B70040", onClick: () => setSelectedCard(4) },
    /*     {
          name: "Product Inspection",
          color: "#BFD6EC",
          index: 4,
          textColor: "black"
        }, */

    {
      name: "Product Inspection",
      color: "#BFD6EC",
      index: 7,
      textColor: "black"
    },

    {
      name: "Production Record",
      color: "#FFC300",
      index: 5,
      textColor: "black"
    },
    {
      name: "Machine Maintenance",
      color: "#C692F7",
      index: 6,
      textColor: "white"
    },
    //{name: "Special Process",color: "#007B6B",onClick: () => setSelectedCard(6)},
  ];

  const AppBar = styled(AppBarTemp)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

  //console.log(selectedcard, "selectedcard")

  return (
    <>

      <Grid container md={12} direction="row"
        style={{ padding: "16px" }}>

        <Grid item md={2} sm={6} lg={2} style={{ textAlign: "left" }}>
          <img
            src={currentUser?.logo || ""}
            alt="User Logo"
            style={{ height: 60, display: "inline-block" }}
          />

        </Grid>

        <Grid item md={2} sm={6} lg={2} direction="row"
          style={{ textAlign: "center", margin: 'auto' }}>

          <SideBarProfile
            phoneNumber={currentUser?.phone_number || ""}
            name={currentUser?.name || ""}
          />
        </Grid>

        <Grid item md={8} sm={6} lg={6} direction="row"
          style={{ textAlign: "center", margin: 'auto' }}>
          <Typography style={{ color: "#616161" }} align="center" variant="h3">
            {currentUser?.business_name}
          </Typography>
        </Grid>

        <Grid item md={2} sm={6} lg={2} style={{ textAlign: "right" }}>
          <img
            src={digitalgembalogo}
            style={{ height: 40, display: "inline-block" }}
          />
        </Grid>

      </Grid >



      {selectedcard === -1 ? (
        <Grid
          container
          spacing={6}
          style={{ display: "flex" }}
          alignItems="center"
        >
          {cards.map((exp, i) => (
            <GembaCard
              key={i}
              name={exp?.name}
              color={exp.color}
              textColor={exp.textColor}
              onClick={() => setSelectedCard(exp?.index)}
            />
          ))}
        </Grid>
      ) : null
      }

      <Grid container item style={{ paddingTop: 20 }}>
        {selectedcard === 3 ? (
          <SpcGembaEntry
            setSelectedCard={setSelectedCard} />
        ) : null}
        {selectedcard === 2 ? (
          <GembaGaugeIssue
            setSelectedCard={setSelectedCard} />
        ) : null}

        {selectedcard === 4 ? (
          <GembaProductInspect
            setSelectedCard={setSelectedCard}
          />
        ) : null}

        {selectedcard === 5 ? (
          <GembaTooLifeRecord
            setSelectedCard={setSelectedCard}
          />
        ) : null}
        {selectedcard === 6 ? (
          <GembaMachineMaintenance
            setSelectedCard={setSelectedCard}
          />
        ) : null}

        {selectedcard === 7 ? (
          <GembaUnderInspectionListing
            setSelectedCard={setSelectedCard}
          />
        ) : null}

      </Grid>

    </>
  );
}
