import { Box, Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { array, object, string } from "yup";
import { DeleteRenderer } from "../../components/cellRenderers/DeleteRenderer";
import { ResultRenderer } from "../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../components/CommonGrid";
import { DGCheckbox } from "../../components/shared/DGCheckbox";
import { DGInput } from "../../components/shared/DGInput";
import { DGSelect } from "../../components/shared/DGSelect";
import { AlertContext } from "../../context/AlertContextProvider";
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider";
import productService from "../../services/productService";
import errorLog from "../../services/errorLog";
import plantService from "../../services/plantService";

export function ProductNewForm({
  componentProps = null,
  Footer = () => null,
  closeForm = () => { },
}) {
  const [gridApiContext, setGridApi] = useState(null);
  const { updateAlert } = useContext(AlertContext);
  const [rawFlag, setRawFlag] = useState(false);
  //const custFlagRef = useRef(custFlag);
  const { v4: uuidv4 } = require("uuid");
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext)







  const columns = [
    {
      cellRenderer: DeleteRenderer,
      minWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
    },
    {
      field: "part_number",
      headerName: "Part Number",
      minWidth: 150,
      resizable: true,
    },
    {
      field: "part_name",
      headerName: "Part Name",
      minWidth: 150,
      resizable: true,
    },
    {
      field: "change_number",
      headerName: "Change_Number",
      minWidth: 150,
      resizable: true,
    },
    {
      field: "customer_delivery",
      headerName: "Customer Delivery",
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.customer_delivery ? 1 : 0} />
      ),
      minWidth: 150,
      resizable: true,
    },
    {
      field: "special_process",
      headerName: "Special Process",
      minWidth: 150,
      resizable: true,
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.special_process ? 1 : 0} />
      ),
    },
    {
      field: "raw_material",
      headerName: "Raw Material",
      minWidth: 150,
      resizable: true,
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.raw_material ? 1 : 0} />
      ),
    },
  ];


  const detailForm = useFormik({
    initialValues: {
      plant: null,
      plantOptions: [],
      customer: null,
      customerOptions: [],
      evaluation_guideline: null,
      evalOptions: [],
      part_name: "",
      part_number: "",
      change_number: "",
      customer_delivery: null,
      special_process: null,
      raw_material: false,
      plant_flag: null
    },
    validationSchema: object().shape({
      plant: object().required(),
      part_name: string().required(),
      part_number: string().required(),

      customer: object()
        .nullable()
        .when('raw_material', {
          is: false,
          then: object().required(),
          otherwise: object().nullable(),
        }),


      evaluation_guideline: object()
        .nullable()
        .when('raw_material', {
          is: false,
          then: object().required(),
          otherwise: object().nullable(),
          debug: true,
        }),



    }),

    onSubmit: addForm,
  });


  let form = useFormik({
    initialValues: {
      gridData: [],
      payLoad: []
    },
    // validationSchema: object().shape({
    //   gridData: array().min(1, 'At least one item is required in the grid data.'),
    // }),
    onSubmit: SaveForm,
  });



  useEffect(() => {
    plantService.getDefaultPlant(currentUser?.id)
      .then((res) => {
        const plantOptions = (res?.data ?? []).slice(1);
        detailForm.setFieldValue("plantOptions", plantOptions);
      })
  }, []);

  useEffect(() => {
    if (detailForm.values.plantOptions.length < 2 || currentUser.user_role > 3) {
      detailForm.setFieldValue("plant", (globalPlant?.globalPlant));
      detailForm.setFieldValue("plant_flag", 0)

    } else {
      if (currentUser.default_plant) {
        const defaultPlant = detailForm.values.plantOptions.find(plant => plant.default_flag === 1)
        detailForm.setFieldValue("plant", defaultPlant)
        detailForm.setFieldValue("plant_flag", 1)

      } else {
        const defaultPlant = form.values.plantOptions[0]
        detailForm.setFieldValue("plant", defaultPlant)
        detailForm.setFieldValue("plant_flag", 2)
      }
    }
  }, [detailForm.values.plantOptions]);

  useEffect(() => {
    detailForm.setValues({
      plantOptions: componentProps?.plants ?? [],
      customerOptions: componentProps?.customers ?? [],
      evalOptions: componentProps?.evals ?? [],
      plant: null,
      customer: null,
      evaluation_guideline: null,
      gridData: [],
    });
  }, []);


  // console.log(form.values.gridData)

  function addForm() {
    form.setFieldValue("gridData", [
      ...form.values.gridData,
      detailForm.values,
    ]);
    detailForm.setFieldValue('part_number', '');
    detailForm.setFieldValue('part_name', '');
    detailForm.setFieldValue('change_number', '');

  }



  function onGridReady(params) {
    setGridApi(params?.api);
  }

  function SaveForm() {
    const payload = [];
    gridApiContext.forEachNode((node) => {
      let data = node?.data;
      payload.push({
        id: uuidv4(),
        part_name: data.part_name,
        part_number: data.part_number,
        plant_id: data?.plant?.id,
        customer_id: data.customer?.id,
        evaluation_guidelines: data.evaluation_guideline?.id,
        status_id: 1,
        client_id: currentUser?.client_id,
        enabled: 1,
        raw_material: data.raw_material ? 1 : 0,
        special_process: data.special_process ? 1 : 0,
        customer_delivery: data.customer_delivery ? 1 : 0,
        change_number: data.change_number,

      })
    })


    if (payload.length > 0 && form.isValid) {
      productService.createProduct(payload)
        .then((res) => {
          updateAlert({
            open: true,
            message: `Products Added Successfully.`,
            type: "success",
          });
          closeForm();
        })
        .catch((err) => {
          updateAlert({
            open: true,
            message: `Error in adding part.`,
            type: "failure",
          });
          let payload = {
            id: uuidv4(),
            error_description: err.message,
            error_location: "createProduct",
            client_id: currentUser?.client_id,
            user_id: currentUser?.id,
            entity_id: null,
          };
          errorLog.createErrorLog(payload)
        });
    }
  }

  function saveForm() {
    const payload = [];
    form.values.gridData?.forEach((data) => {
      payload.push({
        //        ...data,
        id: uuidv4(),
        part_name: form.values.part_name,
        part_number: form.values.part_number,
        plant_id: form.values?.plant?.id,
        customer_id: form.values.values.customer?.id,
        evaluation_guidelines: form.values.values.evaluation_guideline?.id,
        status_id: 1,
        client_id: currentUser?.client_id,
        enabled: 1,
        raw_material: form.values.raw_material ? 1 : 0,
        special_process: form.values.special_process ? 1 : 0,
        customer_delivery: form.values.customer_delivery ? 1 : 0,
        change_number: form.values.change_number,

      });
    });
    //console.log(payload)
    if (payload.length > 0 && form.isValid) {
      productService.createProduct(payload)
        .then((res) => {
          updateAlert({
            open: true,
            message: `Products Added Successfully.`,
            type: "success",
          });
          closeForm();
        })
        .catch((err) => {
          //console.error("An error occurred:", err);
          let payload = {
            id: uuidv4(),
            error_description: err.message,
            error_location: "createProduct",
            client_id: currentUser?.client_id,
            user_id: currentUser?.id,
            entity_id: null,
          };
          errorLog.createErrorLog(payload)
        });
    }
  }


  function removeDetailRow(index, rowData) {
    let data = detailForm.values.gridData;
    data.splice(index, 1);
    gridApiContext.api.updateRowData({ remove: [rowData] });
    detailForm.setFieldValue("gridData", data);
  }




  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          id="plant"
          label="Plant"
          value={detailForm.values.plant}
          options={detailForm.values.plantOptions}
          onChange={(val) => detailForm.setFieldValue("plant", val)}
          error={detailForm.touched.plant && Boolean(detailForm.errors.plant)}
          disabled={form.values.gridData.length > 0 || detailForm.values.plant_flag === 0}
          required
        />
      </Grid>
      <Grid item md={6} sm={6} lg={6}>
        <DGSelect
          label="Customer"
          value={detailForm.values.customer}
          options={detailForm.values.customerOptions}
          getOptionLabel={(option) => option?.company_name ?? ""}
          onChange={(val) => detailForm.setFieldValue("customer", val)}
          error={detailForm.touched.customer && Boolean(detailForm.errors.customer)}
          disabled={form.values.gridData.length > 0 || detailForm.values.raw_material}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          label="Evaluation Guideline"
          getOptionLabel={(option) => option?.template_name ?? ""}
          options={detailForm.values.evalOptions}
          value={detailForm.values.evaluation_guideline}
          disabled={form.values.gridData.length > 0 || detailForm.values.raw_material}
          onChange={(val) => detailForm.setFieldValue("evaluation_guideline", val)}
          error={
            detailForm.touched.evaluation_guideline &&
            Boolean(detailForm.errors.evaluation_guideline)
          }
          required
        />
      </Grid>
      <Grid container item md={12} sm={12} lg={12} spacing={2}>
        <Grid item md={12} lg={12} sm={12}>
          <Box fontWeight="fontWeightBold" m={1}>
            Part Details
          </Box>
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            id="part_name"
            label="Part name"
            isFormik
            required
            value={detailForm.values?.part_name}
            onChange={detailForm.handleChange}
          /*         error={
                    detailForm.touched.part_name &&
                    Boolean(detailForm.errors.part_name)
                  } */
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            id="part_number"
            label="Part Number"
            isFormik
            required
            value={detailForm.values?.part_number}
            onChange={detailForm.handleChange}
          /*            error={
                       detailForm.touched.part_number &&
                       Boolean(detailForm.errors.part_number)
                     } */
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            id="change_number"
            label="Change Number"
            isFormik
            value={detailForm.values?.change_number}
            onChange={detailForm.handleChange}
            error={
              detailForm.touched.change_number &&
              Boolean(detailForm.errors.change_number)
            }
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGCheckbox
            id="customer_delivery"
            label="Customer Delivery"
            value={detailForm.values.customer_delivery}
            onChange={(checked) =>
              detailForm.setFieldValue("customer_delivery", checked)
            }

            isFormik
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGCheckbox
            id="special_process"
            label="Special Process"
            value={detailForm.values.special_process}
            onChange={(checked) =>
              detailForm.setFieldValue("special_process", checked)
            }
          />
        </Grid>
        <Grid item md={4} sm={4} lg={4}>
          <DGCheckbox
            id="raw_material"
            label="Raw Material"
            value={detailForm.values.raw_material}
            disabled={form.values.gridData.length > 0 || detailForm.values.customer !== null
              || detailForm.values.evaluation_guideline !== null}
            onChange={(checked) => {
              detailForm.setFieldValue("raw_material", checked);
              setRawFlag(checked);
            }}
          />
        </Grid>
        <Grid item md={12} lg={12} sm={12}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disabled={!detailForm.isValid}
            onClick={detailForm.handleSubmit}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          rows={form.values.gridData}
          columns={columns}
          needExport={false}
          onGridReady={onGridReady}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
