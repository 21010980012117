import Card from "@material-ui/core/Card";
import {
  Button,
  TextareaAutosize as MuiTextareaAutosize,
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import gaugeService from "../../../../services/gaugeService";
import CommonGrid from "../../../../components/CommonGrid";
import { useParams } from "react-router-dom";
import { DGDrawer } from "../../../../components/DGDrawer";
import { CalibrationForm } from "../../calibrationHistory/calibrationForm";
import piService from "../../../../services/piService";

const PIList = ({ piHistoryList, gaugeItem, onSuccess, openDialog }) => {
  const [rows, setRows] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [selecteddata, setSelectedData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    gaugeService.getCalibGauge(id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);

  function loadFormData(data) {
    piService
      .getPIrequestforID(data?.id ?? "")
      .then((res) => {
        setSelectedData(res?.data);
        return Promise.resolve();
      })
      .then(() => {
        setFormOpen(!formOpen);
      });
  }

  const columns = [
    {
      field: "serial",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      minWidth: 100,
      sorting: true,
    },
    {
      field: "report_name",
      headerName: "Report Number",
      minWidth: 200,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              loadFormData(params?.data);
            }}
          >
            {params.value}
          </Button>
        );
      },
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    /*
    {
      field: "pi_due_date",
      headerName: "Previous due date",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        params?.data?.pi_due_date
          ? new Date(params?.data?.pi_due_date).toLocaleDateString()
          : "",
      floatingFilter: true,
      sorting: true,
    },
    */
    {
      field: "calibrated_date",
      headerName: "Calibrated Date",
      minWidth: 200,
      resizable: true,
      filterParams: filterParams,
      valueGetter: (params) =>
        params?.data?.calibrated_date
          ? new Date(params?.data?.calibrated_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "approved_date",
      headerName: "Approved Date",
      minWidth: 200,
      resizable: true,
      filterParams: filterParams,
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        params?.data?.approved_date
          ? new Date(params?.data?.approved_date).toLocaleDateString()
          : "",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "business_name",
      headerName: "Business Name",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
  ];

  return (
    <React.Fragment>
      <CommonGrid columns={columns} rows={rows} />
      <DGDrawer
        componentProps={{ apiData: selecteddata }}
        Component={CalibrationForm}
        isOpen={formOpen}
        drawerWidth={500}
        setOpen={setFormOpen}
        title={selecteddata?.report_name ?? ""}
        subtitle={selecteddata?.serial_number ?? ""}
        needSave
      />
    </React.Fragment>
  );
};

export default PIList;

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
