import { blueGrey } from '@material-ui/core/colors';
import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../../components/CommonGrid'
import { AuthContext } from '../../../context/AuthContextProvider';
import gaugeService from '../../../services/gaugeService';
import productService from '../../../services/productService';
import { rgba } from "polished";
import { makeStyles, Button } from "@material-ui/core";
import { DGDrawer } from "../../../components/DGDrawer"
import { GaugeProductList } from "./gaugeProductList"
import AddIcon from "@material-ui/icons/Add";
import { GaugeProductLink } from "./gaugeProductLink"
import { PlantContext } from '../../../context/PlantContextProvider';
import StatusFilter from '../../../components/shared/StatusFilter';
import ActiveInActive from '../../../components/ActiveInActive';
import { PageTitleContext } from '../../../context/PageTitleContextProvider';

export function GaugeProduct() {
  //style Definition
  const classes = useStyles();
  const { globalPlant } = useContext(PlantContext);
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [formOpen, setFormOpen] = useState(false);
  const [formLinkOpen, setFormLinkOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [subtitle, setSubtitle] = useState("");
  const { setHeaderPageTitle } = useContext(PageTitleContext);



  const globalPlantId = globalPlant?.globalPlant?.id

  useEffect(() => {
    productService.getAssetPartPlantList(
      globalPlantId,
      currentUser?.client_id)
      .then(res => {
        setRows(res?.data ?? []);
      })
  }, [globalPlantId])

  useEffect(() => {
    setHeaderPageTitle(`Gauge Product`)
  }, []);

  /*   useEffect(() => {
      gaugeService.getGaugeChar(currentUser?.client_id).then(res => {
        setRows(res?.data ?? []);
      })
    }, []) */

  const columns = [{
    field: "serial_number",
    headerName: "#",
    valueGetter: "node.rowIndex + 1",
    maxWidth: 100,
    sorting: true,
  },
  {
    field: "part_name",
    headerName: "Part Name",
    minWidth: 200,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    sorting: true,
    cellRenderer: (params) => {
      return (
        <Button
          size="small"
          color="primary"
          onClick={() => loadForm(params?.data)}
        >
          {`${params?.data?.part_name}`}
        </Button>
      );
    },
  },
  {
    field: "plant_name",
    headerName: "Plant Name",
    minWidth: 200,
    resizable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    sorting: true,
  },

  {
    field: "enabled",
    headerName: "Status",
    minWidth: 150,
    filter: "agNumberColumnFilter",
    floatingFilter: true,
    suppressMenu: true,
    floatingFilterComponent: StatusFilter,
    floatingFilterComponentParams: {
      suppressFilterButton: true,
    },
    cellRenderer: (params) => (
      <ActiveInActive
        status={params?.data?.enabled}
      />
    ),
  },
  ]


  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            LoadLinkForm();
          }}
          style={{ marginLeft: 3 }}
        >
          ADD
        </Button>
      </>
    );
  }

  function LoadLinkForm() {
    setFormLinkOpen(true);
  }

  const getRowStyle = (params) => {
    //console.log(params)
    const rowData = params.data;
    const customerFlag = rowData.customer_delivery;

    return customerFlag === 1
      ? { background: rgba(212, 230, 241, 0.1) }
      : {};
  };


  function loadForm(data) {
    setSubtitle(data?.request_name ?? "");
    setSelectedRow(data);
    setFormOpen(true);
  }

  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={90}
        ButtonComponent={ButtonComponent}
        getRowStyle={getRowStyle}
        suppressRowClickSelection={true}
        suppressCellSelection={true}
      />

      <DGDrawer
        Component={GaugeProductList}
        title="Product Gauge List"
        isOpen={formOpen}
        setOpen={(state) => {
          setSubtitle("");
          setFormOpen(state);
        }}
        subtitle={subtitle}
        drawerWidth={1000}
        componentProps={selectedRow}
      />

      <DGDrawer
        Component={GaugeProductLink}
        title="Link Gauge to Product"
        isOpen={formLinkOpen}
        setOpen={(state) => {
          //setSubtitle("");
          setFormLinkOpen(state);
        }}
        subtitle={subtitle}
        drawerWidth={600}
        needSave
      />


    </>
  );
}

const useStyles = makeStyles({
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});
