import http from "./httpService";
//  "/machine-capability-study"
//  "/process-capability-study"

const getBiasStudy = async (id) => {
  return await http.get(`/bias-study/${id}`);
};

const deleteBiasResult = async (id) => {
  return await http.delete(`/bias-study/${id}`);
};

const getGrrStudy = async (id) => {
  return await http.get(`/grr-study/${id}`);
};

const getCgkStudy = async (id) => {
  return await http.get(`/guage-capability-study/${id}`);
};

const getKappaStudy = async (id) => {
  return await http.get(`/kappa-study/${id}`);
};

const getSignalStudy = async (id) => {
  return await http.get(`/signal-study/${id}`);
};

const getStudyResultByGaugeIdCharacterId = async (gaugeId, characterId) => {
  const biasStudyResponse = await http.get("/bias-study", {
    params: { guage_id: gaugeId, character_id: characterId },
  });
  const grrStudyResponse = await http.get("/grr-study", {
    params: { guage_id: gaugeId, character_id: characterId },
  });
  const cgkStudyResponse = await http.get("/guage-capability-study", {
    params: { guage_id: gaugeId, character_id: characterId },
  });
  const kappaStudyResponse = await http.get("/kappa-study", {
    params: { guage_id: gaugeId, character_id: characterId },
  });
  const signalStudyResponse = await http.get("/signal-study", {
    params: { guage_id: gaugeId, character_id: characterId },
  });

  return {
    grrResult: grrStudyResponse.data.data,
    biasResult: biasStudyResponse.data.data,
    cgkResult: cgkStudyResponse.data.data,
    signalResult: signalStudyResponse.data.data,
    kappaResult: kappaStudyResponse.data.data,
  };
};

const getMSAtablebyChar = async (characterId) => {

  const grrStudyResponse = await http.get("/grr-study-table", {
    params: {character_id: characterId},});

  const kappaStudyResponse = await http.get("/kappa-study-table", {
    params: {character_id: characterId},});

  const biasStudyResponse = await http.get("/bias-study-table", {
    params: {character_id: characterId},});

  const gcapabilityStudyResponse = await http.get("/guage-capability-table", {
      params: {character_id: characterId},});

  const signalStudyResponse = await http.get("/signal-study-table", {
        params: {character_id: characterId},});

  return {
    grrResult: grrStudyResponse.data.data,
    kappaResult: kappaStudyResponse.data.data,
    biasResult : biasStudyResponse.data.data,
    signalResult:signalStudyResponse.data.data,
    cgkResult:gcapabilityStudyResponse.data.data,
  };

}
const getMSAtablebyClient = async (clientId) => {
  const grrStudyResponse = await http.get("/grr-study-table", {
    params: {client_id: clientId},});

  const kappaStudyResponse = await http.get("/kappa-study-table", {
    params: {client_id: clientId},});

  const biasStudyResponse = await http.get("/bias-study-table", {
    params: {client_id: clientId},});

  const gcapabilityStudyResponse = await http.get("/guage-capability-table", {
      params: {client_id: clientId},});

  const signalStudyResponse = await http.get("/signal-study-table", {
        params: {client_id: clientId},});

  return {
    grrResult: grrStudyResponse.data.data,
    kappaResult: kappaStudyResponse.data.data,
    biasResult : biasStudyResponse.data.data,
    signalResult:signalStudyResponse.data.data,
    cgkResult:gcapabilityStudyResponse.data.data,
  };
};
const getMSAtablebyPart = async (partId) => {
  const grrStudyResponse = await http.get("/grr-study-table", {
    params: {part_id: partId},});

  const kappaStudyResponse = await http.get("/kappa-study-table", {
    params: {part_id: partId},});

  const biasStudyResponse = await http.get("/bias-study-table", {
    params: {part_id: partId},});

  const gcapabilityStudyResponse = await http.get("/guage-capability-table", {
      params: {part_id: partId},});

  const signalStudyResponse = await http.get("/signal-study-table", {
        params: {part_id: partId},});

  return {
    grrResult: grrStudyResponse.data.data,
    kappaResult: kappaStudyResponse.data.data,
    biasResult : biasStudyResponse.data.data,
    signalResult:signalStudyResponse.data.data,
    cgkResult:gcapabilityStudyResponse.data.data,
  };
};


const getMSAChar = async (character_id) =>{
  return http.get("/msa-table",{
    params:{
      character_id:character_id
    }
  })
}

const getMSAPart = async (part_id) =>{
  return http.get("/msa-table",{
    params:{
      part_id:part_id
    }
  })
}

const getMSAClient = async (client_id) =>{
  return http.get("/msa-table",{
    params:{
      client_id:client_id
    }
  })
}

const getMSAGauge = async (client_id) =>{
  return http.get("/guage-msa-table",{
    params:{
      client_id:client_id
    }
  })
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBiasStudy,
  getGrrStudy,
  getCgkStudy,
  getKappaStudy,
  getSignalStudy,
  getStudyResultByGaugeIdCharacterId,
  deleteBiasResult,
  getMSAtablebyChar,
  getMSAtablebyClient,
  getMSAtablebyPart,
  getMSAChar,
  getMSAPart,
  getMSAClient,
  getMSAGauge
  
};
