import http from "./httpService";

const createDrawing = (data) => {
  return http.post("/drawings", data);
};

const getDrawings = () => {
  return http.get("/drawings");
};

const getDrawingByProductId = (productId) => {
  return http.get("/drawings", {
    params: {
      part_id: `${productId}`,
    },
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createDrawing,
  getDrawings,
  getDrawingByProductId,
};
