import React, { useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import { GaugeIssues } from "../../gauge/gaugeIssues/gaugeissues";

export function GembaGaugeIssue({ setSelectedCard = () => { } }) {
    const [formOpen, setFormOpen] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [measureOpen, setMeasureOpen] = useState(false);

    return (
        <>
            {" "}
            <DGDrawer
                Component={GaugeIssues}
                isOpen={formOpen}
                title="Gauge Issue"
                setOpen={(state) => {
                    setFormOpen(state);
                    setSelectedCard(-1);
                }}
                drawerWidth={800}
                componentProps={{ gemba_flag: 1 }}

            />

        </>
    );
}
