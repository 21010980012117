import React, { useEffect } from "react";
import { useState } from "react";
import { chunk } from "lodash";
import CommonGrid from "../../../../../components/CommonGrid";

export function DataInfo({ data_info = [] }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  function onGridReady(params) {
    var eGridDiv = document.querySelector("#grr2");
    eGridDiv.style.width = "100%";
    eGridDiv.style.height = "400px";
    params?.api.setDomLayout();
  }
  useEffect(() => {
    let { columns, rows } = generateGrid(data_info);
    setColumns(columns);
    setRows(rows);
  }, [data_info]);

  function generateGrid(data_info) {
    let rowData = [];
    let columnData = [];
    let rows = JSON?.parse(data_info[0]?.input_values ?? "[]") ?? [];
    let data = chunk(rows ?? [], 5);
    data.map((col, i) => {
      let rowObj = {};
      col?.map((rowValue, j) => {
        rowObj["Column-" + j] = rowValue;
      });
      rowData.push(rowObj);
    });

    data[0]?.map((col, i) => {
      columnData.push({
        field: `Column-${i}`,
        headerName: `Column-${i + 1}`,
        minWidth: 130,
        cellClass: "text-center",
      });
    });
    return { columns: columnData, rows: rowData };
  }

  return (
    <div>
      <CommonGrid
        onGridReady={onGridReady}
        needExport={false}
        rows={rows}
        columns={columns}
        id="grr2"
      />
    </div>
  );
}
