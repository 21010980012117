import { Box, Button, Typography } from "@material-ui/core";
//import React from "react";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { DataInfo } from "./DataInfo";
import { GaugeRep } from "./GaugeRepeatandRepro";
import { DrawingInfo } from "./DrawingInfo";
import { PartInfo } from "./PartInfo";
import { Evaluationfo } from "./EvaluationInfo";
import { ResultInfo } from "./ResultInfo";
import { GraphInfo } from "./GraphInfo";
import { ApprovaInfo } from "./approval_info";
import { indigo } from "@material-ui/core/colors";
import { AuthContext } from "../../../../../context/AuthContextProvider";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import reportService from "../../../../../services/reportService";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import approvalService from "../../../../../services/approvalService";
import { AlertContext } from "../../../../../context/AlertContextProvider";
import initiateStudyService from "../../../../../services/initiateStudyService";
import Loader from "../../../../../components/Loader";
import { StatusContext } from "../../../../../context/StatusContextProvider";


export function Grr1Report({
  study_id = "",
  disabledDownload = false,
} = {}) {
  const componentRef = useRef();

  let { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const [reportData, setReportData] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false);
  const [approveFlag, setApproveFlag] = useState(0)
  const { setStatus } = useContext(StatusContext);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(),
      callback: determineApproveFlag(),
    }));
    reloadGridData()
  }, []);



  function reloadGridData() {
    initiateStudyService.MSAReport(study_id, 3)
      .then((res) => {
        setReportData(res?.data);
        determineApproveFlag(res?.data)
        setDataLoaded(true)

      });
  }

  if (!dataLoaded) {
    return <Loader />;
  }


  function ApproveORReject(statusId) {
    let payload = {
      approval_id: reportData?.approval_info.approve_id,
      user_id: currentUser?.id,
      status_id: statusId,
    };
    approvalService.createApprovalDecision(payload)
      .then((res) => {
        let message;
        if (statusId === 2) {
          message = "Request Approved";
        } else { message = "Request Rejected" }
        updateAlert({
          open: true,
          type: "success",
          message: message
        })
      })
      .catch((err) => {
        updateAlert({
          open: true,
          type: "error",
          message: "Approval decision failed to update",
        });
      })
    reloadGridData()

  }


  function determineApproveFlag(rData) {
    if (
      !rData?.approval_info?.approved_by ||
      rData?.approval_info?.approved_by === ""
    ) {
      if (currentUser.id === rData?.approval_info?.approver_id) {
        var approve_flag = 1;
      } else if (currentUser.id === rData?.approval_info?.requested_by) {
        var approve_flag = 0;
      } else if (currentUser.user_role < 4) {
        var approve_flag = 1;
      }
    }
    setApproveFlag(approve_flag)
  }


  const handlePrint = function () {
    // reportData.graph_info = [{ graph_data: reportData?.graph_info }];
    reportService.generateGrr1Report(reportData).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([new Uint8Array(res.data).buffer])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "grr1Report.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };


  return (
    <>
      <div className="text-end w-100 mb-2">

        {approveFlag === 1 && (

          <Button
            //className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<CheckCircleOutlineIcon />}
            onClick={() => { ApproveORReject(2) }}
            style={{ marginRight: 9 }}
          >
            APPROVE
          </Button>
        )}

        {approveFlag === 1 && (
          <Button
            //className={classes.button}
            variant="outlined"
            color="secondary"
            startIcon={<CancelOutlinedIcon />}
            onClick={() => { ApproveORReject(3); }}
          >
            REJECT
          </Button>
        )}


        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudDownloadOutlinedIcon />}
          style={{ margin: 2, marginRight: 4 }}
          onClick={handlePrint}
          disabled={disabledDownload}
        >
          Download
        </Button>
      </div>
      <div
        className="container  text-center"
        style={{ backgroundColor: "#fff" }}
        ref={componentRef}
      >
        <div className="row align-items-center">
          <div className="col-3 justify-self-start">
            <Box>
              <img
                src={reportData?.company_info[0]?.logo}
                style={{ width: 200, height: 100, objectFit: "contain" }}
                className="p-1"
              />
            </Box>
          </div>
          <div className="col-6 align-self-center text-center">
            <Box>
              <Typography variant="h4" align="center">
                {reportData?.company_info[0]?.business_name}
              </Typography>
            </Box>
          </div>
          <div className="col-3 ">
            <Box>
              <Typography variant="h4" align="center">
                {reportData?.company_info[0]?.plant_name}
              </Typography>
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <GaugeRep
              study_info={reportData?.study_info}
              product_info={reportData?.product_info}
              gauge_info={reportData?.gauge_info}
              test_info={reportData?.test_info}
            />
          </div>
        </div>
        <div className="row mt-1 pl-0 pr-0">
          <div className="col-12 h-100">
            <DataInfo data_info={reportData?.data_info} />
          </div>
        </div>
        <div className="row mt-1 w-100">
          <GraphInfo graph_info={reportData?.graph_info ?? []} />
        </div>
        <div className="row mt-1">
          <div className="col-4">
            <DrawingInfo drawing_info={reportData?.drawing_info} />
          </div>
          <div className="col-4">
            <PartInfo part_info={reportData?.part_info} />
          </div>
          <div className="col-4">
            <Evaluationfo evaluation_info={reportData?.evaluation_info} />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-12">
            <ResultInfo result_info={reportData?.result_info} />
          </div>
        </div>
        <div
          className="row mt-2 align-items-center"
          style={{ backgroundColor: indigo[100] }}
        >
          <ApprovaInfo approval_info={reportData?.approval_info} />
        </div>
      </div>
    </>
  )
}