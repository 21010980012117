import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography, Divider, CardHeader, Box } from "@material-ui/core";
import { PieGraph } from "../components/graphs/PieGraph"
import { StackedBarGraph } from "../components/graphs/StackedBarGraphs"
import React, { useContext, useEffect, useState } from "react";
import { DGDrawer } from "./DGDrawer";
import { GaugeOverDueList } from "../pages/gauge/gaugeOverdueDetail";
import { GaugeCalibrationList } from "../pages/gauge/gaugeStatusDetails";

const colorFill = [
    "#0000FF",  // Blue
    "#800080",  // Purple
    "#FFA500",  // Orange
    "#FFC0CB",  // Pink
    "#E6E6FA",  // Lavender
    "#00FFFF",  // Cyan
    "#FF00FF",  // Magenta
    "#082567",  // Sapphire
    "#40E0D0",  // Turquoise
    "#EE82EE",  // Violet
    "#FFD700",  // Gold
    "#FF1493",  // Deep Pink
    "#9370DB",  // Medium Purple
    "#DA70D6",  // Orchid
    "#F0E68C"   // Khaki
];

const useStyledDivStyleCard2 = () => ({
    maxWidth: '98%',
    width: '100%',
    height: '60px',
    borderRadius: '10%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    boxSizing: 'border-box'

})


export function OverviewCard({ name = "", count = 0,
    backgroundColor = "#E4E6E7", cardHeight = 150 }) {
    return (
        <Card style={{ backgroundColor: backgroundColor, height: cardHeight }}>
            <CardContent>
                <Grid container>
                    <Grid item md={12} sm={12} lg={12}>
                        <Typography align="center" variant="h4"
                        >
                            {name}
                        </Typography>
                        <Typography align="center" variant="h1">
                            {count}
                        </Typography>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

// Characteristics Card
export function OverviewCard2({
    name = "",
    count = 0,
    totalcount = 0,
    okcount = 0,
    notokcount = 0,
    charactercount = 0,
    partcount = 0,
    mainHeading = "",
    subHeading = "",
    timePeriod = ""

}) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const closeDrawer = () => {
        setDrawerOpen(false);
    }



    return (
        <>
            <DGDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                setOpen={closeDrawer}
                drawerWidth={500}
                title={subHeading}
            />

            <div onClick={() => { setDrawerOpen(true) }} className="dg_dash_cc">

                <span className="dg_dash_cc_tagline">
                    {mainHeading}
                </span>
                <h6 className="dg_dash_cc_title">
                    {subHeading}
                </h6>
                <div className="dg_dash_entry1_count">
                    <div className="dg_dash_entry1_count_main">
                        <h3>{totalcount}</h3>
                        <span>Evaluations {(timePeriod)}</span>
                    </div>
                    <div className="dg_dash_entry1_count_sub">
                        <h4 className="dg_dash_cc_count_2">{okcount}</h4>
                        <h4 className="dg_dash_cc_count_3">{notokcount}</h4>
                    </div>
                </div>
                <p className="dg_dash_entry1_para">No of Parts: {partcount}</p>
                <p className="dg_dash_entry1_para">
                    No of Characteristics: {charactercount}
                </p>
            </div>
        </>
    );
}


// Asset Card
export function OverviewCard3({
    mainHeading = "",
    subHeading = "",
    metrics = "",
    drawerWidth = "",
    Component = ""
}) {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    // console.log(Component)
    const componentMap = {
        GaugeOverDueList: GaugeOverDueList,
        GaugeCalibrationList: GaugeCalibrationList
    };

    const templink = componentMap[Component]

    return (
        <>
            <DGDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                setOpen={closeDrawer}
                drawerWidth={drawerWidth}
                title={subHeading}
                Component={templink}
            />

            <div onClick={() => { setDrawerOpen(true) }} className="dg_dash_am">
                <span className="dg_dash_cc_tagline">
                    {mainHeading}
                </span>
                <h6 className="dg_dash_cc_title">
                    {subHeading}
                </h6>

                {/* // <h6 className="dg_dash_am_title">Gauge Caliberation</h6> */}
                <div className="dg_dash_am_gauge_count">
                    <div className="dg_dash_am_gauge_count_no">
                        {metrics[(Object.keys(metrics)[0])]}
                    </div>
                    <div className="dg_dash_am_gauge_count_title">
                        {Object.keys(metrics)[0]}
                    </div>
                </div>
                <div className="dg_dash_am_gauge_count">
                    <div className="dg_dash_am_gauge_count_no">
                        {metrics[(Object.keys(metrics)[1])]}
                    </div>
                    <div className="dg_dash_am_gauge_count_title">
                        {Object.keys(metrics)[1]}
                    </div>
                </div>
                <div className="dg_dash_am_gauge_count">
                    <div className="dg_dash_am_gauge_count_no">
                        {metrics[(Object.keys(metrics)[2])]}
                    </div>
                    <div className="dg_dash_am_gauge_count_title">
                        {Object.keys(metrics)[2]}
                    </div>
                </div>
            </div>
        </>
    );
}

// Gauges Overview / Gauge Calibration Status
export function OverviewCard4({ name = "", graphData = [],
    backgroundColor = "#ffe57f", cardHeight = 400 }) {

    const [ccmPieGraph, setccmPieGraph] = useState(null);



    let color1 = ['#CCCC66', '#CCCCFF', '#CC99FF', '#666633', '#3399CC']


    const series = [];
    series.push({
        type: "pie",
        sectorLabelKey: "status",
        sectorLabel: {
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        angleKey: "count_",
        strokeWidth: 0,
        fills: color1,
        innerRadiusRatio: 0.5,
        tooltip: {
            renderer: (params) => {
                return {
                    content: params?.datum?.count_,
                    title: params?.datum?.status
                };
            }
        },
        label: { enabled: true },
        /*         innerLabels: [
                    { text: `${totalcount}` },
                    { text: 'Evaluations' }
                ], */
    });


    return (
        <Card style={{
            backgroundColor: '#EAF2F8', height: cardHeight, width: '350px',
            minWidth: '0px%', border: '1px solid black',
        }}>
            <CardContent>

                <Typography
                    align="center"
                    variant="h4"
                    style={{
                        fontWeight: 'bold',
                        color: '#44339A',
                    }}
                >
                    {name}
                </Typography>

                <Grid>
                    <div style={{ height: '300px' }}>
                        <PieGraph options={ccmPieGraph} />
                    </div>
                </Grid>



            </CardContent>
        </Card >
    );
}

// Bar graph for Calibration history
export function OverviewCard5({ name = "", graphData = [],
    backgroundColor = "#ffe57f", cardHeight = 400 }) {


    const [gaugeStackGraph, setgaugeStackGraph] = useState({
        data: [],
        series: [],
        legend: {
            enabled: false
        },
        background: {
            fill: '#E9F4F9',
        }
    });


    useEffect(() => {
        let series = new Map();
        let temp = [];
        if (graphData !== null && graphData !== undefined) {
            graphData.map((exp) => {
                for (let key in exp?.key_) {
                    series.set(key, {
                        type: "column",
                        xKey: "Calib_date",
                        yKey: key,
                        yName: key,
                        fills: colorFill,
                        strokeWidth: 0,
                        stacked: true,
                        tooltip: {
                            renderer: (params) => {
                                return {
                                    content: params?.yValue,
                                    title: `${params?.xValue} - ${params?.yName}`
                                };
                            }
                        },
                    });
                }
                temp.push({ Calib_date: exp.Calib_date, ...exp.key_ });
            });
        }

        setgaugeStackGraph({
            data: temp,
            series: [...series.values()],
            legend: {
                enabled: true
            },
            background: {
                fill: '#E9F4F9',
            },
        });


    }, [graphData]);


    return (
        <Card style={{
            backgroundColor: '#EAF2F8', height: cardHeight, width: '700px',
            minWidth: '0px%', border: '1px solid black',
        }}>
            <CardContent>

                <Typography
                    align="center"
                    variant="h4"
                    style={{
                        'font-weight': 'bold',
                        color: '#44339A',
                    }}
                >
                    {name}
                </Typography>

                <Grid>
                    <div style={{ height: '300px' }}>
                        <StackedBarGraph options={gaugeStackGraph} />
                    </div>
                </Grid>



            </CardContent>
        </Card >
    );
}

// Multiple box
export function OverviewCard6({ name = "", countData = [],
    backgroundColor = "#ffe57f", cardHeight = 400 }) {

    const countDataArray = countData.map((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        return { key, value };
    });

    const styledDivStyle = useStyledDivStyleCard2();

    return (
        <Card style={{
            backgroundColor: '#EAF2F8', height: cardHeight, width: '350px',
            minWidth: '0px%', border: '1px solid black',
        }}>
            <CardContent>

                <Typography
                    align="center"
                    variant="h4"
                    style={{
                        fontWeight: 'bold',
                        color: '#44339A',
                    }}
                >
                    {name}
                </Typography>

                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"

                >

                    <Grid>
                        {countDataArray.map(({ key, value }) => (
                            <Grid item md={12} sm={12} lg={12} key={key}>
                                <Box paddingTop={3}>
                                    <div style={{
                                        display: 'flex', // Use display: flex
                                        flexDirection: 'column', // Stack elements vertically
                                        alignItems: 'center', // Align items to the center
                                    }}>
                                        <Typography
                                            align="center"
                                            variant="h4"
                                            style={{
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color: '#44339A',
                                                paddingBottom: '3px',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            {key}
                                        </Typography>
                                        <div style={{
                                            ...styledDivStyle,
                                            border: '3px solid brown',
                                            width: '60%',
                                        }}>
                                            {value}
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>



                </Grid>



            </CardContent>
        </Card >
    );
}