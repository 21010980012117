/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../../components/CommonGrid";
import AddIcon from "@material-ui/icons/Add";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import spcService from "../../../../services/spcService";
import { useParams } from "react-router-dom";
import moment from "moment";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import ActiveInActive from "../../../../components/ActiveInActive";
import { ApprovalStatus } from "../../../../components/approvalStatus";
import machineService from "../../../../services/machineService";
import userService from "../../../../services/userService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import productService from "../../../../services/productService";
import { rgba } from "polished";
import { blueGrey } from "@material-ui/core/colors";
import { DGDrawer } from "../../../../components/DGDrawer";
import { ControlLimitFormNew } from "./ControlLimitFormNew";
import StatusFilter from "../../../../components/shared/StatusFilter";
import ControlLimitView from "./ControlLimitView"


function CharControlLimitListing() {
  //component state
  const [evals, setEvals] = useState([]);
  const [studyDetails, setStudyDetails] = useState(null)
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [formOpen, setFormOpen] = useState(false);
  const [formViewOpen, setFormViewOpen] = useState(false);
  const { id } = useParams();


  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: loadAllEvals,
      callback: updateEvalStatus,
    }));
  }, []);


  function loadAllEvals() {
    return spcService.getSPCEvaltable(id).then((res) => {
      setEvals(res?.data);
    });
  }

  function updateEvalStatus(dummy1, dummy2, newStatus, rowId) {
    return spcService.updateSPCEval(rowId, { enabled: newStatus });
  }

  function GetFormattedValue(value) {
    if (value) {
      const specs = value.split(" ");
      return (
        <>
          {parseFloat(specs[0]) || parseFloat(specs[0]) === 0 ? (
            <span className={classes.spec}>
              {" "}
              {parseFloat(specs[0]).toFixed(4)}
            </span>
          ) : null}
          {parseFloat(specs[1]) || parseFloat(specs[1]) === 0 ? (
            <span className={classes.spec}>
              {" "}
              {parseFloat(specs[1]).toFixed(4)}
            </span>
          ) : null}
          {parseFloat(specs[2]) || parseFloat(specs[1]) === 0 ? (
            <span className={classes.spec}>
              {" "}
              {parseFloat(specs[2]).toFixed(4)}
            </span>
          ) : null}
        </>
      );
    } else {
      return <></>;
    }
  }

  function onGridReady(params) {
    return loadAllEvals();
  }



  //Column Definition
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      pinned: "left",
      sortable: true,
      resizable: true,
    },
    {
      field: "report_name",
      headerName: "Evaluation",
      minWidth: 150,
      resizable: true,
      pinned: "left",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setStudyDetails(params.data);
              //loadEvalpopup(params)
              setFormViewOpen(true)

            }}
          >
            {params.value}
          </Button>
        );
      },
    },

    {
      headerName: "Specification",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <>
            {params?.data?.characteristics} {"  "}
            {params?.data?.specification ? params.data.specification : ""}{"  "}
            {params?.data?.upper_specification ? params.data.upper_specification : ""}{"  "}
            {params?.data?.lower_specification ? params.data.lower_specification : ""}
          </>
        )

      },

    },


    {
      field: "machine_spindle",
      headerName: "Machine-Spindle",
      minWidth: 180,
      resizable: true,
      valueGetter: (params) => `${params?.data["machine_spindle"] ?? ""}`,
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      field: "Location_Chart",
      headerName: "Location chart",
      minWidth: 300,
      resizable: true,
      cellRenderer: (params) =>
        GetFormattedValue(`${params?.data["Location_Chart"]}`),
    },
    {
      field: "Distribution_Chart",
      headerName: "Dispersion Chart",
      minWidth: 300,
      resizable: true,
      cellRenderer: (params) =>
        GetFormattedValue(`${params?.data["Distribution_Chart"] ?? ""}`),
    },
    {
      field: "evaluation_date",
      headerName: "Evaluation Date",
      minWidth: 200,
      resizable: true,
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      valueGetter: (params) => {
        return moment(params?.data?.evaluation_date).format("DD/MM/YYYY");
      },
    },
    {
      field: "evaluation_method",
      headerName: "Evaluation Method",
      minWidth: 200,
      resizable: true,
      valueGetter: (params) => `${params?.data["evaluation_method"] ?? ""}`,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
    },
    {
      field: "approval_status",
      headerName: "Approval Status",
      minWidth: 200,
      resizable: true,
      // valueGetter:(params)=>`${params?.data?.approver_id[0]?.approval_status?.id??"--||--"}`,
      cellRenderer: (params) => (
        <ApprovalStatus status={params?.data["approval_status"]} />
      ),
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.report_name,
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.report_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];


  const classes = useStyles();



  return (
    <>
      <Grid
        item
        className={classes.addButtonLayout}
        md={12}
        sm={12}
        lg={12}
        xs={12}
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CloudDownloadOutlinedIcon />}
          style={{ margin: 2, marginRight: 4 }}
          onClick={() => { }}
        >
          Download
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormOpen(1);
          }}
        >
          New Evaluation
        </Button>
      </Grid>

      <Grid item md={12} sm={12} lg={12} xs={12}>
        <CommonGrid
          needExport={false}
          onGridReady={onGridReady}
          rows={evals}
          columns={columns}
          gridheight={215}
        // onRowSelect={(data) => setSelectedRow(data[0])}
        />
      </Grid>

      <DGDrawer
        Component={ControlLimitFormNew}
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false)
          loadAllEvals()

        }}
        drawerWidth={800}
        componentProps={id}
        title={evals[0]?.part_number + "  " + evals[0]?.part_name}
        needNext

      />

      <DGDrawer
        Component={ControlLimitView}
        //onClose={loadAllEvals}
        isOpen={formViewOpen}
        setOpen={() => {
          setFormViewOpen(false)
          loadAllEvals()
        }}
        drawerWidth={800}
        componentProps={studyDetails}
        title={studyDetails?.part_number + "  " + studyDetails?.part_name
          + "  /  " + studyDetails?.characteristics + "  "
          + studyDetails?.specification
          + studyDetails?.upper_specification + "  " + studyDetails?.lower_specification}

        subtitle={studyDetails?.report_name + "   " + studyDetails?.evaluation_method}
      />
    </>
  );
}
export default CharControlLimitListing;
const useStyles = makeStyles({
  addButton: {
    textAlign: "end",
  },
  addButtonLayout: {
    border: "solid 1px",
    borderColor: "#babfc7",
    textAlign: "end",
    borderBottom: 0,
    padding: 10,
  },
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
  minValidYear: 2000,
  maxValidYear: 3000,
};
