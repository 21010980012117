import {
    Grid, Button, Box, Badge,
    Typography, Chip
} from "@material-ui/core";
import StatusFilter from "../../components/shared/StatusFilter";
import ActiveInActive from "../../components/ActiveInActive";
import { useState, useContext, useEffect, useRef } from "react";
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { AuthContext } from "../../context/AuthContextProvider";
import { PlantContext } from "../../context/PlantContextProvider";
import { StatusContext } from "../../context/StatusContextProvider"
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider"
import CommonGrid from "../../components/CommonGrid";
import { DGDrawer } from "../../components/DGDrawer";
import NewOpenPoint from "./opl_create";
import EditOpenPoint from "./opl_edit";
import oplService from "../../services/oplService";
import { ResultRenderer } from "../../components/cellRenderers/ResultRenderer";
import { transparentize } from "polished";



export default function OPList({



}) {

    const [rows, setRows] = useState([]);
    const [requestFormOpen, setRequestFormOpen] = useState(false);
    const [editFormOpen, setEditFormOpen] = useState(false);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { setStatus } = useContext(StatusContext);
    const [selectedRow, setSelectedRow] = useState(null);
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [gridApi, setGridApi] = useState(null);
    const [noOfRows, setNoOfRows] = useState(0);
    const [masterRows, setMasterRows] = useState([]);
    const gridRef = useRef(null);
    const maxId = useRef(null)


    const isInitialMount = useRef(true);

    const [safetyNo, setSafetyNo] = useState(0);
    const [safetyChip, setSafetyChip] = useState(false);

    const [customerNo, setCustomerNo] = useState(0);
    const [customerChip, setCustomerChip] = useState(false);


    const [uiNo, setUINo] = useState(0);
    const [uIChip, setUIChip] = useState(false);

    const [closedNo, setClosedNo] = useState(0);
    const [closedChip, setClosedChip] = useState(false);

    const [overdueNo, setOverdueNo] = useState(0);
    const [overdueChip, setOverdueChip] = useState(false);

    const [notAssignedChip, setNotAssignedChip] = useState(false);
    const [notAssignedNo, setNotAssignedNo] = useState(0);

    const [assignedToMeChip, setAssignedToMeChip] = useState(false);
    const [assignedToMeNo, setAssignedToMeNo] = useState(0);


    useEffect(() => {
        setHeaderPageTitle(`Open Point's List`);
    }, []);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            callback: changeStatus,
        }));
        reloadGridData();

    }, []);


    useEffect(() => {
        reloadGridData();
    }, [globalPlant]);


    function reloadGridData() {
        const plant_id = globalPlant?.globalPlant?.id;
        if (!plant_id) {
            return;
        }

        oplService.getOPTable({
            plant_id: plant_id,
            client_id: currentUser.client_id,
        }).then((res) => {
            const tempData = res?.data
            setMasterRows(tempData);
            maxId.current = tempData[0].maxOpId


        });

    }

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return oplService.updateOP(rowId, { enabled: newStatus });
    }



    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },




        {
            field: "op_id",
            headerName: "ID",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            autoHeight: true,
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3', // 
                alignContent: 'left'
            },


            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() => loadEditForm(params?.data)}
                        size="small"
                        color="primary"
                    >

                        <>
                            Point ID : {params?.data?.op_id} <br />
                            Version : {params?.data?.op_version}
                        </>

                    </Button>
                );
            },
        },

        {
            field: "source_name",
            headerName: "Source",
            minWidth: 200,
            resizable: true,
            filter: "agTextColumnFilter",
            floatingFilter: true,

            /*    filterParams: {
                   buttons: ["apply", "reset"],
                   closeOnApply: true,
               } */
        },

        {
            field: 'op_description',
            headerName: 'Description',
            minWidth: 300,
            resizable: true,
            filter: "agTextColumnFilter",
            floatingFilter: true,
            cellRenderer: (params) => {
                if (!params.value) {
                    return '';
                }
                const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                return wrappedText;
            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3',
            },
            autoHeight: true,


        },

        {
            field: "criticality_id",
            headerName: "Criticality",
            minWidth: 150,
            resizable: true,
            filter: "agSetColumnFilter",
            filterParams: {
                values: ['1', '2', '3', '4'],
                valueFormatter: (params) => {
                    switch (params.value) {
                        case '1':
                            return 'Urgent';
                        case '2':
                            return 'Critical';
                        case '3':
                            return 'Critical & Urgent';
                        case '4':
                            return 'Non-Critical';
                        default:
                            return null;
                    }
                }
            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.5',
                alignContent: 'center'
            },
            cellRenderer: (params) => {
                if (params?.data?.criticality_id == 1) {
                    return <span style={{ fontSize: '1.1em' }}>Urgent</span>;
                } else if (params?.data?.criticality_id == 2) {
                    return <strong style={{ fontSize: '1em' }}>Critical</strong>;
                } else if (params?.data?.criticality_id == 3) {
                    return <strong style={{ fontSize: '1.2em' }}>Critical & Urgent</strong>;
                } else if (params?.data?.criticality_id == 4) {
                    return "Non-Critical";
                }
                return "";
            }
        },


        {

            field: "action_status",
            headerName: "Status",
            minWidth: 100,
            cellRenderer: (params) => (
                <ResultRenderer value={params?.data?.action_status} />
            ),


        },


        {
            headerName: ' Request Details',
            children: [

                {
                    field: "raised_by_name",
                    headerName: "Details",
                    minWidth: 150,
                    resizable: true,
                    filter: "agtextColumnFilter",
                    //floatingFilter: true,
                    valueGetter: (params) => params.data?.raised_by_name,
                    columnGroupShow: 'null',
                    cellRenderer: (params) => {
                        return (
                            <>
                                {new Date(params?.data?.raised_date).toLocaleDateString()} <br />
                                {params?.data?.raised_by_name}
                            </>
                        );
                    },
                    cellStyle: {
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.5',

                    },

                },
                {
                    field: 'ica',
                    headerName: 'ICA',
                    minWidth: 300,
                    resizable: true,
                    cellDataType: 'date',
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    cellRenderer: (params) => {

                        if (!params.value) {
                            return '';
                        }
                        const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                        return wrappedText;
                    },
                    cellStyle: {
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.3',
                    },
                    autoHeight: true,



                },

                {
                    field: 'closure_criteria',
                    headerName: 'Closure Criteria',
                    minWidth: 300,
                    resizable: true,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    cellRenderer: (params) => {
                        const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                        return wrappedText;
                    },
                    cellStyle: {
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.3',
                        alignContent: 'center'//
                    },
                    autoHeight: true,



                },

            ]
        },


        {
            headerName: 'Action Details',
            children: [


                {
                    field: "action_by_name",
                    headerName: "Action date",
                    minWidth: 150,
                    resizable: true,
                    filter: "agtextColumnFilter",
                    //floatingFilter: true,
                    cellDataType: 'date',
                    columnGroupShow: 'null',
                    valueGetter: (params) => params.data?.action_by_name,
                    cellRenderer: (params) => {

                        if (!params.value) {
                            return '';
                        }

                        return (
                            <>
                                {params?.data?.action_by_name}<br />
                                {params?.data?.action_target_date ? new Date(params.data.action_target_date).toLocaleDateString() : ""}
                            </>
                        );
                    },
                    cellStyle: {
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.5',
                        alignContent: 'center'

                    },

                },

                {
                    field: 'action_descritption',
                    headerName: 'Action Description',
                    minWidth: 300,
                    resizable: true,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    cellRenderer: (params) => {
                        const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                        return wrappedText;
                    },
                    cellStyle: {
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.3',
                        alignContent: 'center'//
                    },
                    autoHeight: true,

                },

            ]
        },




        {
            headerName: 'Closure Details',
            children:
                [

                    {
                        field: "closure_date",
                        headerName: "Closure Date",
                        minWidth: 150,
                        resizable: true,
                        filter: "agDateColumnFilter",
                        columnGroupShow: 'null',
                        cellRenderer: (params) => {

                            if (!params.value) {
                                return '';
                            }
                            return (
                                new Date(params?.data?.closure_date).toLocaleDateString()
                            );
                        },
                        cellStyle: {
                            whiteSpace: 'pre-wrap',
                            lineHeight: '1.5',
                            alignContent: 'center'

                        },

                    },

                    {
                        field: 'closure_descritption',
                        headerName: 'Closure Description',
                        minWidth: 300,
                        resizable: true,
                        columnGroupShow: 'open',
                        filter: "agTextColumnFilter",

                        cellRenderer: (params) => {
                            if (!params.value) {
                                return '';
                            }
                            const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                            return wrappedText;
                        },
                        cellStyle: {
                            whiteSpace: 'pre-wrap',
                            lineHeight: '1.3',
                            alignContent: 'center'//
                        },
                        autoHeight: true,

                    },
                ]
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.op_id,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (

                    < Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.op_id ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button >
                );
            },
        }

    ]


    function loadEditForm(data) {
        setEditFormOpen(true);
        setSelectedRow(data);
    }


    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlaylistAddIcon />}
                    onClick={() => {
                        setRequestFormOpen(true);
                    }}
                >
                    New Open Point
                </Button>

            </>
        );
    }


    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function onRowDataUpdated() {
        safetyBadge()
        customerBadge()
        uiBadge()
        overdueBadge()
        assignedToMeBadge()
        notAssignedBadge()

    }




    function onFirstDataRendered(params) {
        if (!masterRows) {
            return;
        }
        const filteredRows = masterRows.filter(row => row.action_status !== 1);
        params.api.setRowData(filteredRows);
        setNoOfRows(filteredRows.length);
        safetyBadge();
        customerBadge()
        uiBadge();
        closedBadge();
        overdueBadge();
        assignedToMeBadge()
        notAssignedBadge()
    }

    function onFilterChanged(params) {
        setNoOfRows(params.api.getDisplayedRowCount());

    }


    useEffect(() => {
        if (!isInitialMount.current) {
            if (safetyChip) {
                let filteredRows = [];
                gridApi.forEachNode((node) => {
                    if (node.data.source_id == 12001) {
                        filteredRows.push(node.data);
                    }
                });
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);
            } else {
                reSetRows();
            }
        }
    }, [safetyChip]);

    useEffect(() => {
        if (!isInitialMount.current) {
            if (customerChip == true) {
                let filteredRows = [];
                gridApi.forEachNode((node) => {
                    if (node.data.source_id == 12016) {
                        filteredRows.push(node.data);
                    }
                });
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);
            } else {
                reSetRows();

            }
        }
    }, [customerChip]);


    useEffect(() => {
        if (!isInitialMount.current) {
            if (assignedToMeChip) {
                let filteredRows = [];
                gridApi.forEachNode((node) => {
                    if (node.data.action_by_id === currentUser.id) {
                        filteredRows.push(node.data);
                    }
                });
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);
            } else {
                reSetRows();

            }
        }
    }, [assignedToMeChip]);



    useEffect(() => {
        if (!isInitialMount.current) {
            if (notAssignedChip) {
                let filteredRows = [];
                gridApi.forEachNode((node) => {
                    if (!node.data.action_by_id || !node.data.action_target_date
                        || !node.data.action_descritption) {
                        filteredRows.push(node.data);
                    }
                });
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);
            } else {
                reSetRows();

            }
        }
    }, [notAssignedChip]);


    useEffect(() => {
        if (!isInitialMount.current) {
            if (uIChip) {
                let filteredRows = [];
                gridApi.forEachNode((node) => {
                    if (node.data.criticality_id === 3) {
                        filteredRows.push(node.data);
                    }
                });
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);
            } else {
                reSetRows();

            }
        }
    }, [uIChip]);


    useEffect(() => {
        if (!isInitialMount.current) {
            if (closedChip) {
                const filteredRows = masterRows.filter(row => row.action_status === 1);
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);
                setOverdueChip(false);
                overdueBadge(0);
            } else {
                reSetRows();

            }
        }
    }, [closedChip]);


    useEffect(() => {
        if (!isInitialMount.current) {
            if (overdueChip) {
                let filteredRows = [];
                gridApi.forEachNode((node) => {
                    if (node.data.action_target_date &&
                        new Date(node.data.action_target_date) < new Date()) {
                        filteredRows.push(node.data);
                    }
                });
                gridApi.setRowData(filteredRows);
                setNoOfRows(filteredRows.length);

            } else {
                reSetRows();

            }
        }
    }, [overdueChip]);


    function reSetRows() {
        var filteredRows = masterRows.filter(row => row.action_status !== 1);
        setSafetyChip(false);
        setCustomerChip(false);
        setUIChip(false);
        setClosedChip(false);
        setOverdueChip(false);
        setAssignedToMeChip(false);
        setNotAssignedChip(false);
        gridApi.setRowData(filteredRows);
        setNoOfRows(filteredRows.length);

    }



    function customerBadge() {
        let filteredCount = 0
        gridApi.forEachNode((node) => {
            if (node.data.source_id == 120016) {
                filteredCount++;
            }
        });
        setCustomerNo(filteredCount);
    }


    function safetyBadge() {
        let filteredCount = 0
        gridApi.forEachNode((node) => {
            if (node.data.source_id == 12001) {
                filteredCount++;
            }
        });
        setSafetyNo(filteredCount);
    }

    function uiBadge() {
        let filteredCount = 0
        gridApi.forEachNode((node) => {
            if (node.data.criticality_id === 3) {
                filteredCount++;
            }
        });
        setUINo(filteredCount);
    }

    function closedBadge() {
        const filteredRows = masterRows.filter(row => row.action_status === 1);
        setClosedNo(filteredRows.length);


    }

    function overdueBadge() {
        let filteredCount = 0
        gridApi.forEachNode((node) => {
            if (node.data.action_target_date
                && new Date(node.data.action_target_date) < new Date()) {
                filteredCount++;
            }
        });

        setOverdueNo(filteredCount);
    }

    function assignedToMeBadge() {
        let filteredCount = 0
        gridApi.forEachNode((node) => {
            if (node.data.action_by_id === currentUser.id) {
                filteredCount++;
            }
        });
        setAssignedToMeNo(filteredCount);
    }

    function notAssignedBadge() {
        let filteredCount = 0
        gridApi.forEachNode((node) => {
            if (!node.data.action_by_id || !node.data.action_target_date
                || !node.data.action_descritption) {
                filteredCount++;
            }
        })
        setNotAssignedNo(filteredCount);
    }



    useEffect(() => {
        isInitialMount.current = false;

    }, []);




    return (

        <>

            <Box
                sx={{
                    border: '1px solid grey',
                    width: '100%',
                    padding: '5px',
                    marginTop: '5px',
                    //height: '70%',
                    marginRight: '2%',
                    boxSizing: 'border-box',
                    overflowY: 'auto',
                    overflowX: 'auto',
                    height: "120px",
                    borderRadius: '10px',
                    backgroundColor: 'white',
                }}
            >

                <Grid container spacing={2} direction="row">


                    <Grid item xs={3}>
                        <Box
                            sx={{
                                border: '1px solid grey',
                                width: '100%',
                                padding: '5px',
                                marginTop: '5px',
                                height: '90%',
                                marginRight: '10%',
                                boxSizing: 'border-box',
                                // height: "100px",
                                borderRadius: '10px',
                                textAlign: 'center',


                            }}
                        >
                            <Typography style={{ fontSize: '60px', fontWeight: 'bold' }}>
                                {noOfRows}
                            </Typography>



                        </Box>

                    </Grid>

                    <Grid item xs={9}>
                        <Badge badgeContent={safetyNo} color="primary" style={{ margin: "10px" }}>

                            <Chip
                                label={"Safety"}
                                style={{
                                    backgroundColor: !safetyChip ? 'transparent' : transparentize(0.1, 'red'),
                                    border: !safetyChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    color: !safetyChip ? 'black' : 'white'
                                }}
                                onClick={() => {
                                    setSafetyChip(!safetyChip)
                                }}

                            />
                        </Badge>

                        <Badge badgeContent={customerNo} color="primary" style={{ margin: "10px" }}>

                            <Chip
                                label={"Customer"}
                                style={{
                                    backgroundColor: !customerChip ? 'transparent' : transparentize(0.1, 'gold'),
                                    border: !customerChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    // color: !customerChip ? 'white' : 'black'
                                }}
                                onClick={() => {
                                    setCustomerChip(!customerChip)
                                }}
                            />
                        </Badge>

                        <Badge badgeContent={overdueNo} color="primary" style={{ margin: "10px" }}>
                            <Chip
                                label={"Overdue Points"}
                                style={{
                                    backgroundColor: !overdueChip ? 'transparent' : transparentize(0.1, 'brown'),
                                    border: !overdueChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    color: !overdueChip ? 'black' : 'white'
                                }}
                                onClick={() => {
                                    setOverdueChip(!overdueChip)

                                }}
                            />
                        </Badge>

                        <Badge badgeContent={assignedToMeNo} color="primary" style={{ margin: "10px" }}>
                            <Chip
                                label={"Assigned to Me"}
                                style={{
                                    backgroundColor: !assignedToMeChip ? 'transparent' : transparentize(0.8, 'indigo'),
                                    border: !assignedToMeChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    color: 'black'
                                }}
                                onClick={() => {
                                    setAssignedToMeChip(!assignedToMeChip)
                                    //setOverDue(false)
                                }}
                            />
                        </Badge>

                        <Badge badgeContent={uiNo} color="primary" style={{ margin: "10px" }}>
                            <Chip
                                label={"Critical & Urgent"}
                                style={{
                                    backgroundColor: !uIChip ? 'transparent' : transparentize(0.1, 'purple'),
                                    border: !uIChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    color: !uIChip ? 'black' : 'white'
                                }}
                                onClick={() => {
                                    setUIChip(!uIChip)

                                }}
                            />
                        </Badge>

                        <Badge badgeContent={closedNo} color="primary" style={{ margin: "10px" }}>
                            <Chip
                                label={"Closed Points"}
                                style={{
                                    backgroundColor: !closedChip ? 'transparent' : transparentize(0.1, 'green'),
                                    border: !closedChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    color: !closedChip ? 'black' : 'white'
                                }}
                                onClick={() => {
                                    setClosedChip(!closedChip)

                                }}
                            />
                        </Badge>

                        <Badge badgeContent={notAssignedNo} color="primary" style={{ margin: "10px" }}>
                            <Chip
                                label={"Actions defenition incomplete"}
                                style={{
                                    backgroundColor: !notAssignedChip ? 'transparent' : transparentize(0.4, 'blue'),
                                    border: !notAssignedChip ? '1px solid green' : 'none',
                                    fontWeight: 'bold',
                                    color: !notAssignedChip ? 'black' : 'white'
                                }}
                                onClick={() => {
                                    setNotAssignedChip(!notAssignedChip)

                                }}
                            />
                        </Badge>



                    </Grid>

                </Grid>
            </Box>


            <CommonGrid
                rows={masterRows}
                columns={columns}
                ButtonComponent={ButtonComponent}
                gridheight={100}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                // onFilterChanged={onFilterChanged}
                onRowDataUpdated={onRowDataUpdated}
            />


            <DGDrawer
                needSave
                drawerWidth={1200}
                isOpen={requestFormOpen}
                setOpen={() => {
                    setRequestFormOpen(false);
                    reloadGridData();

                }}
                Component={NewOpenPoint}
                componentProps={{
                    maxId: maxId.current
                }}

                title="New Open Point"

            />



            <DGDrawer
                needSave
                drawerWidth={1200}
                isOpen={editFormOpen}
                isClosed={onRowDataUpdated}
                setOpen={() => {
                    setEditFormOpen(false);
                    reloadGridData();
                }}
                Component={EditOpenPoint}
                componentProps={{
                    data: selectedRow,
                    editFlag: true
                }}
                title={"Edit Open Point #" + selectedRow?.op_id}


            />

        </>
    )



}