import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import DataGridTable from "../../components/DataGridTable";
import machineService from "../../services/machineService";

const AllProcessList = ({ processes }) => {
  // console.log("🚀  -> file: AllProcessList.js -> line 8 -> AllProcessList -> processes", processes);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (processes) {
      listProcessToRows();
    }
  }, [processes]);

  const getMachinesByProcessId = async (processId) => {
    const machineIds = await machineService.getMachineByProcessId(processId);
    // console.log("🚀 ~ file: AllProcessList.js ~ line 22 ~ geMachinesByProcessId ~ machineIds", machineIds);
    const machines = await Promise.all(
      machineIds.data.data.map(async (item) => {
        const machine = await machineService.getMachineById(item.machine_id);
        // console.log("🚀 ~ file: AllProcessList.js ~ line 25 ~ machines ~ machine", machine);
        return machine.data.data[0];
      })
    );
    // console.log("🚀 ~ file: AllProcessList.js ~ line 27 ~ machines ~ machines", machines);
    return machines;
  };

  const listProcessToRows = async () => {
    let items = [];
    await Promise.all(
      processes.map(async (process) => {
        const machines = await getMachinesByProcessId(process.id);
        items.push({
          id: process.id,
          name: process.name,
          machine_name:
            machines && machines.length > 0 ? machines[0].name : "Not Assigned",
          machine:
            machines && machines.length > 0
              ? machines[0].machine_number
              : "Not Assigned",
        });
        return items;
      })
    );
    setRows(items);
  };

  const columns = [
    { field: "machine", headerName: "Machine Number", flex: 1 },
    { field: "machine_name", headerName: "Machine Name", flex: 1 },
    {
      field: "name",
      headerName: "Process Name",
      flex: 1,
    },
  ];

  return (<React.Fragment>
    <Card style={{ height: "auto", width: "100%", padding: '1rem' }}>
    {rows && columns && <DataGridTable columns={columns} rows={rows} />}
    </Card>
  </React.Fragment>
  );
};

export default AllProcessList;
