import { useContext, useState, useEffect } from "react";
import {
    Button,
    Grid,
    Paper,
} from "@material-ui/core";
import CommonGrid from "../../../components/CommonGrid";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { StatusContext } from "../../../context/StatusContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { format } from 'date-fns'
import StatusFilter from "../../../components/shared/StatusFilter";
import { filter } from "ag-charts-community/dist/cjs/es5/scale/continuousScale";



export default function DefectRecordList() {

    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { globalPlant } = useContext(PlantContext);
    const [plantShowFlag, setPlantShowFlag] = useState(false);
    const { setStatusHistory } = useContext(StatusHistoryContext);



    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            //callback: updateCustomerStatus,
        }));
        reloadGridData();

    }, []);



    useEffect(() => {
        if (globalPlant?.globalPlant?.id === currentUser?.client_id) {
            setPlantShowFlag(true)
        }
        else { setPlantShowFlag(false) }
        reloadGridData()

    }, [globalPlant]);

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return qualityAnalyticsService.updateDefectRecordTable(rowId, { enabled: newStatus });
    }

    function reloadGridData() {

        qualityAnalyticsService.getDefectRecordTable({
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id
        }
        )
            .then((res) => {
                //    console.log(res.data)
                setRows(res.data)
            })


    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        }
        ,
        ...(plantShowFlag ? [{
            headerName: "Plant",
            field: "plant_name",
            width: 200,
        }] : [])
        ,
        {
            headerName: 'Production Details',
            children: [

                {

                    field: "part_name",
                    headerName: "Part Name",
                    columnGroupShow: 'null',
                    minWidth: 250,
                    sorting: true,
                    cellRenderer: (params) => {
                        return (
                            <>
                                {params?.data?.part_number}-{params?.data?.part_name}

                            </>
                        )
                    }

                }
                , {
                    field: "machine_name",
                    headerName: "Machine",
                    columnGroupShow: 'open',
                    minWidth: 150,
                    sorting: true,
                    cellRenderer: (params) => {
                        return (
                            <>
                                {params?.data?.machine_number}-{params?.data?.machine_name}
                            </>
                        )
                    }
                },
                {
                    field: "operator_name",
                    headerName: "Operator ID",
                    flex: 1,
                    editable: false,
                    resizable: true,
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: "agMultiColumnFilter",
                    minWidth: 150,
                    sortable: true,
                    cellRenderer: (params) => {
                        return (
                            <>
                                {params?.data?.operator_name}-{params?.data?.operator_eid}
                            </>
                        )
                    },
                },

            ]
        },
        {
            headerName: 'Production Date',
            children: [
                {
                    field: "production_date",
                    headerName: "Date:Production",
                    flex: 1,
                    editable: false,
                    resizable: true,
                    floatingFilter: true,
                    columnGroupShow: 'null',
                    filter: "agMultiColumnFilter",
                    minWidth: 150,
                    sort: "desc",
                    sortable: true,
                    valueGetter: (params) => {
                        return new Date(params?.data?.production_date);
                    },
                    valueFormatter: (params) => {
                        return format(new Date(params.value), 'dd/MM/yyyy');
                    }
                },
                {
                    field: "production_start_time",
                    headerName: "Start Time:Production",
                    flex: 1,
                    editable: false,
                    resizable: true,
                    columnGroupShow: 'open',
                    floatingFilter: true,
                    filter: "agMultiColumnFilter",
                    minWidth: 150,

                    valueGetter: (params) => {
                        const productionStartTime = params?.data?.production_start_time;
                        if (productionStartTime) {
                            const [hours, minutes] = productionStartTime.split(':');
                            return `${hours}:${minutes}`;
                        }
                        return '';
                    }

                },
                {
                    field: "production_end_time",
                    headerName: " End Time : Production",
                    flex: 1,
                    editable: false,
                    resizable: true,
                    floatingFilter: true,
                    columnGroupShow: 'open',
                    filter: "agMultiColumnFilter",
                    minWidth: 150,
                    valueGetter: (params) => {
                        const productionEndTime = params?.data?.production_end_time;
                        if (productionEndTime) {
                            const [hours, minutes] = productionEndTime.split(':');
                            return `${hours}:${minutes}`;
                        }
                        return '';
                    }

                },
            ]
        },
        {
            field: "production_quantity",
            headerName: "Production Quantity",
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            minWidth: 150,
        },
        {
            field: "defect_name",
            headerName: "Defect",
            resizable: true,
            filter: 'agMultiColumnFilter',
            floatingFilter: true,
            minWidth: 150,
            sortable: true,

        },
        {
            field: "defect_quantity",
            headerName: "Rejection Quantity",
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            minWidth: 150,

        },
        {
            field: "enabled",
            headerName: "Status",
            minWidth: 200,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },

            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: `${params?.data?.defect_name ?? ""}`,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "history",
            headerName: "Status History",
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params.data.id,
                                entity: params?.data?.name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },









    ]

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} lg={12}>

                    <CommonGrid
                        //ButtonComponent={ButtonComponent}
                        rows={rows}
                        columns={columns}
                        gridheight={115}

                    />

                </Grid>


            </Grid>
        </>
    )

}