import {
    Grid, Typography, Button, makeStyles,
    InputLabel, InputBase, TextField
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import processCapability from "../../../../services/processCapability";
import { DGInput } from "../../../../components/shared/DGInput";
import { format } from 'date-fns';
import { width } from "@material-ui/system";
import { SpcDetail } from "../../spc/SpcDetail";


export default function CpkDetailForm(
    componentProps = null,
    closeForm = () => { },
) {

    const b_colour = componentProps?.componentProps?.cpk_result < 1 ? 'red' : 'green';
    const t_colour = componentProps?.componentProps?.cpk_result < 1 ? 'white' : 'white';

    const useStyles = makeStyles({
        cpk1: {
            'font-weight': 'bold',
            'font-size': '40px',
            'text-align': 'center',
            'padding': '5px',
            'color': t_colour
        },
        cpk2: {
            'font-size': '30px',
            'text-align': 'center',
            'padding': '5px',
        },
        textField: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderWidth: '1px'
                },
                '&:hover fieldset': {
                    borderWidth: '1px'
                },
                '&.Mui-focused fieldset': {
                    borderWidth: '1px'
                }
            }

        }
    });

    const classes = useStyles();

    const componentProps1 = {
        character_id: componentProps?.componentProps?.character_id,
        machine_spindle: componentProps?.componentProps?.machine_spindle,
        start_timestamp: componentProps?.componentProps?.start_timestamp,
        end_timestamp: componentProps?.componentProps?.end_timestamp,
        cpk_flag: 1
    };

    return (
        <>

            <Grid container spacing={2} style={{ marginTop: '10px' }}>

                <Grid item xs={4}>
                    <DGInput
                        label="Part Number"
                        value={componentProps?.componentProps?.part_number + " - "
                            + componentProps?.componentProps?.part_name}
                    />
                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Characteristics"
                        value={
                            componentProps?.componentProps?.character_name + " - " +
                            componentProps?.componentProps?.specification + " " +
                            componentProps?.componentProps?.upper_specification + " " +
                            componentProps?.componentProps?.lower_specification
                        }
                    />
                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Machine"
                        value={
                            componentProps?.componentProps?.machine_number + " " +
                            componentProps?.componentProps?.spindle_name
                        }
                    />
                </Grid>

                <Grid item xs={2}>
                    <DGInput
                        label="Evaluation Date"
                        value={format(new Date(componentProps?.componentProps?.created_date), 'dd/MM/yyyy')}
                    />
                </Grid>

                <Grid item xs={3}>
                    <DGInput
                        label="Evaluation Start Date"
                        value={format(new Date(componentProps?.componentProps?.start_timestamp), 'dd/MM/yyyy')}
                    />
                </Grid>
                <Grid item xs={2}>
                    <DGInput
                        label="Sample Size"
                        value={componentProps?.componentProps?.sample_size}
                    />
                </Grid>

                <Grid item xs={3}>
                    <DGInput
                        label="Evaluation End Date"
                        value={format(new Date(componentProps?.componentProps?.end_timestamp), 'dd/MM/yyyy')}
                    />
                </Grid>

                <Grid item xs={2}>
                    <DGInput
                        label="Cp"
                        value={

                            parseFloat(componentProps?.componentProps?.cp).toFixed(2)}
                    />
                </Grid>

            </Grid >


            <Grid item xs={12}
                style={{
                    marginTop: '20px',
                    justifyContent: "center",
                    alignItems: "center",
                    display: 'flex'
                }}
            >

                <InputBase
                    value={parseFloat(componentProps?.componentProps?.cpk_confidence_lower).toFixed(2)}
                    marginBottom="10px"
                    style={{ width: "10%" }}

                    classes={{ input: classes.cpk2 }}
                />


                <TextField
                    id="validation-outlined-input"
                    label=" Cpk"
                    value={parseFloat(componentProps?.componentProps?.cpk).toFixed(2)}
                    marginBottom="10px"
                    style={{
                        width: "20%",
                        backgroundColor: b_colour,
                    }}

                    InputProps={{
                        classes: {
                            input: classes.cpk1
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            fontSize: '30px',
                            color: t_colour,
                            fontWeight: 'bold',
                            marginLeft: '10px'
                        }
                    }}
                />

                <InputBase
                    value={parseFloat(componentProps?.componentProps?.cpk_confidence_upper).toFixed(2)}
                    marginBottom="10px"
                    style={{ width: "10%" }}

                    classes={{ input: classes.cpk2 }}
                />

            </Grid>


            <Grid item xs={12}>
                <SpcDetail
                    componentProps={componentProps1}

                ></SpcDetail>



            </Grid>


        </>
    )

}