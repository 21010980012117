import http from "./httpService";

const createApproval = (data) => {
    return http.post("/approval", data);

    //entity id = character_id
    //gauge_vharacter_id: null
    // entity_name :part_id
    //report_id : Evaluation id

};

const updateApproval = (id, data) => {
    return http.patch(`/approval/${id}`, data);
}

const getApprovalsByEntityId = (entityId) => {
    return http.get("/approval", {
        params: {
            entity_id: `${entityId}`,
        }
    });
}

const getAllApprovals = (users) => {
    return http.get("/approval", {
        params: {
            "requested_by[$in]": users,
        }
    });
}


const getApprovalApproved = (client_id, user_id) => {
    return http.get("/approval-table", {
        params: {
            status_id: 2,
            client_id: client_id,
            user_id: user_id
        }
    });
}

const getApprovalPending = (client_id, user_id) => {
    return http.get("/approval-table", {
        params: {
            status_id: 1,
            client_id: client_id,
            user_id: user_id
        }
    });
}

const getApprovalRejected = (client_id, user_id) => {
    return http.get("/approval-table", {
        params: {
            status_id: 3,
            client_id: client_id,
            user_id: user_id
        }
    });
}


const approveCharacter = (id, data) => {
    return http.patch(`/character/${id}`, data);
}

const approveMSA = (id, data) => {
    return http.patch(`/msa-plan/${id}`, data);
}

const deleteapprecord = (id) => {
    return http.delete(`/approval/${id}`)
}


const createApprovalDecision = (data) => {
    return http.post('/approval-decision', data)
    // Details of Input
    //approval_id :
    //user_id :
    //status_id :

}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createApproval,
    getAllApprovals,
    approveCharacter,
    updateApproval,
    approveMSA,
    getApprovalsByEntityId,
    getApprovalApproved,
    getApprovalPending,
    getApprovalRejected,
    deleteapprecord,
    createApprovalDecision
};