import http from "./httpService";

const createAddress = (data) => {
  return http.post("/address", data);
};

const updateAddress = (id, data) => {
  return http.patch(`/address/${id}`, data);
};

const getAddress = (id) => {
  return http.get(`/address/${id}`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createAddress,
  updateAddress,
  getAddress
};
