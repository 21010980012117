import http from "./httpService";

const createErrorLog = (data) => {
  return http.post("/error-log", data);
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createErrorLog
};