import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { rgba } from "polished";
import { Button, makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";

import LightTooltip from "../../../components/Tooltip";
import CommonGrid from "../../../components/CommonGrid";
import { ApprovalStatus } from "../../../components/approvalStatus";
import StatusFilter from "../../../components/shared/StatusFilter";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import ActiveInActive from "../../../components/ActiveInActive";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import AddIcon from "@material-ui/icons/Add";
import characterService from "../../../services/characterService";
import { ProductCharacteristicsForm } from "./ProductCharacteristicsFormNew"
import { ProductCharacteristicsApprovalForm } from "./ProductCharacteristicsApproval"
import productService from "../../../services/productService";

import { DGDrawer } from "../../../components/DGDrawer"


const useStyle = makeStyles({
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});
const ProductCharacteristicsList = ({
  Buttoncomponent = () => null,
  onGridReady = () => { },
  update = true,
}) => {
  const [rows, setRows] = useState([]);
  const classes = useStyle();
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [formOpen, setFormOpen] = useState(false);
  const [formAppOpen, setFormAppOpen] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  let { id } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);


  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData,
      callback: updateCharcterStatus,
    }));
    reloadGridData()
  }, []);



  function reloadGridData() {
    characterService
      .getCharacterPartId(id, 1)
      .then((res) => {
        setRows(res?.data ?? []);
      })
  }

  useEffect(() => {
    productService
      .getProductById(id)
      .then((res) => {
        setSubtitle(`${res?.data?.part_number} ${res?.data?.part_name}`);
      })
  }, []);


  const getCharactersByProductId = async (productId) => {
    await characterService.getCharacterPartId(productId, 1).then((res) => {
      setRows(res?.data ?? []);
    });
  };

  function updateCharcterStatus(dummy1, dummy2, newStatus, rowId) {
    return characterService.updateCharacter(rowId, { enabled: newStatus });
  }

  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }

  function ButtonComponent() {
    return (

      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => { setFormOpen(true) }}
        style={{ marginLeft: 3 }}
      >
        Add Character
      </Button>

    );
  }


  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      pinned: "left",
    },
    {
      field: "character_name",
      headerName: "Name",
      editable: false,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      pinned: "left",
      filter: "agMultiColumnFilter",
      cellRenderer: (params) => {
        const style = {
          marginLeft: params.data.level * 20,
          // color: params.data.level > 1 ? '#ff0000' : '#376FD0', // Example of dynamic color based on level
        };

        return (
          <div style={style}>{params.value}</div>
        );
      },

    },
    {
      field: "specification",
      headerName: "Specification",
      resizable: true,
      editable: false,
      pinned: "left",
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      cellRenderer: (params) => (
        <>
          <GetFormattedValue
            value={
              params?.data?.specification !== null &&
                params?.data?.upper_specification !== null &&
                params?.data?.lower_specification !== null
                ? `${params?.data?.specification},${params?.data?.upper_specification},${params?.data?.lower_specification}`
                : null
            }
          />
        </>
      ),
      minWidth: 220,
    },
    {
      field: "type",
      headerName: "Type",
      resizable: true,
      minWidth: 200,
      editable: false,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
    },

    {
      field: "position_in_drawing",
      headerName: "Position in Drawing",
      resizable: true,
      minWidth: 200,
      editable: false,
      filter: "agTextColumnFilter",
      floatingFilter: true,

    },
    {
      headerName: "Gauge",
      field: "gauge_count",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) =>
        params?.data?.gauge_count >= 1 ? <ResultRenderer value={1} /> : null,
    },
    {
      headerName: "Machine",
      field: "machine_count",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) =>
        params?.data?.machine_count >= 1 ? <ResultRenderer value={1} /> : null,
    },
    {
      field: "criticality",
      headerName: "Criticality",
      minWidth: 200,
      resizable: true,
      editable: false,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      cellRenderer: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "partNameNumber",
      headerName: "Part Details",
      minWidth: 200,
      resizable: true,
      editable: false,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params) =>
        `${params?.data?.part_number}-${params?.data?.part_name}`,
      cellStyle: (params) => ({
        marginLeft: params?.data?.level > 0 ? params?.data?.level * 20 : 0,
      }),
    },
    {
      field: "approval_status",
      headerName: "Approval Status",
      minWidth: 200,
      editable: false,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRenderer: (params) => (
        <div
          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
          onClick={() => {
            setFormAppOpen(true);
            setSelectedRow(params.data);
          }}
        >
          <ApprovalStatus status={params?.data?.approval_status} />
        </div>
      ),
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          disabled={params?.data?.level !== 0}
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.name ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
              callback: updateCharcterStatus,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <CommonGrid
        gridheight={158}
        onGridReady={onGridReady}
        needExport={true}
        ButtonComponent={ButtonComponent}
        rows={rows}
        columns={columns}
      />

      <DGDrawer
        Component={ProductCharacteristicsForm}
        title="Add Characteristics"
        isOpen={formOpen}
        setOpen={(state) => {
          setSubtitle("");
          setFormOpen(state);
          if (!state) {
            reloadGridData();
          }
        }}
        onClose={reloadGridData}
        subtitle={subtitle}
        drawerWidth={1000}
        needSave
      />

      <DGDrawer
        Component={ProductCharacteristicsApprovalForm}
        title="Approve Characteristics"
        isOpen={formAppOpen}
        setOpen={(state) => {
          setSubtitle("");
          setFormAppOpen(state);
          if (!state) {
            reloadGridData();
          }
        }}
        //onClose={reloadGridData}
        subtitle={subtitle}
        drawerWidth={1000}
        componentProps={selectedRow}
      />
    </>
  );
};

export default ProductCharacteristicsList;
