import { Grid } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { DGInput } from "../../../components/shared/DGInput";
import { useFormik } from "formik";
import { object, string } from "yup";
import gaugeService from "../../../services/gaugeService";
import { AlertContext } from "../../../context/AlertContextProvider";

export function GaugeReturnForm({
  Footer = () => null,
  closeForm = () => { },
  componentProps = null,
}) {
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      gauge: "",
      issued: "",
      location: "",
      comments: "",
      new_location: "",
    },

    /*
    validationSchema: object().shape({
      comments: string().required(),
    }),
    */

    onSubmit: onSave,
  });

  useEffect(() => {
    //("componentProps", componentProps);
    form.setFieldValue("gauge", componentProps?.gauge_number_name);
    form.setFieldValue("issued", componentProps?.issued_to);
    form.setFieldValue("location", componentProps?.location);
  }, []);




  function onSave() {
    gaugeService
      .createGaugeReturn(
        componentProps?.gauge_item_id,
        componentProps?.issue_id,
        form.values.comments,
        form.values.new_location
      )
      .then((res) => {
        closeForm(true, { status: "s" });
        updateAlert({
          message: "Gauge Returned Successfully",
          type: "success",
          open: true,
        });
      })
      .catch((err) => {
        updateAlert({
          message: "Failed to Return Gauge",
          type: "error",
          open: true,
        });
      });
  }
  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Gauge"
          id="gauge"
          value={form.values.gauge}
          isFormik
          onChange={form.handleChange}
          disabled
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Issued To"
          id="issued"
          value={form.values.issued}
          isFormik
          onChange={form.handleChange}
          disabled
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Location"
          id="location"
          value={form.values.location}
          isFormik
          onChange={form.handleChange}
          disabled
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="Comments"
          id="comments"
          value={form.values.comments}
          isFormik
          onChange={form.handleChange}
          multiline
          rows={5}
          error={form.errors.comments && form.touched.comments}

        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          label="New Location"
          id="new_location"
          value={form.values.new_location}
          isFormik
          onChange={form.handleChange}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
