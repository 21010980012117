import { Grid, TextareaAutosize } from "@material-ui/core";
import { useFormik } from "formik";
import { DropzoneArea } from "material-ui-dropzone";
import moment from "moment";
import React, { useContext, useState } from "react";
import { date, object } from "yup";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGInput } from "../../../components/shared/DGInput";
import { AuthContext } from "../../../context/AuthContextProvider";
import piService from "../../../services/piService";
import uploadService from "../../../services/uploadService";
import { AlertContext } from "../../../context/AlertContextProvider";

export function RecordCalibrationForm({
  Footer = () => { },
  componentProps = null,
  closeForm = () => { },
}) {
  const [uploadedCertificate, setUploadedCertificate] = useState();
  const { updateAlert } = useContext(AlertContext);
  let { currentUser } = useContext(AuthContext);

  let form = useFormik({
    initialValues: {
      uploadedCertificate: null,
      calibratedDate: new Date(),
      certificateNumber: "",
      comments: "",
    },
    validationSchema: object({ calibratedDate: date().required() }),
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    let payload = {
      created_by: currentUser?.id,
      calibrated_date: moment(form.values.calibratedDate).format("YYYY/MM/DD"),
      calibration_comments: form.values.comments,
      pi_request_id: componentProps?.gauge_item?.pi_request_id,
      threeP_certificate: form.values.certificateNumber,
      guage_item: componentProps?.gauge_item?.gauge_item_id,
      activity: componentProps?.activity,
      pi_result_id: componentProps?.pi_result_id,
      certificate_link: "",
    };

    if (uploadedCertificate && uploadedCertificate?.length) {
      uploadService.uploadFile({
        files: uploadedCertificate,
        client_id: currentUser.client_id
      })
        .then((uploadres) => {
          payload["certificate_link"] = uploadres?.data?.files[0]?.location ?? "";
          if (payload["certificate_link"]) {
            piService.createPIActivity(payload).then((res) => {
              closeForm(true, { status: "s" });
            });
          }
        });
    } else {
      piService
        .createPIActivity(payload)
        .then((res) => {
          closeForm(true, { status: "s" });
          updateAlert({
            message: "Calibration Recorded Successfully",
            open: true,
            type: "success",
          });
        })
        .catach((errr) => {
          updateAlert({
            message: "Failed to Record Calibration",
            open: true,
            type: "error",
          });
        });
    }
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DropzoneArea
          showFileNamesInPreview={true}
          showFileNames={true}
          showAlerts={true}
          onChange={(file) => setUploadedCertificate(file)}
          acceptedFiles={["image/*", "application/*"]}
          filesLimit={1}
          maxSize={10971520}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGDateInput
          required
          variant="outlined"
          label="Calibrated Date"
          value={form.values.calibratedDate}
          onChange={(date) => form.setFieldValue("calibratedDate", date)}
          error={form.touched.calibratedDate && form.errors.calibratedDate}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          id="certificateNumber"
          label="Certificate Number"
          variant="outlined"
          value={form.values.certificateNumber}
          onChange={form.handleChange}
          isFormik
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <TextareaAutosize
          aria-label="comments"
          placeholder="Add your comments here"
          minRows={6}
          className="border border-2"
          style={{
            width: "100%",
            // borderColor: "#bbb",
            borderRadius: "5px",
          }}
          onChange={(e) => {
            form.setFieldValue("comments", e.target.value);
          }}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
