import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";

export function DeleteRenderer(params) {
  return (
    <IconButton
      color="primary"
      onClick={() => {
        params?.onClick(params?.data, params?.node?.rowIndex);
        params?.refreshCell();
      }}
    >
      <DeleteIcon></DeleteIcon>
    </IconButton>
  );
}
