import { Button, Grid, Paper, Checkbox } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "../../../context/AuthContextProvider";

import CommonGrid from "../../../components/CommonGrid";
import { useFormik } from "formik";
import { DGInput } from "../../../components/shared/DGInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { object, array } from "yup";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import cuttingToolService from "../../../services/cuttingToolService";
import { grid } from "@material-ui/system";
import productService from "../../../services/productService";
import { DGSelect } from "../../../components/shared/DGSelect";
import errorLog from "../../../services/errorLog";


export function CuttingToolProductLink({
    Footer = () => null,
    closeForm = () => { },
}) {

    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const { globalPlant } = useContext(PlantContext);
    const { v4: uuidv4 } = require('uuid');

    let form = useFormik({
        initialValues: {
            partOptions: [],
            partSelected: null,
            toolOptions: [],
            toolSelected: null,
            gridData: [],
            ctool_life: ""

        },
        validationSchema: object().shape({
            gridData: array().min(1),

        }),

        onSubmit: saveForm
    }
    )

    useEffect(() => {

        productService.getAProductByPlant(globalPlant?.globalPlant?.id)
            .then(res => {
                var temp = res.data;
                form.setFieldValue("partOptions", temp);
            });

    }, []);

    useEffect(() => {

        cuttingToolService.getCToolTable(currentUser.client_id)
            .then(res => {
                var temp = res?.data?.data;
                const filtered = temp.filter((item) => item.enabled === 1);
                form.setFieldValue("toolOptions", filtered);
            });

    }, []);


    function onGridReady(params) {
        setGridApi(params?.api);
    }



    function onAdd() {
        form.setFieldValue("gridData", [
            ...form.values.gridData,
            form.values,
        ])
        form.setFieldValue("toolSelected", null);
    }

    function saveForm() {
        let payload = [];
        gridApi.forEachNode((node) => {
            let data = node?.data;
            // console.log(data, "data")
            payload.push({
                id: uuidv4(),
                client_id: currentUser.client_id,
                plant_id: globalPlant?.globalPlant?.id,
                ctool_id: data?.toolSelected.id,
                part_id: data?.partSelected.id,
                user_id: currentUser.id,
                enabled: 1,
                ctool_life: data?.ctool_life

            })
        })
        cuttingToolService.createCToolProduct(payload)
            .then(res => {
                updateAlert({
                    open: true,
                    message: "Cutting Tool Product Link Created",
                    type: "success",
                });
                closeForm();
            })
            .catch((err) => {
                updateAlert({
                    open: true,
                    message: "Error Creating Cutting Tool Product Link",
                    type: "error",
                });
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "createCToolProduct",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: "",
                };
                errorLog.createErrorLog(payload)
                updateAlert({
                    open: true,
                    message: "Failed to added people",
                    type: "error",
                });
            });
    }

    function removeDetailRow(rowIndex, data) {
        const updatedGridData = [...form.values.gridData];
        updatedGridData.splice(rowIndex, 1);
        form.setFieldValue("gridData", updatedGridData);
        gridApi?.updateRowData({ remove: [data] });
    }

    const columns = [

        {
            cellRenderer: DeleteRenderer,
            maxWidth: 80,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
            resizable: true,
        },

        {
            headerName: "Part",
            resizable: true,
            minWidth: 200,
            valueGetter: (params) => {
                return `${params.data?.partSelected?.part_number} ${params.data?.partSelected?.part_number}`
            }
        },
        {
            headerName: "Cutting Tool",
            resizable: true,
            minWidth: 200,
            valueGetter: (params) => {
                return `${params.data?.toolSelected?.ctool_name}`
            }
        },
        {
            headerName: "Tool Life",
            resizable: true,
            minWidth: 200,
            valueGetter: (params) => {
                return `${params.data?.ctool_life}`
            }
        },



    ]



    return (
        <>

            <Grid container spacing={3}>

                <Grid item md={5} sm={5} lg={5}>
                    <DGSelect
                        id="part"
                        label="Part"
                        options={form.values.partOptions}
                        getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
                        value={form.values.partSelected}
                        onChange={(val) => {
                            form.setFieldValue("partSelected", val);
                        }}
                        required
                        error={form.touched.part && Boolean(form.errors.part)}
                    />
                </Grid>

                <Grid item md={5} sm={5} lg={5}>
                    <DGSelect
                        id="tool"
                        label="Cutting Tool"
                        options={form.values.toolOptions}
                        getOptionLabel={(opt) => `${opt?.ctool_name}`}
                        value={form.values.toolSelected}
                        onChange={(val) => {
                            form.setFieldValue("toolSelected", val);
                        }}
                        required
                        error={form.touched.part && Boolean(form.errors.part)}
                    />
                </Grid>

                <Grid item md={2} sm={2} lg={2}>

                    <DGInput
                        id={"ctool_life"}
                        value={form.values.ctool_life}
                        label="Estimated Tool Life"
                        onChange={form.handleChange}
                        required
                        isFormik
                    />
                </Grid>

            </Grid>


            <Grid item md={12} sm={12} lg={12}
                style={{ padding: '20px' }}>
                <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    disabled={!form.dirty}
                    onClick={onAdd}
                >
                    ADD
                </Button>

            </Grid>

            <Grid item md={12} sm={12} lg={12} margin-top="20px" >
                <CommonGrid
                    rows={form.values.gridData}
                    columns={columns}
                    needExport={false}
                    onGridReady={onGridReady}
                />
            </Grid>

            <Footer onSave={form.handleSubmit} />

        </>

    )

}