import React, { useEffect, useState, createContext } from "react";
import { getUserLocal } from "../services/authService";

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const loginUser = (user) => {
    setCurrentUser(user);
  };

  const logoutUser = () => {
    setCurrentUser(null);
    localStorage.clear();
  };

  const updatePlant = (plant)=>
  {
    setCurrentUser({...currentUser,globalPlant:plant})
  }

  useEffect(() => {
    const user = getUserLocal();
    if (user) {
      loginUser(user);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ loginUser, logoutUser, currentUser,updatePlant }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
export { AuthContext };
