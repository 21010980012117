/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React from "react";
import { useEffect, useContext, useState } from "react";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AuthContext } from "../../../context/AuthContextProvider";
import machineService from "../../../services/machineService";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider"
import CommonGrid from "../../../components/CommonGrid"
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { StatusContext } from "../../../context/StatusContextProvider"
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";


export function SpindleForm({
  Footer = () => { },
  closeForm = () => { },
  componentProps = null,

}) {
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [plant, setPlant] = useState([])
  const [machine, setMachine] = useState([])


  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(),
      callback: updateMachineSpindle
    }));
    reloadGridData();
  }, []);

  function reloadGridData() {
    return machineService.getMachineSpindleList(componentProps)
      .then((res) => {
        // console.log("selectedRow", res.data)
        setRows(res.data[0] ?? []);
        setMachine(`${res.data.machine_number ?? ''} ${res.data.machine_name ?? ''}`);
        setPlant(res.data.name)
      });
  }

  function updateMachineSpindle(comments, newId, newStatus, rowId) {
    return machineService.updateMachineSpindle(rowId, { enabled: newStatus });
  }



  const columns = [
    {
      field: "serial_number",
      headerName: "Sl #",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      maxWidth: 100
    },
    {
      headerName: "Spindle",
      field: "spindle_name",
      resizable: true,
      minWidth: 150,
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      //filter: "agNumberColumnFilter",
      // floatingFilter: true,
      // suppressMenu: true,
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              reloadGrid: reloadGridData,
              entityName: params?.data?.spindle_name ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params?.data?.id,
            }))
          }
        />
      ),
    },
    {
      field: "enabled",
      headerName: "Status History",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.id,
                entityName: params?.data?.gauge_detail ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    }
  ]


  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          id="plant"
          label="Plant"
          value={plant}
        />
      </Grid>
      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          id={"number"}
          label="Machine Number"
          value={machine}
        />
      </Grid>


      <Grid md={12} sm={12} lg={12}>
        <CommonGrid
          columns={columns}
          rows={rows}
          needExport={false}
        />
      </Grid>

    </Grid>
  );
}
