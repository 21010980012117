import { Typography as MuiTypography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import { spacing } from "@material-ui/system";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import addressService from "../../services/addressService";
import LightTooltip from "../../components/Tooltip";
import CommonGrid from "../../components/CommonGrid";

import { DGDrawer } from "../../components/DGDrawer";
import { PlantForm } from "./PlantForm";
import { ACTION_TYPE } from "../../components/shared/commonenum";
const Typography = styled(MuiTypography)(spacing);

const AllPlantsList = ({ plants, getAllPlants, isKeyUser = false }) => {
  const [rows, setRows] = useState([]);
  const [addPlantDialog, setAddPlantDialog] = useState(false);
  const [editingPlant, setEditingPlant] = useState();
  const [componentProps, setEditingAddress] = useState();

  useEffect(() => {
    listPlantsToRows();
  }, [plants]);

  const listPlantsToRows = () => {
    let items = [],
      count = 0;
    plants.map((plant) => {
      items.push({
        id: plant.id,
        slno: ++count,
        address:
          plant && plant.address
            ? `${plant.address.address_line_1}, ${plant.address.address_line_2}, ${plant.address.city}, ${plant.address.pincode}, ${plant.address.state}, ${plant.address.country}`
            : "N/A",
        addressId: plant.address_id ? plant.address_id : null,
        name: plant.name ? plant.name : "N/A",
        action: "",
      });
    });
    setRows(items);
  };

  const columns = [
    {
      field: "slno",
      minWidth: 100,
      headerName: "Sl #",
      editable: false,
      hidden: true,
    },
    {
      field: "name",
      headerName: "Plant Name",
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      editable: false,
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    /*     {
          field: "actions",
          headerName: "Actions",
          editable: false,
          cellRenderer: (params) => (
            <>
              <Button
                variant="text"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => handleEditPlant(params.data)}
              >
                Edit
              </Button>
            </>
          ),
        }, */
  ];

  const handleEditPlant = async (data) => {
    // console.log("🚀  -> file: AllPlantsList.js -> line 78 -> handleEdit -> data", data);
    const response = await addressService.getAddress(data.addressId);
    // console.log("Address Response", response.data);
    if (response && response.data) {
      setEditingAddress({
        address_line_1: response.data.address_line_1,
        address_line_2: response.data.address_line_2,
        city: response.data.city,
        pincode: response.data.pincode,
        state: response.data.state,
        country: response.data.country,
      });
      setEditingPlant(data);
      ACTION_TYPE.edit = true;
      setAddPlantDialog(true);
    }
  };

  return (
    <React.Fragment>
      <Card style={{ width: "100%", height: "auto", padding: "1rem" }}>
        <CommonGrid
          rows={rows}
          columns={columns}
          gridheight={130}
          needExport={false}
        /*           ButtonComponent={() => (
                    <>
                      {isKeyUser ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setEditingPlant(null);
                            setAddPlantDialog(true);
                          }}
                        >
                          PLANT
                        </Button>
                      ) : null}
                    </>
                  )} */
        />
      </Card>
      <DGDrawer
        Component={PlantForm}
        title="Add Plants"
        componentProps={{ editingPlant, address: componentProps }}
        needSave
        isOpen={addPlantDialog}
        setOpen={() => {
          setEditingPlant(null);
          setAddPlantDialog(false);
          getAllPlants();
        }}
      />
    </React.Fragment>
  );
};

export default AllPlantsList;
