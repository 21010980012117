import {
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
  } from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
  import React from "react";
  
  export function PartInfo({
    part_info = [{"Maximum Value": "","Minimum Value": "","Range": ""}]
  }) {
    return (
      <div style={{width:"100%"}}>
        <Typography variant="h5" gutterBottom align="center" style={{backgroundColor:indigo[100]}} >
          PROCESS OUTCOME
        </Typography>
        <List>
          <Divider />
          <ListItem>
            <ListItemText primary={"Maximum Value"} />
            <ListItemSecondaryAction>
              <ListItemText
                primary={part_info[0]["Maximum Value"]}
                primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary={"Minimum Value"} />
            <ListItemSecondaryAction>
              <ListItemText
                primary={part_info[0]["Minimum Value"]}
                primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary={"Range"} />
            <ListItemSecondaryAction>
              <ListItemText
                primary={parseFloat(part_info[0]["Range"]??0).toFixed(3)}
                primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </div>
    );
  }
  