import { Snackbar } from "@material-ui/core";
import React, { useContext } from "react";
import { AlertContext } from "../../context/AlertContextProvider";
import MuiAlert from "@material-ui/lab/Alert";

export function DGAlert() {
  const { open, message, type, resetAlert } = useContext(AlertContext);
  function handleClose() {
    resetAlert();
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <MuiAlert onClose={handleClose} severity={type} variant="filled">
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
