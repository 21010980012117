import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { ModalContext } from "../../context/ModalContextProvider";
export function DGWarningModal() {
  const cancelRef = React.useRef();
  const {
    open,
    title,
    subtitle,
    onClose,
    actionButtons,
    resetModal,
  } = useContext(ModalContext);
  useEffect(() => {
    return () => {
      return resetModal();
    };
  }, []);
  // console.log("actionButtons", actionButtons);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose();
          resetModal();
        }}
        isOpen={open}
        isCentered
      >
        <AlertDialogOverlay width={"100%"} height={"100%"} />

        <AlertDialogContent>
          <AlertDialogHeader alignSelf={"flex-start"}>
            {title}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{subtitle}</AlertDialogBody>
          <AlertDialogFooter>
            {actionButtons?.length
              ? actionButtons.map((btn) => (
                <Button
                  onClick={btn?.onClick}
                  colorScheme={btn?.color}
                  ml={3}
                >
                  {btn?.name}
                </Button>
              ))
              : null}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
