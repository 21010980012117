import { Divider, Grid, IconButton, Tooltip, TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import { useFormik } from "formik";
import { DGInput } from "../../../components/shared/DGInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { object, array, ref } from "yup";
import cuttingToolService from "../../../services/cuttingToolService";
import { DGSelect } from "../../../components/shared/DGSelect";
import errorLog from "../../../services/errorLog";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import { blue, brown } from "@material-ui/core/colors";


import { format } from "date-fns";



export function CuttingToolRefurbishView({
    componentProps = null,
    closeForm = () => { },
    setHeaderButtons = () => { },

}) {



    useEffect(() => {
        setHeaderButtons([
            () => (
                <>
                    {componentProps?.report_link && (
                        <Tooltip title="Download Certificate" arrow>
                            <IconButton
                                href={componentProps?.report_link}
                                aria-label="delete"
                            >
                                <CloudDownloadRoundedIcon />
                            </IconButton>
                        </Tooltip>
                    )}


                </>
            ),
        ]);

    }, []);



    return (

        <Grid container spacing={2}>

            <Grid item md={12} sm={12} lg={12}
                style={{
                    paddingBottom: '20px',
                }}>
                <DGInput
                    label="Vendor Name"
                    value={componentProps?.vendor_name}
                />
            </Grid>


            <Grid item md={6} sm={6} lg={6}
                style={{ paddingBottom: "10px" }}>
                <DGInput

                    label="Intiated By"
                    value={componentProps?.intiater_name}
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}
                style={{ paddingBottom: "10px" }}>
                <DGDateTimePicker
                    label="Initiated Date"
                    value={componentProps?.created_date}
                    format="dd/MM/yyyy"
                />
            </Grid>

            <Divider
                variant="middle"
                style={{
                    backgroundColor: blue[900],
                    width: '100%',
                    height: '1px',
                    margin: '3px',

                }}
            />

            <Grid item md={6} sm={6} lg={6}
                style={{ paddingTop: "10px" }}>
                <DGInput

                    label="Refurbished By"
                    value={componentProps?.refurbish_initiated_by}
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}
                style={{ paddingTop: "10px" }}>
                <DGDateTimePicker
                    label="Refurbished Date"
                    value={componentProps?.refurbish_initiated}
                    format="dd/MM/yyyy"
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}
                style={{ paddingBottom: "10px" }}>
                <DGInput

                    label="Refurbished By"
                    value={componentProps?.refurbish_remarks}
                />
            </Grid>

            <Divider
                variant="middle"
                style={{
                    backgroundColor: blue[900],
                    width: '100%',
                    height: '2px',
                    margin: '3px',

                }}
            />


            <Grid item md={6} sm={6} lg={6}
                style={{ paddingTop: "10px" }}>

                <DGInput

                    label="Approved By"
                    value={componentProps?.refurbish_approved_by}
                />
            </Grid>
            <Grid item md={6} sm={6} lg={6}
                style={{ paddingTop: "10px" }}>
                <DGDateTimePicker
                    label="Approved Date"
                    value={componentProps?.refurbish_approved}
                    format="dd/MM/yyyy"
                />
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <DGInput

                    label="Approver Comment"
                    value={componentProps?.approval_remarks}
                />
            </Grid>


        </Grid>
    )



}