import React, { useEffect, useState } from "react";
//import Highcharts from "highcharts";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import highchartsMore from "highcharts/highcharts-more";
import { rgba } from "polished";

import errorLog from "../../../../services/errorLog";


highchartsMore(Highcharts);
highchartsDumbbell(Highcharts);


export function CLGraph({
    specifications = "",
    input_values = "",
    rowData1 = "",
    clgraphtype = "",
    chartTitle = "",
}) {
    const [sampleMin, setSampleMin] = useState(null);
    const [sampleMax, setSampleMax] = useState(null);
    const [sampleRange, setSampleRange] = useState(null);
    const [sampleStdDev, setSampleStdDev] = useState(null);
    const [xAxis, setXAxis] = useState(null);
    const [specification, setSpecification] = useState("");
    const [upperSpecification, setUpperSpecification] = useState("");
    const [lowerSpecification, setLowerSpecification] = useState("");

    const [Ltarget, setLTarget] = useState("");
    const [LUCL, setLUCL] = useState("");
    const [LLCL, setLLCL] = useState("");

    // create 3 useStates for Dtarget, DUCL and DLCL
    const [Dtarget, setDTarget] = useState("");
    const [DUCL, setDUCL] = useState("");
    const [DLCL, setDLCL] = useState("");


    useEffect(() => {

        if (specifications) {
            const [spec, upperSpec, lowerSpec] = specifications.split(" ");
            setSpecification(parseFloat(spec));
            setUpperSpecification(parseFloat(upperSpec));
            setLowerSpecification(parseFloat(lowerSpec));
        }

        if (rowData1) {
            setLTarget(parseFloat(rowData1[0]["target"]));
            setLUCL(parseFloat(rowData1[0]["upper"]));
            setLLCL(parseFloat(rowData1[0]["lower"]));

            setDTarget(parseFloat(rowData1[1]["target"]));
            setDUCL(parseFloat(rowData1[1]["upper"]));
            setDLCL(parseFloat(rowData1[1]["lower"]));


        }


        if (input_values) {
            let minValues = input_values?.map((array) => {
                return Math.min(...array);
            });
            setSampleMin(minValues);

            let maxValues = input_values?.map((array) => {
                return Math.max(...array);
            });
            setSampleMax(maxValues);


            let rangeValues = input_values?.map((array, i) => {
                return maxValues[i] - minValues[i];
            });
            setSampleRange(rangeValues);

            setXAxis(Array.from({ length: input_values.length }, (_, i) => i + 1))

            // set sampleStdDev for each array in input_values
            let stdDevValues = input_values?.map((array) => {
                return Math.sqrt(array.reduce((acc, val) => acc + (val - (array.reduce((acc, val) => acc + val) / array.length)) ** 2, 0) / array.length);
            });
            setSampleStdDev(stdDevValues);

        }
    }, [input_values, specifications, rowData1]);

    const [chartOptions, setChartOptions] = useState({
        rangeSelector: {
            enabled: false
        },


        chart: {
            type: "dumbbell",
            inverted: false,
            marginRight: 20,
            zoomType: false,
            panning: {
                enabled: false,
                type: 'x'
            },

        },

        boost: { enabled: true },


        yAxis: {
            min: null,
            max: null,

            crosshair: false,
            title: {
                text: "Values",
            },
            plotLines: null
        },

        xAxis: {
            crosshair: false,
            ordinal: true,
            min: 1

        },

        credits: {
            enabled: false,
        },

        legend: false,

        title: {
            text: ""
        },

        series: [
            {
                // name: "Product Inspection",
                data: [],
            },
        ],

    });


    useEffect(() => {

        let formattedData = null
        if (sampleMin) {

            formattedData = Array.from({ length: sampleMin.length }, (_, i) => {
                return {
                    low: sampleMin[i],
                    high: sampleMax[i],
                };
            });
        }



        setChartOptions((prev) => {
            let yAxisOptions = {}

            if (specifications) {
                yAxisOptions.max = Math.max(specification + upperSpecification + 0.001, Math.max(...sampleMax), LUCL);
                yAxisOptions.min = Math.min(specification + lowerSpecification - 0.001, Math.min(...sampleMin), LLCL);

                yAxisOptions.plotLines = [
                    {
                        value: specification + upperSpecification,
                        color: "rgba(0, 128, 0, 0.2)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "Specification",
                            align: "right",
                            x: -1,
                        },
                    },
                    {
                        value: specification + lowerSpecification,
                        color: "rgba(0, 128, 0, 0.2)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "Specification",
                            align: "right",
                            x: -1,
                        },
                    },
                    {
                        value: specification,
                        color: "rgba(0, 128, 0, 0.2)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "Target",
                            align: "right",
                            x: -1,
                        },
                    },
                    {
                        value: LLCL,
                        color: "rgba(205, 97, 85, 0.8)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "LCL",
                            align: "left",
                            x: -1,
                        },
                    },
                    {
                        value: LUCL,
                        color: "rgba(205, 97, 85, 0.8)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "UCL",
                            align: "left",
                            x: 1,
                        },
                    },
                    {
                        value: Ltarget,
                        color: "rgba(205, 97, 85, 0.8)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "Target",
                            align: "left",
                            x: 1,
                        },
                    },
                ];
            }
            else {
                yAxisOptions.plotLines = null;
                yAxisOptions.max = null;
                yAxisOptions.min = null
            }


            return {
                ...prev,
                yAxis: yAxisOptions,
                title: { text: chartTitle },
                series: [
                    {
                        data: formattedData,
                        keys: ["x", "low", "high"],
                        showInNavigator: true,
                        marker: {
                            fillColor: "blue",
                            connectorColor: "brown",
                            lowColor: "blue",
                            color: "blue",
                        }
                    },
                ],
            };
            ;


        })

    }, [sampleMin, specification]);


    const [chartOptions2, setChartOptions2] = useState({
        rangeSelector: {
            enabled: false
        },


        chart: {
            type: "dumbbell",
            inverted: false,
            marginRight: 20,
            zoomType: false,
            panning: {
                enabled: false,
                type: 'x'
            },

        },

        boost: { enabled: true },


        yAxis: {
            min: null,
            max: null,

            crosshair: false,
            title: {
                text: "Values",
            },
            plotLines: null
        },

        xAxis: {
            crosshair: false,
            ordinal: true,
            min: 1

        },

        credits: {
            enabled: false,
        },

        legend: false,


        title: {
            text: chartTitle
        },

        series: [
            {
                // name: "Product Inspection",
                data: [],
            },
        ],

    });



    useEffect(() => {

        let formattedData2 = null
        if (sampleStdDev) {

            formattedData2 = Array.from({ length: sampleStdDev.length }, (_, i) => {
                return {

                    low: sampleStdDev[i],
                    high: sampleStdDev[i],
                    //xAxis: xAxis[i],

                };
            });
        }

        //console.log("formattedData2", formattedData2)

        setChartOptions2((prev) => {
            let yAxisOptions = {}
            let radiu_s = null

            if (sampleStdDev) {
                yAxisOptions.max = Math.max(Math.max(...sampleStdDev), DUCL);
                yAxisOptions.min = 0;
                if (sampleStdDev.length > 175) { radiu_s = 1 }
                else if (sampleStdDev.length > 100) { radiu_s = 2 }
                else if (sampleStdDev.length > 50) { radiu_s = 3 }
                else { radiu_s = 4 }


                yAxisOptions.plotLines = [

                    {
                        value: DLCL,
                        color: "rgba(205, 97, 85, 0.8)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "LCL",
                            align: "left",
                            x: -1,
                        },
                    },
                    {
                        value: DUCL,
                        color: "rgba(205, 97, 85, 0.8)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "UCL",
                            align: "left",
                            x: 1,
                        },
                    },
                    {
                        value: Dtarget,
                        color: "rgba(205, 97, 85, 0.8)",
                        dashStyle: "shortdash",
                        width: 2,
                        label: {
                            text: "Target",
                            align: "left",
                            x: 1,
                        },
                    },
                ];
            }
            else {
                yAxisOptions.plotLines = null;
                yAxisOptions.max = null;
                yAxisOptions.min = null
            }

            return {
                ...prev,
                yAxis: yAxisOptions,
                title: { text: chartTitle },
                series: [
                    {
                        data: formattedData2,
                        keys: ["x", "low", "high"],
                        showInNavigator: true,
                        marker: {
                            fillColor: "blue",
                            connectorColor: "brown",
                            lowColor: "blue",
                            color: "blue",
                            radius: radiu_s
                        }
                    },
                ],
            };
            ;


        })

    }, [sampleStdDev]);


    return chartOptions ? (
        <HighchartsReact
            options={clgraphtype === 0 ? chartOptions : chartOptions2}
            highcharts={Highcharts}

        />
    ) : null;


}
