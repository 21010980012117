import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../components/CommonGrid";
import { Button, Grid } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContextProvider";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import { AlertContext } from "../../../context/AlertContextProvider";
import errorLog from "../../../services/errorLog";
import { PlantContext } from "../../../context/PlantContextProvider";

export function DefectClassificationAdd({
    Footer = () => { },
    closeForm = () => { },
    componentProps = null
}) {

    const [rows, setRows] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require('uuid');
    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const { globalPlant } = useContext(PlantContext);



    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function removeDetailRow(index, rowData) {
        let data = rows;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
    }

    const generateBlankRows = (numRows) => {
        return Array.from({ length: numRows }, () => ({}));
    };

    useEffect(() => {
        setRows(generateBlankRows(10));
    }, []);

    function addRow(numRows) {
        const newRows = generateBlankRows(numRows);
        setRows((prevRows) => [...prevRows, ...newRows]);
    }



    function ButtonComponent() {
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        addRow(5);
                    }}
                >
                    Add 5 more Defects
                </Button>

            </>
        );
    }

    function handleSave() {
        var slNO = componentProps.maxID + 1;
        let payload = [];
        gridApi?.forEachNode((node) => {
            if (!node.data.defect_name) {
                return;
            }
            payload.push({
                id: uuidv4(),
                plant_id: globalPlant?.globalPlant?.id,
                client_id: currentUser.client_id,
                created_by: currentUser.id,
                defect_name: node.data.defect_name,
                defect_id: slNO,
            });
            slNO++;
        });
        qualityAnalyticsService.createDefectMaster(payload)
            .then((res) => {
                closeForm()
                updateAlert({
                    open: true,
                    message: "Defect classification added sucessfully.",
                    type: "success",
                });

            })
            .catch((err) => {
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "createDefectMaster",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: "",
                };
                errorLog.createErrorLog(payload)
                updateAlert({
                    open: true,
                    message: "Failed to create Defects.",
                    type: "error",
                });
            });
    }



    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 75,
            minWidth: 76,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },
        {
            field: "serial_number",
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },

        {
            field: "defect_name",
            headerName: "Defects",
            filter: "agTextColumnFilter",
            minWidth: 500,
            editable: true,
        },

    ]





    return (
        <>
            <Grid item md={12} sm={12} lg={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    gridheight={115}
                    onGridReady={onGridReady}
                    needExport={false}
                    ButtonComponent={ButtonComponent}
                />
            </Grid>





            <Footer onSave={handleSave} />



        </>
    )


}