import * as types from "../../constants";

export default function globalReducer(state = {reqCount:0,message:""}, actions) {
  switch (actions.type) {
    case types.STARTSPINNER:
      return {
        ...state,
        reqCount:state.reqCount+1
      };

    case types.STOPSPINNER:
      return {
        ...state,
        reqCount:0,
      };

    default:
      return state;
  }
}
