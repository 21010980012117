import {
  Button as MuiButton,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContextProvider";
import { DGSelect } from "../../../components/shared/DGSelect";
import { useFormik } from "formik";
import { object } from "yup";
import productService from "../../../services/productService";
import { AlertContext } from "../../../context/AlertContextProvider";

const ComponentForm = ({
  closeForm = () => { },
  Footer = () => null,
  componentProps = null,
}) => {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  let form = useFormik({
    initialValues: {
      components: [],
      component: null,
    },
    validationSchema: object({
      component: object().required(),
    }),
    onSubmit: onSave,
  });
  useEffect(() => {
    getComponentList();
  }, [currentUser]);

  function getComponentList() {
    productService
      .getComponentList(componentProps?.id, currentUser?.client_id)
      .then((res) => {
        form.setFieldValue("components", res?.data ?? []);
      });
  }

  function onSave(values) {
    productService
      .addComponentToPart({
        parent_part_id: componentProps?.id,
        component_part_id: form.values.component?.id,
      })
      .then((res) => {
        closeForm();
        updateAlert({
          open: true,
          message: "Component Added SuccessFully",
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          open: true,
          message: "Failed to Add Component",
          type: "error",
        });
      });
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          label="Products"
          options={form.values.components}
          onChange={(val) => form.setFieldValue("component", val)}
          value={form.values.component}
          getOptionLabel={(opt) => `${opt?.part_number}-${opt?.part_name}`}
          error={form.touched.component && form.errors.component}
        />
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
};
export default ComponentForm;
// <React.Fragment>
//     {products && (<Dialog
//       open={selectProductDialog}
//       onClose={() => setSelectProductDialog(false)}
//       aria-labelledby="form-dialog-title"
//       disableBackdropClick={true}
//       fullWidth
//     >
//       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#0d51ca', color: '#fff' }}>
//         <DialogTitle id="form-dialog-title">
//           Select Products
//         </DialogTitle>
//         <div>
//           <Button onClick={() => setSelectProductDialog(false)}>
//             <CloseRoundedIcon style={{ fill: '#fff' }} />
//           </Button>
//         </div>
//       </div>
//       <DialogContent pb={4}>
//         <DialogContentText>
//           Please select any one product
//         </DialogContentText>
//         <Spacer my={6} />
//         <Formik initialValues={initialValues} onSubmit={handleSubmit}>
//           {({
//             errors,
//             handleBlur,
//             handleChange,
//             handleSubmit,
//             isSubmitting,
//             touched,
//             values,
//             status,
//             setFieldValue,
//           }) => (
//             <Card mb={6}>
//               {status && status.sent && (
//                 <Alert severity="success" my={3}>
//                   Your data has been submitted successfully!
//                 </Alert>
//               )}

//               {isSubmitting ? (
//                 <Box display="flex" justifyContent="center" my={6}>
//                   <CircularProgress />
//                 </Box>
//               ) : (
//                 <form onSubmit={handleSubmit}>
//                   <Spacer my={6} />
//                   <Grid container spacing={6}>
//                     <Grid item md={12}>
//                       <Autocomplete
//                         multiple
//                         id="id"
//                         options={products}
//                         disableCloseOnSelect
//                         getOptionLabel={(option) => option.part_number + ' (' + option.part_name + ')'}
//                         style={{ width: "100%" }}
//                         renderInput={(params) => (
//                           <Field
//                             component={TextField}
//                             {...params}
//                             name="id"
//                             label="Products"
//                             variant="outlined"
//                             fullWidth
//                           />
//                         )}
//                         onChange={(e, value) => {
//                           setFieldValue("selectedValues", value);
//                         }}
//                       />
//                     </Grid>
//                   </Grid>
//                   <Divider my={6} />
//                   <div className="d-flex justify-content-end">
//                     <Button
//                       type="button"
//                       variant="outlined"
//                       color="primary"
//                       mr={3}
//                       mb={3}
//                       position="right"
//                       onClick={() => setSelectProductDialog(false)}
//                     >
//                       Cancel
//                     </Button>
//                     <Button
//                       type="submit"
//                       variant="contained"
//                       color="primary"
//                       mb={3}
//                       position="right"
//                     >
//                       Add
//                     </Button>
//                   </div>
//                 </form>
//               )}
//             </Card>
//           )}
//         </Formik>
//       </DialogContent>
//     </Dialog>)}
//   </React.Fragment>
