import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import React, { useContext, useEffect, useState } from "react";
import addressService from "../../services/addressService";
import AddCustomerDialog from "./AddCustomerDialog";
import EditIcon from "@material-ui/icons/Edit";
import LightTooltip from "../../components/Tooltip";
import { StatusContext } from "../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import ActiveInActive from "../../components/ActiveInActive";
import customerService from "../../services/customerService";
import { AuthContext } from "../../context/AuthContextProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider";
import CommonGrid from "../../components/CommonGrid";
import StatusFilter from "../../components/shared/StatusFilter";

const AllCustomersList = ({
  customers,
  getCustomers,
  ButtonComponent = () => null,
}) => {
  const [rows, setRows] = useState([]);
  const [addCustomerDialog, setAddCustomerDialog] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState();
  const [editingAddress, setEditingAddress] = useState();
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const { currentUser } = useContext(AuthContext);
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      callback: changeStatus,
      reloadGrid: reloadGridData,
    }));
  }, []);

  function changeStatus(dummy1, dummy2, newStatus, rowId) {
    return customerService.updateCustomer(rowId, { enabled: newStatus });
  }
  function reloadGridData() {
    return customerService
      .getCustomers(currentUser.client_id, true)
      .then((res) => {
        setRows(res?.data?.data ?? []);
      });
  }
  useEffect(() => {
    if (customers) {
      listCustomersToRows(customers);
    }
  }, [customers]);

  useEffect(() => {

    setHeaderPageTitle(`Customer Management`);

  }, []);

  const listCustomersToRows = async (customers) => {
    setRows(customers);
  };

  const columns = [
    {
      field: "company_name",
      headerName: "Customer Name",
      headerAlign: "center",
      flex: 1,
      editable: false,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      flex: 1,
      editable: false,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        (params.data.address.address_line_1
          ? params.data.address.address_line_1 + ", "
          : "") +
        (params.data.address.address_line_2
          ? params.data.address.address_line_2 + ", "
          : "") +
        (params.data.address.city ? params.data.address.city : ""),
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "GST",
      headerName: "GST Number",
      headerAlign: "center",
      flex: 1,
      editable: false,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      renderCell: (params) => (
        <LightTooltip title={params.value}>
          <span>{params.value}</span>
        </LightTooltip>
      ),
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.company_name ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.company_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  const handleEdit = async (data) => {
    const response = await addressService.getAddress(data.addressId);
    if (response?.data) {
      setEditingAddress({
        address_line_1: response.data.address_line_1,
        address_line_2: response.data.address_line_2,
        city: response.data.city,
        pincode: response.data.pincode,
        state: response.data.state,
        country: response.data.country,
      });
      setEditingCustomer(data);
      setAddCustomerDialog(true);
    }
  };

  return (
    <React.Fragment>
      {rows && columns && (
        <CommonGrid
          columns={columns}
          rows={rows}
          ButtonComponent={ButtonComponent}
          gridheight={150}
        />
      )}
      {addCustomerDialog ? (
        <AddCustomerDialog
          addCustomerDialog={addCustomerDialog}
          setAddCustomerDialog={setAddCustomerDialog}
          onSuccess={(e) => {
            if (e) {
              getCustomers();
            }
          }}
          isEdit={true}
          editingCustomer={editingCustomer}
          editingAddress={editingAddress}
        />
      ) : null}
    </React.Fragment>
  );
};

export default AllCustomersList;
