import http from "./httpService";

const createCustomer = (data) => {
  return http.post("/customers", data);
};

const updateCustomer = (id, data) => {
  return http.patch(`/customers/${id}`, data);
};

const getCustomers = async (clientId) => {
  return http.get('/customers', {
    params: {
      client_id: `${clientId}`,
      $limit: 100,
    }
  });
};

const getCustomerById = async (customerId) => {
  return http.get(`/customers/${customerId}`);
};

const getCustomerProduct = async (customer_id) => {
  return http.get(`/customer-productlist`, {
    params: {
      customer_id: customer_id
    }
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createCustomer,
  getCustomers,
  updateCustomer,
  getCustomerById,
  getCustomerProduct
};
