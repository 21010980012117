
import { useContext, useState, useEffect } from "react";
import {
    Button,
    Grid,
    Paper,
} from "@material-ui/core";

import { format, max, min } from 'date-fns'
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";

import AddIcon from "@material-ui/icons/Add";
import productionMgmtService from "../../../services/productionMgmtService";
import qualityAnalyticsService from "../../../services/qualityAnalyticsService";
import { DefectClassificationAdd } from "./defectClassificationAdd";
import { DefectIncidentProduction } from "./defectIncidentProductionAdd";


export default function DefectClassificationList() {

    const { setStatus } = useContext(StatusContext);
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [entryFormOpen, setEntryFormOpen] = useState(false);
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const { globalPlant } = useContext(PlantContext);
    const [maxID, setMaxID] = useState(0);
    const [plantShowFlag, setPlantShowFlag] = useState(false);
    const [qincidentForm, setQIncidentForm] = useState(false);


    useEffect(() => {

        setHeaderPageTitle(`Production Record`)

    }, []);


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
        }));
        reloadGridData();

    }, []);


    function reloadGridData() {
        return qualityAnalyticsService.getDefectMasterTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((res) => {
                const data = res?.data ?? []
                setRows(data ?? []);
                setMaxID(data.reduce((max, item) => item.defect_id > max ? item.defect_id : max, data[0].defect_id))
            });
    }

    useEffect(() => {
        if (globalPlant?.globalPlant?.id === currentUser?.client_id) {
            setPlantShowFlag(true)
        }
        else { setPlantShowFlag(false) }
        reloadGridData()

    }, [globalPlant]);


    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return qualityAnalyticsService.updateDefectMasterTable(rowId, { enabled: newStatus });
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        {
            field: "defect_id",
            headerName: "Defect Number",
            minWidth: 75,
            sorting: true,
        },
        ...(plantShowFlag ? [{
            headerName: "Plant",
            field: "plant_name",
            width: 200,
        }] : [])
        ,
        {
            field: "defect_name",
            headerName: "Defect Name",
            minWidth: 400,
            resizable: true,
            sorting: true,
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive

                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.defect_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        disabled={params?.data?.edit_flag !== 1}
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.defect_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        }
    ]


    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setEntryFormOpen(1);
                    }}
                >
                    Add Defects Groups
                </Button>

            </>
        );
    }

    return (

        <>
            <Grid item md={12} sm={12} lg={12}>

                <CommonGrid
                    ButtonComponent={ButtonComponent}
                    rows={rows}
                    columns={columns}
                    gridheight={115}

                />

            </Grid>



            <DGDrawer
                isOpen={entryFormOpen}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()

                }}
                Component={DefectClassificationAdd}
                title='Add Defect classification'
                drawerWidth={800}
                needSave
                componentProps={{ maxID: maxID }}

            />

            <DGDrawer
                isOpen={qincidentForm}
                setOpen={() => {
                    setEntryFormOpen(false)
                    reloadGridData()

                }}
                Component={DefectIncidentProduction}
                title='Add Defect classification'
                drawerWidth={1000}
                needSave
                componentProps={{ maxID: maxID }}

            />

        </>
    )
}