import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import { AppBar, Button, Divider, Toolbar } from "@material-ui/core";
import React, { useEffect } from "react";
import { ACTION_TYPE } from "./commonenum";

export function DGFullScreenDrawer({
  isOpen = false,
  setOpen = () => {},
  Component = () => null,
  title = "",
  componentProps = {},
  needSave = false,
  onCancel = () => {
    setOpen(false);
  },
  size = "full",
}) {
  useEffect(() => {
    return () => {
      ACTION_TYPE.add = false;
      ACTION_TYPE.edit = false;
      ACTION_TYPE.view = false;
    };
  }, []);
  return (
    <Drawer
      isFullHeight
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      size={size}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody>
          {isOpen && (
            <Component
              componentProps={componentProps}
              closeForm={(stat, data) => setOpen(false, data)}
              Footer={({ onSave }) => (
                <AppBar position="fixed" style={{ top: "auto", bottom: 0 }}>
                  <Divider />
                  <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button
                      style={{ margin: 4 }}
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => onCancel()}
                    >
                      CANCEL
                    </Button>
                    {needSave ? (
                      <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onSave}
                      >
                        SAVE
                      </Button>
                    ) : null}
                  </Toolbar>
                </AppBar>
              )}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
