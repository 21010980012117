import React, { useContext, useEffect, useState } from 'react'
import CommonGrid from '../../components/CommonGrid';
import { AuthContext } from '../../context/AuthContextProvider';
import { Grid, Button } from "@material-ui/core";
import { StatusHistoryContext } from "../../context/StatusHistoryProvider";
import { StatusContext } from "../../context/StatusContextProvider";
import piService from '../../services/piService';
import { PlantContext } from '../../context/PlantContextProvider';
import Loader from '../../components/Loader';

export function GaugeCalibrationList({
    componentProps = null,
}) {

    const [rows, setRows] = useState([]);
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { setStatus } = useContext(StatusContext);
    const { globalPlant } = useContext(PlantContext);
    const [dataLoaded, setDataLoaded] = useState(false);


    function reloadGridData1() {
        piService.getCalibOverdue(
            currentUser.client_id,
            21,
            globalPlant.globalPlant.id
        )
            .then((res) => {
                //   console.log(res)
                setRows1(res?.data ?? []);
            });
    }

    function reloadGridData2() {
        piService.getCalibOverdue(
            currentUser.client_id,
            22,
            globalPlant.globalPlant.id
        )
            .then((res) => {
                //    console.log(res)
                setRows2(res?.data ?? []);
            });
    }

    useEffect(() => {
        reloadGridData1();
        reloadGridData2()
        setDataLoaded(true)
    }, [])

    function addstatus() {
        const joined_array = [...rows1, ...rows2];
        // console.log(joined_array)
        setRows(joined_array);
        setDataLoaded(true)
    }

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => addstatus
        }));
        addstatus()
    }, [rows1]);





    const columns = [{
        field: "serial_number",
        headerName: "SI No",
        valueGetter: "node.rowIndex + 1",
        maxWidth: 75,
        sorting: true,
    },
    {
        field: "serial_number",
        headerName: "Gauge",
        minWidth: 200,
        resizable: true,
        sorting: true,
    },
    {
        field: "calib_agency",
        headerName: "Calibration Agency",
        minWidth: 100,
        resizable: true,
        sorting: true,
    },

    {
        field: "family_name",
        headerName: "Gauge Name",
        sortable: true,
        minWidth: 200,
        floatingFilter: true,
        resizable: true,
        valueGetter: (params) => {
            return params?.data?.family_number + " - " + params?.data?.family_name;
        },
    },
    ]

    if (!dataLoaded) {
        return <Loader />;
    }

    return (

        <Grid item md={12} sm={12} lg={12}>

            <CommonGrid
                rows={rows}
                columns={columns}
                gridheight={100}
                tableName={"Gauge under Calibration"}

            />
        </Grid>

    )

}

