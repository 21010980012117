import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import productionMgmtService from "../../../services/productionMgmtService";
import { AlertContext } from "../../../context/AlertContextProvider";




export default function ProductionValueStreamAdd({
    Footer = () => { },
    closeForm = () => { },

}) {

    const [rows, setRows] = useState([])
    const [gridApi, setGridApi] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { v4: uuidv4 } = require('uuid');
    const { updateAlert } = useContext(AlertContext);



    function onGridReady(params) {
        setGridApi(params?.api);
        params.api.sizeColumnsToFit();
    }

    const generateBlankRows = (numRows) => {
        return Array.from({ length: numRows }, () => ({}));
    };

    useEffect(() => {
        setRows(generateBlankRows(10));
    }, []);

    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 75,
            minWidth: 76,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },
        {
            field: "serial_number",
            headerName: "#",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },
        {
            field: "value_stream",
            headerName: "Value Stream",
            filter: "agTextColumnFilter",
            minWidth: 500,
            editable: true,
        },

    ]

    function handleSave() {
        let payload = [];
        gridApi?.forEachNode((node) => {
            if (!node.data.value_stream) {
                return;
            }
            payload.push({
                id: uuidv4(),
                client_id: currentUser.client_id,
                plant_id: globalPlant?.globalPlant?.id,
                created_by: currentUser.id,
                valueStream_name: node.data.value_stream,
            });
        });
        //    console.log(payload);
        productionMgmtService.createVSRecord(payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    message: "Value Stream Created SuccessFully.",
                    open: true,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function removeDetailRow(index, rowData) {
        let data = rows;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
    }


    return (

        <>


            <Grid item md={12} sm={12} lg={12}>
                <CommonGrid
                    columns={columns}
                    rows={rows}
                    gridheight={115}
                    onGridReady={onGridReady}
                    needExport={false}
                />
            </Grid>




            <Footer onSave={handleSave} />

        </>
    )


};