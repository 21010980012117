import { Box, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { ProcessParameter } from "./Processparamter/ProcessParameter";
import { ProductDocument } from "./Productdocument/ProductDocument"
import { GaugeListing } from "./Gaugeproduct/GaugeListing"
import { MachineListing } from "./Machineproduct/MachineListing"
import { useParams } from "react-router-dom";


export function ResourceTab({ }) {
  let { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const buttons = ["Documents", "Gauges", "Machine", "Process Parameter",];

  return (
    <Paper square>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setSelectedTab(val)}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {buttons.map((btn, index) => (
          <StyledTab label={btn} value={index} />
        ))}
      </Tabs>
      <Box m={2}>
        {selectedTab === 0 ? <ProductDocument partId={id} /> : null}
        {selectedTab === 1 ? <GaugeListing partId={id} /> : null}
        {selectedTab === 2 ? <MachineListing partId={id} /> : null}
        {selectedTab === 3 ? <ProcessParameter partId={id} /> : null}


      </Box>
    </Paper>
  );
}

const StyledTab = withStyles((theme) => ({
  wrapper: {
    textTransform: "none",
  },
  root: {
    textTransform: "none",
  },
}))((props) => <Tab disableRipple {...props} />);
