import {
  Grid,
  IconButton,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RadioButtonUncheckedRoundedIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import React from "react";
import { green, grey, red } from "@material-ui/core/colors";
import { Tooltip } from "@chakra-ui/react";
const useStyle = makeStyles({
  root: {
    padding: 5,
  },
});
export function Rules({ values = [], keys = [] }) {
  const classes = useStyle();
  return (
    <div>
      <Grid container>
        {values?.map((exp, i) => (
          <>
            {exp === 1 || exp === "1" ? (
              <Grid item>
                <Tooltip label={keys[i] ? keys[i] : ""} hasArrow arrowSize={15}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    {" "}
                    <CheckCircleIcon style={{ color: green[400] }} />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
            {exp === 0 || exp === "0" || exp === null ? (
              <Grid item>
                <Tooltip label={keys[i] ? keys[i] : ""} arrowSize={15} hasArrow>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CancelIcon style={{ color: red[400] }} />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
            {exp === -1 ? (
              <Grid item>
                <Tooltip label={keys[i] ? keys[i] : ""} arrowSize={15} hasArrow>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <RadioButtonUncheckedRoundedIcon
                      style={{ color: grey[400] }}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : null}
          </>
        ))}
      </Grid>
    </div>
  );
}
