import React from "react";
import {
  Card,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  Paper,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import ProductData from "../dataSet/productData.json";

const CardForInfo = ({ title, part, gauge, studyData }) => {
  return (
    <React.Fragment>
      <Paper
        variant="outlined"
        square
        elevation={0}
        style={{ width: "100%", marginRight: 16 }}
      >
        <Typography
          variant="h6"
          style={{ padding: 8 }}
          className="
          bg-light"
        >
          {title}
        </Typography>
        {part && gauge ? (
          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Part Name</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{part.part_name}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Part Number</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{part.part_number}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Gauge Make</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{gauge.make}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Gauge Number</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{gauge.number}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="left" style={{ borderBottom: "none" }}>
                  <Typography color="initial">Gauge Least Count</Typography>
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <Typography component="h5" color="textPrimary">
                    <b>{gauge.least_count}</b>
                  </Typography>
                </TableCell>
              </TableRow>

              {studyData.master_value ? (
                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Master Value</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>{studyData.master_value}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        ) : null}
      </Paper>
    </React.Fragment>
  );
};

export default CardForInfo;
