import http from "./httpService";

const createPlant = (data) => {
    return http.post("/plants", data);
};

const updatePlant = (id, data) => {
    return http.patch(`/plants/${id}`, data);
};

const getPlants = async (clientId) => {
    return await http.get("/plants", {
        params: {
            client_id: `${clientId}`,
        },
    });
};


const getPlantById = async (plantId) => {
    return await http.get(`/plants/${plantId}`);
};

const getDefaultPlant = (user_id) => {
    return http.get("/plant-default", {
        params: {
            user_id: user_id,
        },
    });
};

const getPlantDetails = async (id) => {
    return await http.get(`/plants/${id}`).then(async (response1) => {
        return await http
            .get(`/address/${response1.data.address_id}`)
            .then(async (response2) => {
                if (response1.data && response2.data) {
                    return {
                        id: response1.data.id,
                        name: response1.data.name,
                        plantAddressId: response2.data.id,
                        plantAddress: response2.data.address_line_1 +
                            " " +
                            response2.data.address_line_2 +
                            ", " +
                            response2.data.city +
                            " " +
                            response2.data.pincode +
                            ", " +
                            response2.data.state +
                            ", " +
                            response2.data.country,
                    };
                }
            });
    });
};

const getPlantlist = async (client_id, list_type) => {
    return http.get('/plant-list', {
        params: {
            client_id: client_id,
            list_type: list_type
        }
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createPlant,
    updatePlant,
    getPlants,
    getPlantDetails,
    getPlantlist,
    getDefaultPlant,
    getPlantById
};