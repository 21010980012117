import { Grid as MuiGrid } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import "../../vendor/roundedBarCharts";
import AllPlantsContainer from "./AllPlantsContainer";
import CompanyDetails from "./CompanyDetails";

const Grid = styled(MuiGrid)(spacing);

const Settings = () => {
  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <CompanyDetails />
        </Grid>
        <Grid item xs={12} lg={8} xl={9}>
          <AllPlantsContainer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Settings;
