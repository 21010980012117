import { Grid, IconButton, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContextProvider";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { MainDashboard } from "../overview/dashboard/mainDashboard";
import { PITrendDetail } from "../productionInspection/product-inspection/Trends/PITrendDetail"
import productionInspection from "../../services/productionInspection";
import digitalgembalogo from "../../assets/images/digitalGembaLogo.png"


var Carousel = require('react-responsive-carousel').Carousel; // requires a loader

export default function Gemba2() {
  const { currentUser } = useContext(AuthContext);
  const [selectedcard, setSelectedCard] = useState(-1);
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [delayIntervel, setDelayIntervel] = useState(10000)



  const onChange = (index, item) => {
    // console.log(index);
    if (index === 1) {
      setDelayIntervel(25000)


    } else {
      setDelayIntervel(10000)
      reloadgridData()

    }
  }
  const onClickItem = (index, item) => {
    // console.log(index);
  }
  const onClickThumb = (index, item) => {
    // console.log(index);
  }




  useEffect(() => {
    reloadgridData();

  }, []);

  function reloadgridData() {
    productionInspection
      .getPITrendList2({
        client_id: currentUser?.client_id,
        plant_id: currentUser?.default_plant

      })
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }


  return (
    <>
      <Grid container md={12} direction="row">
        <Grid item md={2} sm={6} lg={2} style={{ textAlign: "left" }}>
          <img
            src={currentUser && currentUser.logo ? currentUser.logo : ""}
            style={{ height: 60, display: "inline-block" }}
          />
        </Grid>


        <Grid item md={8} sm={6} lg={6} direction="row"
          style={{ textAlign: "center", margin: 'auto' }}>
          <Typography style={{ color: "#616161" }} align="center" variant="h3">
            {currentUser?.business_name}
          </Typography>
        </Grid>

        <Grid item md={2} sm={6} lg={2} style={{ textAlign: "right" }}>
          <img
            src={digitalgembalogo}
            style={{ height: 40, display: "inline-block" }}
          // alt="logo"
          //margin="auto"
          />
        </Grid>
      </Grid>



      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        autoPlay={true}
        showArrows={true}
        onChange={onChange}
        onClickItem={onClickItem}
        onClickThumb={onClickThumb}
        interval={delayIntervel}
      >

        <div>
          <MainDashboard />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <PITrendDetail
            componentProps={rows}
          />
        </div>

      </Carousel>
    </>
  );
}
