import {
    Box,
    Paper,
    Tab,
    Tabs,
    withStyles,
} from "@material-ui/core";
import { PageTitleContext } from "../../../context/PageTitleContextProvider"
import { useContext, useEffect, useState } from "react";
import ProductionValueStreamList from "./production_valueStreamList"
import ProductionValueStreamMachine from "./production_valueStreamMachine"
import ProductionValueStreamOperator from "./production_valueStreamOperators"


export default function ProductionValueStream() {

    const [selectedTab, setSelectedTab] = useState(0);
    const { setHeaderPageTitle } = useContext(PageTitleContext);

    const buttons = [
        "Value Stream - Machines",
        "Value Stream - Operators",
        "Value Stream List",
    ];

    useEffect(() => {
        setHeaderPageTitle(`Value Stream Mapping`);
    }, []);


    return (
        <Paper square>
            <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, val) => setSelectedTab(val)}
                aria-label="disabled tabs example"
            >
                {buttons.map((btn, index) => (
                    <StyledTab label={btn} value={index} />
                ))}
            </Tabs>
            <Box m={2}>
                {selectedTab === 0 ? <ProductionValueStreamMachine /> : null}
                {selectedTab === 1 ? <ProductionValueStreamOperator /> : null}
                {selectedTab === 2 ? <ProductionValueStreamList /> : null}





            </Box>
        </Paper>
    );
}

const StyledTab = withStyles((theme) => ({
    wrapper: {
        textTransform: "none",
    },
    root: {
        textTransform: "none",
    },
}))((props) => <Tab disableRipple {...props} />);