/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AuthContext } from "../../../context/AuthContextProvider";
import dateService from "../../../services/dateService";
import gaugeService from "../../../services/gaugeService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AlertContext } from "../../../context/AlertContextProvider";

export function GaugeFamilyForm({
  componentProps = {},
  onFormSave = () => {},
  ref = null,
  Footer = () => {},
  closeForm = () => {},
}) {
  const { v4: uuidv4 } = require("uuid");
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const form = useFormik({
    initialValues: {
      number: "",
      name: "",
      make: "",
      type: null,
      least_count: null,
      range: "",
      comments: "",
      lead_time: 0,
      gaugeTypes: [],
      inspection_guidelines: "",
    },
    validationSchema: Yup.object({
      number: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
      make: Yup.string().required(),
      type: Yup.object().shape({
        id: Yup.string().required(),
        type_name: Yup.string().required(),
      }),
      lead_time: Yup.number().required(),
    }),
    onSubmit: upsertFormData,
  });

  useEffect(() => {
    gaugeService.getGaugeType().then((response) => {
      form.setFieldValue("gaugeTypes", response?.data?.data ?? []);
    });
    if (componentProps) {
      let data = componentProps;
      form.setValues({
        number: data?.number,
        name: data?.name,
        make: data?.make,
        type: data?.guage_type,
        least_count: data?.least_count,
        range: data?.range,
        comments: data?.comments,
        lead_time: data?.lead_time,
        inspection_guidelines: data?.inspection_guidelines,
      });
    }
  }, []);

  function upsertFormData(values) {
    if (form.isValid) {
      const payload = {
        name: values?.name,
        number: values?.number,
        make: values?.make,
        type_id: values.type?.id,
        least_count:
          values.least_count || values.least_count === 0
            ? values.least_count
            : null,
        range: values.range,
        comments: values.comments,
        guage_status: 1,
        client_id: currentUser.client_id,
        lead_time: values.lead_time,
        created_at: dateService.getDateInSqlFormat(new Date()),
        inspection_guidelines: values?.inspection_guidelines,
      };
      if (ACTION_TYPE.add) {
        payload["id"] = uuidv4();
        gaugeService
          .createGauge(payload)
          .then((res) => {
            closeForm();
            updateAlert({
              open: true,
              message: "Gauge Created SuccessFully",
              type: "success",
            });
          })
          .catch((err) => {
            updateAlert({
              open: true,
              message: "Failed to Create Gauge",
              type: "error",
            });
          });
      } else if (ACTION_TYPE.edit) {
        payload["id"] = componentProps?.id;
        gaugeService
          .updateGauge(componentProps?.id, payload)
          .then((res) => {
            closeForm();
            updateAlert({
              open: true,
              message: "Gauge Updated SuccessFully",
              type: "success",
            });
          })
          .catch((err) => {
            updateAlert({
              open: true,
              message: "Failed to Update Gauge",
              type: "error",
            });
          });
      }
    }
  }

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 10 }}>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            label="Gauge Number"
            id="number"
            value={form.values.number}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.number && Boolean(form.errors.number)}
            isFormik
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            label="Gauge Name"
            id="name"
            value={form.values.name}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.name && Boolean(form.errors.name)}
            isFormik
            required
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            label="Gauge Make"
            id="make"
            value={form.values.make}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.make && Boolean(form.errors.make)}
            isFormik
            required
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGSelect
            id="type"
            label="Type"
            options={form.values.gaugeTypes}
            getOptionLabel={(opt) => opt?.type_name}
            value={form.values.type}
            onChange={(val) => form.setFieldValue("type", val)}
            error={form.touched.type && Boolean(form.errors.type)}
            isFormik
            required
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            id="inspection_guidelines"
            label="Calibration Guidelines"
            value={form.values.inspection_guidelines}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={
              form.touched.inspection_guidelines &&
              Boolean(form.errors.inspection_guidelines)
            }
            isFormik
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            label="Least Count"
            id="least_count"
            value={form.values.least_count}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.least_count && Boolean(form.errors.least_count)}
            type="number"
            isFormik
          />
        </Grid>
        <Grid item md={6} sm={6} lg={6}>
          <DGInput
            label="Range"
            id="range"
            value={form.values.range}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.range && Boolean(form.errors.range)}
            isFormik
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            label="Lead Time(number of days)"
            id="lead_time"
            value={form.values.lead_time}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.lead_time && Boolean(form.errors.lead_time)}
            type="number"
            required
            isFormik
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12}>
          <DGInput
            label="Comments"
            id="comments"
            value={form.values.comments}
            actionType={ACTION_TYPE}
            onChange={form.handleChange}
            error={form.touched.comments && Boolean(form.errors.comments)}
            isFormik
          />
        </Grid>
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </>
  );
}
