import { Grid } from "@material-ui/core";
import React from "react";
import { TenantInfo } from "./tenantInfo";
import { MainDashboard } from "./mainDashboard"


export function DashBoard() {
  return (
    <Grid container style={{ backgroundColor: "#fff", padding: 0, margin: 0 }}>
      <Grid item md={12} sm={12} lg={12}>
        <TenantInfo />
      </Grid>

      <MainDashboard />

    </Grid>
  );
}
