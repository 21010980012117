import {
  Box,
  Button as MuiButton,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import {
  CloudUpload as MuiCloudUpload,
  PauseCircleOutlineTwoTone,
} from "@material-ui/icons";
import { Alert as MuiAlert, Autocomplete } from "@material-ui/lab";
import Checkbox from "@material-ui/core/Checkbox";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { spacing } from "@material-ui/system";
import { useForm, Controller } from "react-hook-form";
import { Field, Formik } from "formik";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { AuthContext } from "../../context/AuthContextProvider";
import customerService from "../../services/customerService";
import plantService from "../../services/plantService";
import productService from "../../services/productService";
import csrTemplateService from "../../services/csrTemplateService";
import uploadService from "../../services/uploadService";
import drawingService from "../../services/drawingService";
import changeManagementService from "../../services/changeManagementService";
import dateService from "../../services/dateService";

const AddProductDialog = ({
  addProductDialog,
  setAddProductDialog,
  onSuccess,
  isEdit,
  editingProduct,
}) => {
  const [customerDelivery, setCustomerDelivery] = useState();
  const [rawMaterial, setRawMaterial] = useState(false);
  const [specialProcess, setSpecialProcess] = useState(false);
  const { v4: uuidv4 } = require("uuid");
  const Divider = styled(MuiDivider)(spacing);
  const Button = styled(MuiButton)(spacing);
  const Alert = styled(MuiAlert)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  const [customers, setCustomers] = useState();
  const [plants, setPlants] = useState();
  const [fileUpload, setFileUpload] = useState();
  const [csrTemplates, setCsrTemplates] = useState();
  const Spacer = styled.div(spacing);
  const CloudUpload = styled(MuiCloudUpload)(spacing);
  const { currentUser } = useContext(AuthContext);

  // ("editingProduct", editingProduct);

  const timeOut = (time) => new Promise((res) => setTimeout(res, time));

  // let initialValues = null;

  let initialValues = {
    partNumber: editingProduct ? editingProduct.part_number : "",
    partName: editingProduct ? editingProduct.part_name : "",
    customerId: editingProduct ? editingProduct.customer_id : "",
    plantId: editingProduct ? editingProduct.plant_id : "",
    evaluation_guidelines: editingProduct
      ? editingProduct.evaluation_guidelines
      : "",
    changeNumber: editingProduct ? editingProduct.change_number : "",
  };

  // useEffect(() => {
  //   console.log("AddProductDialog", customers, plants, csrTemplates);
  //   if (customers && plants && csrTemplates) {
  //     // populate init values

  //   }
  // }, [initialValues, setInitialValues]);

  const validationSchema = Yup.object().shape({
    partNumber: Yup.string().required("Mandatory Field"),
    partName: Yup.string().required("Mandatory Field"),
    evaluation_guidelines: Yup.string().required("Required"),
    customerId: Yup.string().required("Required"),
    plantId: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (editingProduct) {
      // console.log("customer delivery", editingProduct.customer_delivery);
      editingProduct.customer_delivery && setCustomerDelivery(true);
      setSpecialProcess(editingProduct.special_process === "1");
      setRawMaterial(editingProduct.raw_material === "1");
    }
  }, [editingProduct]);

  useEffect(() => {
    getAllCustomers();
    getAllPlants();
    getCsrTemplates();
  }, []);

  const getCsrTemplates = async () => {
    try {
      const response = await csrTemplateService.getCsrTemplates(
        currentUser.client_id
      );
      if (response.data && response.data.data) {
        setCsrTemplates(response.data.data);
      }
    } catch (error) {
      // console.log("🚀  -> file: AddProductDialog.js -> line 58 -> getCsrTemplates -> error", error);
    }
  };

  const getAllCustomers = async () => {
    try {
      const response = await customerService.getCustomers(
        currentUser.client_id
      );
      if (response.data && response.data.data) {
        setCustomers(response.data.data);
      }
    } catch (error) {
      // console.log("🚀  -> file: AddProductDialog.js -> line 58 -> getAllCustomers -> error", error);
    }
  };

  const getAllPlants = async () => {
    try {
      const response = await plantService.getPlants(currentUser.client_id);
      if (response.data && response.data.data) {
        setPlants(response.data.data);
        // console.log("plants", response.data.data)
      }
    } catch (error) {
      // console.log("🚀  -> file: AddProductDialog.js -> line 69 -> getAllPlants -> error", error);
    }
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await timeOut(1500);
      setStatus({ sent: true });
      setSubmitting(false);
      // console.log("🚀  -> file: products-page.js -> line 90 -> ProductsPage -> values", values);
      if (values) {
        if (isEdit && editingProduct) {
          const obj1 = {
            part_number: values.partNumber,
            part_name: values.partName,
            client_id: currentUser.client_id,
            customer_id: values.customerId ? values.customerId : null,
            plant_id: values.plantId ? values.plantId : null,
            evaluation_guidelines: values.evaluation_guidelines
              ? values.evaluation_guidelines
              : null,
            change_number: values.changeNumber,
            status_id: 1,
            customer_delivery: customerDelivery ? customerDelivery : null,
            special_process:specialProcess?specialProcess:null,
            raw_material:rawMaterial?rawMaterial: null
          };
          const obj2 = {
            id: uuidv4(),
            entity: "parts",
            entity_id: editingProduct.id,
            previous_data: JSON.stringify(editingProduct),
            present_data: JSON.stringify(obj1),
            created_at: dateService.getDateInSqlFormat(new Date()),
            changed_by: currentUser.id,
          };
          const res = await productService.updateProduct(
            editingProduct.id,
            obj1
          );
          const res2 = await changeManagementService.createChangeManagementRecord(
            obj2
          );
          // console.log('response creating Change Management', res2);
          if (res && res.status === 200) {
            if (fileUpload) {
              // console.log("File for Uploading", fileUpload);
              const uploadResponse = await uploadService.uploadFile(fileUpload);
              // console.log("Uploading File Response", uploadResponse);
              if (
                uploadResponse &&
                uploadResponse.data &&
                uploadResponse.data.files
              ) {
                uploadResponse.data.files.length &&
                  uploadResponse.data.files.map(async (file) => {
                    const obj = {
                      id: uuidv4(),
                      part_id: editingProduct.id,
                      image_url: file.location,
                      drawing_number: values.drawingNumber
                        ? values.drawingNumber
                        : null,
                      client_id: editingProduct.client_id,
                    };
                    const res = await drawingService.createDrawing(obj);
                    // console.log("Updating Drawing", res);
                  });
              }
            }
            onSuccess(true);
            resetForm();
            setAddProductDialog(false);
          }
        } else {
          const partId = uuidv4();
          const obj1 = {
            id: partId,
            part_number: values.partNumber,
            part_name: values.partName,
            client_id: currentUser.client_id,
            customer_id: values.customerId ? values.customerId : null,
            plant_id: values.plantId ? values.plantId : null,
            evaluation_guidelines: values.evaluation_guidelines
              ? values.evaluation_guidelines
              : null,
            change_number: values.changeNumber,
            status_id: 1,
            customer_delivery: customerDelivery,
            created_at: dateService.getDateInSqlFormat(new Date()),
          };
          // console.log("posting for create product", obj1);
          const res = await productService.createProduct(obj1);
          if (res && res.status === 201) {
            if (fileUpload) {
              // console.log("File for Uploading", fileUpload);
              const uploadResponse = await uploadService.uploadFile(fileUpload);
              // console.log("Uploading File Response", uploadResponse);
              if (
                uploadResponse &&
                uploadResponse.data &&
                uploadResponse.data.files
              ) {
                uploadResponse.data.files.length &&
                  uploadResponse.data.files.map(async (file) => {
                    const fileObj = {
                      id: uuidv4(),
                      part_id: partId,
                      image_url: file.location,
                      drawing_number: values.drawingNumber
                        ? values.drawingNumber
                        : null,
                      client_id: currentUser.client_id,
                    };
                    const res = await drawingService.createDrawing(fileObj);
                    // console.log("Creating Drawing", res);
                  });
              }
            }
            onSuccess(true);
            resetForm();
            setAddProductDialog(false);
          }
        }
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <>
      {customers && csrTemplates && plants && initialValues && (
        <Dialog
          open={addProductDialog}
          onClose={() => setAddProductDialog(false)}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#0d51ca",
              color: "#fff",
            }}
          >
            <DialogTitle id="form-dialog-title">
              {isEdit ? "Update Product" : "Add Product"}
            </DialogTitle>
            <div>
              <Button onClick={() => setAddProductDialog(false)}>
                <CloseRoundedIcon style={{ fill: "#fff" }} />
              </Button>
            </div>
          </div>
          <DialogContent pb={4}>
            <DialogContentText>
              Please fill in the following details of your product
            </DialogContentText>
            <Spacer my={6} />
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
                setFieldValue,
              }) => (
                <Card mb={6}>
                  {status && status.sent && (
                    <Alert severity="success" my={3}>
                      Your data has been submitted successfully!
                    </Alert>
                  )}

                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={6}>
                        <Grid item md={12}>
                          <DropzoneArea
                            showFileNamesInPreview={true}
                            showFileNames={true}
                            onChange={(file) => setFileUpload(file)}
                          />
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            name="partNumber"
                            label="Part Number"
                            value={values.partNumber}
                            defaultValue={editingProduct?.part_number}
                            error={Boolean(
                              touched.partNumber && errors.partNumber
                            )}
                            fullWidth
                            helperText={touched.partNumber && errors.partNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            name="partName"
                            label="Part Name"
                            value={values.partName}
                            defaultValue={editingProduct?.part_name}
                            error={Boolean(touched.partName && errors.partName)}
                            fullWidth
                            helperText={touched.partName && errors.partName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            name="changeNumber"
                            label="Change Number"
                            value={values.changeNumber}
                            defaultValue={editingProduct?.change_number}
                            fullWidth
                            helperText={
                              touched.changeNumber && errors.changeNumber
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>
                        {fileUpload && fileUpload.length ? (
                          <Grid item md={12}>
                            <TextField
                              name="drawingNumber"
                              label="Drawing Number"
                              value={values.drawingNumber}
                              defaultValue={editingProduct?.drawing_number}
                              error={Boolean(
                                touched.drawingNumber && errors.drawingNumber
                              )}
                              fullWidth
                              helperText={
                                touched.drawingNumber && errors.drawingNumber
                              }
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                        ) : null}
                        {customers && customers.length && (
                          <Grid item md={6}>
                            <Autocomplete
                              id="customerId"
                              disabled
                              options={customers}
                              getOptionLabel={(option) => option.company_name}
                              defaultValue={customers.find(
                                (customer) =>
                                  customer.id === editingProduct?.customer_id
                              )}
                              renderInput={(params) => (
                                <Field
                                  component={TextField}
                                  {...params}
                                  name="customerId"
                                  label="Customer"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                              onChange={(e, value) => {
                                setFieldValue(
                                  "customerId",
                                  value ? value.id : ""
                                );
                              }}
                              onBlur={handleBlur}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                        {plants && plants.length && (
                          <Grid item md={6}>
                            <Autocomplete
                              id="plantId"
                              options={plants}
                              getOptionLabel={(option) => option.name}
                              defaultValue={plants.find(
                                (plant) => plant.id === editingProduct?.plant_id
                              )}
                              renderInput={(params) => (
                                <Field
                                  component={TextField}
                                  {...params}
                                  name="plantId"
                                  label="Plant"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                              fullWidth
                              helperText={touched.plantId && errors.plantId}
                              onBlur={handleBlur}
                              onChange={(e, value) => {
                                setFieldValue("plantId", value ? value.id : "");
                              }}
                              variant="outlined"
                            />
                          </Grid>
                        )}
                        {csrTemplates && (
                          <Grid item md={12}>
                            <Autocomplete
                              id="evaluation_guidelines"
                              options={csrTemplates}
                              getOptionLabel={(option) => option.template_name}
                              defaultValue={csrTemplates.find(
                                (csrTemplate) =>
                                  csrTemplate.id ===
                                  editingProduct?.evaluation_guidelines
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="evaluation_guidelines"
                                  label="Evaluation Guidelines"
                                  variant="outlined"
                                  fullWidth
                                  InputLabelProps={{ required: true }}
                                />
                              )}
                              onChange={(e, value) => {
                                setFieldValue(
                                  "evaluation_guidelines",
                                  value ? value.id : ""
                                );
                              }}
                            />
                          </Grid>
                        )}
                        <Grid container item md={12}>
                          <Grid item md={4}>
                            <FormControlLabel
                              label="Customer Delivery Required"
                              control={
                                <Checkbox
                                  checked={customerDelivery}
                                  onChange={(e) =>
                                    setCustomerDelivery(e.target.checked)
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item md={4}>
                            <FormControlLabel
                              label="Special Process Required"
                              control={
                                <Checkbox
                                  checked={specialProcess}
                                  onChange={(e) =>
                                    setSpecialProcess(e.target.checked)
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item md={4}>
                            {" "}
                            <FormControlLabel
                              label="Raw Material Required"
                              control={
                                <Checkbox
                                  checked={rawMaterial}
                                  onChange={(e) =>
                                    setRawMaterial(e.target.checked)
                                  }
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider my={6} />
                      <div className="d-flex justify-content-end">
                        <Button
                          type="button"
                          variant="outlined"
                          color="primary"
                          mr={3}
                          mb={3}
                          position="right"
                          onClick={() => setAddProductDialog(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          mb={3}
                          position="right"
                        >
                          {isEdit ? "Update" : "Add"}
                        </Button>
                      </div>
                    </form>
                  )}
                </Card>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AddProductDialog;
