import {
  Box,
  Button as MuiButton,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  TextField as MuiTextField,
} from "@material-ui/core";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import { Alert as MuiAlert } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { AuthContext } from "../../context/AuthContextProvider";
import addressService from "../../services/addressService";
import customerService from "../../services/customerService";
import dateService from "../../services/dateService";
const { v4: uuidv4 } = require("uuid");

const AddCustomerDialog = ({
  addCustomerDialog,
  setAddCustomerDialog,
  onSuccess,
  isEdit,
  editingCustomer,
  editingAddress,
}) => {
  const Divider = styled(MuiDivider)(spacing);
  const Button = styled(MuiButton)(spacing);
  const Alert = styled(MuiAlert)(spacing);
  const TextField = styled(MuiTextField)(spacing);
  const Spacer = styled.div(spacing);
  const { currentUser } = useContext(AuthContext);
  const timeOut = (time) => new Promise((res) => setTimeout(res, time));

  const initialValues = {
    company_name: editingCustomer ? editingCustomer.company_name : "",
    gst: editingCustomer ? editingCustomer.gstNumber : "",
    address_line_1: editingAddress ? editingAddress.address_line_1 : "",
    address_line_2: editingAddress ? editingAddress.address_line_2 : "",
    city: editingAddress ? editingAddress.city : "",
    pincode: editingAddress ? editingAddress.pincode : "",
    state: editingAddress ? editingAddress.state : "",
    country: editingAddress ? editingAddress.country : "",
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Inccorect Company name or format."),
    address_line_1: Yup.string().required("Required"),
    address_line_2: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    pincode: Yup.string().required("Inccorect format. Example 123456"),
    state: Yup.string().required("Inccorect State name or Format."),
    country: Yup.string().required("Inccorect Country name or Format."),
    gst: Yup.string().min(15).max(15).required("15 digit GST number only"),
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await timeOut(1500);
      resetForm();
      setStatus({ sent: true });
      setSubmitting(false);
      setAddCustomerDialog(false);
      if (isEdit) {
        const obj1 = {
          id: editingCustomer.addressId,
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          city: values.city,
          pincode: values.pincode,
          state: values.state,
          country: values.country,
        };
        const response1 = await addressService.updateAddress(
          editingCustomer.addressId,
          obj1
        );
        if (response1 && response1.data) {
          const obj2 = {
            id: editingCustomer.id,
            company_name: values.company_name,
            GST: values.gst,
            address_id: editingCustomer.addressId,
            client_id: currentUser.client_id,
          };
          const response2 = await customerService.updateCustomer(
            editingCustomer.id,
            obj2
          );
          if (response2 && response2.data) {
            onSuccess(true);
          }
        }
      }
      if (!isEdit) {
        const obj1 = {
          id: uuidv4(),
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          city: values.city,
          pincode: values.pincode,
          state: values.state,
          country: values.country,
        };
        const response1 = await addressService.createAddress(obj1);
        if (response1 && response1.status === 201) {
          const obj2 = {
            id: uuidv4(),
            company_name: values.company_name,
            GST: values.gst,
            address_id: response1.data.id,
            client_id: currentUser.client_id,
            created_at: dateService.getDateInSqlFormat(new Date()),
          };
          const response2 = await customerService.createCustomer(obj2);
          if (response2 && response2.status === 201) {
            onSuccess(true);
          }
        }
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={addCustomerDialog}
      onClose={() => setAddCustomerDialog(false)}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      fullWidth
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#0d51ca",
          color: "#fff",
        }}
      >
        <DialogTitle id="form-dialog-title">Add Customers</DialogTitle>
        <div>
          <Button onClick={() => setAddCustomerDialog(false)}>
            <CloseRoundedIcon style={{ fill: "#fff" }} />
          </Button>
        </div>
      </div>
      <DialogContent pb={4}>
        <DialogContentText>
          Please fill in the following details of your customer
        </DialogContentText>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              {status && status.sent && (
                <Alert severity="success" my={3}>
                  Your data has been submitted successfully!
                </Alert>
              )}

              {isSubmitting ? (
                <Box display="flex" justifyContent="center" my={6}>
                  <CircularProgress />
                </Box>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Spacer my={4} />
                  <Grid container spacing={6}>
                    <Grid item md={12}>
                      <TextField
                        name="company_name"
                        label="Company Name"
                        value={values.company_name}
                        error={Boolean(
                          touched.company_name && errors.company_name
                        )}
                        fullWidth
                        helperText={touched.company_name && errors.company_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <TextField
                        name="address_line_1"
                        label="Street Address Line 1"
                        value={values.address_line_1}
                        error={Boolean(
                          touched.address_line_1 && errors.address_line_1
                        )}
                        fullWidth
                        helperText={
                          touched.address_line_1 && errors.address_line_1
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        name="address_line_2"
                        label="Street Address Line 2"
                        value={values.address_line_2}
                        error={Boolean(
                          touched.address_line_2 && errors.address_line_2
                        )}
                        fullWidth
                        helperText={
                          touched.address_line_2 && errors.address_line_2
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <TextField
                        name="city"
                        label="City"
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        fullWidth
                        helperText={touched.address && errors.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        name="state"
                        label="State"
                        value={values.state}
                        error={Boolean(touched.state && errors.state)}
                        fullWidth
                        helperText={touched.state && errors.state}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={6}>
                    <Grid item md={6}>
                      <TextField
                        name="pincode"
                        label="Pin Code"
                        value={values.pincode}
                        error={Boolean(touched.pincode && errors.pincode)}
                        fullWidth
                        helperText={touched.pincode && errors.pincode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        name="country"
                        label="Country"
                        value={values.country}
                        error={Boolean(touched.country && errors.country)}
                        fullWidth
                        helperText={touched.country && errors.country}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                        my={2}
                      />
                    </Grid>
                    {values.country.toLowerCase() == "india" ? (
                      <Grid item md={6}>
                        <TextField
                          name="gst"
                          label="GST Number"
                          value={values.gst}
                          error={Boolean(touched.gst && errors.gst)}
                          fullWidth
                          helperText={touched.gst && errors.gst}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Divider my={6} />
                  <div className="d-flex justify-content-end">
                    <Button
                      type="button"
                      variant="outlined"
                      color="primary"
                      mr={3}
                      mb={3}
                      position="right"
                      onClick={() => setAddCustomerDialog(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mb={3}
                      position="right"
                      disabled={
                        !(
                          values.company_name &&
                          values.gst &&
                          values.city &&
                          values.state
                        )
                      }
                    >
                      {isEdit ? "Update" : "Add"}
                    </Button>
                  </div>
                </form>
              )}
            </Card>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddCustomerDialog;
