import React, { useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import { RequestForm } from "../../productionInspection/product-inspection/UnderInspection/RequestForm";
import { AddProductionRecord } from "../../productionmgmt/production_record/productionRecordForm2"


export function GembaTooLifeRecord({ setSelectedCard = () => { } }) {
  const [formOpen, setFormOpen] = useState(true);
  return (
    <>
      {" "}
      <DGDrawer
        Component={AddProductionRecord}
        title="New Request"
        isOpen={formOpen}
        setOpen={(state, reqID) => {
          if (reqID) {
            setFormOpen(state);
          } else {
            setFormOpen(state);
            setSelectedCard(-1);
          }
        }}
        subtitle={new Date().toLocaleDateString()}
        drawerWidth={1200}
        needSave
      />

    </>
  );
}
