import { Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import Register from "../../assets/images/digital.png";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import userService from "../../services/userService";
import { saveUserLocal, setAccessToken } from "../../services/authService";
import { useHistory, useLocation } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContextProvider";

export function UpdateAccount() {
  const location = useLocation();
  const { loginUser } = useContext(AuthContext);
  const history = useHistory();



  function RegisterUser() {
    userService
      .updateUser(location.state.user?.id, {
        name: formik.values.name,
        phone_number: formik.values.phone,
      })
      .then((res) => {
        setAccessToken(location.state.token);
        saveUserLocal(JSON.stringify(location.state.user));
        loginUser(location.state.user);
      })
      .finally(() => {
        history.push("/overview");
      });
  }
  useEffect(() => {
    formik.setFieldValue("phone", location?.state?.user?.phone_number);
  }, []);
  const validationSchema = yup.object({
    name: yup.string("Enter your Full Name").required("Full Name  is required"),
    phone: yup
      .number("Enter your Phone")
      .test(
        "len",
        "Must be exactly 10 characters",
        (val) => val?.toString()?.length === 10
      )
      .required("Phone is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      submit: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      return RegisterUser();
    },
  });
  return (
    <Grid
      container
      style={{
        height: "200%",
      }}
      justifyContent="space-around"
      alignItems="center"
      spacing={2}
    >
      <Grid
        item
        md={6}
        sm={6}
        lg={6}
        className="p-4 h-100"
        justifyContent="center"
        alignItems="center"
        style={{ background: "linear-gradient(#3f51b5, #9fa8da)" }}
      >
        <div style={{ textAlign: "center", height: "100%" }}>
          <Typography
            align="left"
            variant="h3"
            style={{ fontSize: 35, color: "#fff" }}
            className="pt-2"
            gutterBottom
          >
            One click to go all digital with{" "}
          </Typography>
          <Typography
            align="left"
            variant="h3"
            style={{ fontSize: 35, color: "#ff5252" }}
          >
            DigitalGemba.
          </Typography>
        </div>

        <img src={Register} style={{ alignSelf: "center" }} />
      </Grid>
      <Grid item md={6} sm={6} lg={6} style={{ height: "100%" }}>
        <div className="w-50 mx-auto">
          <form onSubmit={formik.handleSubmit}>
            <Typography align="left" variant="h3" gutterBottom className="pb-4">
              Sign up
            </Typography>
            <div className="pb-4">
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Full Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="pb-4">
              <TextField
                fullWidth
                id="phone"
                name="phone"
                label="Phone"
                type="tel"
                value={formik.values.phone}
                onChange={formik.handleChange}
                variant="outlined"
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                disabled
              />
            </div>
            <Typography
              variant="body2"
              gutterBottom
              display="block"
              className="pb-4"
            >
              You are agreeing to the <Link href="#">Terms of Services </Link>{" "}
              and <Link href="#">Privacy Policy.</Link>
            </Typography>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Get Started
            </Button>
            <Typography
              align="center"
              variant="body1"
              gutterBottom
              className="pt-2"
            >
              Already a member? <Link href="#">Sign in</Link>{" "}
            </Typography>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
