/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import ActiveInActive from "../../../../components/ActiveInActive";
import { ApprovalStatus } from "../../../../components/approvalStatus";
import { ResultRenderer } from "../../../../components/cellRenderers/ResultRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import { SubNavigationContext } from "../../../../context/SubnavigationProvider";
import msaPlanService from "../../../../services/msaPlanService";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { InitiateCharacterMSA } from "../msa_listing/msatab/index"
import { MsaReport } from "../../msatab/reports"
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../../components/DGDrawer";
import { MsaPlanForm } from "./MsaPlanForm";
import { Link } from "react-router-dom";
import StatusFilter from "../../../../components/shared/StatusFilter";
import { Text } from "@chakra-ui/react";
import { endOfMonth, isAfter, parseISO } from 'date-fns';
import { rgba } from "polished";
import Chip from '@material-ui/core/Chip';
import { transparentize } from 'polished';
import { isThisMonth, isBefore } from 'date-fns';

export function MsaPlanListing() {
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const { globalPlant } = useContext(PlantContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [studyOpen, setStudyOpen] = useState(false);
  //const [formOpen, setFormOpen] = useState(false);
  const [formPlanOpen, setFormPlanOpen] = useState(false);
  const [formReportOpen, setFormReportOpen] = useState(false);
  const { setheaderPageTitle } = useContext(SubNavigationContext);
  const [currentMonth, setCurrentMonth] = useState(false)
  const [overdue, setOverDue] = useState(false)


  function reloadGridData() {
    msaPlanService
      .getMSAPlanTable(currentUser.client_id, globalPlant?.globalPlant?.id)
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }

  function onRowSelection(data) {
    setSelectedRow(data[0]);
  }

  useEffect(() => {
    if (currentMonth) {
      var filteredRows = rows.filter(row => {
        return (
          isThisMonth(new Date(row.scheduled_month)) &&
          row.enabled === 1
        )
      })
      setRows(filteredRows);
    } else if (overdue) {
      var filteredRows = rows.filter(row => {
        return (
          isBefore(new Date(row.scheduled_month), new Date()) &&
          row.result_approval_status === "Pending" &&
          row.enabled === 1
        );
      })
      setRows(filteredRows);
    }
    else {
      reloadGridData()
    }
  }, [currentMonth, overdue]);



  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadGridData,
      callback: changeStatus,
    }));
    if (globalPlant && globalPlant?.globalPlant?.id) {
      reloadGridData();
    }
  }, [globalPlant]);

  function changeStatus(dummy1, dummy2, newStatus, rowId) {
    return msaPlanService.updateMSAPlan(rowId, { enabled: newStatus });
  }

  function handleInitiateStudy() {
    setStudyOpen(true);;
  }

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => { setFormPlanOpen(true); }}
        >
          MSA PLAN
        </Button>

        {selectedRow?.result_approval_status !== null ? (
          null
        ) : (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<MenuBookIcon />}
            onClick={() => { handleInitiateStudy() }}
            style={{ marginLeft: 3 }}
          >
            Initiate Study
          </Button>
        )}

      </>
    );
  }
  ;


  const getRowStyle = (params) => {
    const resultApprovalStatus = params?.data?.result_approval_status;
    const scheduledMonth = params?.data?.scheduled_month;
    const evaluationDate = params?.data?.study_created_date;

    if (resultApprovalStatus !== "Approved") {
      const endOfScheduledMonth = endOfMonth(parseISO(scheduledMonth));
      const endOfCurrentMonth = endOfMonth(new Date());

      if (!evaluationDate) {
        if (isAfter(endOfCurrentMonth, endOfScheduledMonth)) {
          return { background: 'rgba(231,76,60,0.1)' };
        }
      } else {
        //console.log(parseISO(evaluationDate), endOfScheduledMonth)
        if (isAfter(parseISO(evaluationDate), endOfScheduledMonth)) {
          return { background: 'rgba(231,76,60, 0.1)' };
        }
      }
    }

    return {};
  }





  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      minWidth: 105,
      pinned: "left",
    },

    {
      headerName: "Plan",
      field: "part_number",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 350,
      autoHeight: true,
      pinned: 'left',
      customFilterParams: {
        textCustomComparator: function (filter, value, filterText) {
          const filterWords = filterText.toLowerCase().split(' ');
          return filterWords.every(word => {
            return value.toLowerCase().includes(word);
          });
        }
      },
      cellRenderer: (params) => {
        return (
          <>

            <Text height={15} fontSize="xs">
              {params?.data?.part_number}
            </Text>

            {params?.data?.specification !== null &&
              params?.data?.specification !== undefined && (
                <Text height={15} fontSize="xs">
                  {`${params?.data?.characterstic_name}
                ${params?.data?.specification}  
                  ${params?.data?.upper_specification ?? ""
                    }  ${params?.data?.lower_specification ?? ""}`}
                </Text>
              )}

            {params?.data.plan_study_id !== null &&
              params?.data.plan_study_id !== undefined && (
                <Text height={15} fontSize="xs">
                  {params?.data?.plan_study_id}
                </Text>
              )}


            <Text height={15} fontSize="xs">
              {params?.data?.gauge_}
            </Text>

            {params?.data.gauge_serial_number !== null &&
              params?.data.gauge_serial_number !== undefined && (
                <Text height={15} fontSize="xs">
                  {params?.data?.gauge_serial_number}
                </Text>
              )}

            <Text height={15} fontSize="xs">
              {params?.data?.name}
            </Text>


            {params?.data.report_number !== null &&
              params?.data.report_number !== undefined && (
                <Button
                  size="small"
                  color="primary"
                  lineHeight={15}
                  onClick={() => {
                    setFormReportOpen(true);
                  }}
                >
                  {params.data.report_number}
                </Button>
              )}

            {/* <Text marginBottom={6}></Text> */}
          </>
        );
      },
    },

    {
      field: "scheduled_month",
      headerName: "Scheduled Month",
      valueGetter: (params) => {
        return params?.data?.scheduled_month
          ? new Date(params.data.scheduled_month).toLocaleString('en-US', { month: 'short', year: 'numeric' })
          : "";
      },

      sortable: true,
      resizable: true,
      minWidth: "200",
      //floatingFilter: true,
      //filter: "agDateColumnFilter",
      filterParams: filterParams,
      quickFilterText: true,
      // rowGroup: true,
      filter: 'agSetColumnFilter',

    },
    {
      field: "study_created_date",
      headerName: "Evaluated",
      valueGetter: (params) => {
        return params?.data?.study_created_date
          ? new Date(params.data.study_created_date).toLocaleDateString()
          : "";
      },
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      field: "msa_result",
      headerName: "Result",
      cellRenderer: (params) => (
        <ResultRenderer value={params?.data?.msa_result} />
      ),
      sortable: true,
      resizable: true,
      minWidth: "200",
      // floatingFilter: true,
      filter: "agTextColumnFilter",
    },

    {
      field: "result_approval_status",
      headerName: "Approval Status",
      minWidth: 200,
      cellRenderer: (params) => (
        <ApprovalStatus status={params?.data?.result_approval_status} />
      ),
    },
    {
      //field: "report_number",
      headerName: "Report Number",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return params.data?.report_number ? (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              setFormReportOpen(true);
            }}
          >
            {params.data.report_number}
          </Button>
        ) : null;
      },

    },
    /*
    {
      field: "gauge_serial_number",
      headerName: "Gauge Item",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      filter: "agTextColumnFilter",
    },
    */
    {
      field: "enabled",
      headerName: "Status",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.part_ ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params.data.msa_plan_id,
            }))
          }
        />
      ),
      sortable: true,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellClass: "text-center",
      minWidth: 200,
    },
    {
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.msa_plan_id,
                entityName:
                  params?.data?.msaPlan?.character?.part?.part_number ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];
  return (
    <>

      <Chip
        label={"Current Month Plan"}
        disabled={overdue}
        style={{
          backgroundColor: !currentMonth ? 'transparent' : transparentize(0.8, 'green'),
          margin: '20px',
          border: !currentMonth ? '1px solid green' : 'none',
          fontWeight: 'bold'
        }}
        onClick={() => {
          setCurrentMonth(!currentMonth)
          //setOverDue(false)
        }}
      />

      <Chip
        label={"Over Due"}
        disabled={currentMonth}
        style={{
          backgroundColor: !overdue ? 'transparent' : transparentize(0.8, 'red'),
          margin: '20px',
          border: !overdue ? '1px solid green' : 'none',
          fontWeight: 'bold'
        }}
        onClick={() => {
          setOverDue(!overdue)
        }


        }
      />

      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={212}
        ButtonComponent={ButtonComponent}
        onRowSelect={onRowSelection}
        getRowStyle={getRowStyle}
      />




      <DGDrawer
        Component={() => (
          <InitiateCharacterMSA
            msaPlanData={selectedRow}
            fromMSAPlan={true}
            onClose={() => {
              setStudyOpen(false);
            }}
          />
        )}
        isOpen={studyOpen}
        drawerWidth={1150}
        setOpen={() => { setStudyOpen(false) }}
        title="MSA Plan"
        onClose={reloadGridData}
      />

      <DGDrawer
        isOpen={formPlanOpen}
        Component={MsaPlanForm}
        setOpen={(state) => {
          setFormPlanOpen(state);
          reloadGridData();
        }}
        onClose={() => { setFormPlanOpen(false) }}
        onCancel={() => setFormPlanOpen(false)}
        title="New Plan"
        drawerWidth={600}
        subtitle={moment(new Date()).format("DD/MM/YYYY")}
        needSave
      />

      <DGDrawer
        Component={MsaReport}
        setOpen={() => {
          setFormReportOpen(false);
          //setSelectedRow(null);
        }}
        isOpen={formReportOpen}
        componentProps={{ ...selectedRow }}
        drawerWidth={1150}
        title="Report View"
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
}
