import {
    Grid, Typography, Button, makeStyles,
    InputLabel, InputBase, TextField
} from "@material-ui/core";
import React, { useState } from "react";
import { useEffect, useContext } from "react";
import processCapability from "../../../../services/processCapability";
import { DGInput } from "../../../../components/shared/DGInput";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import highchartsMore from "highcharts/highcharts-more";

highchartsMore(Highcharts);
highchartsDumbbell(Highcharts);

export default function CpkTrendForm(
    componentProps = null,
    closeForm = () => { },
) {

    const [chartData, setChartData] = useState([]);

    const [chartOptions, setChartOptions] = useState({
        rangeSelector: {
            enabled: false,
            buttons: [
                {
                    type: 'month',
                    count: 3,
                    text: '3m',
                    title: 'View 2 week',
                },
                {
                    type: 'month',
                    count: 6,
                    text: '6m',
                    title: 'View 6 month',
                },
                {
                    type: 'year',
                    count: 1,
                    text: '1y',
                    title: '1 year',
                },

                {
                    type: 'all',
                    text: 'All',
                    title: 'View all data',
                },


            ],
        },


        chart: {
            type: "column",
            inverted: false,
            marginRight: 20,
            zoomType: "x",
            panning: {
                enabled: true,
                type: 'x'
            },
            panKey: 'shift',

        },

        boost: { enabled: true },


        tooltip: {
            enabled: true,
        },


        animation: true,
        title: false,


        yAxis: {
            min: null,
            max: null,

            crosshair: false,
            title: {
                text: "Cpk Values",
            },
            plotLines: null
        },

        xAxis: {
            type: 'datetime',
            ordinal: true,
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%d/%b', this.value);

                }
            },

        },

        credits: {
            enabled: false,
        },

        legend: false,


    });

    useEffect(() => {
        let formattedData
        let data
        processCapability.getCPKCharacter(
            {
                character_id: componentProps?.componentProps?.character_id,
                machine_spindle: componentProps?.componentProps?.machine_spindle
            }
        )
            .then((res) => {
                //console.log(res?.data, "response")
                data = res?.data
                formattedData = data.map((item) => ({
                    // x_: new Date(item.created_date),
                    x: new Date(item.created_date).getTime(),
                    y: item.cpk,
                    color: item.cpk_result === 0 ? 'red'
                        : (item.cpk_result === 1 ? 'green'
                            : undefined),
                }))
                setChartData(formattedData)

            })


    }, [componentProps])

    useEffect(() => {

        setChartOptions({
            ...chartOptions,



            tooltip: {
                enabled: true,
                formatter: function () {
                    var tooltipContent =
                        "Evaluation Date:<b>" + Highcharts.dateFormat('%d/%m/%y', this.x) + "</b><br/>" +
                        "Cpk:<b>" + this.point.y.toFixed(2) + "</b> "

                    return tooltipContent;
                },
            },

            series: [
                {
                    name: "Process Capability",
                    data: chartData,
                },
            ],
        });


    }, [chartData])


    //console.log(chartOptions, "chartOptions")


    return (
        <>

            <Grid container spacing={2} style={{ marginTop: '10px' }}>

                <Grid item xs={4}>
                    <DGInput
                        label="Part Number"
                        value={componentProps?.componentProps?.part_number + " - "
                            + componentProps?.componentProps?.part_name}
                    />
                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Characteristics"
                        value={
                            componentProps?.componentProps?.character_name + " - " +
                            componentProps?.componentProps?.specification + " " +
                            componentProps?.componentProps?.upper_specification + " " +
                            componentProps?.componentProps?.lower_specification
                        }
                    />
                </Grid>

                <Grid item xs={4}>
                    <DGInput
                        label="Machine"
                        value={
                            componentProps?.componentProps?.machine_number + " " +
                            componentProps?.componentProps?.spindle_name
                        }
                    />
                </Grid>



            </Grid >


            <Grid item xs={12} style={{ marginTop: '20px' }}>
                <HighchartsReact
                    options={chartOptions}
                    highcharts={Highcharts}
                />
            </Grid>

        </>
    )

}