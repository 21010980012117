import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MaskedInput from "react-text-mask";

const KEY_BACKSPACE = "Backspace";
const KEY_F2 = "F2";

export const AngleEditor = memo(
  forwardRef((props, ref) => {
    const createInitialState = () => {
      let startValue;
      let highlightAllOnFocus = true;

      if (props.eventKey === KEY_BACKSPACE) {
        startValue = "";
      } else if (props.charPress) {
        startValue = props.charPress;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = props.value;
        if (props.eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }

      return {
        value: startValue,
        highlightAllOnFocus,
      };
    };

    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);
    const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(
      initialState.highlightAllOnFocus
    );
    const refInput = useRef(null);

    useEffect(() => {
      const eInput = refInput.current?.inputElement; // Access the underlying input element

      if (eInput?.focus) {
        eInput.focus();
      }

      if (highlightAllOnFocus) {
        if (eInput?.select) {
          eInput.select();
        }
        setHighlightAllOnFocus(false);
      } else {
        eInput.setSelectionRange(1, 1);
      }
    }, [highlightAllOnFocus]);

    const cancelBeforeStart =
      props.charPress && "1234567890".indexOf(props.charPress) < 0;

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return cancelBeforeStart;
        },
        isCancelAfterEnd() {
          return value > 1000000;
        },
      };
    });

    return (
      <>
        <MaskedInput
          ref={refInput}
          mask={[
            /[+-]/,
            /[0-9]/,
            /\d/,
            /\d/,
            "°",
            " ",
            /\d/,
            /\d/,
            "'",
            " ",
            /\d/,
            /\d/,
            "''",
          ]}
          className="form-control"
          guide={true}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          defaultValue={"+***° **' **'"}
          value={value}
        />
      </>
    );
  })
);
