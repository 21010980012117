import { Button, Grid, Paper } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import userService from "../../../services/userService";


import AddIcon from "@material-ui/icons/Add";
import { AddNewOperatorForm } from "./AddNewOperatorForm";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";


const OperatorTable = () => {
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { globalPlant } = useContext(PlantContext);
    const [plants, setPlants] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const [rows, setRows] = useState([])
    const [formOpen, setFormOpen] = useState(false);
    const { setHeaderPageTitle } = useContext(PageTitleContext);

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
            callback: changeStatus,
        }));
        reloadGridData()
    }, []);

    useEffect(() => {
        reloadGridData()
    }, [globalPlant])


    function reloadGridData() {

        userService.getOperatorTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,

        })
            .then((res) => {
                setRows(res?.data ?? []);
                setHeaderPageTitle(`Operators Team Management`);
            });


    }

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return userService.updateOperatorStatus(rowId, { enabled: newStatus });
    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },
        {
            field: "operator_name",
            headerName: "Operator Name",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },

        {
            field: "operator_id",
            headerName: "Operator ID",
            flex: 1,
            editable: false,
            resizable: true,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            minWidth: 150,
        },



        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            entityName: params?.data?.operator_name ?? "",
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,

                                rowId: params?.data?.id,
                                entity: params?.data?.operator_name ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        },
    ];





    return (
        <Paper>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <CommonGrid
                        ButtonComponent={() => (
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => setFormOpen(true)}
                            >
                                Operator
                            </Button>
                        )}
                        columns={columns}
                        rows={rows}
                        gridheight={100}

                    />
                </Grid>
            </Grid>

            <DGDrawer
                needSave
                drawerWidth={600}
                isOpen={formOpen}
                setOpen={(state) => {
                    setFormOpen(false);
                    reloadGridData();
                }}
                Component={AddNewOperatorForm}
                title="Add Operator "
            />


        </Paper>
    );
};

export default OperatorTable;
