import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import ImageIcon from "@material-ui/icons/Image";
import React from "react";

export function GaugeAnalysis({
  test_info = [
    {
      "'Study Date'": "",
      Comments: "",
    },
  ],
}) {
  return (
    <Paper elevation={1}>
      <Typography variant="h5" gutterBottom align="center" style={{backgroundColor:indigo[100]}} >
        ANALYSIS INFORMATION
      </Typography>
      <List>
        <Divider />
        <ListItem>
          <ListItemText primary={"Study Date"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={new Date(test_info[0]["'Study Date'"]).toDateString()}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Comments"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={test_info[0]["Comments"]}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
    </Paper>
  );
}
