import React, { useContext, useEffect, useState } from 'react'
import { useFormik } from "formik";
import AddIcon from '@material-ui/icons/Add';
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../../components/CommonGrid";
import { Button, Grid, TextareaAutosize } from "@material-ui/core";
import { AuthContext } from "../../../../context/AuthContextProvider";
import machineMaintenanceService from "../../../../services/machineMaintenanceService";
import { string, object, array } from 'yup';
import { DGInput } from '../../../../components/shared/DGInput';
import { DGSelect } from '../../../../components/shared/DGSelect';
import machineService from '../../../../services/machineService';
import { PlantContext } from '../../../../context/PlantContextProvider';
import { AlertContext } from '../../../../context/AlertContextProvider';


export function MachineMaintenanceCLPQAdd({
    Footer = () => { },
    closeForm = () => { },
    componentProps = null

}) {

    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require('uuid');
    const { updateAlert } = useContext(AlertContext);



    const form = useFormik({
        initialValues: {
            machineOptions: [],
            machineSelected: null,
            planName: "",
            checkPointsOptions: [],
            checkPointSelected: null,
            checkPointDescription: "",
        },
        validationSchema: object().shape({
            planName: string().required("Required"),
            // machineSelected: object().required("Required"),

        }),

    });

    const gridForm = useFormik({
        initialValues: {
            gridData: [],
        },
        validationSchema: object().shape({
            gridData: array().required("Required").min(1, "At least 1 grid object is required"),
        }),
        onSubmit: onSave,
    });


    function onGridReady(params) {
        setGridApi(params?.api);
    }


    useEffect(() => {
        machineService.getMachineTable(currentUser.client_id,
            globalPlant?.globalPlant?.id)
            .then((res) => {
                form.setFieldValue("machineOptions", res?.data ?? []);
            });
        machineMaintenanceService.getMachineMaintenanceQ(currentUser.client_id)
            .then((res) => {
                let data = res?.data
                const filteredData = data.filter((item) => item.enabled === 1)
                form.setFieldValue("checkPointsOptions", filteredData);
            });

    }, []);

    function onSave() {

        const temp_id = uuidv4();
        var slNO = componentProps.maxID + 1;
        const payload1 = {

            id: temp_id,
            plan_number: slNO,
            plan_name: form.values.planName,
            machine_id: form.values.machineSelected?.machine_id ?? null,
            client_id: currentUser.client_id,
            plant_id: globalPlant?.globalPlant?.id,
            user_id: currentUser.id,
            enabled: 1,
        }

        const payload2 = []
        gridApi?.forEachNode((node) => {
            payload2.push({
                id: uuidv4(),
                clp_id: temp_id,
                q_id: node?.data?.id ?? "",
                enabled: 1,
                q_description: node?.data?.value ?? "",
            })
        })


        machineMaintenanceService.createMachineMentananceCLP(payload1)
            .then((res) => {
                if (res?.status <= 210) {
                    machineMaintenanceService.createMachineMentananceCLPQ(payload2)
                        .then((res) => {
                            closeForm();
                            updateAlert({
                                open: true,
                                message: "Check List Created Successfully",
                                type: "success",
                            });
                        })
                        .catch(() => {
                            updateAlert({
                                open: true,
                                message: "Failed to create Check List",
                                type: "error",
                            });
                        });
                } else {
                    updateAlert({
                        open: true,
                        message: "Failed to create Check List",
                        type: "error",
                    });
                }
            })
            .catch(() => {
                updateAlert({
                    open: true,
                    message: "Failed to create Check List",
                    type: "error",
                });
            });
    }

    /*     function onAdd() {
            gridForm.setFieldValue("gridData", [
                ...gridForm.values.gridData,
                form.values.checkPointSelected,
            ])
            form.setFieldValue("checkPointSelected", "");
    
        } */


    function onAdd() {
        const updatedCheckPoint = {
            ...form.values.checkPointSelected,
            ...form.values.checkPointDescription
        };

        gridForm.setFieldValue("gridData", [
            ...gridForm.values.gridData,
            updatedCheckPoint,
        ]);

        form.setFieldValue("checkPointSelected", "");
        form.setFieldValue("checkPointDescription", "");
    }




    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 75,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },

        {
            field: "maintenance_question",
            headerName: "Check Point",
            filter: "agTextColumnFilter",
            minWidth: 150,
        },

        {
            field: "value",
            headerName: "Description",
            filter: "agTextColumnFilter",
            minWidth: 400,
            cellRenderer: (params) => {
                if (!params.value) {
                    return '';
                }
                const wrappedText = params.value.split('\n').map(line => `${line}`).join('');
                return wrappedText;
            },
            cellStyle: {
                whiteSpace: 'pre-wrap',
                lineHeight: '1.3' // 
            },
            autoHeight: true,
        },


    ]


    function removeDetailRow(index, rowData) {
        let data = gridForm.values.gridData;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
        gridForm.setFieldValue("gridData", data);
    }


    return (

        <>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <DGSelect
                        label="Machine"
                        id="machineSelected"
                        options={form.values.machineOptions}
                        getOptionLabel={(option) =>
                            option.machine_number + " - " + option.machine_name}
                        onChange={(val) => {
                            form.setFieldValue("machineSelected", val);
                        }}
                        value={form.values.machineSelected}
                        disabled={gridForm.values?.gridData?.length > 0}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DGInput
                        label="Plan Name"
                        id="planName"
                        isFormik
                        value={form.values.planName}
                        onChange={form.handleChange}
                        disabled={gridForm.values?.gridData?.length > 0}
                    />
                </Grid>

                <Grid item xs={6}>
                    <DGSelect
                        label="Check Point"
                        id="checkPoint"
                        options={form.values.checkPointsOptions}
                        getOptionLabel={(option) => option.maintenance_question}
                        value={form.values.checkPointSelected}
                        onChange={(val) => {
                            form.setFieldValue("checkPointSelected", val);
                        }}
                    />
                </Grid>

                <Grid item md={12} sm={12} lg={12}>
                    <TextareaAutosize
                        aria-label="checkPointDescription"
                        placeholder="Check Point Description"
                        minRows={2}
                        className="border border-2"
                        style={{
                            width: "100%",
                            borderColor: "#bbb",
                            borderRadius: "2px",
                            maxHeight: '100px',
                            minHeight: '50px',
                        }}
                        onChange={(e) => {
                            form.setFieldValue("checkPointDescription", e.target);
                        }}
                    />
                </Grid>

                <Grid item md={12} sm={12} lg={12}
                    style={{ padding: '10px' }}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={onAdd}
                        disabled={form.values.planName === "" || form.values.planName === null}
                    >
                        ADD
                    </Button>

                </Grid>

                <Grid item md={12} sm={12} lg={12} xs={12}
                    justifyContent="flex-end">


                    <CommonGrid

                        columns={columns}
                        rows={gridForm.values.gridData}
                        gridheight={115}
                        onGridReady={onGridReady}
                        needExport={false}


                    />
                </Grid>




            </Grid>

            <Footer onSave={gridForm.handleSubmit} />
        </>



    )


}