/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles, AppBar, Chip } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CommonGrid from "../../components/CommonGrid";
import { AuthContext } from "../../context/AuthContextProvider";
import { PageTitleContext } from "../../context/PageTitleContextProvider";
import approvalService from "../../services/approvalService";
//import NewCharactersticsDialog from "../characterstics/productDetails/_charactersticstab/ProductCharctersticsForm"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ApprovalFilter from "./ApprovalFilter";
import { DGDrawer } from "../../components/DGDrawer";
import { ProductCharacteristicsApprovalForm } from "../characterstics/characteristicstab/ProductCharacteristicsApproval"
import { MsaReport } from "../msa/msatab/reports"
//import { ControlLimitFormNew } from "../depreciated/_ControlLimitFormNew"
import ControlLimitView from "../spc/spctab/controllimit/ControlLimitView"
import { transparentize } from 'polished';

const useStyles = makeStyles({
  approve: {
    borderColor: green[400],
    color: green[400],
    width: "100%",
    "&:hover": { background: green[800], color: "#fff" },
  },
  reject: {
    borderColor: red[400],
    color: red[400],
    width: "100%",
    "&:hover": { background: red[800], color: "#fff" },
  },
  addButtonLayout: {
    border: "solid 1px",
    borderColor: "#babfc7",
    textAlign: "end",
    borderBottom: 0,
    padding: 10,
    textAlignLast: true,
  },
});
function ApprovalPage() {
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState([]);
  const [approverType, setApproverType] = useState(-1);
  const [selectedApprovalId, setSelectedApprovalId] = useState("");
  const [tableName, setTableName] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [reportOpen, setReportOpen] = useState(false);
  const [clformOpen, setClFormOpen] = useState(false);
  const [cltformOpen, setcltformOpen] = useState(false);
  const [approved, setApproved] = useState([]);
  const [pending, setPending] = useState([]);
  const [rejected, setRejected] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [formCharOpen, setFormCharOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [assigned, setAssigned] = useState(false)
  const { setHeaderPageTitle } = useContext(PageTitleContext);


  useEffect(() => {
    setSelectedRow({});
  }, [tableName]);


  useEffect(() => {

    setHeaderPageTitle(`Approval Management`);



  }, []);


  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
  };

  function loadControlLimit(data) {
    setSelectedRow({
      id: data.report_id
    });
    setClFormOpen(true);

  }

  //console.log("selectedRow", selectedRow);
  //console.log(cltformOpen, "cltformOpen");

  /*   function loadControTimelLimit(data) {
      //console.log("data", data);
      setSelectedRow(data);
      setcltformOpen(true);
      setSelectedApprovalId(1501);
    } */

  const defaultColumns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      minWidth: 100,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "part",
      headerName: "Part Number",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "character_",
      headerName: "Characterstics Details",
      minWidth: 200,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sorting: true,
    },
    {
      field: "Approval For",
      headerName: "Requested Approval",
      minWidth: 200,
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      sorting: true,
    },
    {
      field: "requested_by",
      headerName: "Requested By",
      minWidth: 180,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sorting: true,
    },
    {
      field: "requested_date",
      headerName: "Requested date",
      floatingFilter: true,
      minWidth: 150,
      valueGetter: (params) =>
        params?.data?.requested_date
          ? new Date(params.data.requested_date).toLocaleDateString()
          : "",
      resizable: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      sorting: true,
    },
    {
      field: "approved_by",
      headerName: "Approver",
      minWidth: 150,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sorting: true,
    },
    {
      field: "approved_date",
      headerName: "Approved on",
      minWidth: 150,
      filter: "agDateColumnFilter",
      floatingFilter: true,
      valueGetter: (params) =>
        params?.data?.approved_date
          ? new Date(params.data.approved_date).toLocaleDateString()
          : "",
      resizable: true,
      filterParams: filterParams,
      sorting: true,
    },
    {
      field: "report_number",
      headerName: "Report Number",
      minWidth: 150,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sorting: true,
    },
    {
      field: "view",
      headerName: "View",
      minWidth: 150,
      cellRenderer: (params) => (
        <Button
          onClick={() => {
            if (params?.data?.approval_type_id > 1499) {
              loadControlLimit(params?.data);

            }
            else if (params?.data?.approval_type_id === 53) {
              setFormCharOpen(true);
            }
            else if (
              params?.data?.approval_type_id === 3 ||
              params?.data?.approval_type_id === 2 ||
              params?.data?.approval_type_id === 5 ||
              params?.data?.approval_type_id === 12 ||
              params?.data?.approval_type_id === 16
            ) {
              loadReport({
                id: params?.data?.report_id,
                studies_id: params?.data?.approval_type_id,
              });
            }
            setSelectedApprovalId(params?.data?.report_id);
            setApproverType(params?.data?.approval_type_id);
          }}
          color="primary"
          variant="text"
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setColumns(defaultColumns);
  }, []);


  function loadApproved() {
    return approvalService.getApprovalApproved(
      currentUser?.client_id ?? "",
      currentUser?.id
    );
  }

  function loadPending() {
    return approvalService.getApprovalPending(
      currentUser?.client_id ?? "",
      currentUser?.id
    );
  }

  function loadRejected() {
    return approvalService.getApprovalRejected(
      currentUser?.client_id ?? "",
      currentUser?.id
    );
  }

  function loadReport(reportData) {
    setSelectedRow({
      study_id: reportData?.id,
      studies_id: reportData?.studies_id,
    });
    setReportOpen(true);
  }

  useEffect(() => {
    reloadGridData();
  }, []);

  function reloadGridData(index = 0, tableName = "Approved") {
    Promise.all([loadApproved(), loadPending(), loadRejected()]).then((res) => {
      setApproved(res[0]?.data ?? []);
      setPending(res[1]?.data ?? []);
      setRejected(res[2]?.data ?? []);
      setRows(res[index]?.data ?? []);
      setTableName(tableName);

    });
  }
  function onFilter(index) {
    switch (index) {
      case 0:
        setColumns(defaultColumns);
        setRows(approved ?? []);
        break;
      case 1:
        setColumns(defaultColumns);
        setRows(pending ?? []);
        break;
      case 2:
        setColumns(defaultColumns);
        setRows(rejected ?? []);
        break;
      default:
        setColumns(defaultColumns);
        setRows(approved ?? []);
        break;
    }
  }


  function onGridReady(params) {
    //
  }
  function onRowSelection(data) {
    if (data[0]) {
      setSelectedRow(data[0]);
    } else {
      setSelectedRow({});
    }
  }

  function ButtonComponent() {
    return (
      <>
        {selectedRow &&
          selectedRow?.approve_ok === "OK" &&
          tableName === "Pending" ? (
          <>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              startIcon={<CheckCircleOutlineIcon />}
              onClick={() => {
                ApproveORReject(2);
              }}
              style={{ marginRight: 9 }}
            >
              APPROVE
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              color="secondary"
              startIcon={<CancelOutlinedIcon />}
              onClick={() => {
                ApproveORReject(3);
              }}
            >
              REJECT
            </Button>
          </>
        ) : null}
      </>
    );
  }

  function ApproveORReject(statusId) {
    let payload = {
      approval_id: selectedRow?.approval_id,
      user_id: currentUser?.id,
      status_id: statusId,
    };
    approvalService.createApprovalDecision(payload).then((res) => {
      reloadGridData(1, "Pending");
    });
  }


  useEffect(() => {

    if (assigned) {

      var filteredRows = rows.filter(row => {
        return (row.approver_id === currentUser.id)
      })
      setRows(filteredRows);
    }
    else {
      reloadGridData()
    }

  }, [assigned]);


  return (
    <>
      <Grid container justifyContent="space-evenly">
        <ApprovalFilter
          count={{
            approved: approved?.length ?? 0,
            pending: pending?.length ?? 0,
            rejected: rejected?.length ?? 0,
          }}
          rows={rows}
          setRows={onFilter}
          setTableName={setTableName}
        />

        <Grid container md={12} sm={12} lg={12} >

          <Chip
            label={"Assigned to Me"}
            style={{
              backgroundColor: !assigned ? 'transparent' : transparentize(0.8, 'green'),
              margin: '20px',
              border: !assigned ? '1px solid green' : 'none',
              fontWeight: 'bold'
            }}
            onClick={() => {
              setAssigned(!assigned)
              //setOverDue(false)
            }}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={12} style={{ marginTop: 10 }}>
          <CommonGrid
            needExport
            onGridReady={onGridReady}
            rowHeight={45}
            rows={rows}
            columns={columns}
            tableName={tableName}
            ButtonComponent={ButtonComponent}
            onRowSelect={onRowSelection}
            gridheight={240}
          />
        </Grid>

      </Grid>


      <DGDrawer
        Component={ProductCharacteristicsApprovalForm}
        isOpen={approverType === 53 && selectedApprovalId}
        setOpen={() => {
          setApproverType(-1);
        }}
        componentProps={{ approval_id: approverType === 53 ? selectedApprovalId : "", }}
        title="Add Characteristics"
        drawerWidth={900}
      />

      {/*  {approverType === 1500 && selectedApprovalId && ( */}
      <DGDrawer
        Component={ControlLimitView}
        isOpen={clformOpen}
        title={"Control Limit Evaluation" + "  " + selectedRow?.report_number}
        subtitle={selectedRow?.part}
        setOpen={() => {
          setClFormOpen(false);
          setSelectedRow(null);
        }}
        drawerWidth={800}
        componentProps={{
          id: selectedRow?.report_id,

        }}
      />

      <DGDrawer
        Component={MsaReport}
        setOpen={() => {
          setReportOpen(false);
          setSelectedRow(null);
        }}
        isOpen={reportOpen}
        componentProps={{
          study_id: selectedRow?.report_id,
          studies_id: selectedRow?.approval_type_id,
          isabledDownload: true,
        }}
        drawerWidth={1150}
        title="Report View"
      />
    </>
  );
}

export default ApprovalPage;
