import React, { useContext, useEffect, useState, useRef } from "react";

import { Grid, Typography, Slider, Divider } from "@material-ui/core";
import { OverviewCard2 } from '../../components/DashboardCard';
import { OverviewCard3 } from '../../components/DashboardCard';
import { OverviewCard4 } from '../../components/DashboardCard';
import { OverviewCard5 } from '../../components/DashboardCard';
import { OverviewCard6 } from '../../components/DashboardCard';
import dashboardService from '../../services/dashboardService'

import {
    format, addDays, addWeeks, addMonths, getWeek, startOfWeek,
    startOfMonth, endOfMonth, endOfWeek
} from 'date-fns'

const GaugeCard = (props) => {

    const [Metrics, setMetrics] = useState([])
    const [startDate, setstartDate] = useState('')
    const [endDate, setendDate] = useState('')
    const [dataLabel, setdataLabel] = useState('')
    const dataDepth = props.dataDepth
    const Offset_index = props.Offset_index
    const [DateDisplay, setDateDisplay] = useState(new Date())

    /*     useEffect(() => {
            setDateDisplay(currentDate => {
                let newDate = new Date();
                if (dataDepth === 1) {
                    setstartDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                    setendDate(format(addDays(newDate, Offset_index), 'yyyy-MM-dd'));
                    newDate = addDays(newDate, Offset_index);
                    setdataLabel("Day");
                }
                else if (dataDepth === 2) {
                    setstartDate(format(startOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                    setendDate(format(endOfWeek(addWeeks(newDate, Offset_index), { weekStartsOn: 1 }), 'yyyy-MM-dd'));
                    newDate = addWeeks(newDate, Offset_index);
                    setdataLabel("Week")
                }
                else if (dataDepth === 3) {
                    setstartDate(format(startOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                    setendDate(format(endOfMonth(addMonths(newDate, Offset_index)), 'yyyy-MM-dd'));
                    newDate = addMonths(newDate, Offset_index);
                    setdataLabel("Month");
                }
                return newDate;
    
            });
        }, [dataDepth, Offset_index]);
     */



    useEffect(() => {
        dashboardService.assetDashboard({
            client_id: props.client_id,
            assettype: props.cardNumber,
            plant_id: props.plant_id
        })
            .then(res => {
                //   console.log(res.data)
                setMetrics(res.data)

            })
            .catch(error => {
                console.error("An error occurred:", error);
            });


    }, [props.plant_id]);



    return (
        (() => {

            switch (props.cardNumber) {

                case 0:
                case 1:
                case 2:
                    return (
                        <div className="dg_dash_am">
                            <span className="dg_dash_cc_tagline">{Metrics[0]?.title}</span>
                            <h6 className="dg_dash_am_title">Gauge Status</h6>
                            {Metrics.map((metric) => (
                                <div className="dg_dash_am_gauge_count">
                                    <div className="dg_dash_am_gauge_count_no">{metric?.count_}</div>
                                    <div className="dg_dash_am_gauge_count_title">
                                        {metric?.status}
                                    </div>
                                </div>
                            ))}
                            <div className="dg_dash_am_gauge_count">
                                <div className="dg_dash_am_gauge_count_no">45</div>
                                <div className="dg_dash_am_gauge_count_title">
                                    Overdue Gauges
                                </div>
                            </div>
                            <div className="dg_dash_am_gauge_count">
                                <div className="dg_dash_am_gauge_count_no">102</div>
                                <div className="dg_dash_am_gauge_count_title">
                                    Issued Gauges
                                </div>
                            </div>
                        </div>
                    );

                case 6:
                    return (
                        <Grid >
                            <OverviewCard6
                                name={`${props.cardName}`}
                                backgroundColor="#F9E79F"
                                countData={Metrics}
                            />
                        </Grid>
                    );


                // case 2:
                //     return (
                //         <Grid>
                //             <OverviewCard5
                //                 name={`${props.cardName}`}
                //                 backgroundColor="#73C6B6"
                //                 graphData={Metrics}
                //             />
                //         </Grid>
                //     );


                default:
                    return null



            }
        })()
    )
};

export default GaugeCard;
