import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { OverviewCard } from "./OverviewCard";
import customerDashboardService from "../../../services/customerDashboardService";
import { AuthContext } from "../../../context/AuthContextProvider";

export function CustomerOverview() {
  const [cardData, setCardData] = useState({
    characteristics_count: 0,
    part_count: 0,
    vendor_count: 0,
  });
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    customerDashboardService
      .getCustomerOverview(currentUser?.id)
      .then((res) => {
        setCardData(res?.data);
      });
  }, []);
  return (
    <Grid
      container
      spacing={4}
      className="mt-2"
      style={{ width: "99%", marginLeft: 2 }}
    >
      <Grid item md={4} sm={4} lg={4}>
        <OverviewCard name="Vendor" count={cardData?.vendor_count} />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <OverviewCard name="Parts" count={cardData.part_count} />
      </Grid>
      <Grid item md={4} sm={4} lg={4}>
        <OverviewCard
          name="Characterstics"
          count={cardData?.characteristics_count}
        />
      </Grid>
    </Grid>
  );
}
