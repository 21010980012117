import { Grid, Button } from "@material-ui/core";
import { DGInput } from "../../../../components/shared/DGInput";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { DGDrawer } from "../../../../components/DGDrawer";
import CommonGrid from "../../../../components/CommonGrid";
import StatusFilter from "../../../../components/shared/StatusFilter";
import ActiveInActive from "../../../../components/ActiveInActive";
import { useState, useContext, useEffect } from "react";
import { MacineMaintenanceRequestCreate } from "./macineMaintenanceRequestCreate";
import AddIcon from '@material-ui/icons/Add';
import machineMaintenanceService from "../../../../services/machineMaintenanceService";
import { AuthContext } from "../../../../context/AuthContextProvider";
import { PlantContext } from "../../../../context/PlantContextProvider";
import { StatusContext } from "../../../../context/StatusContextProvider"
import MachineMaintenanaceRequestView from "./machineMaintenanceRequestView";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";




export function MacineMaintenanceRequestList({



}) {

    const [rows, setRows] = useState([]);
    const [requestFormOpen, setRequestFormOpen] = useState(false);
    const [recordFormOpen, setRecordFormOpen] = useState(false);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { setStatus } = useContext(StatusContext);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRow_, setSelectedRow_] = useState(null);
    const [maxID, setMaxID] = useState(0);
    const [mainTitle, setMainTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");





    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            callback: changeStatus,
        }));
        reloadGridData();

    }, []);

    useEffect(() => {

        reloadGridData()
    }, [currentUser, globalPlant]);


    function reloadGridData() {
        if (!currentUser || !globalPlant) return;

        machineMaintenanceService.getMachineMaintenanceRequest(
            currentUser.client_id,
            globalPlant?.globalPlant?.id)

            .then((res) => {
                const data = res?.data ?? []
                const filteredData = data.filter((item) => item.request_result === null)
                setMaxID(data.reduce((max, item) => item.request_number > max ? item.request_number : max, data[0].request_number))
                setRows(filteredData);
            })
    }

    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return machineMaintenanceService.updateMachineMaintenanceCLPReq(rowId, { enabled: newStatus });
    }




    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        {
            field: "request_number",
            headerName: "Request Number",
            filter: "agTextColumnFilter",
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            loadRequestForm(data)
                        }}
                    >
                        Request# {params?.data?.request_number}
                    </Button>
                );
            },
        },
        {
            field: "plan_number",
            headerName: "Plan Number",
            minWidth: 300,
            sorting: true,
            resizable: true,
            cellRenderer: (params) => {
                return (
                    <span>{`${params?.data?.plan_number} / ${params?.data?.plan_name}`}</span>
                );
            },
        },

        {
            field: "machine_name",
            headerName: "Machine",
            minWidth: 200,
            resizable: true,
            sorting: true,
            cellRenderer: (params) => {
                if (params.data.machine_number) {
                    return `${params?.data?.machine_number} / ${params?.data?.machine_name}`
                } else {
                    return ""
                }
            }
        },
        {
            field: "evaluation_date",
            headerName: "Evaluation Date",
            minWidth: 150,
            resizable: true,
            filter: "agDateColumnFilter",
            floatingFilter: true,
            valueGetter: (params) => {
                return new Date(params?.data?.evaluation_date).toLocaleDateString();
            },


        },
        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.plan_number + " / " + params?.data?.plan_name,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (

                    < Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.q_number ?? "",
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button >
                );
            },
        }

    ]


    function loadRequestForm(data) {
        setSelectedRow(data?.id);
        setRecordFormOpen(true);
        setSelectedRow_(data);

    }

    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setRequestFormOpen(true);
                    }}
                >
                    Maintenance Request
                </Button>

            </>
        );
    }




    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <CommonGrid
                        columns={columns}
                        rows={rows}
                        ButtonComponent={ButtonComponent}
                        gridheight={115}
                    />

                </Grid>

            </Grid>

            <DGDrawer
                isOpen={requestFormOpen}
                setOpen={(state, reqID) => {
                    if (reqID) {
                        setRequestFormOpen(state);
                        setSelectedRow(reqID.id);
                        setRecordFormOpen(true);
                    } else {
                        setRequestFormOpen(state);
                    }
                    reloadGridData();
                }}
                title="Maintenance Request"
                drawerWidth={1000}
                Component={MacineMaintenanceRequestCreate}


            />

            <DGDrawer
                Component={MachineMaintenanaceRequestView}
                isOpen={recordFormOpen}
                setOpen={() => {
                    setRecordFormOpen(false);
                    reloadGridData()
                }}
                title={`Request Number: ${selectedRow_?.request_number}`}
                drawerWidth={1200}
                rowHeight={150}

                componentProps={selectedRow}

            />
        </>

    )



}