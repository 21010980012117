/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { blueGrey } from "@material-ui/core/colors";
import { rgba } from "polished";
import { useParams } from "react-router-dom";

import { AuthContext } from "../../../../context/AuthContextProvider"
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";

import spcService from "../../../../services/spcService";
import userService from "../../../../services/userService";
import productService from "../../../../services/productService";

import ActiveInActive from "../../../../components/ActiveInActive";
import CommonGrid from "../../../../components/CommonGrid";
import StatusFilter from "../../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../../components/DGDrawer";
import AddIcon from "@material-ui/icons/Add";

import { SpcPlanFormNew } from "./SpcPlanFormNew";

function SpcPlanListing({ plant = "", part = "" }) {
  //component states
  const [csData, setCsData] = useState([]);
  const [maskData, setMaskData] = useState([]);
  const [users, setUsers] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [gridApi, setGridApi] = useState(null);
  const [productDetail, setProductDetail] = useState({});

  //component Triggers
  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: getAllPlans,
      callback: updateSpcplan,
    }))

    productService.getProductById(id).then((res) => {
      setProductDetail(res?.data ?? {});
    });
  }, []);

  function updateSpcplan(dummy1, dummy2, newStatus, rowId) {
    return spcService.updateSPCPlan(rowId, { enabled: newStatus });
  }

  function getAllPlans() {
    spcService.getSPCInputTable({
      query_id: id
    }).then((res) => {
      setPlans(res?.data ?? []);
    });
  }

  const onGridReady = params => {
    getAllPlans();
    setGridApi(params.api);
    const filterModel = {
      enabled: {
        filterType: 'number',
        type: 'equals',
        filter: 1
      }
    };
    params.api.setFilterModel(filterModel);
  };


  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }

  //style Definition
  const classes = useStyles();

  //Component States
  const [newPlanOpen, setNewPlanOpen] = useState(false);
  const [plans, setPlans] = useState([]);

  //column Definition
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },

    {
      field: "plan_number",
      headerName: "SPC Plan Number",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },

    {
      field: "char_name",
      headerName: "Characterstics",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Characterstics Specification",
      cellRenderer: (params) => (
        <GetFormattedValue
          value={
            params?.data?.specification !== null &&
              params?.data?.upper_specification !== null &&
              params?.data?.lower_specification !== null
              ? `${params?.data?.specification},${params?.data?.upper_specification},${params?.data?.lower_specification}`
              : null
          }
        />
      ),
      resizable: true,
      minWidth: 300,
    },

    {
      field: "machine_spindle_name",
      headerName: "Machine Spindle",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 180,
    },

    {
      field: "report_name",
      headerName: "Evaluation Number",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },

    {
      field: "mask_value",
      headerName: "Mask Value",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 180,
    },
    {
      field: "sample_size",
      headerName: "Sample Size",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 180,
    },
    {
      field: "study",
      headerName: "Evaluation Method",
      resizable: true,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      minWidth: 180,
    },

    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={params?.data?.enabled === 1 ? () =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.report_name,
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            })) : null}
        />

      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity: params?.data?.report_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Grid
        item
        className={classes.addButtonLayout}
        md={12}
        sm={12}
        lg={12}
        xs={12}
        justifyContent="flex-end"
      ></Grid>
      <Grid item md={12} sm={12} lg={12} xs={12}>
        <CommonGrid
          onGridReady={onGridReady}
          needExport={true}
          ButtonComponent={() => (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              className={classes.addButton}
              onClick={() => {
                setNewPlanOpen(true);
              }}
            >
              PLAN
            </Button>
          )}
          rows={plans}
          columns={columns}
          gridheight={213}
        />
      </Grid>
      <DGDrawer
        Component={SpcPlanFormNew}
        componentProps={{ users, csData, maskData, part, plant }}
        isOpen={newPlanOpen}
        setOpen={() => {
          setNewPlanOpen(false);
          getAllPlans();
        }}
        drawerWidth={700}
        title={"Add Plan for" + " " + productDetail?.part_number + "  " + productDetail?.part_name}
        needSave
      />
      {/* {newPlanOpen && (
        <SpcPlanForm
          users={users}
          csData={csData}
          maskData={maskData}
          plant={plant}
          part={part}
          isOpen={newPlanOpen}
          onSuccess={(e) => {
            if (e) {
              getAllPlans();
              setNewPlanOpen(false);
            } else {
              setNewPlanOpen(false);
            }
          }}
        />
      )} */}
    </>
  );
}
export default SpcPlanListing;
const useStyles = makeStyles({
  addButton: {
    textAlign: "end",
  },
  addButtonLayout: {
    textAlign: "end",
    borderBottom: 0,
  },
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});
