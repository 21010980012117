import React, { useEffect, useState } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import GembaUnderInspectionOpen from "./productionInspectionOpen"

export default function GembaUnderInspectionListing({ setSelectedCard = () => { } }) {
    const [subtitle, setSubtitle] = useState("");
    const [listOpen, setListOpen] = useState(true);


    return (
        <>
            {" "}
            <DGDrawer
                Component={GembaUnderInspectionOpen}
                title="Production Inspection Open"
                isOpen={listOpen}
                setOpen={(state) => {
                    setListOpen(state);
                    setSelectedCard(-1);
                }}
                subtitle={subtitle}
                drawerWidth={1300}
                needSave
            />
        </>
    );
}
