import React, { useContext, useEffect, useState } from "react";
import { array, date, object, string } from "yup";
import productionInspection from "../../../services/productionInspection";
import CommonGrid from "../../../components/CommonGrid";
import { Grid } from "@material-ui/core";
import ImageLinkCellRenderer from "../../../components/ImageLinkCellRenderer"
import RotatedTextCellRenderer from "../../../components/cellRenderers/RotatedTextCellRenderer"

export function DefectCatalogue({
    componentProps = null,
    closeForm = () => { },
    setHeaderButtons = () => { },
    onGridReady = () => { },
}) {
    const [rows, setRows] = useState([]);
    const [defectCat, setdefectCat] = useState("");
    const [defectCatOpen, setdefectCatOpen] = useState(false)


    const columns = [
        {
            field: "document_name",
            headerName: "Reference Number",
            resizable: true,
            minWidth: 50,
            autoHeight: true,
            //cellRendererFramework: RotatedTextCellRenderer
        },
        {
            field: "document_link",
            headerName: "Inspection Guidelines",
            resizable: true,
            minWidth: 950,
            autoHeight: true,
            cellRendererFramework: ({ value, data }) => (
                <ImageLinkCellRenderer
                    imageURL={value}
                    altText={`Image ${data.document_name}`}
                    linkURL={data.document_link}
                />
            ),
        },

    ]

    useEffect(() => {
        LoadDefectCat();
    }, []);


    function LoadDefectCat() {
        productionInspection
            .getDefectCatalogue(componentProps)
            .then((res) => {
                setRows(res?.data ?? [])
            })
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item md={12} sm={12} lg={12}>
                    <CommonGrid
                        rows={rows}
                        columns={columns}
                        isOpen={defectCatOpen}
                        gridheight={0}
                        onGridReady={onGridReady}
                        needExport={false}

                    />

                </Grid>
            </Grid>
        </>

    );

    ;
}