import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { format, setHours, setMinutes } from 'date-fns';


export default function HourChips({
  blockfrom = 0,
  onTimeSelect = () => { },
  endTime = "",
  shiftStart = 0

}) {

  const useStyles = makeStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '17px',
      marginBottom: '5px',
    },
    chip: {
      fontSize: '15px',
      padding: '9px',
      textAlign: 'center',
      width: 'fit-content',
      marginBottom: '5px',
    },
    chipPM: {
      backgroundColor: '#FFB6C1',
    },
    chipAM: {
      backgroundColor: '#ADD8E6',
    },
  });


  const classes = useStyles();
  const hours = Array.from({ length: 24 }, (_, index) => index + 0);

  const handleClick = (hour, period) => {
    const date = new Date();
    const hoursIn24 = period === 'PM' ? (hour % 12) + 12 : hour % 12;
    const formattedDate = format(
      setMinutes(setHours(date, hoursIn24), 0),
      'HH:mm'
    );

    onTimeSelect(formattedDate);
  };


  return (
    <div className={classes.root}>
      {hours.filter(hour => hour < 12 && hour >= shiftStart).map((hour) => (
        <Chip
          key={`${hour} AM`}
          label={`${hour} AM`}
          variant="outlined"
          className={`${classes.chip} ${classes.chipAM}`}
          onClick={() => handleClick(hour, 'AM')}
          disabled={(hour <= parseInt(blockfrom) || parseInt(blockfrom) < shiftStart) && endTime}
        />
      ))}

      <Chip
        key={`12 PM`}
        label={`12 PM`}
        variant="outlined"
        className={`${classes.chip} ${classes.chipPM}`}
        onClick={() => handleClick(12, 'PM')}
        disabled={(parseInt(blockfrom) >= 12 || parseInt(blockfrom) < shiftStart) && endTime}
      />


      {hours.filter(hour => hour > 12 && hour < 24).map((hour) => (
        <Chip
          key={`${hour - 12} PM`}
          label={`${hour - 12} PM`}
          variant="outlined"
          className={`${classes.chip} ${classes.chipPM}`}
          onClick={() => handleClick(hour, 'PM')}
          disabled={(hour <= parseInt(blockfrom) || parseInt(blockfrom) < shiftStart) && endTime}

        />
      ))}


      {hours.filter(hour => hour <= shiftStart).map((hour) => (
        <Chip
          key={`${hour} AM`}
          label={`${hour} AM`}
          variant="outlined"
          className={`${classes.chip} ${classes.chipAM}`}
          onClick={() => handleClick(hour, 'AM')}
          disabled={hour <= parseInt(blockfrom) && endTime && parseInt(blockfrom) < shiftStart}
        />
      ))}

    </div>
  );
};

