import { Fab, Grid, makeStyles, Typography, TextField } from "@material-ui/core";
import { useEffect, useContext, useState, } from "react";
import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { grey } from "@material-ui/core/colors";
import { DGInput } from "./shared/DGInput";
import { set } from "date-fns";


export default function DialPadComponent({
  eachDial = () => { },
  submit = () => { },
  onClear = () => { },
  event = null,
  maskValue,
  maskEvaluation,
  measuredValue = () => { },
  prodRecordFlag = ""
}) {
  const [dailValue, setDialValue] = useState("");
  const [tempNumber, setTempNumber] = useState("");
  const hasDecimalPoint = maskValue && maskValue.includes(".");


  useEffect(() => {
    if (maskValue) {
      setDialValue(maskValue);
    }
  }, [maskValue, maskEvaluation])

  const useStyles = makeStyles({

    number: {
      backgroundColor: grey[200],
      textAlign: "center"
    },

    smallText: {
      fontSize: "20px",
      marginRight: "5px",
      marginLeft: "5px"
    },

    dialValueDisplay1: {
      'font-weight': 'bold',
      'font-size': '25px',
      'text-align': 'right',
      padding: '3px',
    },
    dialValueDisplay2: {
      // 'font-weight': 'bold',
      'font-size': '25px',
      'text-align': 'right',
      padding: '3px',
    },

  });

  const classes = useStyles();


  const renderDialPad = () => {
    if (prodRecordFlag === 10) {
      var numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", " ", "0", " ", "Del", "Clear", "Next"];
    }
    else if (!hasDecimalPoint) {
      var numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "-", "0", ".", "Del", "Clear", "Next"];
    }
    else {
      var numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "-", "0", "", "Del", "Clear", "Next"];
    }
    return numbers.map((number) => (
      <Grid item sm={4}
        onClick={() => {
          dialClicked(number);
        }}
        style={{ marginBottom: "10px", textAlign: "center" }}
      >
        <Fab variant="extended"
          style={{ justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h2"
            className={number === "Clear" || number === "Next" || number === "Del" ?
              classes.smallText : ""}>
            {number}
          </Typography>
        </Fab>
      </Grid>
    ));
  };

  useEffect(() => {
    if (tempNumber) {
      if (maskEvaluation === 26) {
        setDialValue(parseFloat(maskValue) + parseFloat(tempNumber))
      }
      else {
        setDialValue(maskValue + tempNumber)

      }
    }
  }, [tempNumber])

  const dialClicked = (number) => {
    const tempDecimal = tempNumber && tempNumber.includes(".");
    const tempMinus = tempNumber && tempNumber.includes("-");
    let num1

    if (number === "Clear") {
      setTempNumber("");
      setDialValue(maskValue);


    }
    else if (number === "Next") {
      measuredValue(dailValue)
      setTempNumber("");
      setDialValue(maskValue);


    }
    else if (number === "Del") {
      setTempNumber(tempNumber => tempNumber.slice(0, -1))
      if ((tempNumber.slice(0, -1)).length === 0) {
        setDialValue(maskValue);
      }
    }
    else {
      if (number === "-" && maskEvaluation !== 25) {
        if (!tempMinus) {
          num1 = number.concat(tempNumber)
        }
        else {
          num1 = tempNumber.slice(1);
        }
      }

      else if (number === "." && !tempDecimal) {
        if (!tempMinus) {
          num1 = tempNumber.concat(number);
        }
        else {
          const temp2 = tempNumber.slice(1);
          num1 = ("-".concat(temp2).concat(number));
        }
      }

      else if (number !== "." && number !== "-") {
        num1 = (tempNumber.concat(number))
      }
    }

    if (num1) {
      setTempNumber(num1);

    }

  }



  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    }}>

      <div style={{
        display: "flex",
        flexDirection: "row"
      }}>


        <TextField
          variant="outlined"
          value={tempNumber}
          label="Entered Value"
          onChange={(e) => setDialValue(e.target.value)}
          marginBottom="10px"
          style={{ padding: "10px", width: "50%", marginTop: "5%", marginBottom: "10px" }}
          InputProps={{
            classes: {
              input: classes.dialValueDisplay2
            }
          }}
        />

        {prodRecordFlag !== 10 && (
          <TextField
            variant="outlined"
            label="Measured Value"
            value={dailValue ? dailValue : ""}
            onChange={(e) => setDialValue(e.target.value)}
            style={{ padding: "10px", width: "50%", marginTop: "5%", marginBottom: "10px" }}
            InputProps={{
              classes: {
                input: classes.dialValueDisplay1,
              },
            }}
          />
        )}

      </div>

      <Grid container
        justifyContent="center" alignItems="center"
        style={{ marginBottom: "10px" }}
      >
        {renderDialPad()}
      </Grid>

    </div>
  );
}
