import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import piService from "../../../services/piService";
import { CalibrationFilter } from "./CalibrationFilter";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import { InitiateCalibration } from "./InitiateCalibrationdialog";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import { DGDrawer } from "../../../components/DGDrawer";
import { CalibrationForm } from "../calibrationHistory/calibrationForm";
import { PlantContext } from "../../../context/PlantContextProvider";
import { RecordCalibrationForm } from "./RecordCalibrationForm";
import { Authorize } from "./Authorize/Authorize";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";

export function AllCalibrations() {
  const [rows, setRows] = useState([]);
  const [statusId, setStatusId] = useState();
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext);
  const [selectedRow, setSelectedRow] = useState({});
  const [tableName, setTableName] = useState("");
  const [initiateOpen, setInitiateOpen] = useState(false);
  const [recordOpen, setRecordOpen] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selctedData, setSelectedData] = useState({});
  const [formOpen, setFormOpen] = useState(false);
  const [piAgencyList, setPIAgencyList] = useState([]);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const [gridApi, setGridApi] = useState(null);
  const [recordactivity, setRecordActivity] = useState("");

  useEffect(() => {
    if ((currentUser?.client_id, globalPlant?.globalPlant)) {
      loadAllFilters();
    }
  }, [currentUser, globalPlant]);

  useEffect(() => {
    loadPiAgencyList();
    setHeaderPageTitle(`Gauge Calibration`);
  }, []);

  function loadPiAgencyList() {
    piService.getActivePIlist(
      currentUser.client_id,
      10)
      .then((res) => {
        setPIAgencyList(res?.data ?? []);
      });
  }

  useEffect(() => {
    if (statusId && currentUser?.client_id && globalPlant?.globalPlant) {
      reloadGriddata();
    }
  }, [statusId, currentUser, globalPlant]);
  useEffect(() => {
    return () => {
      ACTION_TYPE.view = false;
    };
  }, []);

  function loadAllFilters(table_number = null, query_name = null) {
    piService
      .getCalibOverview(currentUser?.client_id, globalPlant?.globalPlant?.id)
      .then((res) => {
        setFilters(res?.data ?? []);
        if (res?.data?.length) {
          setStatusId(table_number ? table_number : res?.data[0]?.table_number);
          setTableName(query_name ? query_name : res?.data[0]?.query_name);
        }
      });
  }
  function loadFormData(data) {
    piService
      .getPIrequestforID(data?.pi_request_id ?? "")
      .then((res) => {
        setSelectedData({ ...data, apiData: res?.data });
        return Promise.resolve();
      })
      .then(() => {
        setFormOpen(!formOpen);
      });
  }

  function reloadGriddata() {
    if (currentUser?.client_id && globalPlant?.globalPlant?.id) {
      setSelectedRow(null);
      piService
        .getCalibOverdue(
          currentUser?.client_id,
          statusId,
          globalPlant?.globalPlant?.id
        )
        .then((res) => {
          setRows(res?.data ?? []);
        });
    }
  }

  const columnDefs = [
    {
      headerName: "#",
      maxWidth: 70,
      sortable: false,
      pinned: "left",
      valueGetter: "node.rowIndex + 1",
      cellStyle: { backgroundColor: "#f1f1f1" },
      resizable: true,
      cellRenderer: (params) => {
        if (params.value !== undefined) {
          return params.value;
        } else {
          return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
        }
      },
    },
    {
      field: "report_name",
      headerName: "Report Number",
      sortable: true,
      minWidth: 250,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
      hide: statusId === "24" || statusId === "23",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              loadFormData(params?.data);
            }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "serial_number",
      headerName: "Gauge Number",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      pinned: "left",
      filter: "agTextColumnFilter",
      cellRenderer: (params) => {
        return (
          <Link
            style={{ color: "#2196f3" }}
            variant="body2"
            to={`/pi/${params.data.gauge_item_id}`}
          >
            {params.value}
          </Link>
        );
      },
    },

    {
      field: "family_name",
      headerName: "Gauge Name",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },

    /*
    {
      field: "location",
      headerName: "Location",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
      hide: statusId === "21" || statusId === "22",
    },

    
    {
      field: "pi_due_date",
      headerName: "Calibration Due",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        params?.data?.pi_due_date
          ? new Date(params.data.pi_due_date).toLocaleDateString()
          : "",
      filterParams: filterParams,
      hide: statusId === "21" || statusId === "22",
    },

    */

    {
      field: "pi_last_date",
      headerName: "Last calibrated",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      valueGetter: (params) =>
        params?.data?.pi_last_date
          ? new Date(params.data.pi_last_date).toLocaleDateString()
          : "",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
      //hide: statusId === "21" || statusId === "22",
    },
    {
      field: "pi_due_date",
      headerName: "Calibration due",
      filter: "agDateColumnFilter",
      valueGetter: (params) =>
        params?.data?.pi_due_date
          ? new Date(params.data.pi_due_date).toLocaleDateString()
          : "",
      resizable: true,
      sortable: true,
      floatingFilter: true,
      minWidth: 200,
      hide: statusId === "21" || statusId === "22",
    },
    {
      field: "Due in Days",
      headerName: "Due In Days",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
      hide: statusId === "21" || statusId === "22",
    },
    {
      field: "calib_agency",
      headerName: "Calibration Agency",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agMultiColumnFilter",
      editable: statusId === "23" || statusId === "24" || statusId === "21",
      cellEditor: "agRichSelectCellEditor",
      cellEditorPopup: true,
      cellEditorParams: {
        values: piAgencyList,
        cellHeight: 20,
        formatValue: (value) => value?.NABL_Agency,
        searchDebounceDelay: 500,
      },
    },

    {
      field: "calib_frequency",
      headerName: "Calibration Frequency",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
    },

    /*
    {
      field: "family_number",
      headerName: "Gauge Family",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
    },
     */

    {
      field: "location",
      headerName: "Location",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      resizable: true,
      filter: "agTextColumnFilter",
      hide: statusId === "21" || statusId === "22",
    },

    {
      field: "family_number",
      headerName: "Gauge Family",
      sortable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      resizable: true,
      cellRenderer: (params) => (
        <Link
          variant="body2"
          style={{ color: "#2196f3" }}
          to={`/gauge-details/${params.data.gauge_item_id}`}
        >
          {params.value}
        </Link>
      ),
    },
  ];

  function onRowSelection(data) {
    setSelectedRow(data[0]);
  }

  const onRowValueChanged = useCallback((params) => {
    var data = params.data;
    updatePiAgency(
      { gauge_item: data?.gauge_item_id, pi_agency_id: data?.calib_agency?.id },
      params?.data,
      params
    );
  }, []);

  function updatePiAgency(payload, data, params) {
    let itemsToUpdate = [];
    piService.createInlineAgency(payload).then((res) => {
      // reloadGriddata();
      data.calib_agency = data?.calib_agency?.NABL_Agency;
      itemsToUpdate?.push(data);
      params?.api?.applyTransaction({ update: itemsToUpdate });
    });
  }

  function ButtonComponent() {



    return (
      <>

        {(statusId === "23" || statusId === "24"
          && currentUser.client_id !== "e88e8be1-ad7c-473b-985f-229ff7794457")
          && selectedRow ? (
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<PlayArrowOutlinedIcon />}
            onClick={() => setInitiateOpen(true)}
          >
            INITIATE
          </Button>
        ) : null}
        {statusId === "21" && selectedRow ? (
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            startIcon={<CloudUploadOutlinedIcon />}
            onClick={() => {
              setRecordOpen(true);
              setRecordActivity("record");
            }}
          >
            RECORD
          </Button>
        ) : null}
        {statusId === "22" && selectedRow ? (
          <>
            {" "}
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              startIcon={<CheckCircleOutlineIcon />}
              onClick={() => setApproveOpen(true)}
              style={{ marginRight: 9 }}
            >
              AUTHORIZE
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              startIcon={<CloudUploadOutlinedIcon />}
              onClick={() => {
                setRecordOpen(true);
                setRecordActivity("re-record");
              }}
            >
              RE-RECORD
            </Button>
          </>
        ) : null}

      </>
    );
  }

  function afterInitiateCalibration(status, submitstatus = false) {
    setInitiateOpen(status);
    if (submitstatus) {
      loadAllFilters(statusId, tableName);
      reloadGriddata();
    }
  }
  function afterRecordCalibration(status, submitstatus = false) {
    setRecordOpen(status);
    if (submitstatus) {
      setRecordOpen(false);
      loadAllFilters(statusId, tableName);
      reloadGriddata();
    } else {
      setRecordOpen(false);
    }
  }
  function afterApproveCalibration(status, submitstatus = false) {
    setApproveOpen(status);
    if (submitstatus) {
      setApproveOpen(false);
      setRejectOpen(false);
      loadAllFilters(statusId, tableName);
      reloadGriddata();
    } else {
      setApproveOpen(false);
    }
  }

  function onGridReady(params) {
    setGridApi(params?.api);
  }

  const classes = useStyle();
  return (
    <Grid
      container
      justifyContent="space-around"
      alignContent="center"
      spacing={2}
    >
      <CalibrationFilter
        onClick={(id, tableName) => {
          setStatusId(id);
          setTableName(tableName);
        }}
        filters={filters}
      />
      <Grid item md={12} sm={12} lg={12}>
        <CommonGrid
          ButtonComponent={ButtonComponent}
          rows={rows}
          columns={columnDefs}
          onRowSelect={onRowSelection}
          onRowValueChanged={onRowValueChanged}
          tableName={tableName}
          editType="fullRow"
          onGridReady={onGridReady}
          gridheight={200}
        />
      </Grid>
      {selectedRow && initiateOpen && (
        <InitiateCalibration
          isOpen={initiateOpen}
          setOpen={afterInitiateCalibration}
          gaugeItem={selectedRow}
        />
      )}
      {selectedRow && recordOpen && (
        <DGDrawer
          Component={RecordCalibrationForm}
          isOpen={recordOpen}
          setOpen={(status, data) =>
            afterRecordCalibration(true, data?.status === "s")
          }
          title={"Record Calibration"}
          componentProps={{
            gauge_item: selectedRow,
            activity: recordactivity,
            pi_result_id: selectedRow?.pi_result_id,
          }}
          needSave
        />
      )}
      <DGDrawer
        Component={Authorize}
        isOpen={approveOpen}
        setOpen={(status, data) => {
          afterApproveCalibration(true, data?.status === "s");
        }}
        title="Authorize Calibration"
        componentProps={{ ...selectedRow }}
      />

      <DGDrawer
        componentProps={selctedData}
        Component={CalibrationForm}
        isOpen={formOpen}
        drawerWidth={500}
        setOpen={setFormOpen}
        title={selctedData?.report_name ?? ""}
        subtitle={selctedData?.serial_number ?? ""}
      />
    </Grid>
  );
}

const useStyle = makeStyles({ button: {} });
var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
