import { Box, Button, Grid } from "@material-ui/core";
import { isInteger, useFormik } from "formik";
import React, { useState, useEffect, useContext } from "react";
import { array, object, string } from "yup";

import { DGInput } from "../../../components/shared/DGInput";
import { DGSelect } from "../../../components/shared/DGSelect";
import { AlertContext } from "../../../context/AlertContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import plantService from "../../../services/plantService"
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import userService from "../../../services/userService";
import errorLog from "../../../services/errorLog";



export function AddNewOperatorForm({
    Footer = () => null,
    closeForm = () => { }

}) {
    const { v4: uuidv4 } = require("uuid");
    const { currentUser } = useContext(AuthContext);
    const { updateAlert } = useContext(AlertContext);
    const [gridApi, setGridApi] = useState(null);
    const [plantOptions] = useState([])
    const [customerOptions] = useState([])
    const [processOptions] = useState([])
    const globalPlant = useContext(PlantContext)

    let form = useFormik({
        initialValues: {
            operatorName: null,
            OperatorID: null,
            plantOptions: [],
            plant: null
        },
        validationSchema: object().shape({
            operatorName: string().required()
        }),

        onSubmit: onAdd

    });


    let gridForm = useFormik({
        initialValues: {
            gridData: []
        },
        validationSchema: object({
            gridData: array().min(1)
        }),
        onSubmit: onSave
    });


    function onAdd() {
        let data = [];
        data?.push({
            operator_name: form.values.operatorName,
            operator_id: form.values.OperatorID

        });
        gridForm.setFieldValue("gridData", [...gridForm.values.gridData, ...data])
        form.setFieldValue("operatorName", '')
        form.setFieldValue("OperatorID", '')


    }

    function removeDetailRow(rowIndex, data) {
        let plans = form.values.gridData;
        plans.splice(rowIndex, 1);
        gridApi.api.updateRowData({ remove: [data] });
        const rowsToDisplay = gridApi.api.rowModel.rowsToDisplay;
        const rowDataArray = rowsToDisplay.map((rowNode) => rowNode.data);
        //setRefreshedData(rowDataArray)
    }

    function onGridReady(params) {
        setGridApi(params.api);

    }

    useEffect(() => {
        plantService.getDefaultPlant(currentUser?.id)
            .then((res) => {
                const plantOptions = res?.data ?? [];
                form.setFieldValue("plantOptions", plantOptions);
                const defaultPlant = plantOptions.find((option) => option.default_flag === 1);
                if (defaultPlant) {
                    form.setFieldValue('plant', defaultPlant);
                }
            })
    }, [currentUser?.id, currentUser?.client_id])


    useEffect(() => {
        plantService.getDefaultPlant(currentUser?.id)
            .then((res) => {
                const plantOptions = (res?.data ?? []).slice(1);
                form.setFieldValue("plantOptions", plantOptions);
            })
    }, []);

    useEffect(() => {
        if (form.values.plantOptions.length < 2 || currentUser.user_role > 3) {
            form.setFieldValue("plant", (globalPlant?.globalPlant?.globalPlant));
            form.setFieldValue("plant_flag", 0)

        } else {
            if (currentUser.default_plant) {
                const defaultPlant = form.values.plantOptions.find(plant => plant.default_flag === 1)
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 1)

            } else {
                const defaultPlant = form.values.plantOptions[0]
                form.setFieldValue("plant", defaultPlant)
                form.setFieldValue("plant_flag", 2)
            }
        }
    }, [form.values.plantOptions]);

    const columns = [
        /*      {
                 cellRenderer: DeleteRenderer,
                 minWidth: 80,
                 cellRendererParams: {
                     onClick: (data, rowIndex) => {
                         removeDetailRow(rowIndex, data);
                     },
                 },
             }, */

        {
            field: "serial_number",
            headerName: "Serial No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 75,
            pinned: "left",

        },
        {
            field: "operator_id",
            headerName: "Operator ID",
            filter: "agTextColumnFilter",
            minWidth: 150,
        },
        {
            field: "operator_name",
            headerName: "Operator Name",
            minWidth: 250,
            filter: "agTextColumnFilter",
            resizable: true,
            autoHeight: true,


        },


    ]

    //console.log(gridForm.values)

    function onSave() {
        let payload = [];
        gridApi.forEachNode((node) => {
            let data = node?.data;
            payload.push({
                id: uuidv4(),
                client_id: currentUser?.client_id,
                plant_id: form.values.plant.id,
                operator_name: data.operator_name,
                operator_id: data.operator_id,
                created_by: currentUser?.created_by,
                enabled: 1,
            })
        })
        userService.createOperator(payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    open: true,
                    message: "Operators added successfully.",
                    type: "success",
                });
            })
            .catch((err) => {
                let payload = {
                    id: uuidv4(),
                    error_description: err.message,
                    error_location: "createOperator",
                    client_id: currentUser.client_id,
                    user_id: currentUser.id,
                    entity_id: "",
                };
                errorLog.createErrorLog(payload)
                updateAlert({
                    open: true,
                    message: "Failed to added people",
                    type: "error",
                });
            });
    }



    return (

        <Grid container spacing={3}>
            <Grid item md={12} sm={12} lg={12}>
                <DGSelect
                    id="plant"
                    label="Plant"
                    options={form.values.plantOptions}
                    getOptionLabel={(option) => option.name}
                    value={form.values.plant}
                    onChange={(val) => {
                        form.setFieldValue("plant", val);
                        gridForm.setFieldValue("gridData", '')
                    }}

                    required
                />
            </Grid>
            <Grid item md={8} sm={8} lg={8}>
                <DGInput
                    id="operatorName"
                    label="Operator Name"
                    value={form.values?.operatorName}
                    onChange={form.handleChange}
                    required
                    isFormik

                />
            </Grid>
            <Grid item md={4} sm={4} lg={4}>
                <DGInput
                    id="OperatorID"
                    label="OperatorID"
                    value={form.values?.OperatorID}
                    onChange={form.handleChange}
                    isFormik


                />
            </Grid>
            <Grid item md={12} lg={12} sm={12}>
                <Button
                    color="primary"
                    variant="outlined"
                    disabled={!form.isValid}
                    onClick={form.handleSubmit}
                    size="small"
                >
                    Add
                </Button>
            </Grid>

            <Grid item md={12} sm={12} lg={12}>
                <CommonGrid

                    rows={gridForm.values.gridData?.length ? gridForm.values.gridData : []}
                    columns={columns}
                    onGridReady={onGridReady}

                />
            </Grid>


            <Footer
                onSave={gridForm.handleSubmit}
                isValid={gridForm.isValid}
            />
        </Grid>
    );

}
export default AddNewOperatorForm;