import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState, useRef } from "react";
import AddIcon from "@material-ui/icons/Add";
import { deepOrange, blue, green, red, yellow, } from "@material-ui/core/colors";

import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import cuttingToolService from "../../../services/cuttingToolService";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";

import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { DGDrawer } from "../../../components/DGDrawer";
import CommonGrid from "../../../components/CommonGrid";
import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { grid, maxWidth, minWidth } from "@material-ui/system";
import { set } from "date-fns";
import { CuttingToolRefurbishInitiate } from "./cuttingToolRefurbishInitiate";
import { CuttingToolRefurbishApprove } from "./cuttingToolRefurbishApprove";
import { CuttingToolRefurbishView } from "./cuttingToolRefurbishView";
import { fi, fil } from "date-fns/locale";
import { format } from "date-fns";



export function CuttingToolRefurbish() {
    const { setHeaderPageTitle } = useContext(PageTitleContext);
    const [masterRows, setMasterRows] = useState([]);
    const [rows, setRows] = useState([]);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [Under_Refurbishing_, setUnder_Refurbishing] = useState(0);
    const [ForApproval_, setForApproval_] = useState(0);
    const [History_, setHistory_] = useState(0);
    const classes = useStyles();
    const [selected, setSelected] = useState(109);
    const { setStatus } = useContext(StatusContext);
    const [gridFilter, setGridFilter] = useState([]);
    const [initiateForm, setInitiateForm] = useState(false);
    const [approveForm, setApproveForm] = useState(false);
    const [viewForm, setViewForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [componentProps, setComponentProps] = useState(null);

    setHeaderPageTitle("Cutting Tool Refurbish");


    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);

    useEffect(() => {
        reloadGridData()
    }, [globalPlant]);

    const statuses = [
        { title: 'Refurbishing Intiated', background: yellow[100], value: Under_Refurbishing_, id: 109 },
        { title: 'For Approval', background: blue[100], value: ForApproval_, id: 110 },
        { title: 'History', background: green[200], value: History_, id: 100 }
    ];

    useEffect(() => {
        setUnder_Refurbishing(gridFilter.filter(item => item.ctool_status_id === 109).length);
        setForApproval_(gridFilter.filter(item => item.ctool_status_id === 110).length);
        setHistory_(gridFilter.filter(item => item.ctool_status_id < 109).length);

    }, [gridFilter]);

    function handleClick(id) {
        if (selected === id) {
            setSelected(null);
            updateGridData(null)
            return;
        } else {
            setSelected(id);
            updateGridData(id)
        }
    }

    const handleFilterApplied = (event) => {
        const allDisplayedNodes = event.api.getRenderedNodes();
        const displayedData = allDisplayedNodes.map(node => node.data);
        setGridFilter(displayedData);
    };

    const StatusGridItem = ({ title, background, value, onClick, selected }) => (
        <Grid item container direction="column" xs={3}
            className={`${classes.common} ${selected ? classes.selected : ''}`}
            onClick={onClick}
            style={{ background }}
        >
            <Typography align="left" variant="h6" className={classes.header}>
                {title}
            </Typography>
            <Typography align="left" className={classes.count} variant="h1">
                {value}
            </Typography>
        </Grid>
    );

    function reloadGridData() {
        cuttingToolService.getCToolNumberRefurbishTable(
            {
                plant_id: globalPlant?.globalPlant?.id,
                client_id: currentUser.client_id,
            }

        ).then((res) => {
            setRows(res.data);
            setGridFilter(res.data);
            setMasterRows(res.data);
        });
    }

    function loadForm(updatedData) {
        setSelectedRow(updatedData);
        if (updatedData.ctool_status_id === 109) {
            setInitiateForm(true);
        } else if (updatedData.ctool_status_id === 110) {
            setApproveForm(true);
        }
        else {
            setViewForm(true);
        }

    }

    function saveForm(data) {
        //console.log(data);
    }

    function updateGridData(id) {
        //  console.log(id)
        let temp_data
        if (id === null) {
            setRows(masterRows);
            return;
        } else if (id < 109) {
            temp_data = masterRows.filter(item => item.ctool_status_id < 109)
            setRows(temp_data)
        } else {
            temp_data = masterRows.filter(item => item.ctool_status_id === id)
            setRows(temp_data)

        }

    }

    const columns = [
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            pinned: "left",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },

        {
            field: "request_id",
            headerName: "Request ID",
            valueGetter: "node.rowIndex + 1",
            pinned: "left",
            filter: "agTextColumnFilter",
            minWidth: 200,
            cellRenderer: (params) => {
                return (
                    <Button
                        size="small"
                        color="primary"
                        onClick={() => {
                            const data = params?.data;
                            loadForm(data)
                        }}
                    >
                        {`CToolR/${params?.data?.request_id}`}
                    </Button>
                );
            },
        },


        {
            headerName: "Tool Name",
            field: "ctool_name",
            flex: 1,
            pinned: "left",
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 250,

        },
        {
            headerName: "Tool Number",
            field: "ctool_number",
            flex: 1,
            pinned: "left",
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 150,

        },
        {
            headerName: "Vendor Name",
            field: "vendor_name",
            flex: 1,
            editable: false,
            floatingFilter: true,
            filter: "agMultiColumnFilter",
            resizable: true,
            minWidth: 250,

        },
        {
            headerName: 'Refurbish Detial',
            children: [

                {
                    headerName: "Tool Status ",
                    field: "ctool_status",
                    flex: 1,
                    filter: "agDateColumnFilter",
                    columnGroupShow: 'null',
                    resizable: true,
                    minWidth: 200,

                },

                {
                    headerName: "Last Updated ",
                    flex: 1,
                    filter: "agDateColumnFilter",
                    columnGroupShow: 'null',
                    resizable: true,
                    minWidth: 200,
                    valueGetter: (params) =>
                        format(new Date(params?.data?.updated_at), "dd/mm/yyyy")
                },

                {
                    headerName: "Initiated Date",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agDateColumnFilter",
                    resizable: true,
                    minWidth: 200,
                    valueGetter: (params) =>
                        format(new Date(params?.data?.created_at), "dd/mm/yyyy")
                },
                {
                    field: "intiater_name",
                    headerName: "Initiated By",
                    columnGroupShow: 'open',
                    flex: 1,
                    filter: "agTextColumnFilter",
                    resizable: true,
                    minWidth: 200,
                },
                {
                    headerName: "Refurbished Date",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agDateColumnFilter",
                    resizable: true,
                    minWidth: 200,
                    valueGetter: (params) =>
                        format(new Date(params?.data?.refurbish_initiated), "dd/mm/yyyy")
                },
                {
                    field: "refurbish_initiated_by",
                    headerName: "Refurbished",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    resizable: true,
                    minWidth: 200,
                },
                {
                    field: "refurbish_remarks",
                    headerName: "Refurbish Remarks",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    resizable: true,
                    minWidth: 200,
                },

                {
                    headerName: "Approved Date",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agDateColumnFilter",
                    resizable: true,
                    minWidth: 200,
                    valueGetter: (params) =>
                        format(new Date(params?.data?.refurbish_initiated), "dd/mm/yyyy")
                },
                {
                    field: "refurbish_approved_by",
                    headerName: "Approved By",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    resizable: true,
                    minWidth: 200,
                },
                {
                    field: "approval_remarks",
                    headerName: "Approval Remarks",
                    flex: 1,
                    columnGroupShow: 'open',
                    filter: "agTextColumnFilter",
                    resizable: true,
                    minWidth: 200,
                },
            ]
        }

    ]


    return (

        <>

            <Grid container style={{ paddingBottom: "15px" }} >

                <Grid container justifyContent="space-evenly">
                    {statuses.map((status, index) => (
                        <StatusGridItem
                            key={index}
                            title={status.title}
                            value={status.value}
                            background={status.background}
                            onClick={() => handleClick(status.id)}
                            selected={selected === status.id}

                        />
                    ))}
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} lg={12} xs={12} justifyContent="flex-end">
                <CommonGrid
                    coldefs={{
                        filter: true,
                        filterParams: {
                            apply: true,
                            newRowsAction: "keep",
                            enabled: { filter: 1 },
                        },
                    }}
                    columns={columns}
                    //   ButtonComponent={ButtonComponent}
                    rows={rows}
                    gridheight={200}
                    onFilterChanged={handleFilterApplied}


                />

            </Grid>

            <DGDrawer
                isOpen={initiateForm}
                setOpen={() => {
                    setInitiateForm(false)
                    updateGridData(selected)
                }}
                title={[selectedRow?.ctool_name, " - ", selectedRow?.ctool_number]}
                componentProps={selectedRow}
                Component={CuttingToolRefurbishInitiate}
                drawerWidth={600}
                needSave


            />
            <DGDrawer
                isOpen={approveForm}
                setOpen={() => {
                    setApproveForm(false)
                    updateGridData(selected)
                }}
                title="Refurbishing Approval"
                componentProps={selectedRow}
                Component={CuttingToolRefurbishApprove}
                drawerWidth={600}
                needSave


            />

            <DGDrawer
                isOpen={viewForm}
                setOpen={() => {
                    setViewForm(false)
                }}
                title="Refurbishing Report View"
                subtitle={[selectedRow?.ctool_name, " - ", selectedRow?.ctool_number]}
                componentProps={selectedRow}
                Component={CuttingToolRefurbishView}
                drawerWidth={600}


            />
        </>



    )

}

const useStyles = makeStyles({
    common: {
        color: "#fff",
        borderRadius: 15,
        cursor: "pointer",
        // width: "100%",
    },
    header: {
        color: "#000",
        padding: 15,
        paddingBottom: 5,
    },
    count: {
        color: "#000",
        padding: 15,
        paddingTop: 0,
    },
    selected: {
        //border: "2px solid #000",
        backgroundColor: "#fff !important",
    },

});