/* eslint-disable import/first */
import React from "react";
import { useContext, useEffect, useState } from "react";
import { Monitor, Sliders, Users } from "react-feather";
import { AuthContext } from "../context/AuthContextProvider"

import AuthGuard from "../components/AuthGuard";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import ResetPassword from "../pages/auth/ResetPassword";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
// import AllGauges from "../pages/gauges/AllGauges";
import { DashBoard } from "../pages/overview/dashboard/_dashboard";
// Landing
import Landing from "../pages/presentation/Landing";
import AllProcess from "../pages/process/AllProcess";
import ProductDetails from "../pages/productmanagement/ProductDetails";
//import ProductCCM from "../pages/characterstics/productDetails/_CCMProductDetails";
import AllCustomers from "../pages/customers/AllCustomers";
// import GaugeItemForOverview from "../pages/gaugeItemsForOverview";
// import GaugesOverdueEarly from "../pages/gaugesOverdueEarly";
import Approvals from "../pages/approvals/Approvals";
import PIDetails from "../pages/gauge/gauges/pi/PIDetails";
import Reports from "../pages/reports/Reports";
import Settings from "../pages/settings/Settings";
import Help from "../pages/help";
//import Csr from "../pages/Csr/Csr";

import VisibilityIcon from "@material-ui/icons/Visibility";
import BuildIcon from "@material-ui/icons/Build";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import BallotIcon from "@material-ui/icons/Ballot";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import DoneAllRoundedIcon from "@material-ui/icons/DoneAllRounded";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import BusinessIcon from "@material-ui/icons/Business";
import TimelineIcon from "@material-ui/icons/Timeline";
import TabletIcon from "@material-ui/icons/Tablet";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import SpeedTwoToneIcon from "@material-ui/icons/SpeedTwoTone";
import BallotTwoToneIcon from "@material-ui/icons/BallotTwoTone";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import ViewGaugeEditHistory from "../pages/gauge/calibration/ViewGaugeEditHistory";
import ViewGaugeItemEditHistory from "../pages/gauge/gauges/ViewGaugeItemEditHistory";
import VendorDashboard from "../pages/vendorDashboard/VendorDashboard";

/* import MSAPlan from "../pages/msaPlan/MSAPlan";
import MSADetails from "../pages/msaPlan/MSADetails";
import MSAOverview from "../pages/msaPlan/MSAOverview"; */
import { CCMOverview } from "../pages/ccm/CCMOverview"

import MachineListing from "../pages/machines/machineListing/MachineListing";
//import MachineDetailPage from "../pages/machines/_machineDetails/MachineDetailPage";
import MachineProcessList from "../pages/machines/machineParameter/machineListing"
import MachineParameterDetail from "../pages/machines/machineParameter/machineParameterDetails/machineParameterTab"
import MachineProductList from "../pages/machines/machineProduct/machineProductTab"
import MachineMaintenanceTab from "../pages/machines/machineMaintanance/machineMaintenanceTab"

import Gemba2 from "../pages/gemba/Gemba2";
import Gemba from "../pages/gemba/Gemba";

import GaugeDetails from "../pages/gauge/gaugesFamily/GaugeDetails";
import { AllGaugesPage } from "../pages/gauge/gauges/AllGauges";
import { GaugeFamily } from "../pages/gauge/gaugesFamily/GaugesFamily";
import { AllCalibrations } from "../pages/gauge/calibration/AllCalibrations";
import { GaugeUtilization } from "../pages/gauge/_gaugeUtilization";
import { GaugeProduct } from "../pages/gauge/gaugeProduct/gaugeProduct";
import { CalibrationHistory } from "../pages/gauge/calibrationHistory/calibrationHistory";
import { GaugeIssues } from "../pages/gauge/gaugeIssues/gaugeissues";
import { GaugeOverview } from "../pages/gauge/_GaugeOverview";

import { MsaReport } from "../pages/msa/msatab/reports"
import { ProductInspectionListing } from "../pages/productionInspection/product-inspection/ProductInspection";
import { PreDispatchInspectionListing } from "../pages/preDispatchInspection/Index";
import { CriticalCharacterSPC } from "../pages/spc/spc/SpcListing"

import FormTool from "../pages/formTool/FormToolListing/FormTool"
import FormToolProduct from "../pages/formTool/FormToolProduct/FormToolProductLink"
import { FormToolOverview } from "../pages/formTool/FormToolOverview";

import { CreateAccount } from "../pages/auth/CreateAccount";
import { UpdateAccount } from "../pages/auth/UpdateAccount";
import InvitesList from "../pages/team/InvitesList";
import TeamList from "../pages/team/TeamList";
import OperatorTable from "../pages/team/operator_list/OperatorTable";

import { ProcessCapabiltyPlantListing } from "../pages/spc/spctab/ProcessCapabilityPlantListing";
import { ProcessCapabilityListing } from "../pages/spc/spctab/processCapability/ProcessCapabilityListing";
import AllProductsList from "../pages/productmanagement/ProductListing";
import AllProductsList2 from "../pages/productmanagement/ProductListing2";

import { MsaListing } from "../pages/msa/msa/Msa";
import VendorListing from "../pages/vendor/VendorListing";
import { UserSetting } from "../pages/settings/user/UserSetting";

import CharProductsList from "../pages/characterstics/CharProductListing"
//import CharacteristicDetails from "../pages/characterstics/charactersticsDetails/CharacteristicDetails";
import CharacterProductDetails from "../pages/characterstics/characteristicsDetail"

import ProductionRecordTable from "../pages/productionmgmt/production_record/production_record";
import ProductionValueStream from "../pages/productionmgmt/production_valueStream/production_valueStreamTab";

import { CustomerContactListing } from "../pages/customers/customerContact/CustomerContactListing";
import { PartAccessListing } from "../pages/customers/PartAccess/PartAccessListing";
import { CustomerProducts } from "../pages/characterstics/customer/CustomerProducts";
import { CustomerCharacters } from "../pages/characterstics/customer/CustomerCharacters";
import { CustomerProductInspections } from "../pages/characterstics/customer/CustomerProductInspections";
import { CustomerOverview } from "../pages/overview/customer/CustomerOverview";
import { CustomerCharacterDetails } from "../pages/characterstics/customer/CustomerCharacterDetails";
import { CustomerDispatchInspection } from "../pages/preDispatchInspection/Customer/CustomerDispatchInspection";
import { CustomerCriticalMsa } from "../pages/ccm/customer/CustomerMsa";
import { CustomerCriticalSpc } from "../pages/ccm/customer/CustomerSpc";
import { CustomerProcessCapability } from "../pages/ccm/customer/CustomerProcessCapability";
import { CustomerProductInspection } from "../pages/ccm/customer/ProductionInspection";

import { CuttingToolMaster } from "../pages/cuttingTool/cuttingToolMaster/CuttingToolMaster";
import { CuttingToolProduct } from "../pages/cuttingTool/cuttingToolPart/CuttingToolPartList";
import { CuttingToolNumber } from "../pages/cuttingTool/cuttingToolNumber/CuttingToolNumberList";
import { CuttingToolRefurbish } from "../pages/cuttingTool/cuttingToolRefurbish/cuttingToolRefurbish";

import DefectManagement from "../pages/qualityAnalytics/defectClassification/defectManagement";

import OPList from "../pages/opl/opl_list"



// Protected routes
// import ProtectedPage from "../pages/protected/ProtectedPage";

//console.log(AccessList());


const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

export const RegisterRoutes = [
  {
    id: "Register Page",
    path: "/create-account",
    header: "Docs",
    icon: Monitor,
    component: CreateAccount,
  },
  {
    id: "Update Page",
    path: "/update-account",
    header: "Docs",
    icon: Monitor,
    component: UpdateAccount,
  },
];

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: Monitor,
  component: Landing,
  children: null,
};

// This route is only visible while signed in
// If any new route is added, Please update the same in getPageName() of Dashboard.js

const protectedPageRoutes = {
  id: "Dashboard",
  path: "/overview",
  containsHome: true,
  guard: AuthGuard,
  icon: VisibilityIcon,
  children: [
    {
      path: "/overview",
      name: "Overview",
      component: DashBoard,
      client_access: "general"
    },
    {
      path: "/all-products",
      name: "Products",
      component: AllProductsList,
      client_access: "general"
    },
    {
      path: "/all-customers",
      name: "Customers",
      component: AllCustomers,
      client_access: "general"
    },
    {
      path: "/all-process",
      name: "Process",
      component: AllProcess,
    },
    // {
    //   path: "/all-gauges",
    //   name: "Gauges",
    //   component: AllGauges,
    // },
    {
      path: "/product-details/:id",
      name: "Products",
      component: ProductDetails,
    },
    {
      path: "/gauge-details/:id",
      name: "Gauges",
      component: GaugeDetails,
      client_access: "ccmgmt"
    },
    {
      path: "/pi/:id",
      name: "Calibration",
      component: PIDetails,
      client_access: "ccmgmt"
    },
    /*     {
          path: "/characteristic-details/:id",
          name: "Characteristic",
          component: CharacteristicDetails,
          client_access: "ccmgmt"
        }, */
    {
      path: "/all-machines",
      name: "Machines",
      component: MachineListing,
      client_access: "assetmgmt"
    },
    /*     {
          path: "/machine-details/:id",
          name: "Machines",
          component: MachineDetailPage,
          client_access: "assetmgmt"
        }, */
    {
      path: "/approvals",
      name: "Approvals",
      component: Approvals,
      client_access: "general"
    },
    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      client_access: "general"
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      client_access: "general"
    },
    {
      path: "/vendor",
      name: "Vendor",
      component: VendorListing,
      client_access: "general"
    },
    /*    {
         path: "/csr",
         name: "Csr",
         component: Csr,
         client_access: "general"
       }, */
    {
      path: "/help",
      name: "Help",
      component: Help,
      client_access: "general"
    },
    // {
    //   path: "/active-gauge-items",
    //   name: "ActiveGauges",
    //   component: GaugeItemForOverview,
    // },
    {
      path: "/gauges",
      name: "Gauges",
      component: AllGaugesPage,
      client_access: "assetmgmt"
    },
    /*     {
          path: "/msa-overview",
          name: "MSAOverview",
          component: MSAOverview,
          client_access: "ccmgmt"
        }, */
    // {
    //   path: "/gauges-overdue-early",
    //   name: "GaugeOverdue",
    //   component: GaugesOverdueEarly,
    // },
    {
      path: "/gauge-changes/:id",
      name: "GaugeChanges",
      component: ViewGaugeEditHistory,
      client_access: "assetmgmt"
    },
    {
      path: "/gauge-item-changes/:id",
      name: "GaugeItemChanges",
      component: ViewGaugeItemEditHistory,
      client_access: "assetmgmt"
    },
    {
      path: "/ccm-msa",
      name: "MSAPlanning",
      component: MsaListing,
      client_access: "ccmgmt"
    },
    /*     {
          path: "/msa-details/:id",
          name: "MSADetails",
          component: MSADetails,
          client_access: "ccmgmt"
        }, */
    {
      path: "/ccm-spcplant",
      name: "SPC",
      component: CriticalCharacterSPC,
      client_access: "ccmgmt"
    },
    {
      path: "/calibration-history",
      name: "Calibration History",
      component: CalibrationHistory,
      client_access: "assetmgmt"
    },
    {
      path: "/gauge-utilization",
      name: "Gauge Utilization",
      component: GaugeUtilization,
      client_access: "assetmgmt"
    },
    {
      path: "/gauge-product",
      name: "Gauge Product",
      component: GaugeProduct,
      client_access: "assetmgmt"
    },
    {
      name: "Gauges Family",
      path: "/gauge-family",
      component: GaugeFamily,
      client_access: "assetmgmt"
    },
    {
      name: "Calibration",
      path: "/calibration",
      component: AllCalibrations,
      client_access: "assetmgmt"
    },
    {
      path: "/gauge-issues",
      name: "Gauge Issue Logs",
      component: GaugeIssues,
      client_access: "assetmgmt"
    },
    {
      path: "/msa-report",
      name: "Report",
      component: MsaReport,
      client_access: "assetmgmt"
    },
    {
      name: "Teams",
      path: "/teams",
      component: TeamList,
      client_access: "general"
    },
    {
      name: "Invites",
      path: "/invites",
      component: InvitesList,
      client_access: "general"
    },
    {
      name: "Process Capability",
      path: "/ccm-process-capability",
      component: ProcessCapabilityListing,
      client_access: "ccmgmt"
    },
    {
      name: "Production Inspection",
      path: "/ccm-product-inspection",
      component: ProductInspectionListing,
      client_access: "ccmgmt"
    },
    {
      name: "Pre-Dispatch Inspection",
      path: "/ccm-dispatch-inspection",
      component: PreDispatchInspectionListing,
      client_access: "ccmgmt"
    },
    {
      name: "User Setting",
      path: "/user/setting",
      component: UserSetting,
      client_access: "general"
    },
    {
      name: "Customers",
      path: "/customers",
      component: AllCustomers,
      client_access: "general"
    },
    {
      name: "Customer Contact",
      path: "/customers/contact",
      component: CustomerContactListing,
      client_access: "general"
    },
    {
      name: "Part Access",
      path: "/customers/partaccess",
      component: PartAccessListing,
      client_access: "general"
    },

    {
      name: "Asset Management",
      path: "/all-products",
      component: AllProductsList,
      client_access: "assetmgmt"
    },

    {
      name: "Form Tool",
      path: "/form-tool",
      component: FormTool,
      client_access: "assetmgmt"

    },
    ,

    {
      name: "Form Product",
      path: "/form-product",
      component: FormToolProduct,
      client_access: "assetmgmt"

    },

    {
      name: "Form Tool Overview",
      path: "/form-tool-overview",
      component: FormToolOverview,
      client_access: "assetmgmt"

    },
    {
      name: "Gauge Management Overview",
      path: "/gauge-management-overview",
      component: GaugeOverview,
      client_access: "assetmgmt"
    },
    {
      name: "Critical Character Overview",
      path: "/ccm-overview",
      component: CCMOverview,
      client_access: "ccmgmt"
    },
    /*     {
          name: "Critical Character Management",
          path: "/ccm-management",
          component: ProductCCM,
          client_access: "ccmgmt"
    
    
        }, */
    {
      name: "Characteristics Management",
      path: "/characterstics-management",
      component: CharProductsList,
      client_access: "ccmgmt"

    },

    {
      name: "Characteristics Detail",
      path: "/characteristics-details/:id",
      component: CharacterProductDetails,
      client_access: "ccmgmt"

    },

    {
      name: "Machines Parameters",
      path: "/machine-parameter",
      component: MachineProcessList,
      client_access: "assetmgmt"
    },

    {
      path: "/machine-parameter/:id",
      name: "Machine Parameter",
      component: MachineParameterDetail,
      client_access: "assetmgmt"
    },

    {
      name: "Machine Product",
      path: "/machine-part",
      component: MachineProductList,
      client_access: "assetmgmt"
    },

    {
      name: "Operator",
      path: "/Operator",
      component: OperatorTable,
      client_access: "general"
    },

    {
      name: "Production Record",
      path: "/production-record",
      component: ProductionRecordTable,
      client_access: "prdnmgmt"
    }
    ,

    {
      path: "/all-product",
      name: "Products",
      component: AllProductsList2,
      client_access: "general"
    },

    {
      path: "/ctool-master",
      name: "Cutting Tool",
      component: CuttingToolMaster,
      client_access: "assetmgmt"
    },


    {
      path: "/ctool-product",
      name: "Cutting Tool Product",
      component: CuttingToolProduct,
      client_access: "assetmgmt"
    },

    {
      path: "/ctool-number",
      name: "Cutting Tool",
      component: CuttingToolNumber,
      client_access: "assetmgmt"
    },

    {
      path: "/ctool-refurbish",
      name: "Cutting Tool Refurbish",
      component: CuttingToolRefurbish,
      client_access: "assetmgmt"
    },

    {
      path: "/machine-maintenance",
      name: "Machine Maintenance",
      component: MachineMaintenanceTab,
      client_access: "assetmgmt"

    },
    {
      path: "/production-valueStream",
      name: "Production Value Stream",
      component: ProductionValueStream,
      client_access: "prdnmgmt"
    },

    {
      path: "/defect-management",
      name: "Defect Classification",
      component: DefectManagement,
      client_access: "prdnmgmt"


    },

    {

      path: "/opl",
      name: "OPL",
      component: OPList,
      client_access: "general"


    }




  ],
};


const vendorRoutes = {
  path: "/vendor-dashboard",
  name: "VendorDashboard",
  icon: VisibilityIcon,
  component: VendorDashboard,
  children: null,
};

const gembaRoutes = {
  path: "/gemba-dashboard",
  name: "Gemba Dashboard",
  icon: VisibilityIcon,
  component: Gemba,
  children: null,
};

const overviewRoutes = {
  id: "Overview",
  path: "/overview",
  icon: AssessmentTwoToneIcon,
  component: DashBoard,
  levels: 0,
  children: null,
};

const productsRoutes = {
  id: "Products",
  path: "/all-products",
  icon: BuildIcon,
  levels: 0,
  component: AllProductsList,
  children: null,
};

const oplRoutes = {
  id: "OPL",
  path: "/opl",
  icon: PlaylistAddCheckIcon,
  levels: 0,
  component: OPList,
  children: null,
}

const formtoolRoutes = {

  id: "Form Tools",
  path: "/form-tool",
  icon: SpeedTwoToneIcon,
  component: FormTool,
  children: [

    {
      name: "Form Product",
      path: "/form-product",
      component: FormToolProduct,

    },

  ]

};

const ctoolRoutes = {
  id: "Cutting Tools",
  icon: SpeedTwoToneIcon,
  component: CuttingToolNumber,
  path: "/ctool-number",
  children: [
    {
      path: "/ctool-master",
      name: "Cutting Tool Master",
      component: CuttingToolMaster,
      client_access: "assetmgmt"
    },
    {
      path: "/ctool-product",
      name: "Cutting Tool Product",
      component: CuttingToolProduct,
      client_access: "assetmgmt"
    },
    {
      path: "/ctool-refurbish",
      name: "Cutting Tool Refurbish",
      component: CuttingToolRefurbish,
      client_access: "assetmgmt"
    }

  ]
}


const guagesRoutes = {
  id: "Gauges",
  icon: SpeedTwoToneIcon,
  // badge: "2",
  component: AllGaugesPage,
  path: "/gauges",
  children: [
    /*     {
          name: "Gauges",
          path: "/gauges",
          component: AllGaugesPage,
        }, */
    {
      name: "Gauge Family",
      path: "/gauge-family",
      component: GaugeFamily,
    },
    // {
    //   name: "Gauges Overview",
    //   path: "/all-gauges",
    //   component: AllGauges,
    // },
    {
      name: "Calibration",
      path: "/calibration",
      component: AllCalibrations,
    },
    // {
    //   name: "Gauges Overdue",
    //   path: "/overdue-gauge-items",
    //   component: GaugeItemForOverview,
    // },
    {
      path: "/calibration-history",
      name: "Calibration History",
      component: CalibrationHistory,
    },
    {
      path: "/gauge-product",
      name: "Gauge Product",
      component: GaugeProduct,
    },
    {
      path: "/gauge-issues",
      name: "Gauge Issue Logs",
      component: GaugeIssues,
    },
  ],
};

const criticalCharacterRoutes = {
  id: "Characteristics Management",
  icon: BallotTwoToneIcon,
  path: "/characterstics-management",
  component: CharProductsList,
  levels: 1,
  children: [
    /*     {
          name: "Characteristics Management",
          path: "/characterstics-management",
          component: CharProductsList
    
        }, */
    {
      name: "MSA",
      path: "/ccm-msa",
      component: MsaListing,
    },
    {
      name: "SPC",
      path: "/ccm-spcplant",
      component: CriticalCharacterSPC,
    },
    {
      name: "Process Capability",
      path: "/ccm-process-capability",
      component: ProcessCapabilityListing,
    },
    {
      name: "Production Inspection",
      path: "/ccm-product-inspection",
      component: ProductInspectionListing,
    },
    {
      name: "Pre-Dispatch Inspection",
      path: "/ccm-dispatch-inspection",
      component: PreDispatchInspectionListing,
    },

  ],
};

const machinesRoutes = {
  id: "Machines",
  path: "/all-machines",
  icon: BallotIcon,
  component: MachineListing,
  children: [
    {
      name: "Machine Parameter",
      path: "/machine-parameter",
      component: MachineProcessList,
    },
    {
      name: "Machine Product",
      path: "/machine-part",
      component: MachineProductList,
    },
    {
      path: "/machine-maintenance",
      name: "Machine Maintenance",
      component: MachineMaintenanceTab,

    }
  ]
};

const assetManagementRoutes = {
  id: "Asset Management",
  icon: SpeedTwoToneIcon,
  path: "/all-product",
  levels: 2,
  children: [machinesRoutes, formtoolRoutes, guagesRoutes, ctoolRoutes]
};

const productionManagementRoutes = {
  id: "Production Management",
  icon: BallotTwoToneIcon,
  path: "/all-product",
  levels: 1,
  children: [

    {
      name: "Production Record",
      path: "/production-record",
      component: ProductionRecordTable,
    },

    {
      name: "Value Stream",
      path: "/production-valueStream",
      component: ProductionValueStream,


    },

    {
      path: "/defect-management",
      name: "Defect Management",
      component: DefectManagement,


    }
  ]

}


const customersRoutes = {
  id: "Customers",
  path: "/customers",
  icon: AccessibilityIcon,
  levels: 1,
  children: [
    {
      name: "Customers",
      path: "/customers",
      component: AllCustomers,
    },
    {
      name: "Customer Contact",
      path: "/customers/contact",
      component: CustomerContactListing,
    },
    {
      name: "Part Access",
      path: "/customers/partaccess",
      component: PartAccessListing,
    },
  ],
};

const customerOverview = {
  id: "Overview",
  path: "/overview",
  icon: AssessmentTwoToneIcon,
  component: CustomerOverview,
  levels: 0,
  children: null,
};

const customerProducts = {
  id: "Products",
  path: "/customer/product",
  icon: BuildIcon,
  component: CustomerProducts,
  levels: 0,
  children: null,
};

const customerCriticalCharacter = {
  id: "Critical Character Management",
  path: "/customer/msa",
  icon: BallotTwoToneIcon,
  children: [
    {
      name: "MSA",
      path: "/customer/msa",
      component: CustomerCriticalMsa,
    },
    {
      name: "SPC",
      path: "/customer/spc",
      component: CustomerCriticalSpc,
    },
    {
      name: "Process Capability",
      path: "/customer/process-capability",
      component: CustomerProcessCapability,
    },
    {
      name: "Production Inspection",
      path: "/customer/product-inspection",
      component: CustomerProductInspection,
    },
  ],
};

const customerDispatchInspection = {
  id: "Pre Dispatch Inspection",
  path: "/customer/dispatch-inspection",
  icon: AccountTreeTwoToneIcon,
  children: null,
  component: CustomerDispatchInspection,
};
const processRoutes = {
  id: "Process",
  path: "/all-process",
  icon: Sliders,
  //  badge: "4",
  component: AllProcess,
  children: null,
};


const teamRoutes = {
  id: "Team",
  path: "/teams",
  icon: PeopleIcon,
  levels: 1,
  children: [
    {
      name: "Teams",
      path: "/teams",
      component: TeamList,
    },
    {
      name: "Invites",
      path: "/invites",
      component: InvitesList,
    },
    {
      name: "Operator",
      path: "/Operator",
      component: OperatorTable
    }



  ],
};

const vendorManagementRoutes = {
  id: "Vendor Management",
  path: "/vendor",
  icon: BusinessIcon,
  levels: 0,
  component: VendorListing,
  children: null,
};

const settingsRoutes = {
  id: "Settings",
  path: "/settings",
  icon: SettingsIcon,
  levels: 0,
  component: Settings,
  children: null,
};

const gembaSidebarRoutes = {
  id: "Gemba",
  path: "/gemba-dashboard",
  icon: TabletIcon,
  levels: 0,
  component: Gemba,
  children: null,
};

const gembaBoardRoutes = {
  id: "Gemba Board",
  path: "/gemba-board",
  icon: TabletIcon,
  levels: 0,
  component: Gemba2,
  children: null,
};

/* const csrRoutes = {
  id: "CSR",
  path: "/csr",
  icon: AccountTreeIcon,
  // badge: "7",
  component: Csr,
  children: null,
}; */

const approvalsRoutes = {
  id: "Approvals",
  path: "/approvals",
  icon: DoneAllRoundedIcon,
  levels: 0,
  component: Approvals,
  children: null,
};

const helpRoutes = {
  id: "Help",
  path: "/help",
  icon: LiveHelpIcon,
  component: Help,
  children: null,
};

const msaRoutes = {
  id: "MSA Plan",
  path: "/msa",
  icon: TimelineIcon,
  //component: MSAPlan,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  //dashboardsRoutes,
  // pagesRoutes,
  // projectsRoutes,
  // orderRoutes,
  // invoiceRoutes,
  // tasksRoutes,
  // calendarRoutes,
  // componentsRoutes,
  // formsRoutes,
  // tablesRoutes,
  // iconsRoutes,
  // mapsRoutes,
  // documentationRoutes,
  // overviewRoutes,
  // productsRoutes,
  // processesRoutes,
  // guagesRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];
export const vendorAccessRouts = [vendorRoutes];
export const GembaAccessRouts = [gembaRoutes];
export const GembaBoardAccessRouts = [gembaBoardRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  overviewRoutes,
  productsRoutes,
  criticalCharacterRoutes,
  assetManagementRoutes,
  productionManagementRoutes,
  oplRoutes,
  approvalsRoutes,
  vendorManagementRoutes,
  customersRoutes,
  teamRoutes,
  settingsRoutes,
  gembaSidebarRoutes,
  gembaBoardRoutes,
  RegisterRoutes,
];

const customerProtected = {
  id: "Dashboard",
  path: "/overview",
  containsHome: true,
  guard: AuthGuard,
  icon: VisibilityIcon,
  children: [
    {
      name: "Products",
      path: "/customer/product",
      component: CustomerProducts,
    },
    {
      name: "Characterstics",
      path: "/customer/characterstics",
      component: CustomerCharacters,
    },
    {
      name: "MSA",
      path: "/customer/msa",
      component: CustomerCriticalMsa,
    },
    {
      name: "SPC",
      path: "/customer/spc",
      component: CustomerCriticalSpc,
    },
    {
      name: "Production Inspection",
      path: "/customer/product_Inspection",
      component: CustomerProductInspections,
    },
    {
      name: "Overview",
      path: "/overview",
      component: CustomerOverview,
    },
    {
      name: "Characterstics",
      path: "/customer/characterstics/:id",
      component: CustomerCharacterDetails,
    },
    {
      name: "Production Inspection",
      path: "/customer/product_Inspection",
      component: CustomerProductInspections,
    },
    {
      name: "Pre-Dispatch Inspection",
      path: "/customer/dispatch-inspection",
      component: CustomerDispatchInspection,
    },
    {
      name: "Process Capability",
      path: "/customer/process-capability",
      component: CustomerProcessCapability,
    },
    {
      name: "Production Inspection",
      path: "/customer/product-inspection",
      component: CustomerProductInspection,
    },
  ],
};
export const customersMenuRoutes = [
  customerOverview,
  customerProducts,
  customerCriticalCharacter,
  customerDispatchInspection,
];

export const customerProtectedRoutes = [customerProtected];
