import { Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { object } from "yup";
import { DGSelect } from "../../../../components/shared/DGSelect";
import { AlertContext } from "../../../../context/AlertContextProvider";
import { AuthContext } from "../../../../context/AuthContextProvider";
import characterService from "../../../../services/characterService";
import machineService from "../../../../services/machineService";
import processService from "../../../../services/processService";
import CommonGrid from "../../../../components/CommonGrid";
import { DeleteRenderer } from "../../../../components/cellRenderers/DeleteRenderer"
import dateService from "../../../../services/dateService";
import { PlantContext } from "../../../../context/PlantContextProvider";



export function MachineCharacterForm({
  Footer = () => { },
  closeForm = () => { },
  componentProps = null,
}) {
  const { v4: uuidv4 } = require("uuid");
  let { id } = useParams();
  const { updateAlert } = useContext(AlertContext);
  const { currentUser } = useContext(AuthContext);
  const [gridApi, setGridApi] = useState(null);
  const globalplant = useContext(PlantContext);

  let tempform = useFormik({
    initialValues: {
      machine: null,
      process: null,
      machineoptions: [],
      processOptions: [],
      characteristics: null,
      characterOptions: [],
    },
    validationSchema: object({
      machine: object().required(),
      process: object().required(),
      characteristics: object().required(),
    }),
    onSubmit: onAdd
  });


  let form = useFormik({
    initialValues: {
      gridData: []
    },
    validationSchema: object({

    }),
    onSubmit: onSave
  });

  useEffect(() => {
    getAllProcess()
    loadCharacter()
  }, []);


  useEffect(() => {
    if (tempform.values.characteristics) {
      getMachineSpindle()
    }
  }, [tempform.values.characteristics]);


  function getMachineSpindle() {

    const mlist = machineChar(tempform.values.characteristics?.id)

    return machineService.getMachineSpindleList1({
      character_id: tempform.values.characteristics?.id,
      plant_id: globalplant?.globalPlant?.globalPlant.id,
      machine_list: mlist
    })
      .then((res) => {
        tempform.setFieldValue("machineoptions", res?.data ?? [])
      });
  }

  function getAllProcess() {
    return processService.getProcesses().then((res) => {
      tempform.setFieldValue("processOptions", res?.data?.data ?? [])
    });
  }

  function loadCharacter() {
    characterService.getACharactersByProductId(componentProps?.partId?.partId)
      .then((res) => {
        var temp = res.data

        for (const item of temp) {
          item["character_id"] = item.id;
        }
        tempform.setFieldValue("characterOptions", temp);

      })
  }


  function machineChar(characterID) {
    if ((form.values.gridData).length > 0) {
      var temp2 = form.values.gridData;
      temp2.forEach(obj => {
        obj.gridentry = { ...obj.process, ...obj.characteristics, ...obj.machine };
      });
      const temp3 = temp2.map(({ gridentry }) => gridentry);
      // console.log("temp2", temp3)
      var temp4 = temp3.filter(item => item.character_id === characterID);
      var temp5 = temp4.map(item => item.id);
      //   console.log(temp4)

    } else { var temp5 = [] }
    return temp5
  }




  function onAdd() {
    let data = [];
    data?.push({
      characteristics: tempform.values.characteristics,
      machine: tempform.values.machine,
      process: tempform.values.process,

    });
    form.setFieldValue("gridData", [...form.values.gridData, ...data]);
    tempform.resetForm();
    getMachineSpindle()
    getAllProcess()
    loadCharacter()
  }

  function onClear() {
    tempform?.resetForm();
    getMachineSpindle()
    getAllProcess()
    loadCharacter()
  }

  function removeDetailRow(rowIndex, data) {
    let chars = form.values.gridData;
    chars.splice(rowIndex, 1);
    gridApi?.updateRowData({ remove: [data] });
  }




  function onGridReady(params) {
    setGridApi(params?.api);
  }


  const columns = [
    {
      cellRenderer: DeleteRenderer,
      maxWidth: 80,
      cellRendererParams: {
        onClick: (data, rowIndex) => {
          removeDetailRow(rowIndex, data);
        },
      },
      pinned: "left",
      resizable: true,
      hide: !!componentProps?.approvalId,
    },

    {
      headerName: "Characteristics",
      resizable: true,
      minWidth: 200,
      pinned: "left",
      valueGetter: (params) => {
        return ` ${params.data.characteristics?.name ?? ""} 
                ${params?.data?.characteristics?.specification ?? ""} 
              ${params?.data?.characteristics?.upper_specification ?? ""}  
              ${params?.data?.characteristics?.lower_specification ?? ""}`;
      }
    }

    ,
    {
      headerName: "Processes",
      resizable: true,
      minWidth: 200,
      pinned: "left",
      valueGetter: (params) => {
        return ` ${params?.data?.process.name} `
      }
    },

    {
      headerName: "Machine",
      resizable: true,
      minWidth: 200,
      pinned: "left",
      valueGetter: (params) => {
        return ` ${params?.data?.machine.machine_number} ${params?.data?.machine.machine_name} ${params?.data?.machine.spindle_name}`
          ;
      }
    }


  ]


  function onSave() {
    let machineObj = [];
    gridApi.forEachNode((node) => {
      let data = node?.data;
      machineObj.push({
        id: uuidv4(),
        client_id: currentUser?.client_id,
        character_id: data.characteristics.id,
        machine_id: data.machine.machine_id,
        machine_spindle_id: data.machine.id ?? "",
        process_id: data.process.id ?? "",
        created_at: dateService.getDateInSqlFormat(new Date()),
        updated_at: dateService.getDateInSqlFormat(new Date()),
        created_by: currentUser?.id ?? "",
        part_id: componentProps?.partId?.partId,
        enabled: 1,
      })
    })
    characterService.createCharacterMachine(machineObj).then(res => {
      closeForm();
      updateAlert({ message: "Machine added successfully", type: "success", open: true });
    }).catch(err => {
      updateAlert({ message: "Failed To add Gauge", type: "error", open: true })
    })
  }



  return (
    <Grid container spacing={4}>

      <Grid item md={5} sm={5} lg={5}>
        <DGSelect
          label="Characteristics"
          value={tempform.values.characteristics}
          options={tempform.values.characterOptions}
          getOptionLabel={(opt) =>
            `${opt?.name} ${opt?.specification !== null ? opt?.specification : ""
            } ${opt?.upper_specification !== null
              ? opt?.upper_specification
              : ""
            } ${opt?.lower_specification !== null
              ? opt?.lower_specification
              : ""
            } `
          }
          onChange={(val) => {
            tempform?.setFieldValue("characteristics", val);
          }}
          required
        />
      </Grid>

      <Grid item md={2} sm={2} lg={2}>
        <DGSelect
          label="Process"
          options={tempform.values.processOptions}
          required
          value={tempform.values.process}
          onChange={(val) => {
            tempform.setFieldValue("process", val);
          }}
          error={tempform.touched.process && tempform.errors.process}
        />
      </Grid>
      <Grid item md={5} sm={5} lg={5}>
        <DGSelect
          label="Machine"
          options={tempform.values.machineoptions}
          required
          value={tempform.values.machine}
          onChange={(val) => {
            tempform.setFieldValue("machine", val);
          }}
          error={form.touched.machine && form.errors.machine}
          getOptionLabel={(option) =>
            `${option?.machine_number ?? ""}  ${option?.machine_name ?? ""} - ${option?.spindle_name ?? ""}`
          }
        />
      </Grid>


      <Grid item md={12} sm={12} lg={12}>
        <Button
          style={{ paddingLeft: 40, paddingRight: 40 }}
          color="primary"
          variant="outlined"
          onClick={tempform.handleSubmit}
          className="m-2"
        >
          Add
        </Button>
        <Button
          style={{ paddingLeft: 40, paddingRight: 40 }}
          color="primary"
          variant="outlined"
          onClick={onClear}
        >
          Clear
        </Button>
      </Grid>

      <Grid md={12} sm={12} lg={12}>
        <CommonGrid
          columns={columns}
          rows={form.values.gridData?.length ? form.values.gridData : []}
          needExport={false}
          onGridReady={onGridReady}
        //gridheight={componentProps?.approvalId ? 138 : 420}
        />
      </Grid>

      <Footer onSave={form.handleSubmit} />
    </Grid>


  );
}
