import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { Rules } from "../../../components/cellRenderers/RulesRenderer";
import CommonGrid from "../../../components/CommonGrid";
import { Spec } from "../../../components/spec";
import { AuthContext } from "../../../context/AuthContextProvider";
import studiesResultService from "../../../services/studiesResultService";

export function GaugeMsa() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    studiesResultService.getMSAGauge(currentUser?.client_id).then(res=>{
        setRows(res?.data??[]);
    });
  }, []);

  const columnDefs = [
    {
      field: "serial",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      minWidth: 100,
      sorting: true,
    },
    {
      field: "name",
      headerName: "Guage",
      minWidth: 200,
      resizable: true,
      filter: 'agMultiColumnFilter',
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "study",
      headerName: "Analysis",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "rm",
      headerName: "Report Number",
      minWidth: 200,
      resizable: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "gauge",
      headerName: "Part",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      valueGetter:(params)=>`${params?.data?.part_number}-${params?.data?.part_name}`,
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "char_name",
      headerName: "Character",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "char_name",
      headerName: "Specification",
      minWidth: 200,
      resizable: true,
      cellRenderer:(params)=><Spec value={`${params?.data?.specification},${params?.data?.lower_specification},${params?.data?.upper_specification}`} />,
      floatingFilter: true,
      sorting: true,
    },
    {
      field: "msa_result",
      headerName: "Result",
      minWidth: 200,
      resizable: true,
      cellRenderer:(params)=><Rules values={[params?.data?.msa_result]}/>,
      sorting: true,
    },
    {
      field: "plant_name",
      headerName: "Plant",
      minWidth: 200,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      sorting: true,
    },
  ];
  return <CommonGrid rows={rows} columns={columnDefs} gridheight={215} />;
}
