import React from "react";
import { DGSelect } from "../../../components/shared/DGSelect";
import { DGInput } from "../../../components/shared/DGInput";
import CommonGrid from "../../../components/CommonGrid";
import DataGridTable from "../../../components/DataGridTable";
import {
  Button, Checkbox, makeStyles, Box,
  FormControlLabel, Grid, Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useContext, useState, useCallback, } from "react";
import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import spcService from "../../../services/spcService";
import errorLog from "../../../services/errorLog";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";
import DialPadComponent from "../../../components/DialPadComponent";
import format from "date-fns/format";
import { AlertContext } from "../../../context/AlertContextProvider";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";

export default function SPCEntryForm({
  closeForm = () => { },
}) {

  let form = useFormik({
    initialValues: {
      plant: null,
      partOptions: [],
      partSelected: null,
      characterOptions: [],
      spindleOptions: [],
      spindleSelected: null,
      characterSelected: null,
      prodDate: new Date(),
      prodTime: new Date().toISOString(),

      newEntry: false,

    }
  })
  const { v4: uuidv4 } = require("uuid");
  const { globalPlant } = useContext(PlantContext);
  const { currentUser } = useContext(AuthContext);
  const [SPCPlan, setSPCPlan] = useState([])
  const [SPCInput, setSPCInput] = useState([])
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [rowIndex, setRowIndex] = useState(0)
  const [newEntry, setNewEntry] = useState(false);
  const [rowsData, setRowsData] = useState([])
  const [inputValues, setInputValues] = useState([])
  const [evalFlag, setEvalFlag] = useState(false)
  const { updateAlert } = useContext(AlertContext);
  const [spcEvalResult, setSpcEvalResult] = useState({})
  const [spcEvalResultId, setSpcEvalResultId] = useState(null)

  //setPlantId(globalPlant?.globalPlant?.id)


  const useStyles = makeStyles(() => ({
    increasedCheckbox: {
      width: '30px',
      height: '30px',
      marginRight: '10px',

    }
  }))



  const classes = useStyles();

  useEffect(() => {

    spcService.getSPCInputTable({
      query_id: globalPlant?.globalPlant?.id,
      client_id: currentUser?.client_id,
    })
      .then((res) => {

        setSPCPlan(res?.data)
        const uniqueArray = res.data
          .filter(item => item.enabled === 1)
          .filter((item, index, self) =>
            index === self.findIndex((t) => t.part_id === item.part_id)
          );
        form.setFieldValue("partOptions", uniqueArray);
        if (uniqueArray.length === 1) {
          form.setFieldValue("partSelected", uniqueArray[0])
        }

      });

  }, []);

  useEffect(() => {
    if (form.values.partSelected) {
      const filteredData = SPCPlan.filter((item) => item.part_id === form.values?.partSelected?.part_id);
      const uniqueArray = filteredData.filter((item, index, self) =>
        index === self.findIndex((t) => t.character_id === item.character_id)
      );
      form.setFieldValue("characterOptions", uniqueArray);
      if (uniqueArray.length === 1) {
        form.setFieldValue("characterSelected", uniqueArray[0])
      }
    }
  }, [form.values.partSelected]);


  useEffect(() => {
    if (form.values.characterSelected) {
      const filteredData = SPCPlan.filter((item) => item.character_id === form.values?.characterSelected?.character_id);

      const uniqueArray = filteredData.filter((item, index, self) =>
        index === self.findIndex((t) => t.machine_spindle === item.machine_spindle)
      );
      form.setFieldValue("spindleOptions", uniqueArray);
      if (uniqueArray.length === 1) {
        form.setFieldValue("spindleSelected", uniqueArray[0])
      }
    }
  }, [form.values.characterSelected]);


  useEffect(() => {

    if (SPCInput.sample_size !== 0) {
      FormatStudyInput()
    }
  }, [SPCInput])



  function FormatStudyInput() {
    const rowData_ = Array.from({ length: SPCInput.sample_size }, (_, index) => {
      let row = {
        serial_number: index + 1,
        value: null,
      };
      return row

    })
    setRowsData(rowData_)
  };

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agNumberColumnFilter",
    },
    {
      field: "value",
      headerName: "Values",
      minWidth: 75,
      cellStyle: { height: "100%" },
    },

  ]

  useEffect(() => {
    if (form.values.spindleSelected) {
      spcService.getSPCInputForEntry({
        character_id: form.values?.spindleSelected?.character_id,
        machine_spindle_id: form.values.spindleSelected?.machine_spindle_id,
      }).then((res) => {
        setSPCInput(res?.data[0])
      })
    }
  }, [form.values.spindleSelected]);

  const setInitialFocus = useCallback(() => {
    if (gridApi) {
      gridApi.ensureIndexVisible(0);
      gridApi.setFocusedCell(0, 1);
    }
  }, [gridApi]);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    // setInitialFocus();
  }, [setInitialFocus]);

  const onCellClicked = useCallback(() => {
    if (gridApi) {
      const temp = gridApi.getFocusedCell().rowIndex;
      setRowIndex(temp);
    }
  }, [gridApi]);

  const handlemeasuredValue = useCallback((newValue) => {
    if (gridApi && columnApi && rowIndex !== null) {
      const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
      const colKey = columnApi.getAllColumns()[1].getColId();
      rowNode.setDataValue(colKey, newValue);
      gridApi.refreshCells({
        rowNodes: [rowNode],
        columns: [colKey],
        force: true
      });
      setRowIndex(rowIndex + 1)
    }
  }, [gridApi, columnApi, rowIndex]);

  function clearTableData() {
    setRowsData([]);
    setEvalFlag(false)
    FormatStudyInput()
    setRowIndex(0)
    setSpcEvalResultId(null)
    setSPCInput([])

  };

  useEffect(() => {
    if (rowIndex) {
      const data = [];
      gridApi.forEachNode((node) => data.push(node.data));
      const values = data.map(item => parseFloat(item.value));
      const filteredValues = values.filter(value => !isNaN(value));
      setInputValues(values)
      if (filteredValues.length === SPCInput.sample_size) {
        setEvalFlag(true)
      }
    }

  }, [rowIndex])


  function onEvaluate() {
    let payload = [];
    payload.push({
      character_id: form.values?.characterSelected?.character_id,
      client_id: currentUser?.client_id,
      part_id: form.values?.partSelected?.part_id,
      machine_spindle_id: form.values?.spindleSelected?.machine_spindle,
      plant_id: globalPlant?.globalPlant?.id,
      spc_input_id: SPCInput.id,
      production_date: format(form.values?.prodDate, 'yyyy-MM-dd'),
      production_time: format(form.values?.prodDate, 'HH:mm:ss'),
      comments: "",
      user_id: currentUser?.id,
      change_event: newEntry,
      values: inputValues,
    });
    spcService.createSPCdata(payload)
      .then((res) => {
        setSpcEvalResultId(res.data)
        updateAlert({
          message: "Evaluated",
          open: true,
          type: "success",
        });
      })
      .catch((err) => {
        updateAlert({
          message: "Error in Evaluation, Try again",
          open: true,
          type: "error",
        });
        let payload = {
          id: uuidv4(),
          error_description: err.message,
          error_location: "createSPCdata",
          client_id: currentUser?.client_id,
          user_id: currentUser?.user_id,
          entity_id: SPCInput.id,
        };
        // errorLog.createErrorLog(payload)
      });
  }

  useEffect(() => {
    if (spcEvalResultId) {
      spcService.getSPCEval(spcEvalResultId)
        .then((res) => {
          //   console.log(res.data[0])
          setSpcEvalResult(res.data[0])
        }

        )
    }


  }, [spcEvalResultId])


  const getRowStyle = useCallback(
    (params) => {
      if (params.data.id === rowIndex) {
        return { background: 'green' };
      }
      return null;
    },
    [rowIndex]
  );


  return (

    <Grid container spacing={2}>

      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          id="part"
          label="Part Number"
          options={form.values.partOptions}
          getOptionLabel={(option) => `${option?.part_number} - ${option?.part_name}`}
          onChange={(value) => {
            form.setFieldValue("partSelected", value)
            form.setFieldValue("characterSelected", null)
            form.setFieldValue("spindleSelected", null)
            clearTableData()
            setSPCInput([])
          }}
          value={form.values.partSelected}

        />
      </Grid>
      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          id="part"
          label="Characteristics"
          options={form.values.characterOptions}
          getOptionLabel={(opt) =>
            `${opt?.char_name} ${opt?.specification !== null ? opt?.specification : ""
            } ${opt?.upper_specification !== null
              ? opt?.upper_specification
              : ""
            } ${opt?.lower_specification !== null
              ? opt?.lower_specification
              : ""
            } `
          }
          onChange={(value) => {
            form.setFieldValue("characterSelected", value)
            form.setFieldValue("spindleSelected", null)
            clearTableData()
            setSPCInput([])
          }}
          value={form.values.characterSelected}
        />
      </Grid>

      <Grid item md={3} sm={3} lg={3}>
        <DGSelect
          id="machine_spindle"
          label="Machine Spindle"
          options={form.values.spindleOptions}
          getOptionLabel={(opt) =>
            `${opt?.machine_spindle_name}`
          }
          onChange={(value) => {
            form.setFieldValue("spindleSelected", value)
            clearTableData()
          }}
          value={form.values.spindleSelected}
        />
      </Grid>

      <Grid item md={3} sm={3} lg={3}>
        <DGDateTimePicker
          label="Production Date"
          format="dd/MM/yy HH:mm"
          value={form.values.prodDate}
          onChange={(val) => {
            form.setFieldValue("prodDate", val);
          }}
        />
      </Grid>
      {Object.keys(SPCInput).length > 0 && (
        <Box sx={{ border: '1px solid grey', width: '100%', marginTop: "20px" }}>
          <Grid container spacing={2} direction="row">


            <Grid item md={4} sm={4} lg={4} >
              <DialPadComponent
                maskValue={SPCInput?.mask_value}
                maskEvaluation={SPCInput?.mask_evaluation}
                measuredValue={handlemeasuredValue}

              />



            </Grid>

            <Grid item md={3} sm={3} lg={3} style={{ marginTop: '2%' }}>
              <CommonGrid
                onGridReady={onGridReady}
                needExport={false}
                columns={columns}
                rows={rowsData}
                gridheight={300}
                onCellClick={onCellClicked}
                getRowStyle={getRowStyle}
              />


            </Grid>

            <Grid item md={5} sm={5} lg={5} style={{ marginTop: '1%', paddingRight: "5px" }} >

              <div style={{
                display: "flex",
                flexDirection: "row",

              }}>

                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newEntry}
                        onChange={(e) => setNewEntry(e.target.checked)}
                        name="checkedB"
                        color="primary"
                        className={classes.increasedCheckbox}
                      />
                    }
                    label={<Typography variant="body1" style={{ fontSize: '16px' }}>
                      New Set-up
                    </Typography>}
                  />
                </Grid>

                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    style={{ marginRight: 4 }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={onEvaluate}
                    fullWidth
                    disabled={!evalFlag || spcEvalResultId}
                  >
                    <Typography variant="body1" style={{ fontSize: '16px' }}>
                      Evaluate
                    </Typography>
                  </Button>

                </Grid>

                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    style={{ marginLeft: 2 }}
                    variant="contained"
                    color="orange"
                    size="small"
                    onClick={clearTableData}
                    width="90%"
                  >
                    <Typography variant="body1" style={{ fontSize: '16px' }}>
                      Reset
                    </Typography>
                  </Button>

                </Grid>
              </div>

              <Grid item xs={12}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "5px" }}>
                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  <b>{spcEvalResult?.evaluation_number}</b> <ResultRenderer value={spcEvalResult?.spc_result} />
                </Typography>

              </Grid>

              <Box
                sx={{
                  border: '1px solid grey',
                  width: '98%',
                  padding: '5px',
                  marginTop: '10px',
                  height: '40%',
                  marginRight: '2%',
                  boxSizing: 'border-box',
                }}
              >

                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Location : <b>{spcEvalResult?.loc_val?.toFixed(4)}</b>
                </Typography>

                <Typography variant="body1" style={{ fontSize: '12px' }}>
                  Rules :   <div>
                    {spcEvalResult && spcEvalResult.loc_rules && (
                      <div>
                        {Object.entries(spcEvalResult.loc_rules).map(([ruleName, ruleValue]) => (
                          <span key={ruleName}>
                            <b>{ruleName}:</b>
                            <ResultRenderer value={ruleValue} />
                            {" | "}
                          </span>
                        ))}
                      </div>
                    )}

                  </div>

                </Typography>
              </Box>

              <Box
                sx={{
                  border: '1px solid grey',
                  width: '98%',
                  padding: '5px',
                  marginTop: '10px',
                  height: '38%',
                  marginRight: '2%',
                  boxSizing: 'border-box',
                }}
              >

                <Typography variant="body1" style={{ fontSize: '16px' }}>
                  Dispersion : <b>{spcEvalResult?.dis_val?.toFixed(4)}</b>
                </Typography>
                <Typography variant="body1" style={{ fontSize: '12px' }}>
                  Rules :   <div>
                    {spcEvalResult && spcEvalResult.dis_rules && (
                      <div>
                        {Object.entries(spcEvalResult.dis_rules).map(([ruleName, ruleValue]) => (
                          <span key={ruleName}>
                            <b>{ruleName}:</b>
                            <ResultRenderer value={ruleValue} />
                            {" | "}
                          </span>
                        ))}
                      </div>
                    )}

                  </div>

                </Typography>
              </Box>

            </Grid>

          </Grid>

        </Box>
      )}
    </Grid>
  )
}



