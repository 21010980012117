import React, { useEffect, useRef } from "react";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import moment from "moment";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import { useReactToPrint } from "react-to-print";
import { DGInput } from "../../../components/shared/DGInput";
import { ACTION_TYPE } from "../../../components/shared/commonenum";


export function CalibrationForm({
  componentProps = {},
  setHeaderButtons = () => { },
  Footer = () => { },
}) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    // console.log("componentProps", componentProps);
    setHeaderButtons([
      () => (
        <>


          {componentProps?.apiData?.pi_result?.certificate_link && (
            <Tooltip title="Download Certificate" arrow>
              <IconButton
                href={componentProps?.apiData?.pi_result?.certificate_link}
                aria-label="delete"
              >
                <CloudDownloadRoundedIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Print history" arrow>
            <IconButton
              href={componentProps?.apiData?.pi_result?.certificate_link}
              aria-label="delete"
              onClick={handlePrint}
            >
              <PrintRoundedIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    ]);
    ACTION_TYPE.view = true;
    return () => {
      ACTION_TYPE.view = false;
    };
  }, []);

  return (
    <div ref={componentRef}>
      <Grid container spacing={3}>
        <Grid item md={12} sm={12}></Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="report Number"
            value={componentProps?.apiData?.report_name}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Gauge SI Number"
            value={componentProps?.apiData?.gauge_item?.serial_number}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Gauge Name"
            value={componentProps?.apiData?.gauge_item?.guage?.name}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Group Family Number"
            value={componentProps?.apiData?.gauge_item?.guage?.number}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <DGInput
            label="Calibration Agency"
            value={
              componentProps?.apiData?.pi_agency
                ? componentProps?.apiData?.pi_agency[0]?.business_name ?? ""
                : componentProps?.apiData?.business_name
            }
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Requested Date"
            value={
              componentProps?.apiData?.requested_date
                ? moment(componentProps?.apiData?.requested_date).format(
                  "DD-MM-YYYY"
                )
                : ""
            }
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Calibrated On"
            value={
              componentProps?.apiData?.pi_result?.calibrated_date
                ? moment(
                  componentProps?.apiData?.pi_result?.calibrated_date
                ).format("DD-MM-YYYY")
                : ""
            }
          />
        </Grid>
        {/* <Grid item md={6} sm={6}>
        {" "}
        <TextField label="Calibration Due" variant="filled" value={componentProps?.apiData?.pi_due_date}  fullWidth disabled />
      </Grid> */}
        <Grid item md={6} sm={6}>
          <DGInput
            label="Next Calibration Due"
            value={
              componentProps?.apiData?.gauge_item?.pi_due_date
                ? moment(
                  componentProps?.apiData?.gauge_item?.pi_due_date
                )?.format("DD-MM-YYYY") ?? ""
                : ""
            }
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Approved Date"
            value={
              componentProps?.apiData?.pi_result?.approved_date
                ? moment(
                  componentProps?.apiData?.pi_result?.approved_date
                ).format("DD-MM-YYYY")
                : ""
            }
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Approved By"
            value={componentProps?.apiData?.pi_result?.approved_by_user?.name}
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <DGInput
            label="Certificate Number"
            value={componentProps?.apiData?.pi_result?.threeP_certificate ?? ""}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <DGInput
            label="Calibration Comments"
            value={componentProps?.apiData?.pi_result?.calibration_comments}
            multiline
            rows={4}
          />
        </Grid>
        <Grid item md={12} sm={12}>
          <DGInput
            label="Approver Comments"
            value={componentProps?.apiData?.pi_result?.comments}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
}
