import http from "./httpService";

const createProduct = (data) => {
  return http.post("/parts", data);
};

const updateProduct = (id, data) => {
  return http.patch(`/parts/${id}`, data);
};

const getProductbyRM = async () => {
  return http.get("/parts", {
    params: {
      raw_material: 1,
    },
  });
};

const getProductbySP = async () => {
  return http.get("/parts", {
    params: {
      special_process: 1,
    },
  });
};

const createComponent = (data) => {
  return http.post("/components", data);
};

const getProducts = async (clientId) => {
  return http.get("/parts", {
    params: {
      client_id: `${clientId}`,
    },
  });
};

const getProductsCountByClientId = async (clientId) => {
  return http.get("/parts", {
    params: {
      $limit: 0,
      client_id: `${clientId}`,
    },
  });
};

const getProductsCountByPlantId = async (plantId) => {
  return http.get("/parts", {
    params: {
      $limit: 0,
      plant_id: `${plantId}`,
    },
  });
};

const getAProductsCountByPlantId = async (plantId) => {
  return http.get("/parts", {
    params: {
      $limit: 0,
      plant_id: plantId,
      enabled: 1,
    },
  });
};


const getAProductByPlant = async (plant_id) => {
  return http.get("/part-plant-list", {
    params: { plant_id: plant_id },
  });
};

const getProductById = async (productId) => {
  return await http.get(`/parts/${productId}`);
};

const getProductStatus = async () => {
  return await http.get("/part-status");
};

const deleteComponent = (id) => {
  return http.delete(`/components/${id}`);
};

const getProductComponentsById = async (currentProductId) => {
  return await http.get("/components", {
    params: {
      parent_part_id: `${currentProductId}`,
    },
  });
};

function getProductDetailTabs() {
  return [
    { name: "CHARACETERSTICS" },
    { name: "MSA" },
    { name: "SPC" },
    { name: "PRODUCT INSPECTION", size: 2 },
    { name: "PRE-DISPATCH INSPECTION", size: 2 },
  ];
}
function getMSATabs() {
  return [{ name: "MSA" }, { name: "MSA Plan" }, { name: "" }, { name: "" }];
}

const getProductTableByClient = async (plant_id, client_id) => {
  return http.get("/parts-client-table", {
    params: {
      plant_id: plant_id,
      client_id: client_id,
    },
  });
};

// Get dropdown down data for component
const getComponentList = async (part_id, client_id) => {
  return http.get("/component-add-list", {
    params: {
      part_id: part_id,
      client_id: client_id,
    },
  });
};

const getPartHierarchy = async (part_id, display_enabled) => {
  return http.get("/part-hierarchy-list", {
    params: {
      part_id: part_id,
      display_enabled: display_enabled
    },
  });
};

// Add Component to product
const addComponentToPart = async (data) => {
  return http.post("/component-add", data);
};
//parent_part_id => Existing part
//component_part_id => Part to be added

const updateComponents = async (id, newStatus) => {
  return http.patch(`/components/${id}`, newStatus);
};


const getPartDocumentTable = async (part_id) => {
  return http.get("/part-document-table", {
    params: { part_id: part_id }
  })
};

const getDocumentType = async (part_id) => {
  return http.get("/part-document-select1", {
    params: {
      part_id: part_id
    }
  })
};

const addDocument = async (data) => {
  return http.post("/part-document-save", data);
};

const UpdateDocumentStatus = async (id, newStatus) => {
  return http.patch(`/part-document/${id}`, newStatus);
};

const getPartDetails = async (part_id) => {
  return http.get("/parts-detail", {
    params: { part_id: part_id }
  })
};


const getAssetPartPlantList = async (plant_id, client_id) => {
  return http.get("/asset-part-list", {
    params: {
      plant_id: plant_id,
      client_id: client_id
    }
  })
};





// eslint-disable-next-line import/no-anonymous-default-export
export default {
  createComponent,
  deleteComponent,
  createProduct,
  updateProduct,
  getProducts,
  getProductStatus,
  getProductById,
  getProductComponentsById,
  getProductsCountByPlantId,
  getProductsCountByClientId,
  getProductDetailTabs,
  getMSATabs,
  getProductbyRM,
  getProductbySP,
  getAProductsCountByPlantId,
  getProductTableByClient,
  getComponentList,
  getPartHierarchy,
  addComponentToPart,
  updateComponents,
  getAProductByPlant,

  getPartDocumentTable,
  getDocumentType,
  addDocument,
  UpdateDocumentStatus,
  getPartDetails,
  getAssetPartPlantList
};
