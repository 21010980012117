import { combineReducers } from "redux";
import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import globalReducer from "./globalReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  globalReducer,
});
