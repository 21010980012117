import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ExitToAppRounded } from "@material-ui/icons";
import moment from "moment";
import "./vendor.css";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import VendorDashboardContainer from "./VendorDashboardContainer";
import digitalGembaLogo from "../../assets/images/digitalGembaLogo.png";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { AuthContext } from "../../context/AuthContextProvider";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
const Spacer = styled.div(spacing);
const useStyles = makeStyles({
  table: {
    width: 450,
    marginRight: 1,
    height: 330,
    scroll: "auto",
  },
  media: {
    height: 140,
  },
});

const VendorDashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser, logoutUser } = useContext(AuthContext);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [businessDetails, setBusinessDetails] = useState({
    business_name: "",
    user_phone_number: "",
    vendor_access_from: "",
  });

  useEffect(() => {}, [businessDetails]);
  const getDateString = (inDate) => {
    const date = new Date(inDate);
    return (
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "/" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };
  const name = sessionStorage.getItem("customerName");
  const handleLogout = () => {
    logoutUser();
    history.push("/");
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div>
            <Typography
              variant="h1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#101B55",
                letterSpacing: "3px",
              }}
            >
              VENDOR DASHBOARD
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <img
              src={digitalGembaLogo ? digitalGembaLogo : ""}
              width="140rem"
              height="70rem"
              alt=" "
            />
          </div>
          <div>
            <Card>
              <CardContent className="userinfo">
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    size="medium"
                    style={{
                      width: "auto",
                      height: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          <Typography component="h5">
                            <span
                              style={{
                                color: "#101B55",
                                fontWeight: "bold",
                                fontSize: "large",
                              }}
                            >
                              Name
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography component="h5" className="uservalues">
                            <b>{businessDetails?.business_name}</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">
                          <Typography component="h5">
                            <span
                              style={{
                                color: "#101B55",
                                fontWeight: "bold",
                                fontSize: "large",
                              }}
                            >
                              Phone Number
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography component="h5" className="uservalues">
                            <b>{businessDetails?.user_phone_number}</b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {currentUser?.created_at ? (
                        <TableRow>
                          <TableCell align="left">
                            <Typography component="h5">
                              <span
                                style={{
                                  color: "#101B55",
                                  fontWeight: "bold",
                                  fontSize: "large",
                                }}
                              >
                                Registered On
                              </span>
                            </Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography component="h5" className="uservalues">
                              <b>
                                {businessDetails?.vendor_access_from
                                  ? moment(
                                      businessDetails?.vendor_access_from
                                    ).format("DD-MM-YYYY")
                                  : ""}
                              </b>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
              <CardActionArea
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  color="default"
                  startIcon={<ExitToAppRounded />}
                  onClick={() => setShowConfirmBox(true)}
                >
                  Logout
                </Button>
              </CardActionArea>
            </Card>
          </div>
        </div>
        <div style={{ marginTop: "-12rem" }}>
          <VendorDashboardContainer
            setBusinessName={setBusinessName}
            setBusinessDetails={setBusinessDetails}
          />
        </div>
      </div>
      {showConfirmBox && (
        <Dialog
          open={showConfirmBox}
          onClose={() => setShowConfirmBox(false)}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
          fullWidth
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#0d51ca",
              color: "#fff",
            }}
          >
            <DialogTitle id="form-dialog-title">
              Do you really want to logout ?
            </DialogTitle>
            <div>
              <Button onClick={() => setShowConfirmBox(false)}>
                <CloseRoundedIcon style={{ fill: "#fff" }} />
              </Button>
            </div>
          </div>
          <DialogContent pb={4}>
            <Spacer my={6} />
            <Button
              variant="outlined"
              color="primary"
              position="right"
              style={{ marginRight: "10px" }}
              onClick={() => setShowConfirmBox(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              position="right"
              onClick={() => handleLogout()}
            >
              Yes
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default VendorDashboard;
