import { Grid } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useContext, useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { DGInput } from "../../../components/shared/DGInput";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import { DGSelect } from "../../../components/shared/DGSelect";
import productionInspection from "../../../services/productionInspection";
import automatedInspectionService from "../../../services/automatedInspectionService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import CommonGrid from "../../../components/CommonGrid";
import plantService from "../../../services/plantService";
import uploadClientService from "../../../services/uploadClientService";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContextProvider";
import { DGDateTimePicker } from "../../../components/shared/DGDateTimePicker";

export function UploadClientView({
    Footer = () => { },
    componentProps = null,
    closeForm = () => { },
}) {

    const [file, setFile] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const [dropdownColumns, setDropdowncolumns] = useState([]);
    const [plantId, setPlantId] = useState();
    const { updateAlert } = useContext(AlertContext);
    const [bodyData, setBodyData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    //console.log(componentProps, "componentProps");

    let form = useFormik({
        initialValues: {
            part: null,
            batch: null,
            inspection_date: new Date(),
            inspection_time: new Date(),
            reportNumber: null,
        },
    });



    useEffect(() => {
        automatedInspectionService
            .getAutomatedInspectionDetail({
                id: componentProps?.id,
                client_id: currentUser?.client_id,
            })
            .then((res) => {
                //console.log("res", res.data);
                setHeaderData(res?.data?.header);
                let header = res?.data?.header;
                setBodyData(res?.data?.body)
                form.setFieldValue("part", `${header[0]?.part_number} - ${header[0]?.part_name}`);
                form.setFieldValue("batch", header[0]?.batch);
                form.setFieldValue("reportNumber", header[0]?.request_id);
                form.setFieldValue("inspection_date", header[0]?.inspection_date);


            });
    }, [componentProps]);

    useEffect(() => {
        if (bodyData.length === 0) return;

        const column = bodyData.map((item, index) => {
            return {
                field: `${index + 1}`,
                headerName: `${item.name} - ${item.specification}
                  ${item.upper_specification}  ${item.lower_specification}`,
                minWidth: 150,
            };
        })

        setColumns(column);


        const maxLength = Math.max(...bodyData.map(item => item.raw_data.length));
        const transposedData = Array.from({ length: maxLength }, (_, index) => {
            const row = {};
            bodyData.forEach((item, itemIndex) => {
                row[`${itemIndex + 1}`] = item.raw_data[index] !== undefined ? item.raw_data[index] : null;
            });
            return row;
        });

        setRows(transposedData);

    }, [bodyData]);


    return (
        <>
            <Grid container spacing={2} className="mt-2">
                <Grid item md={4} lg={4} sm={4}>
                    <DGInput
                        id="part"
                        label="Part Number"
                        value={form.values.part}

                    />
                </Grid>
                <Grid item md={2} lg={2} sm={2}>
                    <DGInput
                        id="reportNumber"
                        label="Report number"
                        value={form.values.reportNumber}
                    />
                </Grid>


                <Grid item md={4} lg={4} sm={4}>
                    <DGInput
                        id="batch"
                        label="Batch Details"
                        value={form.values.batch}
                    />
                </Grid>
                <Grid item md={2} sm={2} lg={2}>
                    <DGDateTimePicker
                        label="Inspection Date"
                        format="dd/MM/yyyy"
                        value={form.values.inspection_date}

                    />
                </Grid>

                <Grid item md={12} sm={12} lg={12}>
                    <CommonGrid
                        rows={rows}
                        columns={columns}
                        needExport
                    />
                </Grid>
            </Grid>
        </>
    );
}
