import React, { useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { useParams } from "react-router-dom";
import customerDashboardService from "../../../services/customerDashboardService";
import { Button, makeStyles } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { rgba } from "polished";

const useStyle = makeStyles({
  spec: {
    color: blueGrey[600],
    fontWeight: "bold",
    background: rgba(blueGrey[600], 0.1),
    padding: "2px 8px",
    borderRadius: "3px",
    margin: 5,
  },
});

export function CustomerCharacters() {
  const [rows, setRows] = useState([]);
  const { id } = useParams();
  const classes = useStyle();

  function GetFormattedValue({ value }) {
    if (value) {
      const specs = value.split(",");
      return (
        <>
          <b style={{ margin: 12 }}>{specs[0]}</b>
          <span className={classes.spec}> {specs[1]}</span>
          <span className={classes.spec}> {specs[2]}</span>
        </>
      );
    } else {
      return <></>;
    }
  }

  useEffect(() => {
    customerDashboardService.getCustomerProductChar(id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "character_name",
      headerName: "Character",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellStyle: (params) => {
        return {
          marginLeft: params?.data?.level > 0 ? params?.data?.level * 20 : 0,
        };
      },
      minWidth: 200,
    },
    {
      field: "specification",
      headerName: "Specification",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <>
          <GetFormattedValue
            value={
              params?.data?.specification !== null &&
                params?.data?.upper_specification !== null &&
                params?.data?.lower_specification !== null
                ? `${params?.data?.specification},${params?.data?.upper_specification},${params?.data?.lower_specification}`
                : null
            }
          />
        </>
      ),
      resizable: true,
      minWidth: 200,
    },
    {
      field: "criticality",
      headerName: "Criticality",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "gauge",
      headerName: "Gauge",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) => params?.data?.gauge_list?.join(",") ?? "",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "machine",
      headerName: "Machine",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) => params?.data?.machine_list?.join(",") ?? "",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "part_no",
      headerName: "Part",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        `${params?.data?.part_number}-${params?.data?.part_name}`,
      resizable: true,
      cellStyle: (params) => {
        return {
          marginLeft: params?.data?.level > 0 ? params?.data?.level * 20 : 0,
        };
      },
      minWidth: 200,
    },
  ];

  return <CommonGrid rows={rows} columns={columns} gridheight={150} />;
}
