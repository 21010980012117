import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;


const VendorDashboardLayout = ({ children }) => {
  return (
    <div className="login-bg h-100vh">
      <Root>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </Root>
    </div>
  );
};

export default VendorDashboardLayout;
