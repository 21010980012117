import React, { useCallback, useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import ActiveInActive from "../../../components/ActiveInActive";
import { Button } from "@material-ui/core";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import StatusFilter from "../../../components/shared/StatusFilter";
import customerDashboardService from "../../../services/customerDashboardService";
import { AuthContext } from "../../../context/AuthContextProvider";
import AddIcon from "@material-ui/icons/Add";
import { DGDrawer } from "../../../components/DGDrawer";
import { CustomerContactForm } from "./CustomerContactForm";
import { DateEditor } from "../../../components/cellRenderers/DateEditor";
import moment from "moment";

export function CustomerContactListing() {
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [formOpen, setFormOpen] = useState(false);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: () => reloadGridData(),
      callback: updateCustomerStatus,
    }));
    reloadGridData();
  }, []);

  function reloadGridData() {
    customerDashboardService
      .getCustomerClientTable(currentUser?.client_id)
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }

  function updateCustomerStatus(dummy1, dummy2, newStatus, rowId) {
    return customerDashboardService.updateCustomerAccess(
      { enabled: newStatus },
      rowId
    );
  }

  let columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "customer_company",
      headerName: "Customer",
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "customer_name",
      headerName: "Name",
      editable: false,
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      field: "access_start_date",
      headerName: "Access Start Date",
      resizable: true,
      floatingFilter: true,
      minWidth: 200,
      valueGetter: (params) =>
        params?.data?.access_start_date
          ? new Date(params.data.access_start_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      field: "access_end_date",
      headerName: "Access End Date",
      resizable: true,
      floatingFilter: true,
      minWidth: 200,
      valueGetter: (params) =>
        params?.data?.access_end_date
          ? new Date(params.data.access_end_date).toLocaleDateString()
          : "",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      editable: true,
      cellEditor: DateEditor,
      cellEditorParams: (params) => ({
        value: params?.data?.access_end_date,
      }),
      onCellValueChanged: (params) => {
        let data = params?.data;
        customerDashboardService
          .updateCustomerAccess(
            {
              access_start_date: moment(data?.access_start_date).format(
                "YYYY-MM-DD"
              ),
              access_end_date: data?.access_end_date,
            },
            data?.id
          )
          .then((res) => { });
      },
    },
    {
      field: "enabled",
      headerName: "Status",
      minWidth: 150,
      resizable: true,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: params?.data?.part_name ?? "",
              currentStatus: params?.data?.enabled,
              rowId: params?.data?.id,
            }))
          }
        />
      ),
    },
    {
      field: "enabled",
      headerName: "Status History",
      resizable: true,
      minWidth: 150,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params?.data?.id,
                entity: params?.data?.part_name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  function ButtonComponent() {
    return (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setFormOpen(true)}
      >
        CONTACT
      </Button>
    );
  }

  const onRowValueChanged = function (params) {
    var data = params.data;
    // console.log("inline edit", data);
    let itemsToUpdate = [];
    itemsToUpdate?.push(data);
    params?.api?.applyTransaction({ update: itemsToUpdate });
  };
  return (
    <>
      <CommonGrid
        rows={rows}
        columns={columns}
        gridheight={150}
        ButtonComponent={ButtonComponent}
        onRowValueChanged={onRowValueChanged}
      />
      <DGDrawer
        Component={CustomerContactForm}
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          reloadGridData();
        }}
        title="Add Customer Contact"
        needSave
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
