import { Button, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";
import { ACTION_TYPE } from "../../../components/shared/commonenum";
import CommonGrid from "../../../components/CommonGrid";
import { DGDrawer } from "../../../components/DGDrawer";
import { UploadClientForm } from "./UploadClientForm";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import uploadClientService from "../../../services/uploadClientService";
import plantService from "../../../services/plantService";
import automatedInspectionService from "../../../services/automatedInspectionService";
import { UploadClientView } from "./UploadClientView";
import moment from "moment";
import StatusFilter from "../../../components/shared/StatusFilter";

export function UploadClientListing({ partDetails = null }) {
  const [rows, setRows] = useState([]);
  const { setStatus } = useContext(StatusContext);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { globalPlant } = useContext(PlantContext);




  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: reloadgridData,
      // callback: changeStatus,
    }));
    if (globalPlant && globalPlant?.globalPlant?.id) {
      reloadgridData();
    }
  }, []);



  function reloadgridData() {
    automatedInspectionService
      .getAutomatedInspectionTable({
        client_id: currentUser?.client_id,
        plant_id: globalPlant?.globalPlant?.id,
      })
      .then((res) => {
        setRows(res?.data ?? []);
      });
  }

  function loadForm(data) {
    setFormOpen(true);
  }

  function loadView(data) {
    setViewOpen(true);
  }

  function onRowSelection(data) {
    //  console.log("onRowSelection", data);
    setFormData(data[0] ?? null);
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
      pinned: "left",
    },
    {
      field: "request_id",
      headerName: "Request #",
      filter: "agTextColumnFilter",
      minWidth: 100,
      pinned: "left",
      cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => loadView(params?.data)}
          >
            {`${params?.data?.request_id}`}
          </Button>
        );
      },
    },
    {
      headerName: "Part Details",
      minWidth: 250,
      filter: "agTextColumnFilter",
      pinned: "left",
      floatingFilter: true,
      valueFormatter: (params) => {
        return `${params?.data?.part_number}-${params?.data?.part_name}`;
      },
      /* cellRenderer: (params) => {
        return (
          <Button
            size="small"
            color="primary"
            onClick={() => loadForm(params?.data)}
          >
            {`${params?.data?.part_number}-${params?.data?.part_name}`}
          </Button>
        );
      }, */
      resizable: true,
    },
    /*     {
          headerName: "Gauge Details",
          minWidth: 150,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          resizable: true,
          valueFormatter: (params) => {
            return `${params?.data?.gauge_number || ""}-${params?.data?.gauge_name || ""}`;
          },
        }, */

    {
      field: "batch",
      headerName: "Batch",
      minWidth: 150,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      resizable: true,
    },
    {
      field: "inspection_date",
      headerName: "Inspection Date",
      sortable: true,
      resizable: true,
      minWidth: "200",
      floatingFilter: true,
      autoHeight: true,
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      valueGetter: (params) =>
        moment(new Date(params?.data?.inspection_date)).format("DD/MM/YYYY"),
    },
    {
      field: "inspection_time",
      headerName: "Inspection Time",
      sortable: true,
      resizable: true,
      minWidth: "180",
      floatingFilter: true,
      autoHeight: true,
      filter: "agTextColumnFilter",
    },
  ];

  useEffect(() => {
    if (globalPlant) {
      reloadgridData(globalPlant?.globalPlant?.id);
    }
  }, [globalPlant]);

  function ButtonComponent() {
    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setFormOpen(true);
            ACTION_TYPE.view = false;
          }}
        >
          ADD
        </Button>
      </>
    );
  }
  return (
    <Grid item md={12} sm={12} lg={12}>
      <CommonGrid
        rows={rows}
        columns={columns}
        ButtonComponent={ButtonComponent}
        onRowSelect={onRowSelection}
        gridheight={180}
      />
      <DGDrawer
        Component={UploadClientForm}
        isOpen={formOpen}
        drawerWidth={1150}
        title={"Upload Measurement"}
        setOpen={() => {
          setFormOpen(false);
          reloadgridData();
        }}
        onClose={() => {
          reloadgridData();
        }}
        needSave={true}
      />

      <DGDrawer
        Component={UploadClientView}
        isOpen={viewOpen}
        drawerWidth={1150}
        title={formData?.part_number + " - " + formData?.part_name}
        subtitle={formData?.request_id}
        setOpen={() => {
          setViewOpen(false);
        }}
        componentProps={formData}
      />
    </Grid>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
