import React, { useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import customerDashboardService from "../../../services/customerDashboardService";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { DGDrawer } from "../../../components/DGDrawer";
import { MsaReport } from "../../msa/msatab/reports"

export function CustomerMsas() {
  const [rows, setRows] = useState([]);
  const { id } = useParams();
  const [selectedROw, setSelectedRow] = useState(null);
  const [formOpen, setFormOpen] = useState(false);
  useEffect(() => {
    customerDashboardService.getCustomerMSAProduct(id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);
  function loadForm(data) {
    setSelectedRow({ studies_id: data?.studies_id, study_id: data?.id });
    setFormOpen(true);
  }

  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,
    },
    {
      field: "Report_Number",
      headerName: "Report Number",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: (params) => (
        <Button
          size="small"
          color="primary"
          onClick={() => loadForm(params?.data)}
        >
          {`${params?.data?.Report_Number}`}
        </Button>
      ),
      minWidth: 200,
    },
    {
      field: "part_name",
      headerName: "Part Details",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        `${params?.data?.part_number}-${params?.data?.part_name}`,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "name",
      headerName: "Character",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "vendor_name",
      headerName: "Specification",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      valueGetter: (params) =>
        `${params?.data?.specification}-${params?.data?.upper_specification}-${params?.data?.lower_specification}`,
      resizable: true,
      minWidth: 200,
    },
    {
      field: "Study_Name",
      headerName: "Study",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "Gauge",
      headerName: "Gauge",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: true,
      minWidth: 200,
    },
    {
      headerName: "Date",
      floatingFilter: true,
      valueGetter: (params) =>
        new Date(params?.data?.Test_Date).toLocaleDateString(),
      resizable: true,
      minWidth: 200,
      filterParams: filterParams,
      filter: "agDateColumnFilter",
    },
    {
      field: "Result",
      headerName: "Result",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <ResultRenderer value={params?.data?.Result} />,
      resizable: true,
      minWidth: 200,
    },
  ];
  return (
    <>
      <CommonGrid rows={rows} columns={columns} gridheight={150} />;
      <DGDrawer
        Component={MsaReport}
        setOpen={() => {
          setFormOpen(false);
          setSelectedRow(null);
        }}
        isOpen={formOpen}
        componentProps={{ ...selectedROw, disabledDownload: true }}
        drawerWidth={1150}
        title="Report View"
      />
    </>
  );
}

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("/");
    var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
  browserDatePicker: true,
};
