import {
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { indigo } from "@material-ui/core/colors";
import React from "react";

export function DrawingInfo({
  drawing_info = [
    { "Upper Specification": "", "Lower Specification": "", Tolerance: "" },
  ],
}) {
  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h5" gutterBottom align="center" style={{backgroundColor:indigo[100]}} >
        DRAWING REQUIREMENTS
      </Typography>
      <List>
        <Divider />
        <ListItem>
          <ListItemText primary={"Upper Specification"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={drawing_info[0]["Upper Specification"]}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Lower Specification"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={drawing_info[0]["Lower Specification"]}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={"Tolerance"} />
          <ListItemSecondaryAction>
            <ListItemText
              primary={drawing_info[0]["Tolerance"]}
              primaryTypographyProps={{ style: { fontWeight: "bolder" } }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
}
