import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { number, object, string } from "yup";
import { DGInput } from "../../components/shared/DGInput";
import { DGSelect } from "../../components/shared/DGSelect";
import { AlertContext } from "../../context/AlertContextProvider";
import { AuthContext } from "../../context/AuthContextProvider";
import dateService from "../../services/dateService";
import inviteService from "../../services/inviteService";
import roleService from "../../services/roleService";
import { PageTitleContext } from "../../context/PageTitleContextProvider";



export function InviteForm({ Footer = () => null, closeForm = () => { } }) {
  const { currentUser } = useContext(AuthContext);
  const { updateAlert } = useContext(AlertContext);
  const { v4: uuidv4 } = require("uuid");
  const { setHeaderPageTitle } = useContext(PageTitleContext);

  setHeaderPageTitle(`Invite`);

  let form = useFormik({
    initialValues: {
      phone: "",
      role: null,
      roles: [],
    },
    validationSchema: object({

      phone: string()
        .required('Phone number is required')
        .matches(/^[0-9]+$/, 'Phone number must be only digits')
        .length(10, 'Phone number must be exactly 10 digits'),

      role: object().required(),
    }),
    onSubmit: onFormSave,
  });


  useEffect(() => {
    getRoles();
  }, []);


  const getRoles = async () => {
    const response = await roleService.getRoles();
    if (response.data && response.data.data) {
      const data = response.data.data;
      const filteredData = data.filter((role) => role.id <= 10)
      form.setFieldValue("roles", filteredData ?? []);
    }
  };

  function onFormSave(values) {

    // console.log("values", values.phone);

    inviteService.checkInvitePhone(
      {
        phone_number: values.phone,
      })
      .then((res) => {
        //  console.log("res", res?.data?.status);
        if (res.data.status == 1) {
          updateAlert({
            open: true,
            type: "error",
            message: "Phone number already exists",
          });
        } else {
          const obj = {
            id: uuidv4(),
            invited_phone_number: values.phone,
            invited_by: currentUser.id,
            created_date: dateService.getDateInSqlFormat(new Date()),
            client_id: currentUser.client_id,
            assigned_role_id: values.role?.id,
          };


          inviteService.createInvite(obj).then((res) => {
            updateAlert({
              open: true,
              type: "success",
              message: "Invite Sent Sucessfully",
            });
            closeForm(true);
          });
        }
      })
      .catch((error) => {
        updateAlert({
          open: true,
          type: "error",
          message: "Something went wrong",
        });
      });


  }




  return (
    <Grid container spacing={4}>

      <Grid item md={12} sm={12} lg={12}>
        <DGInput
          id="phone"
          label="Phone Number"
          isFormik
          required
          type="number"
          value={form.values.phone}
          onChange={form.handleChange}
          error={form.touched.phone && form.errors.phone}
        />
      </Grid>

      <Grid item md={12} sm={12} lg={12}>
        <DGSelect
          id="user"
          label="User Role"
          options={form.values.roles}
          value={form.values.role}
          onChange={(val) => form.setFieldValue("role", val)}
          error={form.touched.role && form.errors.role}
        />
      </Grid>
      <Grid item md={12}>
        <Alert severity="info">
          Please enter the phone number of the user you would like to invite on
          Digital Gemba
        </Alert>
      </Grid>
      <Footer onSave={form.handleSubmit} />
    </Grid>
  );
}
