import { Box, Button, Grid, Divider, AppBar, Toolbar, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { object, boolean } from "yup";
import { AuthContext } from "../../../context/AuthContextProvider";
import disptachInspection from "../../../services/disptachInspection";
import { DGInput } from "../../../components/shared/DGInput";
import { DGDateInput } from "../../../components/shared/DGDateInput";
import { AlertContext } from "../../../context/AlertContextProvider";
import CommonGrid from "../../../components/CommonGrid";
import { NumericEditor } from "../../../components/cellRenderers/NumericEditor";
import { AngleEditor } from "../../../components/cellRenderers/AngleEditor";
import { ResultRenderer } from "../../../components/cellRenderers/ResultRenderer";
import { StatusContext } from "../../../context/StatusContextProvider";
import { format } from "date-fns";


export function PDIEvaluation({
    Footer = () => { },
    closeForm = () => { },
    componentProps = null,
}) {

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const { setStatus } = useContext(StatusContext);
    const { updateAlert } = useContext(AlertContext);

    let form = useFormik({
        initialValues: {
            generalContent: [],
            itemGroup: [],
            gridCheck: false,
            generalCheck: false,
            finalResult: 0
        },
        validationSchema: object({
            gridCheck: boolean()
                .oneOf([true], 'Grid check must be true')
                .required('Grid check is required'),
            generalCheck: boolean()
                .oneOf([true], 'Mandatory fields not filled')
                .required('Grid check is required'),


        }),

        onSubmit: recordData,
    });

    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: reloadGridData,
        }));

        reloadGridData();
    }, []);


    function reloadGridData() {
        disptachInspection.getPDIReportView(componentProps)
            .then((res) => {
                form.setFieldValue("generalContent", res.data?.general);
                form.setFieldValue("itemGroup", res.data?.static_header);
                if (res.data?.grid_details.length > 0) {

                    let arrayData = res?.data?.grid_details?.map((exp) => ({
                        ...exp,
                        ...exp?.values?.map((e) => e),
                    }));
                    setRows(arrayData);
                    checkRecord();
                } else {
                    const temp = res.data?.grid_plan_details.map((item) => ({
                        ...item,
                        pdi_result_id: null,
                    }));

                    setRows(temp);
                }
            })

    }

    useEffect(() => {
        if (form.values.generalContent) {
            loadColumns(form.values.generalContent?.max_sample_size);
        }
    }, [form.values.generalContent]);

    function onGridReady(params) {
        setGridApi(params?.api);
    }


    function loadColumns(samples = 0) {

        let entries = [];
        for (let i = 0; i < samples; i++) {
            entries.push({
                field: `${i}`,
                editable: (params) => {
                    let columnIndex = Number(params?.colDef?.field);
                    return columnIndex < params?.data?.sample_size;
                },
                minWidth: 80,
                resizable: true,
                valueGetter: (params) => {
                    if (
                        (params?.data[i] === 1 || params?.data[i] === "OK") &&
                        params?.data?.measurement_id === 303
                    ) {
                        return "OK";
                    } else if (
                        (params?.data[i] === 0 || params?.data[i] === "NOT OK") &&
                        params?.data?.measurement_id === 303
                    ) {
                        return "NOT OK";
                    } else {
                        return params?.data[i];
                    }
                },


                cellEditorSelector: (params) => {
                    if (params?.data?.character_type_id !== 105) {
                        if (params?.data?.measurement_id === 303) {
                            return {
                                component: "agSelectCellEditor",
                                params: {
                                    values: ["OK", "NOT OK"],
                                    valueListMaxHeight: "5",
                                    valueListMaxWidth: 100
                                },
                                popup: false,

                            };
                        }

                        else if (
                            params?.data?.measurement_id === 301 ||
                            params?.data?.measurement_id === 302
                        ) {
                            return {
                                component: NumericEditor,
                            };
                        } else {
                            return {
                                component: "agTextCellEditor",
                            };
                        }
                    } else {

                        if (params?.data?.measurement_id !== 301) {
                            return {
                                component: "agTextCellEditor",
                            };
                        }

                        else {
                            return {
                                component: AngleEditor,
                            }
                        };
                    }
                },


                colSpan: (params) => {
                    if (
                        Number(params?.colDef?.field) + 1 ===
                        Number(params?.data?.sample_size)
                    ) {
                        return samples - Number(params?.data?.sample_size) + 1;
                    } else {
                        return 1;
                    }
                },
                minWidth: 150,
                suppressMovable: true,
                headerValueGetter: (params) => Number(params?.colDef?.field) + 1,
            });
        }


        let static_columns = [
            {
                field: "order_number",
                headerName: "#",
                filter: "agTextColumnFilter",
                minWidth: 75,
                resizable: true,
            },
            {
                field: "characterstics",
                headerName: "Characterstics and Specification",
                filter: "agTextColumnFilter",
                minWidth: 200,
                resizable: true,
                autoHeight: true,
                cellStyle: {
                    whiteSpace: 'pre-wrap',
                    lineHeight: '1.3' // 
                },
                cellRenderer: (params) => {
                    return (
                        <>

                            <Typography variant="body1"
                                style={{ marginTop: '6px', }}>
                                {params?.data?.character_name}
                            </Typography>

                            {params?.data?.specification !== null &&
                                params?.data?.specification !== undefined && (
                                    <Typography variant="body2">
                                        {`${params?.data?.specification}  ${params?.data?.upper_specification ?? ""
                                            }  ${params?.data?.lower_specification ?? ""}`}
                                    </Typography>
                                )}

                            {
                                params?.data?.gauge_number !== null &&
                                params?.data?.gauge_number !== undefined && (
                                    <Typography variant="body2">
                                        {params?.data?.gauge_number} {params?.data?.gauge_name}
                                    </Typography>
                                )
                            }

                            {
                                <Typography variant="body2">
                                    {params?.data?.measurement_name}
                                </Typography>
                            }
                        </>
                    );
                },
            },

            {
                field: "character_result",
                headerName: "Result",
                cellRenderer: (params) => (
                    <ResultRenderer value={params?.data?.pdi_char_result} />
                ),
                minWidth: 150,
                resizable: true,
            },

        ];

        /*         let endColumns = [
                    {
                        field: "required_evaluation",
                        headerName: "Evaluation Required",
                        headerCheckboxSelection: false,
                        checkboxSelection: true,
                        suppressRowClickSelection: true,
                        minWidth: 100,
                        resizable: true,
                        setSelected: true
        
                    }
        
                ]; */
        setColumns([...static_columns, ...entries]);
    }

    function processCellFromClipboard(params) {
        if (!params?.value || isNaN(Number(params?.value))) {
            return "";
        } else {
            return Number(params.value);
        }
    }

    function evaluateData() {


        form.values.itemGroup.forEach((item, index) => {
            if (form.values.itemGroup[index].dirty) {
                const data = {
                    standard_value: form.values.itemGroup[index].default_value,
                    updated_at: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
                };
                disptachInspection.updatePDIResultStd(item.id, data);
            }
        });


        const temp = [];
        gridApi?.forEachNode((node) => {
            temp.push(node.data);
        });

        console.log(temp);

        const temp2 = temp.map(item => {
            const test = Object.keys(item)
                .filter(key => !isNaN(key))
                .map(key => {
                    if (item.measurement_id === 303) {
                        return item[key] === 'OK' ? 1 : 0;
                    }
                    return item[key];
                })

                .filter(value => value !== null && value !== undefined);

            console.log(test);

            return {
                ...item,
                raw_data: test,

            };
        });

        const data = {
            char_data: temp2,
            general: form.values.generalContent,

        }
        disptachInspection.createNewPDIReportEvaluate(data)
            .then((res) => {
                if (res && res.status === 201) {
                    reloadGridData();
                    updateAlert({
                        message: "Dispatch Evaluation done",
                        open: true,
                        type: "success",
                    });
                    checkRecord();
                } else {
                    updateAlert({
                        message: "Failed to evaluate Dispatch Report",
                        open: true,
                        type: "error",
                    });
                }
            })
            .catch((error) => {
                updateAlert({
                    message: "Failed to evaluate Dispatch Report",
                    open: true,
                    type: "error",
                });
            });


    }
    function checkRecord() {
        let found = false;

        gridApi?.forEachNode((node) => {
            if (node.data.pdi_char_result === null) {
                form.values.gridCheck = false;
                found = true;
                return;
            }
        });

        if (!found) {
            form.values.gridCheck = true;
        }


        let found2 = false;

        form.values.itemGroup.forEach((item) => {
            if (item.item_mandatory === 1 &&
                item.default_value !== null &&
                item.default_value !== '') {
                form.values.generalCheck = true;
                found2 = true;
                return;
            }
        });

        if (!found2) {
            form.values.generalCheck = false;
        }


        if (form.values.gridCheck && form.values.generalCheck) {
            let allPass = true;

            gridApi?.forEachNode((node) => {
                if (node.data.measurement_id < 304 && node.data.pdi_char_result !== 1) {
                    allPass = false;
                    return;
                }
            });


            form.values.finalResult = allPass ? 1 : 0;
        }




    }

    function recordData() {

        const data = {
            pdi_result_status: 3,
            pdi_final_result: form.values.finalResult,
            updated_at: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
        };

        disptachInspection.updatePDIRequest(componentProps, data)
            .then((res) => {
                if (res && res.status === 200) {
                    updateAlert({
                        message: "Dispatch Recorded",
                        open: true,
                        type: "success",
                    });
                    closeForm();
                } else {
                    updateAlert({
                        message: "Failed to record Dispatch Report",
                        open: true,
                        type: "error",
                    });
                }
            })


    }


    return (
        <Grid container spacing={2}>

            <Grid container spacing={2} style={{ "margin-top": "15px" }}>
                {form.values.itemGroup.map((item, index) => (
                    item.item_group_id !== 3 ? (

                        <Grid item xs={3} key={index}>
                            {item.pdi_standard_item_id === "3012459c-24ae-4015-95e6-3ad15d3ca365" ? (
                                <DGDateInput
                                    label={item.item_name}
                                    value={item.default_value}
                                    onChange={(val) => {
                                        form.setFieldValue(`itemGroup[${index}].default_value`, val);
                                        form.setFieldValue(`itemGroup[${index}].dirty`, true); // Mark as dirty

                                    }}
                                />
                            ) : item.pdi_standard_item_id === "7e373bba-fc6a-4f6b-b7f0-7fca72926759"
                                || item.pdi_standard_item_id === "048c25e4-7f49-4b5f-9831-35067979327d"
                                || item.item_group_id === 0 ?
                                (
                                    <DGInput
                                        label={item.item_name}
                                        disabled={true}
                                        value={item.default_value}

                                    />
                                ) :
                                item.item_group_id !== 3 ? (
                                    <DGInput
                                        label={item.item_name}
                                        value={item.default_value}
                                        onChange={(val) => {
                                            form.setFieldValue(`itemGroup[${index}].default_value`, val);
                                            form.setFieldValue(`itemGroup[${index}].dirty`, true);

                                        }}

                                    />
                                ) : null}
                        </Grid>

                    ) : null
                ))}
            </Grid>


            <Grid item xs={12}>
                <CommonGrid
                    rows={rows}
                    columns={columns}
                    needExport={false}
                    rowSelection={false}
                    horizontalTab
                    onGridReady={onGridReady}
                    processCellFromClipboard={processCellFromClipboard}
                //  singleClickEdit={true}



                //onRowSelect={onRowSelect}
                />
            </Grid>

            <Grid container spacing={2} style={{ marginTop: "15px" }}>
                {form.values.itemGroup.map((item, index) =>
                    item.item_group_id === 3 ? (
                        <Grid item xs={3} key={index}>
                            <DGInput
                                label={item.item_name}
                                value={item.default_value}
                                onChange={(val) => {
                                    form.setFieldValue(`itemGroup[${index}].default_value`, val);
                                    form.setFieldValue(`itemGroup[${index}].dirty`, true); // Mark as dirty


                                }}
                            />
                        </Grid>
                    ) : null
                )}
            </Grid>


            <AppBar position="fixed" style={{ width: 1200, top: "auto", bottom: 0 }}>
                <Divider />
                <Toolbar>
                    <div style={{ flexGrow: 1 }}></div>

                    <Button
                        style={{ margin: 4 }}
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => closeForm && closeForm(false)}
                    >
                        CANCEL
                    </Button>

                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={evaluateData}
                    // disabled={!form.isValid}
                    >
                        Evaluate
                    </Button>

                    <Button
                        style={{ margin: 4 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={form.handleSubmit}
                        disabled={!form.isValid}
                    >
                        Record
                    </Button>
                </Toolbar>
            </AppBar>


        </Grid>
    )


}