import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import CommonGrid from "../../../components/CommonGrid";
import AddIcon from "@material-ui/icons/Add";
import { useState } from "react";
import { useEffect } from "react";
import gaugeService from "../../../services/gaugeService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PageTitleContext } from "../../../context/PageTitleContextProvider";
import { useContext } from "react";
import { DGDrawer } from "../../../components/DGDrawer";
import { GaugeFamilyForm } from "./GaugeFamilyForm";
import { ACTION_TYPE } from "../../../components/shared/commonenum";

export function GaugeFamily() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [formOpen, setFormOpen] = useState(false);
  const { setHeaderPageTitle } = useContext(PageTitleContext);
  const [selectedData, setSelectedData] = useState({});
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [make, setMake] = useState("");
  const [type, setType] = useState("");
  const [count, setcount] = useState("");
  const [range, setRange] = useState("");
  const [time, setTime] = useState("");
  const [comments, setComments] = useState("");

  useEffect(() => {
    reloadGridData();
    ACTION_TYPE.add = true;
    return () => {
      ACTION_TYPE.add = false;
    }
  }, []);

  function reloadGridData() {
    return gaugeService.getGaugeFamily(currentUser?.client_id).then(res => {
      setRows(res?.data ?? [])
    })
  }

  useEffect(() => {
    setHeaderPageTitle(`Gauge Family`);

  }, []);



  const columnDefs = [
    {
      headerName: "#",
      minWidth: 80,
      sortable: false,
      pinned: "left",
      valueGetter: "node.rowIndex + 1",
      cellStyle: { backgroundColor: "#f1f1f1" },
      cellRenderer: (params) => {
        if (params.value !== undefined) {
          return params.value;
        } else {
          return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
        }
      },
    },
    {
      field: "family_number",
      headerName: "Gauge Number",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      pinned: "left",
      cellRenderer: (params) => (
        <>
          <Link
            style={{ color: "#2196f3" }}
            variant="body2"
            to={`/gauge-details/${params.data.guage_id}`}
          >
            {params.value}
          </Link>
        </>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "count_",
      headerName: "Number off",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "make",
      headerName: "Make",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "least_count",
      headerName: "Least Count",
      pinned: "left",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "range",
      headerName: "Range",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "type_name",
      headerName: "Type",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },
    {
      field: "inspection_guidelines",
      headerName: "Inspection Guidelines",
      sortable: true,
      minWidth: 200,
      floatingFilter: true,
      filter: "agMultiColumnFilter",
      cellRenderer: (params) => <span>{params.value}</span>,
    },





  ];
  return (
    <>
      {" "}
      <CommonGrid
        rows={rows}
        columns={columnDefs}
        gridheight={150}
        ButtonComponent={() => (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setFormOpen(true)}
          >
            GAUGE
          </Button>
        )}
      />
      <DGDrawer
        componentProps={{}}
        Component={GaugeFamilyForm}
        isOpen={formOpen}
        drawerWidth={500}
        setOpen={(state) => {
          setFormOpen(state);
          reloadGridData();
        }}
        onCancel={() => setFormOpen(false)}
        title={"New Gauge"}
        subtitle={"15/09/2022"}
        needSave={true}
      />
    </>
  );
}
