import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography, Divider, CardHeader, Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { DGDrawer } from "../../components/DGDrawer";
import { GaugeOverDueList } from "../gauge/gaugeOverdueDetail";
import { GaugeCalibrationList } from "../gauge/gaugeStatusDetails";

const colorFill = [
    "#0000FF",  // Blue
    "#800080",  // Purple
    "#FFA500",  // Orange
    "#FFC0CB",  // Pink
    "#E6E6FA",  // Lavender
    "#00FFFF",  // Cyan
    "#FF00FF",  // Magenta
    "#082567",  // Sapphire
    "#40E0D0",  // Turquoise
    "#EE82EE",  // Violet
    "#FFD700",  // Gold
    "#FF1493",  // Deep Pink
    "#9370DB",  // Medium Purple
    "#DA70D6",  // Orchid
    "#F0E68C"   // Khaki
];

const useStyledDivStyleCard2 = () => ({
    maxWidth: '98%',
    width: '100%',
    height: '60px',
    borderRadius: '10%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    boxSizing: 'border-box'

})


export function OverviewCard({ name = "", count = 0,
    backgroundColor = "#E4E6E7", cardHeight = 150 }) {
    return (
        <Card style={{ backgroundColor: backgroundColor, height: cardHeight }}>
            <CardContent>
                <Grid container>
                    <Grid item md={12} sm={12} lg={12}>
                        <Typography align="center" variant="h4"
                        >
                            {name}
                        </Typography>
                        <Typography align="center" variant="h1">
                            {count}
                        </Typography>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

// Asset Card
export function OverviewCard3({
    mainHeading = "",
    subHeading = "",
    metrics = "",
    drawerWidth = "",
    Component = ""
}) {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    const componentMap = {
        GaugeOverDueList: GaugeOverDueList,
        GaugeCalibrationList: GaugeCalibrationList
    };

    const templink = componentMap[Component]

    return (
        <>
            <DGDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                setOpen={closeDrawer}
                drawerWidth={drawerWidth}
                title={subHeading}
                Component={templink}
            />

            <div onClick={() => { setDrawerOpen(true) }} className="dg_dash_am">
                <span className="dg_dash_cc_tagline">
                    {mainHeading}
                </span>
                <h6 className="dg_dash_cc_title">
                    {subHeading}
                </h6>

                {/* // <h6 className="dg_dash_am_title">Gauge Caliberation</h6> */}
                <div className="dg_dash_am_gauge_count">
                    <div className="dg_dash_am_gauge_count_no">
                        {metrics[(Object.keys(metrics)[0])]}
                    </div>
                    <div className="dg_dash_am_gauge_count_title">
                        {Object.keys(metrics)[0]}
                    </div>
                </div>
                <div className="dg_dash_am_gauge_count">
                    <div className="dg_dash_am_gauge_count_no">
                        {metrics[(Object.keys(metrics)[1])]}
                    </div>
                    <div className="dg_dash_am_gauge_count_title">
                        {Object.keys(metrics)[1]}
                    </div>
                </div>
                <div className="dg_dash_am_gauge_count">
                    <div className="dg_dash_am_gauge_count_no">
                        {metrics[(Object.keys(metrics)[2])]}
                    </div>
                    <div className="dg_dash_am_gauge_count_title">
                        {Object.keys(metrics)[2]}
                    </div>
                </div>
            </div>
        </>
    );
}
