import React from "react";
import { Grid, Box } from "@material-ui/core";
import CardForInfo from "./cards/cardForInfo";
import CardForChar from "./cards/cardForChar";
import CardsForTable from "./cards/cardForTable";
import CardsForDraw from "./cards/cardForDraw";
import CardsForOutcome from "./cards/cardForOutcome";
import CardsForEval from "./cards/cardForEval";
import CardsForResult from "./cards/cardForResult";
import CardsForScatter from "./cards/cardForScatter";
import { useMediaQuery } from "@material-ui/core";
import "./PrintPageContainer.css";

const PrintPageGridLayout = ({
  studyTitle,
  studyData,
  gaugeCharacter,
  forNextPage,
}) => {
  const matchesPrint = useMediaQuery("print");

  return (
    <React.Fragment>
      <Box container spacing={3}>
        {matchesPrint &&
        gaugeCharacter?.gauge &&
        gaugeCharacter?.character?.part &&
        studyData ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              marginBottom: 7,
            }}
          >
            <CardForInfo
              title={"Product & Gauge Info"}
              part={gaugeCharacter.character.part}
              studyData={studyData}
              gauge={gaugeCharacter.gauge}
            />
            <CardForChar
              title={"Characteristic Info"}
              character={gaugeCharacter.character}
              studyData={studyData}
            />
          </Box>
        ) : null}
        {!matchesPrint &&
        gaugeCharacter &&
        gaugeCharacter.character &&
        gaugeCharacter.gauge &&
        gaugeCharacter.character.part &&
        studyData ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              width: "100%",
              marginBottom: 7,
            }}
          >
            <CardForInfo
              title={"Product & Gauge Info"}
              part={gaugeCharacter.character.part}
              studyData={studyData}
              gauge={gaugeCharacter.gauge}
            />
            <CardForChar
              title={"Characteristic Info"}
              character={gaugeCharacter.character}
              studyData={studyData}
            />
          </Box>
        ) : null}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: 7,
          }}
          className="print-container"
        >
          <div className="page-break" />
          <CardsForTable
            title={"Data Table"}
            studyType={studyTitle}
            studyData={studyData}
            forNextPage={false}
          />
        </Box>
        {studyTitle !== "Attribute system analysis: Signal Method" &&
        studyTitle !== "Attribute system analysis: Kappa Method" ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginBottom: 7,
              justifyContent: "center",
            }}
          >
            <CardsForScatter
              title={"Run Chart"}
              studyData={studyData}
              studyType={studyTitle}
              gaugeCharacter={gaugeCharacter}
            />
          </Box>
        ) : null}
        {matchesPrint && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              marginBottom: 7,
              justifyContent: "space-between",
            }}
          >
            <CardsForDraw
              title={"Drawing Requirement"}
              studyData={studyData}
              studyType={studyTitle}
            />
            <CardsForOutcome
              title={"Process Outcome"}
              studyData={studyData}
              studyType={studyTitle}
            />
            <CardsForEval
              title={"Evaluation"}
              studyData={studyData}
              studyType={studyTitle}
            />
          </Box>
        )}
        {!matchesPrint && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              marginBottom: 7,
              justifyContent: "space-between",
            }}
          >
            <CardsForDraw
              title={"Drawing Requirement"}
              studyData={studyData}
              studyType={studyTitle}
            />
            <CardsForOutcome
              title={"Process Outcome"}
              studyData={studyData}
              studyType={studyTitle}
            />
            <CardsForEval
              title={"Evaluation"}
              studyData={studyData}
              studyType={studyTitle}
            />
          </Box>
        )}
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            marginBottom: 7,
          }}
        >
          <CardsForResult
            title={"Result Table"}
            studyData={studyData}
            studyType={studyTitle}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PrintPageGridLayout;
