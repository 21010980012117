import React from "react";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import OutData from "../dataSet/outcomeData.json";

const CardForOutcome = ({ title, param, studyData, studyType }) => {
  const customStyle = {
    minHeight: "170px",
  };
  /* console.log("props in CardForOutcome", {
    title,
    param,
    studyData,
    studyType,
  }); */

  return (
    <React.Fragment>
      {studyType !== "Attribute system analysis: Kappa Method" &&
      studyType !== "Attribute system analysis: Signal Method" ? (
        <Paper
          variant="outlined"
          square
          elevation={0}
          style={{ width: "100%", marginRight: 16 }}
        >
          <Typography variant="h6" style={{ padding: 8 }} className="bg-light">
            {title}
          </Typography>
          {studyData && studyData.result ? (
            <Table aria-label="simple table" size="small">
              <TableBody>
                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Minimum Value</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>{parseFloat(studyData.result.min_value).toFixed(3)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Maximum Value</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>{parseFloat(studyData.result.max_value).toFixed(3)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align="left" style={{ borderBottom: "none" }}>
                    <Typography color="initial">Range</Typography>
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <Typography component="h5" color="textPrimary">
                      <b>{parseFloat(studyData.result.range).toFixed(3)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : null}
        </Paper>
      ) : null}
    </React.Fragment>
  );
};

export default CardForOutcome;
