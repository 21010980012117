import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import CommonGrid from "../../../components/CommonGrid";
import ProductionValueStreamOperatorMap from "./production_valueStreamMapOperators";
import AddIcon from '@material-ui/icons/Add';
import { DGDrawer } from "../../../components/DGDrawer";
import productionMgmtService from "../../../services/productionMgmtService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { PlantContext } from "../../../context/PlantContextProvider";
import ActiveInActive from "../../../components/ActiveInActive";
import StatusFilter from "../../../components/shared/StatusFilter";
import { StatusContext } from "../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../context/StatusHistoryProvider";


export default function ProductionValueStreamOperator() {

    const [rows, setRows] = useState([])
    const [formOpen, setFormOpen] = useState(false)
    const { currentUser } = useContext(AuthContext);
    const { globalPlant } = useContext(PlantContext);
    const { setStatus } = useContext(StatusContext);
    const { setStatusHistory } = useContext(StatusHistoryContext);
    const [plantShowFlag, setPlantShowFlag] = useState(false);



    useEffect(() => {
        setStatus((prev) => ({
            ...prev,
            reloadGrid: () => reloadGridData(),
            callback: changeStatus,
        }));
        reloadGridData();

    }, []);




    useEffect(() => {
        if (globalPlant?.globalPlant?.id === currentUser?.client_id) {
            setPlantShowFlag(true)
        }
        else { setPlantShowFlag(false) }
        reloadGridData()

    }, [globalPlant]);


    function reloadGridData() {
        productionMgmtService.getVSOperatorTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        }
        ).then((response) => {
            setRows(response.data)
        }).catch((error) => {
            console.log(error)
        })

    }




    const columns = [

        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: (params) => {
                if (params.node && !params.node.group && params.node.parent) {
                    const parentGroup = params.node.parent;
                    const childIndex = parentGroup.allLeafChildren.indexOf(params.node);
                    return childIndex + 1;
                }
                return '';
            },
            filter: "agTextColumnFilter",
            minWidth: 50,
        },

        ...(plantShowFlag ? [{
            headerName: "Plant",
            field: "plant_name",
            width: 200,
            rowGroup: true,
            rowGroupIndex: 0
        }] : []),
        {
            headerName: "Value Stream",
            field: "valueStream_name",
            width: 200,
            rowGroup: true,
            rowGroupIndex: plantShowFlag ? 1 : 0

        },
        {
            field: "operator_name",
            headerName: "Operator",
            valueGetter: (params) => {
                return `${params.data?.operator_list_id} ${params.data?.operator_list_name}`
            },
            filter: "agTextColumnFilter",
        },

        {
            field: "enabled",
            headerName: "Status",
            minWidth: 150,
            resizable: true,
            filter: "agNumberColumnFilter",
            floatingFilter: true,
            suppressMenu: true,
            floatingFilterComponent: StatusFilter,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            cellRenderer: (params) => (
                <ActiveInActive
                    status={params?.data?.enabled}
                    onClick={() =>
                        setStatus((prev) => ({
                            ...prev,
                            open: true,
                            callback: changeStatus,
                            reloadGrid: reloadGridData,
                            entityName: params?.data?.valueStream_name +
                                " - " + params?.data?.operator_list_id +
                                " - " + params?.data?.operator_list_name,
                            currentStatus: params?.data?.enabled,
                            rowId: params?.data?.id,
                        }))
                    }
                />
            ),
        },
        {
            field: "enabled",
            headerName: "Status History",
            resizable: true,
            minWidth: 150,
            cellRenderer: (params) => {
                return (
                    <Button
                        onClick={() =>
                            setStatusHistory((prev) => ({
                                ...prev,
                                isOpen: true,
                                rowId: params?.data?.id,
                                entityName: params?.data?.valueStream_name +
                                    " - " + params?.data?.operator_list_id +
                                    " - " + params?.data?.operator_list_name,
                            }))
                        }
                        size="small"
                        color="primary"
                    >
                        View
                    </Button>
                );
            },
        }

    ]



    function changeStatus(dummy1, dummy2, newStatus, rowId) {
        return productionMgmtService.updateVSOperatorRecord(rowId, { enabled: newStatus });
    }

    function ButtonComponent() {
        return (

            <>
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                        setFormOpen(true);
                    }}
                >
                    Map Operator
                </Button>

            </>
        );
    }

    return (

        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CommonGrid
                        columns={columns}
                        rows={rows}
                        ButtonComponent={ButtonComponent}
                        gridheight={150}
                        groupDefaultExpanded={-1}


                    />

                </Grid>
            </Grid>

            <DGDrawer
                isOpen={formOpen}
                setOpen={() => {
                    setFormOpen(false)
                    reloadGridData()
                }}
                title="Map Machine"
                drawerWidth={800}
                needSave
                Component={ProductionValueStreamOperatorMap}
            />

        </>
    )


};