import React from "react";
import styled, { withTheme } from "styled-components/macro";
import {
  Grid,
  Typography,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Button,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DGSelect } from "./shared/DGSelect";
import { SubNavigation } from "./shared/SubNavigation";
import { UserMenu } from "../pages/auth/UserMenu";
import digitalgembalogo from "../assets/images/digitalGembaLogo.png"

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const AppBarComponent = ({
  onDrawerToggle,
  pageTitle,
  onNavigateBack,
  plants = [],
  selectedPlant = {},
  setSelectedPlant = () => { },
  selectedRoutes = [],
}) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={5}>
      <Toolbar>

        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginBottom: "5px", marginTop: "5px" }}
          >
            <Grid item>
              <Button variant="text" color="primary" onClick={onNavigateBack}>
                <ArrowBackIcon />
              </Button>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                style={{
                  color: "#376fd0",
                  paddingTop: "6px",
                }}
              >
                {pageTitle}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <img
                  src={digitalgembalogo}
                  style={{ width: "7rem" }}
                  alt="logo"
                />
              </div>
            </Grid>
          </Grid>
        </>


      </Toolbar>
    </AppBar>
  </React.Fragment >
);

export default withTheme(AppBarComponent);
