import http from "./httpService";

const createMSAPlan = async (data) => {
    return await http.post("/msa-plan", data);
}

const getMSAPlan = async (id) => {
    return await http.get(`/msa-plan/${id}`);
}

const updateMSAPlan = async (id, data) => {
    return await http.patch(`/msa-plan/${id}`, data);
};

const getAllMSAPlansByClientId = async (clientId) => {
    return await http.get("/msa-plan", {
        params: {
            client_id: `${clientId}`,
            "status_id[$ne]": 2,
        },
    });
};

const getMSAByGaugeCharacter = async (gaugeId, characterId) => {
    return  http.get("/msa-plan", {
        params: {
            gauge_id: `${gaugeId}`,
            character_id: `${characterId}`,
        },
    });
};


const getMSAPlanTable = async (client_id,plant_id) => {
    return http.get("/msa-plan-table",{
        params:{
            client_id:client_id,
            plant_id:plant_id
        },
    });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getAllMSAPlansByClientId,
    createMSAPlan,
    getMSAPlan,
    updateMSAPlan,
    getMSAByGaugeCharacter,
    getMSAPlanTable
};