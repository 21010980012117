import React, { useContext, useEffect, useState } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { AuthContext } from "../../../context/AuthContextProvider";
import customerDashboardService from "../../../services/customerDashboardService";
import { Rules } from "../../../components/cellRenderers/RulesRenderer";
import { Link } from "react-router-dom";

export function CustomerProcessCapability() {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    customerDashboardService.getCustomerCpkCCM(currentUser?.id).then((res) => {
      setRows(res?.data ?? []);
    });
  }, []);
  const columns = [
    {
      headerName: "Sl No.",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      width: 100,
    },

    {
      headerName: "Vendor Name",
      field: "vendor_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Customer Name",
      field: "customer_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Vendor Plant",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
    },
    {
      headerName: "Part Detail",
      field: "plant_name",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 150,
      cellRenderer: (params) => (
        <>
          <Link
            style={{
              cursor: "pointer",
              color: "#2196f3",
            }}
            variant="body2"
            to={`/customer/characterstics/${params.data.part_id}`}
          >
            {`${params?.data?.part_number}-${params?.data?.part_name}`}
          </Link>
        </>
      ),
    },
    {
      field: "characterstic_name",
      headerName: "Character",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: "Specification",
      resizable: true,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
      valueGetter: (params) =>
        `${params?.data?.specification} ${params?.data?.upper_specification} ${params?.data?.lower_specification}`,
    },
    {
      field: "result_",
      headerName: "Trend",
      cellRenderer: "agSparklineCellRenderer",
      minWidth: 200,
      cellRendererParams: {
        sparklineOptions: {
          type: 'column',
          fill: '#91cc75',

          stroke: '#91cc75',
          formatter: columnFormatter,
          highlightStyle: {
            fill: 'dg'
          },
          paddingInner: 0.5,
          paddingOuter: 0.2,
        },
      },
    },
  ];
  return <CommonGrid rows={rows} columns={columns} gridheight={150} />;
}

function columnFormatter(params) {
  const { yValue, highlighted } = params;

  if (highlighted) {
    return;
  }
  return { fill: yValue < 0 ? palette.red : palette.green };
}

const palette = {
  blue: 'rgb(20,94,140)',
  green: 'rgb(63,141,119)',
  red: 'rgb(255,0,0)',
  dg: 'rgb(95,107,186)'
};

