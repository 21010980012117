import React, { useEffect } from "react";
import { useState } from "react";
import CommonGrid from "../../../../../components/CommonGrid";

export function DataInfo({ data_info = [] }) {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let data = JSON?.parse(data_info[0]?.input_values ?? "[]") ?? [];
    let rowData = [];
    let columnData = [];
    let formattedData = [];
    data.map((appraiser, i) => {
      appraiser?.trials?.map((trl, j) => {
        trl = trl?.map((exp) => {
          let obj = {};
          obj[`${appraiser["Operator name"]}-${i}-${j}`] = exp;
          return obj;
        });
        formattedData?.push(trl);
      });
    });
    for (let i = 0; i < formattedData[0]?.length ?? 0; i++) {
      formattedData.map((exp) => {
        rowData[i] = { ...rowData[i], ...exp[i] };
      });
    }
    data?.map((exp, k) => {
      let cl = { headerName: exp["Operator name"], children: [] };
      exp?.trials?.map((row, i) => {
        let childrens = [];
        childrens.push({
          field: `${exp["Operator name"]}-${k}-${i}`,
          headerName: `Trial ${i + 1}`,
          minWidth: 100,
        });
        cl["children"] = [...cl["children"], ...childrens];
      });
      columnData?.push(cl);
    });
    setColumns(columnData);
    setRows(rowData);
  }, [data_info]);

  return (
    <CommonGrid
      needExport={false}
      rows={rows}
      columns={columns}
      gridheight={500}
      fixedHeight
      id="grr1"
    />
  );
}
