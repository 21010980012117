import { Box, Button, Grid, makeStyles } from "@material-ui/core";
import { useState, useEffect, useContext } from "react";
import CommonGrid from "../../../components/CommonGrid";
import { DGSelect } from "../../../components/shared/DGSelect";
import { useFormik } from "formik";
import { grid } from "@chakra-ui/react";
import machineService from "../../../services/machineService";
import userService from "../../../services/userService";
import productionMgmtService from "../../../services/productionMgmtService";
import { PlantContext } from "../../../context/PlantContextProvider";
import { AuthContext } from "../../../context/AuthContextProvider";
import { DeleteRenderer } from "../../../components/cellRenderers/DeleteRenderer";
import { AlertContext } from "../../../context/AlertContextProvider";
import { object, array } from "yup";



export default function ProductionValueStreamOperatorMap(
    {
        Footer = () => { },
        closeForm = () => { },
    }
) {

    const { currentUser } = useContext(AuthContext)
    const { globalPlant } = useContext(PlantContext)
    const [gridApi, setGridApi] = useState(null);
    const { v4: uuidv4 } = require('uuid');
    const { updateAlert } = useContext(AlertContext);




    const form = useFormik({
        initialValues: {
            valueStreamOptions: [],
            valueStreamSelected: "",
            operatorSelected: null,
            operatorOptions: [],
            gridData: [],
            selectedOperator: []
        },
        validationSchema: object().shape({
            gridData: array().min(1, "At least one row is required"),
        }),

    })

    useEffect(() => {

        let filteredIds

        productionMgmtService.getVSOperatorTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        }
        ).then((response) => {
            const data = response.data;
            filteredIds = data
                .filter(item => item.enabled === 1)
                .map(item => item.operator_id);

            form.setFieldValue("selectedOperator", filteredIds);
        })

        productionMgmtService.getVSTable({
            plant_id: globalPlant?.globalPlant?.id,
            client_id: currentUser.client_id
        })
            .then((res) => {
                form.setFieldValue("valueStreamOptions", res?.data ?? []);
            })



        userService.getOperatorTable({
            client_id: currentUser?.client_id,
            plant_id: globalPlant?.globalPlant?.id,
        })
            .then((res) => {
                const data = res?.data;
                const filteredIds1 = data.filter(item => item.enabled === 1)
                const filteredIds2 = filteredIds1.filter(item => !filteredIds.includes(item.operator_Id));
                form.setFieldValue("operatorOptions", filteredIds2 ?? []);
            })


    }, [])




    const columns = [
        {
            cellRenderer: DeleteRenderer,
            maxWidth: 75,
            cellRendererParams: {
                onClick: (data, rowIndex) => {
                    removeDetailRow(rowIndex, data);
                },
            },
        },
        {
            field: "serial_number",
            headerName: "SI No",
            valueGetter: "node.rowIndex + 1",
            filter: "agTextColumnFilter",
            maxWidth: 100,
        },

        {
            field: "operator_name",
            headerName: "Operator",
            valueGetter: (params) => {
                return `${params.data?.operator_id} ${params.data?.operator_name}`
            }


        }




    ]

    function removeDetailRow(index, rowData) {
        let data = form.values.gridData;
        data.splice(index, 1);
        gridApi.updateRowData({ remove: [rowData] });
        form.setFieldValue("gridData", data);
    }

    function machineList(machine_id) {
        const updatedMachine = form.values.machineOptions.filter(item => item.machine_id !== machine_id)
        form.setFieldValue("machineOptions", updatedMachine)


    }

    function onAdd() {
        const updatedCheckPoint = {
            ...form.values.operatorSelected,
            ...form.values.valueStreamSelected
        };

        form.setFieldValue("gridData", [
            ...form.values.gridData,
            updatedCheckPoint,
        ]);

        form.setFieldValue("operatorSelected", null)

    }


    function onGridReady(params) {
        setGridApi(params?.api);
    }

    function operatorList(operator_id) {
        const updatedOperator = form.values.operatorOptions.filter(item => item.operator_Id !== operator_id)
        form.setFieldValue("operatorOptions", updatedOperator)


    }


    function handleSave() {
        let payload = [];
        gridApi?.forEachNode((node) => {
            payload.push({
                id: uuidv4(),
                production_valueStream_id: form.values.valueStreamSelected.id,
                operator_id: node.data?.operator_Id,
                plant_id: globalPlant?.globalPlant?.id,
                created_by: currentUser.id,
            });
        }
        );

        productionMgmtService.createVSOperatorRecord(payload)
            .then((res) => {
                closeForm();
                updateAlert({
                    message: "Operator mapped to Value Stream SuccessFully.",
                    open: true,
                    type: "success",
                });
            })
            .catch((err) => {
                console.log(err);
            })

    }

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={6}>
                    <DGSelect
                        label="Value Stream"
                        name="valueStreamSelected"
                        options={form.values.valueStreamOptions}
                        getOptionLabel={(option) => option.valueStream_name}
                        value={form.values.valueStreamSelected}
                        onChange={(val) => {
                            form.setFieldValue("valueStreamSelected", val);
                        }}
                        disabled={form.values.valueStreamSelected}
                    />
                </Grid>
                <Grid item xs={6}>


                    <DGSelect
                        label="Operator"
                        name="operatorSelected"
                        options={form.values.operatorOptions}
                        getOptionLabel={(option) => option.operator_id + " - " + option.operator_name}
                        value={form.values.operatorSelected}
                        disabled={!form.values.valueStreamSelected}
                        onChange={(val) => {
                            form.setFieldValue("operatorSelected", val);
                            operatorList(val.operator_Id)
                        }}
                    />
                </Grid>

                <Grid item md={12} sm={12} lg={12}
                    style={{ padding: '10px', padding: '20px' }}>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={onAdd}
                        disabled={!form.values.operatorSelected}
                    >
                        ADD
                    </Button>

                </Grid>

                <Grid xs={12}>
                    <CommonGrid
                        rows={form.values.gridData}
                        columns={columns}
                        onGridReady={onGridReady}

                    />


                </Grid>

            </Grid>



            <Footer
                onSave={handleSave}
                disabled={form.values.gridData.length > 0}
            />

        </>
    )


}