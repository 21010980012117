import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import highchartsDumbbell from "highcharts/modules/dumbbell";
import React, { useEffect, useState } from "react";
import HC_more from "highcharts/highcharts-more";
HC_more(Highcharts);
highchartsDumbbell(Highcharts);


export function GraphInfo({graph_info=[]}) {
    useEffect(()=>{
      if(graph_info && graph_info.length)
      {
        setChartOptions(prev=>({...prev,series: [
            {
              name: "Measured Values",
              data:JSON.parse(graph_info[0]?.graph_data??'[]')??[]
            }]}))
      }
    },[graph_info])
    const [chartOptions, setChartOptions] = useState({ 
     title: {
        text: ''
      },
  
      subtitle: {
        text: ''
      },
  
      yAxis: {
        title: {
          text: 'Number of Samples'
        }
      },
  
      xAxis: {
        accessibility: {
          rangeDescription: 'Number of Trails'
        }
      },
  
      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 2010
        }
      },
  
      series: [{
        name: 'Installation & Developers',
        data:JSON.parse(graph_info[0]?.graph_data??'[]')??[]
      }],
  
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });
    
      return (
        <div>
          <HighchartsReact  highcharts={Highcharts} options={chartOptions} />
        </div>
      );
}
