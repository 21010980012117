import http from "./httpService";

const createMachine = (data) => {
    return http.post("/machine", data);
};

const getMachineforID = async (id) => {
    return await http.get(`/machine/${id}`);
};




const updateMachine = (id, data) => {
    return http.patch(`/machine/${id}`, data);
};

const getMachines = async (clientId) => {
    return await http.get("/machine", {
        params: {
            client_id: `${clientId}`,
        },
    });
};


const getActiveMachines = async (clientId) => {
    return await http.get("/machine", {
        params: {
            client_id: `${clientId}`,
            enabled: 1
        },
    });
};




const getMachineById = async (machineId) => {
    return await http.get("/machine", {
        params: {
            id: `${machineId}`,
        },
    });
};


const getMachineByProcessId = async (processId) => {
    return await http.get("/process-machine", {
        params: {
            process_id: `${processId}`,
        },
    });
};

const getProcessesByMachineId = async (machineId) => {
    return await http.get("/process-machine", {
        params: {
            machine_id: `${machineId}`,
        },
    });
};

const deleteMachineProcess = async (id) => {
    return await http.delete(`/process-machine/${id}`);
};


const createMachineSpindle = async (data) => {
    return http.post("/machine-spindle", data);
};

const createMachineDetail = async (data) => {
    return http.post("/machine-detail", data);

};

const createMachineParameter = async (data) => {
    return http.post("/machine-parameter", data);
};


const getMachinedetailsforMachine = async (machineId) => {
    return await http.get("/machine-parameter", {
        params: {
            machine_id: machineId
        }
    })
}


const createSpindleforMachine = async (machineId) => {
    return http.post("/machine-spindle", {
        params:
            { machine_id: machineId }
    });
};

const getSpindleforMachine = async (machineId) => {
    return http.get("/machine-spindle", {
        params:
            { machine_id: machineId }
    });
};


const getMachineSpindleList = async (machine_id) => {
    return http.get("/machine-spindle-list", {
        params:
            { machine_id: machine_id }
    });
};

const updateMachineSpindle = async (id, newStatus) => {
    return http.patch(`/machine-spindle/${id}`, newStatus)
}



const getProcessforSpindle = async (machinespindleID) => {
    return http.post("/machine-spindle", {
        params:
            { machine_spindle_id: machinespindleID }
    });
};

const updateMachineStatus = async (id, newStatus) => {
    return http.patch(`/machine/${id}`, newStatus)
}

const updateMachineParameterStatus = async (id, newStatus) => {
    return http.patch(`/machine-parameter/${id}`, newStatus)
}

const getSpindleChar = async (charId) => {
    return http.get('/character-machine', {
        params: {
            character_id: charId
        }
    })
}

const getASpindleChar = async (character_id) => {
    return http.get('/machine-character', {
        params: {
            character_id: character_id
        }
    })
}

const updateCharMachine = async (id, newStatus) => {
    return http.patch(`/character-machine/${id}`, newStatus);
};

const getMachineProductData = async (part_id) => {
    return http.get(`/machine-product`, {
        params: {
            part_id: part_id,
        },
    });
};

const getMachineSpindleList1 = async (data) => {
    return http.get(`/machine-spindle-list1`, {
        params: {
            ...data,
        },
    });
};

const getMachineTable = async (client_id, plant_id) => {
    return http.get(`/machine-table`, {
        params: {
            client_id: client_id,
            plant_id: plant_id
        },
    });
};

const getMachineProcess = async (machine_id) => {
    return http.get(`/machine-parameter-list`, {
        params: {
            machine_id: machine_id
        }
    })
}

const updateMachineParameter = async (id, newStatus) => {
    return http.patch(`/machine-parameter/${id}`, newStatus)
}

const getProcessPartMatrix = async (machine_id) => {
    return http.get(`/machine-parameterpart-table`, {
        params: {
            machine_id: machine_id
        }
    })
}


const getMachineProcessPerformance = async (machine_id) => {
    return http.get(`/machine-parameter-performance`, {
        params: {
            machine_id: machine_id
        }
    })
}

const getProductMachineDetail = async (part_id, client_id) => {
    return http.get(`/machine-product-table`, {
        params: {
            part_id: part_id,
            client_id: client_id

        }
    })
}

const updateProductMachineDetail = async (id, newStatus) => {
    return http.patch(`/character-machine/${id}`, newStatus)
}

const getProductMachineAdd = async (part_id, plant_id) => {
    return http.get(`/machine-product-list`, {
        params: {
            part_id: part_id,
            plant_id: plant_id

        }
    })
}



const createCharacterMachine = (data) => {
    return http.post("/character-machine", data);
};

const getProductMachineDetail2 = async (machine_id) => {
    return http.get(`/product-machine-detail`, {
        params: {
            machine_id: machine_id

        }
    })
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getMachineforID,
    createMachine,
    deleteMachineProcess,
    getMachines,
    getMachineByProcessId,
    getProcessesByMachineId,
    getMachineById,
    updateMachine,
    createMachineSpindle,
    createMachineDetail,
    updateCharMachine,
    createMachineParameter,
    createSpindleforMachine,
    getMachinedetailsforMachine,
    getProcessforSpindle,
    getSpindleforMachine,
    updateMachineStatus,
    updateMachineParameterStatus,

    getActiveMachines,
    getSpindleChar,
    getASpindleChar,
    getMachineProductData,
    getMachineSpindleList1,
    getMachineTable,
    getMachineSpindleList,
    updateMachineSpindle,
    getMachineProcess,
    updateMachineParameter,
    getProcessPartMatrix,
    getMachineProcessPerformance,


    getProductMachineDetail,
    updateProductMachineDetail,
    getProductMachineAdd,
    createCharacterMachine,
    getProductMachineDetail2
};