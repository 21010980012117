import { makeStyles, Paper, Tab, Tabs, withStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SubNavigationContext } from "../../context/SubnavigationProvider";
const useStyle = makeStyles({
  indicator: {
    height: 5,
    color: "#fff",
  },
  tabsRoot: {
    width: "100%",
    textTransform: "none",
    backgroundColor: "#5c6bc0",
  },
  root: {
    color: "#0d51ca",
    fontSize: 10,
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: "none",
  },
  textColorPrimary: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 10,
    textTransform: "none",
  },
  wrapped: {
    fontSize: 10,
    textTransform: "none",
  },
  wrapper: {
    fontSize: 10,
    textTransform: "none",
    paddingBottom: 0,
    paddingTop: 0,
  },
  selected: {
    color: "#fff",
  },
});
/* let routestData = new Map();
routestData.set("/criticalcharacter", [
  {
    name: "MSA",
    path: "/criticalcharacter/msa",
  },
  {
    name: "SPC",
    path: "/criticalcharacter/spcplant",
  },
  {
    name: "Process Capability",
    path: "/criticalcharacter/process-capability",
  },
  {
    name: "Production Inspection",
    path: "/criticalcharacter/product-inspection",
  },
  {
    name: "Pre-Dispatch Inspection",
    path: "/criticalcharacter/dispatch-inspection",
  },
]); */

export function SubNavigation() {
  const [selectedTab, setSelectedTab] = useState("");
  const classes = useStyle();
  const [tabsData, setTabsdata] = useState([]);
  const history = useHistory();

  const location = useLocation();
  const { subroutes, isChildrenRequired, navigationCallback } = useContext(
    SubNavigationContext
  );
  useEffect(() => {
    let index = subroutes.findIndex((exp) => exp.path === location.pathname);
    if (index === -1 && !isChildrenRequired) {
      /*       if (routestData.has("/" + location.pathname.toString().split("/")[1])) {
              setTabsdata(
                routestData.get("/" + location.pathname.toString().split("/")[1])
              );
            } else { */
      setTabsdata([]);
      // }
    } else {
      // setTabsdata(subroutes);
    }
    isChildrenRequired ? setSelectedTab(0) : setSelectedTab(location.pathname);
  }, [subroutes, location]);

  return (
    <>
      {tabsData?.length ? (
        <Paper square style={{ backgroundColor: "#536dfe" }}>
          <AntTabs
            value={selectedTab}
            classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setSelectedTab(value);
            }}
            aria-label="disabled tabs example"
            variant="scrollable"
            scrollButtons="on"
          >
            {tabsData.map((exp, i) => (
              <AntTab
                value={exp?.path}
                classes={{
                  selected: classes.selected,
                  root: classes.root,
                  wrapper: classes.wrapper,
                  textColorPrimary: classes.textColorPrimary,
                  wrapped: classes.wrapped,
                }}
                wrapped
                label={exp?.name}
                onClick={() => {
                  if (isChildrenRequired) {
                    navigationCallback(i);
                    setSelectedTab(i);
                  } else {
                    history.push(exp?.path);
                  }
                }}
              />
            ))}
          </AntTabs>
        </Paper>
      ) : null}
    </>
  );
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    color: grey[200],
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: grey[200],
      opacity: 1,
    },
    "&$selected": {
      color: grey[200],
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: grey[200],
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: grey[200],
  },
})(Tabs);
