/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { useEffect, useState, useContext } from "react";
import ActiveInActive from "../../../../components/ActiveInActive";
import { StatusContext } from "../../../../context/StatusContextProvider";
import { StatusHistoryContext } from "../../../../context/StatusHistoryProvider";
import machineService from "../../../../services/machineService";
import AddIcon from "@material-ui/icons/Add";
import CommonGrid from "../../../../components/CommonGrid";
import { DGDrawer } from "../../../../components/DGDrawer";
import { MachineCharacterForm } from "./MachinecharForm";
import StatusFilter from "../../../../components/shared/StatusFilter";

export function MachineListing(partId) {
  const [formOpen, setFormOpen] = useState(false);
  const { setStatus } = useContext(StatusContext);
  const { setStatusHistory } = useContext(StatusHistoryContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    setStatus((prev) => ({
      ...prev,
      reloadGrid: getList,
      callback: updateMachineStatus,
    }));
  }, []);

  async function getList() {
    const res = await machineService.getMachineProductData(partId.partId);
    setRows(res?.data ?? []);
  }


  function updateMachineStatus(comments, newId, newStatus, rowId) {
    return machineService.updateCharMachine(rowId, { enabled: newStatus });
  }
  const columns = [
    {
      field: "serial_number",
      headerName: "SI No",
      valueGetter: "node.rowIndex + 1",
      filter: "agTextColumnFilter",
      minWidth: 100,

    },
    {
      field: "specification",
      headerName: "Specification",
      filter: "agTextColumnFilter",
      resizable: true,
      floatingFilter: true,
      minWidth: 300,
      valueGetter: (params) =>
        `${params?.data?.name !== null ? params?.data?.name : ""}
        ${params?.data?.specification !== null ? params?.data?.specification : ""}   
        ${params?.data?.upper_specification !== null ? params?.data?.upper_specification : ""}   
        ${params?.data?.lower_specification !== null ? params?.data?.lower_specification : ""}
        `
    },
    {
      field: "machine_spindle.machine.machine_number",
      headerName: "Machine Number",
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params) => `${params?.data?.machine_number} ${params?.data?.machine_name}`
    },

    {
      field: "spindle_name",
      headerName: "Spindle",
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },
    {
      field: "process_name",
      headerName: "Process",
      minWidth: 200,
      filter: "agTextColumnFilter",
      floatingFilter: true,
    },

    {
      field: "enabled",
      headerName: "Status",
      minWidth: 200,
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      suppressMenu: true,
      floatingFilterComponent: StatusFilter,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: (params) => (
        <ActiveInActive
          disabled={params?.data?.edit_flag !== 1}
          status={params?.data?.enabled}
          onClick={() =>
            setStatus((prev) => ({
              ...prev,
              open: true,
              entityName: `${params?.data?.machine_number} ${params?.data?.machine_name}`,
              currentStatus: params?.data?.enabled,
              rowId: params.data.id,
            }))
          }
        />
      ),
    },
    {
      field: "history",
      headerName: "Status History",
      minWidth: 200,
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() =>
              setStatusHistory((prev) => ({
                ...prev,
                isOpen: true,
                rowId: params.data.id,
                entity:
                  params?.data?.machine_spindle?.machine_spindle?.machine
                    .name ?? "",
              }))
            }
            size="small"
            color="primary"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Grid container justifyContent="flex-end" p={4}>
      <Grid item md={12} sm={12}>
        <CommonGrid
          columns={columns}
          rows={rows}
          gridheight={213}
          ButtonComponent={() => (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setFormOpen(!formOpen);
              }}
            >
              MACHINE
            </Button>
          )}
        />
      </Grid>
      <DGDrawer
        Component={MachineCharacterForm}
        title="Add Machine"
        isOpen={formOpen}
        setOpen={() => {
          setFormOpen(false);
          getList()
        }}
        drawerWidth={1000}
        componentProps={{ partId: partId }}
        needSave
      />

    </Grid>
  );
}
