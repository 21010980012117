import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography, Divider, CardHeader, Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { DGDrawer } from "../../components/DGDrawer";
import { DashboardDetailsPI } from "../productionInspection/product-inspection/DashboardDetailsPI"


const colorFill = [
    "#0000FF",  // Blue
    "#800080",  // Purple
    "#FFA500",  // Orange
    "#FFC0CB",  // Pink
    "#E6E6FA",  // Lavender
    "#00FFFF",  // Cyan
    "#FF00FF",  // Magenta
    "#082567",  // Sapphire
    "#40E0D0",  // Turquoise
    "#EE82EE",  // Violet
    "#FFD700",  // Gold
    "#FF1493",  // Deep Pink
    "#9370DB",  // Medium Purple
    "#DA70D6",  // Orchid
    "#F0E68C"   // Khaki
];

const useStyledDivStyleCard2 = () => ({
    maxWidth: '98%',
    width: '100%',
    height: '60px',
    borderRadius: '10%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    boxSizing: 'border-box'

})


// Characteristics Card
export function OverviewCard2({
    name = "",
    count = 0,
    totalcount = 0,
    okcount = 0,
    notokcount = 0,
    charactercount = 0,
    partcount = 0,
    mainHeading = "",
    subHeading = "",
    timePeriod = "",
    Component = "",
    dataDepth = "",
    dataLabel = ""

}) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const closeDrawer = () => {
        setDrawerOpen(false);
    }


    const componentMap = {
        DashboardDetailsPI: DashboardDetailsPI,
    };

    const templink = componentMap[Component]


    return (
        <>
            <DGDrawer
                isOpen={drawerOpen}
                onClose={closeDrawer}
                setOpen={closeDrawer}
                drawerWidth={800}
                title={subHeading}
                Component={templink}
                componentProps={dataDepth}
            />

            <div onClick={() => { setDrawerOpen(true) }} className="dg_dash_cc">

                <span className="dg_dash_cc_tagline">
                    {mainHeading}
                </span>
                <h6 className="dg_dash_cc_title">
                    {subHeading}
                </h6>
                <div className="dg_dash_entry1_count">
                    <div className="dg_dash_entry1_count_main">
                        <h3>{totalcount}</h3>
                        <span>Evaluations </span>
                        <span>{`(${dataLabel})`}</span>

                    </div>
                    <div className="dg_dash_entry1_count_sub">
                        <h4 className="dg_dash_cc_count_2">{okcount}</h4>
                        <h4 className="dg_dash_cc_count_3">{notokcount}</h4>
                    </div>
                </div>
                <p className="dg_dash_entry1_para">No of Parts: {partcount}</p>
                <p className="dg_dash_entry1_para">
                    No of Characteristics: {charactercount}
                </p>
            </div>
        </>
    );
}

