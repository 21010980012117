import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { spacing } from "@material-ui/system";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import styled from "styled-components/macro";
import piService from "../../../services/piService";
import { AuthContext } from "../../../context/AuthContextProvider";
import { useContext } from "react";

const Spacer = styled.div(spacing);

export function InitiateCalibration({
  isOpen = false,
  setOpen = () => {},
  gaugeItem = {},
}) {
  const { currentUser } = useContext(AuthContext);
  function onSubmit() {
    piService
      .createPIActivity({
        guage_item: gaugeItem?.gauge_item_id,
        user_id: currentUser?.id,
        activity: "initiate",
        pi_agency_id: gaugeItem?.pi_agency_id,
      })
      .then((res) => {
        setOpen(false, true);
      });
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#0d51ca",
          color: "#fff",
        }}
      >
        <DialogTitle id="form-dialog-title">Initiate Calibration</DialogTitle>
        <div>
          <Button onClick={() => setOpen(false)}>
            <CloseRoundedIcon style={{ fill: "#fff" }} />
          </Button>
        </div>
      </div>
      <DialogContent pb={4}>
        <DialogContentText>
          Do you want to initiate calibration
          {gaugeItem?.calib_agency ? (
            <span>
              <strong> {" " + gaugeItem?.calib_agency + " "}</strong>
            </span>
          ) : (
            ""
          )}
          With
          {gaugeItem?.family_name ? (
            <span>
              <strong> {" " + gaugeItem?.family_name}</strong>
            </span>
          ) : (
            ""
          )}
        </DialogContentText>
        <Spacer my={6} />
        <Button
          variant="outlined"
          color="primary"
          mr={3}
          mb={3}
          position="right"
          style={{ margin: 5, marginLeft: 0 }}
          onClick={() => setOpen(false)}
        >
          No
        </Button>
        <Button
          variant="contained"
          color="primary"
          mb={3}
          position="right"
          style={{ margin: 5 }}
          onClick={() => onSubmit()}
        >
          Yes
        </Button>
      </DialogContent>
    </Dialog>
  );
}
